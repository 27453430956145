import ImagePickerComp from 'materialTheme/src/file/ImagePicker';
import { ImagePickerOptions } from 'materialTheme/src/file/ImagePickerOptions';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import * as mime from 'mime';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import React, { PureComponent } from 'react';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { CreateTicket } from 'upmesh-core/src/client/commands/tickets/CreateTicket';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import * as uuid from 'uuid';
import { I18n } from '../i18n/I18n';
import { AddFilesPU } from './file/AddFilesPU';
import { AddStoredFileAndUpload } from './file/AddStoredFileAndUpload';
import { SharedFiles } from './file/SharedFiles';
export class FabWithCamera extends PureComponent {
    constructor() {
        super(...arguments);
        this.openFab = async () => {
            const { additionalActionButtons, showCamera, size } = this.props;
            const sViewHeight = size.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0;
            this.to = undefined;
            if (Fab.instance != null && this.imagePicker != null) {
                const hasCam = showCamera && (await ImagePickerComp.hasCamera());
                const fabActions = [...additionalActionButtons];
                if (hasCam) {
                    fabActions.push({
                        icon: 'camera',
                        onPress: (_e) => {
                            if (Fab.instance != null) {
                                Fab.instance.closeButtons();
                            }
                            this.openCam();
                        },
                        text: I18n.m.getMessage('tooltipOpenCamera'),
                    });
                }
                fabActions.reverse();
                if (fabActions.length === 1) {
                    Fab.instance.open({
                        fabIcon: fabActions[0].icon,
                        fabIconMoon: fabActions[0].iconMoon,
                        fabIconOpen: 'close',
                        small: false,
                        fabColor: ThemeManager.style.brandPrimary,
                        onPressFab: fabActions[0].onPress,
                        title: fabActions[0].text,
                        extraPaddingBottom: sViewHeight,
                    });
                }
                else if (fabActions.length > 1) {
                    Fab.instance.open({
                        fabActions,
                        fabIcon: 'plus',
                        fabIconOpen: 'close',
                        small: false,
                        fabColor: ThemeManager.style.brandPrimary,
                        extraPaddingBottom: sViewHeight,
                    });
                }
            }
            else {
                this.to = window.setTimeout(this.openFab, 100);
            }
        };
        this.closeFab = (callback) => {
            if (Fab.instance != null) {
                Fab.instance.close(callback);
            }
        };
        this.setImagePicker = (r) => {
            this.imagePicker = r;
        };
        this.onGotImage = async (f) => {
            SharedFiles.excludeFolders = null;
            SharedFiles.files = [...f];
            AddFilesPU.openDialog({
                type: 'TicketOrFolder',
                projectId: this.props.projectId,
                dontChangeProject: true,
            })(null);
        };
        this.saveInMyMedia = (f) => async () => {
            const { projectId } = this.props;
            Dialog.instance?.close();
            return AddStoredFileAndUpload.onGotFile(f, projectId);
        };
        this.createNewTicket = (f) => async () => {
            LoadingEvents.instance.startLoading();
            const { projectId } = this.props;
            const mimeType = f.mime;
            const ext = mime.getExtension(mimeType);
            if (ext == null) {
                return Routing.instance.alert.post({ text: I18n.m.getMessage('fileError') });
            }
            const fileId = `${uuid.v1()}.${ext}`;
            const ticketId = await this.createTicket();
            return Dialog.instance?.close(async () => {
                LoadingEvents.instance.stopLoading();
                await AddStoredFileAndUpload.onGotFile(f, projectId, ticketId, fileId);
                requestAnimationFrame(() => {
                    Routing.instance.openDialog('ticket', { id: ticketId }, false)(null);
                });
            });
        };
        this.createTicket = async () => {
            const { projectId } = this.props;
            const title = I18n.m.getMessage('ticketsDetailsTitlePlaceholder');
            try {
                const c = new CreateTicket({
                    title,
                    projectId,
                });
                await c.execute();
                const count = async () => UpmeshClient.instance.modals.ticket.count(`id eq '${c.entityId}'`);
                let timeout = 0;
                while ((await count()) === 0 && timeout < 20) {
                    await new Promise((r) => {
                        timeout += 1;
                        setTimeout(r, 500);
                    });
                }
                return c.entityId;
            }
            catch (e) {
                console.error('cant createTicket', e);
                this.errorResult(e).catch((err) => console.debug(err));
            }
            return '';
        };
        this.errorResult = async (e) => {
            const reportedErrorMessage = e != null && e.messageCode != null ? e.messageCode.toString() : 'ticketsDetailsErrorGenericIssue';
            const reportedError = new Error(reportedErrorMessage);
            ErrorReporter.sendReport({ subject: 'FabWithCamera erorrResult', data: reportedError, type: 'warn' });
        };
        this.onGotImageFile = async (response) => {
            if (Array.isArray(response)) {
                this.onGotImage(response).catch((err) => console.debug(err));
            }
            else {
                this.onGotImage([response]).catch((err) => console.debug(err));
            }
        };
    }
    static get defaultProps() {
        return {
            additionalActionButtons: [],
            showCamera: true,
        };
    }
    componentDidMount() {
        this.openFab().catch((err) => console.debug(err));
    }
    componentWillUnmount() {
        if (this.to != null) {
            clearTimeout(this.to);
            this.to = undefined;
        }
        this.closeFab();
    }
    openCam() {
        if (this.imagePicker != null) {
            this.imagePicker.execute({ selectMethod: 'camera', multiple: true });
        }
    }
    render() {
        return (<ImagePickerComp ref={this.setImagePicker} dragNdrop={false} options={new ImagePickerOptions({
                includeBase64: false,
                cropperCircleOverlay: false,
                cropping: false,
                dropMultiHandlerDialogText: I18n.m.getMessage('addImageOnMultiSelect'),
                dropText: I18n.m.getMessage('addImageDropHint'),
                selectMethod: 'any',
                includeExif: true,
                useFrontCamera: false,
                multiple: true,
                mediaType: 'photo',
                filePicketCompId: 'filesInput',
                compressImageQuality: 0.85,
                editModeEnabled: CurrentUser.settings.appSettings == null ||
                    CurrentUser.settings.appSettings['openImageEditorAfterPhoto'] === true,
            })} callBack={this.onGotImageFile}/>);
    }
}
