import _ from 'lodash';
import { ResponsiveColsList } from 'materialTheme/src/theme/components/ResponsiveColsList';
import { SearchBar } from 'materialTheme/src/theme/components/SearchBar';
import React, { PureComponent } from 'react';
import { FlatList, View } from 'react-native';
import { TicketStatus, TicketStatusColor } from 'upmesh-core/src/client/query/entities/TicketEntity';
import { TicketFilter } from 'upmesh-core/src/client/query/filter/TicketFilter';
import { I18n } from '../../i18n/I18n';
import { TicketCard } from './TicketCard';
export class TicketCanban extends PureComponent {
    constructor(props) {
        super(props);
        this.isRenderingTickets = false;
        this.preRenderTicketsByStatus = async (status) => {
            const { planId, tickets } = this.props;
            const { searchStrings } = this.state;
            let ticketEntitiesByStatus = [];
            for (let i = 0; i < tickets.length; i += 1) {
                if (tickets[i].ticketStatus === status) {
                    ticketEntitiesByStatus.push(tickets[i]);
                }
            }
            if (searchStrings != null && searchStrings[status] != null && searchStrings[status].length > 0) {
                ticketEntitiesByStatus = (await TicketFilter.filterTicketsByText(searchStrings[status], ticketEntitiesByStatus, !(planId != null && planId.length > 0)));
            }
            return ticketEntitiesByStatus;
        };
        this.onSearch = (type) => async (text) => {
            const { searchStrings } = this.state;
            searchStrings[type] = text;
            this.setState({
                searchStrings,
            }, () => {
                this.preRenderTickets().catch((err) => console.debug(err));
            });
        };
        this.getItemKey = (item, _index) => `ticket_card_${item.id}`;
        this.renderRow = ({ item }) => {
            const { planId, activeMultiselect, onMultiSelect, selectedIDs, multiselectEnabled, showProjects } = this.props;
            return (<TicketCard ticket={item} key={`tCard${item.id}`} bullseye={planId != null && planId !== 'all'} status={false} activeMultiselect={multiselectEnabled ? activeMultiselect : undefined} onMultiSelect={multiselectEnabled ? onMultiSelect : undefined} selected={selectedIDs.has(item.id)} showProjects={showProjects}/>);
        };
        this.state = {
            searchStrings: {},
            ticketsByStatus: {
                open: [],
                closed: [],
                checked: [],
                processing: [],
            },
        };
    }
    componenWillUnmount() {
        if (this.isRenderingTicketsTimeOut != null) {
            clearTimeout(this.isRenderingTicketsTimeOut);
        }
    }
    componentDidMount() {
        requestAnimationFrame(() => {
            this.preRenderTickets().catch((err) => console.debug(err));
        });
    }
    componentDidUpdate(_prevProps, _prevState, _snapshot) {
        if (!_.isEqual(this.props.tickets, _prevProps.tickets)) {
            this.preRenderTickets().catch((err) => console.debug(err));
        }
    }
    async preRenderTickets() {
        if (this.isRenderingTickets) {
            this.isRenderingTicketsTimeOut = setTimeout(() => {
                this.preRenderTickets().catch((err) => console.debug(err));
            }, 100);
        }
        else {
            const { onChangeTicketOrder } = this.props;
            this.isRenderingTickets = true;
            const { ticketsByStatus } = this.state;
            ticketsByStatus[TicketStatus.open] = await this.preRenderTicketsByStatus(TicketStatus.open);
            ticketsByStatus[TicketStatus.processing] = await this.preRenderTicketsByStatus(TicketStatus.processing);
            ticketsByStatus[TicketStatus.checked] = await this.preRenderTicketsByStatus(TicketStatus.checked);
            ticketsByStatus[TicketStatus.closed] = await this.preRenderTicketsByStatus(TicketStatus.closed);
            if (onChangeTicketOrder != null)
                onChangeTicketOrder([
                    ...ticketsByStatus[TicketStatus.open],
                    ...ticketsByStatus[TicketStatus.processing],
                    ...ticketsByStatus[TicketStatus.closed],
                    ...ticketsByStatus[TicketStatus.checked],
                ]);
            this.setState({
                ticketsByStatus,
            }, () => {
                this.forceUpdate();
                this.isRenderingTickets = false;
            });
        }
    }
    render() {
        const { width, height, style, iosContentInset, searchBarTextColor } = this.props;
        const { ticketsByStatus, searchStrings } = this.state;
        const headerHeight = 32;
        const columnStyle = {
            height: height - headerHeight - 12,
            width: '100%',
        };
        const columnStyleOuter = {
            padding: 4,
        };
        const headerStyle = {
            height: headerHeight,
            justifyContent: 'center',
            width: '100%',
            padding: 0,
            borderStyle: 'solid',
            borderBottomWidth: 4,
        };
        return (<View style={[
                style,
                {
                    width,
                    height,
                    position: 'relative',
                },
            ]}>
        <ResponsiveColsList width={width} height={height} targetWidthPerSection={228} overlappingWidth={16} showButtons contents={[
                <View key="open" style={columnStyleOuter} accessibilityLabel="open-lane">
              <View style={{
                        ...headerStyle,
                        borderColor: TicketStatusColor.open,
                    }}>
                <SearchBar accessibilityLabel="open-search" backgroundColor="transparent" searchBarPlaceholder={`${I18n.m.getMessage('ticketsDetailsStateopen')} (${ticketsByStatus[TicketStatus.open] ? ticketsByStatus[TicketStatus.open].length : '0'})`} searchOnChange={this.onSearch(TicketStatus.open)} searchBarValue={searchStrings[TicketStatus.open]} tooltip={I18n.m.getMessage('searchTickets')} textColor={searchBarTextColor} iconColor={searchBarTextColor}/>
              </View>
              <FlatList key="openTickets" keyExtractor={this.getItemKey} renderItem={this.renderRow} numColumns={1} data={ticketsByStatus[TicketStatus.open]} style={columnStyle} contentContainerStyle={{ paddingBottom: 60 }} contentInset={{ top: 0, bottom: iosContentInset, left: 0, right: 0 }}/>
            </View>,
                <View key="Processing" style={columnStyleOuter} accessibilityLabel="in-progress-lane">
              <View style={{
                        ...headerStyle,
                        borderColor: TicketStatusColor.processing,
                    }}>
                <SearchBar accessibilityLabel="in-progress-search" backgroundColor="transparent" searchBarPlaceholder={`${I18n.m.getMessage('ticketsDetailsStateprocessing')} (${ticketsByStatus[TicketStatus.processing] ? ticketsByStatus[TicketStatus.processing].length : '0'})`} searchOnChange={this.onSearch(TicketStatus.processing)} searchBarValue={searchStrings[TicketStatus.processing]} tooltip={I18n.m.getMessage('searchTickets')} textColor={searchBarTextColor} iconColor={searchBarTextColor}/>
              </View>
              <FlatList key="processingTickets" keyExtractor={this.getItemKey} renderItem={this.renderRow} numColumns={1} data={ticketsByStatus[TicketStatus.processing]} style={columnStyle} contentContainerStyle={{ paddingBottom: 60 }} contentInset={{ top: 0, bottom: iosContentInset, left: 0, right: 0 }}/>
            </View>,
                <View key="Closed" style={columnStyleOuter} accessibilityLabel="to-be-reviewed-lane">
              <View style={{
                        ...headerStyle,
                        borderColor: TicketStatusColor.closed,
                    }}>
                <SearchBar accessibilityLabel="to-be-verified-search" backgroundColor="transparent" searchBarPlaceholder={`${I18n.m.getMessage('ticketsDetailsStateclosed')} (${ticketsByStatus[TicketStatus.closed] ? ticketsByStatus[TicketStatus.closed].length : '0'})`} searchOnChange={this.onSearch(TicketStatus.closed)} searchBarValue={searchStrings[TicketStatus.closed]} tooltip={I18n.m.getMessage('searchTickets')} textColor={searchBarTextColor} iconColor={searchBarTextColor}/>
              </View>
              <FlatList key="closedTickets" keyExtractor={this.getItemKey} renderItem={this.renderRow} numColumns={1} data={ticketsByStatus[TicketStatus.closed]} style={columnStyle} contentContainerStyle={{ paddingBottom: 60 }} contentInset={{ top: 0, bottom: iosContentInset, left: 0, right: 0 }}/>
            </View>,
                <View key="Checked" style={columnStyleOuter} accessibilityLabel="done-lane">
              <View style={{
                        ...headerStyle,
                        borderColor: TicketStatusColor.checked,
                    }}>
                <SearchBar accessibilityLabel="done-search" backgroundColor="transparent" searchBarPlaceholder={`${I18n.m.getMessage('ticketsDetailsStatechecked')} (${ticketsByStatus[TicketStatus.checked] ? ticketsByStatus[TicketStatus.checked].length : '0'})`} searchOnChange={this.onSearch(TicketStatus.checked)} searchBarValue={searchStrings[TicketStatus.checked]} tooltip={I18n.m.getMessage('searchTickets')} textColor={searchBarTextColor} iconColor={searchBarTextColor}/>
              </View>
              <FlatList key="checkedTickets" keyExtractor={this.getItemKey} renderItem={this.renderRow} numColumns={1} data={ticketsByStatus[TicketStatus.checked]} style={columnStyle} contentContainerStyle={{ paddingBottom: 60 }} contentInset={{ top: 0, bottom: iosContentInset, left: 0, right: 0 }}/>
            </View>,
            ]}/>
      </View>);
    }
}
