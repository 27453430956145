import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { ChipDialogForm } from 'materialTheme/src/theme/components/chips/ChipDialogForm';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { ExcelFileDialog } from 'materialTheme/src/theme/components/file/ExcelFileDialog';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Platform, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { ChangeUserRegion } from 'upmesh-core/src/client/commands/companies/regions/ChangeUserRegion';
import { CreateRegion } from 'upmesh-core/src/client/commands/companies/regions/CreateRegion';
import { DeleteRegion } from 'upmesh-core/src/client/commands/companies/regions/DeleteRegion';
import { RemoveUserRegion } from 'upmesh-core/src/client/commands/companies/regions/RemoveUserRegion';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { AddRegionToCompanyDialog } from './AddRegionToCompanyDialog';
import { ChangeRegionCompanyDialog } from './ChangeRegionCompanyDialog';
const noGroupImg = require('../../assets/img/no_types_or_crafts_or_tags.png');
export class CompanyRegionsView extends PureComponent {
    constructor(props) {
        super(props);
        this.myRole = () => {
            return CompanyUserInfo.me == null ? 'user' : CompanyUserInfo.me.role;
        };
        this.updateWrapper = async () => {
            if (this.updateTimeOut != null)
                clearTimeout(this.updateTimeOut);
            this.updateTimeOut = setTimeout(this.updateAll, 300);
        };
        this.updateAll = async () => {
            const { company } = this.props;
            const regions = await UpmeshClient.instance.modals.region.get({
                filter: `deleted ne true and companyId eq '${company.id}'`,
            });
            const companyUsers = await UpmeshClient.instance.modals.companyMember.get({
                filter: `companyId eq '${company.id}' and deleted ne true`,
                orderby: 'firstName',
            });
            const data = [];
            for (const region of regions) {
                data.push({
                    id: region.id,
                    region: region.region,
                    country: region.country,
                    members: companyUsers.filter((user) => user.regionId === region.id).length,
                    entity: region,
                });
            }
            const companyMemberListItems = [];
            for (let i = 0; i < companyUsers.length; i += 1) {
                const companyUser = companyUsers[i];
                let fullName = `${companyUser.firstName} ${companyUser.lastName}`;
                if (companyUser.userId != null) {
                    try {
                        const user = await AuthClient.instance.modals.user.getById(companyUser.userId);
                        fullName = user.getFullName();
                    }
                    catch (e) {
                        console.debug('cant get user', e);
                    }
                }
                const userRegion = regions.find((elem) => elem.id === companyUser.regionId);
                companyMemberListItems.push({
                    title: fullName,
                    data: companyUsers[i].id,
                    secondTextLine: userRegion != null ? `${userRegion?.region} ${userRegion?.country}` : '',
                });
            }
            this.setState({ data, companyMemberListItems });
        };
        this.removeRegion = (region) => (_e) => {
            Routing.instance.alert.post({
                text: I18n.m.getMessage('companyRemoveRegionQuestion', { region }),
                buttons: [
                    <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                    <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={this.removeRegionNow(region.entity)} backgroundColor={ThemeManager.style.brandDanger}/>,
                ],
            });
        };
        this.removeRegionNow = (region) => (_e) => {
            LoadingEvents.instance.startLoading();
            Alert.instance?.close(async () => {
                try {
                    const remove = new DeleteRegion({}, region.id, CurrentUser.token);
                    await remove.execute(ClientStore.commandStore);
                }
                catch (e) {
                    console.error(e);
                    Routing.instance.alert.post({ text: DefaultErrorHandler.getDefaultErrorMessages(e, I18n.m) });
                }
                LoadingEvents.instance.stopLoading();
            });
        };
        this.openFab = () => {
            if (Fab.instance == null) {
                window.setTimeout(this.openFab, 100);
                return;
            }
            const permitted = this.myRole() === 'admin';
            if (Platform.OS === 'web') {
                Fab.instance.open({
                    fabIcon: 'plus',
                    fabIconOpen: 'close',
                    small: false,
                    fabColor: ThemeManager.style.brandPrimary,
                    fabColorOpen: ThemeManager.style.brandSecondary,
                    onPressFab: !permitted
                        ? () => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') })
                        : undefined,
                    fabActions: permitted
                        ? [
                            {
                                text: I18n.m.getMessage('companyAddRegionDialogTitle'),
                                icon: 'plus',
                                onPress: this.openAddDialog,
                            },
                            {
                                text: I18n.m.getMessage('companyExcelDialogTitle'),
                                icon: 'microsoft-excel',
                                onPress: this.openExcelDialog,
                            },
                        ]
                        : undefined,
                });
            }
            else {
                Fab.instance.open({
                    fabIcon: 'plus',
                    fabIconOpen: 'close',
                    small: false,
                    fabColor: ThemeManager.style.brandPrimary,
                    fabColorOpen: ThemeManager.style.brandSecondary,
                    onPressFab: permitted ? this.openAddDialog : undefined,
                });
            }
        };
        this.openExcelDialog = () => {
            Dialog.instance?.open({
                closeOnTouchOutside: false,
                fullscreenResponsive: true,
                contentPadding: false,
                scrollable: false,
                showCloseButton: false,
                width: 1042,
                content: (<ExcelFileDialog saveFunction={this.saveFunction} generateExampleRecords={this.generateExamples} columns={[
                        { title: I18n.m.getMessage('companyExcelRegionNameHeader'), keyInData: 'Region', style: { width: 200 } },
                        { title: I18n.m.getMessage('companyExcelRegionCountryHeader'), keyInData: 'Land', style: { width: 200 } },
                    ]}/>),
            });
        };
        this.generateExamples = async () => {
            const { data } = this.state;
            if (data != null) {
                return data.map((element) => {
                    return {
                        Region: element.region,
                        Land: element.country,
                    };
                });
            }
            return [];
        };
        this.saveFunction = async (records) => {
            const { company } = this.props;
            const regionPromises = [];
            records.forEach((element) => {
                regionPromises.push(new CreateRegion({
                    region: element.Region,
                    country: element.Land,
                    companyId: company.id,
                }).execute(ClientStore.commandStore));
            });
            await Promise.all(regionPromises);
        };
        this.openAddDialog = (_e) => {
            const { company } = this.props;
            Dialog.instance?.open({
                closeOnTouchOutside: false,
                content: <AddRegionToCompanyDialog company={company}/>,
                contentPadding: false,
                showCloseIcon: true,
                scrollable: false,
            });
        };
        this.openEditDialog = (region) => (_e) => {
            if (this.myRole() === 'admin') {
                const { company } = this.props;
                Dialog.instance?.open({
                    closeOnTouchOutside: false,
                    content: <ChangeRegionCompanyDialog region={region.entity} company={company}/>,
                    contentPadding: false,
                    showCloseIcon: true,
                    scrollable: false,
                });
            }
            else {
                Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') });
            }
        };
        this.openChangeMembersDialog = (_item) => (_e) => {
            const { companyMemberListItems } = this.state;
            for (const member of companyMemberListItems) {
                member.selected = member.secondTextLine === `${_item.region} ${_item.country}`;
            }
            Dialog.instance?.open({
                content: (<ChipDialogForm key="MembersDialogForm" title={I18n.m.getMessage('companyRegionsEditMembers')} items={this.state.companyMemberListItems} onCancel={() => Dialog.instance?.close()} multiselect onSave={(e) => this.onSaveRegionMembers(e, _item.entity)} canAddNewChips={false} searchBarPlaceholder={I18n.m.getMessage('search')}/>),
                contentPadding: false,
                fullscreenResponsive: true,
            });
        };
        this.onSaveRegionMembers = async (items, region) => {
            const { company } = this.props;
            const companyMember = await UpmeshClient.instance.modals.companyMember.get({
                filter: `companyId eq '${company.id}' and deleted ne true`,
                orderby: 'firstname',
            });
            const regionMember = companyMember.filter((elem) => elem.regionId === region.id);
            const addToRegionPromises = [];
            for (let i = 0; i < items.length; i += 1) {
                const current = companyMember.find((a) => a.id === items[i].data);
                if (current) {
                    if (items[i].selected && regionMember.findIndex((elem) => elem.id === current.id) === -1) {
                        const command = new ChangeUserRegion({ memberId: current.id }, region.id, CurrentUser.token);
                        addToRegionPromises.push(command.execute(ClientStore.commandStore));
                    }
                    else if (!items[i].selected && regionMember.find((elem) => elem.id === current.id)) {
                        const command = new RemoveUserRegion({}, current.id, CurrentUser.token);
                        addToRegionPromises.push(command.execute(ClientStore.commandStore));
                    }
                }
            }
            await Promise.all(addToRegionPromises);
            Dialog.instance?.close();
        };
        this.state = {
            myRole: this.myRole(),
            companyMemberListItems: [],
            data: [],
        };
    }
    componentDidMount() {
        const { company } = this.props;
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'CompanyRegionsView',
            readModelName: 'Region',
            callback: this.updateWrapper,
        });
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'CompanyRegionsViewCompany',
            readModelName: 'Company',
            entityId: company.id,
            callback: this.updateWrapper,
        });
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'CompanyRegionsViewMember',
            readModelName: 'CompanyMember',
            callback: this.updateWrapper,
        });
        this.openFab();
        this.updateAll().catch((err) => console.debug(err));
    }
    componentWillUnmount() {
        UpmeshClient.eventDispatcher.detach('Region', 'CompanyRegionsView');
        UpmeshClient.eventDispatcher.detach('Company', 'CompanyRegionsViewCompany');
        UpmeshClient.eventDispatcher.detach('CompanyMember', 'CompanyRegionsViewMember');
        if (Fab.instance != null)
            Fab.instance.close();
    }
    render() {
        const { company, size } = this.props;
        const { data, myRole } = this.state;
        if (!(myRole === 'admin' || myRole === 'user'))
            return null;
        const tableMaxHeight = size.contentHeight - ThemeManager.style.headerHeight - ThemeManager.style.getScreenRelativePixelSize(60);
        return (<View style={{
                width: '100%',
                maxWidth: '100%',
                paddingTop: ThemeManager.style.contentPaddingValue,
                alignSelf: 'center',
                paddingHorizontal: 8,
            }}>
        <View style={{ flexDirection: 'row', marginLeft: 8 + ThemeManager.style.contentPaddingValue }}>
          <MaterialText centeredBox type={MaterialTextTypes.H6}>
            {I18n.m.getMessage('regions')}
          </MaterialText>
        </View>
        <Card style={{ width: '100%', height: tableMaxHeight }}>
          <Table tableName="CompanyRegions" actionItemsLength={3} actions={myRole === 'admin'
                ? (_item) => [
                    {
                        icon: 'wrench-outline',
                        onAction: this.openChangeMembersDialog,
                        toolTip: I18n.m.getMessage('companyRegionsEditMembers'),
                    },
                    {
                        icon: 'pencil-outline',
                        onAction: this.openEditDialog,
                        toolTip: I18n.m.getMessage('edit'),
                    },
                    {
                        icon: 'delete',
                        onAction: this.removeRegion,
                        toolTip: I18n.m.getMessage('delete'),
                        disabled: _item.id === company.id,
                    },
                ]
                : undefined} maxHeight={tableMaxHeight - 16} emptyTableImage={noGroupImg} emptyTableHint={I18n.m.getMessage('companyRegionsNoDataHint')} emptyTableText={I18n.m.getMessage('companyRegionsNoDataText')} data={data} sortBy="region" sortDirection="asc" columns={[
                {
                    title: I18n.m.getMessage('companyExcelRegionNameHeader'),
                    keyInData: 'region',
                    style: { width: 200 },
                    sortable: true,
                    dataType: 'string',
                },
                {
                    title: I18n.m.getMessage('companyExcelRegionCountryHeader'),
                    keyInData: 'country',
                    style: { width: 200 },
                    sortable: true,
                    dataType: 'string',
                },
                {
                    title: I18n.m.getMessage('quantity'),
                    keyInData: 'members',
                    style: { width: 200 },
                    sortable: true,
                    dataType: 'number',
                },
            ]}/>
        </Card>
      </View>);
    }
}
