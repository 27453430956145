import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import DraggableFlatList from 'materialTheme/src/theme/components/DraggableFlatList';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { ExcelFileDialog } from 'materialTheme/src/theme/components/file/ExcelFileDialog';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useRef, useState } from 'react';
import { Image, Platform, TouchableOpacity, View } from 'react-native';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { AddCompanyProjectState } from 'upmesh-core/src/client/commands/companies/projectstates/AddCompanyProjectState';
import { ChangeCompanyProjectState } from 'upmesh-core/src/client/commands/companies/projectstates/ChangeCompanyProjectState';
import { DeleteCompanyProjectState } from 'upmesh-core/src/client/commands/companies/projectstates/DeleteCompanyProjectState';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { AddProjectStateToCompanyDialog } from './AddProjectStateToCompanyDialog';
const noGroupImg = require('../../assets/img/no_types_or_crafts_or_tags.png');
export function CompanyProjectStateView(props) {
    const [listData, setListData] = useState([]);
    const movingItem = useRef(null);
    const showInfo = () => {
        Routing.instance.alert.post({ text: I18n.m.getMessage('projectStateInformation') });
    };
    const myRole = () => {
        return CompanyUserInfo.me == null ? 'user' : CompanyUserInfo.me.role;
    };
    const openAddDialog = (_e) => {
        const { companySettings } = props;
        Dialog.instance?.open({
            closeOnTouchOutside: false,
            content: <AddProjectStateToCompanyDialog companySettings={companySettings}/>,
            contentPadding: false,
            scrollable: false,
            showCloseIcon: true,
        });
    };
    const openEditDialog = (state) => {
        const { companySettings } = props;
        Dialog.instance?.open({
            closeOnTouchOutside: false,
            content: <AddProjectStateToCompanyDialog projectState={state} companySettings={companySettings}/>,
            contentPadding: false,
            scrollable: false,
            showCloseIcon: true,
        });
    };
    const saveFunction = async (records) => {
        const sortedRecord = records.sort((a, b) => a.order - b.order);
        const statePromises = [];
        sortedRecord.forEach((elem) => {
            statePromises.push(new AddCompanyProjectState({
                order: elem.order,
                name: elem.name,
                color: elem.color,
            }, CompanyUserInfo.company?.id ?? '').execute(ClientStore.commandStore));
        });
        try {
            await Promise.all(statePromises);
        }
        catch (err) {
            console.error('error on import project states from excel', err);
            DefaultErrorHandler.showDefaultErrorAlert(err);
        }
    };
    const generateExamples = async () => {
        const { companySettings } = props;
        const examples = [];
        companySettings.projectStateNames?.forEach((element) => {
            examples.push({
                order: element.order,
                name: element.name,
                color: element.color,
            });
        });
        return examples;
    };
    const openExcelDialog = () => {
        Dialog.instance?.open({
            closeOnTouchOutside: false,
            fullscreenResponsive: true,
            contentPadding: false,
            scrollable: false,
            showCloseButton: false,
            width: 1042,
            content: (<ExcelFileDialog saveFunction={saveFunction} generateExampleRecords={generateExamples} columns={[
                    { title: I18n.m.getMessage('projectStateOrder'), keyInData: 'order', style: { width: 200 } },
                    { title: I18n.m.getMessage('projectStateName'), keyInData: 'name', style: { width: 200 } },
                    { title: I18n.m.getMessage('color'), keyInData: 'color', style: { width: 200 } },
                ]}/>),
        });
    };
    const openFab = () => {
        if (Fab.instance == null) {
            window.setTimeout(openFab, 100);
            return;
        }
        const permitted = myRole() === 'admin';
        if (Platform.OS === 'web') {
            Fab.instance.open({
                fabIcon: 'plus',
                fabIconOpen: 'close',
                small: false,
                fabColor: ThemeManager.style.brandPrimary,
                fabColorOpen: ThemeManager.style.brandSecondary,
                onPressFab: !permitted
                    ? () => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') })
                    : undefined,
                fabActions: permitted
                    ? [
                        {
                            text: I18n.m.getMessage('projectState'),
                            icon: 'plus',
                            onPress: openAddDialog,
                        },
                        {
                            text: I18n.m.getMessage('companyExcelDialogTitle'),
                            icon: 'microsoft-excel',
                            onPress: openExcelDialog,
                        },
                    ]
                    : undefined,
            });
        }
        else {
            Fab.instance.open({
                fabIcon: 'plus',
                fabIconOpen: 'close',
                small: false,
                fabColor: ThemeManager.style.brandPrimary,
                fabColorOpen: ThemeManager.style.brandSecondary,
                onPressFab: permitted
                    ? openAddDialog
                    : () => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }),
            });
        }
    };
    useEffect(() => {
        openFab();
        setListData(props.companySettings.projectStateNames);
        return () => {
            Fab.instance?.close();
        };
    }, []);
    useEffect(() => {
        if (listData !== props.companySettings.projectStateNames) {
            setListData(props.companySettings.projectStateNames);
        }
        if (JSON.stringify(listData) !== JSON.stringify(props.companySettings.projectStateNames)) {
            setListData(props.companySettings.projectStateNames);
        }
    }, [props.companySettings]);
    const changeOrder = ({ data }) => {
        setListData(data);
        const { companySettings } = props;
        const currentItem = movingItem.current;
        if (currentItem != null && companySettings != null) {
            const findElemIndex = data.findIndex((elem) => elem.id === currentItem.id);
            const changeCommand = new ChangeCompanyProjectState({ order: findElemIndex + 1, name: currentItem.name, color: currentItem.color, id: currentItem.id }, companySettings.id);
            changeCommand.execute(ClientStore.commandStore).catch((err) => {
                console.error('could not change order of project states', err);
                DefaultErrorHandler.showDefaultErrorAlert(err);
            });
        }
    };
    const removeProjectStateNow = (current) => (_e) => {
        const { company } = props;
        LoadingEvents.instance.stopLoading();
        Alert.instance?.close(async () => {
            try {
                const dCommand = new DeleteCompanyProjectState({ id: current.id }, company.id);
                await dCommand.execute(ClientStore.commandStore);
            }
            catch (e) {
                console.error(e);
                DefaultErrorHandler.showDefaultErrorAlert(e);
            }
            LoadingEvents.instance.stopLoading();
        });
    };
    const removeProjectState = (current) => {
        Routing.instance.alert.post({
            text: I18n.m.getMessage('projectStateDeleteQuestion', { state: current.name }),
            buttons: [
                <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={removeProjectStateNow(current)} backgroundColor={ThemeManager.style.brandDanger}/>,
            ],
        });
    };
    const renderItem = ({ item, drag }) => {
        return (<View style={{
                paddingVertical: 4,
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}>
        <View style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
          <TouchableOpacity onPressIn={() => {
                movingItem.current = item;
                drag();
            }} style={{ width: 56, height: 56, justifyContent: 'center', alignItems: 'center' }}>
            <Icon icon="drag-horizontal" toolTip=""/>
          </TouchableOpacity>
          <View style={{
                height: 16,
                width: 16,
                alignSelf: 'center',
                borderRadius: 8,
                backgroundColor: item.color,
                marginRight: 8,
            }}/>
          <MaterialText centeredBox>{item.name}</MaterialText>
        </View>
        <View style={{
                flexDirection: 'row',
                width: 96,
                justifyContent: 'flex-end',
                alignItems: 'center',
                paddingRight: ThemeManager.style.contentPaddingValue,
            }}>
          <Icon toolTip={I18n.m.getMessage('edit')} onPress={() => {
                openEditDialog(item);
            }} icon="pencil-outline"/>
          <Icon toolTip={I18n.m.getMessage('delete')} icon="delete-outline" onPress={() => {
                removeProjectState(item);
            }}/>
        </View>
      </View>);
    };
    return (<View style={{
            width: '100%',
            maxWidth: '100%',
            paddingTop: ThemeManager.style.contentPaddingValue,
            alignSelf: 'center',
            paddingHorizontal: 8,
        }}>
      <View style={{ flexDirection: 'row', marginLeft: 8 + ThemeManager.style.contentPaddingValue }}>
        <MaterialText centeredBox type={MaterialTextTypes.H6}>
          {I18n.m.getMessage('projectState')}
          <Icon toolTip="" icon="information-outline" onPress={showInfo}/>
        </MaterialText>
      </View>
      <Card style={{ width: '100%', height: 'auto' }}>
        {listData.length > 0 ? (<DraggableFlatList containerStyle={{ width: '100%', maxHeight: props.size.contentHeight - 196 }} data={listData} renderItem={renderItem} keyExtractor={(item) => item.id} onDragEnd={changeOrder}/>) : (<View style={{
                width: '100%',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                padding: 16,
            }}>
            <Image style={{ width: '100%', height: 200, resizeMode: 'contain' }} resizeMode="contain" source={noGroupImg}/>
            <View style={{ width: '100%', marginTop: 16, alignItems: 'center' }}>
              <MaterialText centeredBox centeredText color={ThemeManager.style.black54} type={MaterialTextTypes.Subtitle2} strong>
                {I18n.m.getMessage('projectStateNoDataHint')}
              </MaterialText>
            </View>
          </View>)}
      </Card>
    </View>);
}
