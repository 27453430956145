import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { ChipDialogForm } from 'materialTheme/src/theme/components/chips/ChipDialogForm';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { ExcelFileDialog } from 'materialTheme/src/theme/components/file/ExcelFileDialog';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Platform, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { AddUserToGroup } from 'upmesh-core/src/client/commands/companies/AddUserToGroup';
import { CreateGroup } from 'upmesh-core/src/client/commands/companies/CreateGroup';
import { DeleteGroup } from 'upmesh-core/src/client/commands/companies/DeleteGroup';
import { GroupEntity } from 'upmesh-core/src/client/query/entities/GroupEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { ChangeDefaultGroup, } from 'upmesh-core/src/client/commands/companies/ChangeDefaultGroup';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { AddGroupToCompanyDialog } from './AddGroupToCompanyDialog';
import { ChangeGroupCompanyDialog } from './ChangeGroupCompanyDialog';
const noGroupImg = require('../../assets/img/no_types_or_crafts_or_tags.png');
export class CompanyGroupsView extends PureComponent {
    constructor(props) {
        super(props);
        this.myRole = () => {
            return CompanyUserInfo.me == null ? 'user' : CompanyUserInfo.me.role;
        };
        this.updateWrapper = async () => {
            if (this.updateTimeOut != null)
                clearTimeout(this.updateTimeOut);
            this.updateTimeOut = setTimeout(this.updateAll, 300);
        };
        this.updateAll = async () => {
            const { company } = this.props;
            const groups = await UpmeshClient.instance.modals.group.get({
                filter: `deleted ne true and companyId eq '${company.id}'`,
            });
            const companyUsers = await UpmeshClient.instance.modals.companyMember.get({
                filter: `companyId eq '${company.id}' and deleted ne true`,
            });
            const data = [];
            for (const group of groups) {
                data.push({
                    id: group.id,
                    groupName: this.getGroupName(group),
                    members: companyUsers.filter((user) => user.groupId === group.id).length,
                    entity: group,
                });
            }
            const companyMemberListItems = [];
            for (let i = 0; i < companyUsers.length; i += 1) {
                const companyUser = companyUsers[i];
                let fullName = `${companyUser.firstName} ${companyUser.lastName}`;
                if (companyUser.userId != null) {
                    try {
                        const user = await AuthClient.instance.modals.user.getById(companyUser.userId);
                        fullName = user.getFullName();
                    }
                    catch (e) {
                        console.debug('cant get user', e);
                    }
                }
                const userGroup = groups.find((elem) => elem.id === companyUser.groupId);
                companyMemberListItems.push({
                    data: companyUser.id,
                    title: fullName,
                    secondTextLine: this.getGroupName(userGroup),
                });
            }
            this.setState({ data, companyMemberListItems });
        };
        this.removeGroup = (group) => (_e) => {
            Routing.instance.alert.post({
                text: I18n.m.getMessage('companyRemoveGroupQuestion', { group: group.groupName }),
                buttons: [
                    <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                    <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={this.removeGroupNow(group.entity)} backgroundColor={ThemeManager.style.brandDanger}/>,
                ],
            });
        };
        this.removeGroupNow = (group) => (_e) => {
            LoadingEvents.instance.startLoading();
            Alert.instance?.close(async () => {
                try {
                    const remove = new DeleteGroup({}, group.id, CurrentUser.token);
                    await remove.execute(ClientStore.commandStore);
                }
                catch (e) {
                    console.error(e);
                    Routing.instance.alert.post({ text: DefaultErrorHandler.getDefaultErrorMessages(e, I18n.m) });
                }
                LoadingEvents.instance.stopLoading();
            });
        };
        this.openFab = () => {
            if (Fab.instance == null) {
                window.setTimeout(this.openFab, 100);
                return;
            }
            const permitted = this.myRole() === 'admin';
            if (Platform.OS === 'web') {
                Fab.instance.open({
                    fabIcon: 'plus',
                    fabIconOpen: 'close',
                    small: false,
                    fabColor: ThemeManager.style.brandPrimary,
                    fabColorOpen: ThemeManager.style.brandSecondary,
                    onPressFab: !permitted
                        ? () => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') })
                        : undefined,
                    fabActions: permitted
                        ? [
                            {
                                text: I18n.m.getMessage('companyAddGroupDialogTitle'),
                                icon: 'plus',
                                onPress: this.openAddDialog,
                            },
                            {
                                text: I18n.m.getMessage('companyExcelDialogTitle'),
                                icon: 'microsoft-excel',
                                onPress: this.openExcelDialog,
                            },
                        ]
                        : undefined,
                });
            }
            else {
                Fab.instance.open({
                    fabIcon: 'plus',
                    fabIconOpen: 'close',
                    small: false,
                    fabColor: ThemeManager.style.brandPrimary,
                    fabColorOpen: ThemeManager.style.brandSecondary,
                    onPressFab: permitted
                        ? this.openAddDialog
                        : () => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }),
                });
            }
        };
        this.openExcelDialog = () => {
            Dialog.instance?.open({
                closeOnTouchOutside: false,
                fullscreenResponsive: true,
                contentPadding: false,
                scrollable: false,
                showCloseButton: false,
                width: 1042,
                content: (<ExcelFileDialog saveFunction={this.saveFunction} generateExampleRecords={this.generateExamples} columns={[
                        { title: I18n.m.getMessage('companyExcelGroupNameHeader'), keyInData: 'Gruppe', style: { width: 200 } },
                    ]}/>),
            });
        };
        this.generateExamples = async () => {
            const { data } = this.state;
            if (data != null) {
                return data.map((element) => {
                    return { Gruppe: element.groupName };
                });
            }
            return [];
        };
        this.saveFunction = async (records) => {
            const { company } = this.props;
            const groupPromises = [];
            records.forEach((element) => {
                groupPromises.push(new CreateGroup({ groupName: element.Gruppe, companyId: company.id, foreignId: '' }).execute(ClientStore.commandStore));
            });
            await Promise.all(groupPromises);
        };
        this.openAddDialog = (_e) => {
            const { company } = this.props;
            Dialog.instance?.open({
                closeOnTouchOutside: false,
                content: <AddGroupToCompanyDialog company={company}/>,
                contentPadding: false,
                showCloseIcon: true,
                scrollable: false,
            });
        };
        this.openEditDialog = (group) => (_e) => {
            if (this.myRole() === 'admin') {
                const { company } = this.props;
                Dialog.instance?.open({
                    closeOnTouchOutside: false,
                    content: <ChangeGroupCompanyDialog group={group.entity} company={company}/>,
                    contentPadding: false,
                    showCloseIcon: true,
                    scrollable: true,
                });
            }
            else {
                Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') });
            }
        };
        this.setGroupAsDefault = (group) => (_e) => {
            if (this.myRole() === 'admin') {
                const { company } = this.props;
                const command = new ChangeDefaultGroup({ defaultGroupId: group.id }, company.id);
                command.execute(ClientStore.commandStore).catch((err) => {
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                });
            }
            else {
                Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') });
            }
        };
        this.openChangeMembersDialog = (_item) => (_e) => {
            const { companyMemberListItems } = this.state;
            for (const member of companyMemberListItems) {
                member.selected = member.secondTextLine === _item.groupName;
            }
            Dialog.instance?.open({
                content: (<ChipDialogForm key="MembersDialogForm" title={I18n.m.getMessage('companyGroupsEditMembers')} items={this.state.companyMemberListItems} onCancel={() => Dialog.instance?.close()} multiselect onSave={(e) => this.onSaveGroupMembers(e, _item.entity)} canAddNewChips={false} searchBarPlaceholder={I18n.m.getMessage('search')}/>),
                contentPadding: false,
                fullscreenResponsive: true,
            });
        };
        this.onSaveGroupMembers = async (items, group) => {
            const { company } = this.props;
            const companySettings = await UpmeshClient.instance.modals.companySettings.getById(company.id);
            const companyMember = await UpmeshClient.instance.modals.companyMember.get({
                filter: `companyId eq '${company.id}' and deleted ne true`,
            });
            const groupMember = [...companyMember.filter((elem) => elem.groupId === group.id)];
            const addToGroupPromises = [];
            const removeFromDefaultErrors = [];
            for (let i = 0; i < items.length; i += 1) {
                const current = companyMember.find((a) => a.id === items[i].data);
                if (current) {
                    const wasGroupMember = groupMember.findIndex((elem) => elem.id === current.id) > -1;
                    if (items[i].selected && !wasGroupMember) {
                        const command = new AddUserToGroup({ memberId: current.id }, group.id, CurrentUser.token);
                        addToGroupPromises.push(command.execute(ClientStore.commandStore));
                    }
                    else if (!items[i].selected && wasGroupMember) {
                        if (group.id === companySettings.defaultGroupId) {
                            removeFromDefaultErrors.push(items[i].title);
                        }
                        else {
                            const command = new AddUserToGroup({ memberId: current.id }, companySettings.defaultGroupId, CurrentUser.token);
                            addToGroupPromises.push(command.execute(ClientStore.commandStore));
                        }
                    }
                }
            }
            await Promise.all(addToGroupPromises);
            Dialog.instance?.close(() => {
                if (removeFromDefaultErrors.length > 0) {
                    Routing.instance.alert.post({
                        text: `${I18n.m.getMessage('companyGroupNeedGroup')}: ${removeFromDefaultErrors.join(', ')}`,
                    });
                }
            });
        };
        this.state = {
            myRole: this.myRole(),
            companyMemberListItems: [],
            data: [],
        };
    }
    componentDidMount() {
        const { company } = this.props;
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'CompanyGroupsView',
            readModelName: 'Group',
            callback: this.updateWrapper,
        });
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'CompanyGroupsViewMember',
            readModelName: 'Company',
            entityId: company.id,
            callback: this.updateWrapper,
        });
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'CompanyGroupsViewMembers',
            readModelName: 'CompanyMember',
            callback: this.updateWrapper,
        });
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'CompanyGroupsViewCSettings',
            readModelName: 'CompanySettings',
            callback: this.updateWrapper,
        });
        this.openFab();
        this.updateAll().catch((err) => console.debug(err));
    }
    getGroupName(group) {
        if (group == null)
            return '';
        const name = group.groupName === GroupEntity.getDefaultName() ? I18n.m.getMessage('companyGroupDefaultName') : group.groupName;
        const cSettings = CompanyUserInfo.companySettings;
        if (cSettings != null && cSettings.defaultGroupId === group.id) {
            return `${name} (${I18n.m.getMessage('default')})`;
        }
        return name;
    }
    componentWillUnmount() {
        UpmeshClient.eventDispatcher.detach('Group', 'CompanyGroupsView');
        UpmeshClient.eventDispatcher.detach('Company', 'CompanyGroupsViewMember');
        UpmeshClient.eventDispatcher.detach('CompanyMember', 'CompanyGroupsViewMembers');
        UpmeshClient.eventDispatcher.detach('CompanySettings', 'CompanyGroupsViewCSettings');
        if (Fab.instance != null)
            Fab.instance.close();
    }
    render() {
        const { company, size } = this.props;
        const { data, myRole } = this.state;
        if (!(myRole === 'admin' || myRole === 'user'))
            return null;
        const tableMaxHeight = size.contentHeight - ThemeManager.style.headerHeight - ThemeManager.style.getScreenRelativePixelSize(60);
        return (<View style={{
                width: '100%',
                maxWidth: '100%',
                paddingTop: ThemeManager.style.contentPaddingValue,
                alignSelf: 'center',
                paddingHorizontal: 8,
            }}>
        <View style={{ flexDirection: 'row', marginLeft: 8 + ThemeManager.style.contentPaddingValue }}>
          <MaterialText centeredBox type={MaterialTextTypes.H6}>
            {I18n.m.getMessage('groups')}
          </MaterialText>
        </View>
        <Card style={{ width: '100%', height: tableMaxHeight }}>
          <Table tableName="CompanyGroups" actionItemsLength={4} actions={myRole === 'admin'
                ? (_item) => [
                    {
                        icon: 'account-hard-hat',
                        onAction: this.openChangeMembersDialog,
                        toolTip: I18n.m.getMessage('companyAddUserDilaogButton'),
                    },
                    {
                        icon: 'pencil-outline',
                        onAction: this.openEditDialog,
                        toolTip: I18n.m.getMessage('edit'),
                    },
                    {
                        icon: 'group',
                        onAction: this.setGroupAsDefault,
                        toolTip: I18n.m.getMessage('groupSetDefault'),
                    },
                    {
                        icon: 'delete',
                        onAction: this.removeGroup,
                        toolTip: I18n.m.getMessage('delete'),
                        disabled: _item.id === company.id,
                    },
                ]
                : undefined} maxHeight={tableMaxHeight - 16} emptyTableImage={noGroupImg} emptyTableHint={I18n.m.getMessage('companyGroupNoDataHint')} emptyTableText={I18n.m.getMessage('companyGroupNoDataText')} data={data} sortBy="groupName" sortDirection="asc" columns={[
                {
                    title: I18n.m.getMessage('name'),
                    keyInData: 'groupName',
                    style: { width: 250 },
                    sortable: true,
                    dataType: 'string',
                },
                {
                    title: I18n.m.getMessage('quantity'),
                    keyInData: 'members',
                    style: { width: 100 },
                    sortable: true,
                    dataType: 'number',
                },
            ]}/>
        </Card>
      </View>);
    }
}
