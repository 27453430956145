import color from 'color';
import { OpenableChip } from 'materialTheme/src/theme/components/chips/OpenableChip';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { ChangeTicketStatus } from 'upmesh-core/src/client/commands/tickets/ChangeTicketStatus';
import { TicketStatus, TicketStatusColor } from 'upmesh-core/src/client/query/entities/TicketEntity';
import { I18n } from '../../../i18n/I18n';
export class TicketStatusChip extends PureComponent {
    constructor(props) {
        super(props);
        this.checkDisabled = async () => {
            const { ticket, project } = this.props;
            const statusList = TicketStatusChip.statusList(project);
            let numberOfDisables = 0;
            for (let i = 0; i < statusList.length; i += 1) {
                const c = new ChangeTicketStatus({ ticketStatus: statusList[i].onPressChipData }, ticket.id);
                let canI = false;
                try {
                    await c.canI();
                    canI = true;
                }
                catch (e) {
                    console.debug('no rights');
                }
                if (canI !== true) {
                    statusList[i].chipDisabled = true;
                    statusList[i].buttonDisabled = true;
                    statusList[i].textColor = ThemeManager.style.black54;
                    numberOfDisables += 1;
                }
            }
            this.setState({ statusList, disabledChip: numberOfDisables === statusList.length });
        };
        this.onChangeStatus = async (e) => {
            const { ticket } = this.props;
            ticket.ticketStatus = e;
            try {
                const c = new ChangeTicketStatus({ ticketStatus: ticket.ticketStatus }, ticket.id);
                await c.execute();
            }
            catch (err) {
                this.props.errorResult(err);
            }
        };
        this.state = { disabledChip: false, statusList: TicketStatusChip.statusList(props.project) };
    }
    componentDidMount() {
        this.checkDisabled().catch((err) => console.debug(err));
    }
    static statusList(project) {
        return [
            {
                title: I18n.m.getMessage('ticketsDetailsStateopen', {}, project.messagesOverride),
                onPressChipData: TicketStatus.open,
                backgroundColor: TicketStatusColor.getColorForStatus(TicketStatus.open),
                hoverColor: color(TicketStatusColor.getColorForStatus(TicketStatus.open)).darken(0.2).toString(),
                textColor: ThemeManager.style.black87,
                thumbnail: <View style={{ height: 16, width: 16, borderRadius: 8, backgroundColor: 'rgb(245, 124, 0)' }}/>,
            },
            {
                title: I18n.m.getMessage('ticketsDetailsStateprocessing', {}, project.messagesOverride),
                onPressChipData: TicketStatus.processing,
                backgroundColor: TicketStatusColor.getColorForStatus(TicketStatus.processing),
                hoverColor: color(TicketStatusColor.getColorForStatus(TicketStatus.processing)).darken(0.2).toString(),
                textColor: ThemeManager.style.black87,
                thumbnail: <View style={{ height: 16, width: 16, borderRadius: 8, backgroundColor: 'rgb(255,213,0)' }}/>,
            },
            {
                title: I18n.m.getMessage('ticketsDetailsStateclosed', {}, project.messagesOverride),
                onPressChipData: TicketStatus.closed,
                backgroundColor: TicketStatusColor.getColorForStatus(TicketStatus.closed),
                hoverColor: color(TicketStatusColor.getColorForStatus(TicketStatus.closed)).darken(0.2).toString(),
                textColor: ThemeManager.style.black87,
                thumbnail: <View style={{ height: 16, width: 16, borderRadius: 8, backgroundColor: 'rgb(0, 115, 255)' }}/>,
            },
            {
                title: I18n.m.getMessage('ticketsDetailsStatechecked', {}, project.messagesOverride),
                onPressChipData: TicketStatus.checked,
                backgroundColor: TicketStatusColor.getColorForStatus(TicketStatus.checked),
                hoverColor: color(TicketStatusColor.getColorForStatus(TicketStatus.checked)).darken(0.2).toString(),
                textColor: ThemeManager.style.black87,
                thumbnail: <View style={{ height: 16, width: 16, borderRadius: 8, backgroundColor: 'rgb(0, 205, 158)' }}/>,
            },
        ];
    }
    render() {
        const { labelStyle, ticket } = this.props;
        const { disabledChip, statusList } = this.state;
        return [
            <View key="statusLabel" style={labelStyle}>
        <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption}>
          {I18n.m.getMessage('ticketsDetailsState')}
        </MaterialText>
      </View>,
            <OpenableChip dialogTitle={I18n.m.getMessage('ticketsDetailsState')} accessibilityLabel="status" key={`status_${ticket.ticketStatus}`} elevation={40} selected={ticket.ticketStatus} onPressChip={this.onChangeStatus} widthOpen={256} textColor={ThemeManager.style.black87} chipsList={statusList} chipDisabled={disabledChip} thumbOnlyOnList/>,
        ];
    }
}
