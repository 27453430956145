import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Checkbox } from 'materialTheme/src/theme/components/Checkbox';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContainer, } from 'materialTheme/src/theme/components/dialog/DialogContainer';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { ScrollContainer } from 'materialTheme/src/theme/components/scroll/ScrollContainer';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { Switch } from 'materialTheme/src/theme/components/Switch';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { UploadingList } from 'materialTheme/src/theme/components/upload/UploadingList';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import React from 'react';
import { Keyboard, UIManager, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { CreatePlan } from 'upmesh-core/src/client/commands/plans/CreatePlan';
import { CreatePlanVersion } from 'upmesh-core/src/client/commands/plans/CreatePlanVersion';
import { DiscardPlanFile } from 'upmesh-core/src/client/commands/plans/DiscardPlanFile';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { PdfPage } from 'upmesh-core/src/pdf/PdfPage';
import { DateLocale } from 'upmesh-i18n/src/i18n/DateLocale';
import * as uuid from 'uuid';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { PlanUploadedThumbs } from './PlanUploadedThumbs';
class PDFinfoExtended extends PdfPage {
}
export class PlanUploadedPU extends DialogContainer {
    static getDerivedStateFromProps(_nextProps) {
        return { ...PlanUploadedPU.createSize() };
    }
    constructor(props) {
        super(props);
        this.title = I18n.m.getMessage('plansUploadedTitle');
        this.addAsNewVersion = (_value, pdfFileIndex) => {
            const { allPdfFiles, planTitleListAutoComplete } = this.state;
            allPdfFiles[pdfFileIndex].addAsNewVersion = !allPdfFiles[pdfFileIndex].addAsNewVersion;
            allPdfFiles[pdfFileIndex].error = this.getTitleError(allPdfFiles[pdfFileIndex], planTitleListAutoComplete, allPdfFiles);
            this.setState({ allPdfFiles }, () => this.forceUpdate());
        };
        this.planfilesChanged = () => {
            this.checkData().catch((err) => console.debug(err));
        };
        this.discard = async (pdfFile) => {
            Routing.instance.alert.post({
                text: I18n.m.getMessage('planUploadedDeleteQuestion'),
                buttons: [
                    <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                    <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={() => this.discardNow(pdfFile)} backgroundColor={ThemeManager.style.brandDanger}/>,
                ],
            });
        };
        this.discardNow = (pdfFile) => {
            Alert.instance?.close(async () => {
                if (pdfFile != null && pdfFile.id != null) {
                    const discard = new DiscardPlanFile({
                        deviceId: pdfFile.deviceId,
                        userId: pdfFile.userId,
                        projectId: pdfFile.projectId,
                        processed: true,
                    }, pdfFile.id);
                    try {
                        await discard.execute();
                    }
                    catch (e) {
                        Routing.instance.alert.post({
                            text: `${I18n.m.getMessage('plansErrorAtDiscardAction')} ${DefaultErrorHandler.getDefaultErrorMessages(e)}`,
                        });
                        ErrorReporter.sendReport({ subject: `discard now error ${e.name} ${e.message}`, data: e, type: 'warn' });
                    }
                }
            });
        };
        this.focusNotesField = () => {
            if (this._notesTextField != null) {
                this._notesTextField.focus();
            }
        };
        this.onChangeTitle = (pdfFileIndex) => (text) => {
            this.setState((prevState) => {
                const { planTitleListAutoComplete } = prevState;
                const planTitleExists = planTitleListAutoComplete.includes(text);
                const { allPdfFiles } = prevState;
                allPdfFiles[pdfFileIndex].title = text;
                allPdfFiles[pdfFileIndex].planTitleExists = planTitleExists;
                allPdfFiles[pdfFileIndex].error = this.getTitleError(allPdfFiles[pdfFileIndex], planTitleListAutoComplete, allPdfFiles);
                return {
                    allPdfFiles: [...allPdfFiles],
                    errorMessage: '',
                };
            });
        };
        this.abortSubmit = async () => {
            Alert.instance?.close(() => {
                this.setState({ isLoading: false });
            });
        };
        this.onCheckChange = (pdfFileIndex) => (value) => {
            this.setState((prevState) => {
                const { planTitleListAutoComplete } = prevState;
                const allPdfFiles = [...prevState.allPdfFiles];
                const pdfFile = { ...allPdfFiles[pdfFileIndex] };
                let pdfFilesToDiscard = [...prevState.pdfFilesToDiscard];
                let { numberOfCheckedPdfFiles } = prevState;
                pdfFile.planTitleExists = pdfFile.title != null ? planTitleListAutoComplete.includes(pdfFile.title) : false;
                pdfFile.checked = value;
                pdfFile.error = this.getTitleError(pdfFile, this.state.planTitleListAutoComplete, this.state.allPdfFiles);
                allPdfFiles[pdfFileIndex] = pdfFile;
                let canSubmit = false;
                for (let i = 0; i < allPdfFiles.length; i += 1) {
                    if (allPdfFiles[i].checked) {
                        canSubmit = true;
                        break;
                    }
                }
                if (value) {
                    pdfFilesToDiscard.push(pdfFile);
                    numberOfCheckedPdfFiles += 1;
                }
                else {
                    pdfFilesToDiscard = pdfFilesToDiscard.filter((pdf) => pdf.fileIdWithExtension !== pdfFile.fileIdWithExtension);
                    numberOfCheckedPdfFiles -= 1;
                }
                return { allPdfFiles, pdfFilesToDiscard, canSubmit, numberOfCheckedPdfFiles };
            });
        };
        this.submit = () => {
            this.setState({
                isLoading: true,
            }, () => {
                this.submitNow().catch((err) => console.debug(err));
            });
        };
        this.submitNow2 = (checked) => async (_e) => {
            Alert.instance?.close(async () => {
                try {
                    Routing.instance.routeBlock.post(true);
                    const { allPdfFiles, pdfFilesToDiscard, project } = this.state;
                    let counted = 0;
                    if (checked > 0 && project != null) {
                        for (let i = 0; i < allPdfFiles.length; i += 1) {
                            const pvId = uuid.v1();
                            if (allPdfFiles[i].checked) {
                                let planId;
                                let version = 1;
                                if (allPdfFiles[i].addAsNewVersion !== true) {
                                    const c = new CreatePlan({
                                        title: allPdfFiles[i].title,
                                        activatedBy: CurrentUser.entity.id,
                                        activatedOn: new Date(),
                                        activePlanId: pvId,
                                        projectId: project.id,
                                    });
                                    try {
                                        await c.execute();
                                    }
                                    catch (e) {
                                        ErrorReporter.sendReport({ subject: 'cant create Plan', data: e, type: 'warn' });
                                    }
                                    planId = c.entityId;
                                }
                                else {
                                    const plans = await UpmeshClient.instance.modals.plan.get({
                                        filter: `projectId eq '${project.id}' and title eq '${allPdfFiles[i].title}' and deleted ne true`,
                                        top: 1,
                                    });
                                    planId = plans[0].id;
                                    version =
                                        (await UpmeshClient.instance.modals.planVersion.count(`projectId eq '${project.id}' and planId eq '${planId}'`)) + 1;
                                }
                                const cv = new CreatePlanVersion({
                                    planId,
                                    version,
                                    projectId: project.id,
                                    fileId: allPdfFiles[i].fileIdWithExtension,
                                    orgFilename: allPdfFiles[i].orgFileName,
                                    pageHeight: allPdfFiles[i].rotate === 90 || allPdfFiles[i].rotate === 270
                                        ? allPdfFiles[i].pageWidth
                                        : allPdfFiles[i].pageHeight,
                                    pageWidth: allPdfFiles[i].rotate === 90 || allPdfFiles[i].rotate === 270
                                        ? allPdfFiles[i].pageHeight
                                        : allPdfFiles[i].pageWidth,
                                    rotate: allPdfFiles[i].rotate,
                                }, pvId);
                                counted += 1;
                                this.setState({
                                    isLoadingText: `${counted}/${checked} \n ${I18n.m.getMessage('createPlanVersion', {
                                        planTitle: allPdfFiles[i].title,
                                    })}`,
                                });
                                await new Promise((resolve, reject) => {
                                    UpmeshClient.eventDispatcher.attach({
                                        readModelName: 'PlanVersion',
                                        attachKey: `newPV_${cv.entityId}`,
                                        callback: (pvs) => {
                                            const pv = pvs.entities.get(cv.entityId);
                                            if (pv != null && pv.entity.id === cv.entityId) {
                                                UpmeshClient.eventDispatcher.detach('PlanVersion', `newPV_${cv.entityId}`);
                                                resolve(true);
                                            }
                                        },
                                    });
                                    cv.execute().catch((e) => {
                                        ErrorReporter.sendReport({
                                            subject: `cant create plan version ${e.name} ${e.message}`,
                                            data: e,
                                            type: 'warn',
                                        });
                                        reject(e);
                                    });
                                });
                            }
                        }
                        for (let i = 0; i < pdfFilesToDiscard.length; i += 1) {
                            const discard = new DiscardPlanFile({
                                deviceId: pdfFilesToDiscard[i].deviceId,
                                userId: pdfFilesToDiscard[i].userId,
                                projectId: pdfFilesToDiscard[i].projectId,
                                processed: true,
                            }, pdfFilesToDiscard[i].id);
                            try {
                                await discard.execute();
                            }
                            catch (e) {
                                ErrorReporter.sendReport({ subject: 'submit now2 e', data: e, type: 'warn' });
                            }
                        }
                        Routing.instance.routeBlock.post(false);
                        this.setState({ isLoading: false, isLoadingText: '' }, () => {
                            requestAnimationFrame(() => {
                                Routing.instance.closeDialog(true)(null);
                            });
                        });
                    }
                    else {
                        this.setState({
                            isLoading: false,
                            errorMessage: I18n.m.getMessage('planUploadNothingToSave'),
                        });
                    }
                }
                catch (err) {
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                    this.setState({
                        isLoading: false,
                    });
                }
            });
        };
        this.onRotateLeft = (pdfFileIndex) => {
            const { allPdfFiles } = this.state;
            let { rotate } = allPdfFiles[pdfFileIndex];
            if (rotate == null || typeof rotate !== 'number') {
                rotate = 0;
            }
            rotate -= 90;
            if (rotate < 0) {
                rotate = 360 + rotate;
            }
            if (rotate >= 360) {
                rotate = 0;
            }
            allPdfFiles[pdfFileIndex].rotate = rotate;
            this.setState({ allPdfFiles, errorMessage: '' }, () => this.forceUpdate());
        };
        this.onRotateRight = (pdfFileIndex) => {
            const { allPdfFiles } = this.state;
            let { rotate } = allPdfFiles[pdfFileIndex];
            if (rotate == null || typeof rotate !== 'number') {
                rotate = 0;
            }
            rotate += 90;
            if (rotate < 0) {
                rotate = 360 + rotate;
            }
            if (rotate >= 360) {
                rotate = 0;
            }
            allPdfFiles[pdfFileIndex].rotate = rotate;
            this.setState({ allPdfFiles, errorMessage: '' }, () => this.forceUpdate());
        };
        this.openRotateMenu = (pdfFileIndex) => (e) => {
            UIManager.measureInWindow(e.nativeEvent.target, (x, y, _width, height) => {
                const client = {
                    x,
                    y,
                    height,
                    width: 256,
                };
                Menu.instance?.open({
                    client,
                    items: [
                        {
                            text: I18n.m.getMessage('rotateLeft'),
                            onPress: () => this.onRotateLeft(pdfFileIndex),
                        },
                        {
                            text: I18n.m.getMessage('rotateRight'),
                            onPress: () => this.onRotateRight(pdfFileIndex),
                        },
                    ],
                });
            });
        };
        this.state = {
            closed: false,
            open: false,
            project: undefined,
            isLoading: false,
            isLoadingText: '',
            isCheckingData: true,
            pdfInfos: [],
            pdfFilesToDiscard: [],
            allPdfFiles: [],
            page: 0,
            errorMessage: '',
            planTitleList: [],
            planTitleListAutoComplete: [],
            planTitleExists: false,
            canSubmit: false,
            numberOfCheckedPdfFiles: 0,
            overlayProps: {
                showOverlay: true,
                animationDuration: 195,
            },
            focusedInputIndex: undefined,
            ...PlanUploadedPU.createSize(),
        };
        this.flatListRef = null;
    }
    componentDidMount() {
        super.componentDidMount();
        this.checkData().catch((err) => console.debug(err));
        UpmeshClient.eventDispatcher.attach({
            readModelName: 'PlanFiles',
            attachKey: 'plansUploaded',
            callback: this.planfilesChanged,
        });
    }
    componentDidUpdate(_prevProps, prevState) {
        if (prevState.isLoading === this.state.isLoading &&
            prevState.errorMessage != null &&
            prevState.errorMessage.length > 0 &&
            this.state.errorMessage != null &&
            this.state.errorMessage.length > 0 &&
            this.state.errorMessage === prevState.errorMessage) {
            this.setState({ errorMessage: '' });
        }
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        UpmeshClient.eventDispatcher.detach('PlanFiles', 'plansUploaded');
        if (Keyboard != null) {
            Keyboard.dismiss();
        }
    }
    renderSpinner() {
        const { isLoadingText } = this.state;
        return this.renderModal(<View style={{
                width: '100%',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                padding: 16,
                height: this.state.maxHeight,
                minHeight: 192,
                overflow: 'hidden',
            }}>
        <Spinner />
        <MaterialText fixedWidth="100%" centeredText>
          {isLoadingText}
        </MaterialText>
      </View>);
    }
    renderItems() {
        const { allPdfFiles } = this.state;
        const items = [];
        let hasErrors = false;
        for (let index = 0; index < allPdfFiles.length; index += 1) {
            const item = allPdfFiles[index];
            items.push(this.renderItem({ item, index }));
            if (item.error)
                hasErrors = true;
        }
        return { hasErrors, renderedItems: items };
    }
    render() {
        const { closed, project } = this.state;
        if (closed) {
            return null;
        }
        const { pdfInfos, isLoading, isCheckingData, canSubmit } = this.state;
        if (isLoading) {
            return this.renderSpinner();
        }
        const items = this.renderItems();
        if (pdfInfos != null && pdfInfos.length > 0) {
            return this.renderModal(<View style={{ width: '100%', position: 'relative', height: this.state.maxHeight, overflow: 'hidden' }}>
          <View style={{
                    alignItems: 'flex-start',
                    alignContent: 'flex-start',
                    justifyContent: 'flex-start',
                    minHeight: 50,
                    padding: 0,
                    marginBottom: 10,
                }}>
            <ScrollContainer style={{
                    height: this.state.maxHeight - ThemeManager.style.getScreenRelativePixelSize(56),
                    width: '100%',
                    maxWidth: '100%',
                }}>
              <View style={{
                    backgroundColor: 'white',
                    width: '100%',
                    zIndex: 2,
                    padding: ThemeManager.style.contentPaddingValue,
                }}>
                <MaterialText type={MaterialTextTypes.H6}>{I18n.m.getMessage('planSiteReleasePlans')}</MaterialText>
                <UploadingList type={`${project?.id}_plan_upload`}/>
                {items.renderedItems}
              </View>
            </ScrollContainer>
          </View>
          <DialogActions onBottom>
            <View key="1" style={{ alignSelf: 'center' }}>
              <ContainedButton full={false} onPress={Routing.instance.closeDialog(true)} title={I18n.m.getMessage('abort')} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
            </View>

            <View>
              <ContainedButton key="2" disabled={!canSubmit || items.hasErrors} onPress={this.submit} title={`${I18n.m.getMessage('release')} (${this.state.numberOfCheckedPdfFiles})`} textColor="#FFFFFF" backgroundColor={ThemeManager.style.brandPrimary}/>
            </View>
          </DialogActions>
        </View>);
        }
        return this.renderModal(<View style={{
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                padding: 16,
                height: this.state.maxHeight,
            }}>
        {isCheckingData ? (<View>
            <Spinner />
          </View>) : (<View style={{ width: '100%' }}>
            <UploadingList type={`${project?.id}_plan_upload`}/>
            <MaterialText centeredText fixedWidth="100%">
              {I18n.m.getMessage('plansUploadedNoPDFsFound')}
            </MaterialText>
          </View>)}
        <View style={{
                position: 'absolute',
                bottom: 16,
                right: 16,
                flexDirection: 'row',
                alignItems: 'flex-end',
            }}>
          <ContainedButton key="1" full={false} onPress={Routing.instance.closeDialog(true)} title={I18n.m.getMessage('close')} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
        </View>
      </View>);
    }
    async checkData() {
        await DateLocale.waitFor(() => UpmeshClient.instance.ready);
        let project;
        if (this.props.data && this.props.data.projectId && this.props.data.projectId.length > 0) {
            const catched = await DefaultErrorHandler.getProjectErrors(this.props.data.projectId);
            if (catched) {
                this.setState({
                    isCheckingData: false,
                    isLoading: false,
                }, this.forceUpdate);
                return;
            }
            project = await UpmeshClient.instance.modals.project.getById(this.props.data.projectId);
            const pdfInfos = await UpmeshClient.instance.modals.planFiles.get({
                filter: `projectId eq '${project.id}' and uploaded eq true and processed ne true`,
                orderby: `orgFileName ASC`,
            });
            const titles = await UpmeshClient.instance.modals.plan.get({
                filter: `projectId eq '${project.id}' and deleted ne true`,
            });
            const planTitleList = [];
            const planTitleListAutoComplete = [];
            for (let i = 0; i < titles.length; i += 1) {
                const plan = titles[i];
                planTitleList.push({ id: plan.id, title: plan.title });
                planTitleListAutoComplete.push(plan.title);
            }
            if (pdfInfos != null) {
                if (pdfInfos.length === 0) {
                    this.setState({
                        project,
                        isCheckingData: false,
                        isLoading: false,
                        allPdfFiles: [],
                        pdfInfos: [],
                    }, this.forceUpdate);
                }
                else {
                    const allPdfFiles = [];
                    for (let i = 0; i < pdfInfos.length; i += 1) {
                        const allPdfFilesExtended = pdfInfos[i].pages;
                        allPdfFilesExtended.forEach((pdfFile) => {
                            let planTitle = pdfInfos[i].orgFileName.slice(0, Math.min(pdfInfos[i].orgFileName.length - 4, 100));
                            if (pdfInfos[i].pages.length > 1) {
                                planTitle = `${planTitle}(${pdfFile.page + 1})`;
                            }
                            pdfFile['title'] = planTitle;
                            pdfFile['orgFileName'] = pdfInfos[i].orgFileName;
                            pdfFile['planTitleExists'] = false;
                            pdfFile['deviceId'] = pdfInfos[i].deviceId;
                            pdfFile['userId'] = pdfInfos[i].userId;
                            pdfFile['projectId'] = pdfInfos[i].projectId;
                            pdfFile['id'] = pdfInfos[i].id;
                            pdfFile['checked'] = true;
                            pdfFile['planTitleExists'] = pdfFile.title != null && planTitleListAutoComplete.includes(pdfFile.title);
                            pdfFile['error'] = this.getTitleError(pdfFile, planTitleListAutoComplete, allPdfFiles);
                            pdfFile['rotate'] = this.getCorrectRotation(pdfInfos[i].id);
                        });
                        allPdfFiles.push(...allPdfFilesExtended);
                    }
                    this.setState({
                        canSubmit: true,
                        project,
                        allPdfFiles,
                        pdfInfos,
                        planTitleListAutoComplete,
                        planTitleList,
                        numberOfCheckedPdfFiles: allPdfFiles.length,
                        pdfFilesToDiscard: allPdfFiles,
                    });
                }
            }
        }
        else {
            Routing.instance.goTo('/', true);
        }
    }
    getCorrectRotation(pdfId) {
        const { allPdfFiles } = this.state;
        for (const file of allPdfFiles) {
            if (file.id === pdfId) {
                return file.rotate;
            }
        }
        return 0;
    }
    static createSize() {
        let fullscreen;
        let width = '100%';
        let height = ResizeEvent.current.contentHeight * 0.9;
        let maxWidth = 768;
        let maxHeight = ResizeEvent.current.contentHeight * 0.9;
        const isMobile = ResizeEvent.current.windowWidth <= ThemeManager.style.breakpointM;
        if (isMobile) {
            fullscreen = true;
            width = maxWidth = ResizeEvent.current.contentWidth;
            height = maxHeight = ResizeEvent.current.contentHeight;
        }
        const x = {
            fullscreen,
            width,
            height,
            maxWidth,
            maxHeight,
            isMobile,
            currentKeyboardHeight: ResizeEvent.current.currentKeyboardHeight,
        };
        return x;
    }
    getTitleError(forPage, planTitleListAutoComplete, allPdfFiles) {
        const planTitleExists = forPage.title != null ? planTitleListAutoComplete.includes(forPage.title) : false;
        if (forPage.checked) {
            if (forPage.title == null || forPage.title.length === 0) {
                return I18n.m.getMessage('planUploadPlanTitleRequired');
            }
            if (forPage.title.length > 100) {
                return I18n.m.getMessage('stringToLong');
            }
            if (planTitleExists && !forPage.addAsNewVersion) {
                return I18n.m.getMessage('planUploadPlanTitleAlready');
            }
            for (let i = 0; i < allPdfFiles.length; i += 1) {
                if (allPdfFiles[i].checked && allPdfFiles[i] !== forPage && allPdfFiles[i].title === forPage.title) {
                    return I18n.m.getMessage('planUploadPlanTitleAlreadyInOtherPage', { page: i + 1 });
                }
            }
        }
        return '';
    }
    renderAddNewVersion(pdfFile, pdfFileIndex) {
        if (!pdfFile.planTitleExists || !pdfFile.checked) {
            return null;
        }
        const addAsNewVersion = pdfFile.addAsNewVersion ? pdfFile.addAsNewVersion : false;
        return (<View style={{
                flexDirection: 'row',
            }}>
        <Switch key={`${pdfFile.fileIdWithExtension}_${addAsNewVersion}`} value={addAsNewVersion} onPress={(value) => this.addAsNewVersion(value, pdfFileIndex)}/>
        <MaterialText centeredBox>{I18n.m.getMessage('newPlanVersion')}</MaterialText>
      </View>);
    }
    async submitNow() {
        const { allPdfFiles } = this.state;
        let checked = 0;
        for (let i = 0; i < allPdfFiles.length; i += 1) {
            if (allPdfFiles[i].checked) {
                allPdfFiles[i].error = this.getTitleError(allPdfFiles[i], this.state.planTitleListAutoComplete, this.state.allPdfFiles);
                if (allPdfFiles[i]?.error !== undefined && allPdfFiles[i]?.error.length > 0) {
                    Routing.instance.alert.post({ text: I18n.m.getMessage('planUploadedErrorOnSubmit') });
                    this.setState({ isLoading: false });
                    return;
                }
                checked += 1;
            }
        }
        if (checked === allPdfFiles.length) {
            this.submitNow2(checked)().catch((err) => console.debug('cant submit on PlanUploadedPU', err));
        }
        else {
            Routing.instance.alert.post({
                text: I18n.m.getMessage('planUploadedQuestionAfterSubmit'),
                buttons: [
                    <ContainedButton key="no" textColor={ThemeManager.style.brandDanger} title={I18n.m.getMessage('no')} onPress={this.abortSubmit} backgroundColor="transparent"/>,
                    <ContainedButton key="yes" title={I18n.m.getMessage('yes')} onPress={this.submitNow2(checked)} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
                ],
            });
        }
    }
    renderItem({ item, index }) {
        const presentPdfFile = item;
        const previousPdfFile = this.state.allPdfFiles[index - 1];
        const nextPdfFile = this.state.allPdfFiles[index + 1];
        let shouldHaveTitle;
        let shouldHaveSectionBorder;
        if (previousPdfFile === undefined) {
            shouldHaveTitle = true;
        }
        else if (presentPdfFile.id !== previousPdfFile.id) {
            shouldHaveTitle = true;
        }
        else {
            shouldHaveTitle = false;
        }
        if (nextPdfFile == null) {
            shouldHaveSectionBorder = true;
        }
        else if (presentPdfFile.id !== nextPdfFile.id) {
            shouldHaveSectionBorder = true;
        }
        else {
            shouldHaveSectionBorder = false;
        }
        return (<View key={`item_${index}_${item.id}`} style={{
                width: '100%',
            }}>
        {shouldHaveTitle && (<View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingTop: 8,
                    paddingBottom: 8,
                    overflow: 'visible',
                }}>
            <View style={{
                    maxWidth: '90%',
                }}>
              <MaterialText>{item.orgFileName}</MaterialText>
            </View>
            <Icon toolTip={I18n.m.getMessage('deletePdf')} icon="delete" onPress={() => this.discard(item)}/>
          </View>)}
        {this.renderPdfPage(item, index, shouldHaveSectionBorder)}
        {shouldHaveSectionBorder && !(this.state.allPdfFiles.length - 1 === index && nextPdfFile === undefined) && (<View style={{
                    height: 8,
                    borderBottomColor: ThemeManager.style.borderColor,
                    borderBottomWidth: 1,
                }}/>)}
      </View>);
    }
    renderPdfPage(pdfFile, pdfFileIndex, shouldHaveFileBorder) {
        const planRotation = pdfFile.rotate != null ? pdfFile.rotate : 0;
        const disabled = !pdfFile.checked;
        const { planTitleListAutoComplete } = this.state;
        if (this.state.isMobile) {
            return (<View style={{
                    borderBottomColor: !shouldHaveFileBorder ? ThemeManager.style.borderColor : '#fff',
                    borderBottomWidth: 1,
                    marginBottom: !shouldHaveFileBorder ? 24 : 0,
                }}>
          <View key={`container_${pdfFile.fileIdWithExtension}`} style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}>
            <View style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: 220,
                }}>
              <Checkbox key={`checkbox_${pdfFile.fileIdWithExtension}`} value={pdfFile.checked} onChange={this.onCheckChange(pdfFileIndex)}/>
              <PlanUploadedThumbs key={pdfFile.fileIdWithExtension} rotation={planRotation} fileId={pdfFile.fileIdWithExtension} imageWidth={170} imageHeight={96} margin={false}/>
            </View>
            <Icon toolTip={I18n.m.getMessage('rotate')} icon="dots-vertical" onPress={this.openRotateMenu(pdfFileIndex)}/>
          </View>
          <FormInputFilled maxLength={100} key={`title_${pdfFile.fileIdWithExtension}`} ref={(_titleTextField) => {
                    this._titleTextField = _titleTextField;
                }} labelText={I18n.m.getMessage('planUploadPlanTitle')} onChange={this.onChangeTitle(pdfFileIndex)} initValue={pdfFile.title} error={pdfFile.error != null && pdfFile.error.length > 0} onSubmitEditing={this.focusNotesField} returnKeyType="next" helperText={pdfFile.error} maxAutoCompleteHeight={70} disabled={disabled} autoCompleteList={planTitleListAutoComplete}/>
          {this.renderAddNewVersion(pdfFile, pdfFileIndex)}
        </View>);
        }
        return (<View key={`containerL_${pdfFile.fileIdWithExtension}`} style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: 128,
            }}>
        <View style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: 216,
            }}>
          <Checkbox key={`checkbox_${pdfFile.fileIdWithExtension}`} value={pdfFile.checked} onChange={this.onCheckChange(pdfFileIndex)}/>
          <PlanUploadedThumbs key={pdfFile.fileIdWithExtension} rotation={planRotation} fileId={pdfFile.fileIdWithExtension} imageWidth={170} imageHeight={96} margin={false}/>
        </View>
        <View style={{
                width: 484,
                minHeight: 112,
                alignItems: 'center',
                alignContent: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginLeft: undefined,
                borderBottomColor: !shouldHaveFileBorder ? ThemeManager.style.borderColor : '#fff',
                borderBottomWidth: 1,
                paddingBottom: 8,
            }}>
          <View style={{ width: 444 }}>
            <View style={{ width: 444 }}>
              <FormInputFilled maxLength={100} accessibilityLabel={`title_${pdfFile.fileIdWithExtension}`} key={`title_${pdfFile.fileIdWithExtension}`} ref={(_titleTextField) => {
                this._titleTextField = _titleTextField;
            }} labelText={I18n.m.getMessage('planUploadPlanTitle')} onChange={this.onChangeTitle(pdfFileIndex)} initValue={pdfFile.title} error={pdfFile.error != null && pdfFile.error.length > 0} onSubmitEditing={this.focusNotesField} returnKeyType="next" helperText={pdfFile.error} maxAutoCompleteHeight={70} disabled={disabled} fieldWidth={444} autoCompleteList={planTitleListAutoComplete}/>
            </View>
            {this.renderAddNewVersion(pdfFile, pdfFileIndex)}
          </View>
          <View style={{
                height: '100%',
                width: 36,
                justifyContent: 'center',
                alignSelf: 'center',
            }}>
            <Icon toolTip={I18n.m.getMessage('rotate')} icon="dots-vertical" onPress={this.openRotateMenu(pdfFileIndex)}/>
          </View>
        </View>
      </View>);
    }
}
PlanUploadedPU.defaultProps = {
    data: null,
};
