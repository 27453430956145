var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Divider = Divider;
var _react = _interopRequireDefault(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _ThemeManager = require("../../ThemeManager");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function Divider(props) {
  return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
    style: Object.assign({}, props.style, {
      paddingBottom: _ThemeManager.ThemeManager.style.contentPaddingValue / 2,
      height: 0,
      width: '100%',
      borderBottomWidth: _ThemeManager.ThemeManager.style.borderWidth,
      borderBottomColor: props.color || _ThemeManager.ThemeManager.style.borderColor,
      marginBottom: _ThemeManager.ThemeManager.style.contentPaddingValue / 2
    })
  });
}