var _a;
import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { Measurement } from 'materialTheme/src/theme/components/utils/Measurement';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React from 'react';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { ChangeAbsence } from 'upmesh-core/src/client/commands/companies/absence/ChangeAbsence';
import { ChangeExtraPayTracking } from 'upmesh-core/src/client/commands/companies/extrapay/ChangeExtraPayTracking';
import { ChangeExtraPayTrackingStatus } from 'upmesh-core/src/client/commands/companies/extrapay/ChangeExtraPayTrackingStatus';
import { DeleteExtraPayTracking } from 'upmesh-core/src/client/commands/companies/extrapay/DeleteExtraPayTracking';
import { ChangeTimeTracking, } from 'upmesh-core/src/client/commands/companies/timetracking/ChangeTimeTracking';
import { DeleteTimeTracking } from 'upmesh-core/src/client/commands/companies/timetracking/DeleteTimeTracking';
import { LockTimeTracking } from 'upmesh-core/src/client/commands/companies/timetracking/LockTimeTracking';
import { UnlockTimeTracking } from 'upmesh-core/src/client/commands/companies/timetracking/UnlockTimeTracking';
import { TimeTrackingEntity } from 'upmesh-core/src/client/query/entities/TimeTrackingEntity';
import { CombinedExtraPayAndTimeTrackingEntity, CombinedExtraPayAndTimeTrackingFilter, } from 'upmesh-core/src/client/query/filter/CombinedExtraPayAndTimeTrackingFilter';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { SUPPORTED_EXPORTS } from 'upmesh-core/src/server/webserver/commands/timetracking/ExportInformation';
import { I18n } from '../../i18n/I18n';
import { openAbsenceCreationChangeDialog } from '../absences/AbsenceCreationChangeDialog';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { AddOrChangeExtraPayTrackDialog } from '../extrapay/AddOrChangeExtraPayTrackDialog';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { ChangeTimeTrackDialog } from './ChangeTimeTrackDialog';
import { TimeTrackingDownload } from './TimeTrackingDownload';
export class CombinedExtraPayTrackingDialogsControl {
}
_a = CombinedExtraPayTrackingDialogsControl;
CombinedExtraPayTrackingDialogsControl.openMenu = (t) => (e) => {
    Measurement.measure(e.currentTarget)
        .then(async (s) => {
        const client = {
            x: s.pageX,
            height: s.height,
            y: s.pageY > ThemeManager.style.statusBarHeight ? s.pageY : s.y,
            width: 256,
        };
        const items = [
            {
                text: I18n.m.getMessage('exportAsCSV'),
                onPress: CombinedExtraPayTrackingDialogsControl.downloadEntry(t),
                thumbnail: { thumbnail: <Icon icon="export" toolTip=""/>, width: 24 },
            },
        ];
        const isPayRol = !!CompanyUserInfo.me?.payroll &&
            !(t.type === 'TimeTrackingEntity' && t.orgEntry.absenceId != null);
        let canEdit = isPayRol;
        if (!canEdit) {
            try {
                if (t.type === 'TimeTrackingEntity') {
                    if (t.orgEntry.absenceId != null) {
                        canEdit =
                            (await new ChangeAbsence({ ...t.orgEntry }, t.orgEntry.absenceId).canI()) ===
                                true;
                    }
                    else
                        canEdit =
                            (await new ChangeTimeTracking({ ...t.orgEntry, changeTime: new Date() }, t.id).canI()) === true;
                }
                else {
                    canEdit = (await new ChangeExtraPayTracking({ ...t.orgEntry }, t.id).canI()) === true;
                }
            }
            catch (e) {
                console.debug('cant edit', e);
            }
        }
        if (canEdit) {
            items.unshift({
                text: I18n.m.getMessage('edit'),
                onPress: CombinedExtraPayTrackingDialogsControl.editEntry(t),
                thumbnail: { thumbnail: <Icon icon="pencil-outline" toolTip=""/>, width: 24 },
            });
        }
        if (t.type === 'TimeTrackingEntity' && t.ends == null) {
            items.unshift({
                text: I18n.m.getMessage('timeTrackingStopTimer'),
                onPress: CombinedExtraPayTrackingDialogsControl.stopTiming(t),
                thumbnail: { thumbnail: <Icon icon="stop-circle-outline" toolTip=""/>, width: 24 },
            });
        }
        if (isPayRol && (t.status === 'Locked' || t.status === 'Exported')) {
            items.push({
                text: I18n.m.getMessage('timeTrackingUnlock'),
                onPress: CombinedExtraPayTrackingDialogsControl.unlock(t),
                thumbnail: { thumbnail: <Icon icon="update" toolTip=""/>, width: 24 },
            });
        }
        else if (isPayRol) {
            items.push({
                text: I18n.m.getMessage('timeTrackingLock'),
                onPress: CombinedExtraPayTrackingDialogsControl.lock(t),
                thumbnail: { thumbnail: <Icon icon="clock-check-outline" toolTip=""/>, width: 24 },
            });
        }
        if (canEdit && t.orgEntry.absenceId == null) {
            items.push({
                text: I18n.m.getMessage('delete'),
                onPress: CombinedExtraPayTrackingDialogsControl.deleteEntry(t),
                thumbnail: { thumbnail: <Icon icon="delete-outline" toolTip=""/>, width: 24 },
            });
        }
        Menu.instance?.open({
            client,
            items,
        });
    })
        .catch((err) => console.debug(err));
};
CombinedExtraPayTrackingDialogsControl.downloadEntry = (t) => () => {
    Menu.instance?.close();
    TimeTrackingDownload.downloadCSV([{ id: t.id, type: t.type }], false, SUPPORTED_EXPORTS.csv).catch((e) => {
        DefaultErrorHandler.showDefaultErrorAlert(e);
    });
};
CombinedExtraPayTrackingDialogsControl.stopTiming = (t, openEditDialog = false) => async (_e) => {
    Menu.instance?.close();
    if (openEditDialog) {
        const entry = await UpmeshClient.instance.modals.timeTracking.getById(t.id);
        entry.ends = new Date();
        const convert = await CombinedExtraPayAndTimeTrackingEntity.convert(entry);
        CombinedExtraPayTrackingDialogsControl.editEntry(convert)(null);
    }
    else {
        try {
            const data = {
                ends: new Date(),
                changeTime: new Date(),
            };
            const create = new ChangeTimeTracking(data, t.id);
            await create.execute(ClientStore.commandStore);
        }
        catch (err) {
            DefaultErrorHandler.showDefaultErrorAlert(err);
        }
    }
};
CombinedExtraPayTrackingDialogsControl.lock = (t) => async (_e) => {
    Menu.instance?.close();
    try {
        if (t.type === 'TimeTrackingEntity') {
            const create = new LockTimeTracking({ to: 'Locked' }, t.id);
            await create.execute(ClientStore.commandStore);
        }
        else {
            const create = new ChangeExtraPayTrackingStatus({ to: 'Locked' }, t.id);
            await create.execute(ClientStore.commandStore);
        }
    }
    catch (err) {
        DefaultErrorHandler.showDefaultErrorAlert(err);
    }
};
CombinedExtraPayTrackingDialogsControl.unlock = (t) => async (_e) => {
    Menu.instance?.close();
    try {
        if (t.type === 'TimeTrackingEntity') {
            const create = new UnlockTimeTracking({}, t.id);
            await create.execute(ClientStore.commandStore);
        }
        else {
            const create = new ChangeExtraPayTrackingStatus({ to: 'Recorded' }, t.id);
            await create.execute(ClientStore.commandStore);
        }
    }
    catch (err) {
        DefaultErrorHandler.showDefaultErrorAlert(err);
    }
};
CombinedExtraPayTrackingDialogsControl.deleteEntry = (t) => () => {
    Menu.instance?.close();
    Routing.instance.alert.post({
        text: I18n.m.getMessage(t.type === 'TimeTrackingEntity' ? 'timeTrackingDeleteEntryQuestion' : 'timeTrackingDeleteEntryQuestion'),
        buttons: [
            <ContainedButton key="okButton" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
            <ContainedButton key="deleteNow" title={I18n.m.getMessage('delete')} onPress={_a.deleteEntryNow(t)} backgroundColor={ThemeManager.style.brandDanger}/>,
        ],
    });
};
CombinedExtraPayTrackingDialogsControl.deleteEntryNow = (t) => (_e) => {
    Alert.instance?.close(async () => {
        try {
            LoadingEvents.instance.startLoading();
            if (t.type === 'TimeTrackingEntity') {
                const create = new DeleteTimeTracking({}, t.id);
                await create.execute(ClientStore.commandStore);
            }
            else {
                const create = new DeleteExtraPayTracking({}, t.id);
                await create.execute(ClientStore.commandStore);
            }
            LoadingEvents.instance.stopLoading();
        }
        catch (err) {
            LoadingEvents.instance.stopLoading();
            DefaultErrorHandler.showDefaultErrorAlert(err);
        }
    });
};
CombinedExtraPayTrackingDialogsControl.editEntry = (t) => (_e) => {
    Menu.instance?.close();
    if (t.type === 'TimeTrackingEntity') {
        if (t.orgEntry.absenceId != null) {
            UpmeshClient.instance.modals.absence
                .getById(t.orgEntry.absenceId)
                .then((a) => {
                openAbsenceCreationChangeDialog({ absence: a })(_e);
            })
                .catch((err) => {
                console.debug(err);
                Routing.instance.goTo('/absences');
            });
        }
        else {
            Dialog.instance?.open({
                content: (<ChangeTimeTrackDialog checkAndSaveTimeTracking={CombinedExtraPayTrackingDialogsControl.checkAndSaveTimeTracking} timeTrackingEntry={t.orgEntry}/>),
                fullscreenResponsive: true,
                contentPadding: false,
                scrollable: false,
            });
        }
    }
    else {
        Dialog.instance?.open({
            content: <AddOrChangeExtraPayTrackDialog extraPayTracking={t.orgEntry}/>,
            fullscreenResponsive: true,
            contentPadding: false,
            scrollable: false,
        });
    }
};
CombinedExtraPayTrackingDialogsControl.checkAndSaveTimeTracking = async (entry, save, collidingEntries = null, secondButtonText = I18n.m.getMessage('timeTrackingChangeSaveAnyway')) => {
    const reallyColliding = collidingEntries == null ? await TimeTrackingEntity.isColliding(entry) : collidingEntries;
    if (reallyColliding.length > 0) {
        Routing.instance.alert.post({
            text: I18n.m.getMessage('timeTrackingChangeFoundCollision'),
            buttons: [
                <ContainedButton backgroundColor="transparent" key="cancel" textColor={ThemeManager.style.brandPrimary} title={I18n.m.getMessage('cancel')} onPress={() => {
                        Alert.instance?.close();
                    }}/>,
                <ContainedButton backgroundColor="transparent" key="show_collision_tickets" textColor={ThemeManager.style.brandPrimary} title={I18n.m.getMessage('timeTrackingChangeShowCollision')} onPress={() => {
                        CombinedExtraPayTrackingDialogsControl.showColliding(entry, reallyColliding);
                    }}/>,
                <ContainedButton key="not_show_collision_tickets" title={secondButtonText} onPress={() => {
                        Alert.instance?.close();
                        save();
                    }}/>,
            ],
        });
    }
    else {
        save();
    }
};
CombinedExtraPayTrackingDialogsControl.showColliding = (tt, collidingEntries) => {
    const timeTracking = new TimeTrackingEntity(tt);
    Alert.instance?.close(async () => {
        let start = new Date(timeTracking.starts);
        let end = timeTracking.ends != null ? new Date(timeTracking.ends) : new Date(timeTracking.starts);
        collidingEntries.forEach((entry) => {
            if (new Date(entry.starts).getTime() < start.getTime()) {
                start = new Date(entry.starts);
            }
            if (entry.ends != null && new Date(entry.ends).getTime() > end.getTime()) {
                end = new Date(entry.ends);
            }
        });
        const userId = timeTracking.userId ? timeTracking.userId : await timeTracking.getMemberId();
        const timeTrackingFilter = new CombinedExtraPayAndTimeTrackingFilter();
        timeTrackingFilter.date = start.getTime();
        timeTrackingFilter.dateTo = end.getTime();
        timeTrackingFilter.userIds = [userId];
        Routing.instance.changeQueryParameter({ ft: JSON.stringify(timeTrackingFilter) }, false);
        Dialog.instance?.close();
    });
};
