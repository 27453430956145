"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeNote = void 0;
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var NoteChanged_1 = require("../../../events/project/notes/NoteChanged");
var ProjectEntity_1 = require("../../../query/entities/ProjectEntity");
var ClientStore_1 = require("../../../ClientStore");
var ChangeNote = function (_CommandRoot_1$Comman) {
  function ChangeNote(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v1();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ChangeNote);
    _this = _callSuper(this, ChangeNote, [data, entityId, token]);
    _this.aggregate = 'project';
    _this.commandName = 'ChangeNote';
    return _this;
  }
  (0, _inherits2.default)(ChangeNote, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(ChangeNote, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var entity = yield CommandReadModels_1.CommandReadModels.instance.note.getById(this.entityId, undefined, userId);
        if (!entity.projectId) return true;
        return ProjectEntity_1.ProjectEntity.checkProjectStatusAndGeneralAccess(entity.projectId, userId, false);
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        return new NoteChanged_1.NoteChanged(this.entityId, {
          notes: this.data.notes
        }, userId);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        if (key == null || key === 'notes') {
          if (this.data.notes == null) {
            throw {
              message: 'Ungültige Eingabe',
              messageCode: 'badRequest'
            };
          }
          for (var i = 0; i < this.data.notes.length; i += 1) {
            if (this.data.notes[i].text == null || this.data.notes.length > 512) {
              throw {
                message: 'Ungültige Eingabe',
                messageCode: 'badRequest'
              };
            }
          }
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "execute",
    value: function () {
      var _execute = (0, _asyncToGenerator2.default)(function* () {
        var onStore = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ClientStore_1.ClientStore.commandStore;
        return (0, _get2.default)((0, _getPrototypeOf2.default)(ChangeNote.prototype), "execute", this).call(this, onStore);
      });
      function execute() {
        return _execute.apply(this, arguments);
      }
      return execute;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.ChangeNote = ChangeNote;