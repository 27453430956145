import color from 'color';
import { FilePicker } from 'materialTheme/src/file/FilePicker';
import { FilePicketComp } from 'materialTheme/src/file/FilePicketComp';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { Divider } from 'materialTheme/src/theme/components/utils/Divider';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useRef, useState } from 'react';
import { FlatList, Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { AddTicketTag } from 'upmesh-core/src/client/commands/tickets/AddTicketTag';
import { AssignTicketTo } from 'upmesh-core/src/client/commands/tickets/AssignTicketTo';
import { ChangeTicketApprover } from 'upmesh-core/src/client/commands/tickets/ChangeTicketApprover';
import { ChangeTicketCraft } from 'upmesh-core/src/client/commands/tickets/ChangeTicketCraft';
import { ChangeTicketCustomField, } from 'upmesh-core/src/client/commands/tickets/ChangeTicketCustomField';
import { ChangeTicketDescription } from 'upmesh-core/src/client/commands/tickets/ChangeTicketDescription';
import { ChangeTicketTitle } from 'upmesh-core/src/client/commands/tickets/ChangeTicketTitle';
import { ChangeTicketType } from 'upmesh-core/src/client/commands/tickets/ChangeTicketType';
import { CreateTicket } from 'upmesh-core/src/client/commands/tickets/CreateTicket';
import { SetTicketShouldCompleteDate } from 'upmesh-core/src/client/commands/tickets/SetTicketShouldCompleteDate';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import * as uuid from 'uuid';
import * as XLSX from 'xlsx';
import { I18n } from '../../../i18n/I18n';
import { DefaultErrorHandler } from '../../DefaultErrorHandler';
import { CurrentProject } from '../../project/CurrentProject';
import { AssignmentPicker } from './AssignmentPicker';
var ImportStates;
(function (ImportStates) {
    ImportStates[ImportStates["SELECT_FILE"] = 0] = "SELECT_FILE";
    ImportStates[ImportStates["SELECT_TYPE"] = 1] = "SELECT_TYPE";
    ImportStates[ImportStates["SELECT_ASSIGNMENT"] = 2] = "SELECT_ASSIGNMENT";
    ImportStates[ImportStates["SELECT_DUPLICATE"] = 3] = "SELECT_DUPLICATE";
    ImportStates[ImportStates["IMPORT"] = 4] = "IMPORT";
})(ImportStates || (ImportStates = {}));
var DuplicateSelection;
(function (DuplicateSelection) {
    DuplicateSelection[DuplicateSelection["NEW"] = 0] = "NEW";
    DuplicateSelection[DuplicateSelection["IGNORE"] = 1] = "IGNORE";
    DuplicateSelection[DuplicateSelection["UPDATE"] = 2] = "UPDATE";
})(DuplicateSelection || (DuplicateSelection = {}));
const newImage = require('../../../assets/img/excel/new.png');
const ignoreImage = require('../../../assets/img/excel/ignore.png');
const updateImage = require('../../../assets/img/excel/update.png');
export function TicketExcelImportDialog(_props) {
    const getAvailableTypes = () => {
        const currentProject = CurrentProject.instance;
        const project = currentProject.getCurrentProject();
        if (project != null && project.ticketTypes != null) {
            return [I18n.m.getMessage('ticketTypeNotSelected'), ...project.ticketTypes];
        }
        return [];
    };
    const pickerCompId = useRef(uuid.v4());
    const readToArrayBuffer = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = (err) => {
                reject(err);
            };
            reader.readAsArrayBuffer(file);
        });
    };
    const [excelData, setExcelData] = useState([]);
    const [excelKeys, setExcelKeys] = useState([]);
    const [selectedFile, setSelectedFile] = useState();
    const [importPhase, setImportPhase] = useState(ImportStates.SELECT_FILE);
    const [selectedTypeIndex, setSelectedTypeIndex] = useState(0);
    const [fields, setFields] = useState([]);
    const [mapping, setMapping] = useState(new Map());
    const [progress, setProgress] = useState(0);
    const [targetProgress, setTargetProgress] = useState(0);
    const createdTicketIds = useRef([]);
    const errors = useRef(new Map());
    const [errorCounter, setErrorCounter] = useState(0);
    const [handleDuplicates, setHandleDuplicates] = useState(DuplicateSelection.NEW);
    const [duplicateIndex, setDuplicateIndex] = useState(0);
    const [duplicateIndexUpdate, setDuplicateIndexUpdate] = useState(0);
    const [duplicateSelector, setDuplicateSelector] = useState();
    const [imageViewHeight, setImageViewHeight] = useState(100);
    const handleFileImport = async (f) => {
        const files = Object.values(f);
        setSelectedFile(files[0].name);
        const data = await readToArrayBuffer(files[0]);
        const sheet = XLSX.read(data, { type: 'base64', cellDates: true });
        const rawJson = XLSX.utils.sheet_to_json(sheet.Sheets[sheet.SheetNames[0]], { raw: true });
        setExcelData(rawJson);
        if (rawJson.length > 0) {
            const firstElement = rawJson[0];
            const keys = [];
            for (const key in firstElement) {
                keys.push(key);
            }
            setExcelKeys(keys);
        }
        else {
            setExcelKeys([]);
        }
        setImportPhase(ImportStates.SELECT_ASSIGNMENT);
    };
    const openFilePickerDialog = () => {
        FilePicker.show(pickerCompId.current, false, ['allFiles'], () => { });
    };
    const getAvailableFieldsForType = (ticketType) => {
        const cpr = CurrentProject.instance.getCurrentProject();
        const result = [{ key: 'title', title: I18n.m.getMessage('ticketsDetailsTitle') }];
        if (cpr != null) {
            const layout = ChangeTicketCustomField.getTicketLayout(ticketType, cpr);
            layout.fields.forEach((field) => {
                result.push({ key: field.id, title: I18n.m.getMessage(field.label) });
            });
        }
        return result;
    };
    const selectedTicketType = useRef('');
    const onChangeTemplate = (val) => {
        setSelectedTypeIndex(val.index);
        selectedTicketType.current = val.value;
        const tmp = getAvailableFieldsForType(val.value);
        setFields(tmp);
    };
    useEffect(() => {
        onChangeTemplate({ index: 0, value: getAvailableTypes()[0] });
    }, []);
    const changeKeyForImport = (val) => {
        const newMapping = new Map(mapping);
        if (val.value.title === I18n.m.getMessage('importExcelNoAssignment')) {
            newMapping.delete(val.value.data);
        }
        else {
            newMapping.set(val.value.data, { excelKey: val.value.title });
        }
        setMapping(newMapping);
    };
    const renderAssignmentItem = ({ item }) => {
        return (<AssignmentPicker item={item} excelKeys={excelKeys} changeFunction={changeKeyForImport} excelElement={excelData[0]} mapping={mapping}/>);
    };
    const renderFieldsViewNew = () => {
        return (<>
        <View style={{ width: '100%', flex: 1, flexDirection: 'row' }}>
          <View style={{ height: 48, flex: 2 }}>
            <MaterialText strong>{I18n.m.getMessage('importExcelTableHeaderTicket')}</MaterialText>
          </View>
          <View style={{ flex: 1 }}>
            <MaterialText />
          </View>
          <View style={{ height: 48, flex: 2 }}>
            <MaterialText strong>{I18n.m.getMessage('importExcelTableHeaderExcel')}</MaterialText>
          </View>
          <View style={{ height: 48, flex: 2 }}>
            <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'flex-end' }}>
              <MaterialText strong>{I18n.m.getMessage('importExcelTableHeaderPreview')}</MaterialText>
            </View>
          </View>
        </View>
        <Divider />
        <FlatList key="assignmentList" renderItem={renderAssignmentItem} data={fields} keyExtractor={(item) => item.key}/>
      </>);
    };
    let internalErrorCounter = 0;
    const addError = (ticketId, ticketIndex, error, customMessage) => {
        if (error != null && error.messageCode === 'TicketnotFound') {
            return;
        }
        const forTicket = errors.current.get(ticketId) ?? [];
        const errorData = {
            ticketIndex,
            error,
            ticketString: excelData[ticketIndex],
            customErrorMessage: customMessage,
        };
        forTicket.push(errorData);
        errors.current.set(ticketId, forTicket);
        internalErrorCounter += 1;
        setErrorCounter(internalErrorCounter);
    };
    const fetchAllProjectTickets = async (projectId, identifierKey) => {
        const allProjectTickets = await UpmeshClient.instance.modals.ticket.get({
            filter: `projectId eq '${projectId}' and type eq '${selectedTicketType.current}' and deleted ne true`,
        });
        const mapWithSelectorAsKey = new Map();
        for (const ticket of allProjectTickets) {
            const correspondingValue = ticket[identifierKey];
            if (correspondingValue != null) {
                mapWithSelectorAsKey.set(correspondingValue, ticket);
            }
            else if (ticket.fields != null) {
                const correspondingValue = ticket.fields.find((elem) => {
                    return elem.id === identifierKey;
                });
                if (correspondingValue != null) {
                    mapWithSelectorAsKey.set(correspondingValue.value, ticket);
                }
            }
        }
        return mapWithSelectorAsKey;
    };
    const updateRestOfTicket = (ticketIndex, ticketId, afterCreationChanges) => {
        if (ticketId != null) {
            for (const command of afterCreationChanges) {
                const currentCommand = command;
                currentCommand.execute(ClientStore.commandStore).catch((err) => {
                    console.error(err);
                    addError(ticketId, ticketIndex, err);
                });
            }
        }
    };
    const doImport = async () => {
        setImportPhase(ImportStates.IMPORT);
        const type = selectedTicketType.current;
        const project = CurrentProject.instance.getCurrentProject();
        if (project == null) {
            return;
        }
        const projectTagMap = new Map();
        if (project.tags != null) {
            project.tags.forEach((tag) => {
                projectTagMap.set(tag.tagName, tag);
            });
        }
        setTargetProgress(excelData.length);
        let identifierMap = new Map();
        if (handleDuplicates !== DuplicateSelection.NEW && duplicateSelector != null) {
            try {
                identifierMap = await fetchAllProjectTickets(project.id, duplicateSelector);
            }
            catch (err) {
                console.error('could not create ticket identifier map', err);
            }
        }
        for (let i = 0; i < excelData.length; i += 1) {
            setProgress(i);
            const singleEntry = excelData[i];
            let ticketId = uuid.v1();
            let correspondingTicket;
            if (handleDuplicates !== DuplicateSelection.NEW && duplicateSelector != null) {
                const mappedKey = mapping.get(duplicateSelector);
                if (mappedKey != null) {
                    correspondingTicket = identifierMap.get(singleEntry[mappedKey.excelKey]);
                    if (correspondingTicket != null) {
                        ticketId = correspondingTicket.id;
                    }
                }
            }
            if (correspondingTicket != null && handleDuplicates === DuplicateSelection.IGNORE) {
                continue;
            }
            const ticketData = {
                title: I18n.m.getMessage('ticketsDetailsTitlePlaceholder'),
                projectId: project.id,
            };
            const afterCreationChanges = [];
            for (const field of fields) {
                const mappedKey = mapping.get(field.key);
                if (mappedKey != null &&
                    singleEntry[mappedKey.excelKey] != null &&
                    singleEntry[mappedKey.excelKey].length > 0) {
                    if (field.key === 'title') {
                        const value = singleEntry[mappedKey.excelKey];
                        if (correspondingTicket == null) {
                            ticketData.title = value;
                        }
                        else if (value !== correspondingTicket.title) {
                            afterCreationChanges.push(new ChangeTicketTitle({ title: value }, ticketId));
                        }
                    }
                    else if (field.key === '12') {
                        const value = singleEntry[mappedKey.excelKey];
                        if (correspondingTicket == null) {
                            ticketData.description = value;
                        }
                        else if (value !== correspondingTicket.description) {
                            afterCreationChanges.push(new ChangeTicketDescription({ description: value }, ticketId));
                        }
                    }
                    else if (field.key === '5') {
                        afterCreationChanges.push(new ChangeTicketCraft({ craft: singleEntry[mappedKey.excelKey] }, ticketId));
                    }
                    else if (field.key === '6') {
                        afterCreationChanges.push(new SetTicketShouldCompleteDate({ completionOn: new Date(singleEntry[mappedKey.excelKey]) }, ticketId));
                    }
                    else if (field.key === '7') {
                        const excelEmail = singleEntry[mappedKey.excelKey];
                        const projectTeam = CurrentProject.instance.getCurrentProjectTeam('user');
                        const found = projectTeam.find((elem) => {
                            if (elem.user.emails == null) {
                                return false;
                            }
                            return elem.user.emails.findIndex((uEmail) => uEmail === excelEmail) > -1;
                        });
                        if (found != null) {
                            afterCreationChanges.push(new AssignTicketTo({ assignedToUserId: found.user.id }, ticketId));
                        }
                        else {
                            addError(ticketId, i, {}, 'could not find user');
                        }
                    }
                    else if (field.key === '8') {
                        const excelEmail = singleEntry[mappedKey.excelKey];
                        const projectTeam = CurrentProject.instance.getCurrentProjectTeam('user');
                        const found = projectTeam.find((elem) => {
                            if (elem.user.emails == null) {
                                return false;
                            }
                            return elem.user.emails.findIndex((uEmail) => uEmail === excelEmail) > -1;
                        });
                        if (found != null) {
                            afterCreationChanges.push(new ChangeTicketApprover({ approverUserId: found.user.id }, ticketId));
                        }
                        else {
                            addError(ticketId, i, null, 'could not find user to set approver');
                        }
                    }
                    else if (field.key === '9') {
                        const usedTagNames = singleEntry[mappedKey.excelKey].split(';');
                        for (const usedTagName of usedTagNames) {
                            const addTag = projectTagMap.get(usedTagName);
                            if (addTag != null) {
                                afterCreationChanges.push(new AddTicketTag({ tag: addTag }, ticketId));
                            }
                        }
                    }
                    else {
                        const customData = {
                            fieldId: field.key,
                            value: singleEntry[mappedKey.excelKey],
                        };
                        afterCreationChanges.push(new ChangeTicketCustomField(customData, ticketId));
                    }
                }
            }
            if (correspondingTicket == null) {
                const createTicketCommand = new CreateTicket(ticketData, ticketId);
                try {
                    await createTicketCommand.execute();
                    createdTicketIds.current.push(ticketId);
                }
                catch (err) {
                    addError(ticketId, i, err);
                }
                if (selectedTypeIndex !== 0) {
                    const changeTicketType = new ChangeTicketType({
                        ticketType: type,
                    }, ticketId);
                    try {
                        await changeTicketType.execute();
                    }
                    catch (err) {
                        addError(ticketId, i, err);
                    }
                }
            }
            const eventKey = uuid.v1();
            const timeOut = setTimeout(() => {
                UpmeshClient.eventDispatcher.detach('Ticket', eventKey);
            }, 2000);
            if (correspondingTicket == null) {
                const createListener = (en) => {
                    const current = en.entities.get(ticketId);
                    if (current != null && current.entity != null && current.entity.id === ticketId) {
                        updateRestOfTicket(i, ticketId, afterCreationChanges);
                        clearTimeout(timeOut);
                    }
                };
                UpmeshClient.eventDispatcher.attach({
                    attachKey: eventKey,
                    callback: createListener,
                    readModelName: 'Ticket',
                });
            }
            else {
                updateRestOfTicket(i, ticketId, afterCreationChanges);
            }
        }
        setProgress(excelData.length);
    };
    const startImport = () => {
        doImport().catch((err) => {
            console.error('error', err);
        });
    };
    const renderSingleError = ({ item }) => {
        const ticketAsFields = [];
        const first = item[0];
        if (first == null) {
            return <View />;
        }
        for (const key in first.ticketString) {
            ticketAsFields.push(<View key={`${first.ticketIndex}_${key}`}>
          <MaterialText>{`${key}: ${first.ticketString[key].toString().substring(0, 24)}${first.ticketString[key].length > 32 ? '...' : ''}`}</MaterialText>
        </View>);
        }
        const items = item.map((elem) => {
            return (<View>
          <MaterialText>{DefaultErrorHandler.getDefaultErrorMessages(elem.error)}</MaterialText>
        </View>);
        });
        return (<Card outerPadding={8} backgroundColor={color(ThemeManager.style.brandDanger).lighten(0.6).toString()}>
        <View style={{ margin: 8 }}>
          <MaterialText strong>
            {I18n.m.getMessage('importExcelErrorAtLine')}
            {item[0].ticketIndex}
          </MaterialText>
          {ticketAsFields}
          <MaterialText strong>{I18n.m.getMessage('error')}:</MaterialText>
          {items}
        </View>
      </Card>);
    };
    const renderErrorView = () => {
        const data = [...errors.current.values()];
        return (<View>
        <MaterialText>{I18n.m.getMessage('importExcelError')}</MaterialText>
        <FlatList key={`reRenderKey_${errorCounter}`} renderItem={renderSingleError} data={data} keyExtractor={(_item, index) => `error_index_${index}`}/>
      </View>);
    };
    const renderSuccessView = () => {
        return (<View>
        <MaterialText>{I18n.m.getMessage('importExcelSuccess')}</MaterialText>
      </View>);
    };
    if (importPhase >= ImportStates.IMPORT) {
        if (progress < targetProgress) {
            return (<>
          <DialogTitle>{I18n.m.getMessage('importExcelDialogHeader')}</DialogTitle>
          <DialogContent padding={16}>
            <View style={{
                    flexDirection: 'column',
                    alignContent: 'center',
                    width: '100%',
                }}>
              <Spinner style={{ paddingBottom: 16 }}/>
              <MaterialText centeredText centeredBox>
                {progress}/{targetProgress}
              </MaterialText>
            </View>
          </DialogContent>
        </>);
        }
        return (<>
        <DialogTitle>{I18n.m.getMessage('importExcelDialogHeader')}</DialogTitle>
        <DialogContent>{errorCounter > 0 ? renderErrorView() : renderSuccessView()}</DialogContent>
        <DialogActions>
          <ContainedButton onPress={() => Dialog.instance?.close()} title={errorCounter > 0 ? I18n.m.getMessage('ok') : I18n.m.getMessage('next')}/>
        </DialogActions>
      </>);
    }
    if (importPhase === ImportStates.SELECT_DUPLICATE) {
        const styles = StyleSheet.create({
            helperBox: {
                margin: ThemeManager.style.contentPaddingValue,
                ...ThemeManager.style.contentPadding,
                flex: 1,
                borderWidth: ThemeManager.style.borderWidth,
                borderRadius: ThemeManager.style.borderRadius,
            },
        });
        const pickList = [{ title: I18n.m.getMessage('pleaseSelect'), data: '' }];
        for (const value of mapping.keys()) {
            const correspondingField = fields.find((elem) => elem.key === value);
            if (correspondingField != null) {
                pickList.push({
                    title: correspondingField.title,
                    data: correspondingField.key,
                });
            }
        }
        return (<>
        <DialogTitle>{I18n.m.getMessage('importExcelDialogHeader')}</DialogTitle>
        <DialogContent>
          <View>
            <MaterialText>{I18n.m.getMessage('importExcelHandleDuplicatesHeader')}</MaterialText>
            <View style={{
                width: '100%',
                flex: 1,
                flexDirection: 'row',
            }}>
              <TouchableOpacity style={[
                styles.helperBox,
                {
                    borderColor: handleDuplicates === DuplicateSelection.NEW
                        ? ThemeManager.style.brandPrimary
                        : ThemeManager.style.borderColor,
                },
            ]} onPressIn={() => setHandleDuplicates(DuplicateSelection.NEW)}>
                <View onLayout={(l) => {
                setImageViewHeight((l.nativeEvent.layout.width * 300) / 500);
            }}>
                  <Image style={{ marginBottom: 16, width: '100%', height: imageViewHeight }} resizeMode="center" source={newImage}/>
                  <MaterialText strong additionalTextStyle={{ paddingBottom: ThemeManager.style.contentPaddingValue }}>
                    {I18n.m.getMessage('importExcelDuplicatesNew')}
                  </MaterialText>
                  <MaterialText>{I18n.m.getMessage('importExcelDuplicatesNewDescription')}</MaterialText>
                </View>
              </TouchableOpacity>
              <TouchableOpacity onPressIn={() => setHandleDuplicates(DuplicateSelection.IGNORE)} style={[
                styles.helperBox,
                {
                    borderColor: handleDuplicates === DuplicateSelection.IGNORE
                        ? ThemeManager.style.brandPrimary
                        : ThemeManager.style.borderColor,
                },
            ]}>
                <Image style={{ marginBottom: 16, width: '100%', height: imageViewHeight }} resizeMode="center" source={ignoreImage}/>
                <MaterialText strong additionalTextStyle={{ paddingBottom: ThemeManager.style.contentPaddingValue }}>
                  {I18n.m.getMessage('importExcelDuplicatesIgnore')}
                </MaterialText>
                <MaterialText>{I18n.m.getMessage('importExcelDuplicatesIgnoreDescription')}</MaterialText>
                <FormInputPicker selectedIndex={duplicateIndex} onChangePicker={setDuplicateIndex} onChange={(val) => {
                setDuplicateSelector(val.value.data);
            }} disabled={handleDuplicates !== DuplicateSelection.IGNORE} list={pickList}/>
              </TouchableOpacity>
              <TouchableOpacity onPressIn={() => setHandleDuplicates(DuplicateSelection.UPDATE)} style={[
                styles.helperBox,
                {
                    borderColor: handleDuplicates === DuplicateSelection.UPDATE
                        ? ThemeManager.style.brandPrimary
                        : ThemeManager.style.borderColor,
                },
            ]}>
                <Image style={{ marginBottom: 16, width: '100%', height: imageViewHeight }} resizeMode="center" source={updateImage}/>
                <MaterialText strong additionalTextStyle={{ paddingBottom: ThemeManager.style.contentPaddingValue }}>
                  {I18n.m.getMessage('importExcelDuplicatesUpdate')}
                </MaterialText>
                <MaterialText>{I18n.m.getMessage('importExcelDuplicatesUpdateDescription')}</MaterialText>
                <FormInputPicker selectedIndex={duplicateIndexUpdate} onChangePicker={setDuplicateIndexUpdate} onChange={(val) => {
                setDuplicateSelector(val.value.data);
            }} disabled={handleDuplicates !== DuplicateSelection.UPDATE} list={pickList}/>
              </TouchableOpacity>
            </View>
          </View>
        </DialogContent>
        <DialogActions>
          <ContainedButton backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} onPress={() => setImportPhase(ImportStates.SELECT_ASSIGNMENT)} title={I18n.m.getMessage('back')}/>
          <ContainedButton onPress={() => {
                setImportPhase(ImportStates.IMPORT);
                startImport();
            }} title={I18n.m.getMessage('importExcelImport')}/>
        </DialogActions>
      </>);
    }
    return (<>
      <FilePicketComp id={pickerCompId.current} multiple={false} gotFile={handleFileImport}/>
      <DialogTitle>{I18n.m.getMessage('importExcelDialogHeader')}</DialogTitle>
      <DialogContent padding={16}>
        
        <View style={{ width: '100%', maxWidth: 768 }}>
          <ContainedButton backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} borderStyle="solid" outlineColor={ThemeManager.style.brandPrimary} full title={selectedFile != null
            ? I18n.m.getMessage('importExcelSelectFileChange')
            : I18n.m.getMessage('importExcelSelectFile')} onPress={openFilePickerDialog}/>
          {selectedFile != null ? <MaterialText>{selectedFile}</MaterialText> : null}
        </View>
        
        {importPhase >= ImportStates.SELECT_TYPE.valueOf() ? (<>
            <View style={{
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>
              <View style={{ width: '100%' }}>
                <FormInputPicker labelText={I18n.m.getMessage('importExcelTypeLabel')} list={getAvailableTypes()} selectedIndex={selectedTypeIndex} onChange={onChangeTemplate}/>
              </View>
            </View>
            <Divider />
          </>) : null}
        
        {importPhase >= ImportStates.SELECT_ASSIGNMENT.valueOf() ? renderFieldsViewNew() : null}
      </DialogContent>
      <DialogActions>
        <ContainedButton disabled={mapping.size === 0} onPress={() => setImportPhase(ImportStates.SELECT_DUPLICATE)} title={I18n.m.getMessage('next')}/>
      </DialogActions>
    </>);
}
export const openTicketExcelImportDialog = (props) => (_e) => {
    Menu.instance?.close();
    Dialog.instance?.open({
        content: <TicketExcelImportDialog {...props}/>,
        width: 768,
        contentPadding: false,
        showCloseIcon: true,
        closeOnTouchOutside: false,
    });
};
