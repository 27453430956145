import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { CurrentProject } from '../CurrentProject';
import { DashBoardItem } from './DashBoardItem';
export class PlansItem extends PureComponent {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.pressAddPlan = async (e) => {
            Routing.instance.openDialog('uploadPlan')(e);
        };
        this.loadDashData = async () => {
            const projectId = CurrentProject.instance.getCurrentProjectId();
            const userId = CurrentUser.entity.id;
            if (projectId != null && this.mounted) {
                const canRead = await RightsManager.hasReadRight(projectId, userId, 'plans');
                const counts = await UpmeshClient.instance.modals.plan.count(`projectId eq '${projectId}' and deleted ne true`);
                this.setState({ counts, projectId, canRead }, () => { });
            }
        };
        this.state = {
            counts: CurrentProject.instance.getCurrentPlans().length,
            projectId: props.projectId,
            canRead: CurrentProject.instance.getCurrentRights().read.plans,
        };
    }
    componentDidMount() {
        this.mounted = true;
        CurrentProject.plansChanged.attach(this.loadDashData);
        this.loadDashData().catch((err) => console.debug(err));
    }
    componentWillUnmount() {
        this.mounted = false;
        CurrentProject.plansChanged.detach(this.loadDashData);
    }
    render() {
        const { counts, projectId, canRead } = this.state;
        return (<Ripple style={{ width: '100%', paddingBottom: 16 }} onPress={canRead ? Routing.instance.goToButton(`/projects/${projectId}/plans`) : undefined}>
        <View style={{ width: '100%' }}>
          <DashBoardItem title={I18n.m.getMessage('menuProjectPlans')} icon="floor-plan" iconColor="#448AFF" counts={counts}/>
        </View>
      </Ripple>);
    }
}
