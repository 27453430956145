import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { ExcelFileDialog } from 'materialTheme/src/theme/components/file/ExcelFileDialog';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Platform, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { AddCompanyTicketType } from 'upmesh-core/src/client/commands/companies/tickettypes/AddCompanyTicketType';
import { RemoveCompanyTicketType } from 'upmesh-core/src/client/commands/companies/tickettypes/RemoveCompanyTicketType';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { AddTicketTypeToCompanyDialog } from './AddTicketTypeToCompanyDialog';
import { ChangeTicketTypeCompanyDialog } from './ChangeTicketTypeCompanyDialog';
const noTicketTypeImg = require('../../assets/img/no_types_or_crafts_or_tags.png');
export class CompanyTicketTypeView extends PureComponent {
    constructor(props) {
        super(props);
        this.myRole = () => {
            return CompanyUserInfo.me == null ? 'user' : CompanyUserInfo.me.role;
        };
        this.removeType = (ticketType) => (_e) => {
            Routing.instance.alert.post({
                text: I18n.m.getMessage('companyRemoveTicketTypeQuestion', { type: ticketType.type }),
                buttons: [
                    <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                    <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={this.removeTypeNow(ticketType.type)} backgroundColor={ThemeManager.style.brandDanger}/>,
                ],
            });
        };
        this.removeTypeNow = (ticketType) => (_e) => {
            const { company } = this.props;
            LoadingEvents.instance.startLoading();
            Alert.instance?.close(async () => {
                try {
                    const remove = new RemoveCompanyTicketType({ ticketType }, company.id, CurrentUser.token);
                    await remove.execute(ClientStore.commandStore);
                }
                catch (e) {
                    console.error(e);
                    Routing.instance.alert.post({ text: DefaultErrorHandler.getDefaultErrorMessages(e, I18n.m) });
                }
                LoadingEvents.instance.stopLoading();
            });
        };
        this.openFab = () => {
            if (Fab.instance == null) {
                window.setTimeout(this.openFab, 100);
                return;
            }
            const permitted = this.myRole() === 'admin';
            if (Platform.OS === 'web') {
                Fab.instance.open({
                    fabIcon: 'plus',
                    fabIconOpen: 'close',
                    small: false,
                    fabColor: ThemeManager.style.brandPrimary,
                    fabColorOpen: ThemeManager.style.brandSecondary,
                    onPressFab: !permitted
                        ? () => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') })
                        : undefined,
                    fabActions: permitted
                        ? [
                            {
                                text: I18n.m.getMessage('companyAddTicketTypeDialogTitle'),
                                icon: 'plus',
                                onPress: this.openAddDialog,
                            },
                            {
                                text: I18n.m.getMessage('companyExcelDialogTitle'),
                                icon: 'microsoft-excel',
                                onPress: this.openExcelDialog,
                            },
                        ]
                        : undefined,
                });
            }
            else {
                Fab.instance.open({
                    fabIcon: 'plus',
                    fabIconOpen: 'close',
                    small: false,
                    fabColor: ThemeManager.style.brandPrimary,
                    fabColorOpen: ThemeManager.style.brandSecondary,
                    onPressFab: permitted
                        ? this.openAddDialog
                        : () => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }),
                });
            }
        };
        this.openExcelDialog = () => {
            Dialog.instance?.open({
                closeOnTouchOutside: false,
                fullscreenResponsive: true,
                contentPadding: false,
                scrollable: false,
                showCloseButton: false,
                width: 1042,
                content: (<ExcelFileDialog saveFunction={this.saveFunction} generateExampleRecords={this.generateExamples} columns={[
                        { title: I18n.m.getMessage('projectSettingsTypes'), keyInData: 'Tickettyp', style: { width: 200 } },
                    ]}/>),
            });
        };
        this.saveFunction = async (records) => {
            const { company } = this.props;
            const ticketTypePromises = [];
            records.forEach((element) => {
                ticketTypePromises.push(new AddCompanyTicketType({ ticketType: element.Tickettyp }, company.id).execute(ClientStore.commandStore));
            });
            await Promise.all(ticketTypePromises);
        };
        this.generateExamples = async () => {
            const { data } = this.state;
            const examples = [];
            data?.forEach((element) => {
                examples.push({
                    Tickettyp: element.type,
                });
            });
            return examples;
        };
        this.openAddDialog = (_e) => {
            const { company } = this.props;
            Dialog.instance?.open({
                closeOnTouchOutside: false,
                content: <AddTicketTypeToCompanyDialog company={company}/>,
                contentPadding: false,
                scrollable: false,
                showCloseIcon: true,
            });
        };
        this.openEditDialog = (ticketType) => (_e) => {
            if (this.myRole() === 'admin') {
                const { company } = this.props;
                Dialog.instance?.open({
                    closeOnTouchOutside: false,
                    content: <ChangeTicketTypeCompanyDialog type={ticketType.type} company={company}/>,
                    contentPadding: false,
                    scrollable: true,
                    showCloseIcon: true,
                });
            }
            else {
                Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') });
            }
        };
        this.showInfo = (_e) => {
            Routing.instance.alert.post({ text: I18n.m.getMessage('companySettingsCraftsTagsTypesInformation') });
        };
        this.state = {
            myRole: this.myRole(),
        };
    }
    componentDidUpdate(prevProps) {
        if (prevProps.companySettings !== this.props.companySettings ||
            prevProps.companySettings.ticketTypes !== this.props.companySettings.ticketTypes) {
            this.updateData();
        }
    }
    componentDidMount() {
        this.updateData();
        this.openFab();
    }
    updateData() {
        const { companySettings } = this.props;
        const data = [];
        companySettings.ticketTypes.forEach((type) => {
            data.push({ type, id: type });
        });
        this.setState({ data });
    }
    componentWillUnmount() {
        if (Fab.instance != null)
            Fab.instance.close();
    }
    render() {
        const { data, myRole } = this.state;
        if (!(myRole === 'admin' || myRole === 'user'))
            return null;
        const { size } = this.props;
        const tableMaxHeight = size.contentHeight - ThemeManager.style.headerHeight - ThemeManager.style.getScreenRelativePixelSize(72);
        return (<View style={{
                width: '100%',
                maxWidth: '100%',
                paddingTop: ThemeManager.style.contentPaddingValue,
                alignSelf: 'center',
                paddingHorizontal: 8,
            }}>
        <View style={{ flexDirection: 'row', marginLeft: 8 + ThemeManager.style.contentPaddingValue }}>
          <MaterialText centeredBox type={MaterialTextTypes.H6}>
            {I18n.m.getMessage('ticketTypes')}
            <Icon toolTip="" icon="information-outline" onPress={this.showInfo}/>
          </MaterialText>
        </View>
        <Card style={{ width: '100%', height: 'auto' }}>
          <Table tableName="CompanyTicketType" actionItemsLength={2} actions={myRole === 'admin'
                ? (_item) => [
                    { icon: 'pencil-outline', onAction: this.openEditDialog, toolTip: I18n.m.getMessage('edit') },
                    { icon: 'delete', onAction: this.removeType, toolTip: I18n.m.getMessage('delete') },
                ]
                : undefined} maxHeight={tableMaxHeight} emptyTableImage={noTicketTypeImg} emptyTableHint={I18n.m.getMessage('companyTypeNoDataHint')} emptyTableText={I18n.m.getMessage('companyTypeNoDataText')} data={data} sortBy="type" sortDirection="asc" columns={[
                {
                    title: I18n.m.getMessage('name'),
                    keyInData: 'type',
                    style: { width: 350 },
                    sortable: true,
                    dataType: 'string',
                },
            ]}/>
        </Card>
      </View>);
    }
}
