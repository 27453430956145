import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { RemoveProjectCraft } from 'upmesh-core/src/client/commands/project/crafts/RemoveProjectCraft';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { DefaultErrorHandler } from '../../DefaultErrorHandler';
import { AddCraftToProjectDialog } from './AddCraftToProjectDialog';
import { ChangeCraftProjectDialog } from './ChangeCraftProjectDialog';
const noDataImage = require('../../../assets/img/no_types_or_crafts_or_tags.png');
export class ProjectCraftSettings extends PureComponent {
    constructor(props) {
        super(props);
        this.renderTicketCell = (item) => (<View key={`tickets_${item.id}`} style={{
                flex: 1,
                flexDirection: 'row',
                padding: 8,
                alignContent: 'flex-end',
                alignItems: 'flex-end',
                minWidth: 150,
            }}>
      <MaterialText fixedWidth="100%" textAlign="right" centeredBox>
        {item.tickets} {I18n.m.getMessage('tickets')}
      </MaterialText>
    </View>);
        this.init = async () => {
            const { project } = this.props;
            const data = [];
            const promises = [];
            project.crafts?.forEach((craft) => {
                promises.push(new Promise((resolve) => {
                    UpmeshClient.instance.modals.ticket
                        .count(`projectId eq '${project.id}' and craft eq '${craft.replace(/'/gi, "''")}'`)
                        .then((tickets) => {
                        data.push({ craft, tickets, id: craft });
                        resolve();
                    })
                        .catch((err) => console.debug(err));
                }));
            });
            await Promise.all(promises);
            this.setState({ data });
        };
        this.openAddDialog = (_e) => {
            const { project } = this.props;
            Dialog.instance?.open({
                closeOnTouchOutside: false,
                content: <AddCraftToProjectDialog project={project}/>,
                height: 0,
                contentPadding: false,
            });
        };
        this.openEditDialog = (craft) => (_e) => {
            const { project } = this.props;
            Dialog.instance?.open({
                closeOnTouchOutside: false,
                content: <ChangeCraftProjectDialog craft={craft.craft} project={project}/>,
                contentPadding: false,
            });
        };
        this.openFab = () => {
            if (Fab.instance != null) {
                const { size } = this.props;
                Fab.instance.open({
                    fabIcon: 'plus',
                    extraPaddingBottom: size.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0,
                    fabIconOpen: 'close',
                    small: false,
                    fabColor: ThemeManager.style.brandPrimary,
                    fabColorOpen: ThemeManager.style.brandSecondary,
                    onPressFab: this.openAddDialog,
                });
            }
            else {
                window.setTimeout(this.openFab, 100);
            }
        };
        this.removeType = (craft) => (_e) => {
            Routing.instance.alert.post({
                text: I18n.m.getMessage('projectRemoveCraftQuestion', { craft: craft.craft }),
                buttons: [
                    <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                    <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={this.removeTypeNow(craft.craft)} backgroundColor={ThemeManager.style.brandDanger}/>,
                ],
            });
        };
        this.removeTypeNow = (craft) => (_e) => {
            const { project } = this.props;
            LoadingEvents.instance.startLoading();
            Alert.instance?.close(async () => {
                try {
                    const remove = new RemoveProjectCraft({ craft }, project.id, CurrentUser.token);
                    await remove.execute(ClientStore.commandStore);
                }
                catch (e) {
                    console.error(e);
                    Routing.instance.alert.post({ text: DefaultErrorHandler.getDefaultErrorMessages(e, I18n.m) });
                }
                LoadingEvents.instance.stopLoading();
            });
        };
        this.renderTable = () => {
            const { data } = this.state;
            const { size } = this.props;
            const height = size.contentHeight;
            const columns = [
                {
                    title: I18n.m.getMessage('projectSettingsName'),
                    keyInData: 'craft',
                    dataType: 'string',
                    style: { width: 150 },
                },
                {
                    title: I18n.m.getMessage('projectSettingsContainedIn'),
                    keyInData: 'tickets',
                    dataType: 'number',
                    style: { width: 150 },
                    cellRenderer: this.renderTicketCell,
                },
            ];
            return (<Table tableName="ProjectCrafts" actionItemsLength={2} actions={(_item) => [
                    {
                        toolTip: I18n.m.getMessage('edit'),
                        onAction: this.openEditDialog,
                        icon: 'pencil-outline',
                    },
                    {
                        toolTip: I18n.m.getMessage('delete'),
                        onAction: this.removeType,
                        icon: 'delete-outline',
                    },
                ]} columns={columns} maxHeight={height - ThemeManager.style.headerHeight - ThemeManager.style.getScreenRelativePixelSize(108)} emptyTableHint={I18n.m.getMessage('projectTypeNoDataHint')} emptyTableText={I18n.m.getMessage('projectTypeNoDataText')} emptyTableImage={noDataImage} data={data}/>);
        };
        this.state = {};
    }
    componentDidMount() {
        this.init().catch((err) => console.debug('cant init ProjectTagSettings', err));
        this.openFab();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.project !== this.props.project) {
            this.init().catch((err) => console.debug('cant init ProjectTagSettings', err));
        }
    }
    componentWillUnmount() {
        if (Fab.instance != null)
            Fab.instance.close();
    }
    render() {
        return (<View style={{
                width: '100%',
                maxWidth: '100%',
                paddingTop: ThemeManager.style.contentPaddingValue,
                alignSelf: 'center',
                paddingHorizontal: 8,
            }}>
        <View style={{ marginLeft: 8 + ThemeManager.style.contentPaddingValue }}>
          <MaterialText type={MaterialTextTypes.H6}>{I18n.m.getMessage('projectSettingsCrafts')}</MaterialText>
        </View>
        <Card style={{ width: '100%', height: 'auto' }}>{this.renderTable()}</Card>
      </View>);
    }
}
