import { ChipDialogForm } from 'materialTheme/src/theme/components/chips/ChipDialogForm';
import { DialogUp } from 'materialTheme/src/theme/components/DialogUp';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React from 'react';
import { View } from 'react-native';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { PlansFilterDialog } from '../plans/PlansFilterDialog';
import { TicketDialogFilter } from './TicketDialogFilter';
export function TicketTagFilterIcon(props) {
    const openTagFilter = async (e) => {
        const projectTags = TicketDialogFilter.getTags(props.tickets);
        const selectedTags = TicketDialogFilter.getSelectedChipsByIds(projectTags, props.currentFilter.t);
        const projectIds = new Set();
        props.tickets.forEach((t) => projectIds.add(t.projectId));
        const projects = await UpmeshClient.instance.modals.project.get({
            filter: `id in ${JSON.stringify(projectIds.values())}`,
        });
        const projectTagGroups = PlansFilterDialog.getTagsGroups(projects);
        const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
        const listItems = [];
        for (let i = 0; i < projectTags.length; i += 1) {
            const aChip = projectTags[i];
            if (aChip.title != null) {
                listItems.push({
                    selected: selectedTags != null && selectedTags.length > 0
                        ? selectedTags.findIndex((a) => a.title === aChip.title) > -1
                        : false,
                    secondTextLine: aChip.groupId,
                    thumbnail: aChip.backgroundColor
                        ? {
                            thumbnail: (<View style={{ height: 24, width: 24, borderRadius: 12, backgroundColor: aChip.backgroundColor }}/>),
                            width: 24,
                            rounded: true,
                        }
                        : undefined,
                    title: aChip.title,
                    groupId: aChip.groupId,
                    id: aChip.id ? aChip.id : aChip.title,
                });
            }
        }
        DialogUp.instance?.open({
            openPosition,
            content: (<ChipDialogForm key="TagsChipDialogForm" title={I18n.m.getMessage('tags')} chipGroups={projectTagGroups} sortByGroup icon={{ icon: 'tag-multiple-outline', toolTip: '' }} items={listItems} onCancel={DialogUp.instance.close} multiselect onSave={(selectedTags) => {
                    const currentFilter = { ...props.currentFilter };
                    if (selectedTags != null && selectedTags.length > 0) {
                        currentFilter.t = [];
                        selectedTags.forEach((t) => {
                            if (t.id != null && t.selected)
                                currentFilter.t.push(t.id);
                        });
                    }
                    DialogUp.instance?.close(() => {
                        props.setFilter(currentFilter);
                    });
                }} canAddNewChips={false}/>),
            contentPadding: false,
            fullscreenResponsive: true,
            showCloseButton: false,
            showCloseIcon: false,
        });
    };
    const isFilterActive = props.currentFilter.t != null && props.currentFilter.t.length > 0;
    return (<Icon badge={props.currentFilter.t != null && props.currentFilter.t.length > 0
            ? props.currentFilter.t?.length.toString(10)
            : undefined} key={`tags_${isFilterActive}`} icon="tag-multiple-outline" toolTip={props.currentFilter.t != null && props.currentFilter.t.length > 0
            ? `${I18n.m.getMessage('tags')}: ${props.currentFilter.t.join(', ')}`
            : I18n.m.getMessage('tags')} onPress={openTagFilter} color={isFilterActive
            ? ThemeManager.style.brandPrimary
            : props.color
                ? props.color
                : ThemeManager.style.defaultIconColor}/>);
}
