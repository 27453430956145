import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Switch } from 'materialTheme/src/theme/components/Switch';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { FlatList, StyleSheet, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { ChangeUserSettings } from 'upmesh-auth-core/src/client/commands/user/ChangeUserSettings';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { UserSettingsEntity } from 'upmesh-auth-core/src/client/query/entities/UserSettingsEntity';
import { MarkLogBookAsNotRead } from 'upmesh-core/src/client/commands/MarkLogBookAsNotRead';
import { MarkLogBookAsRead } from 'upmesh-core/src/client/commands/MarkLogBookAsRead';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { LogBookItem } from '../logbook/LogBookItem';
const styles = StyleSheet.create({
    container: { width: '100%', height: '100%' },
    rowContainer: { flexDirection: 'row', width: '100%' },
    rowReadContainer: {
        width: 48,
        overflow: 'visible',
        justifyContent: 'center',
        borderWidth: 0,
        borderStyle: 'solid',
        borderColor: ThemeManager.style.borderColor,
        borderBottomWidth: ThemeManager.style.borderWidth,
    },
    rowItemContainer: { flex: 1 },
});
export const saveLastOpenNotifications = async () => {
    try {
        if (CurrentUser.settings != null) {
            const appSettings = CurrentUser.settings.appSettings != null ? CurrentUser.settings.appSettings : {};
            const newSettings = new UserSettingsEntity({
                ...CurrentUser.settings,
                appSettings: { ...appSettings, lastOpenNotifications: new Date().getTime() },
            });
            const c = new ChangeUserSettings(newSettings);
            await c.execute(AuthClient.instance.commandStore);
        }
    }
    catch (e) {
        console.debug('cant save settings');
        DefaultErrorHandler.showDefaultErrorAlert(e);
    }
};
export function NotificationBellDialog(_props) {
    const [notifications, setNotifications] = useState([]);
    const [onlyUnRead, setOnlyUnRead] = useState(true);
    const t = new Date();
    const limit = new Date(t.getFullYear(), t.getMonth(), t.getDate() - 30, 0, 0, 0, 0);
    useEffect(() => {
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'NotificationBellDialog',
            readModelName: 'LogBook',
            callback: (e) => {
                let forMe = false;
                e.entities.forEach((l) => {
                    const lb = l.entity;
                    if (lb && lb.userIds.findIndex((a) => a.userId === CurrentUser.userId) >= 0)
                        forMe = true;
                });
                if (forMe) {
                    UpmeshClient.instance.modals.logBook
                        .get({
                        filter: `userIds/userId eq '${CurrentUser.userId}' and creator ne '${CurrentUser.userId}' and createdBy ne '${CurrentUser.userId}' and createdAt gt ${limit.toISOString()}`,
                        orderby: 'createdAt DESC',
                        top: 99,
                    })
                        .then((e) => {
                        setNotifications(e);
                    })
                        .catch((err) => console.error(err));
                }
            },
        });
        UpmeshClient.instance.modals.logBook
            .get({
            filter: `userIds/userId eq '${CurrentUser.userId}' and creator ne '${CurrentUser.userId}' and createdBy ne '${CurrentUser.userId}' and createdAt gt ${limit.toISOString()}`,
            orderby: 'createdAt DESC',
            top: 99,
        })
            .then((e) => {
            setNotifications(e);
            return saveLastOpenNotifications();
        })
            .catch((err) => console.error(err));
        return () => {
            UpmeshClient.eventDispatcher.detach('LogBook', 'NotificationBellDialog');
        };
    }, []);
    const getItemKey = (item) => {
        return `logbookEntry_bell_${item.id}`;
    };
    const renderRow = ({ item }) => {
        const hasRead = item.readByUserIds != null && item.readByUserIds.findIndex((a) => a.userId === CurrentUser.userId) > -1;
        const markAsRead = () => {
            if (!hasRead) {
                const markLogBookAsRead = new MarkLogBookAsRead({}, item.id);
                markLogBookAsRead.execute(AuthClient.instance.commandStore).catch((err) => {
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                });
            }
        };
        const markAsNotRead = () => {
            if (hasRead) {
                const markLogBookAsNotRead = new MarkLogBookAsNotRead({}, item.id);
                markLogBookAsNotRead.execute(AuthClient.instance.commandStore).catch((err) => {
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                });
            }
        };
        return (<View style={styles.rowContainer}>
        <View style={styles.rowItemContainer}>
          <LogBookItem hideIcon closeDialogBefore key={`logbookEntry_bell2_${item.id}`} logbookItem={item} deep="multiproject" withUserImage flat/>
        </View>
        <View style={styles.rowReadContainer}>
          {hasRead ? (<Icon key="markAsNotReadIcon" icon="circle-outline" toolTip={I18n.m.getMessage('notificationsMarkAsNotRead')} onPress={markAsNotRead} color="rgb(200, 200, 200)"/>) : (<Icon key="markAsReadIcon" icon="circle-medium" toolTip={I18n.m.getMessage('notificationsMarkAsRead')} onPress={markAsRead} color="rgb(97, 97, 97)"/>)}
        </View>
      </View>);
    };
    const markAllAsRead = (_e) => {
        const unReaded = notifications.filter((item) => {
            return !(item.readByUserIds != null && item.readByUserIds.findIndex((a) => a.userId === CurrentUser.userId) > -1);
        });
        const promises = [];
        unReaded.forEach((item) => {
            promises.push(new Promise((r, re) => {
                const markLogBookAsRead = new MarkLogBookAsRead({}, item.id);
                markLogBookAsRead
                    .execute(AuthClient.instance.commandStore)
                    .then(() => r())
                    .catch((err) => re(err));
            }));
        });
        Promise.all(promises).catch((err) => {
            DefaultErrorHandler.showDefaultErrorAlert(err);
        });
    };
    const tabelData = onlyUnRead
        ? notifications.filter((item) => {
            return !(item.readByUserIds != null && item.readByUserIds.findIndex((a) => a.userId === CurrentUser.userId) > -1);
        })
        : notifications;
    return (<View style={styles.container}>
      <DialogTitle key="title">{`${I18n.m.getMessage('accountNotifications')} (${tabelData.length})`}</DialogTitle>
      <View style={{
            flexDirection: 'row',
            height: 48,
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingHorizontal: 16,
        }}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Switch value={onlyUnRead} labelText="nur ungelesene anzeigen" onChange={(e) => setOnlyUnRead(e)}/>
          <MaterialText type={MaterialTextTypes.Caption} centeredBox>
            {' '}
            {I18n.m.getMessage('notificationsShowOnlyUnread')}
          </MaterialText>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Chip title={I18n.m.getMessage('notificationsMarkAllAsRead')} onPressChip={markAllAsRead}/>
        </View>
      </View>
      <FlatList key="logbook_all" keyExtractor={getItemKey} style={{
            height: (_props.dialogState?.height ? _props.dialogState.height : 0.9 * ResizeEvent.current.contentHeight) - 112,
            width: '100%',
        }} data={tabelData} renderItem={renderRow} onEndReachedThreshold={0.1}/>
    </View>);
}
export const openNotificationBellDialog = (_props) => (e) => {
    const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
    Dialog.instance?.open({
        content: <NotificationBellDialog />,
        fullscreenResponsive: true,
        contentPadding: false,
        openPosition,
        scrollable: false,
        openImmediately: false,
        closeOnTouchOutside: true,
        showCloseIcon: true,
    });
};
