import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { CacheableImage } from 'materialTheme/src/theme/components/images/CacheableImage';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { MaterialTextAsync } from 'materialTheme/src/theme/components/text/MaterialTextAsync';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { UIManager, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { JournalEntity } from 'upmesh-core/src/client/query/entities/JournalEntity';
import * as path from 'path';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { StoredFileDownloader } from '../file/StoredFileDownloader';
import { StoredFileDownloaderOptions } from '../file/StoredFileDownloaderOptions';
import { CurrentProject } from '../project/CurrentProject';
const bytes = require('bytes');
export class JournalPreview extends PureComponent {
    constructor(props) {
        super(props);
        this.receivers = [];
        this.workerCount = 'workerCount';
        this.openActionMenu = (e) => {
            const { selectedDate, canI, previewOrSendIsLoading, openEditDialog, getAsPdf, sendCurrentDraft } = this.props;
            UIManager.measure(e.nativeEvent.target, (_x, _y, width, height, pageX, pageY) => {
                const client = {
                    height,
                    width,
                    x: pageX,
                    y: pageY,
                };
                Menu.instance?.open({
                    client,
                    items: [
                        {
                            text: I18n.m.getMessage('journalSend'),
                            thumbnail: { thumbnail: <Icon icon="share-outline" toolTip=""/>, width: 24 },
                            onPress: () => sendCurrentDraft(selectedDate),
                        },
                        {
                            text: I18n.m.getMessage('journalGetAsPDF'),
                            thumbnail: { thumbnail: <Icon icon="download-outline" toolTip=""/>, width: 24 },
                            disabled: previewOrSendIsLoading || !canI,
                            onPress: () => getAsPdf(selectedDate),
                        },
                        {
                            text: I18n.m.getMessage('edit'),
                            thumbnail: { thumbnail: <Icon icon="pencil-outline" toolTip=""/>, width: 24 },
                            disabled: previewOrSendIsLoading || !canI,
                            onPress: () => openEditDialog(selectedDate),
                        },
                    ],
                });
            });
        };
        this.downloadProtocolFile = (j) => async (_e) => {
            try {
                const link = await CurrentProject.instance.createJournalProtocolLink(j);
                if (link != null) {
                    let orgFilename = j.fileId;
                    if (j.fileName != null) {
                        orgFilename = `${j.fileName}.pdf`;
                    }
                    StoredFileDownloader.downloadFile(new StoredFileDownloaderOptions({
                        link,
                        orgFilename,
                    })).catch((e) => {
                        console.error('cant download', link, e);
                        DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m, e);
                        throw e;
                    });
                }
            }
            catch (e) {
                console.debug('JournalDialog Error', e);
            }
        };
        this.getAllReceivers = async () => {
            const allReceivers = [];
            const team = CurrentProject.instance.getCurrentProjectTeam();
            for (let i = 0; i < team.length; i += 1) {
                const user = await AuthClient.instance.modals.user.getById(team[i].user.userId);
                allReceivers.push({
                    title: `${team[i].user.firstname} ${team[i].user.lastname}`,
                    id: team[i].user.id,
                    thumbnail: <UserImage size={24} user={team[i].user}/>,
                    secondTextLine: user.emails != null ? user.emails.join(', ') : '',
                });
            }
            return allReceivers;
        };
        this.getAttendanceData = (journalData) => {
            const attendanceData = [];
            if (journalData.attendance.length > 0) {
                for (let i = 0; i < journalData.attendance.length; i += 1) {
                    attendanceData.push({ ...journalData.attendance[i], id: `attendanceData${i}` });
                }
            }
            return attendanceData;
        };
        this.getEmployeeData = (journalData) => {
            const employeeData = [];
            if (journalData.employee.length > 0) {
                for (let i = 0; i < journalData.employee.length; i += 1) {
                    employeeData.push({
                        id: `employeeData${i}`,
                        task: journalData.employee[i].task,
                        timeStart: new Date(journalData.employee[i].timeStart),
                        timeEnd: new Date(journalData.employee[i].timeEnd),
                        totalTime: journalData.employee[i].employee['workerCount'] != null
                            ? `${JournalEntity.minutesToTimeString(journalData.employee[i].totalTimeInMinutes)} [${JournalEntity.minutesToTimeString(journalData.employee[i].totalTimeInMinutes * journalData.employee[i].employee['workerCount'])}]`
                            : `${JournalEntity.minutesToTimeString(journalData.employee[i].totalTimeInMinutes)} [${JournalEntity.minutesToTimeString(journalData.employee[i].totalTimeInMinutes)}]`,
                        name: journalData.employee[i].employee['workerCount'] != null
                            ? `${journalData.employee[i].employee[this.workerCount].toString()} ${I18n.m.getMessage('employee')}\n${journalData.employee[i].employee.groupName}`
                            : `${journalData.employee[i].employee['name']}\n${journalData.employee[i].employee.groupName}`,
                    });
                }
            }
            return employeeData;
        };
        this.getUserName = (userId, type = 'userNameOnly') => async () => {
            try {
                const user = await AuthClient.instance.modals.user.getById(userId);
                if (type === 'sender') {
                    return `${I18n.m.getMessage('journalSender')}: ${user.getFullName()}`;
                }
                return user.getFullName();
            }
            catch (e) {
                console.debug('cant get Username', e);
                return '';
            }
        };
        this.getWeatherData = (journalData) => {
            const weatherData = [];
            if (journalData.weather.length > 0) {
                for (let i = 0; i < journalData.weather.length; i += 1) {
                    weatherData.push({ ...journalData.weather[i], id: `weatherData${i}` });
                }
            }
            return weatherData;
        };
        this.init = async () => {
            const allReceivers = await this.getAllReceivers();
            this.setState({ allReceivers });
        };
        this.onChangeReceivers = (chipReceivers) => {
            const updatedRecievers = [];
            for (const item of chipReceivers) {
                if (item.secondTextLine != null && item.secondTextLine.length > 0) {
                    const tmp = item.secondTextLine.split(', ');
                    if (tmp[0].length > 0) {
                        updatedRecievers.push(tmp[0]);
                    }
                }
                else {
                    updatedRecievers.push(item.title);
                }
            }
            this.receivers = updatedRecievers;
        };
        this.multilineRendererEmployee = (item, column, index) => {
            return (<View key={`name__${item.id}${column.keyInData}${index}`} style={[
                    {
                        width: '20%',
                        paddingHorizontal: 16,
                        justifyContent: 'center',
                    },
                    column.style,
                ]}>
        <MaterialText centeredBox fixedWidth="100%">
          {`${item[column.keyInData]?.toString().trim()}`}
        </MaterialText>
      </View>);
        };
        this.temperatureRenderer = (item, column, index) => {
            const temp = column.keyInData === 'temperatureHigh' ? item.temperatureHigh : item.temperatureLow;
            return (<View key={`temp_${item.id}_${column.keyInData}${index}`} style={[
                    {
                        height: 54,
                        width: '15%',
                        justifyContent: 'center',
                        paddingHorizontal: 16,
                    },
                    column.style,
                ]}>
        <View style={{ alignSelf: 'flex-end' }}>
          <MaterialText>{temp == null ? '' : `${temp} °C`}</MaterialText>
        </View>
      </View>);
        };
        this.timeRendererAttendance = (item, column, index) => {
            const time = column.keyInData === 'timeStart' ? item.timeStart : item.timeEnd;
            return (<View key={`time_${item.id}_${column.keyInData}${index}`} style={[
                    {
                        height: 54,
                        width: '15%',
                        justifyContent: 'center',
                        paddingHorizontal: 16,
                    },
                    column.style,
                ]}>
        <View style={{ alignSelf: 'flex-end' }}>
          <MaterialText>{`${new Date(time).getHours()}:${new Date(time).getMinutes() > 9 ? new Date(time).getMinutes() : `0${new Date(time).getMinutes()}`}`}</MaterialText>
        </View>
      </View>);
        };
        this.attendanceColumns = [
            {
                title: I18n.m.getMessage('journalAttendanceCompany'),
                keyInData: 'companyName',
                style: { width: 150 },
                dataType: 'string',
            },
            {
                title: I18n.m.getMessage('journalAttendanceEmployeeCount'),
                keyInData: 'workerCount',
                style: { width: 120 },
                dataType: 'number',
            },
            {
                title: I18n.m.getMessage('journalAttendanceArrival'),
                keyInData: 'timeStart',
                style: { width: 120 },
                cellRenderer: this.timeRendererAttendance,
                dataType: 'Date',
            },
            {
                title: I18n.m.getMessage('journalAttendanceDeparture'),
                keyInData: 'timeEnd',
                style: { width: 120 },
                cellRenderer: this.timeRendererAttendance,
                dataType: 'Date',
            },
            {
                title: I18n.m.getMessage('journalSpecifics'),
                keyInData: 'specifics',
                style: { width: 120 },
                dataType: 'string',
            },
        ];
        this.timeRendererEmployee = (item, column, index) => {
            const time = column.keyInData === 'timeStart' ? item.timeStart : item.timeEnd;
            return (<View key={`time_${item.id}_${column.keyInData}${index}`} style={[
                    {
                        height: 54,
                        width: '15%',
                        justifyContent: 'center',
                        paddingHorizontal: 16,
                    },
                    column.style,
                ]}>
        <View style={{ alignSelf: 'flex-end' }}>
          <MaterialText>{`${new Date(time).getHours()}:${new Date(time).getMinutes() > 9 ? new Date(time).getMinutes() : `0${new Date(time).getMinutes()}`}`}</MaterialText>
        </View>
      </View>);
        };
        this.employeeColumns = [
            {
                title: `${I18n.m.getMessage('journalEmployeeName')}\n${I18n.m.getMessage('journalEmployeesGroup')}`,
                keyInData: 'name',
                style: { width: 150 },
                dataType: 'string',
                cellRenderer: this.multilineRendererEmployee,
            },
            {
                title: I18n.m.getMessage('journalEmployeesJob'),
                keyInData: 'task',
                style: { width: 120 },
                dataType: 'string',
            },
            {
                title: I18n.m.getMessage('journalAttendanceArrival'),
                keyInData: 'timeStart',
                style: { width: 120 },
                cellRenderer: this.timeRendererEmployee,
                dataType: 'Date',
            },
            {
                title: I18n.m.getMessage('journalAttendanceDeparture'),
                keyInData: 'timeEnd',
                style: { width: 120 },
                cellRenderer: this.timeRendererEmployee,
                dataType: 'Date',
            },
            {
                title: I18n.m.getMessage('journalEmployeesHoursAll'),
                keyInData: 'totalTime',
                style: { width: 120 },
                dataType: 'string',
            },
        ];
        this.timeRendererWeather = (item, column, index) => {
            const { weatherData } = this.state;
            const { time } = item;
            const text = weatherData.length === 1
                ? I18n.m.getMessage('journalWeatherAllDay')
                : `${new Date(time).getHours()}:${new Date(time).getMinutes() > 9 ? new Date(time).getMinutes() : `0${new Date(time).getMinutes()}`}`;
            return (<View key={`time_${item.id}_${column.keyInData}${index}`} style={[
                    {
                        height: 54,
                        width: '15%',
                        justifyContent: 'center',
                        paddingHorizontal: 16,
                    },
                    column.style,
                ]}>
        <View style={{ alignSelf: 'flex-end' }}>
          <MaterialText>{text}</MaterialText>
        </View>
      </View>);
        };
        this.weatherConditionRenderer = (item, column, index, small) => {
            const translationString = `journalWeather${item.weatherCondition
                .charAt(0)
                .toUpperCase()}${item.weatherCondition.slice(1)}`;
            return (<View key={`weather_${item.id}_${column.keyInData}${index}`} style={[
                    {
                        height: 54,
                        width: '20%',
                        justifyContent: small ? 'flex-end' : 'center',
                        paddingHorizontal: 16,
                    },
                    column.style,
                ]}>
        <MaterialText fixedWidth="100%" textAlign={small ? 'right' : undefined}>
          {I18n.m.getMessage(translationString)}
        </MaterialText>
      </View>);
        };
        this.weatherColumns = [
            {
                title: I18n.m.getMessage('journalWeatherConditions'),
                keyInData: 'weatherCondition',
                style: { width: 150 },
                dataType: 'string',
                cellRenderer: this.weatherConditionRenderer,
            },
            {
                title: I18n.m.getMessage('journalWeatherTime'),
                keyInData: 'time',
                style: { width: 120 },
                cellRenderer: this.timeRendererWeather,
                dataType: 'Date',
            },
            {
                title: I18n.m.getMessage('journalWeatherTemperatureLow'),
                keyInData: 'temperatureLow',
                style: { width: 120 },
                dataType: 'number',
                cellRenderer: this.temperatureRenderer,
            },
            {
                title: I18n.m.getMessage('journalWeatherTemperatureHigh'),
                keyInData: 'temperatureHigh',
                style: { width: 120 },
                dataType: 'number',
                cellRenderer: this.temperatureRenderer,
            },
            {
                title: I18n.m.getMessage('journalSpecifics'),
                keyInData: 'specifics',
                style: { width: 120 },
                dataType: 'string',
            },
        ];
        this.state = {
            weatherData: this.getWeatherData(props.journalData),
            attendanceData: this.getAttendanceData(props.journalData),
            employeeData: this.getEmployeeData(props.journalData),
            allReceivers: [],
        };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
    }
    renderAdditionalFields() {
        const { journalSettings, journalData } = this.props;
        if (journalSettings == null ||
            journalSettings.additionalFields == null ||
            journalSettings.additionalFields.length === 0)
            return <View />;
        const fields = [];
        for (let i = 0; i < journalSettings.additionalFields.length; i += 1) {
            const setting = journalSettings.additionalFields[i];
            let data;
            if (journalData != null && journalData.additionalFields != null && journalData.additionalFields.length > 0) {
                data = journalData.additionalFields.find((f) => f.id === setting.id);
            }
            if (data && data.content && data.content.length > 0) {
                fields.push(<View key={`field_${setting.name}_${i}`}>
            <View style={{ height: 24 }}/>
            <MaterialText type={MaterialTextTypes.Body1} strong>
              {setting.name}
            </MaterialText>
            <MaterialText>{data.content}</MaterialText>
          </View>);
            }
        }
        return <View>{fields}</View>;
    }
    render() {
        const { journalData } = this.props;
        const { weatherData, attendanceData, employeeData } = this.state;
        return (<View>
        <Card style={{ maxWidth: 1280, width: '100%', alignSelf: 'center' }}>
          <View style={{ width: '100%', padding: 16 }}>
            {this.renderHeadline()}
            <MaterialText type={MaterialTextTypes.Body1} strong>
              {I18n.m.getMessage('journalWeather')}
            </MaterialText>
            <View style={{ height: 16 }}/>
            {weatherData && weatherData.length > 0 ? (<Table tableName="WeatherData" columns={this.weatherColumns} data={weatherData} maxHeight={0} actionItemsLength={0} emptyTableText={I18n.m.getMessage('journalWeatherNoData')} sortBy="time"/>) : (<MaterialText>-</MaterialText>)}
            <View style={{ height: 24 }}/>
            <MaterialText type={MaterialTextTypes.Body1} strong>
              {I18n.m.getMessage('journalEmployee')}
            </MaterialText>
            <View style={{ height: 16 }}/>
            {employeeData && employeeData.length > 0 ? (<Table tableName="EmployeeData" columns={this.employeeColumns} data={employeeData} maxHeight={0} actionItemsLength={0} emptyTableText={I18n.m.getMessage('journalEmployeeNoData')}/>) : (<MaterialText>-</MaterialText>)}
            <View style={{ height: 24 }}/>
            <MaterialText type={MaterialTextTypes.Body1} strong>
              {I18n.m.getMessage('journalAttendances')}
            </MaterialText>
            <View style={{ height: 16 }}/>
            {attendanceData && attendanceData.length > 0 ? (<Table tableName="AttendanceData" columns={this.attendanceColumns} data={attendanceData} maxHeight={0} actionItemsLength={0} emptyTableText={I18n.m.getMessage('journalAttendancesNoData')}/>) : (<MaterialText>-</MaterialText>)}
            <View style={{ height: 24 }}/>
            <MaterialText type={MaterialTextTypes.Body1} strong>
              {I18n.m.getMessage('journalWorkDone')}
            </MaterialText>
            <View style={{ height: 16 }}/>
            <MaterialText>
              {journalData.workDone && journalData.workDone.length > 0 ? journalData.workDone : '-'}
            </MaterialText>
            <View style={{ height: 24 }}/>
            <MaterialText type={MaterialTextTypes.Body1} strong>
              {I18n.m.getMessage('journalSpecialOccurrences')}
            </MaterialText>
            <MaterialText>
              {journalData.specialOccurrences && journalData.specialOccurrences.length > 0
                ? journalData.specialOccurrences
                : '-'}
            </MaterialText>
            {this.renderAdditionalFields()}
            <View style={{ height: 24 }}/>
            <MaterialText type={MaterialTextTypes.Body1} strong>
              {I18n.m.getMessage('reportTicketActivitiesPhotos')}
            </MaterialText>
            <View style={{ height: 16 }}/>
            {this.renderImageAttachments()}
            <View style={{ height: 24 }}/>
            <MaterialText type={MaterialTextTypes.Body1} strong>
              {I18n.m.getMessage('journalAttachments')}
            </MaterialText>
            <View style={{ height: 16 }}/>
            {this.renderAttachments()}
          </View>
        </Card>
        <View style={{ height: 24 }} key="historyPadding"/>
        <Card style={{ maxWidth: 1280, width: '100%', alignSelf: 'center' }}>
          <View style={{ width: '100%', padding: 16 }}>{this.renderProtocols()}</View>
        </Card>
      </View>);
    }
    renderHeadline() {
        const { selectedDate, canI, previewOrSendIsLoading, openEditDialog, getAsPdf, sendCurrentDraft } = this.props;
        return (<View style={{
                paddingBottom: ThemeManager.style.contentPaddingValue,
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                flexDirection: 'row',
                width: '100%',
            }}>
        <MaterialText type={MaterialTextTypes.H6}>
          {I18n.m.getMessage('journalDialogHeader', { date: I18n.m.dateCurrent.localeDateString(selectedDate) })}
        </MaterialText>
        {ResizeEvent.current.contentWidth > ThemeManager.style.breakpointM ? (<View style={{ flexDirection: 'row' }}>
            <Icon icon="share-outline" toolTip={I18n.m.getMessage('journalSend')} onPress={() => sendCurrentDraft(selectedDate)}/>
            <Icon icon="download-outline" toolTip={!canI ? I18n.m.getMessage('forbidden') : I18n.m.getMessage('journalGetAsPDF')} onPress={() => getAsPdf(selectedDate)} disabled={previewOrSendIsLoading || !canI}/>
            <Icon icon="pencil-outline" toolTip={!canI ? I18n.m.getMessage('forbidden') : I18n.m.getMessage('edit')} onPress={() => openEditDialog(selectedDate)} disabled={previewOrSendIsLoading || !canI}/>
          </View>) : (<Icon icon="dots-vertical" toolTip="" onPress={this.openActionMenu} disabled={previewOrSendIsLoading}/>)}
      </View>);
    }
    renderAttachments() {
        const { journalData } = this.props;
        const views = [];
        if (journalData.attachments != null && journalData.attachments.length > 0) {
            journalData.attachments.forEach((a) => {
                if (a.fileId.substr(a.fileId.length - 3) === 'pdf')
                    views.push(<View key={`attachment_${a.fileId}`} style={{ padding: 16, paddingTop: 8, paddingBottom: 16, flexDirection: 'row', alignItems: 'center' }}>
              <View>
                <Icon toolTip={I18n.m.getMessage('download')} icon="file-pdf-box"/>
              </View>
              <View style={{ padding: 4 }}>
                <MaterialText>{`${a.name} (${bytes(a.size)})`}</MaterialText>
                <MaterialText>{I18n.m.dateCurrent.localeDateWithTimeString(a.uploadedOn)}</MaterialText>
                <MaterialTextAsync asyncText={this.getUserName(a.uploadedBy)}>{a.uploadedBy}</MaterialTextAsync>
                
              </View>
            </View>);
            });
        }
        if (views.length < 1) {
            views.push(<View key="noAttach">
          <MaterialText>-</MaterialText>
        </View>);
        }
        return views;
    }
    renderImageAttachments() {
        const { journalData } = this.props;
        const views = [];
        if (journalData.attachments != null && journalData.attachments.length > 0) {
            for (const a of journalData.attachments) {
                if (path.extname(a.fileId).toLowerCase() !== '.pdf') {
                    views.push(<View key={`attachment_${a.fileId}`} style={{ padding: 16, paddingTop: 8, paddingBottom: 16, flexDirection: 'row', alignItems: 'center' }}>
              <View>
                <CacheableImage key={a.fileId} onlyOnConnectedServer tempFileId={a.fileId} source={{
                            uri: `${UpmeshClient.instance.url}/journal/${this.props.journalData.id}/attachment/${a.fileId}`,
                        }} width={ThemeManager.style.getScreenRelativePixelSize(36)} height={ThemeManager.style.getScreenRelativePixelSize(36)}/>
              </View>
              <View style={{ padding: 4 }}>
                <MaterialText>{`${a.name} (${bytes(a.size)})`}</MaterialText>
                <MaterialText>{I18n.m.dateCurrent.localeDateWithTimeString(a.uploadedOn)}</MaterialText>
                <MaterialTextAsync asyncText={this.getUserName(a.uploadedBy)}>{a.uploadedBy}</MaterialTextAsync>
                
              </View>
            </View>);
                }
            }
        }
        if (views.length < 1) {
            views.push(<View key="noAttach">
          <MaterialText>-</MaterialText>
        </View>);
        }
        return views;
    }
    renderProtocols() {
        const { journalProtocol } = this.props;
        const views = [];
        views.push(<View key="history" style={{ width: '100%' }}>
        <MaterialText type={MaterialTextTypes.H6}>{I18n.m.getMessage('journalVersions')}</MaterialText>
      </View>);
        if (journalProtocol != null && journalProtocol.length > 0) {
            journalProtocol.forEach((j) => {
                views.push(<View key={`protocol_${j.id}`} style={{
                        paddingTop: 8,
                        maxWidth: '100%',
                        paddingBottom: 16,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Icon toolTip={I18n.m.getMessage('download')} icon="file-download-outline" onPress={this.downloadProtocolFile(j)}/>
              <View style={{ padding: 4, maxWidth: '100%' }}>
                <MaterialText>{I18n.m.dateCurrent.localeDateWithTimeString(j.createdAt)}</MaterialText>
                <MaterialTextAsync asyncText={this.getUserName(j.createdBy, 'userNameOnly')}>
                  {j.createdBy}
                </MaterialTextAsync>
                {j.sends?.map((s) => {
                        return (<View key={`send_${s.send}`} style={{ padding: 8, flexDirection: 'row', flexWrap: 'wrap', maxWidth: '100%' }}>
                      <MaterialText> - {I18n.m.dateCurrent.localeDateWithTimeString(s.send)} - </MaterialText>
                      <MaterialTextAsync asyncText={this.getUserName(s.sendByUserId, 'sender')}>
                        {s.sendByUserId}
                      </MaterialTextAsync>
                      <MaterialText>
                        {s.receivers.length > 0
                                ? `- ${I18n.m.getMessage('journalRecipient')}: ${s.receivers.join(', ')}`
                                : '-'}
                      </MaterialText>
                    </View>);
                    })}
              </View>
            </View>
            <View style={{ flexDirection: 'row' }}>
              
              
              
              
              
              
              
              <Icon icon="share-outline" toolTip={I18n.m.getMessage('journalSend')} onPress={() => this.props.openSendDialog(j.id)}/>
            </View>
          </View>);
            });
        }
        else {
            views.push(<View key="noProtocol">
          <MaterialText>-</MaterialText>
        </View>);
        }
        return views;
    }
}
