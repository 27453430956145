"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProjectFilter = exports.convertProject = void 0;
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var UserOrCompany_1 = require("../../../server/webserver/UserOrCompany");
var convertProject = function () {
  var _ref = (0, _asyncToGenerator2.default)(function* (project, companyId) {
    var p = project;
    try {
      if (p.ownerName == null) {
        var creaotr = yield UserOrCompany_1.UserOrCompany.getById(p.creator);
        p.ownerName = creaotr.getFullName();
      }
      if (p.creatorName == null) {
        var creatorNotOwner = yield UserOrCompany_1.UserOrCompany.getById(p.createdBy);
        p.creatorName = creatorNotOwner.getFullName();
      }
      if (p.team == null) {
        var team = [];
        var teamMember = yield CommandReadModels_1.CommandReadModels.instance.projectUser.get({
          filter: `projectId eq '${p.id}' and status eq 'accepted'`
        });
        for (var member of teamMember) {
          var user = yield UserOrCompany_1.UserOrCompany.getById(member.userId);
          var role = yield CommandReadModels_1.CommandReadModels.instance.projectRoles.getById(member.roleId);
          team.push({
            user: user,
            roleId: member.roleId,
            roleName: role.roleName,
            username: user.getFullName()
          });
        }
        p.team = [].concat(team);
      }
      if (companyId != null && companyId.length > 0) {
        var queryCompareEnt = yield CommandReadModels_1.CommandReadModels.companyProjectInfo.get({
          filter: `companyId eq '${companyId}' and projectId eq '${p.id}'`
        });
        if (queryCompareEnt != null && queryCompareEnt.length > 0) {
          var statusId = queryCompareEnt[0].projectStateId;
          var companySettings = yield CommandReadModels_1.CommandReadModels.companySettings.getById(companyId);
          var status = companySettings.projectStateNames.find(function (a) {
            return a.id === statusId;
          });
          if (status) p.state = status;
        }
        var costCenter = yield CommandReadModels_1.CommandReadModels.costCenter.get({
          filter: `deleted ne true and companyId eq '${companyId}'`
        });
        var filteredCostCenter = costCenter.filter(function (c) {
          var _c$projectIds;
          return (_c$projectIds = c.projectIds) == null ? void 0 : _c$projectIds.includes(p.id);
        });
        if (filteredCostCenter.length > 0) p.costUnit = `${filteredCostCenter[0].mark} - ${filteredCostCenter[0].description}`;
      }
    } catch (err) {}
    return p;
  });
  return function convertProject(_x, _x2) {
    return _ref.apply(this, arguments);
  };
}();
exports.convertProject = convertProject;
var ProjectFilter = function () {
  function ProjectFilter() {
    (0, _classCallCheck2.default)(this, ProjectFilter);
  }
  return (0, _createClass2.default)(ProjectFilter, null, [{
    key: "filterProjectsByArchived",
    value: function filterProjectsByArchived(projects, showArchived) {
      if (showArchived === true) return projects;
      var filterByArchived = function filterByArchived(element, _index, _array) {
        return element.archived !== true;
      };
      return projects.filter(filterByArchived);
    }
  }, {
    key: "filterProjectsByTemplate",
    value: function filterProjectsByTemplate(projects, showTemplate) {
      if (showTemplate == null) {
        return projects.filter(function (cPro) {
          return !cPro.template;
        });
      }
      if (showTemplate === '1') {
        return projects.filter(function (cPro) {
          return cPro.template;
        });
      }
      return projects;
    }
  }, {
    key: "filterProjectsByBuildTime",
    value: function filterProjectsByBuildTime(projects, buildTime) {
      if (buildTime == null) return projects;
      var filterBy = function filterBy(element, _index, _array) {
        var constructionStart = element.constructionStart,
          constructionEnd = element.constructionEnd;
        if (constructionStart != null && constructionEnd != null) {
          return constructionStart.getTime() <= buildTime && constructionEnd.getTime() >= buildTime;
        }
        return false;
      };
      return projects.filter(filterBy);
    }
  }, {
    key: "filterProjectsByProjectTime",
    value: function filterProjectsByProjectTime(projects, projectTime) {
      if (projectTime == null) return projects;
      var filterBy = function filterBy(element, _index, _array) {
        var projectStart = element.projectStart,
          projectEnd = element.projectEnd;
        if (projectStart != null && projectEnd != null) {
          return projectStart.getTime() <= projectTime && projectEnd.getTime() >= projectTime;
        }
        return false;
      };
      return projects.filter(filterBy);
    }
  }, {
    key: "filterProjectsByOwner",
    value: function filterProjectsByOwner(projects, owner) {
      if (owner == null) return projects;
      var filterBy = function filterBy(element, _index, _array) {
        var creator = element.creator;
        return creator === owner;
      };
      return projects.filter(filterBy);
    }
  }, {
    key: "filterProjectsByCreator",
    value: function filterProjectsByCreator(projects, creators) {
      if (creators == null) return projects;
      var filterBy = function filterBy(element, _index, _array) {
        var createdBy = element.createdBy;
        return (creators == null ? void 0 : creators.findIndex(function (elem) {
          return elem === createdBy;
        })) >= 0;
      };
      return projects.filter(filterBy);
    }
  }, {
    key: "filterByType",
    value: function filterByType(tickets, filter) {
      function filterElements(element, _index, _array) {
        if (filter != null && filter.length > 0) {
          for (var i = 0; i < filter.length; i += 1) {
            if (filter[i] != null && filter[i].length > 0) {
              var _element$projectType;
              if (filter[i].toLowerCase() === 'null' && (element.projectType == null || ((_element$projectType = element.projectType) == null ? void 0 : _element$projectType.length) === 0)) {
                return true;
              }
              if (element.projectType != null && element.projectType.length > 0) {
                if (filter[i].toLowerCase() === element.projectType.toLowerCase()) {
                  return true;
                }
              }
            }
          }
        } else if (element.projectType == null || element.projectType.length === 0) {
          return true;
        }
        return false;
      }
      return tickets.filter(filterElements);
    }
  }, {
    key: "filterByStates",
    value: function filterByStates(tickets, filter) {
      function filterElements(element, _index, _array) {
        if (filter != null && filter.length > 0) {
          for (var i = 0; i < filter.length; i += 1) {
            if (filter[i] != null && filter[i].length > 0) {
              var _element$state;
              if (filter[i].toLowerCase() === 'null' && (element.state == null || ((_element$state = element.state) == null ? void 0 : _element$state.name.length) === 0)) {
                return true;
              }
              if (element.state != null && element.state.name.length > 0) {
                if (filter[i].toLowerCase() === element.state.name.toLowerCase()) {
                  return true;
                }
              }
            }
          }
        } else if (element.state == null || element.state.name.length === 0) {
          return true;
        }
        return false;
      }
      return tickets.filter(filterElements);
    }
  }, {
    key: "filterByMembers",
    value: function filterByMembers(tickets, filter) {
      function filterElements(element, _index, _array) {
        if (filter != null && filter.length > 0) {
          for (var i = 0; i < filter.length; i += 1) {
            if (filter[i] != null && filter[i].length > 0) {
              var _element$team;
              if (filter[i].toLowerCase() === 'null' && (element.team == null || ((_element$team = element.team) == null ? void 0 : _element$team.length) === 0)) {
                return true;
              }
              if (element.team != null && element.team.length > 0) {
                for (var m of element.team) {
                  if (filter[i].toLowerCase() === m.user.id.toLowerCase()) {
                    return true;
                  }
                }
              }
            }
          }
        } else if (element.team == null || element.team.length === 0) {
          return true;
        }
        return false;
      }
      return tickets.filter(filterElements);
    }
  }, {
    key: "filterProjectsByText",
    value: function filterProjectsByText(text, allProjects) {
      var words = text.split(' ');
      if (text.length === 0) return allProjects;
      var filteredProjectsHelper = [];
      var wordLength = 0;
      var _loop = function _loop(word) {
        if (word != null && word.length > 0) {
          wordLength += 1;
          var _filteredProjects = (0, _toConsumableArray2.default)(allProjects).filter(function (project) {
            return ProjectFilter.checkProjectStrings(project, word);
          });
          filteredProjectsHelper.push.apply(filteredProjectsHelper, (0, _toConsumableArray2.default)(_filteredProjects));
        }
      };
      for (var word of words) {
        _loop(word);
      }
      var hits = [];
      var _loop2 = function _loop2(project) {
        var found = hits.findIndex(function (p) {
          return p.id === project.id;
        });
        if (found >= 0) {
          hits[found] = Object.assign({}, hits[found], {
            counted: hits[found].counted + 1
          });
        } else {
          hits.push({
            id: project.id,
            counted: 1
          });
        }
      };
      for (var project of filteredProjectsHelper) {
        _loop2(project);
      }
      hits.sort(function (a, b) {
        return a.counted > b.counted ? -1 : 1;
      });
      var filteredProjects = [];
      if (hits.length > 0) {
        var _loop3 = function _loop3(hit) {
          var found = filteredProjectsHelper.find(function (p) {
            return p.id === hit.id;
          });
          if (found != null) {
            var pro = Object.assign({}, found);
            if (wordLength > 1 && hits.length > 1) {
              pro.title = `${found.title} (${Math.round(hit.counted / wordLength * 100)} %)`;
            }
            filteredProjects.push(pro);
          }
        };
        for (var hit of hits) {
          _loop3(hit);
        }
      }
      return filteredProjects;
    }
  }, {
    key: "checkProjectStrings",
    value: function checkProjectStrings(project, filterText) {
      var containsText = project.title.toLowerCase().includes(filterText.toLowerCase());
      if (!containsText && project.description != null) {
        containsText = project.description.toLowerCase().includes(filterText.toLowerCase());
      }
      if (!containsText && project.projectType != null) {
        containsText = project.projectType.toLowerCase().includes(filterText.toLowerCase());
      }
      return containsText;
    }
  }]);
}();
exports.ProjectFilter = ProjectFilter;
ProjectFilter.filterProjects = function (projects, filter) {
  var buildTime = filter.buildTime,
    projectTime = filter.projectTime,
    myProjects = filter.myProjects,
    archived = filter.archived,
    words = filter.words,
    projectTypes = filter.projectTypes,
    projectStates = filter.projectStates,
    projectMembers = filter.projectMembers,
    projectCreators = filter.projectCreators,
    showTemplate = filter.showTemplate;
  var filteredProjects = ProjectFilter.filterProjectsByArchived(projects, archived);
  filteredProjects = ProjectFilter.filterProjectsByTemplate(filteredProjects, showTemplate);
  filteredProjects = ProjectFilter.filterProjectsByBuildTime(filteredProjects, buildTime);
  filteredProjects = ProjectFilter.filterProjectsByProjectTime(filteredProjects, projectTime);
  if (projectTypes != null && projectTypes.length > 0) filteredProjects = ProjectFilter.filterByType(filteredProjects, projectTypes);
  if (projectStates != null && projectStates.length > 0) filteredProjects = ProjectFilter.filterByStates(filteredProjects, projectStates);
  if (projectMembers != null && projectMembers.length > 0) filteredProjects = ProjectFilter.filterByMembers(filteredProjects, projectMembers);
  filteredProjects = ProjectFilter.filterProjectsByOwner(filteredProjects, myProjects);
  if (projectCreators != null && projectCreators.length > 0) filteredProjects = ProjectFilter.filterProjectsByCreator(filteredProjects, projectCreators);
  if (words != null && words.length > 0) {
    filteredProjects = ProjectFilter.filterProjectsByText(words, filteredProjects);
  }
  return filteredProjects;
};