import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React from 'react';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { UserEntity } from 'upmesh-auth-core/src/client/query/entities/UserEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
export class Collaborator {
    static async getCollaboratorList(storageKey, forType, memberIds) {
        const collaboratorList = [];
        const collaboratorGroups = [];
        const me = await AuthClient.instance.modals.user.getById(CurrentUser.userId);
        const companyMemberMe = await UpmeshClient.instance.modals.companyMember.get({
            filter: `userId eq '${me.id}' and deleted ne true`,
            top: 1,
        });
        let selectedCollaborators = [];
        collaboratorList.push({
            title: me.getFullName(),
            thumbnail: <UserImage user={me} size={24}/>,
            id: companyMemberMe[0].id,
            groupId: companyMemberMe[0].groupId,
        });
        if (memberIds == null)
            selectedCollaborators.push(collaboratorList[0]);
        if (companyMemberMe[0][forType] === 'self')
            return { collaboratorList, selectedCollaborators, collaboratorGroups };
        const collaborators = await UpmeshClient.instance.modals.companyMember.get({
            filter: `deleted ne true and role ne 'inactive'`,
            orderby: 'firstName',
        });
        for (let i = 0; i < collaborators.length; i += 1) {
            const collaborator = collaborators[i];
            let fullName = `${collaborator.firstName} ${collaborator.lastName}`;
            let user = new UserEntity({
                firstname: collaborator.firstName,
                lastname: collaborator.lastName,
                id: collaborator.id,
            });
            if (collaborator.userId != null && collaborator.userId.length > 0) {
                try {
                    user = await AuthClient.instance.modals.user.getById(collaborator.userId);
                    fullName = user.getFullName();
                }
                catch (e) {
                    console.debug('cant get user', e);
                }
            }
            if (user != null &&
                user.userId !== CurrentUser.userId &&
                (companyMemberMe[0][forType] === 'all' ||
                    (companyMemberMe[0][forType] === 'group' && companyMemberMe[0].groupId === collaborator.groupId))) {
                const newCollege = {
                    title: fullName,
                    thumbnail: <UserImage user={user} size={24}/>,
                    id: collaborator.id,
                    groupId: collaborator.groupId,
                };
                collaboratorList.push(newCollege);
            }
        }
        try {
            if (memberIds != null && memberIds.length > 0) {
                selectedCollaborators = [];
                for (const id of memberIds) {
                    const i = collaboratorList.findIndex((c) => c.id === id);
                    if (i > -1) {
                        selectedCollaborators.push(collaboratorList[i]);
                    }
                    else {
                        console.warn('member Id existiert nicht mehr in company, dies sollte vorher abgefangen werden', id, companyMemberMe[0].companyId);
                    }
                }
            }
            else if (memberIds == null) {
                const last = await SimpleStorage.get(storageKey);
                if (last != null) {
                    const col = JSON.parse(last);
                    if (col != null && col.length > 0) {
                        selectedCollaborators = [];
                        col.forEach((e) => {
                            const i = collaboratorList.findIndex((c) => c.id === e.id);
                            if (i > -1) {
                                selectedCollaborators.push(collaboratorList[i]);
                            }
                        });
                    }
                }
            }
            const groupEntities = await UpmeshClient.instance.modals.group.get({
                filter: `deleted ne true and companyId eq '${companyMemberMe[0].companyId}'`,
            });
            groupEntities.forEach((group) => {
                collaboratorGroups.push({
                    id: group.id,
                    groupName: group.groupName !== 'default_employees' ? group.groupName : I18n.m.getMessage('companyGroupDefaultName'),
                    backgroundColor: 'transparent',
                });
            });
        }
        catch (e) {
            console.debug('cant get lastTimeTrackingCollaborators', e);
        }
        return { collaboratorList, selectedCollaborators, collaboratorGroups };
    }
}
