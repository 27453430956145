var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddressCard = AddressCard;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _AuthClient = require("upmesh-auth-core/build/src/client/AuthClient");
var _AddressEntity = require("upmesh-auth-core/build/src/client/query/entities/AddressEntity");
var uuid = _interopRequireWildcard(require("uuid"));
var _I18n = require("../../i18n/I18n");
var _ThemeManager = require("../../ThemeManager");
var _ContainedButton = require("../button/ContainedButton");
var _Dialog = require("../Dialog");
var _Icon = require("../Icon");
var _MaterialText = require("../text/MaterialText");
var _MapMarker = require("../zoom/MapMarker");
var _ZoomTile = require("../zoom/ZoomTile");
var _AddressCardForm = require("./AddressCardForm");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var styles = {
  lineContainer: {
    width: '100%',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 8
  },
  textContainer: {
    flex: 1,
    alignContent: 'flex-start',
    justifyContent: 'flex-start'
  }
};
function AddressCard(props) {
  var _useState = (0, _react.useState)(props.address != null ? props.address : undefined),
    _useState2 = (0, _slicedToArray2.default)(_useState, 2),
    address = _useState2[0],
    setAddress = _useState2[1];
  (0, _react.useEffect)(function () {
    if (props.address != null) setAddress(props.address);
  }, [props.address]);
  var onSave = function onSave(toSave) {
    var _Dialog$instance;
    setAddress(toSave);
    props.onChangeAddress(toSave);
    (_Dialog$instance = _Dialog.Dialog.instance) == null ? void 0 : _Dialog$instance.close();
  };
  var onCancel = function onCancel() {
    var _Dialog$instance2;
    (_Dialog$instance2 = _Dialog.Dialog.instance) == null ? void 0 : _Dialog$instance2.close();
  };
  var openDialog = function () {
    var _ref = (0, _asyncToGenerator2.default)(function* () {
      var _Dialog$instance3;
      var nameAC = props.nameAC,
        phoneAC = props.phoneAC,
        emailAC = props.emailAC,
        backendURL = props.backendURL;
      var change = address != null;
      var pos = {
        longitude: 10.063476562500002,
        latitude: 51.754240074033525
      };
      if (address == null) {
        if (navigator.geolocation != null) {
          pos = yield new Promise(function (resolve) {
            navigator.geolocation.getCurrentPosition(function (position) {
              if (position['coords']['latitude'] != null && position['coords']['longitude'] != null) {
                resolve({
                  latitude: position['coords']['latitude'],
                  longitude: position['coords']['longitude']
                });
              }
            }, function (error) {
              resolve({
                longitude: 10.063476562500002,
                latitude: 51.754240074033525
              });
            });
          });
        }
      }
      var add = address == null ? new _AddressEntity.AddressEntity(Object.assign({}, pos, {
        latitudeDelta: 1,
        longitudeDelta: 1
      })) : address;
      var hasServerConnection = _AuthClient.AuthClient.instance.serverConnection !== undefined && _AuthClient.AuthClient.instance.serverConnection.isConnected;
      (_Dialog$instance3 = _Dialog.Dialog.instance) == null ? void 0 : _Dialog$instance3.open({
        fullscreenResponsive: _reactNativeWeb.Platform.OS === 'web',
        fullscreen: _reactNativeWeb.Platform.OS !== 'web',
        showCloseButton: false,
        showCloseIcon: !hasServerConnection,
        scrollable: true,
        contentPadding: !hasServerConnection,
        onClose: onCancel,
        scrollContainerStyle: {
          paddingBottom: 8
        },
        maxWidth: 1024,
        width: 1024,
        content: hasServerConnection ? (0, _jsxRuntime.jsx)(_AddressCardForm.AddressCardForm, {
          backendURL: backendURL,
          change: change,
          address: add,
          onSave: onSave,
          onCancel: onCancel,
          nameAC: nameAC,
          phoneAC: phoneAC,
          emailAC: emailAC
        }, uuid.v4()) : _I18n.I18n.m.getMessage('commandOfflineNotPossible')
      });
    });
    return function openDialog() {
      return _ref.apply(this, arguments);
    };
  }();
  var renderNoAddressScreen = function renderNoAddressScreen() {
    var editable = props.editable;
    return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
      style: _ThemeManager.ThemeManager.style.contentPadding,
      children: [(0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
        type: _MaterialText.MaterialTextTypes.H5,
        children: _I18n.I18n.m.getMessage('addressContactAndWeather')
      }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
        children: _I18n.I18n.m.getMessage('addressAddText')
      }), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
        title: _I18n.I18n.m.getMessage('addressAdd'),
        onPress: openDialog,
        disabled: !editable,
        backgroundColor: "transparent",
        textColor: _ThemeManager.ThemeManager.style.brandPrimary
      })]
    });
  };
  var openUrl = function openUrl(url) {
    return function (_e) {
      _reactNativeWeb.Linking.openURL(url).catch(function (err) {
        return void 0;
      });
    };
  };
  var renderMail = function renderMail() {
    if (address == null || address.email == null || address.email.length === 0) {
      return null;
    }
    return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
      style: styles.lineContainer,
      children: [(0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: styles.textContainer,
        children: [(0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          fixedWidth: "100%",
          type: _MaterialText.MaterialTextTypes.Caption,
          numberOfLines: 1,
          children: _I18n.I18n.m.getMessage('email')
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          children: address.email
        })]
      }), (0, _jsxRuntime.jsx)(_Icon.Icon, {
        onPress: openUrl(`mailto:${address.email}`),
        icon: "email-outline",
        color: _ThemeManager.ThemeManager.style.brandPrimary,
        toolTip: _I18n.I18n.m.getMessage('email'),
        backgroundColor: "#FFFFFF",
        borderStyle: "solid"
      })]
    });
  };
  var renderPhone = function renderPhone() {
    if (address == null || address.phone == null || address.phone.length === 0) {
      return null;
    }
    return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
      style: styles.lineContainer,
      children: [(0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: styles.textContainer,
        children: [(0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          fixedWidth: "100%",
          type: _MaterialText.MaterialTextTypes.Caption,
          numberOfLines: 1,
          children: _I18n.I18n.m.getMessage('addressPhone')
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          children: address.phone
        })]
      }), (0, _jsxRuntime.jsx)(_Icon.Icon, {
        onPress: openUrl(`tel:${address.phone}`),
        icon: "phone",
        color: _ThemeManager.ThemeManager.style.brandPrimary,
        toolTip: _I18n.I18n.m.getMessage('addressCall'),
        backgroundColor: "#FFFFFF",
        borderStyle: "solid"
      })]
    });
  };
  var renderAddressString = function renderAddressString() {
    if (address == null || address.addressInput == null || address.addressInput.length === 0) {
      return null;
    }
    return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
      style: styles.lineContainer,
      children: [(0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: styles.textContainer,
        children: [(0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          fixedWidth: "100%",
          type: _MaterialText.MaterialTextTypes.Caption,
          children: _I18n.I18n.m.getMessage('address')
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          children: address.addressInput
        })]
      }), address.longitude != null && address.latitude != null && address.latitude !== 0 && address.longitude !== 0 ? (0, _jsxRuntime.jsx)(_Icon.Icon, {
        onPress: openUrl(`https://www.google.de/maps/dir//'${address.latitude},${address.longitude}'/@${address.latitude},${address.longitude},18z`),
        icon: "directions",
        color: "#FFFFFF",
        toolTip: _I18n.I18n.m.getMessage('address'),
        backgroundColor: _ThemeManager.ThemeManager.style.brandPrimary
      }) : null]
    });
  };
  var renderCaption = function renderCaption() {
    var type = props.type,
      editable = props.editable;
    if (address == null) {
      return null;
    }
    return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
      style: styles.lineContainer,
      children: [(0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: styles.textContainer,
        children: [(0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          fixedWidth: "100%",
          type: _MaterialText.MaterialTextTypes.Caption,
          children: type
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          type: _MaterialText.MaterialTextTypes.H6,
          children: address.contactName.length !== 0 ? address.contactName : address.building.length !== 0 ? address.building : ''
        }), address.contactFunction != null && address.contactFunction.length > 0 && (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            width: '100%',
            paddingTop: 8
          },
          children: [(0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            fixedWidth: "100%",
            type: _MaterialText.MaterialTextTypes.Caption,
            children: _I18n.I18n.m.getMessage('addressContactFunction')
          }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            children: address.contactFunction
          })]
        })]
      }), !props.hideEdit && (0, _jsxRuntime.jsx)(_Icon.Icon, {
        onPress: openDialog,
        icon: "pencil-outline",
        toolTip: _I18n.I18n.m.getMessage('edit'),
        disabled: !editable
      })]
    });
  };
  var hasAddress = function hasAddress() {
    if (address != null) {
      if (address.addressInput != null && address.addressInput.length > 0) {
        return true;
      }
      if (address.phone != null && address.phone.length > 0) {
        return true;
      }
      if (address.contactFunction != null && address.contactFunction.length > 0) {
        return true;
      }
      if (address.email != null && address.email.length > 0) {
        return true;
      }
      if (address.contactName != null && address.contactName.length > 0) {
        return true;
      }
    }
    return false;
  };
  var renderMap = function renderMap() {
    if (address == null || address.addressInput == null || address.addressInput.length === 0 || address.latitude == null || address.longitude == null || address.latitude === 0 || address.longitude === 0) {
      return null;
    }
    return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      style: {
        width: '100%',
        height: 200,
        overflow: 'hidden'
      },
      children: (0, _jsxRuntime.jsx)(_ZoomTile.ZoomTile, {
        zoomAble: false,
        zoomControlEnabled: false,
        initialView: {
          point: {
            x: address.longitude,
            y: address.latitude
          },
          zoom: 17
        },
        type: "map",
        url: "",
        info: {
          dx: 0,
          dy: 0,
          height: 0,
          imageHeight: 0,
          imageWidth: 0,
          maxLevel: 19,
          orgImageHeight: 0,
          orgImageWidth: 0,
          width: 0
        },
        marker: [new _MapMarker.MapMarker(`marker_${address.bound.toString()}`, 'blue', {
          x: address.longitude,
          y: address.latitude
        })]
      }, `map_${address.bound.toString()}`)
    });
  };
  var renderMapView = function renderMapView() {
    if (address == null) {
      return null;
    }
    return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
      style: {
        width: '100%'
      },
      children: [renderMap(), (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: _ThemeManager.ThemeManager.style.contentPadding,
        children: [renderCaption(), renderAddressString(), renderMail(), renderPhone()]
      })]
    });
  };
  return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
    style: {
      width: '100%'
    },
    children: hasAddress() ? renderMapView() : renderNoAddressScreen()
  });
}