"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Expressions = void 0;
var utils_1 = require("./utils");
var lexer_1 = require("./lexer");
var primitiveLiteral_1 = require("./primitiveLiteral");
var nameOrIdentifier_1 = require("./nameOrIdentifier");
var Expressions;
(function (Expressions) {
  function commonExpr(value, index) {
    var token = primitiveLiteral_1.default.primitiveLiteral(value, index) || parameterAlias(value, index) || Expressions.arrayOrObject(value, index) || rootExpr(value, index) || methodCallExpr(value, index) || firstMemberExpr(value, index) || functionExpr(value, index) || negateExpr(value, index) || parenExpr(value, index) || castExpr(value, index);
    if (!token) return undefined;
    var expr = addExpr(value, token.next) || subExpr(value, token.next) || mulExpr(value, token.next) || divExpr(value, token.next) || modExpr(value, token.next);
    if (expr) {
      token.value = {
        left: lexer_1.default.clone(token),
        right: expr.value
      };
      token.next = expr.value.next;
      token.type = expr.type;
      token.raw = utils_1.default.stringify(value, token.position, token.next);
    }
    if (token) return lexer_1.default.tokenize(value, token.position, token.next, token, lexer_1.default.TokenType.CommonExpression);
    return;
  }
  Expressions.commonExpr = commonExpr;
  function boolCommonExpr(value, index) {
    var token = isofExpr(value, index) || boolMethodCallExpr(value, index) || notExpr(value, index) || commonExpr(value, index) || boolParenExpr(value, index);
    if (!token) return undefined;
    var commonMoreExpr;
    if (token.type === lexer_1.default.TokenType.CommonExpression) {
      commonMoreExpr = eqExpr(value, token.next) || neExpr(value, token.next) || ltExpr(value, token.next) || leExpr(value, token.next) || gtExpr(value, token.next) || geExpr(value, token.next) || inExpr(value, token.next) || hasExpr(value, token.next);
      if (commonMoreExpr) {
        token.value = {
          left: token.value,
          right: commonMoreExpr.value
        };
        token.next = commonMoreExpr.value.next;
        token.type = commonMoreExpr.type;
        token.raw = utils_1.default.stringify(value, token.position, token.next);
      }
    }
    var expr = andExpr(value, token.next) || orExpr(value, token.next);
    if (expr) {
      var left = lexer_1.default.clone(token);
      token.next = expr.value.next;
      token.value = {
        left: left,
        right: expr.value
      };
      token.type = expr.type;
      token.raw = utils_1.default.stringify(value, token.position, token.next);
      if (token.type === lexer_1.default.TokenType.AndExpression && token.value.right.type === lexer_1.default.TokenType.OrExpression) {
        token.value.left = lexer_1.default.tokenize(value, token.value.left.position, token.value.right.value.left.next, {
          left: token.value.left,
          right: token.value.right.value.left
        }, token.type);
        token.type = token.value.right.type;
        token.value.right = token.value.right.value.right;
      }
    }
    return token;
  }
  Expressions.boolCommonExpr = boolCommonExpr;
  function andExpr(value, index) {
    var rws = lexer_1.default.RWS(value, index);
    if (rws === index || !utils_1.default.equals(value, rws, 'and')) return undefined;
    var start = index;
    index = rws + 3;
    rws = lexer_1.default.RWS(value, index);
    if (rws === index) return undefined;
    index = rws;
    var token = boolCommonExpr(value, index);
    if (!token) return undefined;
    return lexer_1.default.tokenize(value, start, index, token, lexer_1.default.TokenType.AndExpression);
  }
  Expressions.andExpr = andExpr;
  function orExpr(value, index) {
    var rws = lexer_1.default.RWS(value, index);
    if (rws === index || !utils_1.default.equals(value, rws, 'or')) return undefined;
    var start = index;
    index = rws + 2;
    rws = lexer_1.default.RWS(value, index);
    if (rws === index) return undefined;
    index = rws;
    var token = boolCommonExpr(value, index);
    if (!token) return undefined;
    return lexer_1.default.tokenize(value, start, index, token, lexer_1.default.TokenType.OrExpression);
  }
  Expressions.orExpr = orExpr;
  function leftRightExpr(value, index, expr, tokenType) {
    var rws = lexer_1.default.RWS(value, index);
    if (rws === index) return undefined;
    var start = index;
    index = rws;
    if (!utils_1.default.equals(value, index, expr)) return undefined;
    index += expr.length;
    rws = lexer_1.default.RWS(value, index);
    if (rws === index) return undefined;
    index = rws;
    var token = commonExpr(value, index);
    if (!token) return undefined;
    return lexer_1.default.tokenize(value, start, index, token.value, tokenType);
  }
  Expressions.leftRightExpr = leftRightExpr;
  function eqExpr(value, index) {
    return leftRightExpr(value, index, 'eq', lexer_1.default.TokenType.EqualsExpression);
  }
  Expressions.eqExpr = eqExpr;
  function neExpr(value, index) {
    return leftRightExpr(value, index, 'ne', lexer_1.default.TokenType.NotEqualsExpression);
  }
  Expressions.neExpr = neExpr;
  function ltExpr(value, index) {
    return leftRightExpr(value, index, 'lt', lexer_1.default.TokenType.LesserThanExpression);
  }
  Expressions.ltExpr = ltExpr;
  function leExpr(value, index) {
    return leftRightExpr(value, index, 'le', lexer_1.default.TokenType.LesserOrEqualsExpression);
  }
  Expressions.leExpr = leExpr;
  function gtExpr(value, index) {
    return leftRightExpr(value, index, 'gt', lexer_1.default.TokenType.GreaterThanExpression);
  }
  Expressions.gtExpr = gtExpr;
  function geExpr(value, index) {
    return leftRightExpr(value, index, 'ge', lexer_1.default.TokenType.GreaterOrEqualsExpression);
  }
  Expressions.geExpr = geExpr;
  function inExpr(value, index) {
    return leftRightExpr(value, index, 'in', lexer_1.default.TokenType.InExpression);
  }
  Expressions.inExpr = inExpr;
  function hasExpr(value, index) {
    return leftRightExpr(value, index, 'has', lexer_1.default.TokenType.HasExpression);
  }
  Expressions.hasExpr = hasExpr;
  function addExpr(value, index) {
    return leftRightExpr(value, index, 'add', lexer_1.default.TokenType.AddExpression);
  }
  Expressions.addExpr = addExpr;
  function subExpr(value, index) {
    return leftRightExpr(value, index, 'sub', lexer_1.default.TokenType.SubExpression);
  }
  Expressions.subExpr = subExpr;
  function mulExpr(value, index) {
    return leftRightExpr(value, index, 'mul', lexer_1.default.TokenType.MulExpression);
  }
  Expressions.mulExpr = mulExpr;
  function divExpr(value, index) {
    return leftRightExpr(value, index, 'div', lexer_1.default.TokenType.DivExpression);
  }
  Expressions.divExpr = divExpr;
  function modExpr(value, index) {
    return leftRightExpr(value, index, 'mod', lexer_1.default.TokenType.ModExpression);
  }
  Expressions.modExpr = modExpr;
  function notExpr(value, index) {
    if (!utils_1.default.equals(value, index, 'not')) return undefined;
    var start = index;
    index += 3;
    var rws = lexer_1.default.RWS(value, index);
    if (rws === index) return undefined;
    index = rws;
    var token = boolCommonExpr(value, index);
    if (!token) return undefined;
    return lexer_1.default.tokenize(value, start, token.next, token, lexer_1.default.TokenType.NotExpression);
  }
  Expressions.notExpr = notExpr;
  function boolParenExpr(value, index) {
    var open = lexer_1.default.OPEN(value, index);
    if (!open) return undefined;
    var start = index;
    index = open;
    index = lexer_1.default.BWS(value, index);
    var token = boolCommonExpr(value, index);
    if (!token) return undefined;
    index = lexer_1.default.BWS(value, token.next);
    var close = lexer_1.default.CLOSE(value, index);
    if (!close) return undefined;
    index = close;
    return lexer_1.default.tokenize(value, start, index, token, lexer_1.default.TokenType.BoolParenExpression);
  }
  Expressions.boolParenExpr = boolParenExpr;
  function parenExpr(value, index) {
    var open = lexer_1.default.OPEN(value, index);
    if (!open) return undefined;
    var start = index;
    index = open;
    index = lexer_1.default.BWS(value, index);
    var token = commonExpr(value, index);
    if (!token) return undefined;
    index = lexer_1.default.BWS(value, token.next);
    var close = lexer_1.default.CLOSE(value, index);
    if (!close) return undefined;
    index = close;
    return lexer_1.default.tokenize(value, start, index, token.value, lexer_1.default.TokenType.ParenExpression);
  }
  Expressions.parenExpr = parenExpr;
  function boolMethodCallExpr(value, index) {
    return endsWithMethodCallExpr(value, index) || startsWithMethodCallExpr(value, index) || containsMethodCallExpr(value, index) || intersectsMethodCallExpr(value, index);
  }
  Expressions.boolMethodCallExpr = boolMethodCallExpr;
  function methodCallExpr(value, index) {
    return indexOfMethodCallExpr(value, index) || toLowerMethodCallExpr(value, index) || toUpperMethodCallExpr(value, index) || trimMethodCallExpr(value, index) || substringMethodCallExpr(value, index) || substringOfMethodCallExpr(value, index) || concatMethodCallExpr(value, index) || lengthMethodCallExpr(value, index) || yearMethodCallExpr(value, index) || monthMethodCallExpr(value, index) || dayMethodCallExpr(value, index) || hourMethodCallExpr(value, index) || minuteMethodCallExpr(value, index) || secondMethodCallExpr(value, index) || fractionalsecondsMethodCallExpr(value, index) || totalsecondsMethodCallExpr(value, index) || dateMethodCallExpr(value, index) || timeMethodCallExpr(value, index) || roundMethodCallExpr(value, index) || floorMethodCallExpr(value, index) || ceilingMethodCallExpr(value, index) || distanceMethodCallExpr(value, index) || geoLengthMethodCallExpr(value, index) || totalOffsetMinutesMethodCallExpr(value, index) || minDateTimeMethodCallExpr(value, index) || maxDateTimeMethodCallExpr(value, index) || nowMethodCallExpr(value, index);
  }
  Expressions.methodCallExpr = methodCallExpr;
  function methodCallExprFactory(value, index, method, min, max) {
    if (typeof min === 'undefined') min = 0;
    if (typeof max === 'undefined') max = min;
    if (!utils_1.default.equals(value, index, method)) return undefined;
    var start = index;
    index += method.length;
    var open = lexer_1.default.OPEN(value, index);
    if (!open) return undefined;
    index = open;
    index = lexer_1.default.BWS(value, index);
    var parameters;
    if (min > 0) {
      parameters = [];
      while (parameters.length < max) {
        var expr = commonExpr(value, index);
        if (parameters.length < min && !expr) return undefined;
        if (expr) {
          parameters.push(expr.value);
          index = expr.next;
          index = lexer_1.default.BWS(value, index);
          var comma = lexer_1.default.COMMA(value, index);
          if (parameters.length < min && !comma) return undefined;
          if (comma) index = comma;else break;
          index = lexer_1.default.BWS(value, index);
        } else break;
      }
    }
    index = lexer_1.default.BWS(value, index);
    var close = lexer_1.default.CLOSE(value, index);
    if (!close) return undefined;
    index = close;
    return lexer_1.default.tokenize(value, start, index, {
      method: method,
      parameters: parameters
    }, lexer_1.default.TokenType.MethodCallExpression);
  }
  Expressions.methodCallExprFactory = methodCallExprFactory;
  function containsMethodCallExpr(value, index) {
    return methodCallExprFactory(value, index, 'contains', 2);
  }
  Expressions.containsMethodCallExpr = containsMethodCallExpr;
  function startsWithMethodCallExpr(value, index) {
    return methodCallExprFactory(value, index, 'startswith', 2);
  }
  Expressions.startsWithMethodCallExpr = startsWithMethodCallExpr;
  function endsWithMethodCallExpr(value, index) {
    return methodCallExprFactory(value, index, 'endswith', 2);
  }
  Expressions.endsWithMethodCallExpr = endsWithMethodCallExpr;
  function lengthMethodCallExpr(value, index) {
    return methodCallExprFactory(value, index, 'length', 1);
  }
  Expressions.lengthMethodCallExpr = lengthMethodCallExpr;
  function indexOfMethodCallExpr(value, index) {
    return methodCallExprFactory(value, index, 'indexof', 2);
  }
  Expressions.indexOfMethodCallExpr = indexOfMethodCallExpr;
  function substringMethodCallExpr(value, index) {
    return methodCallExprFactory(value, index, 'substring', 2, 3);
  }
  Expressions.substringMethodCallExpr = substringMethodCallExpr;
  function substringOfMethodCallExpr(value, index) {
    return methodCallExprFactory(value, index, 'substringof', 2);
  }
  Expressions.substringOfMethodCallExpr = substringOfMethodCallExpr;
  function toLowerMethodCallExpr(value, index) {
    return methodCallExprFactory(value, index, 'tolower', 1);
  }
  Expressions.toLowerMethodCallExpr = toLowerMethodCallExpr;
  function toUpperMethodCallExpr(value, index) {
    return methodCallExprFactory(value, index, 'toupper', 1);
  }
  Expressions.toUpperMethodCallExpr = toUpperMethodCallExpr;
  function trimMethodCallExpr(value, index) {
    return methodCallExprFactory(value, index, 'trim', 1);
  }
  Expressions.trimMethodCallExpr = trimMethodCallExpr;
  function concatMethodCallExpr(value, index) {
    return methodCallExprFactory(value, index, 'concat', 2);
  }
  Expressions.concatMethodCallExpr = concatMethodCallExpr;
  function yearMethodCallExpr(value, index) {
    return methodCallExprFactory(value, index, 'year', 1);
  }
  Expressions.yearMethodCallExpr = yearMethodCallExpr;
  function monthMethodCallExpr(value, index) {
    return methodCallExprFactory(value, index, 'month', 1);
  }
  Expressions.monthMethodCallExpr = monthMethodCallExpr;
  function dayMethodCallExpr(value, index) {
    return methodCallExprFactory(value, index, 'day', 1);
  }
  Expressions.dayMethodCallExpr = dayMethodCallExpr;
  function hourMethodCallExpr(value, index) {
    return methodCallExprFactory(value, index, 'hour', 1);
  }
  Expressions.hourMethodCallExpr = hourMethodCallExpr;
  function minuteMethodCallExpr(value, index) {
    return methodCallExprFactory(value, index, 'minute', 1);
  }
  Expressions.minuteMethodCallExpr = minuteMethodCallExpr;
  function secondMethodCallExpr(value, index) {
    return methodCallExprFactory(value, index, 'second', 1);
  }
  Expressions.secondMethodCallExpr = secondMethodCallExpr;
  function fractionalsecondsMethodCallExpr(value, index) {
    return methodCallExprFactory(value, index, 'fractionalseconds', 1);
  }
  Expressions.fractionalsecondsMethodCallExpr = fractionalsecondsMethodCallExpr;
  function totalsecondsMethodCallExpr(value, index) {
    return methodCallExprFactory(value, index, 'totalseconds', 1);
  }
  Expressions.totalsecondsMethodCallExpr = totalsecondsMethodCallExpr;
  function dateMethodCallExpr(value, index) {
    return methodCallExprFactory(value, index, 'date', 1);
  }
  Expressions.dateMethodCallExpr = dateMethodCallExpr;
  function timeMethodCallExpr(value, index) {
    return methodCallExprFactory(value, index, 'time', 1);
  }
  Expressions.timeMethodCallExpr = timeMethodCallExpr;
  function totalOffsetMinutesMethodCallExpr(value, index) {
    return methodCallExprFactory(value, index, 'totaloffsetminutes', 1);
  }
  Expressions.totalOffsetMinutesMethodCallExpr = totalOffsetMinutesMethodCallExpr;
  function minDateTimeMethodCallExpr(value, index) {
    return methodCallExprFactory(value, index, 'mindatetime', 0);
  }
  Expressions.minDateTimeMethodCallExpr = minDateTimeMethodCallExpr;
  function maxDateTimeMethodCallExpr(value, index) {
    return methodCallExprFactory(value, index, 'maxdatetime', 0);
  }
  Expressions.maxDateTimeMethodCallExpr = maxDateTimeMethodCallExpr;
  function nowMethodCallExpr(value, index) {
    return methodCallExprFactory(value, index, 'now', 0);
  }
  Expressions.nowMethodCallExpr = nowMethodCallExpr;
  function roundMethodCallExpr(value, index) {
    return methodCallExprFactory(value, index, 'round', 1);
  }
  Expressions.roundMethodCallExpr = roundMethodCallExpr;
  function floorMethodCallExpr(value, index) {
    return methodCallExprFactory(value, index, 'floor', 1);
  }
  Expressions.floorMethodCallExpr = floorMethodCallExpr;
  function ceilingMethodCallExpr(value, index) {
    return methodCallExprFactory(value, index, 'ceiling', 1);
  }
  Expressions.ceilingMethodCallExpr = ceilingMethodCallExpr;
  function distanceMethodCallExpr(value, index) {
    return methodCallExprFactory(value, index, 'geo.distance', 2);
  }
  Expressions.distanceMethodCallExpr = distanceMethodCallExpr;
  function geoLengthMethodCallExpr(value, index) {
    return methodCallExprFactory(value, index, 'geo.length', 1);
  }
  Expressions.geoLengthMethodCallExpr = geoLengthMethodCallExpr;
  function intersectsMethodCallExpr(value, index) {
    return methodCallExprFactory(value, index, 'geo.intersects', 2);
  }
  Expressions.intersectsMethodCallExpr = intersectsMethodCallExpr;
  function isofExpr(value, index) {
    if (!utils_1.default.equals(value, index, 'isof')) return undefined;
    var start = index;
    index += 4;
    var open = lexer_1.default.OPEN(value, index);
    if (!open) return undefined;
    index = open;
    index = lexer_1.default.BWS(value, index);
    var expr = commonExpr(value, index);
    if (expr) {
      index = expr.next;
      index = lexer_1.default.BWS(value, index);
      var comma = lexer_1.default.COMMA(value, index);
      if (!comma) return undefined;
      index = comma;
      index = lexer_1.default.BWS(value, index);
    }
    var typeName = nameOrIdentifier_1.default.qualifiedTypeName(value, index);
    if (!typeName) return undefined;
    index = typeName.next;
    index = lexer_1.default.BWS(value, index);
    var close = lexer_1.default.CLOSE(value, index);
    if (!close) return undefined;
    index = close;
    return lexer_1.default.tokenize(value, start, index, {
      target: expr,
      typename: typeName
    }, lexer_1.default.TokenType.IsOfExpression);
  }
  Expressions.isofExpr = isofExpr;
  function castExpr(value, index) {
    if (!utils_1.default.equals(value, index, 'cast')) return undefined;
    var start = index;
    index += 4;
    var open = lexer_1.default.OPEN(value, index);
    if (!open) return undefined;
    index = open;
    index = lexer_1.default.BWS(value, index);
    var expr = commonExpr(value, index);
    if (expr) {
      index = expr.next;
      index = lexer_1.default.BWS(value, index);
      var comma = lexer_1.default.COMMA(value, index);
      if (!comma) return undefined;
      index = comma;
      index = lexer_1.default.BWS(value, index);
    }
    var typeName = nameOrIdentifier_1.default.qualifiedTypeName(value, index);
    if (!typeName) return undefined;
    index = typeName.next;
    index = lexer_1.default.BWS(value, index);
    var close = lexer_1.default.CLOSE(value, index);
    if (!close) return undefined;
    index = close;
    return lexer_1.default.tokenize(value, start, index, {
      target: expr,
      typename: typeName
    }, lexer_1.default.TokenType.CastExpression);
  }
  Expressions.castExpr = castExpr;
  function negateExpr(value, index) {
    if (value[index] !== 0x2d) return undefined;
    var start = index;
    index++;
    index = lexer_1.default.BWS(value, index);
    var expr = commonExpr(value, index);
    if (!expr) return undefined;
    return lexer_1.default.tokenize(value, start, expr.next, expr, lexer_1.default.TokenType.NegateExpression);
  }
  Expressions.negateExpr = negateExpr;
  function firstMemberExpr(value, index) {
    var token = inscopeVariableExpr(value, index);
    var member;
    var start = index;
    if (token) {
      if (value[token.next] === 0x2f) {
        index = token.next + 1;
        member = memberExpr(value, index);
        if (!member) return undefined;
        return lexer_1.default.tokenize(value, start, member.next, [token, member], lexer_1.default.TokenType.FirstMemberExpression);
      }
    } else member = memberExpr(value, index);
    token = token || member;
    if (!token) return undefined;
    return lexer_1.default.tokenize(value, start, token.next, token, lexer_1.default.TokenType.FirstMemberExpression);
  }
  Expressions.firstMemberExpr = firstMemberExpr;
  function memberExpr(value, index) {
    var start = index;
    var token = nameOrIdentifier_1.default.qualifiedEntityTypeName(value, index);
    if (token) {
      if (value[token.next] !== 0x2f) return undefined;
      index = token.next + 1;
    }
    var next = propertyPathExpr(value, index) || boundFunctionExpr(value, index);
    if (!next) return undefined;
    return lexer_1.default.tokenize(value, start, next.next, token ? {
      name: token,
      value: next
    } : next, lexer_1.default.TokenType.MemberExpression);
  }
  Expressions.memberExpr = memberExpr;
  function propertyPathExpr(value, index) {
    var token = nameOrIdentifier_1.default.odataIdentifier(value, index);
    var start = index;
    if (token) {
      index = token.next;
      var nav = collectionPathExpr(value, token.next) || collectionNavigationExpr(value, token.next) || singleNavigationExpr(value, token.next) || complexPathExpr(value, token.next) || singlePathExpr(value, token.next);
      if (nav) {
        index = nav.next;
        token = {
          current: lexer_1.default.clone(token),
          next: nav
        };
      }
    } else if (!token) {
      token = nameOrIdentifier_1.default.streamProperty(value, index);
      if (token) index = token.next;
    }
    if (!token) return undefined;
    return lexer_1.default.tokenize(value, start, index, token, lexer_1.default.TokenType.PropertyPathExpression);
  }
  Expressions.propertyPathExpr = propertyPathExpr;
  function inscopeVariableExpr(value, index) {
    return implicitVariableExpr(value, index) || (isLambdaPredicate ? lambdaVariableExpr(value, index) : undefined);
  }
  Expressions.inscopeVariableExpr = inscopeVariableExpr;
  function implicitVariableExpr(value, index) {
    if (utils_1.default.equals(value, index, '$it')) return lexer_1.default.tokenize(value, index, index + 3, '$it', lexer_1.default.TokenType.ImplicitVariableExpression);
    return;
  }
  Expressions.implicitVariableExpr = implicitVariableExpr;
  var isLambdaPredicate = false;
  var hasLambdaVariableExpr = false;
  function lambdaVariableExpr(value, index) {
    var token = nameOrIdentifier_1.default.odataIdentifier(value, index, lexer_1.default.TokenType.LambdaVariableExpression);
    if (token) {
      hasLambdaVariableExpr = true;
      return token;
    }
    return;
  }
  Expressions.lambdaVariableExpr = lambdaVariableExpr;
  function lambdaPredicateExpr(value, index) {
    isLambdaPredicate = true;
    var token = boolCommonExpr(value, index);
    isLambdaPredicate = false;
    if (token && hasLambdaVariableExpr) {
      hasLambdaVariableExpr = false;
      return lexer_1.default.tokenize(value, token.position, token.next, token, lexer_1.default.TokenType.LambdaPredicateExpression);
    }
    return;
  }
  Expressions.lambdaPredicateExpr = lambdaPredicateExpr;
  function anyExpr(value, index) {
    if (!utils_1.default.equals(value, index, 'any')) return undefined;
    var start = index;
    index += 3;
    var open = lexer_1.default.OPEN(value, index);
    if (!open) return undefined;
    index = open;
    index = lexer_1.default.BWS(value, index);
    var variable = lambdaVariableExpr(value, index);
    var predicate;
    if (variable) {
      index = variable.next;
      index = lexer_1.default.BWS(value, index);
      var colon = lexer_1.default.COLON(value, index);
      if (!colon) return undefined;
      index = colon;
      index = lexer_1.default.BWS(value, index);
      predicate = lambdaPredicateExpr(value, index);
      if (!predicate) return undefined;
      index = predicate.next;
    }
    index = lexer_1.default.BWS(value, index);
    var close = lexer_1.default.CLOSE(value, index);
    if (!close) return undefined;
    index = close;
    return lexer_1.default.tokenize(value, start, index, {
      variable: variable,
      predicate: predicate
    }, lexer_1.default.TokenType.AnyExpression);
  }
  Expressions.anyExpr = anyExpr;
  function allExpr(value, index) {
    if (!utils_1.default.equals(value, index, 'all')) return undefined;
    var start = index;
    index += 3;
    var open = lexer_1.default.OPEN(value, index);
    if (!open) return undefined;
    index = open;
    index = lexer_1.default.BWS(value, index);
    var variable = lambdaVariableExpr(value, index);
    if (!variable) return undefined;
    index = variable.next;
    index = lexer_1.default.BWS(value, index);
    var colon = lexer_1.default.COLON(value, index);
    if (!colon) return undefined;
    index = colon;
    index = lexer_1.default.BWS(value, index);
    var predicate = lambdaPredicateExpr(value, index);
    if (!predicate) return undefined;
    index = predicate.next;
    index = lexer_1.default.BWS(value, index);
    var close = lexer_1.default.CLOSE(value, index);
    if (!close) return undefined;
    index = close;
    return lexer_1.default.tokenize(value, start, index, {
      variable: variable,
      predicate: predicate
    }, lexer_1.default.TokenType.AllExpression);
  }
  Expressions.allExpr = allExpr;
  function collectionNavigationExpr(value, index) {
    var start = index;
    var entity;
    var predicate;
    var navigation;
    var path;
    if (value[index] === 0x2f) {
      index++;
      entity = nameOrIdentifier_1.default.qualifiedEntityTypeName(value, index);
      if (!entity) return undefined;
      index = entity.next;
    }
    predicate = keyPredicate(value, index);
    if (predicate) {
      index = predicate.next;
      navigation = singleNavigationExpr(value, index);
      if (navigation) index = navigation.next;
    } else {
      path = collectionPathExpr(value, index);
      if (path) index = path.next;
    }
    if (index > start) {
      return lexer_1.default.tokenize(value, start, index, {
        entity: entity,
        predicate: predicate,
        navigation: navigation,
        path: path
      }, lexer_1.default.TokenType.CollectionNavigationExpression);
    }
    return;
  }
  Expressions.collectionNavigationExpr = collectionNavigationExpr;
  function keyPredicate(value, index, metadataContext) {
    return simpleKey(value, index, metadataContext) || compoundKey(value, index);
  }
  Expressions.keyPredicate = keyPredicate;
  function simpleKey(value, index, metadataContext) {
    var open = lexer_1.default.OPEN(value, index);
    if (!open) return undefined;
    var start = index;
    index = open;
    var token = keyPropertyValue(value, index);
    if (!token) return undefined;
    var close = lexer_1.default.CLOSE(value, token.next);
    if (!close) return undefined;
    var key;
    if (typeof metadataContext === 'object' && metadataContext.key && metadataContext.key.propertyRefs && metadataContext.key.propertyRefs[0] && metadataContext.key.propertyRefs[0].name) {
      key = metadataContext.key.propertyRefs[0].name;
    }
    return lexer_1.default.tokenize(value, start, close, {
      key: key,
      value: token
    }, lexer_1.default.TokenType.SimpleKey);
  }
  Expressions.simpleKey = simpleKey;
  function compoundKey(value, index) {
    var open = lexer_1.default.OPEN(value, index);
    if (!open) return undefined;
    var start = index;
    index = open;
    var pair = keyValuePair(value, index);
    if (!pair) return undefined;
    var keys = [];
    while (pair) {
      keys.push(pair);
      var comma = lexer_1.default.COMMA(value, pair.next);
      if (comma) pair = keyValuePair(value, comma);else pair = undefined;
    }
    index = keys[keys.length - 1].next;
    var close = lexer_1.default.CLOSE(value, index);
    if (!close) return undefined;
    index = close;
    return lexer_1.default.tokenize(value, start, index, keys, lexer_1.default.TokenType.CompoundKey);
  }
  Expressions.compoundKey = compoundKey;
  function keyValuePair(value, index) {
    var prop = nameOrIdentifier_1.default.primitiveKeyProperty(value, index) || keyPropertyAlias(value, index);
    if (!prop) return undefined;
    var eq = lexer_1.default.EQ(value, prop.next);
    if (!eq) return undefined;
    var val = keyPropertyValue(value, eq);
    if (val) {
      return lexer_1.default.tokenize(value, index, val.next, {
        key: prop,
        value: val
      }, lexer_1.default.TokenType.KeyValuePair);
    }
    return;
  }
  Expressions.keyValuePair = keyValuePair;
  function keyPropertyValue(value, index) {
    var token = primitiveLiteral_1.default.primitiveLiteral(value, index);
    if (token) {
      token.type = lexer_1.default.TokenType.KeyPropertyValue;
      return token;
    }
    return;
  }
  Expressions.keyPropertyValue = keyPropertyValue;
  function keyPropertyAlias(value, index) {
    return nameOrIdentifier_1.default.odataIdentifier(value, index, lexer_1.default.TokenType.KeyPropertyAlias);
  }
  Expressions.keyPropertyAlias = keyPropertyAlias;
  function singleNavigationExpr(value, index) {
    if (value[index] !== 0x2f) return undefined;
    var member = memberExpr(value, index + 1);
    if (member) return lexer_1.default.tokenize(value, index, member.next, member, lexer_1.default.TokenType.SingleNavigationExpression);
    return;
  }
  Expressions.singleNavigationExpr = singleNavigationExpr;
  function collectionPathExpr(value, index) {
    var token = countExpr(value, index);
    if (!token) {
      if (value[index] === 0x2f) {
        token = boundFunctionExpr(value, index + 1) || anyExpr(value, index + 1) || allExpr(value, index + 1);
      }
    }
    if (token) return lexer_1.default.tokenize(value, index, token.next, token, lexer_1.default.TokenType.CollectionPathExpression);
    return;
  }
  Expressions.collectionPathExpr = collectionPathExpr;
  function complexPathExpr(value, index) {
    if (value[index] !== 0x2f) return undefined;
    var start = index;
    index++;
    var token = nameOrIdentifier_1.default.qualifiedComplexTypeName(value, index);
    if (token) {
      if (value[token.next] !== 0x2f) return undefined;
      index = token.next + 1;
    }
    var expr = propertyPathExpr(value, index) || boundFunctionExpr(value, index);
    if (expr) {
      return lexer_1.default.tokenize(value, start, expr.next, token ? [token, expr] : [expr], lexer_1.default.TokenType.ComplexPathExpression);
    }
    return;
  }
  Expressions.complexPathExpr = complexPathExpr;
  function singlePathExpr(value, index) {
    if (value[index] !== 0x2f) return undefined;
    var boundFunction = boundFunctionExpr(value, index + 1);
    if (boundFunction) return lexer_1.default.tokenize(value, index, boundFunction.next, boundFunction, lexer_1.default.TokenType.SinglePathExpression);
    return;
  }
  Expressions.singlePathExpr = singlePathExpr;
  function functionExpr(value, index) {
    var namespaceNext = nameOrIdentifier_1.default.namespace(value, index);
    if (namespaceNext === index || value[namespaceNext] !== 0x2e) return undefined;
    var start = index;
    index = namespaceNext + 1;
    var token = nameOrIdentifier_1.default.odataIdentifier(value, index);
    if (!token) return undefined;
    token.position = start;
    token.value.namespace = utils_1.default.stringify(value, start, namespaceNext);
    token.raw = utils_1.default.stringify(value, start, token.next);
    index = token.next;
    var params = functionExprParameters(value, index);
    if (!params) return undefined;
    index = params.next;
    var expr = collectionPathExpr(value, index) || collectionNavigationExpr(value, index) || singleNavigationExpr(value, index) || complexPathExpr(value, index) || singlePathExpr(value, index);
    if (expr) index = expr.next;
    return lexer_1.default.tokenize(value, start, index, {
      fn: token,
      params: params,
      expression: expr
    }, lexer_1.default.TokenType.FunctionExpression);
  }
  Expressions.functionExpr = functionExpr;
  function boundFunctionExpr(value, index) {
    return functionExpr(value, index);
  }
  Expressions.boundFunctionExpr = boundFunctionExpr;
  function functionExprParameters(value, index) {
    var open = lexer_1.default.OPEN(value, index);
    if (!open) return undefined;
    var start = index;
    index = open;
    var params = [];
    var expr = functionExprParameter(value, index);
    while (expr) {
      params.push(expr);
      var comma = lexer_1.default.COMMA(value, expr.next);
      if (comma) {
        index = comma;
        expr = functionExprParameter(value, index);
        if (!expr) return undefined;
      } else {
        index = expr.next;
        expr = undefined;
      }
    }
    var close = lexer_1.default.CLOSE(value, index);
    if (!close) return undefined;
    index = close;
    return lexer_1.default.tokenize(value, start, index, params, lexer_1.default.TokenType.FunctionExpressionParameters);
  }
  Expressions.functionExprParameters = functionExprParameters;
  function functionExprParameter(value, index) {
    var name = parameterName(value, index);
    if (!name) return undefined;
    var eq = lexer_1.default.EQ(value, name.next);
    if (!name || !eq) return undefined;
    var start = index;
    index = eq;
    var param = parameterAlias(value, index) || parameterValue(value, index);
    if (!param) return undefined;
    return lexer_1.default.tokenize(value, start, param.next, {
      name: name,
      value: param
    }, lexer_1.default.TokenType.FunctionExpressionParameter);
  }
  Expressions.functionExprParameter = functionExprParameter;
  function parameterName(value, index) {
    return nameOrIdentifier_1.default.odataIdentifier(value, index, lexer_1.default.TokenType.ParameterName);
  }
  Expressions.parameterName = parameterName;
  function parameterAlias(value, index) {
    var at = lexer_1.default.AT(value, index);
    if (!at) return undefined;
    var id = nameOrIdentifier_1.default.odataIdentifier(value, at);
    if (id) return lexer_1.default.tokenize(value, index, id.next, id.value, lexer_1.default.TokenType.ParameterAlias);
    return;
  }
  Expressions.parameterAlias = parameterAlias;
  function parameterValue(value, index) {
    var token = Expressions.arrayOrObject(value, index) || commonExpr(value, index);
    if (token) return lexer_1.default.tokenize(value, index, token.next, token.value, lexer_1.default.TokenType.ParameterValue);
    return;
  }
  Expressions.parameterValue = parameterValue;
  function countExpr(value, index) {
    if (utils_1.default.equals(value, index, '/$count')) return lexer_1.default.tokenize(value, index, index + 7, '/$count', lexer_1.default.TokenType.CountExpression);
    return;
  }
  Expressions.countExpr = countExpr;
  function refExpr(value, index) {
    if (utils_1.default.equals(value, index, '/$ref')) return lexer_1.default.tokenize(value, index, index + 5, '/$ref', lexer_1.default.TokenType.RefExpression);
    return;
  }
  Expressions.refExpr = refExpr;
  function valueExpr(value, index) {
    if (utils_1.default.equals(value, index, '/$value')) return lexer_1.default.tokenize(value, index, index + 7, '/$value', lexer_1.default.TokenType.ValueExpression);
    return;
  }
  Expressions.valueExpr = valueExpr;
  function rootExpr(value, index) {
    if (!utils_1.default.equals(value, index, '$root/')) return undefined;
    var start = index;
    index += 6;
    var entitySet = nameOrIdentifier_1.default.entitySetName(value, index);
    var predicate;
    var entity;
    var token;
    if (entitySet) predicate = keyPredicate(value, entitySet.next);
    if (!(entitySet && predicate)) {
      entity = nameOrIdentifier_1.default.singletonEntity(value, index);
      if (!entity) return undefined;
      token = {
        entity: entity
      };
    } else {
      token = {
        entitySet: entitySet,
        keys: predicate
      };
    }
    index = (predicate || entity).next;
    var nav = singleNavigationExpr(value, index);
    if (nav) index = nav.next;
    return lexer_1.default.tokenize(value, start, index, {
      current: token,
      next: nav
    }, lexer_1.default.TokenType.RootExpression);
  }
  Expressions.rootExpr = rootExpr;
  function complexColInUri(value, index) {
    var begin = lexer_1.default.beginArray(value, index);
    if (begin === index) return undefined;
    var start = index;
    index = begin;
    var items = [];
    var token = Expressions.complexInUri(value, index);
    if (token) {
      while (token) {
        items.push(token);
        index = token.next;
        var end = lexer_1.default.endArray(value, index);
        if (end > index) {
          index = end;
          break;
        } else {
          var separator = lexer_1.default.valueSeparator(value, index);
          if (separator === index) return undefined;
          index = separator;
          token = Expressions.complexInUri(value, index);
          if (!token) return undefined;
        }
      }
    } else {
      var _end = lexer_1.default.endArray(value, index);
      if (_end === index) return undefined;
      index = _end;
    }
    return lexer_1.default.tokenize(value, start, index, {
      items: items
    }, lexer_1.default.TokenType.Array);
  }
  Expressions.complexColInUri = complexColInUri;
  function complexInUri(value, index) {
    var begin = lexer_1.default.beginObject(value, index);
    if (begin === index) return undefined;
    var start = index;
    index = begin;
    var items = [];
    var token = Expressions.annotationInUri(value, index) || Expressions.primitivePropertyInUri(value, index) || Expressions.complexPropertyInUri(value, index) || Expressions.collectionPropertyInUri(value, index) || Expressions.navigationPropertyInUri(value, index);
    if (token) {
      while (token) {
        items.push(token);
        index = token.next;
        var end = lexer_1.default.endObject(value, index);
        if (end > index) {
          index = end;
          break;
        } else {
          var separator = lexer_1.default.valueSeparator(value, index);
          if (separator === index) return undefined;
          index = separator;
          token = Expressions.annotationInUri(value, index) || Expressions.primitivePropertyInUri(value, index) || Expressions.complexPropertyInUri(value, index) || Expressions.collectionPropertyInUri(value, index) || Expressions.navigationPropertyInUri(value, index);
          if (!token) return undefined;
        }
      }
    } else {
      var _end2 = lexer_1.default.endObject(value, index);
      if (_end2 === index) return undefined;
      index = _end2;
    }
    return lexer_1.default.tokenize(value, start, index, {
      items: items
    }, lexer_1.default.TokenType.Object);
  }
  Expressions.complexInUri = complexInUri;
  function collectionPropertyInUri(value, index) {
    var mark = lexer_1.default.quotationMark(value, index);
    if (mark === index) return undefined;
    var start = index;
    index = mark;
    var prop = nameOrIdentifier_1.default.primitiveColProperty(value, index) || nameOrIdentifier_1.default.complexColProperty(value, index);
    if (!prop) return undefined;
    index = prop.next;
    mark = lexer_1.default.quotationMark(value, index);
    if (mark === index) return undefined;
    index = mark;
    var separator = lexer_1.default.nameSeparator(value, index);
    if (separator === index) return undefined;
    index = separator;
    var propValue = prop.type === lexer_1.default.TokenType.PrimitiveCollectionProperty ? Expressions.primitiveColInUri(value, index) : Expressions.complexColInUri(value, index);
    if (!propValue) return undefined;
    index = propValue.next;
    return lexer_1.default.tokenize(value, start, index, {
      key: prop,
      value: propValue
    }, lexer_1.default.TokenType.Property);
  }
  Expressions.collectionPropertyInUri = collectionPropertyInUri;
  function primitiveColInUri(value, index) {
    var begin = lexer_1.default.beginArray(value, index);
    if (begin === index) return undefined;
    var start = index;
    index = begin;
    var items = [];
    var token = Expressions.primitiveLiteralInJSON(value, index);
    if (token) {
      while (token) {
        items.push(token);
        index = token.next;
        var end = lexer_1.default.endArray(value, index);
        if (end > index) {
          index = end;
          break;
        } else {
          var separator = lexer_1.default.valueSeparator(value, index);
          if (separator === index) return undefined;
          index = separator;
          token = Expressions.primitiveLiteralInJSON(value, index);
          if (!token) return undefined;
        }
      }
    } else {
      var _end3 = lexer_1.default.endArray(value, index);
      if (_end3 === index) return undefined;
      index = _end3;
    }
    return lexer_1.default.tokenize(value, start, index, {
      items: items
    }, lexer_1.default.TokenType.Array);
  }
  Expressions.primitiveColInUri = primitiveColInUri;
  function complexPropertyInUri(value, index) {
    var mark = lexer_1.default.quotationMark(value, index);
    if (mark === index) return undefined;
    var start = index;
    index = mark;
    var prop = nameOrIdentifier_1.default.complexProperty(value, index);
    if (!prop) return undefined;
    index = prop.next;
    mark = lexer_1.default.quotationMark(value, index);
    if (mark === index) return undefined;
    index = mark;
    var separator = lexer_1.default.nameSeparator(value, index);
    if (separator === index) return undefined;
    index = separator;
    var propValue = Expressions.complexInUri(value, index);
    if (!propValue) return undefined;
    index = propValue.next;
    return lexer_1.default.tokenize(value, start, index, {
      key: prop,
      value: propValue
    }, lexer_1.default.TokenType.Property);
  }
  Expressions.complexPropertyInUri = complexPropertyInUri;
  function annotationInUri(value, index) {
    var mark = lexer_1.default.quotationMark(value, index);
    if (mark === index) return undefined;
    var start = index;
    index = mark;
    var at = lexer_1.default.AT(value, index);
    if (!at) return undefined;
    index = at;
    var namespaceNext = nameOrIdentifier_1.default.namespace(value, index);
    if (namespaceNext === index) return undefined;
    var namespaceStart = index;
    index = namespaceNext;
    if (value[index] !== 0x2e) return undefined;
    index++;
    var term = nameOrIdentifier_1.default.termName(value, index);
    if (!term) return undefined;
    index = term.next;
    mark = lexer_1.default.quotationMark(value, index);
    if (mark === index) return undefined;
    index = mark;
    var separator = lexer_1.default.nameSeparator(value, index);
    if (separator === index) return undefined;
    index = separator;
    var token = Expressions.complexInUri(value, index) || Expressions.complexColInUri(value, index) || Expressions.primitiveLiteralInJSON(value, index) || Expressions.primitiveColInUri(value, index);
    if (!token) return undefined;
    index = token.next;
    return lexer_1.default.tokenize(value, start, index, {
      key: `@${utils_1.default.stringify(value, namespaceStart, namespaceNext)}.${term.raw}`,
      value: token
    }, lexer_1.default.TokenType.Annotation);
  }
  Expressions.annotationInUri = annotationInUri;
  function keyValuePairInUri(value, index, keyFn, valueFn) {
    var mark = lexer_1.default.quotationMark(value, index);
    if (mark === index) return undefined;
    var start = index;
    index = mark;
    var prop = keyFn(value, index);
    if (!prop) return undefined;
    index = prop.next;
    mark = lexer_1.default.quotationMark(value, index);
    if (mark === index) return undefined;
    index = mark;
    var separator = lexer_1.default.nameSeparator(value, index);
    if (separator === index) return undefined;
    index = separator;
    var propValue = valueFn(value, index);
    if (!propValue) return undefined;
    index = propValue.next;
    return lexer_1.default.tokenize(value, start, index, {
      key: prop,
      value: propValue
    }, lexer_1.default.TokenType.Property);
  }
  Expressions.keyValuePairInUri = keyValuePairInUri;
  function primitivePropertyInUri(value, index) {
    return Expressions.keyValuePairInUri(value, index, nameOrIdentifier_1.default.primitiveProperty, primitiveLiteralInJSON);
  }
  Expressions.primitivePropertyInUri = primitivePropertyInUri;
  function navigationPropertyInUri(value, index) {
    return Expressions.singleNavPropInJSON(value, index) || Expressions.collectionNavPropInJSON(value, index);
  }
  Expressions.navigationPropertyInUri = navigationPropertyInUri;
  function singleNavPropInJSON(value, index) {
    return Expressions.keyValuePairInUri(value, index, nameOrIdentifier_1.default.entityNavigationProperty, Expressions.rootExpr);
  }
  Expressions.singleNavPropInJSON = singleNavPropInJSON;
  function collectionNavPropInJSON(value, index) {
    return Expressions.keyValuePairInUri(value, index, nameOrIdentifier_1.default.entityColNavigationProperty, rootExprCol);
  }
  Expressions.collectionNavPropInJSON = collectionNavPropInJSON;
  function rootExprCol(value, index) {
    var begin = lexer_1.default.beginArray(value, index);
    if (begin === index) return undefined;
    var start = index;
    index = begin;
    var items = [];
    var token = Expressions.rootExpr(value, index);
    if (token) {
      while (token) {
        items.push(token);
        index = token.next;
        var end = lexer_1.default.endArray(value, index);
        if (end > index) {
          index = end;
          break;
        } else {
          var separator = lexer_1.default.valueSeparator(value, index);
          if (separator === index) return undefined;
          index = separator;
          token = Expressions.rootExpr(value, index);
          if (!token) return undefined;
        }
      }
    } else {
      var _end4 = lexer_1.default.endArray(value, index);
      if (_end4 === index) return undefined;
      index = _end4;
    }
    return lexer_1.default.tokenize(value, start, index, {
      items: items
    }, lexer_1.default.TokenType.Array);
  }
  Expressions.rootExprCol = rootExprCol;
  function primitiveLiteralInJSON(value, index) {
    return Expressions.stringInJSON(value, index) || Expressions.numberInJSON(value, index) || Expressions.booleanInJSON(value, index) || Expressions.nullInJSON(value, index);
  }
  Expressions.primitiveLiteralInJSON = primitiveLiteralInJSON;
  function stringInJSON(value, index) {
    var mark = lexer_1.default.quotationMark(value, index);
    if (mark === index) return undefined;
    var start = index;
    index = mark;
    var char = Expressions.charInJSON(value, index);
    while (char > index) {
      index = char;
      char = Expressions.charInJSON(value, index);
    }
    mark = lexer_1.default.quotationMark(value, index);
    if (mark === index) return undefined;
    index = mark;
    return lexer_1.default.tokenize(value, start, index, 'string', lexer_1.default.TokenType.Literal);
  }
  Expressions.stringInJSON = stringInJSON;
  function charInJSON(value, index) {
    var escape = lexer_1.default.escape(value, index);
    if (escape > index) {
      if (utils_1.default.equals(value, escape, '%2F')) return escape + 3;
      if (utils_1.default.equals(value, escape, '/') || utils_1.default.equals(value, escape, 'b') || utils_1.default.equals(value, escape, 'f') || utils_1.default.equals(value, escape, 'n') || utils_1.default.equals(value, escape, 'r') || utils_1.default.equals(value, escape, 't')) return escape + 1;
      if (utils_1.default.equals(value, escape, 'u') && utils_1.default.required(value, escape + 1, lexer_1.default.HEXDIG, 4, 4)) return escape + 5;
      var escapeNext = lexer_1.default.escape(value, escape);
      if (escapeNext > escape) return escapeNext;
      var mark = lexer_1.default.quotationMark(value, escape);
      if (mark > escape) return mark;
    } else {
      var _mark = lexer_1.default.quotationMark(value, index);
      if (_mark === index) return index + 1;
    }
    return 0;
  }
  Expressions.charInJSON = charInJSON;
  function numberInJSON(value, index) {
    var token = primitiveLiteral_1.default.doubleValue(value, index) || primitiveLiteral_1.default.int64Value(value, index);
    if (token) {
      token.value = 'number';
      return token;
    }
    return;
  }
  Expressions.numberInJSON = numberInJSON;
  function booleanInJSON(value, index) {
    if (utils_1.default.equals(value, index, 'true')) return lexer_1.default.tokenize(value, index, index + 4, 'boolean', lexer_1.default.TokenType.Literal);
    if (utils_1.default.equals(value, index, 'false')) return lexer_1.default.tokenize(value, index, index + 5, 'boolean', lexer_1.default.TokenType.Literal);
    return;
  }
  Expressions.booleanInJSON = booleanInJSON;
  function nullInJSON(value, index) {
    if (utils_1.default.equals(value, index, 'null')) return lexer_1.default.tokenize(value, index, index + 4, 'null', lexer_1.default.TokenType.Literal);
    return;
  }
  Expressions.nullInJSON = nullInJSON;
  function arrayOrObject(value, index) {
    var token = Expressions.complexColInUri(value, index) || Expressions.complexInUri(value, index) || Expressions.rootExprCol(value, index) || Expressions.primitiveColInUri(value, index);
    if (token) return lexer_1.default.tokenize(value, index, token.next, token, lexer_1.default.TokenType.ArrayOrObject);
    return;
  }
  Expressions.arrayOrObject = arrayOrObject;
})(Expressions = exports.Expressions || (exports.Expressions = {}));
exports.default = Expressions;