import { Url } from 'cqrs-shared/src/uri/Url';
import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import ConnectionContext from 'materialTheme/src/connectionContext';
import { FilePicker } from 'materialTheme/src/file/FilePicker';
import { FilePicketComp } from 'materialTheme/src/file/FilePicketComp';
import ImagePickerComp from 'materialTheme/src/file/ImagePicker';
import { ImagePickerOptions } from 'materialTheme/src/file/ImagePickerOptions';
import { Uploads } from 'materialTheme/src/file/upload/Uploads';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { Form } from 'materialTheme/src/theme/components/forminput/Form';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { ListItem } from 'materialTheme/src/theme/components/ListItem';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { MenuRaw } from 'materialTheme/src/theme/components/MenuRaw';
import { ModalBottomSheet } from 'materialTheme/src/theme/components/ModalBottomSheet';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Toast } from 'materialTheme/src/theme/components/Toast';
import { UploadingList } from 'materialTheme/src/theme/components/upload/UploadingList';
import { UploadTypes } from 'materialTheme/src/theme/components/upload/UploadTypes';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent, useEffect, useState } from 'react';
import { Image, Platform, ScrollView, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { UserEntity } from 'upmesh-auth-core/src/client/query/entities/UserEntity';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { CreateFolder } from 'upmesh-core/src/client/commands/folder/CreateFolder';
import { ChangeStoredFileComment } from 'upmesh-core/src/client/commands/storedfile/ChangeStoredFileComment';
import { ChangeStoredFilename, } from 'upmesh-core/src/client/commands/storedfile/ChangeStoredFilename';
import { ChangeStoredFileType } from 'upmesh-core/src/client/commands/storedfile/ChangeStoredFileType';
import { CopyStoredFile } from 'upmesh-core/src/client/commands/storedfile/CopyStoredFile';
import { FolderEntity } from 'upmesh-core/src/client/query/entities/FolderEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { OfflineDataDownloader } from '../../repo/file/OfflineDataDownloader';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CurrentProject } from '../project/CurrentProject';
import { AddFilesPU } from './AddFilesPU';
import { AddFileTypeAndCommentDialog } from './AddFileTypeAndCommentDialog';
import { CreateFolderDialog } from './CreateFolderDialog';
import { MoveFolderDialog } from './MoveFolderDialog';
import { PathCellRenderer } from './PathCellRenderer';
import { SharedFiles } from './SharedFiles';
import { ShareDialog } from './ShareDialog';
const bytes = require('bytes');
const notFoundImage = require('../../assets/img/no_documents.png');
const uploadFoldersAndFiles = require('../../assets/img/uploadFoldersAndFiles.png');
const styles = {
    headerRippleStyle: {
        height: 56,
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    listElementStyle: {
        height: '100%',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
};
function TitleWithThumb({ item, onMultiSelect, connectedToServer, multiselectEnabled, openImage, downloadFile }) {
    const [toolTip, setToolTip] = useState('');
    const [data, setData] = useState({
        itemId: '',
        localFile: undefined,
        thumb: {
            thumbnail: <Icon icon="file-outline" toolTip=""/>,
            width: 24,
            rounded: true,
        },
        lastTime: 0,
    });
    useEffect(() => {
        const localFile = item.mimeType === 'folder'
            ? undefined
            : OfflineDataDownloader.isMediaSynced(item.projectId, item.id, item.getFileId());
        let panoramaImage = false;
        if (item.mimeType.startsWith('image') && item.metaData != null && typeof item.metaData !== 'object') {
            try {
                item.metaData = JSON.parse(`${item.metaData}`);
            }
            catch (e) {
                console.log('cant parse:', item.metaData, e);
            }
        }
        if (item.mimeType.startsWith('image') &&
            item.metaData != null &&
            !Number.isNaN(item.metaData['width']) &&
            !Number.isNaN(item.metaData['height']) &&
            item.metaData['width'] / item.metaData['height'] === 2) {
            panoramaImage = true;
        }
        const time = item.lastModifiedAt?.getTime() > 0 ? item.lastModifiedAt.getTime() : 1;
        if (data.localFile !== localFile || item.id !== data.itemId || time > data.lastTime) {
            const targetUrl = `${UpmeshClient.instance.url}/storedfile/file/${item.id}?lm=${item.lastModifiedAt}`;
            const thumb = item.mimeType === 'folder'
                ? {
                    thumbnail: <Icon icon="folder" toolTip=""/>,
                    width: 24,
                    rounded: true,
                }
                : UploadTypes.getThumbnailForFileName({ targetUrl, name: item.orgFilename, preview: localFile }, undefined, {
                    id: item.id,
                    metaData: item.imageMetadata,
                    downloadFunction: downloadFile(false, localFile)(item),
                }, panoramaImage);
            setData({ localFile, thumb, itemId: item.id, lastTime: time });
        }
    }, [item]);
    return (<Ripple key={`file:${item.id}_filename_${toolTip}`} style={{
            ...styles.listElementStyle,
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%',
        }} onPress={item.fileType === 'image' ? openImage(item) : downloadFile(true, data.localFile)(item)} onLongPress={multiselectEnabled && onMultiSelect != null ? () => onMultiSelect(new Set([item.id])) : undefined} disabled={!(connectedToServer || data.localFile != null || item.mimeType === 'folder')} toolTip={toolTip}>
      <View key={`file:${item.id}_thumb`} style={{ width: 72, justifyContent: 'center', alignItems: 'center' }}>
        {data.thumb.thumbnail}
      </View>
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <MaterialText onToolTipCreated={(e) => {
            setToolTip(e);
        }} showToolTipOnCutText ellipsizeMode="tail" centeredBox fixedWidth="100%" numberOfLines={1}>
          {item.orgFilename}
        </MaterialText>
      </View>
    </Ripple>);
}
export class FilesViewTable extends PureComponent {
    static getDerivedStateFromProps(nextProps, _prevState) {
        if (nextProps.documents != null) {
            const { documents, folders, currentFolder } = nextProps;
            return { documents: FilesViewTable.getDocWithFolder(documents, folders, currentFolder) };
        }
        return null;
    }
    constructor(props) {
        super(props);
        this.setImagePicker = (r) => {
            this.imagePicker = r;
        };
        this.openTicket = (item) => (e) => {
            if (e.persist != null && typeof e.persist === 'function')
                e.persist();
            Routing.instance.openDialog('ticket', { id: item.forEntityId })(e);
        };
        this.openJournal = (item) => (e) => {
            if (e.persist != null && typeof e.persist === 'function')
                e.persist();
            try {
                const data = JSON.parse(item.metaData);
                if (data.journal) {
                    const j = data.journal;
                    Routing.instance.goTo(`/projects/${item.projectId}/dailyreports/${j.forDay}`);
                }
            }
            catch (err) {
                DefaultErrorHandler.showDefaultErrorAlert(err);
            }
        };
        this.moveFolder = (item) => (_e) => {
            if (item.mimeType === 'folder' && item['hiddenData'] != null) {
                MoveFolderDialog.open(item['hiddenData']);
            }
        };
        this.moveFile = (fileIds, excludeFolders) => (_item) => (e) => {
            const { currentFolder } = this.props;
            if (currentFolder === 'reports') {
                SharedFiles.fileIds = fileIds;
                SharedFiles.excludeFolders = excludeFolders;
                AddFilesPU.openDialog({
                    projectId: this.props.projectId,
                    dontChangeProject: true,
                    copyFile: true,
                    source: 'Journal',
                })(null);
            }
            else {
                FolderEntity.hasFolderDeleteRights(currentFolder, CurrentUser.userId)
                    .then(() => {
                    if (e.persist != null && typeof e.persist === 'function')
                        e.persist();
                    SharedFiles.fileIds = fileIds;
                    SharedFiles.excludeFolders = excludeFolders;
                    AddFilesPU.openDialog({
                        projectId: this.props.projectId,
                        dontChangeProject: true,
                        copyFile: false,
                        source: 'StoredFile',
                    })(null);
                })
                    .catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
            }
        };
        this.copyFile = (item) => async (_e) => {
            const copyFileCommand = new CopyStoredFile({ forTicket: false }, item.id, CurrentUser.token);
            try {
                Toast.instance?.open({
                    title: I18n.m.getMessage('fileIsCopying', { file: item.orgFilename }),
                    onPressButton: Toast.instance?.close,
                    buttonTitle: I18n.m.getMessage('close'),
                });
                await copyFileCommand.execute().then(() => Toast.instance?.close());
            }
            catch (e) {
                DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m, e);
                console.error('cant copy file:', e);
            }
        };
        this.changeFileName = (item) => async (_e) => {
            const cmd = new ChangeStoredFilename({ orgFilename: item.orgFilename }, item.id);
            cmd
                .canI()
                .then(() => {
                const dataOptions = new Map();
                let { orgFilename } = item;
                let ext;
                if (item.orgFilename.lastIndexOf('.') >= 0) {
                    orgFilename = item.orgFilename.substr(0, item.orgFilename.lastIndexOf('.'));
                    ext = item.orgFilename.substr(item.orgFilename.lastIndexOf('.'));
                }
                dataOptions.set('orgFilename', {
                    formType: 'string',
                    cols: 1,
                    props: { labelText: I18n.m.getMessage('filesRenameNewFileName') },
                });
                const currentData = {
                    orgFilename,
                    ext,
                };
                const content = (<Form key="ChangeStoredFileNameForm" formHeaderProps={{ formTitle: I18n.m.getMessage('filesRenameHeader') }} stickyActions={false} command={new ChangeStoredFilename(currentData, item.id)} store={ClientStore.commandStore} dataOptions={dataOptions} resetButton={false} onSaved={(_result) => {
                        Dialog.instance?.close();
                    }} saveButtonLabel={I18n.m.getMessage('save')} additionalButtons={[
                        <ContainedButton title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close} backgroundColor="#FFFFFF" textColor={ThemeManager.style.brandPrimary}/>,
                    ]}/>);
                Dialog.instance?.open({
                    closeOnTouchOutside: true,
                    content,
                    height: 0,
                    contentPadding: false,
                });
            })
                .catch(() => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }));
        };
        this.changeFileType = (file) => (_e) => {
            const { fileTypes } = this.props;
            const changeCommentCmd = new ChangeStoredFileComment({ comment: '' }, file.id, CurrentUser.token);
            const changeTypeCmd = new ChangeStoredFileType({ type: '' }, file.id, CurrentUser.token);
            Promise.race([changeCommentCmd.canI(), changeTypeCmd.canI()])
                .then(() => Dialog.instance?.open({
                closeOnTouchOutside: true,
                content: <AddFileTypeAndCommentDialog fileTypes={fileTypes} fileId={file.id}/>,
                height: 0,
                contentPadding: false,
            }))
                .catch(() => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }));
        };
        this.shareFile = (file) => (_e) => {
            Dialog.instance?.open({
                closeOnTouchOutside: true,
                content: <ShareDialog file={file}/>,
                contentPadding: false,
                showCloseIcon: true,
            });
        };
        this.locationHeight = 34;
        this.uploadStatusHeight = 0;
        this.onFolderLocationSize = (e) => {
            this.locationHeight = e.nativeEvent.layout.height;
            this.setState({ maxTableHeight: this.getMaxTableHeight() });
        };
        this.onUploadStatusSize = (e) => {
            this.uploadStatusHeight = e.nativeEvent.layout.height;
            this.setState({ maxTableHeight: this.getMaxTableHeight() });
        };
        this.createAndDownloadZip = async (folderId) => {
            MenuRaw.instance?.close();
            const url = `${UpmeshClient.instance.url}/storedfile/archive/${folderId}`;
            const headers = {};
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
            headers['authorization'] = `Bearer ${CurrentUser.token}`;
            const result = await fetch(url, { headers, method: 'POST', body: '' });
            if (result.status === 200) {
                return await result.text();
            }
            return '';
        };
        this.pressAddFile = async (_e) => {
            ModalBottomSheet.instance?.close();
            const { currentFolder } = this.props;
            try {
                if (currentFolder != null && currentFolder !== 'public' && currentFolder !== 'private') {
                    await FolderEntity.hasFolderWriteRights(currentFolder, CurrentUser.userId);
                }
                const { fileIdCounter } = this.state;
                FilePicker.show(`uploadFiles_to_folder_${fileIdCounter}`, true, ['allFiles'], (response) => this.onGotFilesOrFolder(response));
            }
            catch (err) {
                Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') });
            }
        };
        this.uploadFolder = async () => {
            ModalBottomSheet.instance?.close();
            try {
                Dialog.instance?.open({
                    contentPadding: false,
                    closeOnTouchOutside: true,
                    content: (<View style={{
                            width: '100%',
                            alignItems: 'center',
                            alignContent: 'center',
                            justifyContent: 'center',
                            padding: 16,
                        }}>
            <Image style={{ width: '100%', height: 200, resizeMode: 'contain' }} resizeMode="contain" source={uploadFoldersAndFiles}/>
            <View style={{ width: '100%', marginTop: 16, alignItems: 'center' }}>
              <MaterialText centeredBox centeredText color={ThemeManager.style.black54} type={MaterialTextTypes.Subtitle2} strong>
                {I18n.m.getMessage('uploadFolder')}
              </MaterialText>
              <MaterialText centeredBox centeredText color={ThemeManager.style.black54} type={MaterialTextTypes.Subtitle2}>
                {I18n.m.getMessage('foldersAndFilesUploadHint')}
              </MaterialText>
            </View>
          </View>),
                });
            }
            catch (err) {
                Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') });
            }
        };
        this.onGotFolder = async (e) => {
            LoadingEvents.instance.startLoading();
            try {
                const { currentFolder, projectId } = this.props;
                const folder = currentFolder === 'public' ? null : currentFolder;
                const files = [];
                for (const f of e) {
                    files.push(f);
                }
                for (const f of files) {
                    try {
                        const folders = f['webkitRelativePath'].split('/');
                        folders.pop();
                        const folderId = await this.getFolderIdByName([...folders], folder, true);
                        const url = Url.getURLfromString(`${UpmeshClient.instance.url}/storedfile/file/`);
                        const targetFolder = folderId == null ? 'public' : folderId === 'private' ? null : folderId;
                        await Uploads.addUpload(url.href, f, `${projectId}_documents_upload_${folderId == null ? 'public' : folderId}`, {
                            projectId,
                            folder: targetFolder,
                        });
                    }
                    catch (e) {
                        DefaultErrorHandler.showDefaultErrorAlert(e);
                    }
                }
            }
            catch (e) {
                DefaultErrorHandler.showDefaultErrorAlert(e);
            }
            LoadingEvents.instance.stopLoading();
        };
        this.onGotFilesOrFolder = async (files) => {
            LoadingEvents.instance.startLoading();
            const { currentFolder, projectId } = this.props;
            const folder = currentFolder === 'public' ? null : currentFolder;
            for (const f of files) {
                try {
                    if (folder !== 'private' && f['folder'] != null && f['filepath'] == null) {
                        const folders = f['folder'].split('/');
                        folders.pop();
                        await this.getFolderIdByName([...folders], folder, true);
                    }
                    else if (folder !== 'private' && f['filepath'] != null) {
                        const folders = f['filepath'].split('/');
                        folders.pop();
                        const folderId = await this.getFolderIdByName([...folders], folder, true);
                        const url = Url.getURLfromString(`${UpmeshClient.instance.url}/storedfile/file/`);
                        const targetFolder = folderId == null ? 'public' : folderId === 'private' ? null : folderId;
                        await Uploads.addUpload(url.href, f, `${projectId}_documents_upload_${folderId == null ? 'public' : folderId}`, {
                            projectId,
                            folder: targetFolder,
                        });
                    }
                    else {
                        const url = Url.getURLfromString(`${UpmeshClient.instance.url}/storedfile/file/`);
                        const targetFolder = folder == null ? 'public' : folder === 'private' ? null : folder;
                        await Uploads.addUpload(url.href, f, `${projectId}_documents_upload_${folder == null ? 'public' : folder}`, {
                            projectId,
                            folder: targetFolder,
                        });
                    }
                }
                catch (e) {
                    DefaultErrorHandler.showDefaultErrorAlert(e);
                }
            }
            LoadingEvents.instance.stopLoading();
        };
        this.openFab = async () => {
            const { size, currentFolder } = this.props;
            const sViewHeight = size.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0;
            const onS = size.contentWidth < this.props.breakViewWidth;
            if (Fab.instance != null) {
                if (currentFolder === 'tickets' || currentFolder === 'reports' || currentFolder === 'search') {
                    Fab.instance.close();
                }
                else if (!onS || (currentFolder != null && currentFolder.length > 0)) {
                    const fabActions = [];
                    if (currentFolder !== 'private') {
                        fabActions.push({
                            text: I18n.m.getMessage('createFolder'),
                            icon: 'folder-plus-outline',
                            onPress: this.createFolder,
                        });
                    }
                    fabActions.push({
                        text: I18n.m.getMessage('uploadFiles'),
                        icon: 'cloud-upload-outline',
                        onPress: this.pressAddFile,
                    });
                    if (Platform.OS === 'web' && currentFolder !== 'private') {
                        fabActions.push({
                            text: I18n.m.getMessage('uploadFolder'),
                            icon: 'cloud-upload-outline',
                            onPress: this.uploadFolder,
                        });
                    }
                    if (Platform.OS === 'ios') {
                        fabActions.push({
                            icon: 'folder-image',
                            onPress: () => {
                                if (Fab.instance != null) {
                                    Fab.instance.closeButtons();
                                }
                                this.openLibrary();
                            },
                            text: I18n.m.getMessage('fromImageLibrary'),
                        });
                    }
                    const hasCam = await ImagePickerComp.hasCamera();
                    if (hasCam) {
                        fabActions.push({
                            icon: 'camera',
                            text: I18n.m.getMessage('fromCamera'),
                            onPress: this.openCam,
                        });
                    }
                    Fab.instance.open({
                        fabActions,
                        fabIcon: 'plus',
                        fabIconOpen: 'close',
                        small: false,
                        fabColor: ThemeManager.style.brandPrimary,
                        fabColorOpen: ThemeManager.style.brandSecondary,
                        extraPaddingBottom: sViewHeight,
                    });
                }
                else {
                    Fab.instance.close();
                }
            }
            else {
                window.setTimeout(this.openFab, 100);
            }
        };
        this.openCam = () => {
            if (this.imagePicker != null) {
                this.imagePicker.execute({ selectMethod: 'camera' });
            }
        };
        this.createFolder = () => {
            const { projectId, currentFolder } = this.props;
            if (currentFolder !== 'private' &&
                currentFolder !== 'tickets' &&
                currentFolder !== 'reports' &&
                currentFolder !== 'search') {
                const folderId = currentFolder != null && currentFolder !== 'public' ? currentFolder.toString() : undefined;
                const c = new CreateFolder({
                    subFolderFromId: folderId != null ? folderId : null,
                    title: '',
                    access: [],
                    description: '',
                    tags: [],
                    projectId,
                });
                c.canI()
                    .then(() => CreateFolderDialog.openDialog({ projectId, folderId }))
                    .catch(() => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }));
            }
        };
        this.closeFab = (callback) => {
            if (Fab.instance != null) {
                Fab.instance.close(callback);
            }
        };
        this.onGotImageFile = async (response) => {
            if (Array.isArray(response)) {
                await this.onGotFilesOrFolder(response);
            }
            else {
                await this.onGotFilesOrFolder([response]);
            }
        };
        this.onUploading = (files) => {
            if (files.length === 0)
                this.setState({ uploadHeight: 0 });
            else {
                this.setState({ uploadHeight: files.length * 56 + 16 });
            }
        };
        this.showVersionsList = (file) => (_e) => {
            Menu.instance?.close();
            const listItems = [];
            if (file.versions) {
                file.versions.forEach((f) => {
                    listItems.push(<ListItem key={`li${f.fileId}`} title={f.orgFilename} secondTextLine={`${I18n.m.dateCurrent.localeDateWithTimeString(f.createdAt)} - ${f.uploadedByUsername}`} iconRight={{ icon: 'dots-vertical' }}/>);
                });
            }
            Dialog.instance?.open({
                contentPadding: false,
                content: (<View>
          <DialogTitle>{I18n.m.getMessage('filesVersionsManage')}</DialogTitle>
          <DialogContent>{listItems}</DialogContent>
        </View>),
            });
        };
        const { documents, folders, currentFolder } = this.props;
        this.state = {
            maxTableHeight: this.getMaxTableHeight(),
            documents: FilesViewTable.getDocWithFolder(documents, folders, currentFolder),
            fileIdCounter: 1,
            uploadHeight: 0,
        };
    }
    componentDidMount() {
        if (this.props.currentFolder === 'tickets') {
            this.ticketAttachKey = UpmeshClient.eventDispatcher.attach({
                readModelName: 'Ticket',
                callback: (e) => {
                    this.updateTicketTitlesAndNumber(e);
                },
            });
        }
        this.openFab().catch((err) => console.debug(err));
        this.updateTicketTitlesAndNumber();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.currentFolder !== this.props.currentFolder) {
            requestAnimationFrame(() => {
                this.openFab().catch((err) => console.debug(err));
            });
        }
        if (prevProps.documents?.length !== this.props.documents?.length ||
            (this.props.currentFolder === 'tickets' &&
                this.state.documents != null &&
                this.state.documents.length > 0 &&
                this.state.documents[0].ticketTitle == null)) {
            this.updateTicketTitlesAndNumber();
        }
    }
    updateTicketTitlesAndNumber(en) {
        const { documents } = this.state;
        if (en != null && en.readModelName === 'Ticket' && documents != null) {
            const docs = [...documents];
            for (const doc of docs) {
                en.entities.forEach((e) => {
                    if (doc.forEntityId === e.entity.id) {
                        doc.ticketTitle = e.entity.title;
                        doc.ticketNumber = e.entity.ticketNumber;
                    }
                });
            }
            this.setState({ documents: docs });
            return;
        }
        const docs = documents == null ? [] : [...documents];
        docs.forEach((doc) => {
            if (doc.forEntityId != null && doc.forEntityId.length > 0) {
                if (doc.forEntity === 'Ticket') {
                    UpmeshClient.instance.modals.ticket
                        .getById(doc.forEntityId)
                        .then((ticket) => {
                        doc.ticketTitle = ticket.title;
                        doc.ticketNumber = ticket.ticketNumber;
                        this.setState({ documents: docs });
                    })
                        .catch((e) => {
                        console.warn('cant get ticket title for table', e);
                    });
                }
            }
        });
    }
    static getDocWithFolder(documents, folders, currentFolder) {
        const docs = documents == null ? [] : [...documents];
        folders.forEach((f) => {
            const subFolderFromId = f.subFolderFromId == null ? 'public' : f.subFolderFromId;
            if (subFolderFromId === currentFolder) {
                let uploadedByUsername = '';
                let uploadedByUser = new UserEntity();
                try {
                    const u = CurrentProject.instance.getCurrentProjectTeam('all');
                    const us = u.find((t) => t.user.userId === f.createdBy);
                    if (us != null) {
                        uploadedByUsername = us.user.getFullName();
                        uploadedByUser = us.user;
                    }
                }
                catch (e) {
                    console.debug('cant get Teammember', e);
                }
                const s = {
                    title: ` ${f.title}`,
                    id: f.id,
                    orgFilename: ` ${f.title}`,
                    mimeType: 'folder',
                    deleted: f.deleted,
                    folder: f.subFolderFromId,
                    uploadedByUsername,
                    uploadedByUser,
                    createdBy: f.createdBy,
                    createdAt: f.createdAt,
                    userId: f.createdBy,
                    fileSizeInBytes: 0,
                };
                s['hiddenData'] = f;
                docs.unshift(s);
            }
        });
        return docs;
    }
    getMaxTableHeight() {
        const { size } = this.props;
        const sViewHeight = size.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0;
        return (size.contentHeight -
            ThemeManager.style.headerHeight -
            ThemeManager.style.getScreenRelativePixelSize(16) -
            this.uploadStatusHeight -
            sViewHeight -
            this.locationHeight);
    }
    getLocationElement(folderId, folderName) {
        return (<MaterialText key={`folder_location:${folderId}`} type={MaterialTextTypes.Body1} onPress={this.props.goToFolder(folderId)}>
        {folderName}
      </MaterialText>);
    }
    generateFolderLocations(folderId) {
        const { folders } = this.props;
        const items = [];
        const f = folders.find((e) => e.id === folderId);
        if (f != null) {
            items.unshift({ title: f.title, id: f.id });
            if (f.subFolderFromId != null && f.subFolderFromId.length > 0) {
                items.unshift(...this.generateFolderLocations(f.subFolderFromId));
            }
        }
        return items;
    }
    generateLocations() {
        const { documents, currentFolder } = this.props;
        const items = [];
        if (currentFolder === 'all')
            items.push(this.getLocationElement(currentFolder, I18n.m.getMessage('menuProjectFiles')));
        else if (currentFolder === 'search')
            items.push(this.getLocationElement(currentFolder, I18n.m.getMessage('searchNoun')));
        else if (currentFolder === 'private')
            items.push(this.getLocationElement(currentFolder, I18n.m.getMessage('filesFolderPrivate')));
        else if (currentFolder === 'public')
            items.push(this.getLocationElement(currentFolder, I18n.m.getMessage('filesFolderPublic')));
        else if (currentFolder === 'reports')
            items.push(this.getLocationElement(currentFolder, I18n.m.getMessage('menuProjectDailyReports')));
        else if (currentFolder === 'tickets')
            items.push(this.getLocationElement(currentFolder, I18n.m.getMessage('tickets')));
        else {
            items.push(this.getLocationElement('public', I18n.m.getMessage('filesFolderPublic')));
            const locations = this.generateFolderLocations(currentFolder);
            locations.forEach((f) => {
                items.push(<MaterialText key={`sep_${f.id}`} type={MaterialTextTypes.Body1}>
            {' › '}
          </MaterialText>);
                items.push(this.getLocationElement(f.id, f.title));
            });
        }
        items.push(<MaterialText key="documentsCount" type={MaterialTextTypes.Body1}>
        ({documents == null ? '0' : documents.length})
      </MaterialText>);
        return items;
    }
    componentWillUnmount() {
        if (this.ticketAttachKey != null)
            UpmeshClient.eventDispatcher.detach('Ticket', this.ticketAttachKey);
        this.closeFab();
    }
    async getFolderIdByName(path, subFolderFromId = null, createIfNotExists = true) {
        if (path.length > 0) {
            const p = path.shift();
            const { projectId } = this.props;
            let id = '';
            if (p != null) {
                try {
                    const subFilter = subFolderFromId == null ? 'subFolderFromId eq null' : `subFolderFromId eq '${subFolderFromId}'`;
                    const getFolderByName = await UpmeshClient.instance.modals.folder.get({
                        filter: `projectId eq '${projectId}' and deleted ne true and ${subFilter} and title eq '${p}'`,
                    });
                    if (getFolderByName.length === 0 && createIfNotExists) {
                        let access = [];
                        if (subFolderFromId != null) {
                            const findParentAccess = await UpmeshClient.instance.modals.folder.getById(subFolderFromId);
                            access = [...findParentAccess.access];
                        }
                        const c = new CreateFolder({ title: p, subFolderFromId, projectId, access, description: '', tags: [] });
                        await c.execute();
                        id = c.entityId;
                    }
                    else if (getFolderByName.length > 0) {
                        id = getFolderByName[0].id;
                    }
                }
                catch (e) {
                    console.debug('cant get subfolder', e);
                    throw e;
                }
            }
            if (path.length > 0) {
                return this.getFolderIdByName([...path], id, createIfNotExists);
            }
            return id;
        }
        return subFolderFromId;
    }
    openLibrary() {
        if (this.imagePicker != null) {
            this.imagePicker.execute({ selectMethod: 'library' });
        }
    }
    render() {
        const { searchWords, deleteFile, currentFolder, projectId, selectedIDs, onMultiSelect, multiselectEnabled } = this.props;
        const { maxTableHeight, documents, fileIdCounter, uploadHeight } = this.state;
        const preColumns = [
            {
                title: I18n.m.getMessage('filesTitle'),
                keyInData: 'orgFilename',
                style: { paddingHorizontal: 16, width: 300, minWidth: 300 },
                minWidth: 300,
                dataType: 'string',
                cellRenderer: (item, column, index) => {
                    const { connectedToServer } = this.context;
                    return (<View key={`titleCell-${item.id}-${index}`} style={[
                            {
                                justifyContent: 'flex-end',
                                flexDirection: 'row',
                                height: '100%',
                                paddingHorizontal: 16,
                                overflow: 'hidden',
                            },
                            column.style,
                        ]}>
              <TitleWithThumb key={`titleCellContent-${item.id}-${index}`} onMultiSelect={onMultiSelect} connectedToServer={connectedToServer} downloadFile={this.props.downloadFile} multiselectEnabled={multiselectEnabled} item={item} openImage={this.props.openImage}/>
            </View>);
                },
            },
            {
                title: I18n.m.getMessage('filesUploader'),
                keyInData: 'uploadedByUsername',
                style: { width: 250 },
                dataType: 'string',
                cellRenderer: (item, _column, index) => {
                    return (<View key={`authorCell-${item.id}-${index}`} style={{
                            flex: 2,
                            justifyContent: 'flex-end',
                            flexDirection: 'row',
                            height: '100%',
                            paddingHorizontal: 16,
                            minWidth: 250,
                            width: 250,
                        }}>
              <Ripple key={`author:${item.id}_filename`} style={{
                            ...styles.listElementStyle,
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }} onPress={Routing.instance.openDialog('user', { userId: item.userId })}>
                <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>
                  <UserImage size={24} user={item.uploadedByUser}/>
                  <View style={{ width: 8 }}/>
                  <View style={{ flex: 1 }}>
                    <MaterialText centeredBox fixedWidth="100%" numberOfLines={1}>
                      {item.uploadedByUsername}
                    </MaterialText>
                  </View>
                </View>
              </Ripple>
            </View>);
                },
            },
            {
                title: I18n.m.getMessage('filesUploadDate'),
                keyInData: 'createdAt',
                dataType: 'Date',
                style: { width: 200 },
                cellStyle: { justifyContent: 'flex-end' },
            },
            {
                title: I18n.m.getMessage('filesFileTypeHeadline'),
                keyInData: 'type',
                dataType: 'string',
                sortable: true,
                style: { width: 200 },
            },
            {
                title: I18n.m.getMessage('filesFileSize'),
                keyInData: 'fileSizeInBytes',
                dataType: 'number',
                style: { width: 200 },
                cellStyle: { justifyContent: 'flex-end' },
                cellRenderer: (item, _column, index) => {
                    const storageUsageInBytes = item.fileSizeInBytes != null && item.fileSizeInBytes > 0 ? bytes(item.fileSizeInBytes) : '-';
                    return (<View key={`cell-fileSize-${item.id}-${index}`} style={{
                            flex: 1,
                            justifyContent: 'flex-end',
                            flexDirection: 'row',
                            height: '100%',
                            paddingHorizontal: 16,
                        }}>
              <MaterialText centeredBox>{storageUsageInBytes}</MaterialText>
            </View>);
                },
            },
            {
                title: I18n.m.getMessage('filesVersions'),
                keyInData: 'versions',
                dataType: 'number',
                style: { width: 200 },
                minWidth: 128,
                cellStyle: { justifyContent: 'flex-end' },
                cellRenderer: (item, _column, index) => {
                    return (<View key={`cell-fileSize-${item.id}-${index}`} style={{
                            flex: 1,
                            justifyContent: 'flex-end',
                            flexDirection: 'row',
                            height: '100%',
                            paddingHorizontal: 16,
                            minWidth: 128,
                        }}>
              <MaterialText centeredBox>{item.versions ? item.versions.length : '1'}</MaterialText>
            </View>);
                },
            },
        ];
        let filesUploadHint = 'filesUploadHint';
        if (currentFolder === 'tickets') {
            filesUploadHint += 'Tickets';
            preColumns.splice(1, 0, {
                title: I18n.m.getMessage('filesTicketTitle'),
                keyInData: 'ticketTitle',
                dataType: 'string',
                sortable: true,
                style: { width: 200 },
            });
            preColumns.splice(2, 0, {
                title: I18n.m.getMessage('ticketsDetailsNumber'),
                keyInData: 'ticketNumber',
                dataType: 'number',
                sortable: true,
                style: { width: 150 },
            });
        }
        if (currentFolder === 'reports') {
            filesUploadHint += 'Reports';
            preColumns.splice(1, 0, {
                title: I18n.m.getMessage('journalFrom'),
                keyInData: 'comment',
                dataType: 'string',
                sortable: true,
                style: { width: 200 },
            });
        }
        if (searchWords != null && searchWords.length > 0) {
            preColumns.unshift({
                title: I18n.m.getMessage('filesPathTitle'),
                keyInData: 'folder',
                dataType: 'string',
                sortable: false,
                style: { width: 200 },
                cellRenderer: (item, column, index, sLayout) => {
                    return <PathCellRenderer item={item} column={column} index={index} sLayout={sLayout}/>;
                },
            });
        }
        return [
            <View key="uploadStatusWrapper" style={{
                    width: '100%',
                    paddingTop: 8,
                    paddingHorizontal: 8,
                    height: uploadHeight,
                    maxHeight: ResizeEvent.current.contentHeight / 2,
                }} onLayout={this.onUploadStatusSize}>
        <Card outerPadding={0} style={{
                    width: '100%',
                    height: '100%',
                }}>
          <ScrollView key="uploadStatus" style={{
                    width: '100%',
                    height: '100%',
                    maxHeight: ResizeEvent.current.contentHeight / 2,
                }}>
            <UploadingList itemHeight={56} key={`uploadStatus_${currentFolder}`} type={`${projectId}_documents_upload_${currentFolder}`} onUploading={this.onUploading}/>
          </ScrollView>
        </Card>
      </View>,
            <View key="folderLocation" style={{
                    width: '100%',
                    paddingTop: 8,
                    paddingLeft: 8,
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                }} onLayout={this.onFolderLocationSize}>
        {this.generateLocations()}
      </View>,
            <Card key="Tablecard" style={{
                    padding: 0,
                    marginRight: 8,
                    marginTop: 0,
                    height: maxTableHeight,
                }}>
        <Table onMultiSelect={onMultiSelect} selectedIDs={selectedIDs} isSelectable={(item) => item.mimeType !== 'folder'} multiselectEnabled={multiselectEnabled} key={`table_${currentFolder}`} tableName={`StoredFiles${currentFolder === 'tickets' ? 'tickets' : ''}`} actionItemsLength={5} actions={(item) => {
                    const { downloadFolder, downloadFile, editFolder, editFile, showEnterpriseOnlyInfo } = this.props;
                    const { connectedToServer } = this.context;
                    if (item.mimeType === 'folder') {
                        return [
                            {
                                icon: 'pencil-outline',
                                disabled: !connectedToServer || (item.forEntityId != null && item.forEntityId.length > 0),
                                onAction: editFolder,
                                toolTip: I18n.m.getMessage('edit'),
                            },
                            {
                                icon: 'download-outline',
                                disabled: !connectedToServer || (item.forEntityId != null && item.forEntityId.length > 0),
                                onAction: downloadFolder,
                                toolTip: I18n.m.getMessage('filesDownload'),
                            },
                            {
                                icon: 'folder-move-outline',
                                disabled: item.forEntityId != null && item.forEntityId.length > 0,
                                onAction: this.moveFolder,
                                toolTip: I18n.m.getMessage('moveFolder'),
                            },
                            {
                                icon: 'share-outline',
                                onAction: this.shareFile,
                                toolTip: I18n.m.getMessage('share'),
                            },
                            {
                                icon: 'delete-outline',
                                disabled: !connectedToServer || (item.forEntityId != null && item.forEntityId.length > 0),
                                onAction: (item) => () => this.props.deleteFolder(item.id),
                                toolTip: I18n.m.getMessage('delete'),
                            },
                        ];
                    }
                    const localFile = OfflineDataDownloader.isMediaSynced(item.projectId, item.id, item.getFileId());
                    const tickeIcon = item.forEntity === 'Ticket' && item.forEntityId != null && item.forEntityId.length > 0;
                    const journalIcon = item.forEntity === 'Journal' && item.forEntityId != null && item.forEntityId.length > 0;
                    const excludeFolder = [item.folder == null || item.folder === '' ? 'private' : item.folder];
                    const editFileTypes = item.orgFilename.includes('pdf') ||
                        item.mimeType.includes('pdf') ||
                        item.mimeType.includes('png') ||
                        item.mimeType.includes('jpeg') ||
                        item.mimeType.includes('jpg');
                    const editDisabled = !editFileTypes ||
                        (!localFile && !connectedToServer) ||
                        (item.forEntityId != null && item.forEntityId.length > 0);
                    const project = CurrentProject.instance?.getCurrentProject();
                    let canEditForPdf = false;
                    if (!(item.mimeType.includes('pdf') || item.orgFilename.includes('pdf')))
                        canEditForPdf = true;
                    else if (project != null && project.projectSubscription === 'enterprise')
                        canEditForPdf = true;
                    const icons = [
                        {
                            icon: 'download-outline',
                            disabled: !(connectedToServer || localFile != null),
                            onAction: downloadFile(false, localFile),
                            toolTip: I18n.m.getMessage('filesDownload'),
                        },
                        {
                            icon: tickeIcon ? 'map-marker' : 'file-move-outline',
                            onAction: tickeIcon ? this.openTicket : this.moveFile([item.id], excludeFolder),
                            toolTip: I18n.m.getMessage(tickeIcon ? 'openTicket' : journalIcon ? 'storedFileCopyText' : 'moveFile'),
                        },
                        {
                            icon: 'form-textbox',
                            disabled: journalIcon,
                            onAction: this.changeFileName,
                            toolTip: I18n.m.getMessage('filesRename'),
                        },
                        {
                            icon: 'comment-outline',
                            disabled: journalIcon,
                            onAction: this.changeFileType,
                            toolTip: I18n.m.getMessage('filesChangeCommentTypeTooltip'),
                        },
                        {
                            icon: 'share-outline',
                            onAction: this.shareFile,
                            toolTip: I18n.m.getMessage('share'),
                        },
                    ];
                    if (journalIcon) {
                        icons.unshift({
                            icon: 'calendar',
                            onAction: this.openJournal,
                            toolTip: I18n.m.getMessage('openJournal'),
                        });
                    }
                    if (item.forEntityId == null || item.forEntityId.length === 0) {
                        icons.push({
                            icon: 'pencil-outline',
                            disabled: editDisabled,
                            onAction: canEditForPdf ? editFile : showEnterpriseOnlyInfo,
                            toolTip: I18n.m.getMessage('edit'),
                        });
                        icons.unshift({
                            icon: 'content-copy',
                            disabled: item.forEntityId != null && item.forEntityId.length > 0,
                            onAction: this.copyFile,
                            toolTip: I18n.m.getMessage('storedFileCopyText'),
                        });
                    }
                    icons.push({
                        icon: 'history',
                        onAction: this.props.showVersionsList,
                        toolTip: I18n.m.getMessage('filesVersions'),
                    }, {
                        icon: 'delete-outline',
                        disabled: !connectedToServer || (item.forEntityId != null && item.forEntityId.length > 0),
                        onAction: deleteFile,
                        toolTip: I18n.m.getMessage('delete'),
                    });
                    return icons;
                }} maxHeight={maxTableHeight - 16} emptyTableImage={notFoundImage} emptyTableText={I18n.m.getMessage('filesUploadDescription')} emptyTableHint={I18n.m.getMessage(filesUploadHint)} data={documents} sortBy="orgFilename" sortDirection="asc" columns={preColumns}/>
        <FilePicketComp dragNdrop id={`uploadFiles_to_folder_${fileIdCounter}`} multiple gotFile={this.onGotFilesOrFolder}/>
        <FilePicketComp id="filesTableViewFolderPicker" multiple={false} gotFile={this.onGotFolder} selectType="folder"/>
        <ImagePickerComp ref={this.setImagePicker} dragNdrop={false} options={new ImagePickerOptions({
                    includeBase64: false,
                    cropperCircleOverlay: false,
                    cropping: false,
                    dropMultiHandlerDialogText: I18n.m.getMessage('addImageOnMultiSelect'),
                    dropText: I18n.m.getMessage('addImageDropHint'),
                    selectMethod: 'any',
                    includeExif: true,
                    useFrontCamera: false,
                    multiple: true,
                    mediaType: 'photo',
                    filePicketCompId: 'filesInput',
                    compressImageQuality: 0.85,
                    editModeEnabled: CurrentUser.settings.appSettings == null ||
                        CurrentUser.settings.appSettings['openImageEditorAfterPhoto'] === true,
                })} callBack={this.onGotImageFile}/>
      </Card>,
        ];
    }
}
FilesViewTable.contextType = ConnectionContext;
