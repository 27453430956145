import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { StoredStartPages } from 'materialTheme/src/utils/StoredStartPages';
import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { convertProject } from 'upmesh-core/src/client/query/filter/ProjectFilter';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { ProjectImage } from '../project/ProjectImage';
import { CompanyUserInfo } from './CompanyUserInfo';
export function ProjectTable(props) {
    const [projects, setProjects] = useState();
    const loadProjects = async () => {
        const p = [];
        for (const project of props.projects) {
            p.push(await convertProject(project, CompanyUserInfo.company?.id));
        }
        setProjects(p);
    };
    useEffect(() => {
        loadProjects().catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
    }, [props.projects]);
    const columns = useRef([
        {
            title: { icon: 'domain', toolTip: I18n.m.getMessage('thumb') },
            keyInData: 'username',
            style: { width: 64 },
            sortable: false,
            cellRenderer: (item, columnData, _index, sLayout) => {
                const { style } = columnData;
                return (<View style={[
                        {
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            paddingRight: 16,
                            paddingLeft: 16,
                            flexDirection: 'row',
                            direction: sLayout ? 'rtl' : 'ltr',
                            overflow: 'hidden',
                            maxWidth: '100%',
                        },
                        style,
                    ]} key={`ViewAroundThumb${item.id}}`}>
              <View style={{ paddingRight: sLayout ? 0 : 8, paddingLeft: sLayout ? 8 : 0, paddingVertical: 4 }}>
                <ProjectImage project={item} size={36}/>
              </View>
            </View>);
            },
            dataType: 'string',
            minWidth: 64,
        },
        {
            title: I18n.m.getMessage('project'),
            keyInData: 'title',
            style: { width: 300, minWidth: 200 },
            minWidth: 200,
            sortable: true,
            dataType: 'string',
        },
        {
            title: I18n.m.getMessage('projectDescription'),
            keyInData: 'description',
            style: { width: 200, minWidth: 150 },
            minWidth: 150,
            sortable: true,
            dataType: 'string',
        },
        {
            title: I18n.m.getMessage('projectOwner'),
            keyInData: 'ownerName',
            style: { width: 200, minWidth: 150 },
            minWidth: 150,
            sortable: true,
            dataType: 'string',
        },
        {
            title: I18n.m.getMessage('projectCreatorNotOwner'),
            keyInData: 'creatorName',
            style: { width: 200, minWidth: 150 },
            minWidth: 150,
            sortable: true,
            dataType: 'string',
        },
        {
            title: I18n.m.getMessage('team'),
            keyInData: 'team',
            style: { width: 200, minWidth: 150 },
            minWidth: 150,
            sortable: true,
            dataType: 'string',
            cellRenderer: (item, column, _index) => {
                return (<View key={`type${item.id}`} style={[{ justifyContent: 'center', paddingHorizontal: 16 }, column.style]}>
              <MaterialText fixedWidth="100%" numberOfLines={1}>
                {item.team != null && item.team.length > 0
                        ? item.team.map((t, i) => {
                            let s = '';
                            if (i > 0)
                                s += ', ';
                            s += t.username;
                            return s;
                        })
                        : '-'}
              </MaterialText>
            </View>);
            },
        },
        {
            title: I18n.m.getMessage('createdAt'),
            keyInData: 'createdAt',
            style: { width: 170 },
            sortable: true,
            dataType: 'Date',
        },
        {
            title: I18n.m.getMessage('projectStart'),
            keyInData: 'projectStart',
            style: { width: 170 },
            sortable: true,
            dataType: 'Date',
        },
        {
            title: I18n.m.getMessage('projectEnd'),
            keyInData: 'projectEnd',
            style: { width: 170 },
            sortable: true,
            dataType: 'Date',
        },
        {
            title: I18n.m.getMessage('projectBuildingStart'),
            keyInData: 'constructionStart',
            style: { width: 170 },
            sortable: true,
            dataType: 'Date',
        },
        {
            title: I18n.m.getMessage('projectBuildingEnd'),
            keyInData: 'constructionEnd',
            style: { width: 170 },
            sortable: true,
            dataType: 'Date',
        },
        {
            title: I18n.m.getMessage('projectCostUnit'),
            keyInData: 'costUnit',
            style: { width: 170 },
            sortable: true,
            dataType: 'string',
        },
    ].concat(CompanyUserInfo.companySettings != null &&
        CompanyUserInfo.companySettings.projectStateNames != null &&
        CompanyUserInfo.companySettings.projectStateNames.length > 0
        ? [
            {
                title: I18n.m.getMessage('projectState'),
                keyInData: 'state',
                style: { width: 200, minWidth: 150 },
                minWidth: 150,
                sortable: true,
                dataType: 'string',
                cellRenderer: (item, column, _index) => {
                    return (<View key={`type${item.id}`} style={[{ justifyContent: 'center', paddingHorizontal: 16 }, column.style]}>
                    <MaterialText fixedWidth="100%" numberOfLines={1}>
                      {item.state != null && item.state.name != null && item.state.name.length > 0
                            ? item.state.name
                            : '-'}
                    </MaterialText>
                  </View>);
                },
            },
        ]
        : []));
    const onRowPress = (item, e) => {
        Routing.instance.goToButton(`projects/${item.id}/${StoredStartPages.getStoredProjectStartPage()}`)(e);
    };
    if (projects == null)
        return <Spinner />;
    return (<Table tableName={`projectTable_${props.tableName}`} columns={columns.current} actionItemsLength={1} maxHeight={props.height} onRowPress={onRowPress} data={projects}/>);
}
