"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RemoveProjectCraft = void 0;
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../../access/rights/RightsManager");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var ProjectCraftRemoved_1 = require("../../../events/project/crafts/ProjectCraftRemoved");
var ClientProjectCommands_1 = require("../../_root/ClientProjectCommands");
var RemoveProjectCraft = function (_ClientProjectCommand) {
  function RemoveProjectCraft(data, projectId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, RemoveProjectCraft);
    _this = _callSuper(this, RemoveProjectCraft, [data, projectId, token]);
    _this.aggregate = 'project';
    _this.commandName = 'RemoveProjectCraft';
    return _this;
  }
  (0, _inherits2.default)(RemoveProjectCraft, _ClientProjectCommand);
  return (0, _createClass2.default)(RemoveProjectCraft, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        yield (0, _get2.default)((0, _getPrototypeOf2.default)(RemoveProjectCraft.prototype), "checkProjectStatusAndGeneralAccess", this).call(this, this.entityId, userId);
        var project = yield CommandReadModels_1.CommandReadModels.project.getById(this.entityId);
        if (project.projectSubscription == null || project.projectSubscription === 'basic') {
          throw {
            message: 'Min. Pro-Projekt benötigt',
            messageCode: 'notInBasicProject'
          };
        }
        var tickets = yield CommandReadModels_1.CommandReadModels.ticket.get({
          filter: `projectId eq '${this.entityId}' and craft eq '${this.data.craft.replace(/'/gi, "''")}'`,
          top: 1
        });
        if (tickets.length > 0) {
          throw {
            message: 'Das Gewerk ist mindestens einem Ticket zugeordnet und kann deswegen nicht gelöscht werden',
            messageCode: 'projectCraftRemoveErrorAssignedToMinOneTicket'
          };
        }
        if (yield RightsManager_1.RightsManager.hasWriteRight(this.entityId, userId, 'commandChangeProjectCrafts')) {
          return true;
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return new ProjectCraftRemoved_1.ProjectCraftRemoved(this.entityId, Object.assign({}, this.data));
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {
        var _project$crafts;
        var project = yield CommandReadModels_1.CommandReadModels.project.getById(this.entityId);
        if (!((_project$crafts = project.crafts) != null && _project$crafts.includes(this.data.craft))) {
          throw {
            message: 'Das Gewerk existiert nicht im Projekt',
            messageCode: 'projectCraftNotExists'
          };
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(ClientProjectCommands_1.ClientProjectCommands);
exports.RemoveProjectCraft = RemoveProjectCraft;