"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CommandOfflineFirstStore = exports.CommandOfflineFirstStoreConfig = exports.CommandOfflineModel = void 0;
var B64 = require("base-64");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var ts_events_1 = require("ts-events");
var CommandRemoteStore_1 = require("../../core/commands/CommandRemoteStore");
var EventRootOdata_1 = require("../../core/events/EventRootOdata");
var CommandOfflineRegister_1 = require("./CommandOfflineRegister");
var CommandOfflineModel = function (_OdataEntity_1$OdataE) {
  function CommandOfflineModel(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, CommandOfflineModel);
    _this = _callSuper(this, CommandOfflineModel, [obj]);
    _this.entityName = 'CommandOfflineModel';
    if (obj != null) {
      _this.fill(obj);
    }
    return _this;
  }
  (0, _inherits2.default)(CommandOfflineModel, _OdataEntity_1$OdataE);
  return (0, _createClass2.default)(CommandOfflineModel, [{
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {
        return true;
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = OdataEntity_1.OdataEntity.createBaseMap();
      baseMap.set('aggregate', new OdataEntity_1.OdataIndex(true));
      baseMap.set('commandName', new OdataEntity_1.OdataIndex(true));
      baseMap.set('entityId', new OdataEntity_1.OdataIndex());
      baseMap.set('data', new OdataEntity_1.OdataIndex());
      baseMap.set('synced', new OdataEntity_1.OdataIndex(true));
      baseMap.set('syncTime', new OdataEntity_1.OdataIndex());
      baseMap.set('error', new OdataEntity_1.OdataIndex());
      baseMap.set('result', new OdataEntity_1.OdataIndex());
      return baseMap;
    }
  }, {
    key: "createOne",
    value: function createOne(obj) {
      return new CommandOfflineModel(obj);
    }
  }]);
}(OdataEntity_1.OdataEntity);
exports.CommandOfflineModel = CommandOfflineModel;
var CommandOfflineFirstStoreConfig = function (_CommandRemoteStore_) {
  function CommandOfflineFirstStoreConfig() {
    var _this2;
    (0, _classCallCheck2.default)(this, CommandOfflineFirstStoreConfig);
    _this2 = _callSuper(this, CommandOfflineFirstStoreConfig, arguments);
    _this2.discardOnErrors = 10;
    return _this2;
  }
  (0, _inherits2.default)(CommandOfflineFirstStoreConfig, _CommandRemoteStore_);
  return (0, _createClass2.default)(CommandOfflineFirstStoreConfig);
}(CommandRemoteStore_1.CommandRemoteStoreConfig);
exports.CommandOfflineFirstStoreConfig = CommandOfflineFirstStoreConfig;
var CommandOfflineFirstStore = function (_CommandRemoteStore_2) {
  function CommandOfflineFirstStore(config) {
    var _this3;
    (0, _classCallCheck2.default)(this, CommandOfflineFirstStore);
    _this3 = _callSuper(this, CommandOfflineFirstStore, [config]);
    _this3.onError = new ts_events_1.AsyncEvent();
    _this3._syncing = false;
    _this3._hasToSync = false;
    _this3.abortSync = false;
    _this3.lastUserId = '';
    _this3.currentSyncStatus = {
      percent: 0,
      running: false
    };
    _this3.onConnected = function () {
      _this3.syncCommands().catch(function (err) {
        return void 0;
      });
    };
    _this3.onSyncCouldStart = function () {
      _this3.syncCommands().catch(function (err) {
        return void 0;
      });
    };
    _this3.onSyncShouldStop = function () {
      _this3.abortSyncCommands();
    };
    _this3.offlineCommandRepo = config.commandDb.getRepos(new CommandOfflineModel());
    _this3.eventHandler = config.eventHandler;
    if (config.remoteConfig != null) {
      var _config$remoteConfig$, _config$remoteConfig$2, _config$remoteConfig$3, _config$remoteConfig$4, _config$remoteConfig$5, _config$remoteConfig$6, _config$remoteConfig$7;
      _this3.remoteConfig = config.remoteConfig;
      _this3.lastUserId = '';
      if (_this3.remoteConfig.serverConnection != null && _this3.remoteConfig.serverConnection.token != null && _this3.remoteConfig.serverConnection.token.length > 0) {
        try {
          var bValue = JSON.parse(B64.decode(_this3.remoteConfig.serverConnection.token.split('.')[1]));
          _this3.lastUserId = bValue._id;
        } catch (e2) {}
      }
      (_config$remoteConfig$ = config.remoteConfig.serverConnection) == null ? void 0 : _config$remoteConfig$.onSyncCouldStart.detach(_this3.onSyncCouldStart);
      (_config$remoteConfig$2 = config.remoteConfig.serverConnection) == null ? void 0 : _config$remoteConfig$2.onSyncShouldStop.detach(_this3.onSyncShouldStop);
      (_config$remoteConfig$3 = config.remoteConfig.serverConnection) == null ? void 0 : _config$remoteConfig$3.onDisconnect.detach(_this3.onSyncShouldStop);
      (_config$remoteConfig$4 = config.remoteConfig.serverConnection) == null ? void 0 : _config$remoteConfig$4.onConnect.detach(_this3.onConnected);
      (_config$remoteConfig$5 = config.remoteConfig.serverConnection) == null ? void 0 : _config$remoteConfig$5.onSyncCouldStart.attach(_this3.onSyncCouldStart);
      (_config$remoteConfig$6 = config.remoteConfig.serverConnection) == null ? void 0 : _config$remoteConfig$6.onSyncShouldStop.attach(_this3.onSyncShouldStop);
      (_config$remoteConfig$7 = config.remoteConfig.serverConnection) == null ? void 0 : _config$remoteConfig$7.onDisconnect.attach(_this3.onSyncShouldStop);
      if (config.startSyncOnConnection) {
        var _config$remoteConfig$8;
        (_config$remoteConfig$8 = config.remoteConfig.serverConnection) == null ? void 0 : _config$remoteConfig$8.onConnect.attach(_this3.onConnected);
      }
      if (_this3.remoteStore == null) _this3.remoteStore = new CommandRemoteStore_1.CommandRemoteStore(new CommandRemoteStore_1.CommandRemoteStoreConfig(config.remoteConfig.serverConnection, _this3.remoteConfig.dispatcher));
    }
    return _this3;
  }
  (0, _inherits2.default)(CommandOfflineFirstStore, _CommandRemoteStore_2);
  return (0, _createClass2.default)(CommandOfflineFirstStore, [{
    key: "isSyncing",
    get: function get() {
      return this._syncing || this._hasToSync;
    }
  }, {
    key: "syncing",
    get: function get() {
      return this._syncing;
    }
  }, {
    key: "hasToSync",
    get: function get() {
      return this._hasToSync;
    }
  }, {
    key: "save",
    value: function () {
      var _save = (0, _asyncToGenerator2.default)(function* (command) {
        var _this4 = this;
        var timedout = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        var offlineUsage = false;
        if (this.remoteStore != null && this.remoteStore.isConnected) {
          try {
            return yield this.remoteStore.save(command);
          } catch (e) {
            if (e['messageCode'] !== 'noConnection') throw e;
          }
        }
        offlineUsage = CommandOfflineRegister_1.CommandOfflineRegister.isRegistered(command);
        if (offlineUsage) {
          var oc = CommandOfflineRegister_1.CommandOfflineRegister.getRegisteredCommand(`${command.aggregate}_${command.commandName}`);
          if (oc != null) {
            var c = oc.clone(command.data, command.entityId, command.token);
            var userId = '';
            if (c.token != null && c.token.length > 0) {
              try {
                var bValue = JSON.parse(B64.decode(c.token.split('.')[1]));
                userId = bValue._id;
              } catch (e2) {}
            }
            if (this.remoteStore != null) {
              var entity = new CommandOfflineModel();
              entity.commandName = c.commandName;
              entity.entityId = c.entityId;
              entity.aggregate = c.aggregate;
              entity.data = c.data;
              entity.error = false;
              yield this.offlineCommandRepo.post(entity);
              this._hasToSync = true;
            }
            yield c.canI();
            yield c.validate();
            var e = yield c.createEvents();
            this.postEvents(e, userId);
            return null;
          }
        }
        if (timedout) {
          throw {
            messageCode: 'commandOfflineNotPossible',
            message: 'Die Aktion kann offline nicht ausgeführt werden'
          };
        } else {
          return new Promise(function (resolve, reject) {
            setTimeout((0, _asyncToGenerator2.default)(function* () {
              try {
                var result = yield _this4.save(command, true);
                resolve(result);
              } catch (e) {
                _this4.onError.post(e);
                reject(e);
              }
            }), 3000);
          });
        }
      });
      function save(_x2) {
        return _save.apply(this, arguments);
      }
      return save;
    }()
  }, {
    key: "syncCommands",
    value: function () {
      var _syncCommands = (0, _asyncToGenerator2.default)(function* () {
        var _this5 = this;
        if (this.syncAgainTimer != null) {
          clearTimeout(this.syncAgainTimer);
        }
        var remoteStore = this.remoteStore;
        var connected = remoteStore != null && remoteStore.isConnected;
        if (remoteStore != null && connected && !this._syncing) {
          this._syncing = true;
          yield new Promise(function (resolve) {
            setTimeout(resolve, 1500);
          });
          try {
            var all = yield this.offlineCommandRepo.get({
              filter: 'synced ne true',
              orderby: 'createdAt ASC'
            });
            if (all.length > 0) {
              var commands = (0, _toConsumableArray2.default)(all);
              for (var i = 0; i < commands.length; i += 1) {
                var commandModals = all.shift();
                if (commandModals != null) {
                  if (this.abortSync) {
                    this.abortSync = false;
                    break;
                  }
                  try {
                    var getCommand = CommandOfflineRegister_1.CommandOfflineRegister.getRegisteredCommand(`${commandModals.aggregate}_${commandModals.commandName}`);
                    if (getCommand != null) {
                      var command = getCommand.clone(commandModals.data, commandModals.entityId, this.remoteConfig.serverConnection ? this.remoteConfig.serverConnection.token : '');
                      yield command.execute(remoteStore);
                      if (command.executed) {
                        commandModals.syncTime = new Date();
                        commandModals.synced = true;
                        commandModals.error = false;
                        yield this.offlineCommandRepo.patch(commandModals);
                      }
                    }
                  } catch (e) {
                    commandModals.error = true;
                    commandModals.errorCounter = commandModals.errorCounter != null ? commandModals.errorCounter + 1 : 1;
                    commandModals.result = JSON.stringify(e);
                    if (this.config.discardOnErrors === -1 || commandModals.errorCounter < this.config.discardOnErrors) yield this.offlineCommandRepo.patch(commandModals);
                  }
                }
              }
            }
          } catch (e) {}
          this._hasToSync = false;
          this._syncing = false;
        } else {
          try {
            if (this.remoteStore != null) {
              var counts = yield this.offlineCommandRepo.count('synced ne true');
              if (counts > 0) {
                this.syncAgainTimer = setTimeout(function () {
                  _this5.syncCommands().catch(function (err) {
                    return void 0;
                  });
                }, 2000);
              }
            }
          } catch (e) {}
        }
      });
      function syncCommands() {
        return _syncCommands.apply(this, arguments);
      }
      return syncCommands;
    }()
  }, {
    key: "abortSyncCommands",
    value: function abortSyncCommands() {
      if (this._syncing) {
        this.abortSync = true;
      }
    }
  }, {
    key: "postEvents",
    value: function postEvents(e, createdBy) {
      if (Array.isArray(e)) {
        for (var event of e) {
          this.eventHandler.post(new EventRootOdata_1.EventRootOdata(Object.assign({}, event, {
            createdBy: createdBy
          })));
        }
      } else {
        this.eventHandler.post(new EventRootOdata_1.EventRootOdata(Object.assign({}, e, {
          createdBy: createdBy
        })));
      }
    }
  }]);
}(CommandRemoteStore_1.CommandRemoteStore);
exports.CommandOfflineFirstStore = CommandOfflineFirstStore;