import { DialogUp } from 'materialTheme/src/theme/components/DialogUp';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import PdfViewerComponent from './PdfViewerComponent.web';
export class PDFviewer extends PureComponent {
    constructor(props) {
        super(props);
        this.closeDialog = async (_e) => {
            const { hasChanged } = this.state;
            const canUndo = await this.canUndo();
            const { saveFunction, originalFilename } = this.props;
            try {
                if ((hasChanged || canUndo) && saveFunction != null && this.pdfview != null) {
                    const { documentViewer, annotationManager } = this.pdfview;
                    const doc = documentViewer.getDocument();
                    const xfdfString = await annotationManager.exportAnnotations();
                    const data = await doc.getFileData({
                        xfdfString,
                        flatten: false,
                    });
                    const arr = new Uint8Array(data);
                    const blob = new Blob([arr], { type: 'application/pdf' });
                    if (originalFilename != null) {
                        blob['name'] = originalFilename;
                    }
                    const file = new File([blob], originalFilename != null ? originalFilename : 'export.pdf', {
                        type: 'application/pdf',
                        lastModified: new Date().getTime(),
                    });
                    DialogUp.instance?.close(() => {
                        saveFunction(file).catch((err) => console.debug('cant save pdf', err));
                    });
                }
                else {
                    DialogUp.instance?.close(() => {
                        saveFunction().catch((err) => console.debug('cant save pdf', err));
                    });
                }
            }
            catch (err) {
                console.debug('cant save pdf', err);
                DialogUp.instance?.close();
            }
        };
        this.setRef = (r) => {
            this.pdfview = r;
        };
        this.onChange = () => {
            this.setState({ hasChanged: true });
        };
        this.state = { hasChanged: false };
    }
    async canUndo() {
        try {
            if (this.pdfview) {
                const doc = this.pdfview.documentViewer;
                if (doc && doc.getAnnotationHistoryManager()) {
                    return doc.getAnnotationHistoryManager().canUndo();
                }
            }
        }
        catch (e) {
            console.debug('cant get has Changed', e);
            return false;
        }
        return false;
    }
    render() {
        const { link, id } = this.props;
        return (<View style={{ width: '100%', height: '100%', position: 'relative' }}>
        <PdfViewerComponent close={this.closeDialog} setRef={this.setRef} document={link} onChange={this.onChange} storedFileId={id}/>
        <View style={{ position: 'absolute', top: 1, left: 16 }}>
          <Icon icon="arrow-left" toolTip="" onPress={this.closeDialog}/>
        </View>
      </View>);
    }
}
PDFviewer.open = (props) => (e) => {
    const openPosition = e != null
        ? { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY }
        : { x: ResizeEvent.current.windowWidth / 2, y: ResizeEvent.current.windowHeight / 2 };
    DialogUp.instance?.open({
        content: <PDFviewer {...props}/>,
        fullscreen: true,
        contentPadding: false,
        scrollable: false,
        openPosition,
    });
};
