var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FormInputFilled = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireDefault(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _ThemeManager = require("../../ThemeManager");
var _FormInputAnimation = require("../animations/FormInputAnimation");
var _FormInputBase2 = require("./FormInputBase");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var FormInputFilled = exports.FormInputFilled = function (_FormInputBase) {
  function FormInputFilled(props) {
    var _this;
    (0, _classCallCheck2.default)(this, FormInputFilled);
    _this = _callSuper(this, FormInputFilled, [props]);
    _this.animation = new _FormInputAnimation.FormInputAnimation();
    _this.state = Object.assign({}, _this.state, {
      color: _ThemeManager.ThemeManager.style.black42
    });
    return _this;
  }
  (0, _inherits2.default)(FormInputFilled, _FormInputBase);
  return (0, _createClass2.default)(FormInputFilled, [{
    key: "render",
    value: function render() {
      var _this$props = this.props,
        style = _this$props.style,
        backgroundColor = _this$props.backgroundColor,
        disabled = _this$props.disabled;
      var color = this.state.color;
      var height = _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(this.getHeight());
      var textInput = this.renderBase();
      var width = this.getWidth();
      var borderColor = disabled ? _ThemeManager.ThemeManager.style.black42 : 'transparent';
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          width: width,
          height: height + this.helperTextHeight,
          position: 'relative'
        },
        pointerEvents: "box-none",
        children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          pointerEvents: "box-none",
          style: [style, {
            backgroundColor: backgroundColor,
            height: height - _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(10),
            borderBottomWidth: 1,
            borderBottomColor: borderColor,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            position: 'absolute',
            top: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(10),
            left: 0,
            right: 0
          }]
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          pointerEvents: "box-none",
          style: {
            position: 'absolute',
            left: 0,
            right: 0,
            top: height - _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(1),
            zIndex: 99,
            backgroundColor: 'transparent',
            height: 2,
            justifyContent: 'center',
            alignContent: 'center'
          },
          children: (0, _jsxRuntime.jsx)(_reactNativeWeb.Animated.View, {
            style: [{
              alignSelf: 'center',
              width: '100%',
              zIndex: 100,
              backgroundColor: color,
              height: 2
            }, this.animation.animations]
          })
        }), textInput]
      });
    }
  }, {
    key: "removekeyboardDidHideListener",
    value: function removekeyboardDidHideListener() {
      if (this.keyboardDidHideListener != null) {
        this.keyboardDidHideListener.remove();
        this.keyboardDidHideListener = undefined;
      }
    }
  }, {
    key: "onBlur",
    value: function onBlur() {
      var submitOnBlur = this.props.submitOnBlur;
      if (submitOnBlur) this.onSubmitEditing();
      if (this.avoidOnBlurOnce) {
        this.avoidOnBlurOnce = false;
        return;
      }
      this.removekeyboardDidHideListener();
      this.animation.toValue(0);
      if (this.mounted) {
        this.setState({
          focused: false,
          color: _ThemeManager.ThemeManager.style.black42
        }, (0, _get2.default)((0, _getPrototypeOf2.default)(FormInputFilled.prototype), "onBlur", this));
      }
    }
  }, {
    key: "onFocus",
    value: function onFocus() {
      var _this2 = this;
      this.removekeyboardDidHideListener();
      this.keyboardDidHideListener = _reactNativeWeb.Keyboard.addListener('keyboardDidHide', this.keyboardDidHide);
      var color = this.props.color != null ? this.props.color : _ThemeManager.ThemeManager.style.brandPrimary;
      this.animation.toValue(1);
      this.setState({
        color: color,
        focused: true
      }, function () {
        if (_this2.props.onFocus != null) {
          _this2.props.onFocus();
        }
        _this2.filterText('');
        if ((0, _get2.default)((0, _getPrototypeOf2.default)(FormInputFilled.prototype), "onFocus", _this2) != null) {
          (0, _get2.default)((0, _getPrototypeOf2.default)(FormInputFilled.prototype), "onFocus", _this2).call(_this2);
        }
      });
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return Object.assign({}, _FormInputBase2.FormInputBase.defaultProps, {
        color: _ThemeManager.ThemeManager.style.brandPrimary,
        unfocusedColor: _ThemeManager.ThemeManager.style.black42,
        backgroundColor: _ThemeManager.ThemeManager.style.formInputFilledBackgroundColor
      });
    }
  }]);
}(_FormInputBase2.FormInputBase);