"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UpdatePushNotificationToken = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var ClientStore_1 = require("../../ClientStore");
var NotificationsPushTokenRefreshed_1 = require("../../events/settings/NotificationsPushTokenRefreshed");
var UpdatePushNotificationToken = function (_CommandRoot_1$Comman) {
  function UpdatePushNotificationToken(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : CurrentUser_1.CurrentUser.userId;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, UpdatePushNotificationToken);
    _this = _callSuper(this, UpdatePushNotificationToken, [data, entityId, token]);
    _this.commandName = 'UpdatePushNotificationToken';
    _this.aggregate = 'project';
    return _this;
  }
  (0, _inherits2.default)(UpdatePushNotificationToken, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(UpdatePushNotificationToken, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all' || userId === this.entityId) {
          return true;
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {});
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var _this2 = this;
        var tokenData = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token);
        var getSettings = yield CommandReadModels_1.CommandReadModels.notificationsSettings.getById(tokenData._id);
        if (getSettings.pushNotifications == null) return null;
        var i = getSettings.pushNotifications.findIndex(function (p) {
          return p.deviceId === _this2.data.deviceId;
        });
        if (i === -1) return null;
        if (getSettings.pushNotifications[i].token === this.data.token) return null;
        return new NotificationsPushTokenRefreshed_1.NotificationsPushTokenRefreshed(this.entityId, Object.assign({}, this.data), tokenData._id);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "execute",
    value: function () {
      var _execute = (0, _asyncToGenerator2.default)(function* () {
        var onStore = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ClientStore_1.ClientStore.commandStore;
        return (0, _get2.default)((0, _getPrototypeOf2.default)(UpdatePushNotificationToken.prototype), "execute", this).call(this, onStore);
      });
      function execute() {
        return _execute.apply(this, arguments);
      }
      return execute;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.UpdatePushNotificationToken = UpdatePushNotificationToken;