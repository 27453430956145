"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.literal = exports.keys = exports.filter = exports.query = exports.resourcePath = exports.odataUri = exports.Parser = exports.parserFactory = void 0;
var primitiveLiteral_1 = require("./primitiveLiteral");
var expressions_1 = require("./expressions");
var query_1 = require("./query");
var resourcePath_1 = require("./resourcePath");
var odataUri_1 = require("./odataUri");
var parserFactory = function parserFactory(fn) {
  return function (source, options) {
    options = options || {};
    var raw = new Uint16Array(source.length);
    var pos = 0;
    for (var i = 0; i < source.length; i++) {
      raw[i] = source.charCodeAt(i);
    }
    var result = fn(raw, pos, options.metadata);
    if (!result) throw new Error(`Fail at ${pos}`);
    if (result.next < raw.length) throw new Error(`Unexpected character at ${result.next}`);
    return result;
  };
};
exports.parserFactory = parserFactory;
var Parser = function () {
  function Parser() {
    (0, _classCallCheck2.default)(this, Parser);
  }
  return (0, _createClass2.default)(Parser, [{
    key: "odataUri",
    value: function odataUri(source, options) {
      return (0, exports.parserFactory)(odataUri_1.default.odataUri)(source, options);
    }
  }, {
    key: "resourcePath",
    value: function resourcePath(source, options) {
      return (0, exports.parserFactory)(resourcePath_1.default.resourcePath)(source, options);
    }
  }, {
    key: "query",
    value: function query(source, options) {
      return (0, exports.parserFactory)(query_1.default.queryOptions)(source, options);
    }
  }, {
    key: "filter",
    value: function filter(source, options) {
      return (0, exports.parserFactory)(expressions_1.default.boolCommonExpr)(source, options);
    }
  }, {
    key: "keys",
    value: function keys(source, options) {
      return (0, exports.parserFactory)(expressions_1.default.keyPredicate)(source, options);
    }
  }, {
    key: "literal",
    value: function literal(source, options) {
      return (0, exports.parserFactory)(primitiveLiteral_1.default.primitiveLiteral)(source, options);
    }
  }]);
}();
exports.Parser = Parser;
function odataUri(source, options) {
  return (0, exports.parserFactory)(odataUri_1.default.odataUri)(source, options);
}
exports.odataUri = odataUri;
function resourcePath(source, options) {
  return (0, exports.parserFactory)(resourcePath_1.default.resourcePath)(source, options);
}
exports.resourcePath = resourcePath;
function query(source, options) {
  return (0, exports.parserFactory)(query_1.default.queryOptions)(source, options);
}
exports.query = query;
function filter(source, options) {
  return (0, exports.parserFactory)(expressions_1.default.boolCommonExpr)(source, options);
}
exports.filter = filter;
function keys(source, options) {
  return (0, exports.parserFactory)(expressions_1.default.keyPredicate)(source, options);
}
exports.keys = keys;
function literal(source, options) {
  return (0, exports.parserFactory)(primitiveLiteral_1.default.primitiveLiteral)(source, options);
}
exports.literal = literal;