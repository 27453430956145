import { AudioManager } from 'materialTheme/src/theme/components/audio/AudioManager';
import AudioPlayer from 'materialTheme/src/theme/components/audio/AudioPlayer';
import { ContentFooterEventHandler, } from 'materialTheme/src/theme/components/footer/ContentFooterEventHandler';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { MaterialTextEditable } from 'materialTheme/src/theme/components/text/MaterialTextEditable';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import React, { PureComponent } from 'react';
import { Platform, ScrollView, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { CreateTicketComment, } from 'upmesh-core/src/client/commands/ticketcomments/CreateTicketComment';
import { I18n } from '../../../i18n/I18n';
import { DefaultErrorHandler } from '../../DefaultErrorHandler';
import { RoutesConfigProtectedProjects } from '../../routes/RoutesConfigProtectedProjects';
export class ActivitiesInput extends PureComponent {
    constructor(props) {
        super(props);
        this.saveKey = '';
        this.setScrollView = (r) => {
            this.scrollView = r;
        };
        this.onContentSizeChange = () => {
            if (this.scrollView != null) {
                try {
                    this.scrollView.scrollToEnd();
                }
                catch (e) {
                    console.debug('cant scroll down', e);
                }
            }
        };
        this.discardRecording = () => {
            const { recordedFile } = this.state;
            if (recordedFile != null) {
                AudioManager.discardRecording(recordedFile).catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
            }
            this.setState({ recordedFile: undefined });
        };
        this.uploadRecording = () => {
            const { recordedFile } = this.state;
            this.props.uploadFile({ path: recordedFile });
            this.setState({ recordedFile: undefined });
        };
        this.pad = (num) => {
            return `0${num}`.slice(-2);
        };
        this.mmss = (s) => {
            let secs = Math.floor(s);
            let minutes = Math.floor(secs / 60);
            secs %= 60;
            minutes %= 60;
            return `${this.pad(minutes)}:${this.pad(secs)}`;
        };
        this.clearText = () => {
            SimpleStorage.set(this.saveKey, '');
            this.setState({ inputText: '' });
        };
        this.startRecord = () => {
            AudioManager.startRecording(this.callBack)
                .then((_file) => {
                this.setState({ recording: true, recordDuration: 0 });
            })
                .catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
        };
        this.callBack = (e) => {
            if (e != null && e.currentPosition != null)
                this.setState({ recordDuration: e.currentPosition });
        };
        this.stopRecord = () => {
            AudioManager.stopRecording()
                .then(async (uri) => {
                if (uri === 'Already stopped') {
                    return this.setState({ recording: false, recordedFile: undefined });
                }
                return this.setState({ recording: false, recordedFile: uri });
            })
                .catch((err) => console.debug(err));
        };
        this.footerStatus = (e) => {
            this.setState({ footerOpen: e.open });
        };
        this.onChangeText = (text) => {
            SimpleStorage.set(this.saveKey, text);
            this.setState({ inputText: text });
        };
        this.onLayout = (e) => {
            if (e != null && e.nativeEvent != null && e.nativeEvent.layout != null) {
                const inputWidth = e.nativeEvent.layout.width - (Platform.OS !== 'web' ? 124 : 160);
                this.setState({ inputWidth });
            }
        };
        this.sendComment = async () => {
            this.setState({ inputLocked: true }, async () => {
                const { inputText } = this.state;
                if (this.ti != null && inputText.length > 0) {
                    const a = this.createActivitie();
                    a.comment = inputText;
                    try {
                        const c = new CreateTicketComment({ ...a });
                        await c.execute();
                    }
                    catch (e) {
                        ErrorReporter.sendReport({ subject: 'cant create Comment', data: e, type: 'warn' });
                        Routing.instance.alert.post({
                            text: `${I18n.m.getMessage('ticketsDetailsSendCommentError')} ${DefaultErrorHandler.getDefaultErrorMessages(e)}`,
                        });
                    }
                    SimpleStorage.set(this.saveKey, '');
                    this.setState({ inputLocked: false, inputText: '' });
                }
            });
        };
        this.saveKey = `${CurrentUser.userId}_${props.ticketId}`;
        this.state = {
            width: 0,
            ticketId: '',
            projectId: '',
            footerOpen: RoutesConfigProtectedProjects.footerOpen,
            inputText: '',
            inputLocked: false,
            disabled: false,
            inputWidth: 0,
            recording: false,
            recordDuration: 0,
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        const newState = prevState;
        let updateNeeded = false;
        if (prevState.width !== nextProps.width) {
            newState.width = nextProps.width;
            updateNeeded = true;
        }
        if (prevState.ticketId !== nextProps.ticketId) {
            newState.ticketId = nextProps.ticketId;
            updateNeeded = true;
        }
        if (prevState.projectId !== nextProps.projectId) {
            newState.projectId = nextProps.projectId;
            updateNeeded = true;
        }
        if (updateNeeded) {
            return newState;
        }
        return null;
    }
    componentDidMount() {
        const value = SimpleStorage.get(this.saveKey);
        if (value != null && value.length > 0 && value !== this.state.inputText)
            this.onChangeText(value);
        ContentFooterEventHandler.statusEvent.attach(this.footerStatus);
        this.checkProjectStatus().catch((err) => console.debug(err));
    }
    componentWillUnmount() {
        ContentFooterEventHandler.statusEvent.detach(this.footerStatus);
    }
    renderRecording() {
        const { recording, recordedFile, inputWidth, recordDuration } = this.state;
        if (recording)
            return (<View style={{
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingHorizontal: 8,
                    overflow: 'visible',
                }}>
          <Icon icon="microphone" onPress={this.stopRecord} color={ThemeManager.style.brandDanger} toolTip={I18n.m.getMessage('recording')}/>

          <MaterialText centeredBox>
            {I18n.m.getMessage('recording', { time: this.mmss(recordDuration / 1000) })}
          </MaterialText>
          <Icon icon="stop-circle-outline" onPress={this.stopRecord} toolTip={I18n.m.getMessage('cancel')}/>
        </View>);
        if (recordedFile == null)
            return null;
        return (<View style={{
                width: '100%',
                minHeight: 96,
                height: '100%',
                overflow: 'visible',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
        <View style={{
                width: inputWidth + 24,
                overflow: 'visible',
                minHeight: 96,
            }}>
          <AudioPlayer src={recordedFile} duration={recordDuration / 1000} errorHandler={DefaultErrorHandler.showDefaultErrorAlert}/>
        </View>
        <View style={{ width: 84, height: 64, flexDirection: 'row', marginRight: 16, justifyContent: 'space-between' }}>
          <Icon icon="delete-outline" color={ThemeManager.style.brandDanger} onPress={this.discardRecording} toolTip={I18n.m.getMessage('cancel')}/>
          <Icon icon="send" color={ThemeManager.style.brandPrimary} onPress={this.uploadRecording} toolTip={I18n.m.getMessage('ok')}/>
        </View>
      </View>);
    }
    render() {
        const { addAttachment, onFocus } = this.props;
        const { inputText, inputLocked, inputWidth, recording, recordedFile } = this.state;
        return (<View style={{ width: '100%', overflow: 'visible', maxHeight: '100%', paddingBottom: 0 }} onLayout={this.onLayout}>
        <View style={{
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-between',
                minHeight: 72,
                maxHeight: '100%',
                overflow: 'visible',
            }}>
          {recording || recordedFile != null ? (<View style={{
                    width: inputWidth + 124,
                    height: 96,
                    justifyContent: 'center',
                    overflow: 'visible',
                }}>
              {this.renderRecording()}
            </View>) : (<>
              <Ripple hoverColor={ThemeManager.style.lisItemHoverColor} onPress={addAttachment} toolTip={I18n.m.getMessage('ticketsDetailsAddAttachment')} style={{
                    width: 36,
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                    height: 72,
                }}>
                <Icon toolTip={I18n.m.getMessage('ticketsDetailsAddAttachment')} icon="attachment"/>
              </Ripple>
              <ScrollView ref={this.setScrollView} style={{
                    width: inputWidth,
                    height: 'auto',
                    maxHeight: ResizeEvent.current.windowHeight / 2,
                    minHeight: 72,
                }} onContentSizeChange={this.onContentSizeChange} contentContainerStyle={{
                    width: inputWidth,
                }}>
                <MaterialTextEditable onSubmitByEnter={this.sendComment} clickPadding={27} editable={!this.state.disabled} key={`comment_${this.state.disabled}`} accessibilityLabel="write-comment" materailTextProps={{ color: ThemeManager.style.black87 }} style={{
                    paddingLeft: ThemeManager.style.contentPaddingValue,
                }} placeholder={I18n.m.getMessage('ticketsDetailsWriteComment')} onChange={this.onChangeText} ref={(e) => {
                    this.ti = e;
                }} underlineColor="transparent" onFocus={onFocus}>
                  {inputText}
                </MaterialTextEditable>
              </ScrollView>
              <View style={{
                    width: Platform.OS !== 'web' ? 52 : 88,
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    paddingHorizontal: 8,
                    alignItems: 'flex-start',
                }}>
                {inputText != null && inputText.length > 0 ? (<Ripple hoverColor={ThemeManager.style.lisItemHoverColor} toolTip={I18n.m.getMessage('reset')} onPress={this.clearText} style={{
                        height: 72,
                        justifyContent: 'center',
                        width: 36,
                        alignItems: 'flex-end',
                    }}>
                    <Icon icon="close" toolTip={I18n.m.getMessage('reset')}/>
                  </Ripple>) : null}

                {(inputText != null && inputText !== '') || Platform.OS === 'web' ? (<Ripple hoverColor={ThemeManager.style.lisItemHoverColor} toolTip={I18n.m.getMessage('tooltipTicketsDetailsSend')} onPress={this.sendComment} disabled={inputText == null || inputText.length === 0 || inputText.trim().length === 0 || inputLocked} style={{
                        height: 72,
                        justifyContent: 'center',
                        width: 36,
                        alignItems: 'flex-end',
                    }}>
                    <Icon icon="send" color={ThemeManager.style.brandPrimary} toolTip=""/>
                  </Ripple>) : (<Ripple hoverColor={ThemeManager.style.lisItemHoverColor} onPress={this.startRecord} style={{
                        width: 36,
                        justifyContent: 'center',
                        height: 72,
                        alignItems: 'flex-end',
                    }}>
                    <Icon key="Microphone_record" onPress={this.startRecord} icon="microphone" toolTip="" color={ThemeManager.style.brandPrimary} disabled={false}/>
                  </Ripple>)}
              </View>
            </>)}
        </View>
      </View>);
    }
    async checkProjectStatus() {
        const locked = await DefaultErrorHandler.getProjectErrors(this.state.projectId, false);
        if (locked !== this.state.disabled) {
            this.setState({ disabled: locked });
        }
    }
    createActivitie() {
        return {
            comment: '',
            ticketId: this.state.ticketId,
            projectId: this.state.projectId,
            clientCreation: new Date(),
        };
    }
}
