"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeProjectTicketLayout = void 0;
var StdApplicationError_1 = require("odatarepos/build/src/entities/StdApplicationError");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../../access/rights/RightsManager");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var ProjectTicketLayoutChanged_1 = require("../../../events/project/ticketlayout/ProjectTicketLayoutChanged");
var TicketApproverRemoved_1 = require("../../../events/tickets/TicketApproverRemoved");
var TicketAssignerRemoved_1 = require("../../../events/tickets/TicketAssignerRemoved");
var TicketCompletionOnRemoved_1 = require("../../../events/tickets/TicketCompletionOnRemoved");
var TicketCraftChanged_1 = require("../../../events/tickets/TicketCraftChanged");
var TicketCustomFieldRemoved_1 = require("../../../events/tickets/TicketCustomFieldRemoved");
var TicketDescriptionChanged_1 = require("../../../events/tickets/TicketDescriptionChanged");
var TicketPlanAffiliationRemoved_1 = require("../../../events/tickets/TicketPlanAffiliationRemoved");
var TicketStatusChanged_1 = require("../../../events/tickets/TicketStatusChanged");
var TicketTagsChanged_1 = require("../../../events/tickets/TicketTagsChanged");
var ClientProjectCommands_1 = require("../../_root/ClientProjectCommands");
var AddProjectTicketLayout_1 = require("./AddProjectTicketLayout");
var ChangeProjectTicketLayout = function (_ClientProjectCommand) {
  function ChangeProjectTicketLayout(data, projectId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ChangeProjectTicketLayout);
    _this = _callSuper(this, ChangeProjectTicketLayout, [data, projectId, token]);
    _this.aggregate = 'project';
    _this.commandName = 'ChangeProjectTicketLayout';
    _this.waitForResult = false;
    _this.hasRemovedFields = (0, _asyncToGenerator2.default)(function* () {
      var currentLayout = yield _this.getLayout();
      var fields = [];
      currentLayout.fields.forEach(function (field) {
        var _this$data$fields;
        var stillIn = (_this$data$fields = _this.data.fields) == null ? void 0 : _this$data$fields.some(function (f) {
          return f.id === field.id;
        });
        if (!stillIn) fields.push(field);
      });
      return fields;
    });
    return _this;
  }
  (0, _inherits2.default)(ChangeProjectTicketLayout, _ClientProjectCommand);
  return (0, _createClass2.default)(ChangeProjectTicketLayout, [{
    key: "getProject",
    value: function () {
      var _getProject = (0, _asyncToGenerator2.default)(function* () {
        if (this._project == null) this._project = yield CommandReadModels_1.CommandReadModels.project.getById(this.data.projectId);
        return this._project;
      });
      function getProject() {
        return _getProject.apply(this, arguments);
      }
      return getProject;
    }()
  }, {
    key: "getLayout",
    value: function () {
      var _getLayout = (0, _asyncToGenerator2.default)(function* () {
        var _this2 = this;
        if (this._layout == null) {
          var _p$ticketLayouts;
          var p = yield this.getProject();
          var l = (_p$ticketLayouts = p.ticketLayouts) == null ? void 0 : _p$ticketLayouts.find(function (a) {
            return a.id === _this2.entityId;
          });
          if (l == null) throw StdApplicationError_1.StdApplicationError.notFound();
          this._layout = l;
        }
        return this._layout;
      });
      function getLayout() {
        return _getLayout.apply(this, arguments);
      }
      return getLayout;
    }()
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        yield (0, _get2.default)((0, _getPrototypeOf2.default)(ChangeProjectTicketLayout.prototype), "checkProjectStatusAndGeneralAccess", this).call(this, this.data.projectId, userId);
        var project = yield this.getProject();
        if (project.projectSubscription == null || project.projectSubscription === 'basic') {
          throw {
            message: 'Min. Pro-Projekt benötigt',
            messageCode: 'notInBasicProject'
          };
        }
        if (yield RightsManager_1.RightsManager.hasWriteRight(this.data.projectId, userId, 'commandChangeProjectTicketLayouts')) {
          return true;
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var _this$data$fields2,
          _this$data,
          _this3 = this;
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var d = {};
        var old = yield this.getLayout();
        var hasChanged = false;
        for (var k in this.data) {
          if (Array.isArray(this.data[k]) && JSON.stringify(this.data[k] !== JSON.stringify(old[k]))) {
            hasChanged = true;
            d[k] = this.data[k];
          } else if (this.data[k] !== old[k]) {
            hasChanged = true;
            d[k] = this.data[k];
          }
        }
        if (!hasChanged) return [];
        var events = [new ProjectTicketLayoutChanged_1.ProjectTicketLayoutChanged(this.entityId, d, this.data.projectId)];
        var fields = (_this$data$fields2 = (_this$data = this.data) == null ? void 0 : _this$data.fields) != null ? _this$data$fields2 : [];
        var hasStatus = fields.some(function (field) {
          return field.systemField === 'status';
        });
        var hasDescription = fields.some(function (field) {
          return field.systemField === 'description';
        });
        var hasPlan = fields.some(function (field) {
          return field.systemField === 'plan';
        });
        var hasCraft = fields.some(function (field) {
          return field.systemField === 'craft';
        });
        var hasTags = fields.some(function (field) {
          return field.systemField === 'tags';
        });
        var hasAssignee = fields.some(function (field) {
          return field.systemField === 'assignee';
        });
        var hasApprover = fields.some(function (field) {
          return field.systemField === 'approver';
        });
        var hasCompletionOn = fields.some(function (field) {
          return field.systemField === 'completionOn';
        });
        var onT = this.data.onTypes ? this.data.onTypes : old.onTypes;
        if (onT && onT.length > 0) {
          var filter = `projectId eq '${this.data.projectId}' and type in ${JSON.stringify(onT)}`;
          var getTickets = yield CommandReadModels_1.CommandReadModels.ticket.get({
            filter: filter
          });
          getTickets.forEach(function (t) {
            var _t$fields;
            (_t$fields = t.fields) == null ? void 0 : _t$fields.forEach(function (f) {
              var stillHas = fields.some(function (f2) {
                return f2.id === f.id;
              });
              if (!stillHas) {
                events.push(new TicketCustomFieldRemoved_1.TicketCustomFieldRemoved(t.id, {
                  fieldId: f.id,
                  name: f.name
                }, _this3.data.projectId));
              }
            });
            var hasCom = t.completionOn != null;
            if (!hasCompletionOn && hasCom) {
              events.push(new TicketCompletionOnRemoved_1.TicketCompletionOnRemoved(t.id, {}, _this3.data.projectId));
            }
            var hasTag = t.tags != null && t.tags.length > 0;
            if (!hasTags && hasTag) {
              events.push(new TicketTagsChanged_1.TicketTagsChanged(t.id, {
                tagIds: []
              }, _this3.data.projectId));
            }
            var hasAss = t.assignedToUserId != null && t.assignedToUserId.length > 0;
            if (!hasAssignee && hasAss) {
              events.push(new TicketAssignerRemoved_1.TicketAssignerRemoved(t.id, {
                userId: t.assignedToUserId
              }, _this3.data.projectId));
            }
            var hasApp = t.approverUserId != null && t.approverUserId.length > 0;
            if (!hasApprover && hasApp) {
              events.push(new TicketApproverRemoved_1.TicketApproverRemoved(t.id, {
                userId: t.approverUserId
              }, _this3.data.projectId));
            }
            var hasD = t.description != null && t.description.length > 0;
            if (!hasDescription && hasD) {
              events.push(new TicketDescriptionChanged_1.TicketDescriptionChanged(t.id, {
                description: ''
              }, _this3.data.projectId));
            }
            var hasP = t.planPositionX != null;
            if (!hasPlan && hasP) {
              events.push(new TicketPlanAffiliationRemoved_1.TicketPlanAffiliationRemoved(t.id, {
                changedBy: userId
              }, _this3.data.projectId));
            }
            var hasCr = t.craft != null;
            if (!hasCraft && hasCr) {
              events.push(new TicketCraftChanged_1.TicketCraftChanged(t.id, {
                craft: null
              }, _this3.data.projectId));
            }
            if (hasStatus && (t.ticketStatus === -1 || t.ticketStatus == null)) {
              events.push(new TicketStatusChanged_1.TicketStatusChanged(t.id, {
                ticketStatus: 0,
                changedBy: userId
              }, _this3.data.projectId));
            } else if (!hasStatus && t.ticketStatus >= 0) {
              events.push(new TicketStatusChanged_1.TicketStatusChanged(t.id, {
                ticketStatus: -1,
                changedBy: userId
              }, _this3.data.projectId));
            }
          });
        }
        if (d.onTypes != null) {
          var data = (yield this.getProject()).ticketLayouts;
          var onTypes = d.onTypes;
          if (data) {
            for (var tl of data) {
              if (tl.onTypes != null && tl.id !== this.entityId) {
                var newType = [];
                for (var t of tl.onTypes) {
                  if (!onTypes.includes(t)) {
                    newType.push(t);
                  }
                }
                if (JSON.stringify(newType) !== JSON.stringify(tl.onTypes)) {
                  events.push(new ProjectTicketLayoutChanged_1.ProjectTicketLayoutChanged(tl.id, {
                    onTypes: newType
                  }, this.data.projectId));
                }
              }
            }
          }
        }
        return events;
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {
        yield AddProjectTicketLayout_1.AddProjectTicketLayout.validateCustoms(this.data.fields);
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(ClientProjectCommands_1.ClientProjectCommands);
exports.ChangeProjectTicketLayout = ChangeProjectTicketLayout;