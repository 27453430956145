"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeWorkingTimeModel = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../../access/rights/RightsManager");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var WorkingTimeModelChanged_1 = require("../../../events/company/workingtimemodel/WorkingTimeModelChanged");
var WorkingTimeModelEntity_1 = require("../../../query/entities/WorkingTimeModelEntity");
var CreateWorkingTimeModel_1 = require("./CreateWorkingTimeModel");
var ChangeWorkingTimeModel = function (_CommandRoot_1$Comman) {
  function ChangeWorkingTimeModel(data, entityId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ChangeWorkingTimeModel);
    _this = _callSuper(this, ChangeWorkingTimeModel, [data, entityId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'ChangeWorkingTimeModel';
    return _this;
  }
  (0, _inherits2.default)(ChangeWorkingTimeModel, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(ChangeWorkingTimeModel, [{
    key: "getWorkingTimeModel",
    value: function () {
      var _getWorkingTimeModel = (0, _asyncToGenerator2.default)(function* () {
        if (this.workingTimeModel == null) this.workingTimeModel = yield CommandReadModels_1.CommandReadModels.workingTimeModel.getById(this.entityId);
      });
      function getWorkingTimeModel() {
        return _getWorkingTimeModel.apply(this, arguments);
      }
      return getWorkingTimeModel;
    }()
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        yield this.getWorkingTimeModel();
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        var company = yield CommandReadModels_1.CommandReadModels.company.getById(this.workingTimeModel.companyId);
        if (yield RightsManager_1.RightsManager.isCompanyAdmin(userId, company.id)) return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return new WorkingTimeModelChanged_1.WorkingTimeModelChanged(this.entityId, Object.assign({}, this.data), this.workingTimeModel.companyId);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        yield this.getWorkingTimeModel();
        if (this.data.sections.length === 1) {
          this.data.sections[0].validFromDay = 1;
          this.data.sections[0].validFromMonth = 1;
        }
        yield CreateWorkingTimeModel_1.CreateWorkingTimeModel.validateWorkingTimeModel(new WorkingTimeModelEntity_1.WorkingTimeModelEntity(Object.assign({
          id: this.entityId,
          companyId: this.workingTimeModel.companyId
        }, this.data)), key);
        if (key == null || key === 'postRecordingInDays') {
          if (this.data.postRecordingInDays > Number.MAX_SAFE_INTEGER) {
            throw {
              message: 'Der Zeitraum für das Nacherfassen ist zu groß',
              messageCode: 'workingTimeModelPostRecordingInDaysTooLarge',
              key: 'postRecordingInDays'
            };
          }
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.ChangeWorkingTimeModel = ChangeWorkingTimeModel;