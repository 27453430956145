var _a;
import { AsyncEvent } from 'ts-events';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
export class CompanyUserInfo {
    static get companyMember() {
        return CompanyUserInfo._companyMember;
    }
    static get me() {
        if (CompanyUserInfo._me != null)
            return CompanyUserInfo._me;
        if (CurrentUser.userId != null && CurrentUser.userId.length > 4) {
            const me = CompanyUserInfo._companyMember.find((c) => c.userId === CurrentUser.userId);
            if (me != null) {
                CompanyUserInfo._me = me;
                return me;
            }
        }
        return null;
    }
    static get companyMemberNames() {
        return CompanyUserInfo._companyMemberNames;
    }
    static get company() {
        return CompanyUserInfo._company;
    }
    static set company(c) {
        if ((c == null || c.id !== CompanyUserInfo._lastId) && CompanyUserInfo.companyListenerKey != null) {
            UpmeshClient.eventDispatcher.detach('Company', CompanyUserInfo.companyListenerKey);
            if (CompanyUserInfo.companyMemberListenerKey != null)
                UpmeshClient.eventDispatcher.detach('CompanyMember', CompanyUserInfo.companyMemberListenerKey);
            if (CompanyUserInfo.companySettingsListenerKey != null)
                UpmeshClient.eventDispatcher.detach('CompanySettings', CompanyUserInfo.companySettingsListenerKey);
        }
        if (c != null && CompanyUserInfo._lastId !== c.id) {
            CompanyUserInfo._lastId = c.id;
            CompanyUserInfo.companyListenerKey = UpmeshClient.eventDispatcher.attach({
                callback: (en) => {
                    const e = en.entities.get(c.id);
                    if (e != null) {
                        CompanyUserInfo._company = e.entity;
                        CompanyUserInfo.companyChanged.post(e.entity);
                    }
                },
                readModelName: 'Company',
                entityId: c.id,
            });
            CompanyUserInfo.companySettingsListenerKey = UpmeshClient.eventDispatcher.attach({
                callback: (en) => {
                    const e = en.entities.get(c.id);
                    if (e != null) {
                        CompanyUserInfo._companySettings = e.entity;
                        I18n.m.defaultMessagesOverride = e.entity.messagesOverride;
                        CompanyUserInfo.companySettingsChanged.post(e.entity);
                    }
                },
                readModelName: 'CompanySettings',
                entityId: c.id,
            });
            CompanyUserInfo.companyMemberListenerKey = UpmeshClient.eventDispatcher.attach({
                callback: (_e) => {
                    CompanyUserInfo.updateCompanyMember().catch((err) => console.debug('cant update company member', err));
                },
                readModelName: 'CompanyMember',
            });
        }
        if (c == null) {
            CompanyUserInfo._companySettings = null;
            I18n.m.defaultMessagesOverride = {};
            CompanyUserInfo._companyMember = [];
            CompanyUserInfo._companyMemberNames = [];
        }
        else {
            CompanyUserInfo.updateCompanyMember().catch((err) => console.debug('cant update company member', err));
            CompanyUserInfo.setCompanySettings().catch((err) => console.debug('cant update company settings', err));
        }
        CompanyUserInfo._company = c;
    }
    static async getUserNameFromCompanyMember(c) {
        try {
            if (c.userId != null) {
                const user = await AuthClient.instance.modals.user.getById(c.userId);
                return user.getFullName();
            }
            return `${c.firstName} ${c.lastName}`;
        }
        catch (err) {
            console.debug('cant update company member', err);
        }
        return null;
    }
    static async updateCompanyMember() {
        try {
            const promises = [];
            const member = await UpmeshClient.instance.modals.companyMember.get({
                filter: `companyId eq '${CompanyUserInfo._lastId}' and deleted ne true`,
            });
            if (member.length > 0 && this._company == null)
                await CompanyUserInfo.setCompanyUserInfo();
            CompanyUserInfo._companyMember = member;
            member.forEach((cm) => promises.push(CompanyUserInfo.getUserNameFromCompanyMember(cm)));
            const usernames = await Promise.all(promises);
            CompanyUserInfo._companyMemberNames = [];
            usernames.forEach((u) => {
                if (u != null)
                    CompanyUserInfo._companyMemberNames.push(u);
            });
            CompanyUserInfo._me = null;
        }
        catch (err) {
            console.debug('cant update company member', err);
        }
    }
    static async setCompanySettings() {
        try {
            CompanyUserInfo._companySettings = await UpmeshClient.instance.modals.companySettings.getById(CompanyUserInfo._lastId);
            I18n.m.defaultMessagesOverride = CompanyUserInfo._companySettings?.messagesOverride;
        }
        catch (err) {
            console.debug('cant update company member', err);
        }
    }
    static get companySettings() {
        return CompanyUserInfo._companySettings;
    }
}
_a = CompanyUserInfo;
CompanyUserInfo._company = null;
CompanyUserInfo._companySettings = null;
CompanyUserInfo._companyMember = [];
CompanyUserInfo._companyMemberNames = [];
CompanyUserInfo._lastId = '';
CompanyUserInfo.companyListenerKey = null;
CompanyUserInfo.companyMemberListenerKey = null;
CompanyUserInfo.companySettingsListenerKey = null;
CompanyUserInfo.companyChanged = new AsyncEvent();
CompanyUserInfo.companySettingsChanged = new AsyncEvent();
CompanyUserInfo.setCompanyUserInfo = async () => {
    try {
        const companyMember = await UpmeshClient.instance.modals.companyMember.get({
            filter: `userId eq '${CurrentUser.userId}' and deleted ne true`,
            top: 1,
        });
        if (companyMember.length > 0 && companyMember[0].role !== 'inactive') {
            CompanyUserInfo.company = await UpmeshClient.instance.modals.company.getById(companyMember[0].companyId);
        }
        else {
            CompanyUserInfo.company = null;
        }
    }
    catch (err) {
        console.debug('cant get company informations', err);
        CompanyUserInfo.company = null;
    }
};
