import { StringUtils } from 'cqrs-core/src/core/StringUtils';
import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { ChipDialogForm } from 'materialTheme/src/theme/components/chips/ChipDialogForm';
import { DialogUp } from 'materialTheme/src/theme/components/DialogUp';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MenuRaw } from 'materialTheme/src/theme/components/MenuRaw';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { Measurement } from 'materialTheme/src/theme/components/utils/Measurement';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { AddTicketWatcher } from 'upmesh-core/src/client/commands/tickets/AddTicketWatcher';
import { RemoveTicketWatcher } from 'upmesh-core/src/client/commands/tickets/RemoveTicketWatcher';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { DefaultErrorHandler } from '../../DefaultErrorHandler';
import { CurrentProject } from '../../project/CurrentProject';
import { ProjectMemberDetailsPU } from '../../team/ProjectMemberDetailsPU';
export function WatcherIcon(props) {
    const teamMemberList = () => {
        const chips = [];
        const team = CurrentProject.instance.getCurrentProjectTeam();
        for (let i = 0; i < team.length; i += 1) {
            chips.push({
                title: `${team[i].user.firstname} ${team[i].user.lastname}`,
                id: team[i].user.id,
                thumbnail: { thumbnail: <UserImage size={24} user={team[i].user}/>, width: 24, rounded: true },
                selected: props.ticket.watchers
                    ? props.ticket.watchers.findIndex((a) => team[i].user.id === a.userId) >= 0
                    : false,
            });
        }
        return chips;
    };
    const [watchers, setWatchers] = useState(props.ticket.watchers ? props.ticket.watchers.length : 0);
    const [imWatching, setImWatching] = useState(props.ticket.watchers ? props.ticket.watchers.findIndex((a) => a.userId === CurrentUser.userId) >= 0 : false);
    const [disabled, setDisabled] = useState(true);
    const [autocompleteList, setAutocompleteList] = useState(teamMemberList());
    const checkDisabled = async () => {
        const { ticket } = props;
        const cAdd = new AddTicketWatcher({}, ticket.id);
        let canIAdd = false;
        try {
            canIAdd = await cAdd.canI();
        }
        catch (e) {
            canIAdd = false;
        }
        const allowedToAdd = !!(typeof canIAdd === 'boolean' && canIAdd);
        if (disabled !== !allowedToAdd)
            setDisabled(!allowedToAdd);
    };
    useEffect(() => {
        setWatchers(props.ticket.watchers ? props.ticket.watchers.length : 0);
        const me = props.ticket.watchers
            ? props.ticket.watchers.findIndex((a) => a.userId === CurrentUser.userId) >= 0
            : false;
        if (me !== imWatching)
            setImWatching(me);
        const team = teamMemberList();
        if (StringUtils.deepStringify(team) !== StringUtils.deepStringify(autocompleteList))
            setAutocompleteList(team);
        checkDisabled().catch((err) => console.warn(err));
    }, [props.ticket.watchers]);
    const saveWatcher = async (allIitems) => {
        DialogUp.instance?.close();
        const items = [];
        allIitems.forEach((a) => {
            if (a.selected)
                items.push(a);
        });
        const { ticket } = props;
        if (ticket.watchers == null)
            ticket.watchers = [];
        for (let i = 0; i < items.length; i += 1) {
            if (ticket.watchers.findIndex((j) => j.userId === items[i].id) === -1) {
                try {
                    const c = new AddTicketWatcher({ userId: items[i].id }, ticket.id);
                    await c.execute();
                }
                catch (err) {
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                }
            }
        }
        for (const watcher of ticket.watchers) {
            if (items.findIndex((j) => j.id === watcher.userId) === -1) {
                try {
                    const c = new RemoveTicketWatcher({ userId: watcher.userId }, ticket.id);
                    await c.execute();
                }
                catch (err) {
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                }
            }
        }
    };
    const onPress = async (e) => {
        let x = 0;
        let y = 0;
        try {
            if (e.nativeEvent?.pageX != null && e.nativeEvent.pageY) {
                x = e.nativeEvent.pageX;
                y = e.nativeEvent.pageY;
            }
            else {
                const s = await Measurement.measure(e.currentTarget);
                x = s.pageX;
                y = s.pageY;
            }
        }
        catch (err) {
            console.warn(err);
        }
        const client = {
            height: 0,
            width: 300,
            x,
            y,
        };
        const items = [
            {
                thumbnail: { thumbnail: <Icon icon={imWatching ? 'eye-off-outline' : 'eye-outline'} toolTip=""/>, width: 40 },
                cutLine: true,
                title: imWatching ? I18n.m.getMessage('watcherIamWatching') : I18n.m.getMessage('watcherIamNotWatching'),
                onPress: () => {
                    MenuRaw.instance?.close();
                    if (!imWatching) {
                        const c = new AddTicketWatcher({ userId: CurrentUser.entity.id }, props.ticket.id);
                        c.execute().catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
                    }
                    else {
                        const c = new RemoveTicketWatcher({ userId: CurrentUser.entity.id }, props.ticket.id);
                        c.execute().catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
                    }
                },
            },
        ];
        const w = [];
        autocompleteList.forEach((a) => {
            if (a.selected) {
                w.push({
                    title: a.title,
                    thumbnail: a.thumbnail,
                    onPress: a.id == null
                        ? undefined
                        : (e) => {
                            MenuRaw.instance?.close();
                            const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
                            UpmeshClient.instance.modals.projectUser
                                .get({
                                filter: `projectId eq '${props.ticket.projectId}' and userId eq '${a.id}'`,
                            })
                                .then((projectUser) => {
                                const projectUserId = projectUser.length === 0 ? `creator_${a.id}` : projectUser[0].id;
                                DialogUp.instance?.open({
                                    openPosition,
                                    content: (<ProjectMemberDetailsPU projectId={props.ticket.projectId} data={{ projectUserId, userId: a.id }} closeFunction={DialogUp.instance?.close}/>),
                                });
                            })
                                .catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
                        },
                });
            }
        });
        if (w.length > 0) {
            w[w.length - 1].cutLine = true;
            items.push({
                height: 26,
                title: '',
                secondTextLine: I18n.m.getMessage('watcherMemberAreWatching'),
            }, ...w);
        }
        if (!disabled)
            items.push({
                thumbnail: { thumbnail: <Icon icon="pencil-outline" toolTip=""/>, width: 40 },
                title: I18n.m.getMessage('watcherMemberEdit'),
                onPress: (e) => {
                    MenuRaw.instance?.close();
                    const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
                    DialogUp.instance?.open({
                        content: (<ChipDialogForm key="ChipDialogForm" title={I18n.m.getMessage('ticketsDetailsObserver')} onCancel={() => DialogUp.instance?.close()} multiselect onSave={(items) => {
                                saveWatcher(items).catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
                            }} items={autocompleteList} canAddNewChips={false} canAddNewChipsViaSearchbar={false}/>),
                        openPosition,
                        contentPadding: false,
                        fullscreenResponsive: true,
                        showCloseButton: false,
                        showCloseIcon: true,
                    });
                },
            });
        MenuRaw.instance?.open({
            client,
            items,
        });
    };
    return (<Ripple onPress={onPress} style={{ position: 'relative', overflow: 'visible', flexDirection: 'row', paddingHorizontal: 4 }}>
      <Icon outerSize={26} icon="eye-outline" color={imWatching ? ThemeManager.style.brandPrimary : ThemeManager.style.headerButtonColor} toolTip={I18n.m.getMessage('watcher')}/>
      <MaterialText color={imWatching ? ThemeManager.style.brandPrimary : ThemeManager.style.headerButtonColor} centeredBox>
        {watchers}
      </MaterialText>
    </Ripple>);
}
