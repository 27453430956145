"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeCompanyTicketType = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../../access/rights/RightsManager");
var CompanyTicketTypeChanged_1 = require("../../../../server/events/companies/tickettypes/CompanyTicketTypeChanged");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var ChangeCompanyTicketType = function (_CommandRoot_1$Comman) {
  function ChangeCompanyTicketType(data, companyId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ChangeCompanyTicketType);
    _this = _callSuper(this, ChangeCompanyTicketType, [data, companyId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'ChangeCompanyTicketType';
    return _this;
  }
  (0, _inherits2.default)(ChangeCompanyTicketType, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(ChangeCompanyTicketType, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        var companies = ReadModels_1.ReadModels.get('Company');
        var company = yield companies.getById(this.entityId);
        if (yield RightsManager_1.RightsManager.isCompanyAdmin(userId, company.id)) return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var data = Object.assign({}, this.data);
        return new CompanyTicketTypeChanged_1.CompanyTicketTypeChanged(this.entityId, data);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        var companySettings = yield CommandReadModels_1.CommandReadModels.companySettings.getById(this.entityId);
        if (key == null || key === 'newValue') {
          var _companySettings$tick;
          if (this.data.newValue.length <= 0) {
            throw {
              key: 'newValue',
              messageCode: 'required',
              message: 'Tickettyp wird benötigt'
            };
          }
          if ((_companySettings$tick = companySettings.ticketTypes) != null && _companySettings$tick.includes(this.data.newValue)) {
            throw {
              message: 'Der Tickettyp existiert bereits',
              messageCode: 'companyTicketTypeAlreadyExists'
            };
          }
        }
        if (key == null || key === 'oldValue') {
          var _companySettings$tick2;
          if (!((_companySettings$tick2 = companySettings.ticketTypes) != null && _companySettings$tick2.includes(this.data.oldValue))) {
            throw {
              message: 'Der Tickettyp existiert nicht',
              messageCode: 'companyTicketTypeNotExists'
            };
          }
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.ChangeCompanyTicketType = ChangeCompanyTicketType;