import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { OpenableChip } from 'materialTheme/src/theme/components/chips/OpenableChip';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { DateInputFormFilled } from 'materialTheme/src/theme/components/forminput/DateInputFormFilled';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { RouterControl } from 'materialTheme/src/theme/routing/RouterControl';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { PureComponent } from 'react';
import { BackHandler, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { CreateTimeTrackings, } from 'upmesh-core/src/client/commands/companies/timetracking/CreateTimeTrackings';
import { TimeTrackingEntity } from 'upmesh-core/src/client/query/entities/TimeTrackingEntity';
import { CombinedExtraPayAndTimeTrackingFilter } from 'upmesh-core/src/client/query/filter/CombinedExtraPayAndTimeTrackingFilter';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { Collaborator } from '../companies/Collaborator';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
export class AddTimeTrackDialog extends PureComponent {
    constructor(props) {
        let isToday = true;
        let propsDate = new Date();
        if (props.selectedDate != null) {
            isToday = false;
            propsDate = new Date(props.selectedDate);
            const todayDate = new Date();
            if (propsDate.getDate() === todayDate.getDate() &&
                propsDate.getMonth() === todayDate.getMonth() &&
                propsDate.getFullYear() === todayDate.getFullYear()) {
                isToday = true;
            }
        }
        super(props);
        this.init = async () => {
            const projects = await UpmeshClient.instance.modals.project.get({ filter: `archived ne true and deleted ne true` }, CurrentUser.userId);
            const projectPickerList = [];
            projects.forEach((pr, index) => projectPickerList.push({ title: pr.title, onPressChipData: { type: 'project', id: pr.id, index } }));
            const tasks = await UpmeshClient.instance.modals.task.get({ filter: `deleted ne true and active ne false and productive eq true`, orderby: 'mark' }, CurrentUser.userId);
            const taskList = [];
            tasks.forEach((ta) => taskList.push({ title: `${ta.mark} - ${ta.description}`, data: ta.id }));
            let selectedTask = 0;
            const collaborators = await Collaborator.getCollaboratorList('lastTimeTrackingCollaborators', 'canTimeTrackFor', this.props.memberIds);
            const me = await AuthClient.instance.modals.user.getById(CurrentUser.userId);
            const companyMemberMe = await UpmeshClient.instance.modals.companyMember.get({
                filter: `userId eq '${me.id}'`,
                top: 1,
            });
            const costCenters = await UpmeshClient.instance.modals.costCenter.get({
                filter: `companyId eq '${companyMemberMe[0].companyId}' and deleted ne true and active ne false`,
            });
            const costUnitList = [];
            const costCenterList = [];
            costCenters.forEach((cc) => {
                if (cc.costType === 'costUnit')
                    costUnitList.push({
                        title: `${cc.mark} - ${cc.description}`,
                        onPressChipData: { id: cc.id, type: 'costUnit', index: costUnitList.length },
                    });
                else
                    costCenterList.push({
                        title: `${cc.mark} - ${cc.description}`,
                        onPressChipData: { id: cc.id, type: 'costCenter', index: costCenterList.length },
                    });
            });
            let selectedProjectAndCost = {
                type: '',
                projectId: '',
                costId: '',
            };
            let errorMessage;
            if (projectPickerList.length > 0) {
                const checked = await this.costCenterCheckForError(projectPickerList[0].onPressChipData);
                selectedProjectAndCost = checked.projectAndCost;
                errorMessage = checked.errorMessage;
            }
            else if (costCenterList.length > 0) {
                const checked = await this.costCenterCheckForError(costCenterList[0].onPressChipData);
                selectedProjectAndCost = checked.projectAndCost;
                errorMessage = checked.errorMessage;
            }
            else if (costUnitList.length > 0) {
                const checked = await this.costCenterCheckForError(costUnitList[0].onPressChipData);
                selectedProjectAndCost = checked.projectAndCost;
                errorMessage = checked.errorMessage;
            }
            else {
                errorMessage = I18n.m.getMessage('timeTrackingNoProjectOrCostCenter');
            }
            const segmentedList = [];
            if (projectPickerList.length > 0)
                segmentedList.push({ chips: projectPickerList, title: I18n.m.getMessage('projects'), type: 'project' });
            if (costCenterList.length > 0)
                segmentedList.push({ chips: costCenterList, title: I18n.m.getMessage('costCenters'), type: 'costCenter' });
            if (costUnitList.length > 0)
                segmentedList.push({ chips: costUnitList, title: I18n.m.getMessage('costUnits'), type: 'costUnit' });
            try {
                const getLastTimeTrackingTask = await SimpleStorage.get('lastTimeTrackingTask');
                if (getLastTimeTrackingTask != null) {
                    const selectedTaskId = getLastTimeTrackingTask;
                    const taskIndex = taskList.findIndex((t) => t.data === selectedTaskId);
                    if (taskIndex > 0)
                        selectedTask = taskIndex;
                }
            }
            catch (e) {
                console.debug('cant get lastTimeTrackingTask', e);
            }
            let selectedProject = 0;
            let selectedSegment = 0;
            try {
                const getLastTimeTrackingCostCenter = await SimpleStorage.get('lastTimeTrackingCostCenter');
                if (getLastTimeTrackingCostCenter != null) {
                    selectedProjectAndCost = JSON.parse(getLastTimeTrackingCostCenter);
                    selectedSegment = segmentedList.findIndex((c) => c.type === selectedProjectAndCost.type);
                    const list = segmentedList[selectedSegment];
                    const val = list.type === 'project' ? 'projectId' : 'costId';
                    const chip = list.chips.find((p) => p.onPressChipData.id === selectedProjectAndCost[val]);
                    if (chip != null) {
                        selectedProject = chip.onPressChipData.index;
                        const checked = await this.costCenterCheckForError(chip.onPressChipData);
                        selectedProjectAndCost = checked.projectAndCost;
                        errorMessage = checked.errorMessage;
                    }
                }
            }
            catch (e) {
                console.debug('cant get lastTimeTrackingCostCenter', e);
            }
            const startTimeQuickSelection = [
                { name: I18n.m.dateCurrent.getLocalTimeString(new Date(1970, 0, 1, 6, 0, 0, 0)), time: { hour: 6, minute: 0 } },
                { name: I18n.m.dateCurrent.getLocalTimeString(new Date(1970, 0, 1, 7, 0, 0, 0)), time: { hour: 7, minute: 0 } },
                { name: I18n.m.dateCurrent.getLocalTimeString(new Date(1970, 0, 1, 8, 0, 0, 0)), time: { hour: 8, minute: 0 } },
                { name: I18n.m.dateCurrent.getLocalTimeString(new Date(1970, 0, 1, 9, 0, 0, 0)), time: { hour: 9, minute: 0 } },
            ];
            const endTimeQuickSelection = [
                { name: I18n.m.dateCurrent.getLocalTimeString(new Date(1970, 0, 1, 16, 0, 0, 0)), time: { hour: 16, minute: 0 } },
                { name: I18n.m.dateCurrent.getLocalTimeString(new Date(1970, 0, 1, 17, 0, 0, 0)), time: { hour: 17, minute: 0 } },
                { name: I18n.m.dateCurrent.getLocalTimeString(new Date(1970, 0, 1, 18, 0, 0, 0)), time: { hour: 18, minute: 0 } },
                { name: I18n.m.dateCurrent.getLocalTimeString(new Date(1970, 0, 1, 19, 0, 0, 0)), time: { hour: 19, minute: 0 } },
            ];
            const pauseTimeQuickSelection = [
                { name: '0:15', time: { hour: 0, minute: 15 } },
                { name: '0:30', time: { hour: 0, minute: 30 } },
                { name: '0:45', time: { hour: 0, minute: 45 } },
                { name: '1:00', time: { hour: 1, minute: 0 } },
            ];
            const today = new Date();
            const dateQuickSelection = [
                { name: I18n.m.getMessage('today'), days: today.getTime() },
                {
                    name: I18n.m.getMessage('yesterday'),
                    days: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 0, 0, 0).getTime(),
                },
                {
                    name: I18n.m.getMessage('dayBeforeYesterday'),
                    days: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 2, 0, 0, 0).getTime(),
                },
            ];
            if (CompanyUserInfo.me != null && CompanyUserInfo.me.workingTimeModelId != null) {
                try {
                    const workingTimeModel = await UpmeshClient.instance.modals.workingTimeModel.getById(CompanyUserInfo.me.workingTimeModelId);
                    const d = workingTimeModel.getCorrectDaytime(new Date());
                    if (d != null) {
                        if (d.start != null) {
                            const find = startTimeQuickSelection.findIndex((e) => e.time.hour === d.start.hours && e.time.minute === d.start.minutes);
                            if (find === -1) {
                                startTimeQuickSelection.unshift({
                                    name: I18n.m.dateCurrent.getLocalTimeString(new Date(1970, 0, 1, d.start.hours, d.start.minutes, 0, 0)),
                                    time: { hour: d.start.hours, minute: d.start.minutes },
                                });
                            }
                        }
                        if (d.stop != null) {
                            const find = endTimeQuickSelection.findIndex((e) => e.time.hour === d.stop.hours && e.time.minute === d.stop.minutes);
                            if (find === -1) {
                                endTimeQuickSelection.unshift({
                                    name: I18n.m.dateCurrent.getLocalTimeString(new Date(1970, 0, 1, d.stop.hours, d.stop.minutes, 0, 0)),
                                    time: { hour: d.stop.hours, minute: d.stop.minutes },
                                });
                            }
                        }
                        if (d.pause != null) {
                            const find = pauseTimeQuickSelection.findIndex((e) => e.time.hour === d.pause.hours && e.time.minute === d.pause.minutes);
                            if (find === -1) {
                                pauseTimeQuickSelection.unshift({
                                    name: `${d.pause.hours}:${d.stop.minutes > 9 ? d.pause.minutes : `0${d.pause.minutes}`}`,
                                    time: { hour: d.pause.hours, minute: d.pause.minutes },
                                });
                            }
                        }
                    }
                }
                catch (e) {
                    console.debug('cant get working Time Model for user', e);
                }
            }
            this.setState({
                segmentedList,
                taskList,
                selectedTask,
                collaboratorList: collaborators.collaboratorList,
                selectedCollaborators: collaborators.selectedCollaborators,
                collaboratorGroups: collaborators.collaboratorGroups,
                isLoading: false,
                selectedListIndex: selectedProject,
                selectedSegment,
                selectedProjectAndCost,
                projectAndCostHelperText: errorMessage,
                startTimeQuickSelection,
                endTimeQuickSelection,
                pauseTimeQuickSelection,
                dateQuickSelection,
            });
        };
        this.onSave = () => {
            const { end, start, date, pause, selectedTask, taskList, notes, selectedCollaborators, selectedProjectAndCost } = this.state;
            if (start == null || date == null)
                return;
            this.setState({ isLoading: true }, async () => {
                try {
                    const companyMember = (await UpmeshClient.instance.modals.companyMember.get({
                        filter: `userId eq ${CurrentUser.userId} and deleted ne true`,
                        top: 1,
                    }))[0];
                    const memberIds = [];
                    selectedCollaborators.forEach((u) => {
                        if (u.id != null)
                            memberIds.push(u.id);
                    });
                    const starts = new Date(date.getFullYear(), date.getMonth(), date.getDate(), start.getHours(), start.getMinutes());
                    const ends = end == null
                        ? undefined
                        : new Date(date.getFullYear(), date.getMonth(), date.getDate(), end.getHours(), end.getMinutes());
                    if (ends != null && ends.getTime() < starts.getTime())
                        ends.setDate(ends.getDate() + 1);
                    const data = {
                        memberIds,
                        ends,
                        starts,
                        pause: pause != null ? { hours: pause.getHours(), minutes: pause.getMinutes() } : undefined,
                        taskId: taskList.length > 0 ? taskList[selectedTask].data : undefined,
                        companyId: companyMember.companyId,
                        note: notes,
                        projectId: selectedProjectAndCost.projectId,
                        costCenterId: selectedProjectAndCost.costId,
                    };
                    const create = new CreateTimeTrackings(data);
                    await create.execute(ClientStore.commandStore);
                    Dialog.instance?.close();
                }
                catch (e) {
                    console.debug('errors', e);
                    this.setState({ isLoading: false }, () => {
                        DefaultErrorHandler.showDefaultErrorAlert(e);
                    });
                }
            });
        };
        this.checkAndSaveTimeTracking = async (entry, save) => {
            const reallyColliding = await TimeTrackingEntity.isColliding(entry);
            if (reallyColliding.length > 0) {
                Routing.instance.alert.post({
                    text: I18n.m.getMessage('timeTrackingChangeFoundCollision'),
                    buttons: [
                        <ContainedButton backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} key="cancel" title={I18n.m.getMessage('cancel')} onPress={() => {
                                Alert.instance?.close();
                            }}/>,
                        <ContainedButton backgroundColor="transparent" key="show_collision_tickets" textColor={ThemeManager.style.brandPrimary} title={I18n.m.getMessage('timeTrackingChangeShowCollision')} onPress={() => {
                                this.showColliding(reallyColliding);
                            }}/>,
                        <ContainedButton key="not_show_collision_tickets" title={I18n.m.getMessage('timeTrackingChangeSaveAnyway')} onPress={() => {
                                Alert.instance?.close();
                                save();
                            }}/>,
                    ],
                });
            }
            else {
                save();
            }
        };
        this.check = async () => {
            const { date, end, start, selectedCollaborators } = this.state;
            if (start == null || date == null)
                return;
            const reallyColliding = [];
            for (let i = 0; i < selectedCollaborators.length; i += 1) {
                const member = selectedCollaborators[i];
                if (member.id != null) {
                    const current = new TimeTrackingEntity();
                    current.starts = new Date(date.getFullYear(), date.getMonth(), date.getDate(), start.getHours(), start.getMinutes());
                    current.ends =
                        end == null
                            ? undefined
                            : new Date(date.getFullYear(), date.getMonth(), date.getDate(), end.getHours(), end.getMinutes());
                    current.memberId = member.id;
                    const currentFound = await TimeTrackingEntity.isColliding(current);
                    if (currentFound.length > 0) {
                        reallyColliding.push(...currentFound);
                    }
                }
            }
            if (reallyColliding != null && reallyColliding.length > 0) {
                Routing.instance.alert.post({
                    text: I18n.m.getMessage('timeTrackingChangeFoundCollision'),
                    buttons: [
                        <ContainedButton key="cancel" backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} title={I18n.m.getMessage('cancel')} onPress={() => {
                                Alert.instance?.close();
                            }}/>,
                        <ContainedButton backgroundColor="transparent" key="show_collision_tickets" textColor={ThemeManager.style.brandPrimary} title={I18n.m.getMessage('timeTrackingChangeShowCollision')} onPress={() => {
                                this.showColliding(reallyColliding);
                            }}/>,
                        <ContainedButton key="not_show_collision_tickets" title={I18n.m.getMessage('timeTrackingChangeSaveAnyway')} onPress={() => {
                                Alert.instance?.close();
                                this.onSave();
                            }}/>,
                    ],
                });
            }
            else {
                this.onSave();
            }
        };
        this.showColliding = (collidingEntries) => {
            Alert.instance?.close(async () => {
                let start = new Date();
                let end = new Date(0);
                collidingEntries.forEach((entry) => {
                    if (entry.starts.getTime() < start.getTime()) {
                        start = entry.starts;
                    }
                    if (entry.ends != null && entry.ends.getTime() > end.getTime()) {
                        end = entry.ends;
                    }
                });
                if (end.getTime() <= 0)
                    end.setTime(start.getTime());
                const userIds = [];
                for (const e of collidingEntries) {
                    const m = e.userId != null ? e.userId : await e.getMemberId();
                    if (m != null && m.length > 0 && !userIds.includes(m))
                        userIds.push(m);
                }
                const timeTrackingFilter = new CombinedExtraPayAndTimeTrackingFilter();
                timeTrackingFilter.date = start.getTime();
                timeTrackingFilter.dateTo = end.getTime();
                timeTrackingFilter.userIds = userIds;
                const { currentUrl } = RouterControl.instance;
                if (!currentUrl.pathname.startsWith('/timeTracking'))
                    Routing.instance.goTo(`/timeTracking?ft=${JSON.stringify(timeTrackingFilter)}`);
                else
                    Routing.instance.changeQueryParameter({ ft: JSON.stringify(timeTrackingFilter) }, true);
                Dialog.instance?.close();
            });
        };
        this.costCenterCheckForError = async (selected) => {
            let errorMessage;
            let projectAndCost = {
                type: selected.type,
                projectId: selected.type === 'project' ? selected.id : undefined,
                costId: selected.type !== 'project' ? selected.id : '',
            };
            if (selected.type === 'project') {
                const costCenters = await UpmeshClient.instance.modals.costCenter.get({
                    filter: `deleted ne true`,
                });
                const center = costCenters.find((c) => c.projectIds != null && c.projectIds.indexOf(selected.id) > -1);
                projectAndCost = {
                    projectId: projectAndCost.projectId,
                    costId: center != null ? center.id : '',
                    type: projectAndCost.type,
                };
                if (projectAndCost.costId === '') {
                    errorMessage = I18n.m.getMessage('costCenterProjectWithoutCenterError');
                }
            }
            else if (selected.type === 'costUnit') {
                const costCenters = await UpmeshClient.instance.modals.costCenter.get({
                    filter: `deleted ne true and id eq '${selected.id}'`,
                });
                const { projectIds } = costCenters[0];
                if (projectIds == null || projectIds.length === 0) {
                    errorMessage = I18n.m.getMessage('costCenterNoProjectError');
                }
                else if (projectIds.length === 1) {
                    projectAndCost = {
                        projectId: projectIds[0] != null ? projectIds[0] : undefined,
                        costId: projectAndCost.costId,
                        type: projectAndCost.type,
                    };
                }
                else {
                    const getProjects = await UpmeshClient.instance.modals.project.get({
                        filter: projectIds.length === 1
                            ? `id eq '${projectIds[0]}' and deleted ne true`
                            : `(id in ${JSON.stringify(projectIds)}) and deleted ne true`,
                    });
                    if (getProjects.length === 0) {
                        errorMessage = I18n.m.getMessage('costCenterNoProjectError');
                    }
                    else if (getProjects.length > 1) {
                        errorMessage = I18n.m.getMessage('costCenterMultipleProjectsError');
                    }
                    else {
                        projectAndCost = {
                            projectId: projectIds[0] != null ? projectIds[0] : undefined,
                            costId: projectAndCost.costId,
                            type: projectAndCost.type,
                        };
                    }
                }
            }
            return { errorMessage, projectAndCost };
        };
        this.onPressProjectChip = async (newSelected) => {
            const { segmentedList } = this.state;
            const checked = await this.costCenterCheckForError(newSelected);
            if (checked.errorMessage == null || checked.errorMessage.length === 0) {
                SimpleStorage.set('lastTimeTrackingCostCenter', JSON.stringify(checked.projectAndCost));
            }
            this.setState({
                selectedProjectAndCost: checked.projectAndCost,
                projectAndCostHelperText: checked.errorMessage,
                selectedListIndex: newSelected.index,
                selectedSegment: segmentedList.findIndex((c) => c.type === newSelected.type),
            });
        };
        this.state = {
            segmentedList: [],
            taskList: [],
            collaboratorList: [],
            collaboratorGroups: [],
            start: isToday
                ? new Date()
                : props.start
                    ? props.start
                    : new Date(propsDate.getFullYear(), propsDate.getMonth(), propsDate.getDate(), 8),
            end: isToday
                ? undefined
                : props.end
                    ? props.end
                    : new Date(propsDate.getFullYear(), propsDate.getMonth(), propsDate.getDate(), 17),
            selectedTask: 0,
            selectedListIndex: 0,
            pause: props.pause,
            selectedSegment: 0,
            selectedCollaborators: [],
            date: props.selectedDate != null ? props.selectedDate : new Date(),
            isLoading: true,
            selectedProjectAndCost: { type: '', projectId: '', costId: '' },
            startTimeQuickSelection: [],
            endTimeQuickSelection: [],
            pauseTimeQuickSelection: [],
            dateQuickSelection: [],
        };
    }
    componentDidMount() {
        this.backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
            Dialog.instance?.close();
            return true;
        });
        this.init().catch((err) => console.debug('cant load data', err));
    }
    componentWillUnmount() {
        if (this.backHandler)
            this.backHandler.remove();
    }
    render() {
        const { taskList, selectedListIndex, selectedTask, start, selectedCollaborators, collaboratorList, date, end, pause, notes, isLoading, collaboratorGroups, projectAndCostHelperText, selectedSegment, segmentedList, startTimeQuickSelection, endTimeQuickSelection, pauseTimeQuickSelection, dateQuickSelection, } = this.state;
        const { hideTimes, title } = this.props;
        let isToday = false;
        const todayDate = new Date();
        if (date != null &&
            date.getDate() === todayDate.getDate() &&
            date.getMonth() === todayDate.getMonth() &&
            date.getFullYear() === todayDate.getFullYear()) {
            isToday = true;
        }
        return [
            <DialogTitle key="title">{title || I18n.m.getMessage('timeTrackingAddTimeTrack')}</DialogTitle>,
            <DialogContent key="content">
        <View style={{ padding: 0 }}>
          {segmentedList.length === 0 ? null : (<OpenableChip formInputLabel={`${I18n.m.getMessage('costCenter')}/${I18n.m.getMessage('costUnit')}/${I18n.m.getMessage('project')}`} dialogTitle={`${I18n.m.getMessage('costCenter')}/${I18n.m.getMessage('costUnit')}/${I18n.m.getMessage('project')}`} selected={selectedListIndex} selectedSegment={selectedSegment} onPressChip={this.onPressProjectChip} looksLikeFI formInputHelperText={projectAndCostHelperText} formInputError={projectAndCostHelperText != null} segmentedChipsList={segmentedList}/>)}
          {collaboratorList.length === 1 ? null : (<ChipGroup key={`${JSON.stringify(selectedCollaborators)}_${JSON.stringify(collaboratorList)}`} chips={selectedCollaborators} availableChips={collaboratorList} label={I18n.m.getMessage('collaborator')} sortFormByGroup chipGroups={collaboratorGroups} onChanged={(chips) => {
                        SimpleStorage.set('lastTimeTrackingCollaborators', JSON.stringify(chips));
                        this.setState({ selectedCollaborators: chips }, () => this.forceUpdate());
                    }}/>)}
          {taskList.length === 0 ? null : (<OpenableChip chipsList={taskList} dialogTitle={I18n.m.getMessage('journalEmployeesJob')} formInputLabel={I18n.m.getMessage('journalEmployeesJob')} selected={selectedTask} onPressChip={(newIndex) => {
                        const { taskList } = this.state;
                        SimpleStorage.set('lastTimeTrackingTask', taskList[newIndex].data);
                        this.setState({ selectedTask: newIndex });
                    }} looksLikeFI/>)}
          {hideTimes ? null : (<View style={{ flexDirection: 'row', width: '100%' }}>
              <View style={{ flex: 1, paddingRight: 8 }}>
                <DateInputFormFilled selectDate={false} clearButton={false} selectTime labelText={I18n.m.getMessage('workingTimeModelsStart')} value={start} timeQuickSelections={startTimeQuickSelection} helperText={start == null ? I18n.m.getMessage('required') : undefined} error={start == null} onChange={(start) => {
                        this.setState({ start });
                    }}/>
              </View>
              <View style={{ flex: 1, paddingLeft: 8 }}>
                <DateInputFormFilled selectDate={false} clearButton={false} selectTime labelText={I18n.m.getMessage('workingTimeModelsStop')} startDate={end} timeQuickSelections={endTimeQuickSelection} value={end} helperText={!isToday && end == null ? I18n.m.getMessage('timeTrackingEndTimeNeeded') : undefined} error={!isToday && end == null} onChange={(end) => {
                        this.setState({ end });
                    }}/>
              </View>
            </View>)}
          {hideTimes ? null : (<View style={{ flexDirection: 'row', width: '100%' }}>
              <View style={{ flex: 1, paddingRight: 8 }}>
                <DateInputFormFilled clearButton={false} labelText={I18n.m.getMessage('date')} value={date} quickSelections={dateQuickSelection} helperText={date == null ? I18n.m.getMessage('required') : undefined} error={date == null} onChange={(date) => {
                        this.setState({ date });
                    }}/>
              </View>
              <View style={{ flex: 1, paddingLeft: 8 }}>
                <DateInputFormFilled selectDate={false} clearButton={false} value={pause} selectTime labelText={I18n.m.getMessage('workingTimeModelsPause')} startDate={pause} timeFormat="24" timeQuickSelections={pauseTimeQuickSelection} onChange={(pause) => {
                        this.setState({ pause });
                    }}/>
              </View>
            </View>)}

          <FormInputFilled labelText={I18n.m.getMessage('timeTrackingNotes')} value={notes} onChange={(notes) => this.setState({ notes })}/>
        </View>
      </DialogContent>,
            <DialogActions key="actions" onBottom>
        <ContainedButton title={I18n.m.getMessage('cancel')} textColor={ThemeManager.style.brandPrimary} backgroundColor="transparent" onPress={() => Dialog.instance?.close()} disabled={isLoading}/>
        <ContainedButton title={I18n.m.getMessage('timeTrackingCapture')} onPress={this.check} loading={isLoading} disabled={projectAndCostHelperText != null ||
                    selectedCollaborators.length === 0 ||
                    (!isToday && end == null) ||
                    start == null ||
                    date == null}/>
      </DialogActions>,
        ];
    }
}
