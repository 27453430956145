import * as B64 from 'base-64';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { ZoomTile } from 'materialTheme/src/theme/components/zoom/ZoomTile';
import { ZoomTileHelper } from 'materialTheme/src/theme/components/zoom/ZoomTileHelper';
import UserContext from 'materialTheme/src/userContext';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { AddressEntity } from 'upmesh-auth-core/src/client/query/entities/AddressEntity';
import { ReCreateTilesForPlanVersion } from 'upmesh-core/src/client/commands/plans/ReCreateTilesForPlanVersion';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
export class PlanZoomTileMapper extends PureComponent {
    constructor(props, context) {
        super(props, context);
        this.mounted = false;
        this.zoomToTries = 0;
        this.getTileInfo = async () => {
            const { type, url, planVersion, plan } = this.props;
            const initialPlanPosition = this.getInitialPosition();
            if (type === 'map') {
                const info = {
                    dx: 0,
                    dy: 0,
                    height: 0,
                    imageHeight: 0,
                    imageWidth: 0,
                    maxLevel: 19,
                    orgImageHeight: 0,
                    orgImageWidth: 0,
                    width: 0,
                };
                this.setState({ initialPlanPosition, info, url: '', canRecreate: false, loaded: true });
            }
            else {
                this.updateCanRecreate(initialPlanPosition);
                if (this.tileInfoTimeOut != null)
                    clearTimeout(this.tileInfoTimeOut);
                if (planVersion != null) {
                    try {
                        const thumb = `${UpmeshClient.instance.url}/plan/tiles/${planVersion.projectId}/planVersion/${planVersion.id}/0/0/0?${Math.random()}`;
                        await fetch(thumb);
                    }
                    catch (e) {
                        console.debug('cant load thumb', e);
                    }
                }
                try {
                    const info = planVersion?.info != null && planVersion.info.final
                        ? planVersion?.info
                        : await ZoomTileHelper.getTileInfo(url);
                    let ext = '.png';
                    let newUrl = url;
                    const tileServerUrl = UpmeshClient.instance.url.includes('127.0.0.1') ||
                        UpmeshClient.instance.url.includes('localhost') ||
                        UpmeshClient.instance.url.includes('api.local')
                        ? 'http://127.0.0.1:30099'
                        : UpmeshClient.instance.url.includes('devapi')
                            ? 'https://devtiles.upmesh.de'
                            : 'https://tiles.upmesh.de';
                    if (planVersion != null) {
                        newUrl = url.replace(`${UpmeshClient.instance.url}/plan/tiles/${plan.projectId}/planVersion/${planVersion?.id}`, `${tileServerUrl}/${planVersion?.fileId}_tiles`);
                        const extension = planVersion.fileId.substr(planVersion.fileId.lastIndexOf('.'));
                        ext = extension.toLowerCase() === '.pdf' ? '.png' : extension;
                    }
                    if (this.mounted) {
                        this.setState({ info, url: newUrl, extension: ext }, () => {
                            if (this.mounted && (info.final == null || info.final === false)) {
                                this.tileInfoTimeOut = setTimeout(() => this.getTileInfo(), 10000);
                            }
                        });
                    }
                }
                catch (e) {
                    if (e['messageCode'] !== 'TilesNotYetCreated')
                        console.error('ZoomTileHelper.getMaxZoom', e);
                    if (this.mounted)
                        this.tileInfoTimeOut = setTimeout(() => this.getTileInfo(), 30000);
                }
            }
        };
        this.zoomTo = (point, zoomLevel = 3, selectedId) => {
            if (this.zoomToTimeout != null)
                clearTimeout(this.zoomToTimeout);
            if (this.zoomTile != null) {
                this.zoomToTries = 0;
                this.zoomToTimeout = setTimeout(() => this.zoomTile?.zoomTo(point, zoomLevel, selectedId), 100);
            }
            else {
                this.zoomToTries += 1;
                if (this.zoomToTries < 5)
                    this.zoomToTimeout = setTimeout(() => this.zoomTo(point, zoomLevel, selectedId), 300);
            }
        };
        this.reCreateTiles = (pv) => () => {
            const c = new ReCreateTilesForPlanVersion({}, pv.id);
            c.execute().catch((e) => console.debug('cant recreate plan version', e));
        };
        this.setMapRef = (r) => {
            this.zoomTile = r;
        };
        this.state = { canRecreate: null, loaded: false, extension: '.png' };
    }
    async takeSnapshot() {
        if (this.zoomTile != null) {
            return this.zoomTile.takeSnapshot();
        }
        throw new Error('Zoomtile not ready yet');
    }
    componentWillUnmount() {
        this.mounted = false;
        if (this.zoomToTimeout != null)
            clearTimeout(this.zoomToTimeout);
        if (this.tileInfoTimeOut != null)
            clearTimeout(this.tileInfoTimeOut);
    }
    getInitialPosition() {
        let point;
        const { type, initialPlanPosition } = this.props;
        if (initialPlanPosition != null) {
            point = { point: initialPlanPosition.center.point, zoom: initialPlanPosition.zoom };
        }
        else if (type === 'map') {
            const { plan, marker } = this.props;
            let center = { x: 0, y: 0 };
            let zoomLevel = 15;
            if (plan != null && plan.address != null) {
                const add = new AddressEntity(plan.address);
                center = { y: add.latitude, x: add.longitude };
            }
            if (marker != null && marker.length > 0) {
                if (marker.length === 1) {
                    center = marker[0].position;
                    zoomLevel = 16;
                }
                else {
                    const points = [];
                    let biggestX = marker[0].position.x;
                    let biggestY = marker[0].position.y;
                    let smallestX = marker[0].position.x;
                    let smallestY = marker[0].position.y;
                    marker.forEach((marker) => {
                        const m = marker.position;
                        points.push([m.x, m.y]);
                        if (m.x > biggestX)
                            biggestX = m.x;
                        if (m.y > biggestY)
                            biggestY = m.y;
                        if (m.x < smallestX)
                            smallestX = m.x;
                        if (m.y < smallestY)
                            smallestY = m.y;
                    });
                    const getCenterOfPoints = ZoomTileHelper.getCenterOfPoints(points);
                    center = { x: getCenterOfPoints[1], y: getCenterOfPoints[0] };
                    const diffX = biggestX - smallestX;
                    const diffY = biggestY - smallestY;
                    const zoomLevelX = Math.log(360 / diffX) / Math.LN2;
                    const zoomLevelY = Math.log(360 / diffY) / Math.LN2;
                    zoomLevel = Math.floor(Math.min(zoomLevelX, zoomLevelY));
                }
            }
            point = { point: center, zoom: zoomLevel };
        }
        return point;
    }
    componentDidMount() {
        this.mounted = true;
        this.getTileInfo().catch((err) => console.debug('cant get tileinfo', err));
    }
    updateCanRecreate(initialPlanPosition) {
        const { planVersion } = this.props;
        const { canRecreate } = this.state;
        if (planVersion != null && planVersion.tileStatus === 'failed' && canRecreate == null) {
            const c = new ReCreateTilesForPlanVersion({}, planVersion.id);
            c.canI()
                .then((canRecreate) => this.setState({ canRecreate: canRecreate === true, initialPlanPosition, loaded: true }))
                .catch(() => {
                this.setState({ canRecreate: false, initialPlanPosition, loaded: true });
            });
        }
        else {
            this.setState({ canRecreate: false, initialPlanPosition, loaded: true });
        }
    }
    render() {
        const { planVersion, type } = this.props;
        const { canRecreate, loaded, initialPlanPosition, info, url, extension } = this.state;
        if (planVersion != null) {
            if (planVersion.tileStatus != null && planVersion.tileStatus === 'failed') {
                const button = !canRecreate ? null : (<View style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}>
            <ContainedButton title={I18n.m.getMessage('planVersionCreationRecreate')} onPress={this.reCreateTiles(planVersion)}/>
          </View>);
                console.debug('planversion failed', planVersion);
                return (<View style={{ width: '100%', height: '100%', justifyContent: 'center' }}>
            <View style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}>
              <MaterialText centeredBox centeredText>
                {I18n.m.getMessage('planVersionCreationFailed')}
              </MaterialText>
              <View style={{ height: 8 }}/>
              {button}
            </View>
          </View>);
            }
        }
        if (!loaded || info == null || url == null)
            return (<View style={{ width: '100%', height: '100%', justifyContent: 'center' }}>
          <View style={{ alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}>
            <Spinner />
          </View>
        </View>);
        return (<ZoomTile ref={this.setMapRef} {...this.props} templateFormat={extension} url={url} basicAuth={planVersion != null && type !== 'map'
                ? B64.encode(`${planVersion.projectId}:${planVersion.planId}`)
                : undefined} info={info} infoText={type !== 'map' && (info.final == null || info.final === false)
                ? I18n.m.getMessage('planTilesNotYetCreated')
                : undefined} initialView={initialPlanPosition} clusteringDisabled={!(this.context.settings.appSettings == null || this.context.settings.appSettings['clusterMarker'] === true)}/>);
    }
}
PlanZoomTileMapper.contextType = UserContext;
