import { CommandOfflineModel } from 'cqrs-core/src/client/commands/CommandOfflineFirstStore';
import { ReadModelChangedDefaultClientDispatcher } from 'cqrs-core/src/client/query/ReadModelChangedDefaultClientDispatcher';
import { ServerConnection } from 'cqrs-core/src/core/ServerConnection';
import { Url } from 'cqrs-shared/src/uri/Url';
import { TempFile } from 'materialTheme/src/file/upload/entities/TempFile';
import { Uploads } from 'materialTheme/src/file/upload/Uploads';
import { FileHandler } from 'materialTheme/src/FileHandler';
import { ClientDBadapter } from 'materialTheme/src/odataDB/loki/ClientDBadapterSharedWorker';
import { SecureLocalStoreB } from 'materialTheme/src/securestore/SecureLocalStoreB';
import { LokiDBOptions } from 'odatarepos/src/db/loki/LokiDataBase';
import { LokiODataDB } from 'odatarepos/src/db/loki/LokiODataDB';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import { AsyncEvent } from 'ts-events';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { WorkerInterface } from 'upmesh-auth-core/src/client/WorkerInterface';
import { WorkerServerConnection } from 'upmesh-auth-core/src/client/WorkerServerConnection';
import { ClientDB } from 'upmesh-auth-core/src/ClientDB';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import * as uuid from 'uuid';
import { Config } from './Config';
import { ConfigAll } from './ConfigAll';
export class SharedWorkerWeb extends WorkerInterface {
    constructor() {
        super(...arguments);
        this.startUploads = () => {
            this.getUploadDB()
                .then((localDB) => {
                return Uploads.instance.startUploads(localDB);
            })
                .then(() => {
                console.debug('start Uploads');
            })
                .catch((err) => console.debug('cant start Upload Service', err));
        };
        this.messagesToClientCounter = 0;
    }
    onLogout() {
        SecureLocalStoreB.instance
            .removeItem(`tt_${Url.getURLfromString(Config.b2cURL).host}`)
            .catch((e) => console.debug('cant set token', e));
        AuthClient.instance.logOut(true).catch((err) => console.warn('logout error', err));
        Uploads.instance.stopUploads().catch((err) => console.debug('cant stop Upload Service', err));
    }
    async onWorkerLogout() {
        try {
            await AuthClient.instance.logOut(true);
        }
        catch (err) {
            console.warn('logout error', err);
        }
    }
    startSyncInWorker() {
        AuthClient.instance.startSync(true).catch((err) => console.debug('cant start sync in worker', err));
    }
    async getUploadDB() {
        if (this._localdb == null) {
            this._localdb = new LokiODataDB();
            const options = new LokiDBOptions(new FileHandler(`${Config.b2cURL}/files`));
            await this._localdb.initDB(options);
        }
        return this._localdb;
    }
    async getClientDB(userId, _deleteDB = false) {
        if (userId == null || userId.length === 0) {
            if (this.sc != null && this.sc instanceof WorkerServerConnection && typeof this.sc.setLocalReadDB === 'function')
                this.sc.setLocalReadDB(undefined, this.processMessageOnClient);
        }
        else if (this.cbClient == null || this.lastUserId !== userId) {
            this.lastUserId = userId;
            if (this.sc != null) {
                const lokiDB = new LokiODataDB();
                const adapter = await ClientDBadapter.getAdapter(userId);
                const options = new LokiDBOptions(new FileHandler(`${Config.b2cURL}/files`), `${userId}.json`, adapter);
                await lokiDB.initDB(options);
                if (this.sc != null &&
                    this.sc instanceof WorkerServerConnection &&
                    typeof this.sc.setLocalReadDB === 'function')
                    this.sc.setLocalReadDB(lokiDB, this.processMessageOnClient);
            }
            this.cbClient = new ClientDB(undefined);
        }
        return this.cbClient;
    }
    async freshDatabase() {
        return AuthClient.instance.freshDatabase();
    }
    onLogin(token) {
        AuthClient.instance.setToken({ token }).catch((err) => console.warn('logout error', err));
        AuthClient.instance.syncDispatcher.syncStop.detach(this.startUploads);
        AuthClient.instance.syncDispatcher.syncStop.attach(this.startUploads);
        setTimeout(() => {
            this.startUploads();
        }, 5000);
    }
    postToWorker(msg) {
        if (SharedWorkerWeb.port != null)
            SharedWorkerWeb.port.postMessage(msg);
    }
    async postToWorkerWithAnswer(msg) {
        return new Promise((resolve) => {
            if (SharedWorkerWeb.port != null) {
                const messageId = uuid.v4();
                let to;
                const attacher = (d) => {
                    if (d.messageId === messageId) {
                        this.onResult.detach(attacher);
                        if (to)
                            clearTimeout(to);
                        resolve(d.data);
                    }
                };
                to = setTimeout(() => {
                    if (to)
                        clearTimeout(to);
                    this.onResult.detach(attacher);
                    resolve(null);
                }, 60000);
                this.onResult.attach(attacher);
                SharedWorkerWeb.port.postMessage(`${msg}µ${messageId}`);
            }
            else {
                resolve(null);
            }
        });
    }
    postToClient(msg) {
        this.messagesToClientCounter += 1;
        SharedWorkerWeb.browserInstances.forEach((instance) => {
            instance.postMessage(msg);
        });
    }
    async initializeDB(userId) {
        let initDB = false;
        this.cb = new ClientDB(new LokiODataDB());
        const adapter = await ClientDBadapter.getAdapter(userId);
        const dbOptions = new LokiDBOptions(new FileHandler(`${Config.b2cURL}/files`), `${userId}.json`, adapter);
        try {
            initDB = await this.cb.localDb?.initDB(dbOptions);
            const getAll = await SimpleStorage.get(`offlineCommands_${userId}`);
            let all = [];
            try {
                if (getAll != null) {
                    all = JSON.parse(getAll);
                }
            }
            catch (err) {
                all = [];
                console.error('cant get offlineCommands for sync', err);
            }
            if (all != null && all.length > 0) {
                try {
                    const offlineCommandRepo = this.cb.localDb?.getRepos(new CommandOfflineModel());
                    if (offlineCommandRepo) {
                        for (const c of all) {
                            try {
                                await offlineCommandRepo.post(c);
                            }
                            catch (e) {
                                console.error('cant post offlinecommand from simple storage synCommands', e);
                            }
                        }
                        await SimpleStorage.remove(`offlineCommands_${userId}`);
                    }
                }
                catch (e) {
                    console.error('cant save offline commands', e);
                }
            }
        }
        catch (e) {
            console.debug('cant load Loki, try to reset local db', e);
            await ClientDBadapter.deleteDB(userId);
            try {
                initDB = await this.cb.localDb?.initDB(dbOptions);
            }
            catch (e) {
                console.warn('still cant load Loki, select other adapter?', e);
            }
        }
        return initDB;
    }
    async createWorkerConnections(token) {
        if (this.init === false) {
            this.init = 'started';
            try {
                const uId = token != null && token.length > 0 ? CurrentUser.getUnVerifiedDataFromToken(token)._id : '';
                if (this.sc == null) {
                    const clientversion = Config.getVersion().split('@')[1];
                    this.sc = new ServerConnection({
                        remoteOptions: {
                            apiUrl: Config.b2cURL,
                            authToken: token,
                        },
                        autoConnect: false,
                        query: { clientversion },
                    });
                    this.sc.onConnectionChanged.attach((connected) => {
                        this.postToClient(`clientµallµconnectionµ${connected ? '1' : ''}`);
                    });
                    ReadModelChangedDefaultClientDispatcher.instance.attach({
                        readModelName: 'any',
                        callback: (e) => {
                            this.postToClient(`clientµallµreadmodelµ${e != null ? JSON.stringify(e) : ''}`);
                        },
                    });
                    const reInitDB = async (userId, deleteDB = false) => {
                        if (this.lastUserId !== userId || deleteDB) {
                            this.lastUserId = userId;
                            let initDB = await this.initializeDB(userId);
                            const lastReset = await SimpleStorage.get(`dbVersion_${userId}`);
                            let tempfiles = [];
                            let offlineCommands = [];
                            if (deleteDB || lastReset == null || parseInt(lastReset, 10) < 34) {
                                let online = false;
                                try {
                                    const apiOnline = await fetch(`${ConfigAll.b2cURL}/health`);
                                    const state = await apiOnline.text();
                                    online = state === 'started';
                                }
                                catch (e) {
                                    console.debug('cant fetch online state', e);
                                }
                                if (online) {
                                    const offlineCommandRepo = this.cb?.localDb?.getRepos(new CommandOfflineModel());
                                    if (offlineCommandRepo && (await offlineCommandRepo.count()) > 0) {
                                        const g = await offlineCommandRepo.get();
                                        offlineCommands = [...g];
                                    }
                                    const repo = this.cb?.localDb?.getRepos(new TempFile());
                                    if (repo && (await repo.count()) > 0) {
                                        const g = await repo.get();
                                        tempfiles = [...g];
                                    }
                                    await ClientDBadapter.deleteDB(userId);
                                    await SimpleStorage.set(`dbVersion_${userId}`, '34');
                                    initDB = await this.initializeDB(userId);
                                    if (offlineCommands.length > 0) {
                                        const offlineCommandRepo = this.cb?.localDb?.getRepos(new CommandOfflineModel());
                                        if (offlineCommandRepo) {
                                            for (const c of offlineCommands) {
                                                try {
                                                    if (offlineCommandRepo)
                                                        await offlineCommandRepo.post(c);
                                                }
                                                catch (e) {
                                                    console.debug('cant save offlineCommand', e);
                                                }
                                            }
                                        }
                                    }
                                    if (tempfiles.length > 0) {
                                        const repo = this.cb?.localDb?.getRepos(new TempFile());
                                        if (repo) {
                                            for (const t of tempfiles) {
                                                try {
                                                    if (repo)
                                                        await repo.post(t);
                                                }
                                                catch (e) {
                                                    console.debug('cant save tempfile', e);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            if (!initDB) {
                                ErrorReporter.sendReport({ subject: 'cant init db', data: new Error(), type: 'warn' });
                                return this.cb;
                            }
                        }
                        return this.cb;
                    };
                    if (this.cb == null || this.lastUserId !== uId) {
                        await reInitDB(uId);
                    }
                    await UpmeshClient.instance.init({
                        serverConnection: this.sc,
                        clientDB: this.cb,
                        reInitDB,
                        remoteUrl: Config.b2cURL,
                        startSync: true,
                        discardOnErrors: -1,
                    }, token);
                    UpmeshClient.instance.syncDispatcher.syncStop.attach(() => {
                        this.postToClient('clientµallµsyncStopped');
                    });
                    UpmeshClient.instance.syncDispatcher.syncStart.attach(() => {
                        this.postToClient(`clientµallµsyncStarted`);
                    });
                    UpmeshClient.instance.syncDispatcher.syncStatus.attach((s) => {
                        this.postToClient(`clientµallµsyncStatusµ${JSON.stringify(s)}`);
                    });
                }
                if (token != null && token.length > 0)
                    await AuthClient.instance.logIn({ token });
                this.commandStore = AuthClient.instance.commandStore;
                if (!this.sc.isConnected)
                    await this.sc.connect();
                this.initError = null;
                this.init = true;
            }
            catch (e) {
                console.debug('cant login in worker', e);
                this.init = 'error';
                this.initError = e;
            }
        }
    }
}
SharedWorkerWeb.instance = new SharedWorkerWeb();
SharedWorkerWeb.browserInstances = [];
onconnect = (e) => {
    console.debug('starting Sharedworker', e);
    const port = e.ports[0];
    ConfigAll.init()
        .then(() => {
        AsyncEvent.setScheduler(function (callback) {
            setTimeout(callback, 0);
        });
        SharedWorkerWeb.browserInstances.push(port);
        port.addEventListener('message', (m) => WorkerInterface.instance.toWorkerMessage(m.data));
        port.start();
    })
        .catch((err) => {
        console.error('cant start Shared Worker');
        throw err;
    });
};
