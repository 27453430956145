"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeStoredFilename = void 0;
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var StoredFilenameChanged_1 = require("../../events/storedfiles/StoredFilenameChanged");
var FolderEntity_1 = require("../../query/entities/FolderEntity");
var ClientProjectCommands_1 = require("../_root/ClientProjectCommands");
var ChangeStoredFilename = function (_ClientProjectCommand) {
  function ChangeStoredFilename(data, entityId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ChangeStoredFilename);
    _this = _callSuper(this, ChangeStoredFilename, [data, entityId, token]);
    _this.aggregate = 'project';
    _this.commandName = 'ChangeStoredFilename';
    return _this;
  }
  (0, _inherits2.default)(ChangeStoredFilename, _ClientProjectCommand);
  return (0, _createClass2.default)(ChangeStoredFilename, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        this.file = yield CommandReadModels_1.CommandReadModels.storedFile.getById(this.entityId);
        yield (0, _get2.default)((0, _getPrototypeOf2.default)(ChangeStoredFilename.prototype), "checkProjectStatusAndGeneralAccess", this).call(this, this.file.projectId, userId);
        if (this.file.userId === userId) {
          return true;
        }
        if (this.file.folder != null && this.file.folder.length > 0) {
          try {
            var canEdit = yield FolderEntity_1.FolderEntity.hasFolderEditRights(this.file.folder, userId);
            if (canEdit) return true;
          } catch (e) {}
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var _this2 = this;
        var orgFilename = this.data.ext ? `${this.data.orgFilename}${this.data.ext.startsWith('.') ? '' : '.'}${this.data.ext}` : this.data.orgFilename;
        var events = [new StoredFilenameChanged_1.StoredFilenameChanged(this.entityId, {
          orgFilename: orgFilename,
          oldFilename: this.file.orgFilename
        }, this.file.projectId)];
        if (this.file.forEntityId == null || this.file.forEntityId.length === 0) {
          var filter = `projectId eq '${this.file.projectId}' and orgFilename eq '${this.file.orgFilename}'`;
          if (this.file.folder != null) filter += ` and folder eq '${this.file.folder}'`;else filter += ` and folder eq null`;
          var files = yield CommandReadModels_1.CommandReadModels.storedFile.get({
            filter: filter
          });
          files.forEach(function (f) {
            if (f.id !== _this2.file.id) events.push(new StoredFilenameChanged_1.StoredFilenameChanged(f.id, {
              orgFilename: orgFilename,
              oldFilename: _this2.file.orgFilename
            }, _this2.file.projectId));
          });
        }
        return events;
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {
        var regexp = /^[^<>:;,?"*|/]+$/g;
        var matched = regexp.test(this.data.orgFilename);
        if (!(this.data.orgFilename != null && this.data.orgFilename.length > 0) || !matched) {
          throw {
            message: 'Der neue Dateiname ist ungültig',
            messageCode: 'projectFileInvalidFilename'
          };
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(ClientProjectCommands_1.ClientProjectCommands);
exports.ChangeStoredFilename = ChangeStoredFilename;