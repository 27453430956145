"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RemoveEmail = void 0;
var AuthClient_1 = require("../../AuthClient");
var UserRemovedEmail_1 = require("../../events/user/UserRemovedEmail");
var ClientUserCommands_1 = require("./root/ClientUserCommands");
var RemoveEmail = function (_ClientUserCommands_) {
  function RemoveEmail(data, entityId) {
    var _this;
    (0, _classCallCheck2.default)(this, RemoveEmail);
    _this = _callSuper(this, RemoveEmail, [data, entityId]);
    _this.commandName = 'RemoveEmail';
    _this.aggregate = 'user';
    return _this;
  }
  (0, _inherits2.default)(RemoveEmail, _ClientUserCommands_);
  return (0, _createClass2.default)(RemoveEmail, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var superCan = yield (0, _get2.default)((0, _getPrototypeOf2.default)(RemoveEmail.prototype), "canI", this).call(this);
        if (superCan === true) {
          try {
            var mail = yield AuthClient_1.AuthClient.instance.modals.userMails.getById(this.entityId);
            if (mail.validated !== true) {
              return true;
            }
          } catch (e) {
            return {
              messageCode: 'cantDeleteEmail',
              message: 'Email kann nicht gelöscht werden'
            };
          }
          var count = yield AuthClient_1.AuthClient.instance.modals.userMails.count(`userId eq '${this.data.userId}' and validated eq true and deleted ne true`);
          if (count > 1) {
            return true;
          }
          return {
            messageCode: 'cantDeleteEmail',
            message: 'Email kann nicht gelöscht werden'
          };
        }
        return superCan;
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {});
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var data = {
          userId: this.data.userId
        };
        return new UserRemovedEmail_1.UserRemovedEmail(this.entityId, data);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }]);
}(ClientUserCommands_1.ClientUserCommands);
exports.RemoveEmail = RemoveEmail;