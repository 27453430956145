"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CreateTicket = void 0;
var ApplicationError_1 = require("odatarepos/build/src/entities/ApplicationError");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var RightsManager_1 = require("../../../access/rights/RightsManager");
var TicketCreated_1 = require("../../events/tickets/TicketCreated");
var TicketEntity_1 = require("../../query/entities/TicketEntity");
var ClientProjectCommands_1 = require("../_root/ClientProjectCommands");
var CreateTicket = function (_ClientProjectCommand) {
  function CreateTicket(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v1();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, CreateTicket);
    _this = _callSuper(this, CreateTicket, [data, entityId, token]);
    _this.aggregate = 'project';
    _this.commandName = 'CreateTicket';
    return _this;
  }
  (0, _inherits2.default)(CreateTicket, _ClientProjectCommand);
  return (0, _createClass2.default)(CreateTicket, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        yield (0, _get2.default)((0, _getPrototypeOf2.default)(CreateTicket.prototype), "checkProjectStatusAndGeneralAccess", this).call(this, this.data.projectId, userId);
        if (yield RightsManager_1.RightsManager.hasWriteRight(this.data.projectId, userId, 'commandCreateTicket')) {
          return true;
        }
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var tokenData = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token);
        var userId = tokenData._id;
        var data = {
          completionOn: this.data.completionOn,
          title: this.data.title,
          description: this.data.description,
          planId: this.data.planId,
          planPositionX: this.data.planPositionX,
          planPositionY: this.data.planPositionY,
          planPositionZ: this.data.planPositionZ,
          projectId: this.data.projectId,
          address: this.data.address,
          foreignId: this.data.foreignId,
          builder: this.data.builder,
          creator: userId,
          archived: false,
          ticketType: undefined,
          ticketStatus: TicketEntity_1.TicketStatus.open,
          subTicketFrom: this.data.subTicketFrom
        };
        return new TicketCreated_1.TicketCreated(this.entityId, data);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        if (key == null || key === 'description') {
          var _this$data$descriptio;
          if (this.data.description != null && ((_this$data$descriptio = this.data.description) == null ? void 0 : _this$data$descriptio.length) > 4096) {
            throw new ApplicationError_1.ApplicationError('Eingabe zu lang', 'tooLong', ApplicationError_1.ApplicationErrorStatusCode.BadRequestError);
          }
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(ClientProjectCommands_1.ClientProjectCommands);
exports.CreateTicket = CreateTicket;