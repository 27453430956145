"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PromisePool = void 0;
var PromisePool = function () {
  function PromisePool() {
    (0, _classCallCheck2.default)(this, PromisePool);
  }
  return (0, _createClass2.default)(PromisePool, null, [{
    key: "run",
    value: function () {
      var _run = (0, _asyncToGenerator2.default)(function* (_ref) {
        var collection = _ref.collection,
          task = _ref.task,
          maxConcurrency = _ref.maxConcurrency;
        if (!maxConcurrency) {
          return Promise.all(collection.map(function (item, i) {
            return task(item, i);
          }));
        }
        if (!collection.length) {
          return [];
        }
        var results = [];
        var mutableCollection = collection.slice().map(function (t, i) {
          return [t, i];
        });
        var available = maxConcurrency;
        var done = false;
        var globalResolve;
        var globalReject;
        var finalPromise = new Promise(function (resolve, reject) {
          globalResolve = resolve;
          globalReject = reject;
        });
        var listeners = new Set();
        function notify() {
          for (var listener of listeners) {
            listener();
          }
        }
        function ready() {
          return new Promise(function (resolve) {
            var listener = function listener() {
              if (done) {
                listeners.delete(listener);
                resolve();
              } else if (available > 0) {
                listeners.delete(listener);
                available -= 1;
                resolve();
              }
            };
            listeners.add(listener);
            notify();
          });
        }
        var _loop = function* _loop() {
            var value = mutableCollection.shift();
            if (!value) return 0;
            if (done) return 0;
            var _value = (0, _slicedToArray2.default)(value, 2),
              t = _value[0],
              i = _value[1];
            yield ready();
            task(t, i).then(function (r) {
              results.push([r, i]);
              available += 1;
              if (results.length === collection.length) {
                done = true;
                globalResolve();
              }
            }).catch(function (e) {
              done = true;
              globalReject(e);
            }).finally(notify);
          },
          _ret;
        while (true) {
          _ret = yield* _loop();
          if (_ret === 0) break;
        }
        yield finalPromise;
        return results.slice().sort(function (_ref2, _ref3) {
          var _ref4 = (0, _slicedToArray2.default)(_ref2, 2),
            a = _ref4[1];
          var _ref5 = (0, _slicedToArray2.default)(_ref3, 2),
            b = _ref5[1];
          return a - b;
        }).map(function (_ref6) {
          var _ref7 = (0, _slicedToArray2.default)(_ref6, 1),
            r = _ref7[0];
          return r;
        });
      });
      function run(_x) {
        return _run.apply(this, arguments);
      }
      return run;
    }()
  }]);
}();
exports.PromisePool = PromisePool;