import { ReadModels } from 'cqrs-core/src/core/query/ReadModels';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { CommandReadModels } from 'upmesh-core/src/server/webserver/commands/CommandReadModels';
export class CachedEntities {
    static async init() {
        try {
            UpmeshClient.eventDispatcher.attach({
                attachKey: CachedEntities.userChangeKey,
                readModelName: 'User',
                callback: CachedEntities.onUserUpdate,
            });
            UpmeshClient.eventDispatcher.attach({
                attachKey: CachedEntities.taskChangeKey,
                readModelName: 'Task',
                callback: CachedEntities.onTaskUpdate,
            });
            UpmeshClient.eventDispatcher.attach({
                attachKey: CachedEntities.companyMemberChangeKey,
                readModelName: 'CompanyMember',
                callback: CachedEntities.onCompanyMemberUpdate,
            });
            UpmeshClient.eventDispatcher.attach({
                attachKey: CachedEntities.costCenterChangeKey,
                readModelName: 'CostCenter',
                callback: CachedEntities.onCostCenterUpdate,
            });
            UpmeshClient.eventDispatcher.attach({
                attachKey: CachedEntities.extraPayChangeKey,
                readModelName: 'ExtraPay',
                callback: CachedEntities.onExtraPayUpdate,
            });
            UpmeshClient.eventDispatcher.attach({
                attachKey: CachedEntities.projectChangeKey,
                readModelName: 'Project',
                callback: CachedEntities.onProjectUpdate,
            });
            await CachedEntities.loadKnownProjects();
            await CachedEntities.loadKnownUser();
            await CachedEntities.loadKnownTasks();
            await CachedEntities.loadKnownCompanyMember();
            await CachedEntities.loadKnownExtraPay();
            await CachedEntities.loadKnownCostCenter();
        }
        catch (e) {
            console.warn('cant init cached Entities', e);
        }
    }
    static async reset() {
        UpmeshClient.eventDispatcher.detach('User', CachedEntities.userChangeKey);
        UpmeshClient.eventDispatcher.detach('ExtraPay', CachedEntities.extraPayChangeKey);
        UpmeshClient.eventDispatcher.detach('Task', CachedEntities.taskChangeKey);
        UpmeshClient.eventDispatcher.detach('CostCenter', CachedEntities.costCenterChangeKey);
        UpmeshClient.eventDispatcher.detach('CompanyMember', CachedEntities.companyMemberChangeKey);
        UpmeshClient.eventDispatcher.detach('Project', CachedEntities.projectChangeKey);
        CachedEntities._knownProjects = new Map();
        CachedEntities._knownUsers = new Map();
        CachedEntities._knownExtraPay = new Map();
        CachedEntities._knownTasks = new Map();
        CachedEntities._knownCostCenter = new Map();
        CachedEntities._knownCompanyMember = new Map();
    }
    static get knownUsers() {
        return CachedEntities._knownUsers;
    }
    static get knownProjects() {
        return CachedEntities._knownProjects;
    }
    static async loadKnownProjects() {
        const us = await CommandReadModels.project.get();
        CachedEntities._knownProjects = new Map();
        us.forEach((u) => {
            CachedEntities._knownProjects.set(u.id, u);
        });
    }
    static async loadKnownUser() {
        const users = ReadModels.get('user');
        const us = await users.get();
        CachedEntities._knownUsers = new Map();
        us.forEach((u) => {
            CachedEntities._knownUsers.set(u.id, u);
        });
    }
    static get knownTasks() {
        return CachedEntities._knownTasks;
    }
    static async loadKnownTasks() {
        const us = await CommandReadModels.task.get();
        CachedEntities._knownTasks = new Map();
        us.forEach((u) => {
            CachedEntities._knownTasks.set(u.id, u);
        });
    }
    static get knownCompanyMember() {
        return CachedEntities._knownCompanyMember;
    }
    static async loadKnownCompanyMember() {
        const us = await CommandReadModels.companyMember.get();
        CachedEntities._knownCompanyMember = new Map();
        us.forEach((u) => {
            CachedEntities._knownCompanyMember.set(u.id, u);
        });
    }
    static get knownExtraPay() {
        return CachedEntities._knownExtraPay;
    }
    static async loadKnownExtraPay() {
        const us = await CommandReadModels.extraPay.get();
        CachedEntities._knownExtraPay = new Map();
        us.forEach((u) => {
            CachedEntities._knownExtraPay.set(u.id, u);
        });
    }
    static get knownCostCenter() {
        return CachedEntities._knownCostCenter;
    }
    static async loadKnownCostCenter() {
        const us = await CommandReadModels.costCenter.get();
        CachedEntities._knownCostCenter = new Map();
        us.forEach((u) => {
            CachedEntities._knownCostCenter.set(u.id, u);
        });
    }
}
CachedEntities.userChangeKey = 'CachedEntitiesUsers';
CachedEntities.taskChangeKey = 'CachedEntitiesTasks';
CachedEntities.companyMemberChangeKey = 'CachedEntitiesCompanyMember';
CachedEntities.costCenterChangeKey = 'CachedEntitiesCostCenter';
CachedEntities.extraPayChangeKey = 'CachedEntitiesExtraPay';
CachedEntities.projectChangeKey = 'ProjectExtraPay';
CachedEntities.onUserUpdate = (e) => {
    e.entities.forEach((u) => {
        if (u.entity != null)
            CachedEntities._knownUsers.set(u.entity.id, u.entity);
    });
};
CachedEntities.onExtraPayUpdate = (e) => {
    e.entities.forEach((u) => {
        if (u.entity != null)
            CachedEntities._knownExtraPay.set(u.entity.id, u.entity);
    });
};
CachedEntities.onProjectUpdate = (e) => {
    e.entities.forEach((u) => {
        if (u.entity != null)
            CachedEntities._knownProjects.set(u.entity.id, u.entity);
    });
};
CachedEntities.onTaskUpdate = (e) => {
    e.entities.forEach((u) => {
        if (u.entity != null)
            CachedEntities._knownTasks.set(u.entity.id, u.entity);
    });
};
CachedEntities.onCostCenterUpdate = (e) => {
    e.entities.forEach((u) => {
        if (u.entity != null)
            CachedEntities._knownCostCenter.set(u.entity.id, u.entity);
    });
};
CachedEntities.onCompanyMemberUpdate = (e) => {
    e.entities.forEach((u) => {
        if (u.entity != null)
            CachedEntities._knownCompanyMember.set(u.entity.id, u.entity);
    });
};
CachedEntities._knownUsers = new Map();
CachedEntities._knownProjects = new Map();
CachedEntities._knownTasks = new Map();
CachedEntities._knownCompanyMember = new Map();
CachedEntities._knownExtraPay = new Map();
CachedEntities._knownCostCenter = new Map();
