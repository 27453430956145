var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AddWidgetDialog = AddWidgetDialog;
exports.openAddWidgetDialog = void 0;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var uuid = _interopRequireWildcard(require("uuid"));
var _I18n = require("../../i18n/I18n");
var _Card = require("../Card");
var _Dialog = require("../Dialog");
var _DialogContent = require("../dialog/DialogContent");
var _DialogTitle = require("../dialog/DialogTitle");
var _Icon = require("../Icon");
var _MaterialText = require("../text/MaterialText");
var _Ripple = require("../utils/Ripple");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function AddWidgetDialog(props) {
  var _useState = (0, _react.useState)(),
    _useState2 = (0, _slicedToArray2.default)(_useState, 2),
    optionsDialog = _useState2[0],
    setOptionDialog = _useState2[1];
  var _useState3 = (0, _react.useState)(0),
    _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
    currentWidth = _useState4[0],
    setCurrentWith = _useState4[1];
  var onOptionsSaved = function onOptionsSaved(w) {
    return function (e) {
      var _Dialog$instance;
      (_Dialog$instance = _Dialog.Dialog.instance) == null ? void 0 : _Dialog$instance.close();
      props.addItem({
        widgetId: w.id,
        id: uuid.v4(),
        settings: e,
        width: w.defaultSize ? w.defaultSize.width : 3,
        height: w.defaultSize ? w.defaultSize.height : 2
      });
    };
  };
  var addWidget = function addWidget(w) {
    return function () {
      if (w.optionWindow != null) {
        var o = w.optionWindow(w.defaultSettings ? Object.assign({}, w.defaultSettings, {
          onSaved: onOptionsSaved(w),
          type: 'add'
        }) : {
          onSaved: onOptionsSaved(w),
          type: 'add'
        });
        setOptionDialog(o);
      } else {
        var _Dialog$instance2;
        (_Dialog$instance2 = _Dialog.Dialog.instance) == null ? void 0 : _Dialog$instance2.close();
        props.addItem({
          widgetId: w.id,
          id: uuid.v4(),
          settings: w.defaultSettings ? Object.assign({}, w.defaultSettings) : {},
          width: w.defaultSize ? w.defaultSize.width : 3,
          height: w.defaultSize ? w.defaultSize.height : 2
        });
      }
    };
  };
  if (optionsDialog) return optionsDialog;
  var perRow = currentWidth > 728 ? 4 : currentWidth > 512 ? 3 : 2;
  var width = currentWidth ? currentWidth / perRow - 8 : 208;
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_DialogTitle.DialogTitle, {
      children: _I18n.I18n.m.getMessage('widgetAddDialogTitle')
    }), (0, _jsxRuntime.jsx)(_DialogContent.DialogContent, {
      padding: 8,
      children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          paddingVertical: 4,
          width: '100%'
        },
        onLayout: function onLayout(e) {
          if (e.nativeEvent.layout.width) setCurrentWith(e.nativeEvent.layout.width);
        },
        children: currentWidth > 0 ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            paddingVertical: 4,
            width: '100%',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-start'
          },
          children: props.templates.map(function (w) {
            var count = 0;
            props.currentItems.forEach(function (a) {
              if (a.widgetId === w.id) count += 1;
            });
            var disabled = w.notAddable || !w.multiple && count > 0;
            return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              style: {
                paddingBottom: 16,
                width: width + 8,
                justifyContent: 'center'
              },
              children: (0, _jsxRuntime.jsx)(_Card.Card, {
                style: {
                  width: width - 8,
                  alignSelf: 'center'
                },
                outerPadding: 4,
                children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
                  style: {
                    width: width - 8,
                    position: 'relative'
                  },
                  children: [(0, _jsxRuntime.jsx)(_Ripple.Ripple, {
                    onPress: addWidget(w),
                    disabled: disabled,
                    style: {
                      width: '100%',
                      backgroundColor: '#FFFFFF'
                    },
                    children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
                      style: {
                        width: '100%'
                      },
                      children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
                        style: {
                          width: '100%',
                          justifyContent: 'center',
                          alignItems: 'center'
                        },
                        children: w.previewImage ? (0, _jsxRuntime.jsx)(_reactNativeWeb.Image, {
                          style: {
                            width: 160,
                            height: 160
                          },
                          resizeMode: "contain",
                          source: w.previewImage,
                          width: 100,
                          height: 100
                        }) : (0, _jsxRuntime.jsx)(_Icon.Icon, {
                          icon: "file-hidden",
                          iconSize: 64,
                          outerSize: 64,
                          toolTip: ""
                        })
                      }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
                        style: {
                          width: '100%',
                          flexDirection: 'row',
                          alignItems: 'center',
                          height: 48
                        },
                        children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
                          style: {
                            flex: 1,
                            paddingBottom: 8
                          },
                          children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
                            fixedWidth: "100%",
                            textAlign: "center",
                            children: w.widgetName
                          })
                        })
                      })]
                    })
                  }), disabled && (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
                    style: {
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: 'rgba(255,255,255,0.5)'
                    }
                  })]
                })
              })
            }, `widget_${w.id}`);
          })
        }) : null
      })
    })]
  });
}
var openAddWidgetDialog = exports.openAddWidgetDialog = function openAddWidgetDialog(props) {
  return function (e) {
    var _Dialog$instance3;
    var openPosition = {
      x: e.nativeEvent.pageX,
      y: e.nativeEvent.pageY
    };
    props.templates.sort(function (a, b) {
      return `${a.widgetName}`.localeCompare(`${b.widgetName}`);
    });
    (_Dialog$instance3 = _Dialog.Dialog.instance) == null ? void 0 : _Dialog$instance3.open({
      content: (0, _jsxRuntime.jsx)(AddWidgetDialog, Object.assign({}, props)),
      fullscreenResponsive: true,
      width: 768,
      contentPadding: false,
      openPosition: openPosition,
      scrollable: false,
      openImmediately: false,
      closeOnTouchOutside: true,
      showCloseIcon: true
    });
  };
};