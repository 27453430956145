"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeUserSettings = void 0;
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var CurrentUser_1 = require("../../CurrentUser");
var UserSetttingsChanged_1 = require("../../events/user/UserSetttingsChanged");
var UserSettingsEntity_1 = require("../../query/entities/UserSettingsEntity");
var ClientUserCommands_1 = require("./root/ClientUserCommands");
var ChangeUserSettings = function (_ClientUserCommands_) {
  function ChangeUserSettings(data) {
    var _this;
    var userId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : CurrentUser_1.CurrentUser.userId;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ChangeUserSettings);
    _this = _callSuper(this, ChangeUserSettings, [data, userId, token]);
    _this.commandName = 'ChangeUserSettings';
    _this.aggregate = 'user';
    return _this;
  }
  (0, _inherits2.default)(ChangeUserSettings, _ClientUserCommands_);
  return (0, _createClass2.default)(ChangeUserSettings, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        yield (0, _get2.default)((0, _getPrototypeOf2.default)(ChangeUserSettings.prototype), "canI", this).call(this);
        if (this.data.userId !== this.entityId) {
          throw {
            message: 'Keine Berechtigung',
            messageCode: 'forbiddenCommand'
          };
        }
        return (0, _get2.default)((0, _getPrototypeOf2.default)(ChangeUserSettings.prototype), "canI", this).call(this);
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        if (key == null || key === 'timeFormat') {
          if (this.data.timeFormat.length <= 0) {
            throw {
              key: 'timeFormat',
              messageCode: 'required',
              message: 'Zeitformat wird benötigt'
            };
          }
        }
        if (key == null || key === 'showKW') {
          if (this.data.showKW == null) {
            throw {
              key: 'showKW',
              messageCode: 'required',
              message: 'Kalenderwoche wird benötigt'
            };
          }
        }
        if (key == null || key === 'startWithMonday') {
          if (this.data.startWithMonday == null) {
            throw {
              key: 'startWithMonday',
              messageCode: 'required',
              message: 'Kalenderwoche wird benötigt'
            };
          }
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var _this$data = this.data,
          appSettings = _this$data.appSettings,
          showKW = _this$data.showKW,
          startWithMonday = _this$data.startWithMonday,
          timeFormat = _this$data.timeFormat;
        var currentSettings;
        try {
          currentSettings = yield CommandReadModels_1.CommandReadModels.userSettings.getById(this.entityId);
        } catch (e) {
          currentSettings = new UserSettingsEntity_1.UserSettingsEntity({
            id: this.entityId,
            userId: this.entityId
          });
        }
        var hasChanged = currentSettings.showKW !== this.data.showKW || currentSettings.startWithMonday !== this.data.startWithMonday || currentSettings.timeFormat !== this.data.timeFormat || this.data.appSettings !== currentSettings.appSettings;
        if (!hasChanged) return [];
        var data = {
          userId: this.entityId,
          appSettings: appSettings,
          showKW: showKW,
          startWithMonday: startWithMonday,
          timeFormat: timeFormat
        };
        return new UserSetttingsChanged_1.UserSettingsChanged(this.entityId, data);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }]);
}(ClientUserCommands_1.ClientUserCommands);
exports.ChangeUserSettings = ChangeUserSettings;