"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CreateWorkingTimeModel = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var RightsManager_1 = require("../../../../access/rights/RightsManager");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var WorkingTimeModelCreated_1 = require("../../../events/company/workingtimemodel/WorkingTimeModelCreated");
var WorkingTimeModelEntity_1 = require("../../../query/entities/WorkingTimeModelEntity");
var CreateWorkingTimeModel = function (_CommandRoot_1$Comman) {
  function CreateWorkingTimeModel(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v1();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, CreateWorkingTimeModel);
    _this = _callSuper(this, CreateWorkingTimeModel, [data, entityId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'CreateWorkingTimeModel';
    return _this;
  }
  (0, _inherits2.default)(CreateWorkingTimeModel, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(CreateWorkingTimeModel, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        var company = yield CommandReadModels_1.CommandReadModels.company.getById(this.data.companyId);
        if (yield RightsManager_1.RightsManager.isCompanyAdmin(userId, company.id)) return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return new WorkingTimeModelCreated_1.WorkingTimeModelCreated(this.entityId, Object.assign({}, this.data));
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        if (this.data.sections.length === 1) {
          this.data.sections[0].validFromDay = 1;
          this.data.sections[0].validFromMonth = 1;
        }
        yield CreateWorkingTimeModel.validateWorkingTimeModel(new WorkingTimeModelEntity_1.WorkingTimeModelEntity(Object.assign({
          id: this.entityId
        }, this.data)), key);
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }], [{
    key: "validateWorkingTimeModel",
    value: function () {
      var _validateWorkingTimeModel = (0, _asyncToGenerator2.default)(function* (data, key) {
        var workingTimeModels = yield CommandReadModels_1.CommandReadModels.workingTimeModel.get({
          filter: `companyId eq '${data.companyId}' and deleted ne true`
        });
        var errors = [];
        if (key == null || key === 'postRecordingInDays') {
          if (data.postRecordingInDays > Number.MAX_SAFE_INTEGER) {
            errors.push({
              message: 'Nacherfassung in Tagen ist zu groß',
              messageCode: 'workingTimeModelPostRecordingInDaysTooLarge',
              key: 'postRecordingInDays'
            });
          }
        }
        if (key == null || key === 'mark') {
          if (data.mark == null || data.mark.length === 0) {
            errors.push({
              message: 'Das Kennzeichen wird benötigt',
              messageCode: 'required',
              key: 'mark'
            });
          }
          if ((workingTimeModels == null ? void 0 : workingTimeModels.findIndex(function (t) {
            return data.mark === t.mark && data.id !== t.id;
          })) > -1) {
            errors.push({
              message: 'Das Kennzeichen existiert bereits',
              messageCode: 'companyWorkingTimeModelAlreadyExists',
              key: 'mark'
            });
          }
        }
        if (key == null || key === 'postRecordingInDays') {
          if (data.postRecordingInDays == null) {
            errors.push({
              message: 'Der Erfassungszeitraum wird benötigt',
              messageCode: 'required',
              key: 'postRecordingInDays'
            });
          }
        }
        if (key == null || key === 'sections') {
          if (data.sections == null || data.sections.length === 0) {
            errors.push({
              message: 'Es wird mindestens ein Zeitabschnitt benötigt',
              messageCode: 'workingTimeModelSectionRequired',
              key: 'sections'
            });
          }
          if (data.sections.length > 0) {
            data.sections.forEach(function (s1, i1) {
              data.sections.forEach(function (s2, i2) {
                if (i1 !== i2 && s1.validFromDay === s2.validFromDay && s1.validFromMonth === s2.validFromMonth) {
                  errors.push({
                    message: 'Es darf kein Zeitabschnitt mit der selben Gültigkeit geben',
                    messageCode: 'workingTimeModelNoSectionsWithSameValidDate',
                    messageData: {
                      section1: i1 + 1,
                      section2: i2 + 1
                    },
                    key: 'sections'
                  });
                }
              });
            });
          }
        }
        if (errors.length > 0) throw errors;
      });
      function validateWorkingTimeModel(_x2, _x3) {
        return _validateWorkingTimeModel.apply(this, arguments);
      }
      return validateWorkingTimeModel;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.CreateWorkingTimeModel = CreateWorkingTimeModel;