import { Card } from 'materialTheme/src/theme/components/Card';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import BackButton from 'materialTheme/src/views/components/BackButton';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { I18n } from '../../i18n/I18n';
import { PageView } from '../root/PageView';
import { LogBook } from './LogBook';
const HEIGHT_ADJUSTMENT = 72;
export class LogBookView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { size } = this.props;
        const height = size.contentHeight - HEIGHT_ADJUSTMENT;
        const title = I18n.m.getMessage('logBook');
        const leftButtons = ResizeEvent.current.windowWidth <= ThemeManager.style.breakpointM
            ? [<BackButton key="backButtonLogBookView" onAllPlatforms/>]
            : undefined;
        return (<PageView headerProps={{ title, leftButtons }} scrollable={false}>
        <View style={{
                height,
                width: '100%',
                maxWidth: '100%',
                alignSelf: 'center',
            }}>
          <Card style={{ height }}>
            <LogBook height={height} projectId={this.props.projectId}/>
          </Card>
        </View>
      </PageView>);
    }
}
