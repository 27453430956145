var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RegisterView = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-client/node_modules/react-native-web");
var _LeftImageOrTopLogoResponsive = require("../../components/login/components/LeftImageOrTopLogoResponsive");
var _LoginOrRegisterTitle = require("../../components/login/components/LoginOrRegisterTitle");
var _RegisterForm = require("../../components/login/components/RegisterForm");
var _ContainedButton = require("../../theme/components/button/ContainedButton");
var _MaterialText = require("../../theme/components/text/MaterialText");
var _I18n = require("../../theme/i18n/I18n");
var _Routing = require("../../theme/routing/Routing");
var _ThemeManager = require("../../theme/ThemeManager");
var _BackButton = _interopRequireDefault(require("../components/BackButton"));
var _PageView = require("../root/PageView");
var _jsxRuntime = require("../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var containerStyle = {
  width: '100%',
  maxWidth: 512,
  alignSelf: 'center'
};
var RegisterView = exports.RegisterView = function (_PureComponent) {
  function RegisterView(props, context) {
    var _this;
    (0, _classCallCheck2.default)(this, RegisterView);
    _this = _callSuper(this, RegisterView, [props, context]);
    _this.onRegistered = function (e) {
      _this.setState({
        registered: e
      });
    };
    _this.state = {
      registered: undefined
    };
    return _this;
  }
  (0, _inherits2.default)(RegisterView, _PureComponent);
  return (0, _createClass2.default)(RegisterView, [{
    key: "render",
    value: function render() {
      var registered = this.state.registered;
      if (!registered) return this.renderForm();
      return this.renderSuccessScreen();
    }
  }, {
    key: "renderSuccessScreen",
    value: function renderSuccessScreen() {
      var registered = this.state.registered;
      var size = this.props.size;
      var platformSpecificPadding = _reactNativeWeb.Platform.OS === 'ios' ? 1 : undefined;
      var textStyle = Object.assign({}, _ThemeManager.ThemeManager.style.Body1, {
        textAlign: 'left'
      });
      var mailStyle = Object.assign({}, _ThemeManager.ThemeManager.style.Body1, {
        color: _ThemeManager.ThemeManager.style.brandPrimary,
        fontWeight: 'bold',
        textAlign: 'left'
      });
      var centered = !(size.contentWidth > _ThemeManager.ThemeManager.style.breakpointM);
      if (registered && registered.email) {
        return (0, _jsxRuntime.jsx)(_PageView.PageView, {
          showAccountIcon: false,
          headerProps: {
            leftButtons: [(0, _jsxRuntime.jsx)(_BackButton.default, {}, "backButtonSettingsView")],
            rightButtons: [],
            title: ''
          },
          scrollable: false,
          children: (0, _jsxRuntime.jsx)(_LeftImageOrTopLogoResponsive.LeftImageOrTopLogoResponsive, {
            size: size,
            topPadding: platformSpecificPadding,
            children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
              style: [containerStyle, _ThemeManager.ThemeManager.style.contentStyle, {
                alignItems: centered ? 'center' : 'flex-start',
                alignSelf: centered ? 'center' : 'flex-start'
              }],
              children: [(0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
                type: _MaterialText.MaterialTextTypes.H3,
                centeredBox: centered,
                centeredText: centered,
                children: _I18n.I18n.m.getMessage('registerSuccess', {
                  name: registered.firstname
                })
              }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
                centeredBox: centered,
                centeredText: centered,
                type: _MaterialText.MaterialTextTypes.H6,
                color: _ThemeManager.ThemeManager.style.black42,
                children: _I18n.I18n.m.getMessage('registerPleaseActivate')
              }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
                style: {
                  height: 32
                }
              }), (0, _jsxRuntime.jsxs)(_reactNativeWeb.Text, {
                style: [textStyle, {
                  alignSelf: centered ? 'center' : 'flex-start',
                  textAlign: centered ? 'center' : 'left'
                }],
                children: [_I18n.I18n.m.getMessage('registerSuccessText'), (0, _jsxRuntime.jsx)(_reactNativeWeb.Text, {
                  style: [mailStyle, {
                    alignSelf: centered ? 'center' : 'flex-start',
                    textAlign: centered ? 'center' : 'left'
                  }],
                  children: registered.email
                }), _I18n.I18n.m.getMessage('registerSuccessText2')]
              }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
                style: {
                  alignSelf: centered ? 'center' : 'flex-start',
                  paddingTop: 20
                },
                children: (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
                  title: _I18n.I18n.m.getMessage('registerSuccessButton'),
                  onPress: function onPress() {
                    _Routing.Routing.instance.goTo('/login');
                  }
                })
              })]
            })
          })
        });
      }
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {});
    }
  }, {
    key: "renderForm",
    value: function renderForm() {
      var appVersionInfo = this.props.appVersion;
      var size = this.props.size;
      return (0, _jsxRuntime.jsx)(_LeftImageOrTopLogoResponsive.LeftImageOrTopLogoResponsive, {
        size: size,
        children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: [_ThemeManager.ThemeManager.style.contentStyle, containerStyle],
          children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              width: '100%'
            },
            children: (0, _jsxRuntime.jsx)(_LoginOrRegisterTitle.LoginOrRegisterTitle, {
              active: "register"
            })
          }), (0, _jsxRuntime.jsx)(_RegisterForm.RegisterForm, Object.assign({}, this.props, {
            onRegistered: this.onRegistered
          })), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            type: _MaterialText.MaterialTextTypes.Overline,
            color: "#aaaaaa",
            children: appVersionInfo
          })]
        })
      });
    }
  }]);
}(_react.PureComponent);