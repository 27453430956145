var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LanguagePicker = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _SimpleStorage = require("odatarepos/build/src/db/SimpleStorage");
var _react = _interopRequireWildcard(require("../../../../../upmesh-client/node_modules/react"));
var _connectionContext = _interopRequireDefault(require("../../connectionContext"));
var _userContext = _interopRequireDefault(require("../../userContext"));
var _FormInputPicker = require("../components/forminput/FormInputPicker");
var _LoadingEvents = require("../routing/LoadingEvents");
var _I18n = require("./I18n");
var _jsxRuntime = require("../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var LanguagePicker = exports.LanguagePicker = function (_PureComponent) {
  function LanguagePicker(props, context) {
    var _this;
    (0, _classCallCheck2.default)(this, LanguagePicker);
    _this = _callSuper(this, LanguagePicker, [props, context]);
    _this.lang = ['Deutsch', 'English', 'Nederlands', 'Polski'];
    _this.onChangeLang = function () {
      var _ref = (0, _asyncToGenerator2.default)(function* (value) {
        var _this$props = _this.props,
          onChanged = _this$props.onChanged,
          changeOutside = _this$props.changeOutside;
        _LoadingEvents.LoadingEvents.instance.startLoading();
        var locale = 'de';
        if (value.index === 1) locale = 'en';
        if (value.index === 2) locale = 'nl';
        if (value.index === 3) locale = 'pl';
        yield _SimpleStorage.SimpleStorage.set('locale', locale);
        if (changeOutside === true && onChanged != null) {
          onChanged(locale);
        } else if (locale !== _this.context.locale) {
          yield _I18n.I18n.m.changeLanguage(locale);
          if (onChanged != null) onChanged(locale);
        }
        _this.setState({
          langIndex: value.index
        }, function () {
          _LoadingEvents.LoadingEvents.instance.stopLoading();
        });
      });
      return function (_x) {
        return _ref.apply(this, arguments);
      };
    }();
    var langIndex = 0;
    if (_I18n.I18n.m.currentLanguage === 'en') langIndex = 1;
    if (_I18n.I18n.m.currentLanguage === 'nl') langIndex = 2;
    if (_I18n.I18n.m.currentLanguage === 'pl') langIndex = 3;
    _this.state = {
      langIndex: langIndex
    };
    return _this;
  }
  (0, _inherits2.default)(LanguagePicker, _PureComponent);
  return (0, _createClass2.default)(LanguagePicker, [{
    key: "render",
    value: function render() {
      var _this2 = this;
      var langIndex = this.state.langIndex;
      var labelText = this.props.labelText;
      return (0, _jsxRuntime.jsx)(_connectionContext.default.Consumer, {
        children: function children(connection) {
          return (0, _jsxRuntime.jsx)(_FormInputPicker.FormInputPicker, {
            labelText: labelText,
            disabled: !connection.connectedToServer,
            selectedIndex: langIndex,
            list: _this2.lang,
            onChange: _this2.onChangeLang,
            helperText: !connection.connectedToServer ? _I18n.I18n.m.getMessage('commandOfflineNotPossible') : ''
          });
        }
      });
    }
  }]);
}(_react.PureComponent);
LanguagePicker.contextType = _userContext.default;