import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Form } from 'materialTheme/src/theme/components/forminput/Form';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { CreateFolder } from 'upmesh-core/src/client/commands/folder/CreateFolder';
import { FolderEntity } from 'upmesh-core/src/client/query/entities/FolderEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { FolderAccessComponent } from './FolderAccessComponent';
import { FolderTagsComponent } from './FolderTagsComponent';
export class CreateFolderDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.onSaved = () => {
            Dialog.instance?.close();
        };
        this.accessComponent = (props) => {
            const { folder } = this.state;
            return <FolderAccessComponent folder={folder} {...props}/>;
        };
        this.tagsComponent = (props) => {
            const { folder } = this.state;
            return <FolderTagsComponent folder={folder} {...props}/>;
        };
        const { folderId, projectId } = this.props;
        const folder = new FolderEntity({
            title: '',
            projectId,
            access: [],
            tags: [],
            subFolderFromId: folderId != null ? folderId : null,
            description: '',
        });
        this.state = { init: false, folder, maxLength: FolderEntity.maxPathLength };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
    }
    async init() {
        const { folder } = this.state;
        let maxLength = FolderEntity.maxPathLength;
        if (folder.subFolderFromId != null && folder.subFolderFromId.length > 0) {
            const mainFolders = await UpmeshClient.instance.modals.folder.getById(folder.subFolderFromId);
            folder.access = mainFolders.access;
            const parentLength = await FolderEntity.getPathLength(folder.subFolderFromId);
            if (parentLength != null && parentLength.pathLength > 0)
                maxLength -= parentLength.pathLength;
        }
        this.setState({ init: true, folder, maxLength });
    }
    static openDialog(props) {
        Dialog.instance?.open({
            content: <CreateFolderDialog {...props}/>,
            fullscreenResponsive: true,
            contentPadding: false,
            scrollable: false,
            showCloseIcon: true,
        });
    }
    render() {
        const { init, maxLength } = this.state;
        if (!init)
            return <Spinner />;
        const { projectId, subFolderFromId } = this.state.folder;
        const command = new CreateFolder({
            title: '',
            projectId,
            access: [],
            tags: [],
            subFolderFromId,
            description: '',
        });
        const dataOptions = new Map();
        dataOptions.set('title', {
            formType: 'string',
            cols: 1,
            props: { labelText: I18n.m.getMessage('folderName'), maxLength },
        });
        dataOptions.set('description', {
            formType: 'string',
            cols: 1,
            props: { labelText: I18n.m.getMessage('folderDescription'), numberOfLines: 4 },
        });
        dataOptions.set('tags', {
            formType: 'individiual',
            cols: 1,
            props: { labelText: I18n.m.getMessage('tags'), numberOfLines: 4 },
            component: this.tagsComponent,
        });
        dataOptions.set('access', {
            formType: 'individiual',
            cols: 1,
            props: { labelText: I18n.m.getMessage('folderAccess'), numberOfLines: 4 },
            component: this.accessComponent,
        });
        return (<Form baseCols={1} stickyHeader stickyActions showButtonsIfOffline command={command} dataOptions={dataOptions} saveButtonLabel={I18n.m.getMessage('create')} onSaved={this.onSaved} store={ClientStore.commandStore} formHeaderProps={{ formTitle: I18n.m.getMessage('createFolder') }} additionalButtons={[
                <ContainedButton key="abort" title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close} backgroundColor="#FFFFFF" textColor={ThemeManager.style.brandPrimary}/>,
            ]}/>);
    }
}
