import { OpenableChip } from 'materialTheme/src/theme/components/chips/OpenableChip';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { ChangeTicketCraft } from 'upmesh-core/src/client/commands/tickets/ChangeTicketCraft';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
export class CraftChip extends PureComponent {
    constructor(props) {
        super(props);
        this.init = async () => {
            const { ticket } = this.props;
            const project = await UpmeshClient.instance.modals.project.getById(ticket.projectId);
            const { crafts } = project;
            const c = new ChangeTicketCraft({ craft: ticket.craft == null ? null : ticket.craft }, ticket.id);
            try {
                const canI = await c.canI();
                if (typeof canI === 'boolean' && canI) {
                    this.setState({ crafts, disabledChip: false });
                }
                else {
                    this.setState({ crafts, disabledChip: true });
                }
            }
            catch (e) {
                this.setState({ crafts, disabledChip: true });
            }
        };
        this.onChangeType = async (e) => {
            const { errorResult } = this.props;
            const { ticket } = this.props;
            try {
                const c = new ChangeTicketCraft({ craft: e == null || e === 0 ? null : e }, ticket.id);
                await c.execute();
            }
            catch (err) {
                errorResult(err);
            }
        };
        this.state = { disabledChip: true, crafts: [] };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
    }
    static craftList(crafts) {
        const list = [];
        crafts?.forEach((s) => {
            list.push({
                title: s,
                onPressChipData: s,
                textColor: ThemeManager.style.defaultTextColor,
            });
        });
        list.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
        return list;
    }
    render() {
        const { labelStyle, ticket } = this.props;
        const { crafts } = this.state;
        if (crafts == null || crafts.length === 0)
            return null;
        let currentTypeIndex = -1;
        const craftList = CraftChip.craftList(crafts);
        for (let i = 0; i < craftList.length; i += 1) {
            if (craftList[i].onPressChipData === ticket.craft) {
                currentTypeIndex = i;
                break;
            }
        }
        return [
            <View key="craftLabel" style={labelStyle}>
        <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption} numberOfLines={1}>
          {I18n.m.getMessage('craft')}
        </MaterialText>
      </View>,
            <OpenableChip key="craftChip" showDeleteIcon emptyStateChip={{
                    title: I18n.m.getMessage('ticketsCraftNotSelected'),
                    onPressChipData: 0,
                    textColor: ThemeManager.style.defaultTextColor,
                }} dialogTitle={I18n.m.getMessage('craft')} accessibilityLabel="craft" elevation={40} selected={currentTypeIndex} onPressChip={this.onChangeType} widthOpen={256} chipsList={craftList} chipDisabled={this.state.disabledChip}/>,
        ];
    }
}
