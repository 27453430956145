import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { SegmentedButton } from 'materialTheme/src/theme/components/button/SegmentedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import React, { PureComponent } from 'react';
import { FlatList, ScrollView, UIManager, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { AcceptInvitation } from 'upmesh-core/src/client/commands/user/AcceptInvitation';
import { convertProject, ProjectFilter, } from 'upmesh-core/src/client/query/filter/ProjectFilter';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { DateLocale } from 'upmesh-i18n/src/i18n/DateLocale';
import { I18n } from '../../i18n/I18n';
import { openProjectsFilterDialog } from '../project/ProjectsDialogFilter';
import { AppBar } from './AppBar';
import { CompanyUserInfo } from './CompanyUserInfo';
import { GlobalBar } from './GlobalBar';
import { HomeProjectsGanttChartView } from './HomeProjectsGanttChartView';
import { ProjectListItem } from './ProjectListItem';
import { ProjectsOnMap } from './ProjectsOnMap';
import { ProjectTable } from './ProjectTable';
export class HomeProjectsView extends PureComponent {
    constructor(props) {
        super(props);
        this.mounted = true;
        this.filteredProjects = [];
        this.filter = new ProjectFilter();
        this.acceptInvitation = (id) => async (_e) => {
            try {
                await LoadingEvents.instance.startLoading();
                const getPU = await UpmeshClient.instance.modals.projectUser.getById(id);
                const acceptInvitation = new AcceptInvitation({}, id);
                await acceptInvitation.execute();
                const count = async () => UpmeshClient.instance.modals.project.count(`id eq '${getPU.projectId}'`);
                let timeout = 0;
                while ((await count()) === 0 && timeout < 20) {
                    await new Promise((r) => {
                        timeout += 1;
                        setTimeout(r, 500);
                    });
                }
                await this.waitForSync();
                await LoadingEvents.instance.stopLoading();
                requestAnimationFrame(() => {
                    Routing.instance.goTo(`projects/${getPU.projectId}/project`);
                });
            }
            catch (e) {
                ErrorReporter.sendReport({
                    data: e,
                    subject: e.messageCode != null ? e.messageCode : 'cant accept invitation',
                    type: 'warn',
                });
                await LoadingEvents.instance.stopLoading();
            }
        };
        this.waitForSync = async () => {
            if (AuthClient.instance != null && AuthClient.instance.serverConnection != null) {
                await new Promise((resolve) => {
                    AuthClient.instance.syncDispatcher.syncStop.once(() => {
                        resolve();
                    });
                    if (AuthClient.instance != null) {
                        AuthClient.instance.startSync().catch((err) => console.debug(err));
                    }
                    else {
                        resolve();
                    }
                });
            }
        };
        this.onRouteChange = (_e) => {
        };
        this.openFab = () => {
            if (Fab.instance == null) {
                window.setTimeout(this.openFab, 100);
                return;
            }
            Fab.instance.open({
                fabIcon: 'plus',
                fabIconOpen: 'close',
                small: false,
                fabColor: ThemeManager.style.brandPrimary,
                fabColorOpen: ThemeManager.style.brandSecondary,
                title: I18n.m.getMessage('tooltipCreateNewProject'),
                onPressFab: this.pressCreateProject,
                extraPaddingBottom: ResizeEvent.current.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0,
            });
        };
        this.getfilteredProjects = () => {
            const allProjects = this.filteredProjects;
            const filteredProjects = ProjectFilter.filterProjects(allProjects, this.filter);
            return [...filteredProjects];
        };
        this.filterProjectsState = () => {
            const projects = this.getfilteredProjects();
            this.setState({ projects });
        };
        this.changeView = (e) => {
            Menu.instance?.close();
            this.setState({ viewNumber: e }, () => {
                SimpleStorage.set('allProjectsSelectedView', e.toString(10));
            });
        };
        this.openViewMenu = (e) => {
            const { target } = e.nativeEvent;
            UIManager.measureInWindow(target, async (x, y, _width, height) => {
                const client = {
                    x,
                    y,
                    height,
                    width: 320,
                };
                Menu.instance?.open({
                    client,
                    items: [
                        {
                            text: I18n.m.getMessage('ticketsDetailsViewChangeToListView'),
                            onPress: () => this.changeView(0),
                            thumbnail: {
                                thumbnail: <Icon toolTip="" icon="format-list-bulleted-square"/>,
                                width: 40,
                            },
                        },
                        {
                            text: I18n.m.getMessage('ticketsDetailsViewChangeToTableView'),
                            onPress: () => this.changeView(1),
                            thumbnail: {
                                thumbnail: <Icon toolTip="" icon="table-large"/>,
                                width: 40,
                            },
                        },
                        {
                            text: I18n.m.getMessage('ticketsDetailsViewChangeToGantt'),
                            onPress: () => this.changeView(2),
                            thumbnail: {
                                thumbnail: <Icon toolTip="" icon="chart-gantt"/>,
                                width: 40,
                            },
                        },
                    ],
                });
            });
        };
        this.getViewIcon = () => {
            const { viewNumber } = this.state;
            let icon = {
                icon: 'format-list-bulleted-square',
                rotation: 0,
                iconIconMoon: false,
                toolTip: I18n.m.getMessage('ticketsDetailsViewChangeToListView'),
            };
            if (viewNumber === 1) {
                icon = {
                    icon: 'table-large',
                    rotation: 0,
                    iconIconMoon: false,
                    toolTip: I18n.m.getMessage('ticketsDetailsViewChangeToTableView'),
                };
            }
            else if (viewNumber === 2) {
                icon = {
                    icon: 'chart-gantt',
                    rotation: 0,
                    toolTip: I18n.m.getMessage('ticketsDetailsViewChangeToGantt'),
                };
            }
            icon.onPress = this.openViewMenu;
            icon.toolTip = I18n.m.getMessage('changeView');
            return icon;
        };
        this.renderInvitationRow = ({ item }) => (<Card style={{ padding: ThemeManager.style.getScreenRelativePixelSize(8) }}>
      <View style={{
                width: '100%',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
        <View style={{ maxWidth: '100%' }}>
          <MaterialText>{I18n.m.getMessage('invitationMessage', item)}</MaterialText>
        </View>
        <View style={{ width: 10, height: 1 }}/>
        <View style={{ alignSelf: 'flex-end' }}>
          <ContainedButton title={I18n.m.getMessage('invitationAccept')} onPress={this.acceptInvitation(item.id)} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
        </View>
      </View>
    </Card>);
        this.searching = false;
        this.searchProjects = (text) => {
            if (this.searchTimer != null) {
                clearTimeout(this.searchTimer);
            }
            this.searchTimer = window.setTimeout(this.searchNow(text), 250);
        };
        this.searchNow = (text) => async () => {
            if (this.searching) {
                this.searchTimer = window.setTimeout(this.searchNow(text), 100);
                return;
            }
            this.searching = true;
            this.searching = false;
            this.filter.words = text;
            Routing.instance.changeQueryParameter({ f: JSON.stringify(this.filter) });
            this.setState({ searchWords: text });
        };
        this.lastQuery = '';
        this.getItemKey = (item, _index) => `project_${item.id}`;
        this.handleChange = (_changed) => {
            this.init().catch((e) => console.debug('cant update projects', e));
        };
        this.init = async () => {
            await DateLocale.waitFor(() => CurrentUser.entity != null, 1000, 15000);
            if (CurrentUser.entity == null) {
                Routing.instance.goTo('/login');
                return;
            }
            const projects1 = await UpmeshClient.instance.modals.project.get({
                filter: 'deleted ne true and inCreation ne true',
                orderby: 'title',
            });
            const projects = [];
            for (const p of projects1) {
                const pe = await convertProject(p, CompanyUserInfo.company?.id);
                projects.push(pe);
            }
            const invitations = await UpmeshClient.instance.modals.projectUser.get({
                filter: `userId eq '${CurrentUser.userId}' and status eq 'invited'`,
            });
            this.filteredProjects = projects;
            const filteredProjects = this.getfilteredProjects();
            const sm = await SimpleStorage.get('allProjectsShowMap');
            const showMap = sm != null && sm === '1';
            const view = await SimpleStorage.get('allProjectsSelectedView');
            const viewNumber = view != null && typeof Number.parseInt(view, 10) === 'number' ? Number.parseInt(view, 10) : 0;
            if (this.mounted) {
                this.setState({
                    invitations,
                    projects: filteredProjects,
                    allProjects: projects,
                    init: true,
                    showMap,
                    viewNumber,
                });
            }
        };
        this.onLayoutInvitations = (e) => {
            this.setState({ invitationsHeight: e.nativeEvent.layout.height });
        };
        this.pressCreateProject = async (e) => {
            Routing.instance.openDialog('createProject')(e);
        };
        this.pressFilterProject = async () => {
            const { allProjects } = this.state;
            if (allProjects != null) {
                openProjectsFilterDialog(allProjects, this.filter);
            }
        };
        this.filter = new ProjectFilter();
        if (props.f != null) {
            try {
                this.filter = JSON.parse(props.f);
            }
            catch (e) {
                console.debug('cant read ProjectFilter', e);
            }
        }
        this.state = {
            init: false,
            invitations: [],
            projects: [],
            allProjects: [],
            invitationsHeight: 0,
            searchWords: this.filter.words != null ? this.filter.words : '',
            viewNumber: 0,
            showMap: false,
        };
    }
    componentDidMount() {
        this.mounted = true;
        this.init().catch((err) => console.debug('cant init HomeProjectsView', err));
        this.projectAttachKey = UpmeshClient.eventDispatcher.attach({
            callback: this.handleChange,
            readModelName: 'Project',
        });
        this.invitationAttachKey = UpmeshClient.eventDispatcher.attach({
            callback: this.handleChange,
            readModelName: 'ProjectUser',
        });
        Routing.instance.routeChanged.attach(this.onRouteChange);
        this.openFab();
    }
    componentWillUnmount() {
        this.mounted = false;
        Routing.instance.routeChanged.detach(this.onRouteChange);
        if (this.projectAttachKey != null) {
            UpmeshClient.eventDispatcher.detach('Project', this.projectAttachKey);
            this.projectAttachKey = undefined;
        }
        if (this.invitationAttachKey != null) {
            UpmeshClient.eventDispatcher.detach('ProjectUser', this.invitationAttachKey);
            this.invitationAttachKey = undefined;
        }
        if (Fab.instance != null) {
            Fab.instance.close();
        }
    }
    renderContent(contentHeight) {
        const { viewNumber, invitationsHeight, projects } = this.state;
        if (viewNumber === 0) {
            return (<ScrollView style={{
                    width: '100%',
                    height: contentHeight - ThemeManager.style.headerHeight - invitationsHeight,
                }}>
          <View style={{ width: '100%' }}>{projects.map((item) => HomeProjectsView.renderRow({ item }))}</View>
        </ScrollView>);
        }
        if (viewNumber === 1) {
            return (<Card style={{
                    height: contentHeight - ThemeManager.style.headerHeight - invitationsHeight - 16,
                }}>
          <ProjectTable projects={projects} tableName="allProjects" height={contentHeight - ThemeManager.style.headerHeight - invitationsHeight - 32}/>
        </Card>);
        }
        if (viewNumber === 2) {
            return (<Card style={{
                    height: contentHeight - ThemeManager.style.headerHeight - invitationsHeight - 16,
                }}>
          <HomeProjectsGanttChartView height={contentHeight - ThemeManager.style.headerHeight - invitationsHeight - 16} projects={projects}/>
        </Card>);
        }
        return null;
    }
    render() {
        const { searchWords, invitationsHeight, viewNumber, projects, invitations, showMap } = this.state;
        const { children } = this.props;
        let { contentHeight } = this.props.size;
        const isProjectFilterActive = this.filter.projectTime != null ||
            (this.filter.projectCreators != null && this.filter.projectCreators.length > 0) ||
            (this.filter.projectTypes != null && this.filter.projectTypes.length > 0) ||
            (this.filter.projectMembers != null && this.filter.projectMembers.length > 0) ||
            (this.filter.projectStates != null && this.filter.projectStates.length > 0) ||
            this.filter.buildTime != null ||
            this.filter.showTemplate != null ||
            this.filter.archived === true ||
            this.filter.myProjects === CurrentUser.userId;
        const sDisplay = !(ResizeEvent.current.windowWidth > ThemeManager.style.breakpointM);
        if (!sDisplay)
            contentHeight -= ThemeManager.style.headerHeight;
        else
            contentHeight -= ThemeManager.style.getScreenRelativePixelSize(48);
        const rightIcons = [
            <Icon toolTip={I18n.m.getMessage('tooltopFilterProjects')} key="filterProjects" color={isProjectFilterActive ? ThemeManager.style.brandPrimary : 'white'} onPress={this.pressFilterProject} icon={isProjectFilterActive ? 'filter-remove' : 'filter-outline'}/>,
        ];
        if (!sDisplay) {
            rightIcons.push(<Icon toolTip={!showMap ? I18n.m.getMessage('showMap') : I18n.m.getMessage('hideMap')} key={`showMap_${showMap}`} color="#FFFFFF" onPress={() => this.setState({ showMap: !showMap }, () => {
                    SimpleStorage.set('allProjectsShowMap', !showMap ? '1' : '0');
                })} icon={!showMap ? 'map-outline' : 'map-check-outline'}/>);
            rightIcons.push(<View style={{ width: 198, paddingHorizontal: 4 }} key="viewSegmentButton">
          <SegmentedButton buttons={[
                    { icon: { icon: 'format-list-bulleted-square' }, key: 'list' },
                    { icon: { icon: 'table-large' }, key: 'table' },
                    { icon: { icon: 'chart-gantt' }, key: 'gantt' },
                ]} onPress={this.changeView} singleSelectSelected={viewNumber} backgroundColor="transparent" borderColor="#ffffff" textColor="#ffffff" selectedColor="rgba(196, 196, 196, 0.2)" density={2}/>
        </View>);
        }
        else {
            rightIcons.push(<Icon {...this.getViewIcon()} key="viewIcon" color="#ffffff"/>);
        }
        return (<View style={{
                width: '100%',
                backgroundColor: 'transparent',
                position: 'absolute',
                right: 0,
                left: 0,
                top: 0,
                bottom: 0,
            }}>
        {!sDisplay ? <GlobalBar user={CurrentUser.entity} size={this.props.size} site="projects"/> : undefined}

        <View style={{ maxHeight: '50%' }} onLayout={this.onLayoutInvitations}>
          <FlatList key="invitations" data={invitations} renderItem={this.renderInvitationRow}/>
        </View>
        {children}
        <View style={{
                borderTopColor: 'transparent',
                borderTopWidth: ThemeManager.style.borderWidth,
            }}/>
        <AppBar textColor="#FFFFFF" backgroundColor="transparent" showAccountIcon={sDisplay} searchBarProps={{
                backgroundColor: 'transparent',
                textColor: '#ffffff',
                iconColor: '#ffffff',
                hoverColor: ThemeManager.style.getDefaultHoverColor('#ffffff'),
                searchBarPlaceholder: I18n.m.getMessage('myProjects'),
                searchOnChange: this.searchProjects,
                tooltip: I18n.m.getMessage('searchProjects'),
                onlyAppBar: true,
                searchBarValue: searchWords,
            }} leftButtons={[]} withElevation={false} withBorder={false} rightButtons={rightIcons}/>
        <View style={{ width: '100%' }}>
          
          {projects.length > 0 ? (showMap ? (<View style={{ width: '100%', flexDirection: 'row' }}>
                <View style={{ width: '50%', flex: 1 }}>{this.renderContent(contentHeight)}</View>
                <ProjectsOnMap projects={projects} mapHeight={contentHeight - ThemeManager.style.headerHeight - invitationsHeight - 16} style={{
                    borderRadius: ThemeManager.style.borderRadius,
                    overflow: 'hidden',
                    marginTop: 8,
                    marginRight: 16,
                    marginBottom: 16,
                }}/>
              </View>) : (<View style={{ width: '100%' }}>{this.renderContent(contentHeight)}</View>)) : (<View style={{
                    marginLeft: 10,
                }}>
              <MaterialText color="white">{I18n.m.getMessage('noProjectsFound')}</MaterialText>
            </View>)}
        </View>
      </View>);
    }
    componentDidUpdate(_prevProps, _prevState, _prevContext) {
        if (_prevProps.f !== this.props.f) {
            this.filter = new ProjectFilter();
            if (this.props.f != null) {
                try {
                    this.filter = JSON.parse(this.props.f);
                    SimpleStorage.set(`${CurrentUser.userId}_saved_projectfilter`, this.props.f);
                }
                catch (e) {
                    console.debug('cant read ProjectFilter', e);
                }
            }
            this.filterProjectsState();
        }
    }
    getQuery() {
        return this.lastQuery;
    }
}
HomeProjectsView.renderRow = ({ item }) => {
    return (<ProjectListItem key={`project_${item.id}`} project={item} textColor="#FFFFFF" hoverColor="rgba(255,255,255,0.11)"/>);
};
