import { PromisePool } from 'cqrs-shared/src/PromisePool';
import { Url } from 'cqrs-shared/src/uri/Url';
import { WaitFor } from 'cqrs-shared/src/WaitFor';
import ConnectionContext from 'materialTheme/src/connectionContext';
import { Uploads } from 'materialTheme/src/file/upload/Uploads';
import { DeviceManager } from 'materialTheme/src/securestore/DeviceManager';
import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { DialogUp } from 'materialTheme/src/theme/components/DialogUp';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { ListItem } from 'materialTheme/src/theme/components/ListItem';
import { ProgressBar } from 'materialTheme/src/theme/components/ProgressBar';
import { SearchBar } from 'materialTheme/src/theme/components/SearchBar';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { StdApplicationError } from 'odatarepos/src/entities/StdApplicationError';
import React, { PureComponent } from 'react';
import { BackHandler, Keyboard, ScrollView, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { CopyJournalToStoredFile } from 'upmesh-core/src/client/commands/storedfile/CopyJournalToStoredFile';
import { LinkStoredFileToTicket } from 'upmesh-core/src/client/commands/storedfile/LinkStoredFileToTicket';
import { MoveStoredFile } from 'upmesh-core/src/client/commands/storedfile/MoveStoredFile';
import { MoveStoredFileToNewPlan } from 'upmesh-core/src/client/commands/storedfile/MoveStoredFileToNewPlan';
import { MoveStoredFileToNewPlanVersion } from 'upmesh-core/src/client/commands/storedfile/MoveStoredFileToNewPlanVersion';
import { CreateTicket } from 'upmesh-core/src/client/commands/tickets/CreateTicket';
import { FolderEntity } from 'upmesh-core/src/client/query/entities/FolderEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { OfflineDataDownloader } from '../../repo/file/OfflineDataDownloader';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { PlanSelector } from '../selectors/PlanSelector';
import { ProjectSelector } from '../selectors/ProjectSelector';
import { TicketSelector } from '../selectors/TicketSelector';
import { SharedFiles } from './SharedFiles';
class FolderEntityWithFlatTitle extends FolderEntity {
}
export class AddFilesPU extends PureComponent {
    constructor(props) {
        super(props);
        this.init = async () => {
            await WaitFor.instance.waitFor(() => AuthClient.instance.ready);
            if (AuthClient.instance.ready) {
                await WaitFor.instance.waitFor(() => CurrentUser.userId != null);
                const { data } = this.props;
                if (data != null && SharedFiles.count > 0) {
                    if (data.projectId != null && data.projectId.length > 0 && data.entityId != null && data.entityId.length > 0) {
                        const folders = await this.getProjectFolder(data.projectId);
                        const ticket = await UpmeshClient.instance.modals.ticket.getById(data.entityId);
                        const canUploadAsPlan = await this.canBeUploadedAsPlan(data.projectId);
                        this.setState({
                            folders,
                            isLoading: false,
                            projectId: data.projectId,
                            type: data.type,
                            entityId: data.entityId,
                            canUploadAsPlan,
                        }, () => {
                            this.selectTicket(ticket).catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
                        });
                    }
                    else if (data.projectId != null && data.projectId.length > 0) {
                        const canUploadAsPlan = await this.canBeUploadedAsPlan(data.projectId);
                        const folders = await this.getProjectFolder(data.projectId);
                        this.setState({
                            folders,
                            isLoading: false,
                            projectId: data.projectId,
                            type: data.type,
                            entityId: undefined,
                            canUploadAsPlan,
                        });
                    }
                    else {
                        this.setState({
                            isLoading: false,
                            projectId: undefined,
                            type: data.type,
                            entityId: undefined,
                        });
                    }
                }
                else if (data == null && SharedFiles.count > 0) {
                    this.setState({ isLoading: false, projectId: undefined, type: undefined, entityId: undefined });
                }
                else {
                    DialogUp.instance?.close();
                }
            }
        };
        this.onLayout = (e) => {
            let width = e.nativeEvent.layout.width > 0 ? e.nativeEvent.layout.width : 0.9 * ResizeEvent.current.windowWidth;
            width = Math.min(width, 300);
            this.setState({ maxWidth: width });
        };
        this.selectTicket = async (ticket) => {
            const alertText = ticket === 'new'
                ? I18n.m.getMessage('ticketsCreateNewTicketQuestion')
                : SharedFiles.count > 1
                    ? I18n.m.getMessage('ticketsAddFiles', {
                        counted: SharedFiles.count,
                        ticketTitle: `${ticket.title} (#${ticket.ticketNumber})`,
                    })
                    : I18n.m.getMessage('ticketsAddFile', { ticketTitle: `${ticket.title} (#${ticket.ticketNumber})` });
            Routing.instance.alert.post({
                text: alertText,
                buttons: [
                    <ContainedButton key="abort" title={I18n.m.getMessage('no')} onPress={Alert.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
                    <ContainedButton key="save" title={I18n.m.getMessage('yes')} onPress={this.assignToTicket(ticket)}/>,
                ],
            });
        };
        this.selectPlan = async (plan) => {
            const alertText = plan === 'new'
                ? I18n.m.getMessage('plansCreateNewPlanQuestion')
                : SharedFiles.count > 1
                    ? I18n.m.getMessage('plansAddFiles', {
                        counted: SharedFiles.count,
                        ticketTitle: `${plan.title} `,
                    })
                    : I18n.m.getMessage('plansAddFile', { planTitle: `${plan.title}` });
            Routing.instance.alert.post({
                text: alertText,
                buttons: [
                    <ContainedButton key="abort" title={I18n.m.getMessage('no')} onPress={Alert.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
                    <ContainedButton key="save" title={I18n.m.getMessage('yes')} onPress={this.assignToPlan(plan)}/>,
                ],
            });
        };
        this.assignToTicket = (ticket) => (_e) => {
            Alert.instance?.close(async () => {
                try {
                    const t = ticket === 'new' ? await this.createTicket() : ticket;
                    if (SharedFiles.fileIds != null && SharedFiles.fileIds.length > 0) {
                        const promiseData = [];
                        for (const fileId of SharedFiles.fileIds) {
                            promiseData.push({ fileId, ticket: t });
                        }
                        SharedFiles.fileIds = null;
                        await PromisePool.run({
                            collection: promiseData,
                            maxConcurrency: 2,
                            task: this.addStoredFileToTicket,
                        });
                        DialogUp.instance?.close(() => {
                            requestAnimationFrame(() => {
                                Routing.instance.openDialog('ticket', { id: t.id }, false)(null);
                            });
                        });
                    }
                    else {
                        DialogUp.instance?.close(() => {
                            requestAnimationFrame(() => {
                                Routing.instance.openDialog('ticket', { id: t.id, addFiles: true }, false)(null);
                            });
                        });
                    }
                }
                catch (e) {
                    DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m);
                }
            });
        };
        this.assignToPlan = (plan) => (_e) => {
            const file = SharedFiles.files != null && SharedFiles.files.length === 1
                ? SharedFiles.files[0]
                : SharedFiles.fileIds != null && SharedFiles.fileIds.length === 1
                    ? SharedFiles.fileIds[0]
                    : undefined;
            Alert.instance?.close(() => {
                try {
                    if (file == null) {
                        return;
                    }
                    const { projectId } = this.state;
                    DialogUp.instance?.close(async () => {
                        try {
                            LoadingEvents.instance.startLoading();
                            if (plan === 'new') {
                                if (typeof file === 'string') {
                                    const c = new MoveStoredFileToNewPlan({}, file);
                                    await c.execute();
                                }
                                else {
                                    const data = { projectId };
                                    await Uploads.addUpload(`${UpmeshClient.instance.url}/planfiles/upload/`, file, `${data.projectId}_plan_upload`, data);
                                }
                                LoadingEvents.instance.stopLoading();
                                requestAnimationFrame(() => {
                                    Routing.instance.goTo(`/projects/${projectId}/plans/?d=tempPlans&data=projectId%3D${projectId}`);
                                });
                            }
                            else {
                                const data = { projectId: plan.projectId, planId: plan.id };
                                if (typeof file === 'string') {
                                    const c = new MoveStoredFileToNewPlanVersion({ planId: data.planId }, file);
                                    await c.execute();
                                }
                                else {
                                    await Uploads.addUpload(`${UpmeshClient.instance.url}/planfiles/version`, file, `planVersion_${data.planId}`, data);
                                }
                                LoadingEvents.instance.stopLoading();
                                requestAnimationFrame(() => {
                                    Routing.instance.goTo(`/projects/${data.projectId}/plans/${data.planId}/versions`);
                                });
                            }
                        }
                        catch (e) {
                            LoadingEvents.instance.stopLoading();
                            DefaultErrorHandler.showDefaultErrorAlert(e);
                        }
                    });
                }
                catch (e) {
                    DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m);
                }
            });
        };
        this.createTicket = async () => {
            const { projectId } = this.state;
            if (projectId == null)
                throw StdApplicationError.notFound();
            const title = I18n.m.getMessage('ticketsDetailsTitlePlaceholder');
            try {
                const c = new CreateTicket({
                    title,
                    projectId,
                });
                await c.execute();
                const count = async () => UpmeshClient.instance.modals.ticket.count(`id eq '${c.entityId}'`);
                let timeout = 0;
                while ((await count()) === 0 && timeout < 20) {
                    await new Promise((r) => {
                        timeout += 1;
                        setTimeout(r, 500);
                    });
                }
                return UpmeshClient.instance.modals.ticket.getById(c.entityId);
            }
            catch (e) {
                console.error('cant createTicket', e);
                throw e;
            }
            throw StdApplicationError.notFound();
        };
        this.addStoredFileToTicket = async (fnData) => {
            if (fnData.ticket == null) {
                return;
            }
            const { data } = this.props;
            const { connectedToServer } = this.context;
            let e;
            if (this.state.source === 'Journal') {
                e = new CopyJournalToStoredFile({ folder: '', deviceId: DeviceManager.deviceId, forEntityId: fnData.ticket.id, forEntity: 'Ticket' }, fnData.fileId);
            }
            else if (!connectedToServer) {
                const storedFile = await UpmeshClient.instance.modals.storedFile.getById(fnData.fileId);
                const realPath = OfflineDataDownloader.isMediaSynced(storedFile.projectId, storedFile.id, storedFile.getFileId()) ?? '';
                if (!(realPath != null && realPath.length > 0)) {
                    Routing.instance.alert.post({ text: I18n.m.getMessage('ticketCopyFileToTicketErrorOffline') });
                    return;
                }
                const tmpFile = await Uploads.addUpload(`${UpmeshClient.instance.url}/storedFile/file/`, { path: realPath, filename: storedFile.orgFilename }, storedFile.type ?? '', { projectId: fnData.ticket.projectId, folder: '', forEntityId: fnData.ticket.id, forEntity: 'Ticket' });
                e = new LinkStoredFileToTicket({
                    wasOffline: true,
                    clientCreation: new Date(),
                    forEntityId: fnData.ticket.id,
                    copyFile: false,
                }, tmpFile.id);
            }
            else {
                e = new LinkStoredFileToTicket({
                    wasOffline: false,
                    clientCreation: new Date(),
                    forEntityId: fnData.ticket.id,
                    copyFile: data.copyFile === true,
                }, fnData.fileId);
            }
            try {
                await e.execute();
            }
            catch (err) {
                console.debug('cant link file to ticket', fnData, err);
            }
        };
        this.getProjectFolder = async (projectId) => {
            try {
                const folders = await UpmeshClient.instance.modals.folder.get({
                    filter: `projectId eq '${projectId}' and deleted ne true`,
                    orderby: 'title ASC',
                });
                const f = [];
                for (const tmp of folders) {
                    try {
                        if (await FolderEntity.hasFolderWriteRights(tmp.id, CurrentUser.userId)) {
                            f.push(this.getProjectFolderFlatTitle(tmp, folders));
                        }
                    }
                    catch (e) {
                    }
                }
                return f;
            }
            catch (err) {
                DefaultErrorHandler.showDefaultErrorAlert(err);
                DialogUp.instance?.close();
                return [];
            }
        };
        this.selectProject = (p) => {
            this.getProjectFolder(p.id)
                .then(async (f) => {
                const canUploadAsPlan = await this.canBeUploadedAsPlan(p.id);
                this.setState({ projectId: p.id, folders: f, canUploadAsPlan });
            })
                .catch((err) => {
                DefaultErrorHandler.showDefaultErrorAlert(err);
                DialogUp.instance?.close();
            });
        };
        this.selectType = (newType) => (_e) => {
            this.setState({ type: newType });
        };
        this.selectFolder = (folder) => (_e) => {
            const { isLoading } = this.state;
            if (!isLoading) {
                this.setState({ isLoading: true }, () => {
                    this.moveToFolder(folder).catch((err) => {
                        console.debug(err);
                        DefaultErrorHandler.showDefaultErrorAlert(err);
                    });
                });
            }
        };
        this.moveToFolder = async (folder) => {
            const { projectId } = this.state;
            if (SharedFiles.files != null && SharedFiles.files.length > 0 && projectId != null) {
                const url = Url.getURLfromString(`${UpmeshClient.instance.url}/storedfile/file/`);
                for (const f of SharedFiles.files) {
                    try {
                        await Uploads.addUpload(url.href, f, `${projectId}_documents_upload`, { projectId, folder });
                    }
                    catch (e) {
                        DefaultErrorHandler.showDefaultErrorAlert(e);
                    }
                }
                DialogUp.instance?.close(() => {
                    requestAnimationFrame(() => {
                        Routing.instance.goTo(`/projects/${projectId}/files/${folder === '' ? 'private' : folder}`);
                    });
                });
            }
            else if (SharedFiles.fileIds != null && projectId != null) {
                this.setState({ movedFiles: 0, toBeMovedFiles: SharedFiles.fileIds.length });
                let error;
                let i = 0;
                for (const f of SharedFiles.fileIds) {
                    try {
                        if (this.state.source === 'Journal') {
                            const c = new CopyJournalToStoredFile({ folder, deviceId: DeviceManager.deviceId }, f);
                            await c.execute();
                        }
                        else {
                            const c = new MoveStoredFile({ folder }, f);
                            await c.execute();
                        }
                    }
                    catch (e) {
                        error = e;
                    }
                    i += 1;
                    this.setState({ movedFiles: i });
                }
                SharedFiles.fileIds = null;
                DialogUp.instance?.close(() => {
                    requestAnimationFrame(() => {
                        Routing.instance.goTo(`/projects/${projectId}/files/${folder === '' ? 'private' : folder}`);
                    });
                    if (error != null)
                        DefaultErrorHandler.showDefaultErrorAlert(error);
                });
            }
        };
        this.goStepBack = () => {
            const { projectId, type } = this.state;
            const { data } = this.props;
            if (type === 'Tickets') {
                this.setState({ type: 'TicketOrFolder', entityId: undefined });
            }
            else if (!data.dontChangeProject && projectId != null && projectId.length > 0) {
                this.setState({ projectId: undefined });
            }
            else {
                DialogUp.instance?.close();
            }
        };
        this.searchOnChange = (searchValue) => {
            this.setState({ searchValue: searchValue.toLowerCase() });
        };
        this.renderProgressBar = () => {
            const { movedFiles, toBeMovedFiles } = this.state;
            return (<View style={{ padding: ThemeManager.style.contentPaddingValue }}>
        <MaterialText textAlign="right">{`${movedFiles} / ${toBeMovedFiles}`}</MaterialText>
        <View style={{ height: ThemeManager.style.getScreenRelativePixelSize(16) }}/>
        <ProgressBar progressInPercent={(movedFiles / toBeMovedFiles) * 100}/>
      </View>);
        };
        this.state = {
            isLoading: true,
            source: props.data.source === 'Journal' ? 'Journal' : 'StoredFile',
            maxWidth: 300,
            folders: [],
            searchValue: '',
            movedFiles: 0,
            toBeMovedFiles: 0,
            canUploadAsPlan: false,
        };
    }
    componentDidMount() {
        this.backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
            this.goStepBack();
            return true;
        });
        this.init().catch((e) => console.debug('cant init AddFilePU on mount', e));
    }
    async canBeUploadedAsPlan(projectId) {
        try {
            if (SharedFiles.count === 1) {
                const canUploadPlan = await RightsManager.hasWriteRight(projectId, CurrentUser.userId, 'commandCreatePlan');
                if (canUploadPlan) {
                    const planFile = SharedFiles.files != null && SharedFiles.files.length === 1
                        ? SharedFiles.files[0]
                        : SharedFiles.fileIds != null && SharedFiles.fileIds.length === 1
                            ? SharedFiles.fileIds[0]
                            : undefined;
                    if (planFile) {
                        if (typeof planFile === 'string') {
                            const file = await UpmeshClient.instance.modals.storedFile.getById(planFile);
                            const fileId = file.getFileId();
                            return (fileId.toLowerCase().endsWith('pdf') ||
                                fileId.toLowerCase().endsWith('jpg') ||
                                fileId.toLowerCase().endsWith('png'));
                        }
                        return ((planFile.name &&
                            (planFile.name.toLowerCase().endsWith('pdf') ||
                                planFile.name.toLowerCase().endsWith('jpg') ||
                                planFile.name.toLowerCase().endsWith('png'))) ||
                            (planFile.path &&
                                (planFile.path.toLowerCase().endsWith('pdf') ||
                                    planFile.path.toLowerCase().endsWith('jpg') ||
                                    planFile.path.toLowerCase().endsWith('png'))));
                    }
                }
            }
        }
        catch (e) {
            console.debug('cant check canBeUploadedAsPlan', e);
        }
        return false;
    }
    componentWillUnmount() {
        if (this.backHandler)
            this.backHandler.remove();
        if (Keyboard != null)
            Keyboard.dismiss();
    }
    renderSpinner() {
        return (<View onLayout={this.onLayout} style={{
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                minHeight: 242,
            }}>
        <Spinner />
      </View>);
    }
    getProjectFolderFlatTitle(folder, folders) {
        const f = folder;
        let t = folder.title;
        if (folder.subFolderFromId != null) {
            const getMain = folders.find((e) => e.id === folder.subFolderFromId);
            if (getMain != null)
                t = `${this.getProjectFolderFlatTitle(getMain, folders).flatTitle} › ${t}`;
        }
        f.flatTitle = t;
        return f;
    }
    componentDidUpdate(prevProps, _prevState) {
        if (this.props.data !== prevProps.data) {
            this.init().catch((e) => console.debug('cant init AddFilePU', e));
        }
    }
    renderStep() {
        const { projectId, type, entityId, folders, searchValue, canUploadAsPlan } = this.state;
        const { excludeFolders } = SharedFiles;
        if (projectId == null) {
            return <ProjectSelector onSelect={this.selectProject}/>;
        }
        if ((type == null || type === 'TicketOrFolder') && entityId == null) {
            const visibleFolders = [
                <View style={{ height: 56, paddingHorizontal: ThemeManager.style.contentPaddingValue, paddingVertical: 4 }}>
          <SearchBar searchOnChange={this.searchOnChange} searchBarValue={searchValue} maxWidth={526}/>
        </View>,
            ];
            if (searchValue.length === 0 || I18n.m.getMessage('selectorTickets').toLowerCase().indexOf(searchValue) > -1)
                visibleFolders.push(<ListItem key="to_tickets" thumbnail={{
                        width: 40,
                        thumbnail: <Icon toolTip="" icon="map-marker" backgroundColor="rgba(0,0,0,0.12)"/>,
                    }} title={I18n.m.getMessage('selectorTickets')} onPress={this.selectType('Tickets')}/>);
            if (canUploadAsPlan) {
                visibleFolders.push(<ListItem key="to_plans" thumbnail={{
                        width: 40,
                        thumbnail: <Icon toolTip="" icon="floor-plan" backgroundColor="rgba(0,0,0,0.12)"/>,
                    }} title={I18n.m.getMessage('selectorPlans')} onPress={this.selectType('plans')}/>);
            }
            if ((excludeFolders == null || excludeFolders.indexOf('private') === -1) &&
                (searchValue.length === 0 || I18n.m.getMessage('selectorFolderPrivate').toLowerCase().indexOf(searchValue) > -1)) {
                visibleFolders.push(<ListItem key="to_folder_private" thumbnail={{
                        width: 40,
                        thumbnail: <Icon toolTip="" icon="folder-lock" backgroundColor="rgba(0,0,0,0.12)"/>,
                    }} title={I18n.m.getMessage('selectorFolderPrivate')} onPress={this.selectFolder('')}/>);
            }
            if ((excludeFolders == null || excludeFolders.indexOf('public') === -1) &&
                (searchValue.length === 0 || I18n.m.getMessage('selectorFolderPublic').toLowerCase().indexOf(searchValue) > -1)) {
                visibleFolders.push(<ListItem key="to_folder_public" thumbnail={{
                        width: 40,
                        thumbnail: <Icon toolTip="" icon="folder-home" backgroundColor="rgba(0,0,0,0.12)"/>,
                    }} title={I18n.m.getMessage('selectorFolderPublic')} onPress={this.selectFolder('public')}/>);
            }
            folders.forEach((e) => {
                if ((excludeFolders == null || excludeFolders.indexOf(e.id) === -1) &&
                    (searchValue.length === 0 || e.title.toLowerCase().indexOf(searchValue) > -1)) {
                    visibleFolders.push(<ListItem key={`folder_${e.id}`} thumbnail={{
                            width: 40,
                            thumbnail: <Icon toolTip="" icon="folder" backgroundColor="rgba(0,0,0,0.12)"/>,
                        }} title={e.title} secondTextLine={e.title !== e.flatTitle ? e.flatTitle : ''} onPress={this.selectFolder(e.id)}/>);
                }
            });
            return (<ScrollView style={{ width: '100%', height: '100%' }}>
          <View>{visibleFolders}</View>
        </ScrollView>);
        }
        if (type === 'plans')
            return <PlanSelector projectId={projectId} showNew onSelect={this.selectPlan}/>;
        return <TicketSelector projectId={projectId} showNew onSelect={this.selectTicket}/>;
    }
    render() {
        if (this.state.isLoading) {
            return this.renderSpinner();
        }
        const { projectId, type, entityId, toBeMovedFiles } = this.state;
        let title = I18n.m.getMessage('filesAddTitleTickets');
        if (projectId == null)
            title = I18n.m.getMessage('filesAddTitleProjects');
        else if ((type == null || type === 'TicketOrFolder') && entityId == null) {
            title = I18n.m.getMessage('filesAddTitle');
        }
        else if (type === 'plans') {
            title = I18n.m.getMessage('filesAddTitlePlans');
        }
        const { data } = this.props;
        if (toBeMovedFiles > 0) {
            return this.renderProgressBar();
        }
        return [
            <DialogTitle backButton={projectId == null || (type !== 'Tickets' && data.dontChangeProject) ? undefined : this.goStepBack} key="title" iconRight={{ icon: 'close', onPress: DialogUp.instance?.close, toolTip: I18n.m.getMessage('close') }}>
        {title}
      </DialogTitle>,
            <DialogContent key="content">{this.renderStep()}</DialogContent>,
        ];
    }
}
AddFilesPU.defaultProps = {
    data: null,
};
AddFilesPU.contextType = ConnectionContext;
AddFilesPU.openDialog = (data) => (e) => {
    if (SharedFiles.count > 0) {
        const openPosition = e != null && e.nativeEvent == null && e.nativeEvent.pageX != null
            ? { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY }
            : {
                x: ResizeEvent.current.windowWidth / 2,
                y: ResizeEvent.current.windowHeight / 2,
            };
        DialogUp.instance?.open({
            content: <AddFilesPU data={data}/>,
            openPosition,
            fullscreenResponsive: true,
            scrollable: false,
            contentPadding: false,
            showCloseIcon: false,
            closeOnTouchOutside: false,
        });
    }
};
