import { PromisePool } from 'cqrs-shared/src/PromisePool';
import * as _ from 'lodash';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import { AsyncEvent } from 'ts-events';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ProjectReadRights } from 'upmesh-core/src/access/rights/ProjectReadRights';
import { ProjectWriteRights } from 'upmesh-core/src/access/rights/ProjectWriteRights';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { JournalEntity } from 'upmesh-core/src/client/query/entities/JournalEntity';
import { StoredFileEntity } from 'upmesh-core/src/client/query/entities/StoredFileEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { UserOrCompany } from 'upmesh-core/src/server/webserver/UserOrCompany';
import { DateLocale } from 'upmesh-i18n/src/i18n/DateLocale';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { StoredFileDownloader } from '../file/StoredFileDownloader';
import { StoredFileDownloaderOptions } from '../file/StoredFileDownloaderOptions';
import { TicketEntitySynced } from '../tickets/TicketEntitySynced';
export class CurrentProject {
    constructor() {
        this.abort = false;
        this.currentFiles = [];
        this.currentPlans = [];
        this.currentProjectTeam = [];
        this.currentProjectTeamUserIdMapIndex = {};
        this.currentTicketId = null;
        this.currentTickets = [];
        this.loading = false;
        this.loadedAll = false;
        this.openReport = (_ticketFilter) => async (_e) => {
        };
        this.openTicketReport = (_ticketId) => async (_e) => {
        };
        this.reloadOnSyncStopAttached = false;
        this.reloadOnSyncStop = () => {
            if (this.currentProjectId != null)
                this.loadAll()?.catch((err) => console.debug(err));
        };
        this.onReSync = async (en) => {
            en.entities.forEach((e) => {
                if (e.entity.ticketId != null) {
                    setTimeout(async () => {
                        this.currentTickets = await this.loadTickets();
                        await this.sortTickets();
                    }, 500);
                }
                else if (e.entity.projectId === this.currentProjectId) {
                    setTimeout(async () => {
                        this.loadAll()?.catch((err) => console.debug(err));
                    }, 500);
                }
            });
        };
        this.loadAll = _.debounce(async () => {
            if (!this.loading && this.currentProjectId != null) {
                this.loading = true;
                await DateLocale.waitFor(() => CurrentUser.entity != null && CurrentUser.entity.id.length > 0, 100, 60000);
                if (CurrentUser.entity == null || CurrentUser.entity.id.length === 0) {
                    Routing.instance.goTo('/');
                    this.loading = false;
                    return null;
                }
                if (this.abort)
                    return this.abortLoading();
                this.currentProject = await this.loadProject();
                if (this.currentProject == null) {
                    this.loading = false;
                    return null;
                }
                this.currentProjectTeam = await this.loadTeam();
                if (this.abort)
                    return this.abortLoading();
                const [currentTickets, loadFiles, currentPlans, currentRights] = await Promise.all([
                    this.loadTickets(),
                    this.loadFiles(),
                    this.loadPlans(),
                    this.loadProjectRights(),
                ]);
                this.currentPlans = currentPlans;
                this.currentTickets = currentTickets;
                this.currentFiles = loadFiles;
                this.currentProjectRights = currentRights;
                if (this.abort)
                    return this.abortLoading();
                this.startListener();
                await this.sortPlans();
                await this.sortTickets();
                if (this.abort)
                    return this.abortLoading();
                this.loadedAll = true;
                this.abortLoading();
            }
            else {
                if (this.reloadTimer != null) {
                    window.clearTimeout(this.reloadTimer);
                    this.reloadTimer = undefined;
                }
                if (this.currentProjectId != null) {
                    this.reloadTimer = window.setTimeout(this.reload, 66);
                }
                else {
                    this.abortLoading();
                }
            }
            return null;
        }, 500);
        this.getTicketData = async (t) => {
            try {
                const ts = await TicketEntitySynced.convertFromTicket(t, true);
                return ts;
            }
            catch (error) {
                console.error('cant convert ticktet', { error, t });
                try {
                    const ts2 = await TicketEntitySynced.convertFromTicket(t, false);
                    return ts2;
                }
                catch (err) {
                    console.error('cant convert ticktet 2', { error, t });
                    return new TicketEntitySynced(t);
                }
            }
        };
        this.plansChanged = async (en) => {
            en.entities.forEach((e) => {
                if (e.entity.projectId === this.currentProjectId) {
                    if (e.entity != null) {
                        this.reloadPlan(e.entity)
                            .then(() => {
                            CurrentProject.planChanged.post(e.entity);
                        })
                            .catch((err) => console.debug(err));
                    }
                    else {
                        this.abort = this.loading;
                        this.loadAll()?.catch((err) => console.debug(err));
                    }
                }
            });
        };
        this.plansVersionsChanged = (en) => {
            en.entities.forEach((e) => {
                if (e.entity.projectId === this.currentProjectId) {
                    CurrentProject.planVersionsChanged.post(e.entity);
                }
            });
        };
        this.projectChanged = (en) => {
            en.entities.forEach((e) => {
                if (e.entity?.id === this.currentProjectId) {
                    this.abort = this.loading;
                    this.loadAll()?.catch((err) => console.debug(err));
                }
            });
        };
        this.reload = () => {
            if (this.reloadTimer != null) {
                window.clearTimeout(this.reloadTimer);
                this.reloadTimer = undefined;
            }
            this.loadAll()?.catch((err) => console.debug(err));
        };
        this.postTimeout = {};
        this.storedFilesChanged = (en) => {
            en.entities.forEach((e) => {
                if (e.entity?.projectId === this.currentProjectId) {
                    if (e.entity?.id != null) {
                        this.reloadFile(e.entity).catch((err) => console.debug(err));
                    }
                    else {
                        this.abort = this.loading;
                        this.loadAll()?.catch((err) => console.debug(err));
                    }
                }
            });
        };
        this.ticketChanged = (en) => {
            en.entities.forEach((e) => {
                if (e.entity?.projectId === this.currentProjectId) {
                    if (e.entity?.id != null) {
                        this.reloadTicket(e.entity).catch((err) => console.debug(err));
                    }
                    else {
                        this.abort = this.loading;
                        this.loadAll()?.catch((err) => console.debug(err));
                    }
                }
            });
        };
        this.projectUserChanged = async (en) => {
            let update = false;
            en.entities.forEach((e) => {
                if (e.entity.projectId === this.currentProjectId) {
                    if (e.entity?.id != null) {
                        update = true;
                    }
                    else {
                        this.abort = this.loading;
                        this.loadAll()?.catch((err) => console.debug(err));
                    }
                }
            });
            if (update)
                this.currentProjectTeam = await this.loadTeam();
        };
    }
    get loaded() {
        return this.loadedAll;
    }
    static get instance() {
        if (CurrentProject._instance == null) {
            CurrentProject._instance = new CurrentProject();
        }
        return CurrentProject._instance;
    }
    async createTicketReport(reportSettings, ticketIds, inFiles) {
        const f = JSON.stringify({ ticketIds, reportSettings, inFiles });
        const url = `${UpmeshClient.instance.url}/project/report/${I18n.m.currentLanguage}`;
        const headers = {};
        headers['Accept'] = 'application/json';
        headers['Content-Type'] = 'application/json';
        headers['authorization'] = `Bearer ${CurrentUser.token}`;
        const result = await fetch(url, { headers, method: 'POST', body: f });
        if (result.status === 200) {
            const json = encodeURIComponent(await result.text());
            return `${UpmeshClient.instance.url}/project/report/${json}`;
        }
        try {
            throw await result.json();
        }
        catch (e) {
            throw e;
        }
    }
    async createTicketReportFromTemplate(template, ticketIds, inFiles, companyId, asPdf) {
        const f = JSON.stringify({
            ticketIds,
            templateId: template.id,
            companyId,
            inFiles,
            projectId: CurrentProject.instance.currentProjectId,
            asPdf,
        });
        const url = `${UpmeshClient.instance.url}/project/templatereport/${I18n.m.currentLanguage}`;
        const headers = {};
        headers['Accept'] = 'application/json';
        headers['Content-Type'] = 'application/json';
        headers['authorization'] = `Bearer ${CurrentUser.token}`;
        const result = await fetch(url, { headers, method: 'POST', body: f });
        if (result.status === 200) {
            const json = encodeURIComponent(await result.text());
            return `${UpmeshClient.instance.url}/project/report/${json}`;
        }
        try {
            throw await result.json();
        }
        catch (e) {
            throw e;
        }
    }
    async directDownloadTicketReport(reportSettings, ticketIds) {
        return this.createTicketReport(reportSettings, ticketIds, false);
    }
    async createJournalPreview(journalData) {
        const url = `${UpmeshClient.instance.url}/journal/pdf/${journalData.projectId}`;
        const headers = {};
        headers['Accept'] = 'application/json';
        headers['Content-Type'] = 'application/json';
        headers['authorization'] = `Bearer ${CurrentUser.token}`;
        const result = await fetch(url, { headers, method: 'POST', body: JSON.stringify(journalData) });
        if (result.status === 200) {
            const json = encodeURIComponent(await result.text());
            return `${UpmeshClient.instance.url}/journal/pdf/${json}`;
        }
        try {
            throw await result.json();
        }
        catch (e) {
            throw e;
        }
    }
    async createJournalProtocolLink(journalProtocol) {
        const url = `${UpmeshClient.instance.url}/journal/download/token/${journalProtocol.id}`;
        const headers = {};
        headers['Accept'] = 'application/json';
        headers['Content-Type'] = 'application/json';
        headers['authorization'] = `Bearer ${CurrentUser.token}`;
        const result = await fetch(url, { headers, method: 'POST', body: JSON.stringify(journalProtocol) });
        if (result.status === 200) {
            const json = encodeURIComponent(await result.text());
            return `${UpmeshClient.instance.url}/journal/download/${json}`;
        }
        try {
            throw await result.json();
        }
        catch (e) {
            throw e;
        }
    }
    async downloadPlanNow(plan, planVersion, extension) {
        const url = `${UpmeshClient.instance.url}/planversion/${planVersion.id}/pdf`;
        const headers = {
            Accept: 'application/pdf',
            'Content-Type': 'application/json',
            authorization: `Bearer ${CurrentUser.token}`,
        };
        const result = await fetch(url, { headers, method: 'POST' });
        if (result.status === 200) {
            const body = await result.json();
            const link = `${UpmeshClient.instance.url}/planversion/${planVersion.id}/pdf/${body.token}`;
            StoredFileDownloader.downloadFile(new StoredFileDownloaderOptions({
                link,
                orgFilename: `${plan.title}_${planVersion.version}${extension}`,
            })).catch((e) => {
                console.error('cant download report', link, e);
                DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m, e);
                throw e;
            });
        }
    }
    async downloadPlanWithTicketsNow(plan, planVersion, tickets, extension) {
        const url = `${UpmeshClient.instance.url}/planversion/ticket/${planVersion.id}/pdf`;
        const headers = {
            Accept: 'application/pdf',
            'Content-Type': 'application/json',
            authorization: `Bearer ${CurrentUser.token}`,
        };
        const result = await fetch(url, { headers, method: 'POST', body: JSON.stringify(tickets) });
        if (result.status === 200) {
            const body = await result.json();
            const link = `${UpmeshClient.instance.url}/planversion/${planVersion.id}/pdf/${body.token}`;
            StoredFileDownloader.downloadFile(new StoredFileDownloaderOptions({
                link,
                orgFilename: `${plan.title}_${planVersion.version}${extension}`,
            })).catch((e) => {
                console.error('cant download report', link, e);
                DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m, e);
                throw e;
            });
        }
    }
    async createArchive(projectId = this.currentProjectId) {
        const url = `${UpmeshClient.instance.url}/project/archive/${projectId}`;
        const headers = {};
        headers['Accept'] = 'application/zip';
        headers['Content-Type'] = 'application/zip';
        headers['authorization'] = `Bearer ${CurrentUser.token}`;
        LoadingEvents.instance.startLoading(I18n.m.getMessage('projectDownloadLoading'));
        const result = await fetch(url, { headers, method: 'POST' });
        LoadingEvents.instance.stopLoading();
        if (result.status === 200) {
            const json = encodeURIComponent(await result.text());
            return `${UpmeshClient.instance.url}/project/archive/${json}`;
        }
        try {
            throw await result.json();
        }
        catch (e) {
            throw e;
        }
    }
    getCurrentFiles() {
        if (this.currentFiles == null) {
            return undefined;
        }
        return this.currentFiles;
    }
    getCurrentPlans() {
        if (this.currentPlans == null) {
            return [];
        }
        return this.currentPlans;
    }
    getCurrentRights() {
        if (this.currentProjectRights == null) {
            return { read: new ProjectReadRights(), write: new ProjectWriteRights() };
        }
        return this.currentProjectRights;
    }
    getCurrentProject() {
        return this.currentProject;
    }
    getCurrentProjectId() {
        return this.currentProjectId;
    }
    getCurrentProjectTeam(types = 'user') {
        if (this.currentProjectTeam == null) {
            return [];
        }
        if (types !== 'all') {
            const c = [];
            this.currentProjectTeam.forEach((m) => {
                const isCompany = m.user.id.startsWith('company');
                if ((isCompany && types === 'companies') || (!isCompany && types === 'user')) {
                    c.push(m);
                }
            });
            return c;
        }
        return this.currentProjectTeam;
    }
    getCurrentTicketId() {
        return this.currentTicketId;
    }
    getCurrentTickets() {
        if (this.currentTickets == null) {
            return [];
        }
        return this.currentTickets;
    }
    setProjectId(id) {
        if (id !== this.currentProjectId || this.currentProject == null || this.currentProject.id !== id) {
            if (!this.reloadOnSyncStopAttached) {
                this.reloadOnSyncStopAttached = true;
                UpmeshClient.instance.syncDispatcher.syncStop.attach(this.reloadOnSyncStop);
            }
            this.loadedAll = false;
            this.abort = this.loading;
            this.currentProject = null;
            this.currentFiles = undefined;
            if (id == null) {
                this.currentProjectId = null;
                this.currentProjectTeam = [];
                this.currentTickets = [];
                this.currentPlans = [];
                this.currentFiles = [];
                return;
            }
            this.currentProjectId = id;
            this.loadAll()?.catch((err) => console.debug(err));
        }
        else {
            CurrentProject.projectChanged.post(this.currentProject);
        }
    }
    setTicketId(id) {
        this.currentTicketId = id;
    }
    abortLoading() {
        this.abort = false;
        this.loading = false;
    }
    async loadProjectRights() {
        const read = new ProjectReadRights();
        const readPromises = [];
        for (const key in read) {
            if (this.currentProject != null && CurrentUser.userId.length > 0) {
                readPromises.push(this.loadProjectRight(key, 'read', this.currentProject.id));
            }
        }
        const readRights = await Promise.all(readPromises);
        readRights.forEach((a) => {
            read[a.key] = a.bool;
        });
        const write = new ProjectWriteRights();
        const writePromises = [];
        for (const key in write) {
            if (this.currentProject != null && CurrentUser.userId.length > 0) {
                writePromises.push(this.loadProjectRight(key, 'write', this.currentProject.id));
            }
        }
        const writeRights = await Promise.all(writePromises);
        writeRights.forEach((a) => {
            write[a.key] = a.bool;
        });
        return { read, write };
    }
    async loadProjectRight(key, type, projectId) {
        return {
            key,
            bool: type === 'read'
                ? await RightsManager.hasReadRight(projectId, CurrentUser.userId, key)
                : await RightsManager.hasWriteRight(projectId, CurrentUser.userId, key),
        };
    }
    async loadFiles() {
        if (this.currentProjectId != null) {
            const files = [];
            const getFiles = await UpmeshClient.instance.modals.storedFile.get({
                filter: `projectId eq '${this.currentProjectId}' and deleted ne true`,
            });
            getFiles.forEach((s) => {
                const u = this.getUser(s.userId);
                if (u != null) {
                    s['uploadedByUsername'] = u.getFullName();
                    s['uploadedByUser'] = u;
                    files.push(s);
                }
            });
            const journals = await UpmeshClient.instance.modals.journal.get({
                filter: `projectId eq '${this.currentProjectId}' and deleted ne true`,
            });
            const journalSet = new Map();
            journals.forEach((j) => {
                journalSet.set(j.id, j);
            });
            const reports = await UpmeshClient.instance.modals.journalProtocol.get({
                filter: `projectId eq '${this.currentProjectId}' and deleted ne true`,
            });
            const currentVersions = new Map();
            reports.forEach((f) => {
                const j = journalSet.get(f.journalId);
                if (j != null) {
                    const current = currentVersions.get(j.forDay.toString());
                    if (!current || new Date(f.createdAt).getTime() > new Date(current.createdAt).getTime()) {
                        const s = this.journalToStoredFile(f, j);
                        s.comment = I18n.m.dateCurrent.localeDateString(JournalEntity.getNormalizedDateShortToDate(j.forDay.toString()));
                        currentVersions.set(j.forDay.toString(), s);
                    }
                }
            });
            currentVersions.forEach((s) => {
                files.push(s);
            });
            CurrentProject.storedFilesChanged.post(files);
            return files;
        }
        return [];
    }
    journalToStoredFile(f, j) {
        const s = new StoredFileEntity({
            ...f,
            fileId: f.fileId,
            projectId: f.projectId,
            userId: f.createdBy,
            lastModifiedAt: f.lastModifiedAt,
            createdBy: f.createdBy,
            forEntity: 'Journal',
            mimeType: 'application/pdf',
            forEntityId: f.journalId,
            type: 'pdf',
            createdAt: f.createdAt,
            orgFilename: `${j.forDay} ${I18n.m.dateCurrent.localeDateWithTimeString(new Date(f.createdAt))}.pdf`,
            fileType: 'pdf',
            metaData: JSON.stringify({ journal: j, protocol: f }),
        });
        const u = this.getUser(s.createdBy);
        if (u != null) {
            s['uploadedByUsername'] = u.getFullName();
            s['uploadedByUser'] = u;
        }
        return s;
    }
    getUser(id) {
        const user = this.currentProjectTeam.find((u) => u.user.id === id);
        return user != null ? user.user : null;
    }
    async loadPlans() {
        if (this.currentProjectId != null) {
            const plans = await UpmeshClient.instance.modals.plan.get({
                filter: `projectId eq '${this.currentProjectId}' and deleted ne true`,
                orderby: 'title',
            });
            CurrentProject.plansChanged.post(plans);
            return plans;
        }
        return [];
    }
    async loadProject() {
        if (this.currentProjectId != null) {
            try {
                const projectId = this.currentProjectId;
                const project = await UpmeshClient.instance.modals.project.getById(projectId);
                this.currentProject = project;
                if (project != null) {
                    if (project.messagesOverride != null)
                        I18n.m.messagesOverride = project.messagesOverride;
                    else
                        I18n.m.messagesOverride = {};
                }
                CurrentProject.projectChanged.post(project);
                return project;
            }
            catch (e) {
                console.debug('CANT LOAD PROJECT!', e);
                Routing.instance.goTo('/');
                return null;
            }
        }
        return null;
    }
    async loadTeam() {
        if (this.currentProject != null && this.currentProject.id != null) {
            const projectId = this.currentProject.id;
            const creatorId = this.currentProject.creator;
            await DateLocale.waitFor(() => UpmeshClient.instance.ready);
            const user = [];
            try {
                const creator = await UserOrCompany.getById(creatorId);
                try {
                    const company = await UpmeshClient.instance.modals.company.get({
                        filter: `users/userId eq '${creator.id}'`,
                        top: 1,
                    });
                    if (company.length > 0)
                        creator.company = company[0].company;
                }
                catch (e) {
                    console.debug('cant get company for user', creator.id);
                }
                user.push({ user: creator, role: 'admin' });
            }
            catch (e) {
                console.debug('loadTeam  cant load creator user', e);
            }
            try {
                const pUser = await UpmeshClient.instance.modals.projectUser.get({
                    filter: `projectId eq '${projectId}' and status eq 'accepted' and deleted ne true`,
                });
                for (let i = 0; i < pUser.length; i += 1) {
                    if (pUser[i].userId != null) {
                        try {
                            const u = await AuthClient.instance.modals.user.getById(pUser[i].userId);
                            try {
                                const company = await UpmeshClient.instance.modals.company.get({
                                    filter: `users/userId eq '${u.id}'`,
                                    top: 1,
                                });
                                if (company.length > 0)
                                    u.company = company[0].company;
                            }
                            catch (e) {
                                console.debug('cant get company for user', u.id);
                            }
                            const role = await UpmeshClient.instance.modals.projectRoles.getById(pUser[i].roleId);
                            user.push({ user: u, role: role.roleName });
                        }
                        catch (e) {
                            ErrorReporter.sendReport({
                                subject: `loadTeam cant load user ${e.name} ${e.message}`,
                                data: e,
                                type: 'warn',
                            });
                        }
                    }
                }
                user.sort((a, b) => {
                    return `${a.user.firstname} ${a.user.firstname}`.localeCompare(`${b.user.firstname} ${b.user.lastname}`);
                });
                this.currentProjectTeamUserIdMapIndex = {};
                user.forEach((u, index) => {
                    this.currentProjectTeamUserIdMapIndex[u.user.id] = index;
                });
            }
            catch (e) {
                ErrorReporter.sendReport({ subject: 'cant load ProjectUserEntity', data: e, type: 'warn' });
            }
            CurrentProject.teamChanged.post(user);
            return user;
        }
        return [];
    }
    async loadTickets() {
        if (this.currentProjectId != null) {
            const tickets = await UpmeshClient.instance.modals.ticket.get({
                filter: `projectId eq '${this.currentProjectId}'`,
            });
            const ticketsSynced = await PromisePool.run({
                collection: tickets,
                task: this.getTicketData,
                maxConcurrency: 2,
            });
            CurrentProject.ticketsChanged.post(ticketsSynced);
            return ticketsSynced;
        }
        return [];
    }
    async reloadFile(storedFile) {
        if (this.currentProjectId != null && this.currentFiles != null) {
            if (storedFile.entityName === 'JournalProtocol') {
                const f = storedFile;
                const j = await UpmeshClient.instance.modals.journal.getById(f.journalId);
                const s = this.journalToStoredFile(f, j);
                const findIndex = this.currentFiles.findIndex((v) => v.forEntityId === s.forEntityId);
                if (findIndex >= 0)
                    this.currentFiles[findIndex] = s;
                else
                    this.currentFiles.push(s);
                CurrentProject.storedFileChanged.post(s);
                CurrentProject.storedFilesChanged.post(this.currentFiles);
            }
            else {
                const file = storedFile;
                if (file.deleted) {
                    for (let i = 0; i < this.currentFiles.length; i += 1) {
                        const item = this.currentFiles[i];
                        if (item.id === file.id) {
                            this.currentFiles.splice(i, 1);
                            break;
                        }
                    }
                }
                else {
                    const u = this.getUser(file.userId);
                    if (u != null) {
                        file.uploadedByUsername = u.getFullName();
                        file.uploadedByUser = u;
                        let changed = false;
                        for (let i = 0; i < this.currentFiles.length; i += 1) {
                            const item = this.currentFiles[i];
                            if (item.id === file.id) {
                                this.currentFiles[i] = file;
                                changed = true;
                                break;
                            }
                        }
                        if (!changed) {
                            this.currentFiles.push(file);
                        }
                    }
                }
                CurrentProject.storedFileChanged.post(file);
                CurrentProject.storedFilesChanged.post(this.currentFiles);
            }
        }
    }
    async reloadPlan(plan) {
        if (this.currentProjectId != null) {
            const index = this.currentPlans.findIndex((p) => p.id === plan.id);
            if (index > -1 && plan.deleted) {
                this.currentPlans.splice(index, 1);
            }
            else if (index > -1) {
                this.currentPlans[index] = plan;
            }
            else if (!plan.deleted) {
                this.currentPlans.push(plan);
            }
            await this.sortPlans();
            CurrentProject.planChanged.post(plan);
            CurrentProject.plansChanged.post(this.currentPlans);
        }
    }
    async reloadTicket(ticket) {
        if (this.currentProjectId != null) {
            let changed = false;
            const ts = await this.getTicketData(ticket);
            for (let i = 0; i < this.currentTickets.length; i += 1) {
                const t = this.currentTickets[i];
                if (t.id === ticket.id) {
                    this.currentTickets[i] = ts;
                    changed = true;
                    break;
                }
            }
            if (!changed) {
                this.currentTickets.push(ts);
            }
            await this.sortTickets();
            if (this.postTimeout[ticket.id] != null)
                clearTimeout(this.postTimeout[ticket.id]);
            this.postTimeout[ticket.id] = setTimeout(() => {
                CurrentProject.ticketChanged.post(ts);
            }, 100);
            if (this.postTimeout['tickets'] != null)
                clearTimeout(this.postTimeout['tickets']);
            this.postTimeout['tickets'] = setTimeout(() => {
                CurrentProject.ticketsChanged.post(this.currentTickets);
            }, 100);
        }
    }
    async sortPlans() {
        this.currentPlans.sort((a, b) => {
            if (a.title < b.title) {
                return -1;
            }
            if (a.title > b.title) {
                return 1;
            }
            return 0;
        });
    }
    async sortTickets() {
        this.currentTickets.sort((a, b) => {
            a.completionOn = a.completionOn != null ? new Date(a.completionOn) : undefined;
            b.completionOn = b.completionOn != null ? new Date(b.completionOn) : undefined;
            const aCO = a.completionOn != null ? a.completionOn.getTime() : undefined;
            const bCO = b.completionOn != null ? b.completionOn.getTime() : undefined;
            if (aCO != null && bCO != null) {
                if (aCO < bCO) {
                    return -1;
                }
                if (aCO > bCO) {
                    return 1;
                }
            }
            else if (aCO != null) {
                return -1;
            }
            else if (bCO != null) {
                return 1;
            }
            if (a.ticketNumber < b.ticketNumber) {
                return -1;
            }
            if (a.ticketNumber > b.ticketNumber) {
                return 1;
            }
            return 0;
        });
    }
    startListener() {
        this.stopListener();
        if (this.currentProjectId != null) {
            UpmeshClient.eventDispatcher.attach({
                readModelName: 'Project',
                attachKey: 'currentProject',
                callback: this.projectChanged,
            });
            UpmeshClient.eventDispatcher.attach({
                readModelName: 'ProjectUser',
                attachKey: 'currentProjectUser',
                callback: this.projectUserChanged,
            });
            UpmeshClient.eventDispatcher.attach({
                readModelName: 'Ticket',
                attachKey: 'currentProjectTickets',
                callback: this.ticketChanged,
            });
            UpmeshClient.eventDispatcher.attach({
                readModelName: 'Plan',
                attachKey: 'currentProjectPlans',
                callback: this.plansChanged,
            });
            UpmeshClient.eventDispatcher.attach({
                readModelName: 'PlanVersion',
                attachKey: 'currentProjectPlanVersions',
                callback: this.plansVersionsChanged,
            });
            UpmeshClient.eventDispatcher.attach({
                readModelName: 'StoredFile',
                attachKey: 'currentProjectStoredFile',
                callback: this.storedFilesChanged,
            });
            UpmeshClient.eventDispatcher.attach({
                readModelName: 'JournalProtocol',
                attachKey: 'currentProjectStoredFile2',
                callback: this.storedFilesChanged,
            });
            AuthClient.instance.eventDispatcher.attach({
                readModelName: 'ReSync',
                callback: this.onReSync,
                attachKey: 'CurrentProjectReSync',
            });
        }
    }
    stopListener() {
        UpmeshClient.eventDispatcher.detach('Project', 'currentProject');
        UpmeshClient.eventDispatcher.detach('ProjectUser', 'currentProjectUser');
        UpmeshClient.eventDispatcher.detach('Ticket', 'currentProjectTickets');
        UpmeshClient.eventDispatcher.detach('Plan', 'currentProjectPlans');
        UpmeshClient.eventDispatcher.detach('PlanVersion', 'plansVersionsChanged');
        UpmeshClient.eventDispatcher.detach('StoredFile', 'currentProjectStoredFile');
        UpmeshClient.eventDispatcher.detach('JournalProtocol', 'currentProjectStoredFile2');
        UpmeshClient.eventDispatcher.detach('ReSync', 'CurrentProjectReSync');
    }
}
CurrentProject.planChanged = new AsyncEvent();
CurrentProject.planVersionsChanged = new AsyncEvent();
CurrentProject.plansChanged = new AsyncEvent();
CurrentProject.projectChanged = new AsyncEvent();
CurrentProject.storedFileChanged = new AsyncEvent();
CurrentProject.storedFilesChanged = new AsyncEvent();
CurrentProject.teamChanged = new AsyncEvent();
CurrentProject.ticketChanged = new AsyncEvent();
CurrentProject.ticketsChanged = new AsyncEvent();
