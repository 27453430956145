import { Url } from 'cqrs-shared/src/uri/Url';
import * as EmailValidator from 'email-validator';
import * as _ from 'lodash';
import ConnectionContext from 'materialTheme/src/connectionContext';
import { AddressCardForm } from 'materialTheme/src/theme/components/addressCard/AddressCardForm';
import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { SegmentedButton } from 'materialTheme/src/theme/components/button/SegmentedButton';
import { Checkbox } from 'materialTheme/src/theme/components/Checkbox';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { DialogBetween } from 'materialTheme/src/theme/components/DialogBetween';
import { DialogUp } from 'materialTheme/src/theme/components/DialogUp';
import { DateInputFormFilled } from 'materialTheme/src/theme/components/forminput/DateInputFormFilled';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import * as path from 'path';
import React, { PureComponent } from 'react';
import { Platform, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { AddressEntity } from 'upmesh-auth-core/src/client/query/entities/AddressEntity';
import { FinalizeJournal } from 'upmesh-core/src/client/commands/project/journal/FinalizeJournal';
import { SaveJournal } from 'upmesh-core/src/client/commands/project/journal/SaveJournal';
import { ChangeProjectAddress } from 'upmesh-core/src/client/commands/projectaddress/ChangeProjectAddress';
import { CreateProjectAddress } from 'upmesh-core/src/client/commands/projectaddress/CreateProjectAddress';
import { SaveReportSettings } from 'upmesh-core/src/client/commands/report/SaveReportSettings';
import { GroupEntity } from 'upmesh-core/src/client/query/entities/GroupEntity';
import { EmployeeJournal, JournalEntity, } from 'upmesh-core/src/client/query/entities/JournalEntity';
import { ReportSettingsEntity } from 'upmesh-core/src/client/query/entities/ReportSettingsEntity';
import { TaskEntity } from 'upmesh-core/src/client/query/entities/TaskEntity';
import { TicketFilter } from 'upmesh-core/src/client/query/filter/TicketFilter';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import * as uuid from 'uuid';
import { ConfigAll } from '../../config/ConfigAll';
import { I18n } from '../../i18n/I18n';
import { WeatherWrapper } from '../../utils/weather/WeatherWrapper';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { ProjectUploadButton } from '../file/ProjectUploadButton';
import { StoredFileDownloader } from '../file/StoredFileDownloader';
import { StoredFileDownloaderOptions } from '../file/StoredFileDownloaderOptions';
import { CurrentProject } from '../project/CurrentProject';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { ReportDialog } from '../tickets/ReportDialog';
import { TicketDialogFilter } from '../tickets/TicketDialogFilter';
import { CopyJournalsDialog } from './CopyJournalsDialog';
export class JournalDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.weatherList = [
            { title: I18n.m.getMessage('journalWeatherClear'), data: 'clear' },
            { title: I18n.m.getMessage('journalWeatherPartlyCloudy'), data: 'partly cloudy' },
            { title: I18n.m.getMessage('journalWeatherCloudy'), data: 'cloudy' },
            { title: I18n.m.getMessage('journalWeatherFog'), data: 'fog' },
            { title: I18n.m.getMessage('journalWeatherRain'), data: 'rain' },
            { title: I18n.m.getMessage('journalWeatherPouring'), data: 'pouring' },
            { title: I18n.m.getMessage('journalWeatherWind'), data: 'wind' },
            { title: I18n.m.getMessage('journalWeatherHail'), data: 'hail' },
            { title: I18n.m.getMessage('journalWeatherLightning'), data: 'lightning' },
            { title: I18n.m.getMessage('journalWeatherThunderstorm'), data: 'thunderstorm' },
            { title: I18n.m.getMessage('journalWeatherSnow'), data: 'snow' },
            { title: I18n.m.getMessage('journalWeatherSleet'), data: 'sleet' },
        ];
        this.onChangeSpecialOccurrences = (text) => {
            this.setState((prevState) => {
                const newJournal = new JournalEntity();
                newJournal.fill(prevState.journalData);
                newJournal.specialOccurrences = text;
                return { journalData: newJournal };
            });
        };
        this.onChangeAdditionalField = (id, text) => {
            this.setState((prevState) => {
                const newJournal = new JournalEntity();
                newJournal.fill(prevState.journalData);
                let index = -1;
                if (newJournal.additionalFields == null)
                    newJournal.additionalFields = [];
                if (newJournal.additionalFields.length > 0) {
                    index = newJournal.additionalFields.findIndex((f) => f.id === id);
                }
                if (index === -1)
                    newJournal.additionalFields.push({ id, content: text });
                else
                    newJournal.additionalFields[index].content = text;
                return { journalData: newJournal };
            });
        };
        this.onChangeWorkDone = (text) => {
            this.setState((prevState) => {
                const newJournal = new JournalEntity();
                newJournal.fill(prevState.journalData);
                newJournal.workDone = text;
                return { journalData: newJournal };
            });
        };
        this.checkForEMail = (text) => !EmailValidator.validate(text) === true;
        this.onUploadStatus = (countedUploads) => {
            this.setState({ currentUploads: countedUploads }, this.checkSaveStatus);
        };
        this.checkSaveStatus = () => {
            const { currentUploads, startUploads, journalData } = this.state;
            if (startUploads && currentUploads === 0) {
                this.setState({
                    saveIsLoading: false,
                    startUploads: false,
                    initialValue: new JournalEntity({ ...journalData }),
                    journalAvailable: true,
                });
            }
        };
        this.errorResult = async (e) => {
            if (e != null && e.messageCode != null) {
                if (e.messageCode === 'notForFree') {
                }
                Routing.instance.alert.post({ text: I18n.m.getMessage('reportErrorNotForFree') });
            }
        };
        this.openReportFilter = async () => {
            const { journalData } = this.state;
            const currentFilter = journalData.ticketReportFilter != null ? journalData.ticketReportFilter : new TicketFilter();
            TicketDialogFilter.open(CurrentProject.instance.getCurrentTickets(), this.onReportFilterSet, currentFilter, CurrentProject.instance.getCurrentProjectId(), this.forDay, true);
        };
        this.saveReportSettings = (t) => {
            this.setState((prevState) => {
                const current = prevState.journalData;
                current.ticketReportSettings = t;
                return { journalData: new JournalEntity(current) };
            });
        };
        this.onReportFilterSet = (t) => {
            this.setState((prevState) => {
                const current = prevState.journalData;
                current.ticketReportFilter = t;
                return { journalData: new JournalEntity(current) };
            }, this.filterTickets);
        };
        this.openReportDialog = async () => {
            const { userId } = CurrentUser;
            const { ticketsForReport, journalData } = this.state;
            const usedSettings = new ReportSettingsEntity({ userId });
            let editable = false;
            const saveReportSettings = new SaveReportSettings(usedSettings);
            try {
                editable = (await saveReportSettings.canI()) === true;
            }
            catch (e) {
                console.debug('default report settings');
            }
            DialogBetween.instance?.open({
                closeOnTouchOutside: false,
                fullscreenResponsive: true,
                scrollable: false,
                contentPadding: false,
                content: (<ReportDialog allTickets={false} saveWithNoResult reportSettings={journalData.ticketReportSettings != null
                        ? new ReportSettingsEntity(journalData.ticketReportSettings)
                        : new ReportSettingsEntity({
                            userId: CurrentUser.userId,
                            layout: {
                                lineBreakBeforeTicket: false,
                                groupBy: 'companyId',
                                sort: 'ticketNumber',
                                includeLinks: true,
                                qrCodes: true,
                                mergeTicketPdfsToReport: false,
                            },
                            frontPage: {
                                selectedMode: 'description',
                                hide: true,
                                description: '',
                                showTableOfContents: false,
                                titleName: 'preview',
                            },
                        })} editable={editable} errorResult={this.errorResult} ticketIds={ticketsForReport} closeFunc={(callback) => DialogBetween.instance?.close(callback)} saveFunc={this.saveReportSettings}/>),
            }, true);
        };
        this.createReport = async (reportSettings, ticketIds) => {
            if (!UpmeshClient.instance.serverConnected()) {
                Routing.instance.alert.post({
                    text: I18n.m.getMessage('commandOfflineNotPossible'),
                });
                return;
            }
            this.props.changePreviewIsLoading(true, async () => {
                try {
                    const link = await CurrentProject.instance.directDownloadTicketReport(reportSettings, ticketIds);
                    if (link != null) {
                        const orgFilename = `${I18n.m.getMessage('preview')}_report.pdf`;
                        StoredFileDownloader.downloadFile(new StoredFileDownloaderOptions({
                            link,
                            orgFilename,
                        }))
                            .catch((e) => {
                            console.error('cant download', link, e);
                            DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m, e);
                            throw e;
                        })
                            .finally(() => {
                            this.props.changePreviewIsLoading(false);
                        });
                    }
                    else {
                        this.props.changePreviewIsLoading(false);
                    }
                }
                catch (e) {
                    console.debug('Report Generation Error', e);
                    DefaultErrorHandler.showDefaultErrorAlert(e);
                    this.props.changePreviewIsLoading(false);
                }
            });
        };
        this.updateWeatherImportTime = () => {
            const { journalData } = this.state;
            let newStart = null;
            let newEnd = null;
            if (journalData.employee.length >= 0) {
                journalData.employee.forEach((a) => {
                    if (a.timeStart != null) {
                        a.timeStart = new Date(a.timeStart);
                    }
                    if (a.timeEnd != null) {
                        a.timeEnd = new Date(a.timeEnd);
                    }
                    if (a.timeStart != null && (newStart == null || a.timeStart.getHours() < newStart)) {
                        newStart = a.timeStart.getHours();
                    }
                    if (a.timeEnd != null && (newEnd == null || a.timeEnd.getHours() > newEnd)) {
                        newEnd = a.timeEnd.getHours();
                    }
                });
            }
            if (journalData.attendance.length >= 0) {
                journalData.attendance.forEach((a) => {
                    if (a.timeStart != null) {
                        a.timeStart = new Date(a.timeStart);
                    }
                    if (a.timeEnd != null) {
                        a.timeEnd = new Date(a.timeEnd);
                    }
                    if (a.timeStart != null && (newStart == null || a.timeStart.getHours() < newStart)) {
                        newStart = a.timeStart.getHours();
                    }
                    if (a.timeEnd != null && (newEnd == null || a.timeEnd.getHours() > newEnd)) {
                        newEnd = a.timeEnd.getHours();
                    }
                });
            }
            if (newStart != null && newEnd != null) {
                return this.setState({ startWeather: newStart, endWeather: newEnd });
            }
            return this.setState({ startWeather: 8, endWeather: 17 });
        };
        this.picturePickerList = [
            I18n.m.getMessage('reportTicketPhotoPerLine'),
            `2 ${I18n.m.getMessage('reportTicketPhotosPerLine')}`,
            `3 ${I18n.m.getMessage('reportTicketPhotosPerLine')}`,
        ];
        this.qualityPickerList = [
            I18n.m.getMessage('qualityNormal'),
            I18n.m.getMessage('qualityGood'),
            I18n.m.getMessage('qualityOptimal'),
        ];
        this.onChangeTemplatePicker = (data) => {
            this.setState((prevState) => {
                const current = prevState.journalData;
                current.ticketReportTemplateId = data.value.data;
                return { selectedIndex: { index: data.index, data: data.value.data }, journalData: current.createOne(current) };
            });
        };
        this.onChangeImagePerLine = (selected) => {
            this.setState((prevState) => {
                const current = prevState.journalData;
                current.imagesPerLine = selected.index + 1;
                return { journalData: current.createOne(current), picturePickerListSelected: selected.index };
            });
        };
        this.onChangePictureQualitySelected = (selected) => {
            this.setState((prevState) => {
                const current = prevState.journalData;
                current.photoQuality = selected.index === 0 ? 'low' : selected.index === 1 ? 'normal' : 'good';
                return { journalData: current.createOne(current), pictureQulaityListSelected: selected.index };
            });
        };
        this.copyReportsDialog = () => {
            const { coloredDates, journalSettings } = this.props;
            DialogUp.instance?.open({
                content: (<CopyJournalsDialog journalSettings={journalSettings} coloredDates={coloredDates} onCopyJournal={this.onCopyJournal}/>),
                contentPadding: false,
            });
        };
        this.onCopyJournal = (newJournal) => {
            const { journalData, importCounter } = this.state;
            const journal = new JournalEntity(journalData);
            if (newJournal.employee && newJournal.employee.length > 0)
                journal.employee = newJournal.employee;
            if (newJournal.attendance && newJournal.attendance.length > 0)
                journal.attendance = newJournal.attendance;
            if (newJournal.weather && newJournal.weather.length > 0)
                journal.weather = newJournal.weather;
            if (newJournal.workDone)
                journal.workDone = newJournal.workDone;
            if (newJournal.specialOccurrences)
                journal.specialOccurrences = newJournal.specialOccurrences;
            if (newJournal.additionalFields)
                journal.additionalFields = newJournal.additionalFields;
            this.setState({ journalData: journal, importCounter: importCounter + 1 });
        };
        this.onCreateVersion = () => {
            Routing.instance.alert.post({
                text: I18n.m.getMessage('journalCreateVersionQuestion'),
                buttons: [
                    <ContainedButton backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} key="cancel" title={I18n.m.getMessage('cancel')} onPress={() => Alert.instance?.close()}/>,
                    <ContainedButton key="importNow" title={I18n.m.getMessage('journalCreateVersion')} onPress={() => {
                            Alert.instance?.close(() => {
                                this.createVersionNow();
                            });
                        }}/>,
                ],
            });
        };
        this.createVersionNow = () => {
            const { journalData } = this.state;
            const create = new FinalizeJournal({ journalId: journalData.id });
            create
                .execute()
                .then(() => {
                Dialog.instance?.close(() => {
                    this.props.openSendDialog(create.entityId);
                });
            })
                .catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
        };
        this.setTicketReport = (value) => {
            this.setState((prevState) => {
                const current = prevState.journalData;
                current.includeTicketReport = value === true;
                return { journalData: new JournalEntity(current) };
            }, () => {
                this.filterTickets().catch((err) => console.error(err));
            });
        };
        this.openTicketReportPreview = () => {
            const { ticketsForReport, journalData } = this.state;
            const reportSettings = journalData.ticketReportSettings != null
                ? new ReportSettingsEntity({
                    ...journalData.ticketReportSettings,
                    userId: CurrentUser.userId,
                })
                : new ReportSettingsEntity({
                    userId: CurrentUser.userId,
                    layout: {
                        lineBreakBeforeTicket: false,
                        groupBy: 'companyId',
                        sort: 'ticketNumber',
                        includeLinks: true,
                        qrCodes: true,
                        mergeTicketPdfsToReport: false,
                    },
                    frontPage: {
                        selectedMode: 'description',
                        hide: true,
                        description: '',
                        showTableOfContents: false,
                        titleName: 'preview',
                    },
                });
            this.createReport(reportSettings, ticketsForReport).catch((e) => console.debug('cant create report', e));
        };
        this.deleteWeather = (index) => (_e) => {
            const { weather } = this.state.journalData;
            const { importCounter } = this.state;
            weather.splice(index, 1);
            const current = this.state.journalData;
            current.weather = weather;
            this.setState({ journalData: new JournalEntity(current), importCounter: importCounter + 1 });
        };
        this.onSaveAsDraft = (_e) => {
            this.setState({ saveIsLoading: true, startUploads: true });
            this.saveNow()
                .then((j) => {
                if (j != null) {
                    this.setState({ journalData: j, initialValue: j }, () => {
                        this.checkSaveStatus();
                    });
                }
            })
                .catch((err) => console.debug(err));
        };
        this.beforeUpload = async () => {
            const { journalData } = this.state;
            if (!(journalData.createdBy != null && journalData.createdBy.length > 0)) {
                await this.saveNow();
            }
        };
        this.deleteAttachments = async (file) => {
            const { attachmentsToDelete, journalData, importCounter } = this.state;
            attachmentsToDelete.push(file);
            if (journalData.attachments != null) {
                const a = [...journalData.attachments];
                const index = a.findIndex((a) => a.fileId === file.id);
                if (index !== -1) {
                    a.splice(index, 1);
                }
                journalData.attachments = a;
            }
            this.setState({
                attachmentsToDelete: [...attachmentsToDelete],
                journalData: new JournalEntity(journalData),
                importCounter: importCounter + 1,
            });
            return true;
        };
        this.deleteAttachmentsNow = async (file) => {
            try {
                const headers = {};
                headers['authorization'] = `Bearer ${CurrentUser.token}`;
                const result = await fetch(file.id ? `${file.targetUrl}/${file.id}` : `${file.targetUrl}`, {
                    method: 'DELETE',
                    headers,
                });
                if (result.status === 200) {
                    return true;
                }
                Routing.instance.alert.post({ text: `${I18n.m.getMessage('couldNotDelete')}` });
            }
            catch (e) {
                const message = e['messageCode'] != null ? I18n.m.getMessage(e['messageCode']) : '?';
                Routing.instance.alert.post({ text: `${I18n.m.getMessage('couldNotDelete')}: ${message !== '?' ? message : e}` });
            }
            return false;
        };
        this.init = async () => {
            const companyAutoCompleteList = await this.getCompanyAutoCompleteList();
            const taskAutoCompleteList = await this.getTaskAutoCompleteList();
            const { initialJournalData } = this.props;
            const templates = CompanyUserInfo.companySettings?.reportTemplates?.filter((a) => a.reportType === 'ticket') ?? [];
            templates.sort((a, b) => a.title.localeCompare(b.title));
            const findIndex = templates.findIndex((a) => a.id === initialJournalData.ticketReportTemplateId);
            const s = new SaveJournal({ ...initialJournalData, forDay: initialJournalData.forDay.toString() });
            let canI = false;
            try {
                await s.canI();
                canI = true;
            }
            catch (e) {
                console.debug('no rights');
            }
            this.setState({
                canI,
                companyAutoCompleteList,
                taskAutoCompleteList,
                availableTemplates: templates,
                selectedIndex: findIndex >= 0
                    ? { index: findIndex + 1, data: initialJournalData.ticketReportTemplateId }
                    : { index: 0, data: '' },
            }, () => {
                this.filterTickets().catch((err) => console.error(err));
            });
        };
        this.filterTickets = async () => {
            const { journalData, ticketsForReport } = this.state;
            if (journalData.includeTicketReport) {
                try {
                    if (ticketsForReport == null || ticketsForReport.length === 0) {
                        LoadingEvents.instance.startLoading(I18n.m.getMessage('loadingTicketReport'));
                        await new Promise((r) => {
                            setTimeout(() => {
                                r();
                            }, 195);
                        });
                        const tickets = CurrentProject.instance.getCurrentTickets();
                        const filteredTickets = await TicketFilter.filterTickets(tickets, journalData.ticketReportFilter);
                        const ticketsForReport = [];
                        for (let i = 0; i < filteredTickets.length; i += 1) {
                            ticketsForReport.push(filteredTickets[i].id);
                        }
                        this.setState({ ticketsForReport });
                    }
                }
                catch (e) {
                    console.error(e);
                }
                LoadingEvents.instance.stopLoading();
            }
            else {
                this.setState({ ticketsForReport: [] });
            }
        };
        this.getCompanyAutoCompleteList = async () => {
            const autoCompleteList = [];
            const team = CurrentProject.instance.getCurrentProjectTeam();
            team.forEach((t) => {
                if (t.user.company != null && autoCompleteList.findIndex((ac) => ac === t.user.company) < 0) {
                    autoCompleteList.push(t.user.company);
                }
            });
            return autoCompleteList;
        };
        this.getTaskAutoCompleteList = async () => {
            const { company } = this.props;
            const autoCompleteList = [];
            if (company != null) {
                const tasks = await UpmeshClient.instance.modals.task.get({
                    filter: `deleted ne true and companyId eq '${company.id}'`,
                });
                tasks.forEach((t) => {
                    if (t.mark != null && t.description != null) {
                        autoCompleteList.push(`${t.mark} - ${t.description}`);
                    }
                });
            }
            return autoCompleteList;
        };
        this.onChangeAttendanceCompany = (index) => (text) => {
            this.setState((prevState) => {
                const current = prevState.journalData;
                current.attendance[index].companyName = text;
                return { journalData: new JournalEntity(current) };
            });
        };
        this.onChangeEmployeeName = (index) => (text) => {
            this.setState((prevState) => {
                const current = prevState.journalData;
                current.employee[index].employee.name = text;
                return { journalData: new JournalEntity(current) };
            });
        };
        this.onChangeEmployeeGroup = (index) => (text) => {
            this.setState((prevState) => {
                const current = prevState.journalData;
                current.employee[index].employee.groupName = text;
                return { journalData: new JournalEntity(current) };
            });
        };
        this.onChangeAttendanceEmployeeCount = (index) => (text) => {
            this.setState((prevState) => {
                const current = prevState.journalData;
                current.attendance[index].workerCount = text == null ? 1 : Number.parseInt(text, 10);
                return { journalData: new JournalEntity(current) };
            });
        };
        this.onChangeEmployeeCount = (index) => (text) => {
            this.setState((prevState) => {
                const current = prevState.journalData;
                const c = text == null ? 1 : Number.parseInt(text, 10);
                current.employee[index].employee.workerCount = c >= 0 ? c : 0;
                return { journalData: new JournalEntity(current) };
            });
        };
        this.onChangeAttendanceText = (index) => (text) => {
            this.setState((prevState) => {
                const current = prevState.journalData;
                current.attendance[index].specifics = text;
                return { journalData: new JournalEntity(current) };
            });
        };
        this.onChangeEmployeeTask = (index) => (text) => {
            this.setState((prevState) => {
                const current = prevState.journalData;
                current.employee[index].task = text;
                return { journalData: new JournalEntity(current) };
            });
        };
        this.onChangeWeatherSpecifics = (index) => (text) => {
            this.setState((prevState) => {
                const current = prevState.journalData;
                current.weather[index].specifics = text;
                return { journalData: new JournalEntity(current) };
            });
        };
        this.onChangeAttendanceTime = (index, direction) => (time) => {
            this.setState((prevState) => {
                const current = prevState.journalData;
                if (direction === 'arrive') {
                    current.attendance[index].timeStart = time != null ? time : new Date();
                }
                else {
                    current.attendance[index].timeEnd = time != null ? time : new Date();
                }
                return { journalData: new JournalEntity(current) };
            }, this.updateWeatherImportTime);
        };
        this.onChangeEmployeeTime = (index, direction) => (time) => {
            this.setState((prevState) => {
                const current = prevState.journalData;
                if (direction === 'arrive') {
                    current.employee[index].timeStart = time != null ? time : new Date();
                }
                else {
                    current.employee[index].timeEnd = time != null ? time : new Date();
                }
                return { journalData: new JournalEntity(current) };
            }, this.updateWeatherImportTime);
        };
        this.onChangeWeatherTemp = (index, temp) => (temperature) => {
            this.setState((prevState) => {
                if (temperature?.lastIndexOf('.') === temperature?.length) {
                    return null;
                }
                const current = prevState.journalData;
                const t = temperature != null ? Number.parseFloat(temperature) : -1;
                if (temp === 'high') {
                    current.weather[index].temperatureHigh = t;
                }
                else {
                    current.weather[index].temperatureLow = t;
                }
                return { journalData: new JournalEntity(current) };
            });
        };
        this.onChangeEmployeeTotalTime = (index) => (date) => {
            this.setState((prevState) => {
                const current = prevState.journalData;
                current.employee[index].totalTimeInMinutes = date == null ? 0 : date.getHours() * 60 + date.getMinutes();
                return { journalData: new JournalEntity(current) };
            });
        };
        this.onChangeWeatherTime = (index) => (date) => {
            this.setState((prevState) => {
                const current = prevState.journalData;
                current.weather[index].time =
                    date != null ? new Date(1970, 0, 1, date.getHours(), date.getMinutes()) : new Date(0);
                return { journalData: new JournalEntity(current) };
            });
        };
        this.onChangeWeatherType = (index) => (type) => {
            this.setState((prevState) => {
                const current = prevState.journalData;
                current.weather[index].weatherCondition =
                    type != null
                        ? typeof this.weatherList[type.index] === 'string'
                            ? this.weatherList[type.index]
                            : this.weatherList[type.index]['data']
                        : null;
                return { journalData: new JournalEntity(current) };
            });
        };
        this.onChangeWeatherStart = (date) => {
            if (date != null) {
                this.setState({ startWeather: date.getHours() });
            }
        };
        this.onChangeWeatherEnd = (date) => {
            if (date != null) {
                if (date.getMinutes() > 0) {
                    this.setState({ endWeather: date.getHours() + 1 });
                }
                else {
                    this.setState({ endWeather: date.getHours() });
                }
            }
        };
        this.importWeather = async (_e) => {
            try {
                const { address, startWeather, endWeather } = this.state;
                const { selectedDate } = this.props;
                if (address && address.address.longitude > 0 && startWeather && endWeather) {
                    const groups = await WeatherWrapper.getGroupsForDate(selectedDate, address.address.longitude, address.address.latitude, startWeather, endWeather);
                    const journalWeather = [];
                    groups?.forEach((group) => {
                        const groupDate = new Date(selectedDate);
                        groupDate.setHours(group.startTime, 0, 0, 0);
                        journalWeather.push({
                            time: groupDate,
                            temperatureLow: group.tempLow,
                            temperatureHigh: group.tempHigh,
                            weatherCondition: WeatherWrapper.brightSkyToWeatherJournal(group.condition),
                            specifics: '',
                        });
                    });
                    const current = this.state.journalData;
                    current.weather = journalWeather;
                    this.setState({ journalData: new JournalEntity(current) }, () => {
                        Routing.instance.alert.post({
                            text: I18n.m.getMessage('journalImportFromWeatherResult', { count: journalWeather.length }),
                        });
                    });
                }
            }
            catch (err) {
                DefaultErrorHandler.showDefaultErrorAlert(err, I18n.m);
            }
            DialogUp.instance?.close();
        };
        this.importFromWeather = (_e) => {
            const { address, journalData, startWeather, endWeather } = this.state;
            if (address && address.address.longitude > 0) {
                const day = JournalEntity.getNormalizedDateShortToDate(journalData.forDay.toString());
                const startTime = new Date(day.getFullYear(), day.getMonth(), day.getDate(), startWeather, 0, 0);
                const endTime = new Date(day.getFullYear(), day.getMonth(), day.getDate(), endWeather, 0, 0);
                DialogUp.instance?.open({
                    contentPadding: false,
                    showCloseButton: false,
                    showCloseIcon: false,
                    content: (<>
            <DialogTitle>{I18n.m.getMessage('journalImportFromWeather')}</DialogTitle>
            <DialogContent>
              <DateInputFormFilled labelText={I18n.m.getMessage('journalImportFromWeatherStartTime')} onChange={this.onChangeWeatherStart} initValue={startTime} selectTime selectDate={false}/>
              <DateInputFormFilled labelText={I18n.m.getMessage('journalImportFromWeatherEndTime')} onChange={this.onChangeWeatherEnd} initValue={endTime} selectTime selectDate={false}/>
            </DialogContent>
            <DialogActions>
              <ContainedButton key="no" backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} title={I18n.m.getMessage('cancel')} onPress={DialogUp.instance?.close}/>
              <ContainedButton key="import_weather" title={I18n.m.getMessage('journalImportFromTimetrackingButton')} onPress={this.importWeather}/>
            </DialogActions>
          </>),
                });
            }
            else {
                Routing.instance.alert.post({
                    title: I18n.m.getMessage('journalImportFromWeatherAddressNeeded'),
                    text: I18n.m.getMessage('journalImportFromWeatherAddAddress'),
                    buttons: [
                        <ContainedButton key="no" backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                        <ContainedButton key="yes" title={I18n.m.getMessage('addressAdd')} onPress={this.openAddressDialog}/>,
                    ],
                });
            }
        };
        this.onSaveAddress = async (newAddress) => {
            const type = 'constructionSite';
            const project = CurrentProject.instance.getCurrentProject();
            if (project == null) {
                DialogUp.instance?.close();
                return;
            }
            try {
                const { address } = this.state;
                if (address != null && !_.isEqual(newAddress, address.address)) {
                    const c = new ChangeProjectAddress({ address: newAddress }, address.id);
                    await c.execute();
                }
                else {
                    const c = new CreateProjectAddress({ type, address: newAddress, projectId: project.id });
                    await c.execute();
                }
                DialogUp.instance?.close();
            }
            catch (e) {
                ErrorReporter.sendReport({ subject: 'Cant update construction address', data: e, type: 'warn' });
                Routing.instance.alert.post({ text: e.message });
                DialogUp.instance?.close();
            }
        };
        this.onCancelAddress = () => {
            DialogUp.instance?.close();
        };
        this.openAddressDialog = (_e) => {
            const hasServerConnection = AuthClient.instance.serverConnection !== undefined && AuthClient.instance.serverConnection.isConnected;
            Alert.instance?.close();
            DialogUp.instance?.open({
                fullscreenResponsive: Platform.OS === 'web',
                fullscreen: Platform.OS !== 'web',
                showCloseButton: !hasServerConnection,
                showCloseIcon: !hasServerConnection,
                scrollable: true,
                contentPadding: !hasServerConnection,
                onClose: this.onCancelAddress,
                scrollContainerStyle: { paddingBottom: 8 },
                content: (<AddressCardForm address={new AddressEntity({ longitude: 10, latitude: 50 })} key={uuid.v4()} change onSave={this.onSaveAddress} onCancel={this.onCancelAddress} backendURL={`${ConfigAll.b2cURL}/backend/googleapis`}/>),
            });
        };
        this.importFromTimeTracking = (_e) => {
            Routing.instance.alert.post({
                text: I18n.m.getMessage('journalImportFromTimetrackingQuestion'),
                buttons: [
                    <ContainedButton backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} key="cancel" title={I18n.m.getMessage('cancel')} onPress={() => Alert.instance?.close()}/>,
                    <ContainedButton key="importNow" title={I18n.m.getMessage('journalImportFromTimetrackingButton')} onPress={() => {
                            Alert.instance?.close(() => {
                                this.importFromTimeTrackingNow().catch((e) => console.debug('cant import', e));
                            });
                        }}/>,
                ],
            });
        };
        this.importFromTimeTrackingNow = async (_e) => {
            const { journalData, importCounter } = this.state;
            const day = JournalEntity.getNormalizedDateShortToDate(journalData.forDay.toString(10));
            const start = new Date(day.getFullYear(), day.getMonth(), day.getDate(), 0, 0, 0, 0);
            const end = new Date(day.getFullYear(), day.getMonth(), day.getDate(), 23, 59, 59, 59);
            const ttEntries = await UpmeshClient.instance.modals.timeTracking.get({
                filter: `projectId eq '${journalData.projectId}' and starts gt ${start.toISOString()} and starts lt ${end.toISOString()} and deleted ne true`,
            });
            const promises = [];
            ttEntries.forEach((t) => {
                promises.push(this.convertTimeTrackingEntryToEmployee(t));
            });
            const entries = await Promise.all(promises);
            let inserts = 0;
            let updates = 0;
            entries.forEach((e) => {
                if (e != null) {
                    e.timeStart = new Date(e.timeStart);
                    const hasEntry = journalData.employee.findIndex((emp) => {
                        emp.timeStart = new Date(emp.timeStart);
                        return (emp.employee['name'] === e.employee['name'] &&
                            emp.task === e.task &&
                            emp.timeStart.getTime() === e.timeStart.getTime());
                    });
                    if (hasEntry === -1) {
                        journalData.employee.push(e);
                        inserts += 1;
                    }
                    else {
                        journalData.employee[hasEntry] = e;
                        updates += 1;
                    }
                }
            });
            this.setState({ journalData: new JournalEntity(journalData), importCounter: importCounter + 1 }, () => {
                Routing.instance.alert.post({
                    text: I18n.m.getMessage('journalImportFromTimetrackingResult', { inserts, updates }),
                });
            });
        };
        this.convertTimeTrackingEntryToEmployee = async (t) => {
            const { journalData } = this.state;
            if (t.ends != null) {
                if (typeof t.starts === 'number') {
                    t.starts = new Date(t.starts);
                }
                if (typeof t.ends === 'number') {
                    t.ends = new Date(t.ends);
                }
                const pause = t.pause == null ? 0 : (t.pause.hours * 60 + t.pause.minutes) * 60 * 1000;
                const duration = new Date(t.ends.getTime() - t.starts.getTime() - pause);
                let filter = `companyId eq '${journalData.owner.id}' and userId eq '${t.userId}' and deleted ne true`;
                if (t.userId == null) {
                    filter = `companyId eq '${journalData.owner.id}' and memberId eq '${t.memberId}' and deleted ne true`;
                }
                const member = await UpmeshClient.instance.modals.companyMember.get({
                    filter,
                });
                let groupName = '';
                if (member.length > 0) {
                    const g = await UpmeshClient.instance.modals.group.getById(member[0].groupId);
                    groupName =
                        GroupEntity.getDefaultName() === g.groupName ? I18n.m.getMessage('companyGroupDefaultName') : g.groupName;
                }
                const task = t.taskId == null ? new TaskEntity({ mark: '' }) : await UpmeshClient.instance.modals.task.getById(t.taskId);
                if (t.userId != null) {
                    const user = await AuthClient.instance.modals.user.getById(t.userId);
                    return {
                        employee: { name: user.getFullName(), groupName },
                        task: task.mark,
                        totalTimeInMinutes: duration.getUTCHours() * 60 + duration.getUTCMinutes(),
                        timeStart: t.starts,
                        timeEnd: t.ends,
                    };
                }
                return {
                    employee: { name: `${member[0].firstName} ${member[0].lastName}`, groupName },
                    task: task.mark,
                    totalTimeInMinutes: duration.getUTCHours() * 60 + duration.getUTCMinutes(),
                    timeStart: t.starts,
                    timeEnd: t.ends,
                };
            }
            return null;
        };
        this.onPressAddAEmployeeButton = () => {
            const { employee } = this.state.journalData;
            employee.push({
                employee: { groupName: '', workerCount: 0 },
                task: '',
                totalTimeInMinutes: 0,
                timeStart: new Date(),
                timeEnd: new Date(),
            });
            this.setState((prevState) => {
                const current = prevState.journalData;
                current.employee = employee;
                return { journalData: new JournalEntity(current) };
            });
        };
        this.onPressAddAttendanceButton = () => {
            const { attendance } = this.state.journalData;
            attendance.push({
                companyName: '',
                workerCount: Number.NaN,
                timeStart: new Date(),
                timeEnd: new Date(),
                specifics: '',
            });
            this.setState((prevState) => {
                const current = prevState.journalData;
                current.attendance = attendance;
                return { journalData: new JournalEntity(current) };
            });
        };
        this.renderAdditionalFields = () => {
            const { journalSettings } = this.props;
            const { journalData, importCounter } = this.state;
            const fields = [];
            if (journalSettings != null &&
                journalSettings.additionalFields != null &&
                journalSettings.additionalFields.length > 0) {
                for (let i = 0; i < journalSettings.additionalFields.length; i += 1) {
                    const field = journalSettings.additionalFields[i];
                    let index = -1;
                    if (journalData != null && journalData.additionalFields != null && journalData.additionalFields.length > 0) {
                        index = journalData.additionalFields.findIndex((f) => f.id === field.id);
                    }
                    if (field.active || index > -1) {
                        fields.push(<View key={`field_${i}${field.name}_${importCounter}`}>
              <View style={{
                                height: 24,
                                width: '100%',
                                borderBottomWidth: ThemeManager.style.borderWidth,
                                borderBottomColor: ThemeManager.style.borderColor,
                                marginBottom: ThemeManager.style.contentPaddingValue,
                            }}/>
              <MaterialText type={MaterialTextTypes.Body1} strong>
                {journalSettings.additionalFields[i].name}
              </MaterialText>
              <View>
                <FormInputFilled accessibilityLabel={field.name} onChange={(t) => this.onChangeAdditionalField(field.id, t)} numberOfLines={2} initValue={index === -1 || journalData.additionalFields == null || journalData.additionalFields[i] == null
                                ? undefined
                                : journalData.additionalFields[i].content}/>
              </View>
            </View>);
                    }
                }
            }
            return <View>{fields}</View>;
        };
        this.renderSingleAttendance = (index, lineBelow) => {
            const { companyAutoCompleteList, journalData, importCounter } = this.state;
            const { size } = this.props;
            const width = size != null ? size.windowWidth : ResizeEvent.current.windowWidth;
            const { attendance } = journalData;
            const attendanceOfIndex = attendance[index];
            return (<View key={`attendanceData_${index}_${importCounter}`} style={{
                    ...ThemeManager.style.borderStyle,
                    borderWidth: 0,
                    borderBottomWidth: lineBelow ? 1 : 0,
                    padding: 4,
                    paddingRight: 32,
                    position: 'relative',
                    marginBottom: 8,
                }}>
        <View style={{ flexDirection: width > 600 ? 'row' : 'column', width: '100%' }}>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1 }}>
            <View style={{ flex: 1, paddingRight: 16, minWidth: 200 }}>
              <FormInputFilled labelText={I18n.m.getMessage('journalAttendanceCompany')} onChange={this.onChangeAttendanceCompany(index)} initValue={attendanceOfIndex && attendanceOfIndex.companyName ? attendanceOfIndex.companyName : undefined} autoCompleteList={companyAutoCompleteList}/>
            </View>
            <View style={{ flex: 1, paddingRight: 16, minWidth: 200 }}>
              <FormInputFilled key={`attendance_workerCount_${journalData.lastModifiedAt}_${index}_${attendanceOfIndex && attendanceOfIndex.workerCount ? attendanceOfIndex.workerCount.toFixed(2) : 'init'}`} onSubmitEditing={this.onChangeAttendanceEmployeeCount(index)} submitOnBlur labelText={I18n.m.getMessage('journalAttendanceEmployeeCount')} keyboardType="numeric" initValue={attendanceOfIndex && attendanceOfIndex.workerCount
                    ? attendanceOfIndex.workerCount.toString()
                    : undefined}/>
            </View>
          </View>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1 }}>
            <View style={{ flex: 1, paddingRight: 16, minWidth: 200 }}>
              <DateInputFormFilled labelText={I18n.m.getMessage('journalAttendanceArrival')} selectDate={false} selectTime onChange={this.onChangeAttendanceTime(index, 'arrive')} value={attendanceOfIndex && attendanceOfIndex.timeStart ? new Date(attendanceOfIndex.timeStart) : undefined}/>
            </View>
            <View style={{ flex: 1, paddingRight: 16, minWidth: 200 }}>
              <DateInputFormFilled labelText={I18n.m.getMessage('journalAttendanceDeparture')} selectDate={false} selectTime onChange={this.onChangeAttendanceTime(index, 'leave')} value={attendanceOfIndex && attendanceOfIndex.timeEnd ? new Date(attendanceOfIndex.timeEnd) : undefined}/>
            </View>
          </View>
        </View>
        <View style={{ flex: 1, paddingRight: 16 }}>
          <FormInputFilled labelText={I18n.m.getMessage('journalSpecifics')} onChange={this.onChangeAttendanceText(index)} initValue={attendanceOfIndex && attendanceOfIndex.specifics ? attendanceOfIndex.specifics : undefined}/>
        </View>
        <View style={{ position: 'absolute', top: 0, bottom: 0, right: 0, justifyContent: 'center', paddingBottom: 8 }}>
          <Icon icon="delete-outline" toolTip={I18n.m.getMessage('journalDeleteEntry')} onPress={() => {
                    const { attendance } = this.state.journalData;
                    attendance.splice(index, 1);
                    this.setState((prevState) => {
                        const current = prevState.journalData;
                        current.attendance = attendance;
                        return { journalData: new JournalEntity(current), importCounter: prevState.importCounter + 1 };
                    }, this.updateWeatherImportTime);
                }}/>
        </View>
      </View>);
        };
        this.onChangeEmployeeOrGroup = (isGroup, index) => (_i, _button) => {
            this.setState((prevState) => {
                const current = prevState.journalData;
                const { groupName } = current.employee[index].employee;
                const newEmployee = isGroup
                    ? { name: '', groupName }
                    : { groupName: groupName || '', workerCount: 0 };
                current.employee[index].employee = newEmployee;
                return { journalData: new JournalEntity(current) };
            });
        };
        this.renderSingleEmployee = (index, lineBelow) => {
            const { employee } = this.state.journalData;
            const { journalData, taskAutoCompleteList, importCounter } = this.state;
            const { size } = this.props;
            const width = size != null ? size.windowWidth : ResizeEvent.current.windowWidth;
            const employeeOfIndex = employee[index];
            return (<View key={`employeeData_${index}_${importCounter}`} style={{
                    ...ThemeManager.style.borderStyle,
                    borderWidth: 0,
                    borderBottomWidth: lineBelow ? 1 : 0,
                    padding: 0,
                    paddingRight: 32,
                    position: 'relative',
                    marginBottom: 8,
                }}>
        <View style={{ width: 312 }}>
          <SegmentedButton key={`segmentedButton${index}_${EmployeeJournal.isGroup(employeeOfIndex)}`} buttons={[
                    { title: I18n.m.getMessage('journalEmployeesGroup'), key: `group${index}` },
                    { title: I18n.m.getMessage('journalEmployeesEmployees'), key: `employees${index}` },
                ]} singleSelectSelected={EmployeeJournal.isGroup(employeeOfIndex) ? 0 : 1} accessibilityLabel="SegmentedButton" onPress={this.onChangeEmployeeOrGroup(EmployeeJournal.isGroup(employeeOfIndex), index)}/>
        </View>
        {this.renderGroupOrSingleEmployee(index, employeeOfIndex)}
        <View style={{ flexDirection: width > 600 ? 'row' : 'column', width: '100%' }}>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1 }}>
            <View style={{ flex: 1, paddingRight: 16, minWidth: 200 }}>
              <DateInputFormFilled labelText={I18n.m.getMessage('journalAttendanceArrival')} selectDate={false} selectTime onChange={this.onChangeEmployeeTime(index, 'arrive')} value={employeeOfIndex && employeeOfIndex.timeStart ? new Date(employeeOfIndex.timeStart) : undefined}/>
            </View>
            <View style={{ flex: 1, paddingRight: 16, minWidth: 200 }}>
              <DateInputFormFilled labelText={I18n.m.getMessage('journalAttendanceDeparture')} selectDate={false} selectTime onChange={this.onChangeEmployeeTime(index, 'leave')} value={employeeOfIndex && employeeOfIndex.timeEnd ? new Date(employeeOfIndex.timeEnd) : undefined}/>
            </View>
          </View>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1 }}>
            <View style={{ flex: 1, paddingRight: 16, minWidth: 200 }}>
              <FormInputFilled labelText={I18n.m.getMessage('journalEmployeesJob')} onChange={this.onChangeEmployeeTask(index)} initValue={employeeOfIndex && employeeOfIndex.task ? employeeOfIndex.task : undefined} autoCompleteList={taskAutoCompleteList}/>
            </View>
            <View style={{ flex: 1, paddingRight: 16, minWidth: 200 }}>
              <DateInputFormFilled key={`employeeTime_${journalData.lastModifiedAt}_${index}`} selectDate={false} selectTime onChange={this.onChangeEmployeeTotalTime(index)} labelText={EmployeeJournal.isGroup(employeeOfIndex)
                    ? I18n.m.getMessage('journalEmployeesHoursPerEmployee')
                    : I18n.m.getMessage('journalEmployeesHours')} value={employeeOfIndex && employeeOfIndex.totalTimeInMinutes
                    ? new Date(1970, 0, 1, Math.floor(employeeOfIndex.totalTimeInMinutes / 60), Math.floor(employeeOfIndex.totalTimeInMinutes % 60))
                    : undefined}/>
            </View>
          </View>
        </View>
        <View style={{ position: 'absolute', top: 0, bottom: 0, right: 0, justifyContent: 'center', paddingBottom: 8 }}>
          <Icon icon="delete-outline" toolTip={I18n.m.getMessage('journalDeleteEntry')} onPress={() => {
                    const { employee } = this.state.journalData;
                    employee.splice(index, 1);
                    this.setState((prevState) => {
                        const current = prevState.journalData;
                        current.employee = employee;
                        return { journalData: new JournalEntity(current), importCounter: prevState.importCounter + 1 };
                    }, this.updateWeatherImportTime);
                }}/>
        </View>
      </View>);
        };
        this.getWeatherIndex = (index) => {
            const { journalData } = this.state;
            if (journalData.weather[index].weatherCondition == null) {
                return 0;
            }
            const weatherIndex = this.weatherList.findIndex((weather) => weather.data === journalData.weather[index].weatherCondition);
            return Math.max(weatherIndex, 0);
        };
        const date = props.initialJournalData.forDay.toString(10);
        const dayStart = new Date(Number.parseInt(date.substr(0, 4), 10), Number.parseInt(date.substr(4, 2), 10) - 1, Number.parseInt(date.substr(6, 2), 10), 0, 0, 0, 0);
        this.forDay = new Date(dayStart);
        if (props.initialJournalData.ticketReportFilter == null) {
            const c = new TicketFilter();
            c.is = dayStart.getTime();
            c.ie = dayStart.getTime() + 86399999;
            props.initialJournalData.ticketReportFilter = c;
        }
        this.state = {
            ticketsForReport: [],
            journalData: new JournalEntity(props.initialJournalData),
            initialValue: new JournalEntity(props.initialJournalData),
            saveIsLoading: false,
            companyAutoCompleteList: [],
            taskAutoCompleteList: [],
            startUploads: false,
            currentUploads: 0,
            attachmentsToDelete: [],
            canI: true,
            startWeather: 8,
            endWeather: 16,
            importCounter: 0,
            journalAvailable: props.journalAvailable,
            picturePickerListSelected: props.initialJournalData.imagesPerLine - 1,
            pictureQulaityListSelected: props.initialJournalData.photoQuality === 'low'
                ? 0
                : props.initialJournalData.photoQuality === 'normal'
                    ? 1
                    : 2,
            availableTemplates: [],
            selectedIndex: { index: 0, data: '' },
        };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
        this.updateAddress().catch((err) => console.debug(err));
        this.attachKey = UpmeshClient.eventDispatcher.attach({
            readModelName: 'ProjectAddress',
            callback: (_e) => {
                this.updateAddress().catch((err) => console.debug(err));
            },
        });
    }
    componentWillUnmount() {
        if (this.attachKey != null) {
            UpmeshClient.eventDispatcher.detach('ProjectAddress', this.attachKey);
        }
    }
    async updateAddress() {
        const projectId = CurrentProject.instance.getCurrentProjectId();
        if (projectId != null) {
            const address = await UpmeshClient.instance.modals.projectAddress.get({
                filter: `projectId eq '${CurrentProject.instance.getCurrentProjectId()}' and type eq 'constructionSite'`,
            });
            if (address.length > 0 &&
                address[0].address != null &&
                address[0].address.addressInput != null &&
                address[0].address.addressInput.length > 0) {
                this.setState({ address: address[0] });
            }
        }
    }
    getUploadedAttachmentsArray(type) {
        const { journalData } = this.state;
        const imageArray = [];
        const pdfArray = [];
        if (journalData.attachments != null && journalData.attachments.length > 0) {
            for (let i = 0; i < journalData.attachments.length; i += 1) {
                const fileExt = path.extname(journalData.attachments[i].fileId).toLowerCase();
                if (fileExt !== '.pdf' && type === 'image') {
                    imageArray.push({
                        name: journalData.attachments[i].name,
                        targetUrl: `${UpmeshClient.instance.url}/journal/${journalData.id}/attachment`,
                        id: journalData.attachments[i].fileId,
                    });
                }
                if (fileExt === '.pdf' && type === 'pdf') {
                    pdfArray.push({
                        name: journalData.attachments[i].name,
                        targetUrl: `${UpmeshClient.instance.url}/journal/${journalData.id}/attachment`,
                        id: journalData.attachments[i].fileId,
                    });
                }
            }
        }
        return type === 'image' ? imageArray : pdfArray;
    }
    componentDidUpdate(_prevProps, prevState) {
        if (!this.state.canI && prevState.canI) {
            Routing.instance.alert.post({ text: I18n.m.getMessage('forbidden') });
            Dialog.instance?.close();
        }
    }
    getTemplateList() {
        const { availableTemplates } = this.state;
        const list = [];
        list.push({ title: I18n.m.getMessage('journalSettingsTemplateDefault'), data: '' });
        availableTemplates.sort();
        for (const template of availableTemplates) {
            list.push({ title: template.title, data: template.id });
        }
        return list;
    }
    render() {
        const { selectedDate, previewIsLoading, createVersionIsLoading } = this.props;
        const { journalData, startUploads, saveIsLoading, currentUploads, canI, journalAvailable, picturePickerListSelected, pictureQulaityListSelected, importCounter, selectedIndex, attachmentsToDelete, } = this.state;
        if (!canI) {
            return null;
        }
        const { connectedToServer } = this.context;
        const equal = currentUploads === 0 &&
            attachmentsToDelete.length === 0 &&
            JSON.stringify(this.state.initialValue) === JSON.stringify(this.state.journalData);
        return [
            <DialogTitle key="journalTitle" iconRight={{
                    icon: 'close',
                    disabled: saveIsLoading || createVersionIsLoading || previewIsLoading,
                    toolTip: I18n.m.getMessage('close'),
                    onPress: () => {
                        if (!equal) {
                            Routing.instance.alert.post({
                                text: I18n.m.getMessage('unsavedChangesInDialog'),
                                buttons: [
                                    <ContainedButton key="discard" title={I18n.m.getMessage('discard')} backgroundColor={ThemeManager.style.brandDanger} onPress={() => {
                                            Alert.instance?.close(() => {
                                                Dialog.instance?.close();
                                            });
                                        }}/>,
                                    <ContainedButton key="save" title={I18n.m.getMessage('save')} onPress={() => {
                                            Alert.instance?.close(() => {
                                                this.saveNow()
                                                    .then(() => {
                                                    Dialog.instance?.close();
                                                })
                                                    .catch((e) => {
                                                    DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m);
                                                });
                                            });
                                        }}/>,
                                ],
                            });
                        }
                        else {
                            Dialog.instance?.close();
                        }
                    },
                }} additionalIcons={[
                    {
                        icon: 'calendar-export',
                        toolTip: I18n.m.getMessage('journalImportFromOthers'),
                        onPress: this.copyReportsDialog,
                    },
                ]}>
        {I18n.m.getMessage('journalDialogHeader', { date: I18n.m.dateCurrent.localeDateString(selectedDate) })}
      </DialogTitle>,
            <DialogContent key="journalContent">
        <View key="inside_scroll">
          <MaterialText type={MaterialTextTypes.Body1} strong>
            {I18n.m.getMessage('journalEmployees')}
          </MaterialText>
          {this.renderEmployees()}
          <View style={{
                    height: 24,
                    width: '100%',
                    borderBottomWidth: ThemeManager.style.borderWidth,
                    borderBottomColor: ThemeManager.style.borderColor,
                    marginBottom: ThemeManager.style.contentPaddingValue,
                }}/>
          <MaterialText type={MaterialTextTypes.Body1} strong>
            {I18n.m.getMessage('journalAttendances')}
          </MaterialText>
          {this.renderAttendances()}
          <View style={{
                    height: 24,
                    width: '100%',
                    borderBottomWidth: ThemeManager.style.borderWidth,
                    borderBottomColor: ThemeManager.style.borderColor,
                    marginBottom: ThemeManager.style.contentPaddingValue,
                }}/>
          <MaterialText type={MaterialTextTypes.Body1} strong>
            {I18n.m.getMessage('journalWeather')}
          </MaterialText>
          {this.renderWeather()}
          <View style={{
                    height: 24,
                    width: '100%',
                    borderBottomWidth: ThemeManager.style.borderWidth,
                    borderBottomColor: ThemeManager.style.borderColor,
                    marginBottom: ThemeManager.style.contentPaddingValue,
                }}/>
          <MaterialText type={MaterialTextTypes.Body1} strong>
            {I18n.m.getMessage('journalWorkDone')}
          </MaterialText>
          <FormInputFilled key={`workDone${importCounter}`} onChange={this.onChangeWorkDone} numberOfLines={4} initValue={journalData.workDone} accessibilityLabel="WorkDone"/>
          <View style={{
                    height: 24,
                    width: '100%',
                    borderBottomWidth: ThemeManager.style.borderWidth,
                    borderBottomColor: ThemeManager.style.borderColor,
                    marginBottom: ThemeManager.style.contentPaddingValue,
                }}/>
          <MaterialText type={MaterialTextTypes.Body1} strong>
            {I18n.m.getMessage('journalSpecialOccurrences')}
          </MaterialText>
          <View>
            <FormInputFilled key={`specialOccurrences_${importCounter}`} labelText={I18n.m.getMessage('journalSpecialOccurrencesPlaceholder')} onChange={this.onChangeSpecialOccurrences} numberOfLines={4} initValue={journalData.specialOccurrences}/>
          </View>
          {this.renderAdditionalFields()}
          <View style={{
                    height: 24,
                    width: '100%',
                    borderBottomWidth: ThemeManager.style.borderWidth,
                    borderBottomColor: ThemeManager.style.borderColor,
                    marginBottom: ThemeManager.style.contentPaddingValue,
                }}/>
          <MaterialText type={MaterialTextTypes.Body1} strong>
            {I18n.m.getMessage('journalTicketReport')}
          </MaterialText>
          <View style={{ flexDirection: 'row' }}>
            <Checkbox value={journalData.includeTicketReport} onChange={this.setTicketReport}/>
            <MaterialText centeredBox>{I18n.m.getMessage('journalTicketReportInclude')}</MaterialText>
            <Icon onPress={this.openReportFilter} toolTip={I18n.m.getMessage('journalTicketReportFilter')} icon={journalData.ticketReportFilter != null && TicketFilter.isSet(journalData.ticketReportFilter)
                    ? 'filter-remove'
                    : 'filter-outline'} color={journalData.ticketReportFilter != null && TicketFilter.isSet(journalData.ticketReportFilter)
                    ? ThemeManager.style.brandPrimary
                    : undefined} disabled={!journalData.includeTicketReport || saveIsLoading || createVersionIsLoading || previewIsLoading}/>
            <Icon onPress={this.openReportDialog} toolTip={I18n.m.getMessage('journalTicketReportSettings')} icon="cog-outline" disabled={!journalData.includeTicketReport ||
                    saveIsLoading ||
                    createVersionIsLoading ||
                    previewIsLoading ||
                    selectedIndex.data.length > 0}/>
            <Icon onPress={this.openTicketReportPreview} toolTip={I18n.m.getMessage('journalTicketReportPreview')} icon="file-eye-outline" disabled={saveIsLoading ||
                    createVersionIsLoading ||
                    previewIsLoading ||
                    this.state.ticketsForReport.length === 0 ||
                    !journalData.includeTicketReport}/>
          </View>
          <FormInputPicker list={this.getTemplateList()} onChange={this.onChangeTemplatePicker} disabled={!journalData.includeTicketReport} selectedIndex={selectedIndex.index}/>
          {this.state.ticketsForReport.length === 0 ? (<MaterialText>{I18n.m.getMessage('journalNoTicketsChangedToday')}</MaterialText>) : undefined}
          <View style={{
                    height: 24,
                    width: '100%',
                    borderBottomWidth: ThemeManager.style.borderWidth,
                    borderBottomColor: ThemeManager.style.borderColor,
                    marginBottom: ThemeManager.style.contentPaddingValue,
                }}/>
          <MaterialText type={MaterialTextTypes.Body1} strong>
            {I18n.m.getMessage('journalImageText')}
          </MaterialText>
          {selectedIndex.data.length > 0 ? null : (<FormInputPicker list={this.qualityPickerList} labelText={I18n.m.getMessage('journalImageQuality')} selectedIndex={pictureQulaityListSelected} accessibilityLabel={I18n.m.getMessage('journalImageQuality')} onChange={this.onChangePictureQualitySelected}/>)}
          {selectedIndex.data.length > 0 ? null : (<FormInputPicker list={this.picturePickerList} labelText={I18n.m.getMessage('journalImagePerLine')} selectedIndex={picturePickerListSelected} accessibilityLabel={I18n.m.getMessage('journalImagePerLine')} onChange={this.onChangeImagePerLine}/>)}
          <ProjectUploadButton beforeUpload={this.beforeUpload} disableUpload={!connectedToServer || startUploads} uploadIcon="cloud-upload-outline" startUploadImmediately={false} startNow={startUploads} onUploading={this.onUploadStatus} id={`journalUploadButton_${selectedDate}_image`} multiple filter={['images']} deleteFunction={this.deleteAttachments} uploaded={this.getUploadedAttachmentsArray('image')} uploadText={I18n.m.getMessage('journalImageAddText')} accessibilityLabel={I18n.m.getMessage('journalImageAddText')} endpointUrl={Url.getURLfromString(`${UpmeshClient.instance.url}/journal/${journalData.id}/attachment`)}/>
          <View style={{
                    height: 24,
                    width: '100%',
                    borderBottomWidth: ThemeManager.style.borderWidth,
                    borderBottomColor: ThemeManager.style.borderColor,
                    marginBottom: ThemeManager.style.contentPaddingValue,
                }}/>
          <MaterialText type={MaterialTextTypes.Body1} strong>
            {I18n.m.getMessage('journalAttachments')}
          </MaterialText>
          <ProjectUploadButton beforeUpload={this.beforeUpload} disableUpload={!connectedToServer || startUploads} uploadIcon="cloud-upload-outline" startUploadImmediately={false} startNow={startUploads} onUploading={this.onUploadStatus} id={`journalUploadButton_${selectedDate}`} multiple filter={['pdf']} deleteFunction={this.deleteAttachments} uploaded={this.getUploadedAttachmentsArray('pdf')} uploadText={I18n.m.getMessage('journalAddAttachment')} accessibilityLabel={I18n.m.getMessage('journalAddAttachment')} endpointUrl={Url.getURLfromString(`${UpmeshClient.instance.url}/journal/${journalData.id}/attachment`)}/>
        </View>
      </DialogContent>,
            <DialogActions key="journalAction">
        <ContainedButton title={I18n.m.getMessage('journalSave')} onPress={this.onSaveAsDraft} loading={saveIsLoading} disabled={createVersionIsLoading || previewIsLoading || equal}/>
        <ContainedButton disabled={saveIsLoading || previewIsLoading || !equal || !connectedToServer || !journalAvailable} title={I18n.m.getMessage('journalCreate')} onPress={this.onCreateVersion} loading={createVersionIsLoading}/>
      </DialogActions>,
        ];
    }
    async saveNow() {
        try {
            const { journalData, attachmentsToDelete } = this.state;
            if (attachmentsToDelete.length > 0) {
                while (attachmentsToDelete.length > 0) {
                    const s = attachmentsToDelete.shift();
                    if (s != null) {
                        try {
                            await this.deleteAttachmentsNow(s);
                        }
                        catch (err) {
                            console.debug('cant remove attachment', err);
                        }
                    }
                }
            }
            if (journalData.weather.length > 1) {
                journalData.weather.sort((a, b) => {
                    a.time = new Date(a.time);
                    b.time = new Date(b.time);
                    return a.time.getHours() * 60 + a.time.getMinutes() - (b.time.getHours() * 60 + b.time.getMinutes());
                });
            }
            const save = new SaveJournal({ ...journalData, forDay: journalData.forDay.toString() }, journalData.id, CurrentUser.token);
            await new Promise((r, reject) => {
                UpmeshClient.eventDispatcher.attach({
                    once: true,
                    attachKey: `journalDialog${save.entityId}`,
                    entityId: save.entityId,
                    readModelName: 'Journal',
                    callback: (_a) => {
                        r();
                    },
                });
                save.execute().catch((err) => {
                    UpmeshClient.eventDispatcher.detach('Journal', `journalDialog${save.entityId}`);
                    reject(err);
                });
            });
            try {
                const js = await UpmeshClient.instance.modals.journal.getById(journalData.id);
                return new JournalEntity(js);
            }
            catch (err) {
                return new JournalEntity(journalData);
            }
        }
        catch (e) {
            this.setState({ saveIsLoading: false, startUploads: false });
            DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m);
            return undefined;
        }
    }
    renderAttendances() {
        const { attendance } = this.state.journalData;
        const allAttendances = [];
        for (let i = 0; i < attendance.length; i += 1) {
            allAttendances.push(this.renderSingleAttendance(i, i < attendance.length - 1));
        }
        return (<View>
        {allAttendances}
        {this.renderPlusButton(this.onPressAddAttendanceButton, I18n.m.getMessage('journalAttendanceAdd'))}
      </View>);
    }
    renderEmployees() {
        const { employee } = this.state.journalData;
        const allEmployees = [];
        for (let i = 0; i < employee.length; i += 1) {
            allEmployees.push(this.renderSingleEmployee(i, i < employee.length - 1));
        }
        const { createVersionIsLoading, previewIsLoading } = this.props;
        const { saveIsLoading } = this.state;
        const { size } = this.props;
        const width = size != null ? size.windowWidth : ResizeEvent.current.windowWidth;
        const disabled = saveIsLoading || createVersionIsLoading || previewIsLoading;
        return (<View>
        {allEmployees}
        <View style={{ width: '100%', flexDirection: width > 720 ? 'row' : 'column' }}>
          {this.renderPlusButton(this.onPressAddAEmployeeButton, I18n.m.getMessage('journalEmployeeAdd'))}
          {CompanyUserInfo.companySettings != null && CompanyUserInfo.companySettings.hasModule('timeTracking') ? (<View style={{ marginLeft: width > 720 ? 8 : 0, marginTop: width > 720 ? 0 : 8 }}>
              <ContainedButton icon={{ icon: 'timer-outline', toolTip: '' }} title={I18n.m.getMessage('journalImportFromTimetracking')} onPress={this.importFromTimeTracking} disabled={disabled}/>
            </View>) : null}
        </View>
      </View>);
    }
    renderGroupOrSingleEmployee(index, employeeOfIndex) {
        const fIs = [];
        const { journalData } = this.state;
        if (!EmployeeJournal.isGroup(employeeOfIndex)) {
            const e = employeeOfIndex.employee;
            fIs.push(<View style={{ flex: 1, paddingRight: 16, minWidth: 200 }}>
          <FormInputFilled autoCompleteList={CompanyUserInfo.companyMemberNames} autoCompleteWeb="employee" labelText={I18n.m.getMessage('journalEmployeeName')} onChange={this.onChangeEmployeeName(index)} initValue={employeeOfIndex && e.name != null ? e.name : undefined}/>
        </View>);
        }
        fIs.push(<View style={{ flex: 1, paddingRight: 16, minWidth: 200 }}>
        <FormInputFilled labelText={I18n.m.getMessage('journalEmployeesGroup')} onChange={this.onChangeEmployeeGroup(index)} initValue={employeeOfIndex && employeeOfIndex.employee.groupName ? employeeOfIndex.employee.groupName : undefined}/>
      </View>);
        if (EmployeeJournal.isGroup(employeeOfIndex)) {
            const g = employeeOfIndex.employee;
            fIs.push(<View style={{ flex: 1, paddingRight: 16, minWidth: 200 }}>
          <FormInputFilled key={`employee_workerCount_${journalData.lastModifiedAt}_${index}_${g && g.workerCount ? g.workerCount.toFixed(2) : 'init'}`} onSubmitEditing={this.onChangeEmployeeCount(index)} submitOnBlur labelText={I18n.m.getMessage('journalAttendanceEmployeeCount')} keyboardType="numeric" initValue={g.workerCount.toString()}/>
        </View>);
        }
        return <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>{fIs}</View>;
    }
    renderSingleWeather(index, lineBelow) {
        const { weather } = this.state.journalData;
        const { journalData, importCounter } = this.state;
        const { size } = this.props;
        const width = size != null ? size.windowWidth : ResizeEvent.current.windowWidth;
        const weatherOfIndex = weather[index];
        const weatherTimeInput = weather.length === 1 ? (<FormInputFilled labelText={I18n.m.getMessage('journalWeatherTime')} disabled initValue={I18n.m.getMessage('journalWeatherAllDay')}/>) : (<DateInputFormFilled startDate={weather[index].time} labelText={I18n.m.getMessage('journalWeatherTime')} onChange={this.onChangeWeatherTime(index)} selectTime selectDate={false} value={weatherOfIndex && weatherOfIndex.time ? new Date(weatherOfIndex.time) : undefined}/>);
        return (<View style={{
                ...ThemeManager.style.borderStyle,
                borderWidth: 0,
                borderBottomWidth: lineBelow ? 1 : 0,
                padding: 4,
                paddingRight: 32,
                position: 'relative',
                marginBottom: 8,
            }} key={`WeatherData_${index}_${importCounter}`}>
        <View style={{ flexDirection: width > 600 ? 'row' : 'column', width: '100%' }}>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1 }}>
            <View style={{ flex: 1, paddingRight: 16, minWidth: 140 }}>{weatherTimeInput}</View>
            <View style={{ flex: 1, paddingRight: 16, minWidth: 140 }}>
              <FormInputPicker list={this.weatherList} labelText={I18n.m.getMessage('journalWeatherConditions')} onChange={this.onChangeWeatherType(index)} selectedIndex={this.getWeatherIndex(index)}/>
            </View>
          </View>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', flex: 1 }}>
            <View style={{ flex: 1, paddingRight: 16, minWidth: 140 }}>
              <FormInputFilled key={`temperatureLow_${journalData.lastModifiedAt}_${index}_${weatherOfIndex && weatherOfIndex.temperatureLow ? weatherOfIndex.temperatureLow.toFixed(2) : 'init'}`} onSubmitEditing={this.onChangeWeatherTemp(index, 'low')} submitOnBlur initValue={weatherOfIndex != null && weatherOfIndex.temperatureLow != null
                ? weatherOfIndex.temperatureLow.toString()
                : undefined} labelText={I18n.m.getMessage('journalWeatherTemperatureLow')} keyboardType="numeric"/>
            </View>
            <View style={{ flex: 1, paddingRight: 16, minWidth: 140, flexDirection: 'row', paddingLeft: 8 }}>
              <FormInputFilled key={`temperatureHigh_${journalData.lastModifiedAt}_${index}_${weatherOfIndex && weatherOfIndex.temperatureHigh ? weatherOfIndex.temperatureHigh.toFixed(2) : 'init'}`} onSubmitEditing={this.onChangeWeatherTemp(index, 'high')} submitOnBlur initValue={weatherOfIndex != null &&
                weatherOfIndex.temperatureHigh != null &&
                !Number.isNaN(weatherOfIndex.temperatureHigh)
                ? weatherOfIndex.temperatureHigh.toString()
                : undefined} labelText={I18n.m.getMessage('journalWeatherTemperatureHigh')} keyboardType="numeric"/>
            </View>
          </View>
        </View>
        <View style={{ flex: 1, paddingRight: 16 }}>
          <FormInputFilled initValue={weatherOfIndex != null && weatherOfIndex.specifics != null ? weatherOfIndex.specifics : undefined} labelText={I18n.m.getMessage('journalSpecifics')} onChange={this.onChangeWeatherSpecifics(index)}/>
        </View>
        <View style={{ position: 'absolute', top: 0, bottom: 0, right: 0, justifyContent: 'center', paddingBottom: 8 }}>
          <Icon icon="delete-outline" toolTip={I18n.m.getMessage('journalDeleteEntry')} onPress={this.deleteWeather(index)}/>
        </View>
      </View>);
    }
    renderWeather() {
        const { size } = this.props;
        const width = size != null ? size.windowWidth : ResizeEvent.current.windowWidth;
        const { weather } = this.state.journalData;
        const weatherEntries = [];
        for (let i = 0; i < weather.length; i += 1) {
            weatherEntries.push(this.renderSingleWeather(i, i < weather.length - 1));
        }
        return (<View>
        {weatherEntries}
        <View style={{ width: '100%', flexDirection: width > 720 ? 'row' : 'column' }}>
          {this.renderPlusButton(() => {
                const { weather } = this.state.journalData;
                weather.push({
                    time: new Date(),
                    temperatureLow: Number.NaN,
                    temperatureHigh: Number.NaN,
                    weatherCondition: 'clear',
                    specifics: '',
                });
                this.setState((prevState) => {
                    const current = prevState.journalData;
                    current.weather = weather;
                    return { journalData: new JournalEntity(current) };
                });
            }, I18n.m.getMessage('journalWeatherAdd'))}
          <View style={{ marginLeft: width > 720 ? 8 : 0, marginTop: width > 720 ? 0 : 8 }}>
            <ContainedButton icon={{ icon: 'weather-partly-snowy-rainy', toolTip: '' }} title={I18n.m.getMessage('journalImportFromWeather')} onPress={this.importFromWeather} disabled={false}/>
          </View>
        </View>
      </View>);
    }
    renderPlusButton(onPress, buttonText) {
        const { createVersionIsLoading, previewIsLoading } = this.props;
        const { saveIsLoading } = this.state;
        const disabled = saveIsLoading || createVersionIsLoading || previewIsLoading;
        return (<View>
        <ContainedButton icon={{ icon: 'plus', toolTip: '' }} title={buttonText} onPress={onPress} disabled={disabled}/>
      </View>);
    }
}
JournalDialog.contextType = ConnectionContext;
