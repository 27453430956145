import { ContentFooterEventHandler, } from 'materialTheme/src/theme/components/footer/ContentFooterEventHandler';
import { Page } from 'materialTheme/src/theme/components/Page';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ProjectEntity } from 'upmesh-core/src/client/query/entities/ProjectEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { DateLocale } from 'upmesh-i18n/src/i18n/DateLocale';
import * as uuid from 'uuid';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { FilesView } from '../file/FilesView';
import { JournalView } from '../journal/JournalView';
import { LogBookView } from '../logbook/LogBookView';
import { PlanSplitView } from '../plans/PlanSplitView';
import { PlansView } from '../plans/PlansView';
import { CurrentProject } from '../project/CurrentProject';
import { ProjectView } from '../project/ProjectView';
import { ProjectSettingsView } from '../project/settings/ProjectSettingsView';
import { GlobalBar } from '../root/GlobalBar';
import { Redirect } from '../root/Redirect';
import { SidePanel } from '../root/SidePanel';
import { TeamView } from '../team/TeamView';
import { TicketsView } from '../tickets/TicketsView';
export class RoutesConfigProtectedProjects extends PureComponent {
    get projectId() {
        if (this.state.project != null) {
            return this.state.project.id;
        }
        return '';
    }
    constructor(props) {
        super(props);
        this.handleChange = async (changed) => {
            if (this.mounted) {
                this.setState({ project: changed, loading: false });
            }
        };
        this.mounted = true;
        this.onProjectChanges = (e) => {
            this.setState({ project: e });
        };
        this.init2 = async (projectId) => {
            CurrentProject.instance.setProjectId(projectId);
            ContentFooterEventHandler.statusEvent.detach(this.footerStatus);
            ContentFooterEventHandler.statusEvent.attach(this.footerStatus);
            RoutesConfigProtectedProjects._instance = this;
            await DateLocale.waitFor(() => CurrentProject.instance.loaded, 33, 50000);
            const project = CurrentProject.instance.getCurrentProject();
            if (project != null) {
                if (project.messagesOverride != null)
                    I18n.m.messagesOverride = project.messagesOverride;
                else
                    I18n.m.messagesOverride = {};
                this.setState({ project, init: true, loading: false });
            }
            else {
                Routing.instance.goTo('/projects');
            }
        };
        this.footerStatus = (e) => {
            this.setState({ footerOpen: e.open });
        };
        this.state = {
            loading: true,
            footerOpen: false,
            init: false,
        };
        this._views = {
            project: (_props) => <ProjectView {..._props} key={`dash_${this.projectId}`}/>,
            team: (_props) => <TeamView {..._props} key={`team_${this.projectId}`}/>,
            tickets: (_props) => (<TicketsView planId={_props.pathvars.planId} {..._props} key={`tickets_${this.projectId}`}/>),
            plans: (_props) => {
                if (_props.pathvars.planId != null && _props.pathvars.planId.length > 0) {
                    return (<PlanSplitView planId={_props.pathvars.planId} tab={_props.pathvars.tab} versionId={_props.pathvars.versionId} projectId={this.projectId} {..._props} key={`planSplit_${this.projectId}`}/>);
                }
                return <PlansView projectId={this.projectId} q={_props.q} {..._props} key={`plans_${this.projectId}`}/>;
            },
            files: (_props) => (<FilesView folder={_props.pathvars['planId']} projectId={this.projectId} {..._props} key={`files_${this.projectId}`}/>),
            logbook: (_props) => <LogBookView projectId={this.projectId} {..._props} key={`logbook_${this.projectId}`}/>,
            projectsettings: (_props) => (<ProjectSettingsView projectId={this.projectId} {..._props} key={`settings_${this.projectId}`}/>),
            dailyreports: (_props) => (<JournalView projectId={this.projectId} {..._props} key={`reports_${this.projectId}`}/>),
        };
    }
    componentDidMount() {
        this.mounted = true;
        CurrentProject.projectChanged.attach(this.onProjectChanges);
        this.init().catch((err) => console.debug(err));
    }
    async init() {
        await DateLocale.waitFor(() => UpmeshClient.instance.serverConnection != null);
        await DateLocale.waitFor(() => CurrentUser.userId != null && CurrentUser.userId.length > 0, 33, 15000);
        if (CurrentUser.userId == null || CurrentUser.userId.length === 0) {
            Routing.instance.goTo('/login', true);
            return;
        }
        await DateLocale.waitFor(() => UpmeshClient.instance.ready, 33, 15000);
        if (!UpmeshClient.instance.ready) {
            Routing.instance.goTo('/login', true);
            return;
        }
        const { projectId } = this.props.pathvars;
        let catched = false;
        try {
            await ProjectEntity.checkProjectStatusAndGeneralAccess(projectId, CurrentUser.userId);
        }
        catch (e) {
            if (e.messageCode !== 'projectMaximumSizeReached') {
                catched = await DefaultErrorHandler.getProjectErrors(projectId);
            }
        }
        if (catched) {
            requestAnimationFrame(() => {
                Routing.instance.goTo('/projects');
            });
            return;
        }
        requestAnimationFrame(() => {
            this.init2(projectId).catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
        });
    }
    componentWillUnmount() {
        this.mounted = false;
        I18n.m.messagesOverride = {};
        CurrentProject.instance.setProjectId(null);
        CurrentProject.projectChanged.detach(this.onProjectChanges);
        this.stopChangeListener();
    }
    componentDidUpdate(_prevProps, _prevState, _snapshot) {
        if (!this.state.loading &&
            (this.state.project == null || this.props.pathvars.projectId !== this.state.project.id)) {
            this.setState({ loading: true, project: undefined }, async () => {
                CurrentProject.instance.setProjectId(this.props.pathvars.projectId);
                await DateLocale.waitFor(() => CurrentProject.instance.loaded, 33, 50000);
                const project = CurrentProject.instance.getCurrentProject();
                if (project != null) {
                    if (project.messagesOverride != null)
                        I18n.m.messagesOverride = project.messagesOverride;
                    else
                        I18n.m.messagesOverride = {};
                    this.setState({ project, init: true, loading: false });
                }
                else {
                    Routing.instance.goTo('/projects');
                }
            });
        }
    }
    startChangeListener() {
        this.stopChangeListener();
        if (this.mounted) {
            CurrentProject.projectChanged.attach(this.handleChange);
        }
    }
    stopChangeListener() {
        try {
            CurrentProject.projectChanged.detach(this.handleChange);
        }
        catch (e) {
            console.debug(e);
        }
    }
    static get instance() {
        return RoutesConfigProtectedProjects._instance;
    }
    static get footerOpen() {
        if (RoutesConfigProtectedProjects._instance != null) {
            return RoutesConfigProtectedProjects._instance.state.footerOpen;
        }
        return false;
    }
    render() {
        const { size } = this.props;
        const { project } = this.state;
        if (!this.state.init || this.state.loading || this.state.project == null) {
            return (<Page headerTitle="...">
          <Spinner />
        </Page>);
        }
        const bigScreen = ThemeManager.style.breakpointM >= size.windowWidth;
        const left = bigScreen ? 0 : ThemeManager.style.getScreenRelativePixelSize(56);
        const globalBar = bigScreen ? null : (<View style={{
                position: 'relative',
                left: 0,
                right: 0,
                height: ThemeManager.style.headerHeight,
                backgroundColor: ThemeManager.style.appBgColor,
            }}>
        <GlobalBar size={size} currentProjectId={this.state.project.id} user={CurrentUser.entity} hideLogo/>
      </View>);
        const { page } = this.props.pathvars;
        if (this.state.project.locked != null && this.state.project.locked) {
            if (page !== 'project') {
                return <Redirect key={uuid.v1()} replace to={`/projects/${this.state.project.id}/project`}/>;
            }
        }
        if (typeof this._views[page] !== 'function') {
            return <Redirect key={uuid.v1()} replace to={`/projects/${this.state.project.id}/project`}/>;
        }
        return (<View collapsable={false} style={{
                ...ThemeManager.style.absoluteStyle,
                left: size.contentLeft,
                right: size.contentRight,
                top: size.contentTop,
                bottom: size.contentBottom,
                position: 'absolute',
                flexDirection: 'row',
            }}>
        <View style={{
                left,
                height: size.windowHeight,
                width: size.contentWidth - left,
                position: 'relative',
                flexDirection: 'column',
            }}>
          {globalBar}
          <View style={{
                width: '100%',
                height: globalBar != null ? size.contentHeight - ThemeManager.style.headerHeight : size.contentHeight,
                position: 'relative',
                backgroundColor: ThemeManager.style.appBgColor,
            }}>
            {project == null ? (<Spinner />) : (this._views[page]({
                project: { ...project },
                ...this.props,
                key: `${page}_${project.id}}`,
                size: {
                    ...size,
                    contentWidth: size.contentWidth - left,
                    contentHeight: globalBar != null ? size.contentHeight - ThemeManager.style.headerHeight : size.contentHeight,
                    contentLeft: left,
                },
            }))}
          </View>
        </View>
        <SidePanel currentProjectId={this.state.project.id} size={this.props.size}/>
      </View>);
    }
}
