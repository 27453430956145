var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TempFile = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _OdataEntity2 = require("odatarepos/build/src/entities/root/OdataEntity");
var uuid = _interopRequireWildcard(require("uuid"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var TempFile = exports.TempFile = function (_OdataEntity) {
  function TempFile(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, TempFile);
    _this = _callSuper(this, TempFile, [obj]);
    _this.entityName = 'TempFile';
    _this.dBOptions;
    if (obj != null && obj.name != null && obj.id == null) {
      obj.id = `${uuid.v1()}${obj.name.substr(obj.name.lastIndexOf('.'))}`;
    }
    _this.fill(obj);
    return _this;
  }
  (0, _inherits2.default)(TempFile, _OdataEntity);
  return (0, _createClass2.default)(TempFile, [{
    key: "createOne",
    value: function createOne(obj) {
      return new TempFile(obj);
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = _OdataEntity2.OdataEntity.createBaseMap();
      baseMap.set('targetUrl', new _OdataEntity2.OdataIndex());
      baseMap.set('file', new _OdataEntity2.OdataIndex());
      baseMap.set('preview', new _OdataEntity2.OdataIndex());
      baseMap.set('name', new _OdataEntity2.OdataIndex());
      baseMap.set('prio', new _OdataEntity2.OdataIndex(true));
      baseMap.set('maxImageSize', new _OdataEntity2.OdataIndex());
      baseMap.set('type', new _OdataEntity2.OdataIndex());
      baseMap.set('data', new _OdataEntity2.OdataIndex());
      baseMap.set('error', new _OdataEntity2.OdataIndex());
      return baseMap;
    }
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {
        return true;
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "fill",
    value: function fill(obj) {
      (0, _get2.default)((0, _getPrototypeOf2.default)(TempFile.prototype), "fill", this).call(this, obj);
    }
  }]);
}(_OdataEntity2.OdataEntity);