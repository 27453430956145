import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { View } from 'react-native';
import React, { useMemo, useRef, useState } from 'react';
import { Switch } from 'materialTheme/src/theme/components/Switch';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { SUPPORTED_EXPORTS, } from 'upmesh-core/src/server/webserver/commands/timetracking/ExportInformation';
import { TimeTrackingEntity } from 'upmesh-core/src/client/query/entities/TimeTrackingEntity';
import { I18n } from '../../i18n/I18n';
import { NovalineOptions } from './NovalineOptions';
import { BRZOptions } from './BRZOptions';
import { AddisonOptions } from './AddisonOptions';
import { DatevLodasOptions } from './DatevLodasOptions';
import { TimeTrackingDownload } from './TimeTrackingDownload';
import { DatevLohnOptions } from './DatevLohnOptions';
const calcExportOptions = () => {
    const result = [];
    for (const key in SUPPORTED_EXPORTS) {
        const exportType = SUPPORTED_EXPORTS[key];
        result.push(I18n.m.getMessage(exportType.titleKey));
    }
    return result;
};
export function TimeTrackingExportDialog(props) {
    const [exportLock, setExportLock] = useState(false);
    const [includeAbsence, setIncludeAbsence] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [warnings, setWarnings] = useState([]);
    const exportOptions = useMemo(() => calcExportOptions(), []);
    const exportInformationRef = useRef(null);
    const download = async () => {
        const exportEntries = props.times ?? [];
        const tmpExportInformation = exportInformationRef.current ?? SUPPORTED_EXPORTS.csv;
        tmpExportInformation.inlcudedAbsences = [];
        tmpExportInformation.includeAbsences = includeAbsence;
        if (includeAbsence) {
            if (props.absenceAsTimeTracking != null && props.absenceAsTimeTracking.length > 0) {
                tmpExportInformation.inlcudedAbsences = props.absenceAsTimeTracking.map((combined) => new TimeTrackingEntity(combined.orgEntry));
            }
        }
        const tmpWarn = await TimeTrackingDownload.downloadCSV(exportEntries, exportLock, tmpExportInformation);
        if (tmpWarn.length === 0) {
            Dialog.instance?.close();
        }
        setWarnings(tmpWarn);
    };
    const onChangeExtraData = (data) => {
        if (data != null) {
            exportInformationRef.current = data;
        }
    };
    const renderExtraOptions = () => {
        switch (selectedIndex) {
            case 0:
                exportInformationRef.current = SUPPORTED_EXPORTS.csv;
                return null;
            case 1:
                return <BRZOptions onChange={onChangeExtraData}/>;
            case 2:
                return <AddisonOptions onChange={onChangeExtraData}/>;
            case 3:
                exportInformationRef.current = SUPPORTED_EXPORTS.gdi;
                return null;
            case 4:
                return <NovalineOptions onChange={onChangeExtraData}/>;
            case 5:
                return <DatevLodasOptions onChange={onChangeExtraData}/>;
            case 6:
                return <DatevLohnOptions onChange={onChangeExtraData}/>;
            default:
                return null;
        }
    };
    const renderWarnings = () => {
        if (warnings.length === 0)
            return [];
        const result = [];
        result.push(<MaterialText key="timeTrackingWarningHelper">{I18n.m.getMessage('timeTrackingExportWarningText')}</MaterialText>);
        for (const warning of warnings) {
            result.push(<MaterialText key={`timeTrackingWarnings_${warning.messageCode.toString()}`} type={MaterialTextTypes.Body2} color={ThemeManager.style.brandDanger}>
          {I18n.m.getMessage(warning.messageCode, { error: warning.messageData['error'] })}
        </MaterialText>);
        }
        return result;
    };
    const numberOfExportEntries = () => {
        if (includeAbsence) {
            const numberOfAbsence = props.absenceAsTimeTracking?.length ?? 0;
            return props.times.length + numberOfAbsence;
        }
        return props.times.length;
    };
    return (<>
      <DialogTitle iconRight={{
            icon: props.filterActive ? 'filter-remove' : 'filter-outline',
            onPress: props.openFilter,
            toolTip: '',
            color: props.filterActive ? ThemeManager.style.brandPrimary : 'white',
        }}>
        {I18n.m.getMessage('timeTrackingExportTitle')}
      </DialogTitle>
      <DialogContent>
        <View>
          <MaterialText>
            {I18n.m.getMessage('timeTrackingCSVexportText', { numberOfExportEntries: numberOfExportEntries() })}
          </MaterialText>
          {!props.payroll ? null : (<View style={{
                width: '100%',
                paddingVertical: 8,
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
            }}>
              <View style={{ width: '100%', flex: 1, justifyContent: 'flex-start', alignItems: 'flex-end' }}>
                <MaterialText fixedWidth="100%" centeredBox>
                  {I18n.m.getMessage('timeTrackingCSVexportLockText')}
                </MaterialText>
              </View>
              <View style={{ width: 56, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                <Switch value={exportLock} onPress={setExportLock}/>
              </View>
            </View>)}
          <View style={{
            width: '100%',
            paddingVertical: 8,
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
        }}>
            <View style={{ width: '100%', flex: 1, justifyContent: 'flex-start', alignItems: 'flex-end' }}>
              <MaterialText fixedWidth="100%" centeredBox>
                {I18n.m.getMessage('timeTrackingCSVexportIncludeAbsence')}
              </MaterialText>
            </View>
            <View style={{ width: 56, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
              <Switch value={includeAbsence} onPress={(value) => {
            setIncludeAbsence(value);
        }}/>
            </View>
          </View>
          <FormInputPicker list={exportOptions} labelText={I18n.m.getMessage('timeTrackingExportType')} selectedIndex={selectedIndex} onChange={(selected) => {
            setSelectedIndex(selected.index);
        }}/>
          {renderExtraOptions()}
          {renderWarnings()}
        </View>
      </DialogContent>
      <DialogActions>
        <ContainedButton title={I18n.m.getMessage('cancel')} textColor={ThemeManager.style.brandPrimary} backgroundColor="transparent" onPress={() => Dialog.instance?.close()}/>
        <ContainedButton disabled={numberOfExportEntries() === 0} title={I18n.m.getMessage('timeTrackingCSVexportButtonExport')} onPress={download}/>
      </DialogActions>
    </>);
}
