"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeUserImageId = void 0;
var UserUploadedUserImage_1 = require("../../events/user/UserUploadedUserImage");
var ClientUserCommands_1 = require("./root/ClientUserCommands");
var ChangeUserImageId = function (_ClientUserCommands_) {
  function ChangeUserImageId(data) {
    var _this;
    (0, _classCallCheck2.default)(this, ChangeUserImageId);
    _this = _callSuper(this, ChangeUserImageId, [data]);
    _this.commandName = 'ChangeUserImageId';
    _this.aggregate = 'user';
    return _this;
  }
  (0, _inherits2.default)(ChangeUserImageId, _ClientUserCommands_);
  return (0, _createClass2.default)(ChangeUserImageId, [{
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {});
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var data = Object.assign({}, this.data, {
          userId: this.data.userId
        });
        return new UserUploadedUserImage_1.UserUploadedUserImage(this.entityId, data);
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }]);
}(ClientUserCommands_1.ClientUserCommands);
exports.ChangeUserImageId = ChangeUserImageId;