"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TicketEntity = exports.TicketStatusColor = exports.TicketStatus = exports.TicketTypes = void 0;
var ReadModelEntity_1 = require("cqrs-core/build/src/core/query/ReadModelEntity");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var TicketTypes;
(function (TicketTypes) {
  TicketTypes[TicketTypes["Defect"] = 0] = "Defect";
  TicketTypes[TicketTypes["Task"] = 1] = "Task";
  TicketTypes[TicketTypes["Inventory"] = 2] = "Inventory";
  TicketTypes[TicketTypes["WarrantyDeficiency"] = 3] = "WarrantyDeficiency";
  TicketTypes[TicketTypes["Note"] = 4] = "Note";
  TicketTypes[TicketTypes["Addendum"] = 5] = "Addendum";
})(TicketTypes = exports.TicketTypes || (exports.TicketTypes = {}));
var TicketStatus;
(function (TicketStatus) {
  TicketStatus[TicketStatus["noStatus"] = -1] = "noStatus";
  TicketStatus[TicketStatus["open"] = 0] = "open";
  TicketStatus[TicketStatus["processing"] = 1] = "processing";
  TicketStatus[TicketStatus["closed"] = 2] = "closed";
  TicketStatus[TicketStatus["checked"] = 3] = "checked";
})(TicketStatus = exports.TicketStatus || (exports.TicketStatus = {}));
var TicketStatusColor = function () {
  function TicketStatusColor() {
    (0, _classCallCheck2.default)(this, TicketStatusColor);
  }
  return (0, _createClass2.default)(TicketStatusColor, null, [{
    key: "getMarkerForStatus",
    value: function getMarkerForStatus(status, completionOn) {
      var newDate = new Date();
      var co = completionOn != null ? new Date(completionOn) : null;
      switch (status) {
        case TicketStatus.open:
          {
            return co == null || co.getTime() > newDate.getTime() ? 'orange' : 'orangeRed';
          }
        case TicketStatus.processing:
          {
            return co == null || co.getTime() > newDate.getTime() ? 'yellow' : 'yellowRed';
          }
        case TicketStatus.closed:
          {
            return 'blue';
          }
        case TicketStatus.noStatus:
          {
            return 'black';
          }
        case TicketStatus.checked:
          {
            return 'green';
          }
        default:
          {
            return 'orange';
          }
      }
    }
  }, {
    key: "getColorForStatus",
    value: function getColorForStatus(status) {
      switch (status) {
        case TicketStatus.open:
          {
            return TicketStatusColor.open;
          }
        case TicketStatus.processing:
          {
            return TicketStatusColor.processing;
          }
        case TicketStatus.noStatus:
          {
            return TicketStatusColor.noStatus;
          }
        case TicketStatus.closed:
          {
            return TicketStatusColor.closed;
          }
        case TicketStatus.checked:
          {
            return TicketStatusColor.checked;
          }
        default:
          {
            return 'grey';
          }
      }
    }
  }]);
}();
exports.TicketStatusColor = TicketStatusColor;
TicketStatusColor.closed = 'rgb(0, 115, 255)';
TicketStatusColor.checked = 'rgb(0, 205, 158)';
TicketStatusColor.open = 'rgb(245, 124, 0)';
TicketStatusColor.processing = 'rgb(255,213,0)';
TicketStatusColor.noStatus = 'rgb(255, 255, 255)';
var TicketEntity = function (_ReadModelEntity_1$Re) {
  function TicketEntity(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, TicketEntity);
    _this = _callSuper(this, TicketEntity, [obj]);
    _this.entityName = 'Ticket';
    _this.commentCounter = 0;
    _this.subTicketsCounter = 0;
    _this.fileCounter = 0;
    _this.fill(obj);
    return _this;
  }
  (0, _inherits2.default)(TicketEntity, _ReadModelEntity_1$Re);
  return (0, _createClass2.default)(TicketEntity, [{
    key: "createOne",
    value: function createOne(obj) {
      return new TicketEntity(obj);
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('projectId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('title', new OdataEntity_1.OdataIndex(true));
      baseMap.set('foreignId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('ticketNumber', new OdataEntity_1.OdataIndex(true));
      baseMap.set('description', new OdataEntity_1.OdataIndex());
      baseMap.set('archived', new OdataEntity_1.OdataIndex(true));
      baseMap.set('deleted', new OdataEntity_1.OdataIndex(true));
      baseMap.set('creator', new OdataEntity_1.OdataIndex(true));
      baseMap.set('craft', new OdataEntity_1.OdataIndex(true));
      baseMap.set('subTicketFrom', new OdataEntity_1.OdataIndex(true));
      baseMap.set('type', new OdataEntity_1.OdataIndex(true));
      baseMap.set('ticketType', new OdataEntity_1.OdataIndex());
      baseMap.set('ticketStatus', new OdataEntity_1.OdataIndex(true));
      baseMap.set('watchers', new OdataEntity_1.OdataIndex(true));
      baseMap.set('tags', new OdataEntity_1.OdataIndex(true));
      baseMap.set('tagIds', new OdataEntity_1.OdataIndex());
      baseMap.set('planId', new OdataEntity_1.OdataIndex());
      baseMap.set('planPositionX', new OdataEntity_1.OdataIndex());
      baseMap.set('planPositionY', new OdataEntity_1.OdataIndex());
      baseMap.set('planPositionZ', new OdataEntity_1.OdataIndex());
      baseMap.set('assignedToUserId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('approverUserId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('assignedOn', new OdataEntity_1.OdataIndex());
      baseMap.set('completionOn', new OdataEntity_1.OdataIndex(true));
      baseMap.set('builder', new OdataEntity_1.OdataIndex());
      baseMap.set('address', new OdataEntity_1.OdataIndex());
      baseMap.set('commentCounter', new OdataEntity_1.OdataIndex());
      baseMap.set('subTicketsCounter', new OdataEntity_1.OdataIndex());
      baseMap.set('fileCounter', new OdataEntity_1.OdataIndex());
      baseMap.set('fields', new OdataEntity_1.OdataIndex(true));
      return baseMap;
    }
  }], [{
    key: "getStatusForCompletionOn",
    value: function getStatusForCompletionOn(status, completionOn) {
      if (completionOn != null && completionOn < new Date() && status != null && (status.valueOf() === TicketStatus.open || status.valueOf() === TicketStatus.processing)) {
        return 'overdue';
      }
      return 'ok';
    }
  }]);
}(ReadModelEntity_1.ReadModelEntity);
exports.TicketEntity = TicketEntity;