"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LokiDBOptions = void 0;
var LokiInMemoryAdapter_1 = require("./adapter/LokiInMemoryAdapter");
var LokiDBOptions = (0, _createClass2.default)(function LokiDBOptions(fileHandler) {
  var filename = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'lokiDB.json';
  var adapter = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : LokiInMemoryAdapter_1.LokiInMemoryAdapter.adapter;
  var db = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : undefined;
  var verbose = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
  var autosave = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : true;
  var autoload = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : false;
  var autoloadCallback = arguments.length > 7 ? arguments[7] : undefined;
  var serializationMethod = arguments.length > 8 ? arguments[8] : undefined;
  var destructureDelimiter = arguments.length > 9 ? arguments[9] : undefined;
  var throttledSaves = arguments.length > 10 && arguments[10] !== undefined ? arguments[10] : true;
  (0, _classCallCheck2.default)(this, LokiDBOptions);
  this.verbose = true;
  this.autosave = true;
  this.autoload = true;
  this.db = undefined;
  this.fileDir = './files';
  this.filename = filename;
  this.db = db;
  this.autoload = autoload;
  this.adapter = adapter;
  this.autoloadCallback = autoloadCallback;
  this.serializationMethod = serializationMethod;
  this.destructureDelimiter = destructureDelimiter;
  this.throttledSaves = throttledSaves;
  this.fileHandler = fileHandler;
  this.verbose = verbose;
  this.autosave = autosave;
});
exports.LokiDBOptions = LokiDBOptions;