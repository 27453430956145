"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeTask = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../../access/rights/RightsManager");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var TaskChanged_1 = require("../../../events/company/tasks/TaskChanged");
var CompanySettingsEntity_1 = require("../../../query/entities/CompanySettingsEntity");
var ChangeTask = function (_CommandRoot_1$Comman) {
  function ChangeTask(data, entityId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ChangeTask);
    _this = _callSuper(this, ChangeTask, [data, entityId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'ChangeTask';
    return _this;
  }
  (0, _inherits2.default)(ChangeTask, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(ChangeTask, [{
    key: "getTask",
    value: function () {
      var _getTask = (0, _asyncToGenerator2.default)(function* () {
        if (this.task == null) this.task = yield CommandReadModels_1.CommandReadModels.task.getById(this.entityId);
      });
      function getTask() {
        return _getTask.apply(this, arguments);
      }
      return getTask;
    }()
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        yield this.getTask();
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        var company = yield CommandReadModels_1.CommandReadModels.company.getById(this.task.companyId);
        if (yield RightsManager_1.RightsManager.isCompanyAdmin(userId, company.id)) return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var data = {};
        var hasChanged = false;
        if (this.data.active != null && this.task.active !== this.data.active) {
          data.active = this.data.active;
          hasChanged = true;
        }
        if (this.data.taskTypeId != null && this.task.taskTypeId !== this.data.taskTypeId) {
          data.taskTypeId = this.data.taskTypeId;
          data.productive = this.taskType.productive;
          hasChanged = true;
        }
        if (this.data.mark != null && this.task.mark !== this.data.mark) {
          data.mark = this.data.mark;
          hasChanged = true;
        }
        if (this.data.description != null && this.task.description !== this.data.description) {
          data.description = this.data.description;
          hasChanged = true;
        }
        if (this.data.wageType != null && this.task.wageType !== this.data.wageType) {
          data.wageType = this.data.wageType;
          hasChanged = true;
        }
        if (this.data.followUpWageType1 != null && this.task.followUpWageType1 !== this.data.followUpWageType1) {
          data.followUpWageType1 = this.data.followUpWageType1;
          hasChanged = true;
        }
        if (this.data.followUpWageType2 != null && this.task.followUpWageType2 !== this.data.followUpWageType2) {
          data.followUpWageType2 = this.data.followUpWageType2;
          hasChanged = true;
        }
        return hasChanged ? new TaskChanged_1.TaskChanged(this.entityId, data, this.task.companyId) : [];
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        var _this2 = this;
        var data = this.data;
        yield this.getTask();
        var tasks = yield CommandReadModels_1.CommandReadModels.task.get({
          filter: `companyId eq '${this.task.companyId}' and deleted ne true`
        });
        var errors = [];
        if (key == null || key === 'mark') {
          if (data.mark != null && (tasks == null ? void 0 : tasks.findIndex(function (t) {
            return data.mark === t.mark && _this2.entityId !== t.id;
          })) > -1) {
            errors.push({
              message: 'Das Kennzeichen existiert bereits',
              messageCode: 'companyTaskAlreadyExists',
              key: 'mark'
            });
          }
        }
        if (key == null || key === 'taskTypeId') {
          if (data.taskTypeId != null) {
            try {
              this.taskType = yield CompanySettingsEntity_1.CompanySettingsEntity.getTaskTypeById(data.taskTypeId);
            } catch (e) {
              errors.push({
                message: 'Die Tätigkeitsart existiert nicht',
                messageCode: 'required',
                key: 'taskTypeId'
              });
            }
          }
        }
        if (key == null || key === 'followUpWageType1') {
          var _this$task$wageType;
          if (data.followUpWageType1 != null && data.followUpWageType1.length > 0 && (data.wageType == null || data.wageType.length === 0) && (this.task.wageType == null || ((_this$task$wageType = this.task.wageType) == null ? void 0 : _this$task$wageType.length) === 0)) {
            errors.push({
              message: 'Die Lohnart wird benötigt',
              messageCode: 'taskWageTypeRequiredIfFollowUp',
              key: 'wageType'
            });
          }
        }
        if (data.followUpWageType2 != null && data.followUpWageType2.length > 0 && (key == null || key === 'followUpWageType2')) {
          if ((data.followUpWageType1 == null || data.followUpWageType1.length === 0) && (this.task.followUpWageType1 == null || this.task.followUpWageType1.length === 0)) {
            errors.push({
              message: 'Die Folgelohnart 1 wird benötigt',
              messageCode: 'taskFollowUpRequiredIfFollowUp2',
              key: 'followUpWageType1'
            });
          }
          if ((data.wageType == null || data.wageType.length === 0) && (this.task.wageType == null || this.task.wageType.length === 0)) {
            errors.push({
              message: 'Die Lohnart wird benötigt',
              messageCode: 'taskWageTypeRequiredIfFollowUp',
              key: 'wageType'
            });
          }
        }
        if (errors.length > 0) throw errors;
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.ChangeTask = ChangeTask;