import { Url } from 'cqrs-shared/src/uri/Url';
import ConnectionContext from 'materialTheme/src/connectionContext';
import { FilePicker } from 'materialTheme/src/file/FilePicker';
import { FilePicketComp } from 'materialTheme/src/file/FilePicketComp';
import ImagePickerComp from 'materialTheme/src/file/ImagePicker';
import { ImagePickerOptions } from 'materialTheme/src/file/ImagePickerOptions';
import { Uploads } from 'materialTheme/src/file/upload/Uploads';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Fab } from 'materialTheme/src/theme/components/Fab';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { LightBoxHandler } from 'materialTheme/src/theme/components/lightbox/LightBoxHandler';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { ModalBottomSheet } from 'materialTheme/src/theme/components/ModalBottomSheet';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { UploadingList } from 'materialTheme/src/theme/components/upload/UploadingList';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { FlatList, Image, Platform, ScrollView, UIManager, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { UserEntity } from 'upmesh-auth-core/src/client/query/entities/UserEntity';
import { CreateFolder } from 'upmesh-core/src/client/commands/folder/CreateFolder';
import { FolderEntity } from 'upmesh-core/src/client/query/entities/FolderEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { OfflineDataDownloader } from '../../repo/file/OfflineDataDownloader';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CurrentProject } from '../project/CurrentProject';
import { CreateFolderDialog } from './CreateFolderDialog';
import { FileThumb169 } from './FileThumb169';
import { MoveFolderDialog } from './MoveFolderDialog';
import { ShareDialog } from './ShareDialog';
const notFoundImage = require('../../assets/img/no_documents.png');
export class FilesViewThumbs extends PureComponent {
    static getDerivedStateFromProps(nextProps, _prevState) {
        if (nextProps.documents != null) {
            const { documents, folders, currentFolder } = nextProps;
            return { documents: FilesViewThumbs.getDocWithFolder(documents, folders, currentFolder) };
        }
        return null;
    }
    constructor(props) {
        super(props);
        this.targetWidth = 100;
        this.setImagePicker = (r) => {
            this.imagePicker = r;
        };
        this.openImage = (file) => (_e) => {
            LightBoxHandler.open('thumb', file.id);
        };
        this.openJournal = (item) => (e) => {
            if (e.persist != null && typeof e.persist === 'function')
                e.persist();
            try {
                const data = JSON.parse(item.metaData);
                if (data.journal) {
                    const j = data.journal;
                    Routing.instance.goTo(`/projects/${item.projectId}/dailyreports/${j.forDay}`);
                }
            }
            catch (err) {
                DefaultErrorHandler.showDefaultErrorAlert(err);
            }
        };
        this.moveFolder = (item) => {
            Menu.instance?.close();
            if (item.mimeType === 'folder' && item['hiddenData'] != null) {
                MoveFolderDialog.open(item['hiddenData']);
            }
        };
        this.pressAddFile = async (_e) => {
            ModalBottomSheet.instance?.close();
            const { currentFolder } = this.props;
            try {
                if (currentFolder != null && currentFolder !== 'public' && currentFolder !== 'private') {
                    await FolderEntity.hasFolderWriteRights(currentFolder, CurrentUser.userId);
                }
                const { fileIdCounter } = this.state;
                FilePicker.show(`uploadFiles_to_folder_${fileIdCounter}`, true, ['allFiles'], (response) => this.onGotFilesOrFolder(response));
            }
            catch (err) {
                Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') });
            }
        };
        this.uploadFolder = async () => {
            ModalBottomSheet.instance?.close();
            const { currentFolder } = this.props;
            try {
                if (currentFolder != null && currentFolder !== 'public' && currentFolder !== 'private') {
                    await FolderEntity.hasFolderWriteRights(currentFolder, CurrentUser.userId);
                }
                FilePicker.show(`filesTableViewFolderPicker`, false, ['allFiles'], (response) => this.onGotFolder(response));
            }
            catch (err) {
                Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') });
            }
        };
        this.onGotFolder = async (e) => {
            LoadingEvents.instance.startLoading();
            try {
                const { currentFolder, projectId } = this.props;
                const folder = currentFolder === 'public' ? null : currentFolder;
                const files = [];
                for (const f of e) {
                    files.push(f);
                }
                for (const f of files) {
                    try {
                        const folders = f['webkitRelativePath'].split('/');
                        folders.pop();
                        const folderId = await this.getFolderIdByName([...folders], folder, true);
                        const url = Url.getURLfromString(`${UpmeshClient.instance.url}/storedfile/file/`);
                        const targetFolder = folderId == null ? 'public' : folderId === 'private' ? null : folderId;
                        await Uploads.addUpload(url.href, f, `${projectId}_documents_upload_${folderId == null ? 'public' : folderId}`, {
                            projectId,
                            folder: targetFolder,
                        });
                    }
                    catch (e) {
                        DefaultErrorHandler.showDefaultErrorAlert(e);
                    }
                }
            }
            catch (e) {
                DefaultErrorHandler.showDefaultErrorAlert(e);
            }
            LoadingEvents.instance.stopLoading();
        };
        this.onGotFilesOrFolder = async (files) => {
            LoadingEvents.instance.startLoading();
            const { currentFolder, projectId } = this.props;
            const folder = currentFolder === 'public' ? null : currentFolder;
            for (const f of files) {
                try {
                    if (folder !== 'private' && f['folder'] != null && f['filepath'] == null) {
                        const folders = f['folder'].split('/');
                        folders.pop();
                        await this.getFolderIdByName([...folders], folder, true);
                    }
                    else if (folder !== 'private' && f['filepath'] != null) {
                        const folders = f['filepath'].split('/');
                        folders.pop();
                        const folderId = await this.getFolderIdByName([...folders], folder, true);
                        const url = Url.getURLfromString(`${UpmeshClient.instance.url}/storedfile/file/`);
                        const targetFolder = folderId == null ? 'public' : folderId === 'private' ? null : folderId;
                        await Uploads.addUpload(url.href, f, `${projectId}_documents_upload_${folderId == null ? 'public' : folderId}`, {
                            projectId,
                            folder: targetFolder,
                        });
                    }
                    else {
                        const url = Url.getURLfromString(`${UpmeshClient.instance.url}/storedfile/file/`);
                        const targetFolder = folder == null ? 'public' : folder === 'private' ? null : folder;
                        await Uploads.addUpload(url.href, f, `${projectId}_documents_upload_${folder == null ? 'public' : folder}`, {
                            projectId,
                            folder: targetFolder,
                        });
                    }
                }
                catch (e) {
                    DefaultErrorHandler.showDefaultErrorAlert(e);
                }
            }
            LoadingEvents.instance.stopLoading();
        };
        this.openFab = async () => {
            const { size, currentFolder } = this.props;
            const sViewHeight = size.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0;
            const onS = size.contentWidth < this.props.breakViewWidth;
            if (Fab.instance != null) {
                if (currentFolder === 'tickets' || currentFolder === 'reports' || currentFolder === 'search') {
                    Fab.instance.close();
                }
                else if (!onS || (currentFolder != null && currentFolder.length > 0)) {
                    const fabActions = [];
                    if (currentFolder !== 'private') {
                        fabActions.push({
                            text: I18n.m.getMessage('createFolder'),
                            icon: 'folder-plus-outline',
                            onPress: this.createFolder,
                        });
                    }
                    fabActions.push({
                        text: I18n.m.getMessage('uploadFiles'),
                        icon: 'cloud-upload-outline',
                        onPress: this.pressAddFile,
                    });
                    if (Platform.OS === 'web' && currentFolder !== 'private') {
                        fabActions.push({
                            text: I18n.m.getMessage('uploadFolder'),
                            icon: 'cloud-upload-outline',
                            onPress: this.uploadFolder,
                        });
                    }
                    if (Platform.OS === 'ios') {
                        fabActions.push({
                            icon: 'folder-image',
                            onPress: () => {
                                if (Fab.instance != null) {
                                    Fab.instance.closeButtons();
                                }
                                this.openLibrary();
                            },
                            text: I18n.m.getMessage('fromImageLibrary'),
                        });
                    }
                    const hasCam = await ImagePickerComp.hasCamera();
                    if (hasCam) {
                        fabActions.push({
                            icon: 'camera',
                            text: I18n.m.getMessage('fromCamera'),
                            onPress: this.openCam,
                        });
                    }
                    Fab.instance.open({
                        fabActions,
                        fabIcon: 'plus',
                        fabIconOpen: 'close',
                        small: false,
                        fabColor: ThemeManager.style.brandPrimary,
                        fabColorOpen: ThemeManager.style.brandSecondary,
                        extraPaddingBottom: sViewHeight,
                    });
                }
                else {
                    Fab.instance.close();
                }
            }
            else {
                window.setTimeout(this.openFab, 100);
            }
        };
        this.openCam = () => {
            if (this.imagePicker != null) {
                this.imagePicker.execute({ selectMethod: 'camera' });
            }
        };
        this.createFolder = () => {
            const { projectId, currentFolder } = this.props;
            if (currentFolder !== 'private' &&
                currentFolder !== 'tickets' &&
                currentFolder !== 'reports' &&
                currentFolder !== 'search') {
                const folderId = currentFolder != null && currentFolder !== 'public' ? currentFolder.toString() : undefined;
                const c = new CreateFolder({
                    subFolderFromId: folderId != null ? folderId : null,
                    title: '',
                    access: [],
                    description: '',
                    tags: [],
                    projectId,
                });
                c.canI()
                    .then(() => CreateFolderDialog.openDialog({ projectId, folderId }))
                    .catch(() => Routing.instance.alert.post({ text: I18n.m.getMessage('forbiddenCommand') }));
            }
        };
        this.closeFab = (callback) => {
            if (Fab.instance != null) {
                Fab.instance.close(callback);
            }
        };
        this.onGotImageFile = async (response) => {
            if (Array.isArray(response)) {
                await this.onGotFilesOrFolder(response);
            }
            else {
                await this.onGotFilesOrFolder([response]);
            }
        };
        this.onUploading = (files) => {
            if (files.length === 0) {
                this.setState({ uploadHeight: 0 });
            }
            else {
                this.setState({ uploadHeight: files.length * 56 + 16 });
            }
        };
        this.getItemKey = (item, _index) => `file${item.id}`;
        this.renderThumbRow = ({ item }) => {
            const { activeMultiselect, selectedIDs, onMultiSelect, multiselectEnabled } = this.props;
            const localFile = item.mimeType === 'folder'
                ? undefined
                : OfflineDataDownloader.isMediaSynced(item.projectId, item.id, item.getFileId());
            let onPress;
            const onPressMulti = () => {
                const s = selectedIDs ? new Set(selectedIDs) : new Set();
                if (s.has(item.id))
                    s.delete(item.id);
                else
                    s.add(item.id);
                onMultiSelect(s);
            };
            if (activeMultiselect && item.mimeType !== 'folder') {
                onPress = onPressMulti;
            }
            else if (!activeMultiselect) {
                onPress = item.fileType === 'image' ? this.openImage(item) : this.props.downloadFile(true, localFile)(item);
            }
            return (<View key={`fileThumb${item.id}`} style={{
                    padding: ThemeManager.style.getScreenRelativePixelSize(8),
                    width: this.targetWidth,
                }}>
        <FileThumb169 projectId={item.projectId} file={item} fileId={item.fileId} width={this.targetWidth - 2 * ThemeManager.style.getScreenRelativePixelSize(8)} onPress={onPress} onLongPress={multiselectEnabled ? onPressMulti : undefined} onPressData={item} title={item.orgFilename} selected={selectedIDs != null && typeof selectedIDs.has === 'function' && selectedIDs.has(item.id)} onPressIcon={this.openMenu(item)}/>
      </View>);
        };
        this.openMenu = (file) => (e) => {
            UIManager.measureInWindow(e.nativeEvent.target, (x, y, _width, height) => {
                const { connectedToServer } = this.context;
                const { hasFolderEditRights } = this.props;
                const client = {
                    x,
                    y,
                    height,
                    width: 256,
                };
                let menuElements;
                if (file.mimeType === 'folder') {
                    menuElements = [
                        {
                            thumbnail: {
                                thumbnail: <Icon toolTip={I18n.m.getMessage('edit')} icon="pencil-outline"/>,
                                width: 24,
                            },
                            disabled: !connectedToServer || (file.forEntityId != null && file.forEntityId.length > 0) || !hasFolderEditRights,
                            onPress: this.props.editFolder(file),
                            text: I18n.m.getMessage('edit'),
                        },
                        {
                            thumbnail: {
                                thumbnail: <Icon toolTip={I18n.m.getMessage('download')} icon="download-outline"/>,
                                width: 24,
                            },
                            disabled: !connectedToServer || (file.forEntityId != null && file.forEntityId.length > 0),
                            onPress: this.props.downloadFolder(file),
                            text: I18n.m.getMessage('filesDownload'),
                        },
                        {
                            thumbnail: {
                                thumbnail: <Icon toolTip={I18n.m.getMessage('moveFolder')} icon="folder-move-outline"/>,
                                width: 24,
                            },
                            disabled: !connectedToServer || (file.forEntityId != null && file.forEntityId.length > 0),
                            onPress: (_e) => this.moveFolder(file),
                            text: I18n.m.getMessage('moveFolder'),
                        },
                        {
                            thumbnail: {
                                thumbnail: <Icon toolTip={I18n.m.getMessage('delete')} icon="delete-outline"/>,
                                width: 24,
                            },
                            disabled: !connectedToServer || (file.forEntityId != null && file.forEntityId.length > 0),
                            onPress: () => this.props.deleteFolder(file.id),
                            text: I18n.m.getMessage('delete'),
                        },
                    ];
                }
                else {
                    const tickeIcon = file.forEntity === 'Ticket' && file.forEntityId != null && file.forEntityId.length > 0;
                    const journalIcon = file.forEntity === 'Journal' && file.forEntityId != null && file.forEntityId.length > 0;
                    const excludeFolder = [file.folder == null || file.folder === '' ? 'private' : file.folder];
                    const localFile = OfflineDataDownloader.isMediaSynced(file.projectId, file.id, file.getFileId());
                    const editFileTypes = file.orgFilename.includes('pdf') ||
                        file.mimeType.includes('png') ||
                        file.mimeType.includes('jpeg') ||
                        file.mimeType.includes('jpg');
                    const editDisabled = !hasFolderEditRights ||
                        !editFileTypes ||
                        (!localFile && !connectedToServer) ||
                        (file.forEntityId != null && file.forEntityId.length > 0);
                    const project = CurrentProject.instance?.getCurrentProject();
                    let canEditForPdf = false;
                    if (!(file.mimeType.includes('pdf') || file.orgFilename.includes('pdf')))
                        canEditForPdf = true;
                    else if (project != null && project.projectSubscription === 'enterprise')
                        canEditForPdf = true;
                    menuElements = [
                        {
                            thumbnail: {
                                thumbnail: (<Icon toolTip={I18n.m.getMessage(tickeIcon ? 'openTicket' : journalIcon ? 'storedFileCopyText' : 'moveFile')} icon={tickeIcon ? 'map-marker' : 'file-move-outline'}/>),
                                width: 24,
                            },
                            onPress: tickeIcon ? this.props.openTicket(file) : this.props.moveFile([file.id], excludeFolder)(file),
                            text: I18n.m.getMessage(tickeIcon ? 'openTicket' : journalIcon ? 'storedFileCopyText' : 'moveFile'),
                        },
                        {
                            thumbnail: {
                                thumbnail: (<Icon toolTip={I18n.m.getMessage('download')} icon="download-outline" disabled={!(connectedToServer || localFile != null)}/>),
                                width: 24,
                            },
                            disabled: !(connectedToServer || localFile != null),
                            onPress: this.props.downloadFile(false, localFile)(file),
                            text: I18n.m.getMessage('filesDownload'),
                        },
                        {
                            thumbnail: {
                                thumbnail: <Icon toolTip={I18n.m.getMessage('filesRename')} icon="form-textbox"/>,
                                width: 24,
                            },
                            disabled: journalIcon,
                            onPress: this.props.changeFileName(file),
                            text: I18n.m.getMessage('filesRename'),
                        },
                        {
                            thumbnail: {
                                thumbnail: <Icon toolTip={I18n.m.getMessage('filesChangeCommentTypeTooltip')} icon="comment-outline"/>,
                                width: 24,
                            },
                            disabled: journalIcon,
                            onPress: this.props.changeFileType(file),
                            text: I18n.m.getMessage('filesChangeCommentTypeTooltip'),
                        },
                        {
                            thumbnail: {
                                thumbnail: (<Icon toolTip={I18n.m.getMessage('filesVersions')} icon="history" disabled={file.versions.length < 2}/>),
                                width: 24,
                            },
                            onPress: this.props.showVersionsList(file),
                            text: I18n.m.getMessage('filesVersions'),
                        },
                        {
                            thumbnail: {
                                thumbnail: (<Icon toolTip={I18n.m.getMessage('delete')} icon="delete-outline" disabled={!connectedToServer || (file.forEntityId != null && file.forEntityId.length > 0)}/>),
                                width: 24,
                            },
                            disabled: !connectedToServer || (file.forEntityId != null && file.forEntityId.length > 0),
                            onPress: this.props.deleteFile(file),
                            text: I18n.m.getMessage('delete'),
                        },
                    ];
                    if (journalIcon) {
                        menuElements.unshift({
                            thumbnail: {
                                thumbnail: <Icon toolTip={I18n.m.getMessage('openJournal')} icon="calendar"/>,
                                width: 24,
                            },
                            disabled: false,
                            onPress: this.openJournal(file),
                            text: I18n.m.getMessage('openJournal'),
                        });
                    }
                    menuElements.unshift({
                        thumbnail: {
                            thumbnail: <Icon toolTip={I18n.m.getMessage('edit')} icon="share-outline"/>,
                            width: 24,
                        },
                        onPress: () => {
                            Menu.instance?.close();
                            Dialog.instance?.open({
                                closeOnTouchOutside: true,
                                content: <ShareDialog file={file}/>,
                                contentPadding: false,
                                showCloseIcon: true,
                            });
                        },
                        text: I18n.m.getMessage('share'),
                    });
                    if (file.forEntityId == null || file.forEntityId.length === 0) {
                        menuElements.unshift({
                            thumbnail: {
                                thumbnail: <Icon toolTip={I18n.m.getMessage('edit')} icon="pencil-outline" disabled={editDisabled}/>,
                                width: 24,
                            },
                            disabled: editDisabled,
                            onPress: canEditForPdf ? this.props.editFile(file) : this.props.showEnterpriseOnlyInfo(file),
                            text: I18n.m.getMessage('edit'),
                        }, {
                            thumbnail: {
                                thumbnail: (<Icon toolTip={I18n.m.getMessage('storedFileCopyText')} icon="content-copy" disabled={file.forEntityId != null && file.forEntityId.length > 0}/>),
                                width: 24,
                            },
                            disabled: file.forEntityId != null && file.forEntityId.length > 0,
                            onPress: this.props.copyFile(file),
                            text: I18n.m.getMessage('storedFileCopyText'),
                        });
                    }
                }
                Menu.instance?.open({
                    items: menuElements,
                    client,
                });
            });
        };
        const { documents, folders, currentFolder } = this.props;
        this.state = {
            documents: FilesViewThumbs.getDocWithFolder(documents, folders, currentFolder),
            fileIdCounter: 1,
            uploadHeight: 0,
        };
    }
    componentDidMount() {
        this.openFab().catch((err) => console.debug(err));
    }
    componentDidUpdate(prevProps) {
        if (prevProps.currentFolder !== this.props.currentFolder) {
            requestAnimationFrame(() => {
                this.openFab().catch((err) => console.debug(err));
            });
        }
    }
    static getDocWithFolder(documents, folders, currentFolder) {
        const docs = documents == null ? [] : [...documents];
        folders.forEach((f) => {
            const subFolderFromId = f.subFolderFromId == null ? 'public' : f.subFolderFromId;
            if (subFolderFromId === currentFolder) {
                let uploadedByUsername = '';
                let uploadedByUser = new UserEntity();
                try {
                    const u = CurrentProject.instance.getCurrentProjectTeam('all');
                    const us = u.find((t) => t.user.userId === f.createdBy);
                    if (us != null) {
                        uploadedByUsername = us.user.getFullName();
                        uploadedByUser = us.user;
                    }
                }
                catch (e) {
                    console.debug('cant get Teammember', e);
                }
                const s = {
                    title: ` ${f.title}`,
                    id: f.id,
                    orgFilename: ` ${f.title}`,
                    mimeType: 'folder',
                    deleted: f.deleted,
                    folder: f.subFolderFromId,
                    uploadedByUsername,
                    uploadedByUser,
                    createdBy: f.createdBy,
                    createdAt: f.createdAt,
                    userId: f.createdBy,
                    fileSizeInBytes: 0,
                };
                s['hiddenData'] = f;
                docs.unshift(s);
            }
        });
        return docs;
    }
    getLocationElement(folderId, folderName) {
        return (<MaterialText key={`folder_location:${folderId}`} type={MaterialTextTypes.Body1} onPress={this.props.goToFolder(folderId)}>
        {folderName}
      </MaterialText>);
    }
    generateFolderLocations(folderId) {
        const { folders } = this.props;
        const items = [];
        const f = folders.find((e) => e.id === folderId);
        if (f != null) {
            items.unshift({ title: f.title, id: f.id });
            if (f.subFolderFromId != null && f.subFolderFromId.length > 0) {
                items.unshift(...this.generateFolderLocations(f.subFolderFromId));
            }
        }
        return items;
    }
    generateLocations() {
        const { documents, currentFolder } = this.props;
        const items = [];
        if (currentFolder === 'all') {
            items.push(this.getLocationElement(currentFolder, I18n.m.getMessage('menuProjectFiles')));
        }
        else if (currentFolder === 'search') {
            items.push(this.getLocationElement(currentFolder, I18n.m.getMessage('searchNoun')));
        }
        else if (currentFolder === 'private') {
            items.push(this.getLocationElement(currentFolder, I18n.m.getMessage('filesFolderPrivate')));
        }
        else if (currentFolder === 'public') {
            items.push(this.getLocationElement(currentFolder, I18n.m.getMessage('filesFolderPublic')));
        }
        else if (currentFolder === 'reports') {
            items.push(this.getLocationElement(currentFolder, I18n.m.getMessage('menuProjectDailyReports')));
        }
        else if (currentFolder === 'tickets') {
            items.push(this.getLocationElement(currentFolder, I18n.m.getMessage('tickets')));
        }
        else {
            items.push(this.getLocationElement('public', I18n.m.getMessage('filesFolderPublic')));
            const locations = this.generateFolderLocations(currentFolder);
            locations.forEach((f) => {
                items.push(<MaterialText key={`sep_${f.id}`} type={MaterialTextTypes.Body1}>
            {' › '}
          </MaterialText>);
                items.push(this.getLocationElement(f.id, f.title));
            });
        }
        items.push(<MaterialText key="documentsCount" type={MaterialTextTypes.Body1}>
        ({documents == null ? '0' : documents.length})
      </MaterialText>);
        return items;
    }
    componentWillUnmount() {
        this.closeFab();
    }
    async getFolderIdByName(path, subFolderFromId = null, createIfNotExists = true) {
        if (path.length > 0) {
            const p = path.shift();
            const { projectId } = this.props;
            let id = '';
            if (p != null) {
                try {
                    const subFilter = subFolderFromId == null ? 'subFolderFromId eq null' : `subFolderFromId eq '${subFolderFromId}'`;
                    const getFolderByName = await UpmeshClient.instance.modals.folder.get({
                        filter: `projectId eq '${projectId}' and deleted ne true and ${subFilter} and title eq '${p}'`,
                    });
                    if (getFolderByName.length === 0 && createIfNotExists) {
                        let access = [];
                        if (subFolderFromId != null) {
                            const findParentAccess = await UpmeshClient.instance.modals.folder.getById(subFolderFromId);
                            access = [...findParentAccess.access];
                        }
                        const c = new CreateFolder({ title: p, subFolderFromId, projectId, access, description: '', tags: [] });
                        await c.execute();
                        id = c.entityId;
                    }
                    else if (getFolderByName.length > 0) {
                        id = getFolderByName[0].id;
                    }
                }
                catch (e) {
                    console.debug('cant get subfolder', e);
                    throw e;
                }
            }
            if (path.length > 0) {
                return this.getFolderIdByName([...path], id, createIfNotExists);
            }
            return id;
        }
        return subFolderFromId;
    }
    openLibrary() {
        if (this.imagePicker != null) {
            this.imagePicker.execute({ selectMethod: 'library' });
        }
    }
    createNumColumns() {
        const { size, folderTreeWidth, breakViewWidth } = this.props;
        let targetWidth = ThemeManager.style.getScreenRelativePixelSize(212);
        const fWitdh = size.contentWidth > breakViewWidth ? folderTreeWidth : 0;
        const width = size.contentWidth - ThemeManager.style.contentPaddingValue - fWitdh;
        const numColumns = Math.max(1, Math.round(width / targetWidth));
        targetWidth = Math.floor(width / numColumns);
        this.targetWidth = targetWidth;
        return numColumns;
    }
    renderEmptyTable() {
        let filesUploadHint = 'filesUploadHint';
        if (this.props.currentFolder === 'tickets')
            filesUploadHint += 'Tickets';
        else if (this.props.currentFolder === 'reports')
            filesUploadHint += 'Reports';
        return (<Card style={{
                width: '100%',
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
                padding: 16,
                marginRight: 8,
            }} outerPadding={8}>
        <Image style={{ width: '100%', height: 200, resizeMode: 'contain' }} resizeMode="contain" source={notFoundImage}/>
        <View style={{ width: '100%', marginTop: 16, alignItems: 'center' }}>
          <MaterialText centeredBox centeredText color={ThemeManager.style.black54} type={MaterialTextTypes.Subtitle2} strong>
            {I18n.m.getMessage('filesUploadDescription')}
          </MaterialText>
          <MaterialText centeredBox centeredText color={ThemeManager.style.black54} type={MaterialTextTypes.Subtitle2}>
            {I18n.m.getMessage(filesUploadHint)}
          </MaterialText>
        </View>
      </Card>);
    }
    renderThumbsList() {
        const { size } = this.props;
        const { documents, fileIdCounter } = this.state;
        const numColumns = this.createNumColumns();
        const sViewHeight = size.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0;
        return (<View key="thumbsList">
        {documents == null || documents.length === 0 ? (this.renderEmptyTable()) : (<FlatList key={`fileslist_${numColumns}`} keyExtractor={this.getItemKey} style={{
                    width: '100%',
                    height: size.contentHeight - ThemeManager.style.headerHeight - sViewHeight,
                    marginRight: 8,
                }} numColumns={numColumns} data={documents} renderItem={this.renderThumbRow} ListFooterComponent={<View />} ListFooterComponentStyle={{ height: 200 }} onEndReachedThreshold={0.5}/>)}
        <FilePicketComp dragNdrop id={`uploadFiles_to_folder_${fileIdCounter}`} multiple gotFile={this.onGotFilesOrFolder}/>
        <FilePicketComp id="filesTableViewFolderPicker" multiple={false} gotFile={this.onGotFolder} selectType="folder"/>
        <ImagePickerComp ref={this.setImagePicker} dragNdrop={false} options={new ImagePickerOptions({
                includeBase64: false,
                cropperCircleOverlay: false,
                cropping: false,
                dropMultiHandlerDialogText: I18n.m.getMessage('addImageOnMultiSelect'),
                dropText: I18n.m.getMessage('addImageDropHint'),
                selectMethod: 'any',
                includeExif: true,
                useFrontCamera: false,
                multiple: true,
                mediaType: 'photo',
                filePicketCompId: 'filesInput',
                compressImageQuality: 0.85,
                editModeEnabled: CurrentUser.settings.appSettings == null ||
                    CurrentUser.settings.appSettings['openImageEditorAfterPhoto'] === true,
            })} callBack={this.onGotImageFile}/>
      </View>);
    }
    render() {
        const { currentFolder, projectId } = this.props;
        const { uploadHeight } = this.state;
        return [
            <View key="uploadStatusWrapper" style={{
                    width: '100%',
                    paddingTop: 8,
                    paddingHorizontal: 8,
                    height: uploadHeight,
                    maxHeight: ResizeEvent.current.contentHeight / 2,
                }}>
        <Card outerPadding={0} style={{
                    width: '100%',
                    height: '100%',
                }}>
          <ScrollView key="uploadStatus" style={{
                    width: '100%',
                    height: '100%',
                    maxHeight: ResizeEvent.current.contentHeight / 2,
                }}>
            <UploadingList itemHeight={56} key={`uploadStatus_${currentFolder}`} type={`${projectId}_documents_upload_${currentFolder}`} onUploading={this.onUploading}/>
          </ScrollView>
        </Card>
      </View>,
            <View key="folderLocation" style={{ width: '100%', paddingTop: 8, paddingLeft: 8, flexDirection: 'row', flexWrap: 'wrap' }}>
        {this.generateLocations()}
      </View>,
            this.renderThumbsList(),
        ];
    }
}
FilesViewThumbs.contextType = ConnectionContext;
