import { Form } from 'materialTheme/src/theme/components/forminput/Form';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Toast } from 'materialTheme/src/theme/components/Toast';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useRef, useState } from 'react';
import { Linking, Platform, ScrollView, Share, View } from 'react-native';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { CreateShareToken } from 'upmesh-core/src/client/commands/storedfile/CreateShareToken';
import { RevokeShareToken } from 'upmesh-core/src/client/commands/storedfile/RevokeShareToken';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import * as uuid from 'uuid';
import Clipboard from '@react-native-clipboard/clipboard';
import { Measurement } from 'materialTheme/src/theme/components/utils/Measurement';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CurrentProject } from '../project/CurrentProject';
export function ShareDialog(props) {
    const [storedShares, setStoredShares] = useState([]);
    const isFolder = props.file.mimeType === 'folder';
    const checkEntityTypes = () => {
        if (props.file.mimeType === 'folder')
            return 'Folder';
        const validEntityTypes = ['StoredFile', 'JournalProtocol'];
        if (validEntityTypes.includes(props.file.entityName)) {
            return props.file.entityName;
        }
        console.error('not compatible share type was found!', props.file.entityName);
        return 'StoredFile';
    };
    const command = new CreateShareToken({
        name: '',
        expireDate: new Date(new Date().getTime() + 90 * 24 * 60 * 60 * 1000),
        forEntityId: props.file.id,
        entityType: checkEntityTypes(),
        projectId: CurrentProject.instance.getCurrentProjectId() ?? '',
    });
    const dataOptions = new Map();
    dataOptions.set('expireDate', {
        formType: 'Date',
        cols: 1,
        props: { labelText: I18n.m.getMessage('shareExpireText') },
    });
    dataOptions.set('name', {
        formType: 'string',
        cols: 1,
        props: { labelText: I18n.m.getMessage('shareName'), helperText: I18n.m.getMessage('shareHelper') },
    });
    const updateCallback = (_e) => {
        UpmeshClient.instance.modals.share
            .get({ filter: `forEntityId eq '${props.file.id}' and deleted ne true` })
            .then((allShares) => {
            setStoredShares(allShares);
        })
            .catch((err) => {
            console.error('could not get shares', err);
        });
    };
    useEffect(() => {
        updateCallback(null);
        const attachKey = uuid.v4();
        UpmeshClient.eventDispatcher.attach({
            attachKey,
            callback: updateCallback,
            readModelName: 'Share',
        });
        return () => {
            UpmeshClient.eventDispatcher.detach('Share', attachKey);
        };
    }, [props.file]);
    const getLinkFromShare = (share) => {
        return `${UpmeshClient.instance.url}/public/share/${share.publicId}`;
    };
    const deleteShare = (share) => {
        const revokeCommand = new RevokeShareToken({}, share.id);
        revokeCommand.execute(ClientStore.commandStore).catch((err) => {
            DefaultErrorHandler.showDefaultErrorAlert(err);
        });
    };
    const copyShare = (share) => {
        Clipboard.setString(getLinkFromShare(share));
        Toast.instance?.open({
            title: `${I18n.m.getMessage('shareCopyHelper')} ${getLinkFromShare(share)}`,
            onPressButton: Toast.instance.close,
            buttonTitle: I18n.m.getMessage('close'),
        });
    };
    const resultWrapper = (e) => {
        if (e != null) {
            setTimeout(() => {
                UpmeshClient.instance.modals.share
                    .get({
                    filter: `forEntityId eq '${e.forEntityId}'`,
                    orderby: 'createdAt DESC',
                })
                    .then((ent) => {
                    copyShare(ent[0]);
                })
                    .catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
            }, 200);
        }
    };
    const openShareMenu = (share) => (e) => {
        Measurement.measure(e.currentTarget)
            .then((p) => {
            const client = {
                height: p.height,
                width: p.width,
                x: p.pageX,
                y: p.pageY,
            };
            const items = [
                {
                    onPress: () => {
                        Menu.instance?.close();
                        copyShare(share);
                    },
                    thumbnail: {
                        thumbnail: <Icon icon="clipboard-text-outline" toolTip={I18n.m.getMessage('shareCopy')}/>,
                        width: 40,
                    },
                    text: I18n.m.getMessage('shareCopy'),
                },
            ];
            if (Platform.OS === 'web')
                items.push({
                    onPress: () => {
                        Menu.instance?.close();
                        Linking.openURL(`mailto:?subject=${I18n.m.getMessage('publishedOverUpmesh')}&body=${I18n.m.getMessage('publishedLinkOverUpmesh', { link: getLinkFromShare(share) })}`).catch(console.debug);
                    },
                    thumbnail: {
                        thumbnail: <Icon icon="email-send-outline" toolTip={I18n.m.getMessage('shareEmail')}/>,
                        width: 40,
                    },
                    text: I18n.m.getMessage('shareEmail'),
                });
            else
                items.push({
                    onPress: () => {
                        Menu.instance?.close();
                        Share.share({
                            message: I18n.m.getMessage('publishedLinkOverUpmesh', { link: getLinkFromShare(share) }),
                        }).catch(console.debug);
                    },
                    thumbnail: {
                        thumbnail: (<Icon icon={Platform.OS === 'ios' ? 'export-variant' : 'share-variant-outline'} toolTip={I18n.m.getMessage('shareMobile')}/>),
                        width: 40,
                    },
                    text: I18n.m.getMessage('shareMobile'),
                });
            Menu.instance?.open({
                client,
                items,
                formInput: false,
            });
        })
            .catch((err) => {
            console.debug('cant open Menu', err);
        });
    };
    const renderStoredShares = () => {
        const texts = [];
        storedShares.forEach((share) => {
            texts.push(<View key={`share_id_${share.id}`} style={{
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    width: '100%',
                    borderWidth: ThemeManager.style.borderWidth,
                    marginBottom: ThemeManager.style.contentPaddingValue,
                    borderRadius: ThemeManager.style.borderRadius,
                    borderColor: ThemeManager.style.borderColor,
                }}>
          <View style={{ flexDirection: 'column', padding: 2 }}>
            <MaterialText>{share.name}</MaterialText>
            <MaterialText>
              {I18n.m.getMessage('shareAccessCount')}: {share.downloadCounter}
            </MaterialText>
            {new Date(share.expireDate).getTime() > new Date().getTime() ? (<MaterialText>
                {I18n.m.getMessage('shareExpireText')}: {new Date(share.expireDate).toLocaleDateString()}
              </MaterialText>) : (<MaterialText color={ThemeManager.style.brandDanger}>
                {I18n.m.getMessage('shareExpireText')}: {new Date(share.expireDate).toLocaleDateString()}
              </MaterialText>)}
          </View>
          <View style={{ position: 'absolute', top: 4, right: 4 }}>
            <View style={{ flexDirection: 'row' }}>
              <Icon icon="delete-outline" toolTip={I18n.m.getMessage('shareDelete')} onPress={() => deleteShare(share)}/>
              <Icon icon={Platform.OS === 'ios' ? 'export-variant' : 'share-variant-outline'} toolTip="" onPress={openShareMenu(share)}/>
            </View>
          </View>
        </View>);
        });
        return texts;
    };
    const dialogHeight = useRef(316);
    const dialogLayout = (e) => {
        dialogHeight.current = e.nativeEvent.layout.height;
    };
    return (<View style={{ marginBottom: storedShares.length > 0 ? 0 : ThemeManager.style.contentPaddingValue }}>
      <Form formHeaderProps={{
            formTitle: `${I18n.m.getMessage('shareHeader', {
                fileOrFolder: isFolder ? props.file.title : props.file.orgFilename,
            })}`,
        }} store={ClientStore.commandStore} command={command} dataOptions={dataOptions} onSaved={resultWrapper} saveButtonLabel={I18n.m.getMessage('shareCreateButton')}/>
      {storedShares.length > 0 ? (<View style={{
                height: 24,
                width: '100%',
                borderBottomWidth: ThemeManager.style.borderWidth,
                borderBottomColor: ThemeManager.style.borderColor,
                marginBottom: ThemeManager.style.contentPaddingValue,
            }}/>) : null}
      {storedShares.length > 0 ? (<View style={{
                margin: ThemeManager.style.contentPaddingValue,
                height: 'auto',
            }} onLayout={dialogLayout}>
          <View style={{ paddingBottom: 8 }}>
            <MaterialText type={MaterialTextTypes.Body1} strong>
              {I18n.m.getMessage('shares')}
            </MaterialText>
          </View>
          <ScrollView style={{ height: dialogHeight.current }}>{renderStoredShares()}</ScrollView>
        </View>) : null}
    </View>);
}
