var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProfileSettings = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var mime = _interopRequireWildcard(require("mime"));
var _ErrorReporter = require("odatarepos/build/src/reporting/ErrorReporter");
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _AuthClient = require("upmesh-auth-core/build/src/client/AuthClient");
var _RemoveUserImage = require("upmesh-auth-core/build/src/client/commands/user/RemoveUserImage");
var _CurrentUser = require("upmesh-auth-core/build/src/client/CurrentUser");
var _DateLocale = require("upmesh-i18n/build/src/i18n/DateLocale");
var uuid = _interopRequireWildcard(require("uuid"));
var _ImagePicker = _interopRequireDefault(require("../../../file/ImagePicker"));
var _ImagePickerOptions = require("../../../file/ImagePickerOptions");
var _Uploads = require("../../../file/upload/Uploads");
var _Alert = require("../../../theme/components/Alert");
var _ContainedButton = require("../../../theme/components/button/ContainedButton");
var _Icon = require("../../../theme/components/Icon");
var _Menu = require("../../../theme/components/Menu");
var _MaterialText = require("../../../theme/components/text/MaterialText");
var _I18n = require("../../../theme/i18n/I18n");
var _ResizeEvent = require("../../../theme/ResizeEvent");
var _Routing = require("../../../theme/routing/Routing");
var _ThemeManager = require("../../../theme/ThemeManager");
var _UserImage = require("./UserImage");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var ProfileSettings = exports.ProfileSettings = function (_PureComponent) {
  function ProfileSettings(props, context) {
    var _this;
    (0, _classCallCheck2.default)(this, ProfileSettings);
    _this = _callSuper(this, ProfileSettings, [props, context]);
    _this.setImagePicker = function (r) {
      _this.imagePicker = r;
    };
    _this.onChangeUser = function (en) {
      en.entities.forEach(function (e) {
        if (_CurrentUser.CurrentUser.entity != null && e.entity.id === _CurrentUser.CurrentUser.entity.id) {
          _this.setState({
            user: e.entity
          });
        }
      });
    };
    _this.onChangeUserAdress = function (en) {
      en.entities.forEach(function (e) {
        if (_CurrentUser.CurrentUser.entity != null && e.entity.userId === _CurrentUser.CurrentUser.entity.userId) {
          _this.setState({
            userAdress: e.entity
          });
        }
      });
    };
    _this.onGotUserFile = function () {
      var _ref = (0, _asyncToGenerator2.default)(function* (response) {
        var f = Array.isArray(response) ? response[0] : response;
        var user = _this.state.user;
        if (user != null) {
          var ext = mime.getExtension(f.mime);
          if (ext == null) {
            _Routing.Routing.instance.alert.post({
              text: _I18n.I18n.m.getMessage('fileError')
            });
            return;
          }
          var fileId = `${uuid.v1()}.${ext}`;
          try {
            yield _Uploads.Uploads.addUpload(`${_AuthClient.AuthClient.instance.url}/user/${user.userId}/image/${fileId}`, f.webFile != null ? f.webFile : f, 'userImage', {}, 5, 1920);
          } catch (e) {
            _ErrorReporter.ErrorReporter.sendReport({
              subject: 'cant add User Image',
              data: e,
              type: 'warn'
            });
            _Routing.Routing.instance.alert.post({
              text: `${_I18n.I18n.m.getMessage('imageUploadError')} ${e.toString()}`
            });
          }
        }
        var fileIdCounter = _this.state.fileIdCounter;
        _this.setState({
          fileIdCounter: fileIdCounter + 1
        });
      });
      return function (_x) {
        return _ref.apply(this, arguments);
      };
    }();
    _this.onLayout = function (_e) {
      _this.setState({
        imageSize: _ResizeEvent.ResizeEvent.current.windowWidth > 400 ? 128 : 64
      });
    };
    _this.onMenuPressChangeAdress = function (e) {
      var _Menu$instance;
      (_Menu$instance = _Menu.Menu.instance) == null ? void 0 : _Menu$instance.close();
      _Routing.Routing.instance.openDialog('changeAdress')(e);
    };
    _this.onMenuPressChangeData = function (e) {
      var _Menu$instance2;
      (_Menu$instance2 = _Menu.Menu.instance) == null ? void 0 : _Menu$instance2.close();
      _Routing.Routing.instance.openDialog('changeUserData')(e);
    };
    _this.onMenuPressChangeUserImage = function (_e) {
      var _Menu$instance3;
      (_Menu$instance3 = _Menu.Menu.instance) == null ? void 0 : _Menu$instance3.close();
      if (_this.imagePicker != null) {
        _this.imagePicker.execute();
      }
    };
    _this.deleteUserImageNow = function (_e) {
      var _Alert$instance;
      (_Alert$instance = _Alert.Alert.instance) == null ? void 0 : _Alert$instance.close();
      var user = _this.state.user;
      if (user != null) {
        var remove = new _RemoveUserImage.RemoveUserImage({
          userId: user.id
        });
        remove.execute(_AuthClient.AuthClient.instance.commandStore).catch(function (err) {
          _ErrorReporter.ErrorReporter.sendReport({
            subject: 'error removing user image',
            data: err,
            type: 'warn'
          });
          var errorMessage = typeof err === 'string' ? err : JSON.stringify(err);
          if (err['messageCode'] != null) {
            errorMessage = _I18n.I18n.m.getMessage(err['messageCode'], err['messageData']);
            if ((err['messageCode'] === 'badRequest' || err['messageCode'] === 'internalServer') && err['message'] != null) {
              errorMessage += `: ${err['message']}`;
            }
          } else if (err['message'] != null) {
            errorMessage = err['message'];
          }
          _Routing.Routing.instance.alert.post({
            text: errorMessage
          });
        });
      }
    };
    _this.onMenuPressDeleteUserImage = function (_e) {
      var _Menu$instance4, _Alert$instance2;
      (_Menu$instance4 = _Menu.Menu.instance) == null ? void 0 : _Menu$instance4.close();
      _Routing.Routing.instance.alert.post({
        text: _I18n.I18n.m.getMessage('accountImageDeleteQuestion'),
        buttons: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
          onPress: (_Alert$instance2 = _Alert.Alert.instance) == null ? void 0 : _Alert$instance2.close,
          title: _I18n.I18n.m.getMessage('no'),
          backgroundColor: "transparent",
          textColor: _ThemeManager.ThemeManager.style.brandPrimary
        }, "no"), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
          onPress: _this.deleteUserImageNow,
          title: _I18n.I18n.m.getMessage('yes'),
          backgroundColor: "transparent",
          textColor: _ThemeManager.ThemeManager.style.brandPrimary
        }, "yes")]
      });
    };
    _this.openEditMenu = function (e) {
      _reactNativeWeb.UIManager.measureInWindow(e.nativeEvent.target, function (x, y, _width, height) {
        var _Menu$instance5;
        var client = {
          x: x,
          y: y,
          height: height,
          width: 256
        };
        var items = [{
          text: _I18n.I18n.m.getMessage('accountImageDropHint'),
          onPress: _this.onMenuPressChangeUserImage
        }, {
          text: _I18n.I18n.m.getMessage('accountChangeData'),
          onPress: _this.onMenuPressChangeData
        }, {
          text: _I18n.I18n.m.getMessage('accountChangeAddressTitle'),
          onPress: _this.onMenuPressChangeAdress
        }];
        var user = _this.state.user;
        if (user != null && user.userImageFileId != null && user.userImageFileId.length > 0) {
          items.unshift({
            text: _I18n.I18n.m.getMessage('accountImageDeleteHint'),
            onPress: _this.onMenuPressDeleteUserImage
          });
        }
        (_Menu$instance5 = _Menu.Menu.instance) == null ? void 0 : _Menu$instance5.open({
          client: client,
          items: items
        });
      });
    };
    _this.state = {
      user: _CurrentUser.CurrentUser.entity,
      userAdress: undefined,
      init: false,
      fileIdCounter: 0,
      imageSize: _ResizeEvent.ResizeEvent.current.windowWidth > 400 ? 128 : 64
    };
    return _this;
  }
  (0, _inherits2.default)(ProfileSettings, _PureComponent);
  return (0, _createClass2.default)(ProfileSettings, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this2 = this;
      this.userAttachKey = _AuthClient.AuthClient.instance.eventDispatcher.attach({
        readModelName: 'User',
        callback: this.onChangeUser
      });
      this.userAdressAttachKey = _AuthClient.AuthClient.instance.eventDispatcher.attach({
        readModelName: 'UserAdress',
        callback: this.onChangeUserAdress
      });
      this.init().catch(function (e) {
        return void 0;
      });
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (this.userAttachKey != null) {
        _AuthClient.AuthClient.instance.eventDispatcher.detach('User', this.userAttachKey);
      }
      if (this.userAdressAttachKey != null) {
        _AuthClient.AuthClient.instance.eventDispatcher.detach('UserAdress', this.userAdressAttachKey);
      }
    }
  }, {
    key: "render",
    value: function render() {
      var elementPadding = this.props.elementPadding;
      var padding = elementPadding != null ? elementPadding : 8;
      var _this$state = this.state,
        user = _this$state.user,
        userAdress = _this$state.userAdress,
        init = _this$state.init,
        imageSize = _this$state.imageSize,
        fileIdCounter = _this$state.fileIdCounter;
      if (!init || user == null) {
        return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {});
      }
      var userName = '-';
      var phone = '-';
      var companyName = '_';
      var adress = '-';
      userName = user.getFullName();
      companyName = user['company'] != null ? user['company'] : '-';
      phone = user['phone'] != null ? user['phone'] : '-';
      if (user.functionInCompany != null && user.functionInCompany.length > 0) {
        companyName = `${companyName}
${user.functionInCompany}`;
      }
      if (userAdress != null) {
        adress = `${userAdress['street']} ${userAdress['streetnumber']}
${userAdress['zip']} ${userAdress['city']}
${userAdress['country']}`;
      }
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        onLayout: this.onLayout,
        style: {
          width: '100%',
          position: 'relative'
        },
        children: [(0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: Object.assign({
            width: '100%',
            position: 'relative',
            minHeight: imageSize + 64
          }, _ThemeManager.ThemeManager.style.contentPadding),
          children: [(0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
            style: {
              width: '100%',
              paddingRight: imageSize + 32
            },
            children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              style: {
                height: padding
              }
            }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              type: _MaterialText.MaterialTextTypes.Caption,
              children: _I18n.I18n.m.getMessage('accountName')
            }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              children: userName
            }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              style: {
                height: padding
              }
            }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              type: _MaterialText.MaterialTextTypes.Caption,
              children: _I18n.I18n.m.getMessage('accountCompany')
            }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              children: companyName
            }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              style: {
                height: padding
              }
            }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              type: _MaterialText.MaterialTextTypes.Caption,
              children: _I18n.I18n.m.getMessage('accountPhone')
            }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              children: phone
            }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              style: {
                height: padding
              }
            }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              type: _MaterialText.MaterialTextTypes.Caption,
              children: _I18n.I18n.m.getMessage('accountAddress')
            }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              children: adress
            })]
          }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              height: padding
            }
          }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              position: 'absolute',
              top: _ThemeManager.ThemeManager.style.contentPaddingValue,
              right: _ThemeManager.ThemeManager.style.contentPaddingValue
            },
            children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
              toolTip: _I18n.I18n.m.getMessage('tooltipEditProfileDetails'),
              icon: "pencil-outline",
              onPress: this.openEditMenu
            })
          }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              position: 'absolute',
              top: 8 + _ThemeManager.ThemeManager.style.contentPaddingValue,
              right: 32 + _ThemeManager.ThemeManager.style.contentPaddingValue
            },
            children: (0, _jsxRuntime.jsx)(_UserImage.UserImage, {
              user: user,
              size: imageSize,
              lightBox: true
            })
          })]
        }), (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            width: '100%',
            paddingTop: _ThemeManager.ThemeManager.style.contentPaddingValue / 4,
            paddingBottom: _ThemeManager.ThemeManager.style.contentPaddingValue / 4,
            paddingLeft: _ThemeManager.ThemeManager.style.contentPaddingValue,
            borderWidth: 0,
            borderTopWidth: _ThemeManager.ThemeManager.style.borderWidth,
            borderColor: _ThemeManager.ThemeManager.style.borderColor,
            flexDirection: 'row',
            flexWrap: 'wrap'
          },
          children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              alignSelf: 'center',
              paddingRight: padding
            },
            children: (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
              iconPosition: "left",
              icon: {
                icon: 'lock-reset'
              },
              onPress: _Routing.Routing.instance.openDialog('changePassword'),
              full: false,
              title: _I18n.I18n.m.getMessage('accountChangePW'),
              backgroundColor: "transparent",
              textColor: _ThemeManager.ThemeManager.style.brandPrimary
            })
          }), (0, _jsxRuntime.jsx)(_ImagePicker.default, {
            ref: this.setImagePicker,
            options: new _ImagePickerOptions.ImagePickerOptions({
              maxWidth: 1920,
              maxHeight: 1920,
              includeBase64: false,
              cropping: true,
              cropperCircleOverlay: true,
              dropMultiHandlerDialogText: _I18n.I18n.m.getMessage('accountImageDropOnMultiSelect'),
              dropText: _I18n.I18n.m.getMessage('accountImageDropHint'),
              selectMethod: 'any',
              includeExif: true,
              useFrontCamera: true,
              multiple: false,
              mediaType: 'photo',
              filePicketCompId: `userImage_${fileIdCounter}`,
              compressImageQuality: 0.85
            }),
            callBack: this.onGotUserFile
          })]
        }, "add")]
      });
    }
  }, {
    key: "init",
    value: function () {
      var _init = (0, _asyncToGenerator2.default)(function* () {
        yield _DateLocale.DateLocale.waitFor(function () {
          return _CurrentUser.CurrentUser.entity != null;
        });
        var adress = yield _AuthClient.AuthClient.instance.modals.userAdress.get({
          filter: `userId eq '${_CurrentUser.CurrentUser.userId}'`
        });
        var userAdress;
        if (adress.length > 0) {
          userAdress = adress[0];
        }
        this.setState({
          userAdress: userAdress,
          init: true
        });
      });
      function init() {
        return _init.apply(this, arguments);
      }
      return init;
    }()
  }]);
}(_react.PureComponent);