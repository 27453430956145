import React, { useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import ConnectionContext from 'materialTheme/src/connectionContext';
import { Card } from 'materialTheme/src/theme/components/Card';
import { NotificationsSettingsEntity, } from 'upmesh-core/src/client/query/entities/NotificationsSettingsEntity';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { RequestValidationMail } from 'upmesh-auth-core/src/client/commands/user/RequestValidationMail';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import { ChangeNotificationsSettings } from 'upmesh-core/src/client/commands/settings/ChangeNotificationsSettings';
import { Checkbox } from 'materialTheme/src/theme/components/Checkbox';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Picker } from 'materialTheme/src/theme/components/Picker';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { DeviceManager } from 'materialTheme/src/securestore/DeviceManager';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Alert } from 'materialTheme/src/theme/components/Alert';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { I18n } from '../../i18n/I18n';
import { PushNotifications } from './PushNotifications';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { ProjectNotifications } from './ProjectNotifications';
const styles = {
    notification: {
        paddingTop: ThemeManager.style.contentPaddingValue,
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    sectionStyle: {
        width: '100%',
        paddingTop: ThemeManager.style.contentPaddingValue,
        paddingBottom: ThemeManager.style.contentPaddingValue,
        paddingHorizontal: ThemeManager.style.contentPaddingValue,
        borderWidth: 0,
        borderColor: ThemeManager.style.borderColor,
        borderTopWidth: ThemeManager.style.borderWidth,
    },
};
export function Notifications(_props) {
    const [settings, setSettings] = useState(new NotificationsSettingsEntity());
    const [emailList, setEmailList] = useState([]);
    const [pushLoading, setPushLoading] = useState(false);
    const [pushEnabledOnDevice, setPushEnabledOnDevice] = useState('notSupported');
    const [selectedEmailIndex, setSelectedEmailIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const connection = useContext(ConnectionContext);
    const initializeCurrentOptions = async () => {
        setLoading(true);
        let settings = new NotificationsSettingsEntity({
            id: CurrentUser.userId,
            userId: CurrentUser.userId,
        });
        try {
            settings = await UpmeshClient.instance.modals.notificationsSettings.getById(CurrentUser.userId);
        }
        catch (err) {
            console.debug('cant get Settings, using defaults', settings, err);
        }
        const userMails = await AuthClient.instance.modals.userMails.get({
            filter: `deleted ne true and userId eq '${CurrentUser.userId}' and validated eq true`,
            orderby: 'created ASC,email ASC',
        });
        let selectedEmailIndex = 0;
        for (let i = 0; i < userMails.length; i += 1) {
            if (settings.email != null && settings.email === userMails[i].email) {
                selectedEmailIndex = i;
                break;
            }
        }
        setSelectedEmailIndex(selectedEmailIndex);
        setSettings(settings);
        setLoading(false);
        setEmailList(emailList);
    };
    useEffect(() => {
        PushNotifications.getState(settings.pushNotifications)
            .then((result) => {
            setPushEnabledOnDevice(result);
        })
            .catch((err) => {
            console.error('could not get push notification state', err);
        });
    }, [settings]);
    useEffect(() => {
        initializeCurrentOptions().catch((err) => {
            console.debug('can not load current notification options', err);
        });
        const userMailsKey = AuthClient.instance.eventDispatcher.attach({
            readModelName: 'UserMails',
            callback: initializeCurrentOptions,
        });
        const settingsKey = AuthClient.instance.eventDispatcher.attach({
            readModelName: 'NotificationsSettings',
            callback: initializeCurrentOptions,
        });
        return () => {
            AuthClient.instance.eventDispatcher.detach('UserMails', userMailsKey);
            AuthClient.instance.eventDispatcher.detach('NotificationSettings', settingsKey);
        };
    }, []);
    const requestValidationEmail = async (mailId) => {
        try {
            const r = new RequestValidationMail({}, mailId);
            await r.execute(AuthClient.instance.commandStore);
            Routing.instance.alert.post({
                text: I18n.m.getMessage('emailValidateSend'),
            });
        }
        catch (e) {
            console.debug('Error requesting email validation:', e);
            Routing.instance.alert.post({
                text: I18n.m.getMessage('emailRequestEmailValidationError'),
            });
        }
    };
    const onChangeNotificationOption = (option) => async (value) => {
        try {
            const newSettings = new NotificationsSettingsEntity({ ...settings, [option]: value });
            const c = new ChangeNotificationsSettings(newSettings);
            await c.execute();
            setSettings(newSettings);
            return true;
        }
        catch (e) {
            console.debug(e, e.message);
            Routing.instance.alert.post({ text: I18n.m.getMessage('notificationsErrorMessage') });
            ErrorReporter.sendReport({ subject: 'change notification error', data: e, type: 'warn' });
            return false;
        }
    };
    const onChangeCurrentEmail = (index) => {
        onChangeNotificationOption('email')(emailList[index].email)
            .then((saved) => {
            if (saved)
                setSelectedEmailIndex(index);
        })
            .catch((e) => {
            Routing.instance.alert.post({
                text: I18n.m.getMessage('notificationsErrorMessage'),
            });
            ErrorReporter.sendReport({ subject: 'change email error', data: e, type: 'warn' });
            console.debug('email could not be changed', e.message, e);
        });
    };
    const onChangePushNotificationOption = (value) => {
        setPushLoading(true);
        PushNotifications.setState(value)
            .then((pushEnabledOnDevice) => {
            setPushEnabledOnDevice(pushEnabledOnDevice);
            setPushLoading(false);
        })
            .catch((err) => {
            console.debug('cant set Notification', err);
            setPushLoading(true);
        });
    };
    const removePushOnDeviceNow = async (deviceId) => {
        Alert.instance?.close();
        if (settings != null && settings.pushNotifications != null) {
            const index = settings.pushNotifications.findIndex((p) => p.deviceId === deviceId);
            if (index > -1) {
                settings.pushNotifications.splice(index, 1);
                LoadingEvents.instance.startLoading();
                try {
                    const newSettings = new NotificationsSettingsEntity({ ...settings });
                    const c = new ChangeNotificationsSettings(newSettings);
                    await c.execute();
                    setSettings(newSettings);
                    LoadingEvents.instance.stopLoading();
                }
                catch (e) {
                    console.debug(e, e.message);
                    LoadingEvents.instance.stopLoading();
                    Routing.instance.alert.post({ text: I18n.m.getMessage('notificationsErrorMessage') });
                    ErrorReporter.sendReport({ subject: 'change notification error', data: e, type: 'warn' });
                }
            }
        }
    };
    const removePushOnDevice = (deviceId) => async (_e) => {
        Routing.instance.alert.post({
            text: I18n.m.getMessage('pushDeleteQuestion'),
            buttons: [
                <ContainedButton key="no" onPress={Alert.instance?.close} title={I18n.m.getMessage('no')} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
                <ContainedButton key="yes" onPress={() => {
                        removePushOnDeviceNow(deviceId).catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
                    }} title={I18n.m.getMessage('yes')} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
            ],
        });
    };
    const renderHelperText = () => {
        if (connection.offline || !connection.connectedToServer) {
            return (<MaterialText type={MaterialTextTypes.Body2} centeredBox={false} color={ThemeManager.style.brandDanger}>
          {I18n.m.getMessage('notificationsOfflineMessage')}
        </MaterialText>);
        }
        return (<MaterialText type={MaterialTextTypes.Body2} centeredBox={false} color="rgba(0,0,0,0.54)">
        {I18n.m.getMessage('sendNotificationsInCases')}
      </MaterialText>);
    };
    const renderSectionTitle = (titleValue) => {
        return (<View style={{ paddingBottom: 0 }}>
        <MaterialText strong>{titleValue}</MaterialText>
      </View>);
    };
    const renderNotificationOption = (notificationType, text) => {
        const disabled = connection.offline || !connection.connectedToServer;
        return (<View style={styles.notification}>
        <View style={{ flex: 1, width: '80%', alignItems: 'center', justifyContent: 'center' }}>
          <MaterialText numberOfLines={2}>{text}</MaterialText>
        </View>
        <Checkbox key={`check-box-${notificationType}-${settings[notificationType]}`} value={settings[notificationType]} onChange={onChangeNotificationOption(notificationType)} disabled={disabled}/>
      </View>);
    };
    const renderTicketSpecificOptions = () => {
        if (settings.allTickets) {
            return null;
        }
        return (<>
        <View style={{ paddingTop: ThemeManager.style.contentPaddingValue }}>
          <MaterialText type={MaterialTextTypes.Body2} centeredBox={false} color="rgba(0,0,0,0.54)">
            {I18n.m.getMessage('specificTaskInTicket')}
          </MaterialText>
        </View>
        {renderNotificationOption('ticketsCreator', I18n.m.getMessage('ticketsCreator'))}
        {renderNotificationOption('assigned', I18n.m.getMessage('assigned'))}
        {renderNotificationOption('watcher', I18n.m.getMessage('watcher'))}
        {renderNotificationOption('tester', I18n.m.getMessage('tester'))}
      </>);
    };
    const renderEmailValidationSection = (mailIndex) => {
        const mail = emailList[mailIndex];
        if (!mail || mail.validated) {
            return null;
        }
        return (<View key="notValidated" style={{ flexDirection: 'column' }}>
        <MaterialText type={MaterialTextTypes.Body2} centeredBox={false}>
          {I18n.m.getMessage('accountEmailNotValidated')}
        </MaterialText>
        <ContainedButton title={I18n.m.getMessage('accountEmailRequestValidateMail')} onPress={() => requestValidationEmail(mail.id)} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
      </View>);
    };
    const renderEmailList = () => {
        const disabled = connection.offline || !connection.connectedToServer;
        if (emailList.length === 0) {
            return null;
        }
        const email = settings.email != null && settings.email.length > 0 ? settings.email : emailList[0].email;
        if (disabled || emailList.length === 1) {
            return (<View style={{ width: 330 }}>
          <MaterialText>{email}</MaterialText>
          {renderEmailValidationSection(selectedEmailIndex)}
        </View>);
        }
        return (<View style={{ width: 330 }}>
        <MaterialText type={MaterialTextTypes.Body2} color="rgba(0,0,0,0.54)">
          {I18n.m.getMessage('preferableEmail')}
        </MaterialText>
        <Picker list={emailList.map((mail) => mail.email)} pickerColor="rgb(237, 237, 237)" pickerTextColor={ThemeManager.style.headerButtonColor} style={{ fontWeight: '400', textAlign: 'left' }} menuWidth={330} elevation={20} onChange={onChangeCurrentEmail} selectedIndex={selectedEmailIndex}/>
        {renderEmailValidationSection(selectedEmailIndex)}
      </View>);
    };
    const renderPushNotificationOption = (text) => {
        const disabled = connection.offline || !connection.connectedToServer;
        if (pushEnabledOnDevice === 'notSupported') {
            return <MaterialText>{I18n.m.getMessage('pushNotificationOnDeviceNotSupported')}</MaterialText>;
        }
        if (pushEnabledOnDevice === 'denied') {
            return <MaterialText>{I18n.m.getMessage('pushNotificationOnDeviceDenied')}</MaterialText>;
        }
        return (<View style={styles.notification}>
        <View style={{ flex: 1, width: '80%', alignItems: 'center', justifyContent: 'center' }}>
          <MaterialText numberOfLines={2}>{text}</MaterialText>
        </View>
        {pushLoading ? (<Spinner small/>) : (<Checkbox key={`check-box-push-${pushEnabledOnDevice}`} value={pushEnabledOnDevice} onChange={onChangePushNotificationOption} disabled={disabled}/>)}
      </View>);
    };
    const renderOtherDevicesPushNotificationOption = () => {
        if (settings != null && settings.pushNotifications != null && settings.pushNotifications.length > 0) {
            const d = DeviceManager.deviceId;
            const pn = [];
            settings.pushNotifications.forEach((p) => {
                if (p != null && p.deviceId !== d) {
                    pn.push(p);
                }
            });
            const list = [];
            pn.forEach((p) => {
                const text = p.type === 'web' ? I18n.m.getMessage('pushDeviceWeb') : I18n.m.getMessage('pushDeviceMobile');
                list.push(<View style={styles.notification} key={`${p.deviceId}`}>
            <View style={{ flex: 1, width: '80%', alignItems: 'center', justifyContent: 'center' }}>
              <MaterialText numberOfLines={2}>{text}</MaterialText>
            </View>
            <Icon icon="delete" toolTip={I18n.m.getMessage('delete')} onPress={removePushOnDevice(p.deviceId)}/>
          </View>);
            });
            if (list.length > 0) {
                return (<View style={{ width: '100%' }}>
            <MaterialText strong>{I18n.m.getMessage('pushOtherDevices')}</MaterialText>
            {list}
          </View>);
            }
        }
        return null;
    };
    if (loading) {
        return <Spinner />;
    }
    return (<>
      <View style={{ marginLeft: 8 + ThemeManager.style.contentPaddingValue, marginTop: 8 }}>
        <MaterialText>{I18n.m.getMessage('accountNotifications')}</MaterialText>
      </View>
      <Card>
        <View style={{ width: '100%', position: 'relative' }}>
          <View style={{
            padding: ThemeManager.style.contentPaddingValue,
            paddingBottom: ThemeManager.style.contentPaddingValue,
        }}>
            {renderHelperText()}
          </View>

          <View style={styles.sectionStyle}>
            {renderSectionTitle(I18n.m.getMessage('plans'))}
            {renderNotificationOption('addedPlanVersion', I18n.m.getMessage('addedPlanVersion'))}
            {renderNotificationOption('planDetailChange', I18n.m.getMessage('planDetailChange'))}
          </View>

          <View style={styles.sectionStyle}>
            {renderSectionTitle(I18n.m.getMessage('tickets'))}
            {renderNotificationOption('ticketCreateChange', I18n.m.getMessage('ticketCreateChange'))}
            {renderNotificationOption('allTickets', I18n.m.getMessage('allTickets'))}
            {renderTicketSpecificOptions()}
          </View>

          <View style={styles.sectionStyle}>
            {renderSectionTitle(I18n.m.getMessage('files'))}
            {renderNotificationOption('filesCreatedOrChanged', I18n.m.getMessage('filesCreateChange'))}
            {renderNotificationOption('folderCreatedOrChanged', I18n.m.getMessage('folderCreateChange'))}
          </View>

          <View style={styles.sectionStyle}>
            {renderSectionTitle(I18n.m.getMessage('emailNotification'))}
            {renderEmailList()}
            {renderNotificationOption('notifyNow', I18n.m.getMessage('notifyNow'))}
            {renderNotificationOption('dailyCompilation', I18n.m.getMessage('dailyCompilation'))}
          </View>

          <View style={styles.sectionStyle}>
            {renderSectionTitle(I18n.m.getMessage('pushNotification'))}
            {renderPushNotificationOption(I18n.m.getMessage('pushNotificationOnDevice'))}
            {renderOtherDevicesPushNotificationOption()}
          </View>
          <View style={styles.sectionStyle}>
            <ProjectNotifications settings={settings}/>
          </View>
        </View>
      </Card>
    </>);
}
