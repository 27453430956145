"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ODataRepo = exports.ChangeEventResult = void 0;
var ApplicationError_1 = require("../../entities/ApplicationError");
var OdataEntity_1 = require("../../entities/root/OdataEntity");
var ChangeEventResult = (0, _createClass2.default)(function ChangeEventResult(event, options) {
  (0, _classCallCheck2.default)(this, ChangeEventResult);
  this.event = event;
  this.options = options;
});
exports.ChangeEventResult = ChangeEventResult;
var ODataRepo = function () {
  function ODataRepo(_exampleEntity, _db) {
    (0, _classCallCheck2.default)(this, ODataRepo);
  }
  return (0, _createClass2.default)(ODataRepo, [{
    key: "addEventListener",
    value: function addEventListener(_event, handler) {
      this.changeEvent.attach(handler);
    }
  }, {
    key: "count",
    value: function () {
      var _count = (0, _asyncToGenerator2.default)(function* (filter) {
        if (filter != null) filter = this.encodeFilter(filter);
        var result = yield this.doCount(filter);
        this.changeEvent.post(new ChangeEventResult('onCounted'));
        return result;
      });
      function count(_x) {
        return _count.apply(this, arguments);
      }
      return count;
    }()
  }, {
    key: "delete",
    value: function () {
      var _delete2 = (0, _asyncToGenerator2.default)(function* (entityId) {
        var orgE = yield this.getById(entityId);
        var result = yield this.doDelete(orgE);
        this.changeEvent.post(new ChangeEventResult('onDeleted', orgE));
        return result;
      });
      function _delete(_x2) {
        return _delete2.apply(this, arguments);
      }
      return _delete;
    }()
  }, {
    key: "deleteFilter",
    value: function () {
      var _deleteFilter = (0, _asyncToGenerator2.default)(function* (filter) {
        if (filter != null) filter = this.encodeFilter(filter);
        var result = yield this.doDeleteFilter(filter);
        this.changeEvent.post(new ChangeEventResult('onDeletedMany', result));
        return result;
      });
      function deleteFilter(_x3) {
        return _deleteFilter.apply(this, arguments);
      }
      return deleteFilter;
    }()
  }, {
    key: "distinct",
    value: function () {
      var _distinct = (0, _asyncToGenerator2.default)(function* (select, _filter) {
        if (_filter != null) _filter = this.encodeFilter(_filter);
        var result = yield this.doDistinct(select);
        this.changeEvent.post(new ChangeEventResult('onDistinct'));
        return result;
      });
      function distinct(_x4, _x5) {
        return _distinct.apply(this, arguments);
      }
      return distinct;
    }()
  }, {
    key: "get",
    value: function () {
      var _get = (0, _asyncToGenerator2.default)(function* (options) {
        if (options != null && options.filter != null) options.filter = this.encodeFilter(options.filter);
        var result = yield this.doGet(options);
        this.changeEvent.post(new ChangeEventResult('onGot'));
        var typedResult = [];
        for (var e of result) {
          typedResult.push(this._exampleEntity.createOne(e));
        }
        return typedResult;
      });
      function get(_x6) {
        return _get.apply(this, arguments);
      }
      return get;
    }()
  }, {
    key: "encodeFilter",
    value: function encodeFilter(filter) {
      return filter.replace(/'([^']+)'/g, function ($1) {
        return encodeURIComponent($1).replace(/%20/g, ' ');
      });
    }
  }, {
    key: "getById",
    value: function () {
      var _getById = (0, _asyncToGenerator2.default)(function* (id, select) {
        var result = yield this.doGetById(id, select);
        if (result == null) {
          throw new ApplicationError_1.ApplicationError('not found', `${this._exampleEntity.entityName}notFound`, ApplicationError_1.ApplicationErrorStatusCode.NotFoundError, {
            id: id
          });
        }
        this.changeEvent.post(new ChangeEventResult('onGot'));
        return this._exampleEntity.createOne(result);
      });
      function getById(_x7, _x8) {
        return _getById.apply(this, arguments);
      }
      return getById;
    }()
  }, {
    key: "patch",
    value: function () {
      var _patch = (0, _asyncToGenerator2.default)(function* (entity) {
        var get = yield this.getById(entity.id, undefined);
        for (var k in entity) {
          entity[k] = OdataEntity_1.OdataEntity.dateReviver(k, entity[k]);
        }
        var e = this._exampleEntity.createOne(get);
        e.fill(entity);
        yield e.validate();
        var result = this._exampleEntity.createOne(yield this.doPatch(entity));
        this.changeEvent.post(new ChangeEventResult('onPatched', entity));
        return result;
      });
      function patch(_x9) {
        return _patch.apply(this, arguments);
      }
      return patch;
    }()
  }, {
    key: "post",
    value: function () {
      var _post = (0, _asyncToGenerator2.default)(function* (entity) {
        try {
          var exists = yield this.getById(entity.id, undefined);
          if (exists != null) {
            throw new ApplicationError_1.ApplicationError('already exists', 'conflict', ApplicationError_1.ApplicationErrorStatusCode.ConflictError);
          }
        } catch (e) {
          if (e instanceof ApplicationError_1.ApplicationError) {
            if (e.statusCode !== ApplicationError_1.ApplicationErrorStatusCode.NotFoundError) {
              throw e;
            }
          }
        }
        for (var k in entity) {
          entity[k] = OdataEntity_1.OdataEntity.dateReviver(k, entity[k]);
        }
        var typedEntity = this._exampleEntity.createOne(entity);
        var valid = yield this.validateEntity(typedEntity);
        if (valid.error) {
          throw this.returnValidationError(valid.errorMessage);
        }
        var result = yield this.doPost(typedEntity);
        this.changeEvent.post(new ChangeEventResult('onPosted', typedEntity));
        return this._exampleEntity.createOne(result);
      });
      function post(_x10) {
        return _post.apply(this, arguments);
      }
      return post;
    }()
  }, {
    key: "postOrPatchMany",
    value: function () {
      var _postOrPatchMany = (0, _asyncToGenerator2.default)(function* (entities) {
        var _this = this;
        var ent = [];
        for (var i = 0; i < entities.length; i += 1) {
          var entity = entities[i];
          for (var k in entity) {
            entity[k] = OdataEntity_1.OdataEntity.dateReviver(k, entity[k]);
          }
          var typedEntity = this._exampleEntity.createOne(entity);
          var valid = yield this.validateEntity(typedEntity);
          if (valid.error) {
            throw this.returnValidationError(valid.errorMessage);
          }
          ent.push(typedEntity);
        }
        var result = [];
        try {
          result = ent.length > 0 ? yield this.doPostOrPatchMany(ent) : [];
        } catch (e) {
          if (e.toString().includes('conflict')) {
            var toPost = [];
            for (var _i = 0; _i < ent.length; _i += 1) {
              result.push(ent[_i].id);
              try {
                yield this.patch(ent[_i]);
              } catch (err) {
                toPost.push(ent[_i]);
              }
            }
            if (toPost.length > 0) yield this.doPostOrPatchMany(toPost);
          } else {
            throw e;
          }
        }
        ent.forEach(function (typedEntity) {
          _this.changeEvent.post(new ChangeEventResult('onPosted', typedEntity));
        });
        return result;
      });
      function postOrPatchMany(_x11) {
        return _postOrPatchMany.apply(this, arguments);
      }
      return postOrPatchMany;
    }()
  }, {
    key: "patchManyWithFilter",
    value: function () {
      var _patchManyWithFilter = (0, _asyncToGenerator2.default)(function* (body, filter) {
        return this.doPatchManyWithFilter(body, filter);
      });
      function patchManyWithFilter(_x12, _x13) {
        return _patchManyWithFilter.apply(this, arguments);
      }
      return patchManyWithFilter;
    }()
  }, {
    key: "removeEventListener",
    value: function removeEventListener() {}
  }, {
    key: "returnSyntaxError",
    value: function returnSyntaxError(errorMessage) {
      return new ApplicationError_1.ApplicationError(`Syntax Error: ${errorMessage}`, 'dbError', ApplicationError_1.ApplicationErrorStatusCode.BadRequestError);
    }
  }, {
    key: "returnValidationError",
    value: function returnValidationError(errorMessage) {
      return new ApplicationError_1.ApplicationError(`Validation Error: ${errorMessage}`, 'dbError', ApplicationError_1.ApplicationErrorStatusCode.BadRequestError);
    }
  }, {
    key: "validateEntity",
    value: function () {
      var _validateEntity = (0, _asyncToGenerator2.default)(function* (entity) {
        try {
          yield entity.validate();
          return {
            error: false,
            errorMessage: ''
          };
        } catch (e) {
          if (e != null) return {
            error: true,
            errorMessage: typeof e === 'string' ? e : JSON.stringify(e)
          };
          return {
            error: true,
            errorMessage: 'unknown error'
          };
        }
      });
      function validateEntity(_x14) {
        return _validateEntity.apply(this, arguments);
      }
      return validateEntity;
    }()
  }]);
}();
exports.ODataRepo = ODataRepo;