import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import * as uuid from 'uuid';
import { I18n } from '../../../i18n/I18n';
export const compareDates = (aRaw, bRaw) => {
    if (aRaw == null && bRaw == null)
        return true;
    if (aRaw == null || bRaw == null)
        return false;
    const a = new Date(aRaw);
    const b = new Date(bRaw);
    return a.getDate() === b.getDate() && a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth();
};
export const getItemKey = (uid) => (item, _index) => `ticket_card_${item.id}${uid === true ? `_${uuid.v1()}` : ''}`;
export const getMonth = (date, range) => {
    if (range === 'month')
        return `${I18n.m.dateCurrent.monthsNames()[date.getMonth()]} ${date.getFullYear()}`;
    if (range === 'day')
        return `${date.getDate()} ${I18n.m.dateCurrent.monthsNames()[date.getMonth()]} ${date.getFullYear()}`;
    const day = date.getDay();
    const weekStart = new Date(date.getTime());
    weekStart.setDate(weekStart.getDate() - day);
    const weekEnd = new Date(weekStart.getTime());
    weekEnd.setDate(weekStart.getDate() + 6);
    if (weekEnd.getMonth() === weekStart.getMonth())
        return `${I18n.m.dateCurrent.monthsNames()[date.getMonth()]} ${date.getFullYear()}`;
    return `${I18n.m.dateCurrent.monthsNames()[weekStart.getMonth()]} ${weekStart.getFullYear()} - ${I18n.m.dateCurrent.monthsNames()[weekEnd.getMonth()]} ${weekEnd.getFullYear()}`;
};
const getNextThursday = (date) => {
    const thursday = new Date();
    thursday.setTime(date.getTime() + (3 - ((date.getDay() + 6) % 7)) * 86400000);
    return thursday;
};
export const getKW = (date) => {
    const doDat = getNextThursday(date);
    const kwjahr = doDat.getFullYear();
    const doKW1 = getNextThursday(new Date(kwjahr, 0, 4));
    return Math.floor(1.5 + (doDat.getTime() - doKW1.getTime()) / 86400000 / 7);
};
export const isS = () => ResizeEvent.current.contentWidth < 900;
export const getOrderedWeekDays = () => {
    const weekdayNamesShort = [...I18n.m.dateCurrent.weekdayNamesShort()];
    const { startWithMonday } = CurrentUser.settings;
    if (startWithMonday) {
        weekdayNamesShort.push(weekdayNamesShort[0].slice(0, 2));
        weekdayNamesShort.splice(0, 1);
    }
    return weekdayNamesShort;
};
export const dateIsInRange = (date, from, to, withTime) => {
    if (date == null || from == null || to == null)
        return -2;
    let start = new Date(from);
    let end = new Date(to);
    if (withTime !== true) {
        start = new Date(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0, 0, 0);
        end = new Date(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59, 59, 999);
    }
    if (date.getTime() < start.getTime())
        return -1;
    if (date.getTime() > end.getTime())
        return 1;
    return 0;
};
