"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeUserNames = void 0;
var CurrentUser_1 = require("../../CurrentUser");
var UserNamesChanged_1 = require("../../events/user/UserNamesChanged");
var ClientUserCommands_1 = require("./root/ClientUserCommands");
var ChangeUserNames = function (_ClientUserCommands_) {
  function ChangeUserNames(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : CurrentUser_1.CurrentUser.userId;
    (0, _classCallCheck2.default)(this, ChangeUserNames);
    _this = _callSuper(this, ChangeUserNames, [data, entityId]);
    _this.commandName = 'ChangeUserNames';
    _this.aggregate = 'user';
    return _this;
  }
  (0, _inherits2.default)(ChangeUserNames, _ClientUserCommands_);
  return (0, _createClass2.default)(ChangeUserNames, [{
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        if (key == null || key === 'firstname') {
          if (this.data.firstname.length <= 0) {
            throw {
              key: 'firstname',
              messageCode: 'registerErrorRequireFirstName',
              message: 'Vorname wird benötigt'
            };
          }
        }
        if (key == null || key === 'lastname') {
          if (this.data.lastname.length <= 0) {
            throw {
              key: 'lastname',
              messageCode: 'registerErrorRequireLastName',
              message: 'Nachname wird benötigt'
            };
          }
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var data = Object.assign({
          userId: this.data.userId
        }, this.data);
        var e = new UserNamesChanged_1.UserNamesChanged(this.entityId, data);
        return e;
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }]);
}(ClientUserCommands_1.ClientUserCommands);
exports.ChangeUserNames = ChangeUserNames;