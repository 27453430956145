var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Toast = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-client/node_modules/react-native-web");
var _ThemeManager = require("../ThemeManager");
var _SlideAnimation = require("./animations/SlideAnimation");
var _ContainedButton = require("./button/ContainedButton");
var _MaterialText = require("./text/MaterialText");
var _jsxRuntime = require("../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var TOAST_OPENING = 'opening';
var TOAST_OPENED = 'opened';
var TOAST_CLOSING = 'closing';
var TOAST_CLOSED = 'closed';
var Toast = exports.Toast = function (_PureComponent) {
  function Toast(props) {
    var _this;
    (0, _classCallCheck2.default)(this, Toast);
    _this = _callSuper(this, Toast, [props]);
    _this.animationRunning = false;
    _this._onLayoutView = function (_event) {
      _this.forceUpdate();
    };
    _this.close = function (callback) {
      if (!_this.animationRunning) {
        if (_this.animationRunningTO != null) {
          clearTimeout(_this.animationRunningTO);
        }
        _this.closeNow(callback);
      } else {
        _this.animationRunningTO = setTimeout(function () {
          _this.close();
        }, 200);
      }
    };
    _this.closeNow = function (callback) {
      var onClosed = _this.state.onClosed;
      _this.settoastState(0, function () {
        if (callback != null && typeof callback === 'function') {
          callback();
        }
        if (onClosed != null) {
          onClosed();
        }
      });
    };
    _this.state = Toast.defaultState;
    return _this;
  }
  (0, _inherits2.default)(Toast, _PureComponent);
  return (0, _createClass2.default)(Toast, [{
    key: "pointerEvents",
    get: function get() {
      return this.state.toastState === TOAST_OPENED ? 'auto' : 'none';
    }
  }, {
    key: "toastState",
    get: function get() {
      return this.state.toastState;
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      Toast._instance = this;
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (_reactNativeWeb.Keyboard != null) _reactNativeWeb.Keyboard.dismiss();
    }
  }, {
    key: "open",
    value: function open(state) {
      var _this2 = this;
      if (!this.animationRunning) {
        if (this.animationRunningTO != null) {
          clearTimeout(this.animationRunningTO);
        }
        if (this.toastState === TOAST_OPENED) {
          this.close(function () {
            return _this2.open(state);
          });
        } else {
          var newState = Object.assign(Toast.defaultState, state);
          this.toastAnimation = new _SlideAnimation.SlideAnimation(0, 'bottom', false);
          this.setState(newState, function () {
            _this2.openNow();
          });
        }
      } else {
        this.animationRunningTO = setTimeout(function () {
          _this2.open(state);
        }, 200);
      }
    }
  }, {
    key: "openNow",
    value: function openNow() {
      var onOpened = this.state.onOpened;
      this.settoastState(1, onOpened);
    }
  }, {
    key: "render",
    value: function render() {
      var size = this.props.size;
      var toastState = this.state.toastState;
      var hidden = toastState === TOAST_CLOSED ? _ThemeManager.ThemeManager.style.dialogHidden : {};
      if (toastState === TOAST_CLOSED) {
        return null;
      }
      var backgroundColor = this.state.backgroundColor != null && this.state.backgroundColor.length > 0 ? this.state.backgroundColor : _ThemeManager.ThemeManager.style.toastDefaultBg;
      var textColor = this.state.textColor != null && this.state.textColor.length > 0 ? this.state.textColor : _ThemeManager.ThemeManager.style.toastDefaultTextColor;
      var dimensions = {
        width: size.windowWidth,
        height: 'auto',
        padding: 16,
        paddingTop: size.contentTop,
        paddingBottom: size.contentBottom,
        justifyContent: 'flex-end',
        alignItems: size.windowWidth <= _ThemeManager.ThemeManager.style.breakpointM ? 'center' : 'flex-start'
      };
      var width;
      if (size.windowWidth <= _ThemeManager.ThemeManager.style.breakpointM) {
        width = size.windowWidth - _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(24);
      }
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        pointerEvents: _reactNativeWeb.Platform.OS.toString() === 'web' ? 'box-none' : 'auto',
        style: [_ThemeManager.ThemeManager.style.toastContainer, hidden, dimensions],
        children: (0, _jsxRuntime.jsx)(_reactNativeWeb.Animated.View, {
          onLayout: this._onLayoutView,
          collapsable: false,
          style: [this.state.toastStyle, this.toastAnimation != null ? this.toastAnimation.animations : null],
          children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
            style: {
              backgroundColor: backgroundColor,
              flexWrap: 'nowrap',
              width: width || null,
              maxWidth: width || 568,
              minWidth: 288,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 4,
              padding: 14
            },
            children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              style: {
                justifyContent: 'flex-start',
                flexGrow: 1,
                flexShrink: 1,
                alignSelf: 'center'
              },
              children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
                type: _MaterialText.MaterialTextTypes.Body2,
                color: textColor,
                centeredBox: false,
                children: this.state.title
              })
            }), this.state.buttonTitle != null && this.state.buttonTitle.length > 0 ? (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
              textColor: this.state.buttonTextColor,
              title: this.state.buttonTitle,
              onPress: this.state.onPressButton,
              full: false,
              backgroundColor: "transparent"
            }) : null]
          })
        })
      });
    }
  }, {
    key: "settoastState",
    value: function settoastState(toValue, callback) {
      var _this3 = this;
      if (!this.animationRunning) {
        this.animationRunning = true;
        var toastState = toValue > 0 ? TOAST_OPENING : TOAST_CLOSING;
        if (this.toastAnimation != null) {
          this.setState({
            toastState: toastState
          }, function () {
            _this3.toastAnimation.toValue(toValue, function () {
              if (toastState === TOAST_CLOSING) {
                _this3.setState(Toast.defaultState, function () {
                  _this3.animationRunning = false;
                  if (callback != null) {
                    callback();
                  }
                });
              } else {
                _this3.setState({
                  toastState: TOAST_OPENED
                }, function () {
                  _this3.animationRunning = false;
                  if (callback != null) {
                    callback();
                  }
                });
              }
            });
          });
        } else if (toastState === TOAST_CLOSING) {
          this.setState(Toast.defaultState, function () {
            _this3.animationRunning = false;
            if (callback != null) {
              callback();
            }
          });
        } else {
          this.setState({
            toastState: TOAST_OPENED
          }, function () {
            _this3.animationRunning = false;
            if (callback != null) {
              callback();
            }
          });
        }
      } else {
        clearTimeout(this.animationRunningTO);
        this.animationRunningTO = setTimeout(function () {
          _this3.settoastState(toValue, callback);
        }, this.state.animationDuration);
      }
      if (this.toastState === TOAST_CLOSED) {
        clearTimeout(this.visibleToastTimeout);
        this.visibleToastTimeout = setTimeout(this.close, this.state.toastVisibleTime);
      }
    }
  }, {
    key: "startTimeoutAgain",
    value: function startTimeoutAgain() {
      if (this.state.toastState === TOAST_OPENED) {
        this.visibleToastTimeout = setTimeout(this.close, this.state.toastVisibleTime);
      }
    }
  }, {
    key: "stopTimeout",
    value: function stopTimeout() {
      clearTimeout(this.visibleToastTimeout);
    }
  }], [{
    key: "defaultState",
    get: function get() {
      return {
        title: '',
        backgroundColor: undefined,
        toastState: TOAST_CLOSED,
        buttonTitle: '',
        toastVisibleTime: 10000,
        onPressButton: function onPressButton() {
          var _Toast$instance;
          (_Toast$instance = Toast.instance) == null ? void 0 : _Toast$instance.close();
        },
        animationDuration: 150,
        onOpened: undefined,
        onClosed: undefined,
        buttonTextColor: undefined,
        textColor: undefined,
        toastStyle: undefined
      };
    }
  }, {
    key: "instance",
    get: function get() {
      if (Toast._instance != null) {
        return Toast._instance;
      }
      return null;
    }
  }, {
    key: "isOpened",
    get: function get() {
      var _Toast$instance2, _Toast$instance3, _Toast$instance4, _Toast$instance5;
      return Toast.instance != null && ((_Toast$instance2 = Toast.instance) == null ? void 0 : _Toast$instance2.state) != null && ((_Toast$instance3 = Toast.instance) == null ? void 0 : _Toast$instance3.state.toastState) != null && (((_Toast$instance4 = Toast.instance) == null ? void 0 : _Toast$instance4.state.toastState) === TOAST_OPENED || ((_Toast$instance5 = Toast.instance) == null ? void 0 : _Toast$instance5.state.toastState) === TOAST_OPENING);
    }
  }, {
    key: "resetTimeout",
    value: function resetTimeout() {
      var _Toast$instance6;
      (_Toast$instance6 = Toast.instance) == null ? void 0 : _Toast$instance6.stopTimeout();
    }
  }, {
    key: "restartTimeout",
    value: function restartTimeout() {
      var _Toast$instance7;
      (_Toast$instance7 = Toast.instance) == null ? void 0 : _Toast$instance7.startTimeoutAgain();
    }
  }]);
}(_react.PureComponent);