var _a;
import { Uploads } from 'materialTheme/src/file/upload/Uploads';
import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React from 'react';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { FolderEntity } from 'upmesh-core/src/client/query/entities/FolderEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { CommandReadModels } from 'upmesh-core/src/server/webserver/commands/CommandReadModels';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { AddFilesPU } from './AddFilesPU';
import { SharedFiles } from './SharedFiles';
export class EditedFileSaver {
}
_a = EditedFileSaver;
EditedFileSaver.saveEditedImage = (storedFile, editable = false) => async (fileData, _alreadyAccepted) => {
    let hasFolderEditRights;
    try {
        hasFolderEditRights =
            storedFile.folder != null &&
                storedFile.folder.length > 0 &&
                (await FolderEntity.hasFolderEditRights(storedFile.folder, CurrentUser.userId));
    }
    catch (e) {
        hasFolderEditRights = false;
    }
    if (fileData != null) {
        const project = await CommandReadModels.project.getById(storedFile.projectId);
        await new Promise((resolve) => {
            const buttons = [
                <ContainedButton backgroundColor="#FFFFFF" textColor={ThemeManager.style.brandPrimary} key="close" onPress={() => {
                        Alert.instance?.close(() => {
                            resolve();
                        });
                    }} title={I18n.m.getMessage('discard')}/>,
                <ContainedButton key="saveCopy" onPress={() => {
                        Alert.instance?.close(() => {
                            EditedFileSaver.saveEditedImageNow(storedFile, fileData, true)().catch((err) => {
                                DefaultErrorHandler.showDefaultErrorAlert(err);
                            });
                            resolve();
                        });
                    }} title={I18n.m.getMessage('saveCopy')}/>,
            ];
            let canEdit = false;
            let reason = '';
            if (AuthClient.instance.serverConnected() && (editable || project != null)) {
                if (storedFile.forEntity === 'Ticket' &&
                    storedFile.forEntityId != null &&
                    storedFile.forEntityId.length > 0) {
                    canEdit =
                        CurrentUser.userId === storedFile.createdBy &&
                            (project.filesEditableTickets === 'ever' ||
                                (project.filesEditableTickets === '24h' &&
                                    storedFile.createdAt.getTime() + 1000 * 60 * 60 * 24 > new Date().getTime()));
                    if (CurrentUser.userId !== storedFile.createdBy)
                        reason = I18n.m.getMessage('filesSaveNotPossibleCreator');
                    else if (!(project.filesEditableTickets === 'ever' ||
                        (project.filesEditableTickets === '24h' &&
                            storedFile.createdAt.getTime() + 1000 * 60 * 60 * 24 > new Date().getTime()))) {
                        reason = I18n.m.getMessage('filesSaveNotPossibleTime');
                    }
                }
                else {
                    canEdit = storedFile.createdBy === CurrentUser.userId || hasFolderEditRights;
                    if (storedFile.createdBy !== CurrentUser.userId)
                        reason = I18n.m.getMessage('filesSaveNotPossibleCreator');
                    else if (!hasFolderEditRights)
                        reason = I18n.m.getMessage('filesSaveNotPossibleFolder');
                }
            }
            else {
                reason = I18n.m.getMessage('filesSaveNotPossibleOffline');
            }
            buttons.push(<ContainedButton key="save" onPress={() => {
                    if (canEdit) {
                        Alert.instance?.close(() => {
                            EditedFileSaver.saveEditedImageNow(storedFile, fileData, false)().catch((err) => {
                                DefaultErrorHandler.showDefaultErrorAlert(err);
                            });
                            resolve();
                        });
                    }
                    else {
                        Alert.instance?.open({
                            title: I18n.m.getMessage('filesSaveNotPossible'),
                            content: reason,
                            closeOnTouchOutside: true,
                            buttons: [
                                <ContainedButton key="imagePickerAlert" title={I18n.m.getMessage('ok')} onPress={Alert.instance?.close} full={false} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
                            ],
                        });
                    }
                }} title={I18n.m.getMessage('save')}/>);
            Routing.instance.alert.post({
                title: I18n.m.getMessage('photoEditorSaveTitle'),
                text: I18n.m.getMessage('photoEditorSaveText'),
                buttons,
            });
        });
    }
};
EditedFileSaver.saveEditedImageNow = (storedFile, fileData, asNewFile) => async (_e) => {
    Alert.instance?.close(() => {
        if (fileData == null)
            return;
        if (storedFile != null) {
            if (asNewFile) {
                const file = fileData['path'] != null ? { path: fileData['path'], filename: storedFile.orgFilename } : fileData;
                try {
                    SharedFiles.files = [file];
                    SharedFiles.excludeFolders = null;
                    const data = { type: 'TicketOrFolder' };
                    data.projectId = storedFile.projectId;
                    if (storedFile.forEntityId != null) {
                        data.type = 'Tickets';
                        data.entityId = storedFile.forEntityId;
                    }
                    AddFilesPU.openDialog(data)(null);
                }
                catch (err) {
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                }
            }
            else {
                const targetUrl = `${UpmeshClient.instance.url}/storedfile/file/${storedFile.id}`;
                const submitData = { ...storedFile };
                if (submitData['versions'])
                    submitData['versions'] = undefined;
                try {
                    Uploads.addUpload(targetUrl, fileData, storedFile.fileType, {
                        ...submitData,
                        projectId: storedFile.projectId,
                    }).catch(DefaultErrorHandler.showDefaultErrorAlert);
                }
                catch (e) {
                    DefaultErrorHandler.showDefaultErrorAlert(e);
                }
            }
        }
    });
};
