"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createFilter = exports.createQuery = void 0;
var parser_1 = require("../parser");
var Visitor_1 = require("./Visitor");
function createQuery(odataQuery) {
  var ast = typeof odataQuery === 'string' ? (0, parser_1.query)(odataQuery) : odataQuery;
  return new Visitor_1.Visitor().Visit(ast);
}
exports.createQuery = createQuery;
function createFilter(odataFilter, regExWithFlag) {
  var context = {
    query: {}
  };
  var ast = typeof odataFilter === 'string' ? (0, parser_1.filter)(odataFilter) : odataFilter;
  Visitor_1.Visitor.regExWithFlag = regExWithFlag;
  new Visitor_1.Visitor().Visit(ast, context);
  return context.query;
}
exports.createFilter = createFilter;