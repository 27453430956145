var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WeatherScreen = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _reactNativeChartKit = require("react-native-chart-kit");
var _I18n = require("../../i18n/I18n");
var _ThemeManager = require("../../ThemeManager");
var _CardHeader = require("../CardHeader");
var _IconWithText = require("../IconWithText");
var _ResponsiveColsList = require("../ResponsiveColsList");
var _Spinner = require("../Spinner");
var _MaterialText = require("../text/MaterialText");
var _Ripple = require("../utils/Ripple");
var _WeatherIcons = require("./icons/WeatherIcons");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var WeatherScreen = exports.WeatherScreen = function (_PureComponent) {
  function WeatherScreen(props) {
    var _this;
    (0, _classCallCheck2.default)(this, WeatherScreen);
    _this = _callSuper(this, WeatherScreen, [props]);
    _this.getClosest = function (time) {
      var weatherData = _this.state.weatherData;
      if (weatherData == null || weatherData.hourly == null || weatherData.hourly.data == null || weatherData.hourly.data.length === 0) {
        return -1;
      }
      var diff = WeatherScreen.maxMinusMin(time, weatherData.hourly.data[0].time);
      var diffPos = 0;
      for (var i = 0; i < weatherData.hourly.data.length; i += 1) {
        if (WeatherScreen.maxMinusMin(time, weatherData.hourly.data[i].time) < diff) {
          diff = WeatherScreen.maxMinusMin(time, weatherData.hourly.data[i].time);
          diffPos = i;
        }
      }
      return diffPos;
    };
    _this.getDataOfTheHour = function () {
      var weatherData = _this.state.weatherData;
      if (weatherData == null || weatherData.hourly == null || weatherData.hourly.data == null || weatherData.hourly.data.length === 0) {
        return null;
      }
      var now = new Date().getTime();
      return weatherData.hourly.data[_this.getClosest(now)];
    };
    _this.selectDate = function (date) {
      return function (_e) {
        _this.setState({
          selectedDay: date
        });
      };
    };
    _this.onLayoutCard = function (e) {
      if (e && e.nativeEvent && e.nativeEvent.layout && e.nativeEvent.layout.width > 0) {
        _this.setState({
          cardWidth: e.nativeEvent.layout.width
        });
      }
    };
    _this.chartConfig = {
      backgroundGradientFrom: '#FFFFFF',
      backgroundGradientFromOpacity: 0,
      backgroundGradientTo: '#FFFFFF',
      backgroundGradientToOpacity: 0,
      color: function color() {
        var opacity = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
        return `rgba(0, 0, 0, ${opacity})`;
      },
      strokeWidth: 2,
      barPercentage: 0.5,
      useShadowColorFromDataset: false
    };
    _this.updateLocationStateData = (0, _asyncToGenerator2.default)(function* () {
      var secondTry = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      try {
        var weatherData = yield _this.getWeatherForLocation();
        if (weatherData != null && weatherData.latitude != null) {
          _this.setState({
            weatherData: weatherData,
            isLoading: false
          });
        } else if (secondTry) _this.setState({
          isLoading: false
        });else {
          setTimeout(function () {
            return _this.updateLocationStateData(true);
          }, 2500);
        }
      } catch (e) {}
    });
    _this.state = {
      selectedDay: new Date(),
      cardWidth: 200,
      isLoading: true
    };
    return _this;
  }
  (0, _inherits2.default)(WeatherScreen, _PureComponent);
  return (0, _createClass2.default)(WeatherScreen, [{
    key: "getForecast",
    value: function getForecast(dataOfTheDay) {
      var showGraph = this.props.showGraph;
      var selectedDay = this.state.selectedDay;
      var day = new Date(dataOfTheDay.time);
      var weekdayString = _I18n.I18n.m.dateCurrent.weekdayNamesShort()[day.getDay()];
      var seletected = selectedDay.getDate() === day.getDate();
      return (0, _jsxRuntime.jsxs)(_Ripple.Ripple, {
        disabled: !showGraph,
        onPress: this.selectDate(day),
        style: {
          height: 148,
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          backgroundColor: seletected && showGraph ? 'rgba(200, 200, 200, 0.10)' : '#FFFFFF'
        },
        children: [(0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          centeredBox: true,
          centeredText: true,
          type: _MaterialText.MaterialTextTypes.Body1,
          children: weekdayString
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            paddingTop: 16,
            paddingBottom: 16
          },
          children: (0, _jsxRuntime.jsx)(_WeatherIcons.WeatherIcons, {
            width: 40,
            height: 40,
            type: dataOfTheDay.icon
          })
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          centeredBox: true,
          centeredText: true,
          type: _MaterialText.MaterialTextTypes.Body1,
          children: `${Math.round(dataOfTheDay.temperatureHigh)}°C`
        }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          centeredBox: true,
          centeredText: true,
          type: _MaterialText.MaterialTextTypes.Body1,
          color: _ThemeManager.ThemeManager.style.black54,
          children: `${Math.round(dataOfTheDay.temperatureLow)}°C`
        })]
      });
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      this.updateLocationStateData().catch(function (e) {
        return void 0;
      });
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps) {
      var _this$props = this.props,
        lat = _this$props.lat,
        long = _this$props.long;
      if (prevProps.lat !== lat || prevProps.long !== long) {
        this.updateLocationStateData().catch(function (e) {
          return void 0;
        });
      }
    }
  }, {
    key: "render",
    value: function render() {
      var noCurrentWeatherDataText = this.props.noCurrentWeatherDataText;
      var _this$state = this.state,
        weatherData = _this$state.weatherData,
        isLoading = _this$state.isLoading;
      if (isLoading) {
        return this.renderCard((0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            alignContent: 'center',
            paddingBottom: 36,
            width: '100%'
          },
          children: (0, _jsxRuntime.jsx)(_Spinner.Spinner, {})
        }));
      }
      try {
        if (weatherData != null && weatherData.hourly != null && weatherData.hourly.data != null && weatherData.hourly.data[weatherData.hourly.data.length - 1].time > new Date().getTime()) {
          var summary = weatherData.daily.data[0].summary;
          return this.renderCard(this.renderTodaysDetails(), summary || '');
        }
      } catch (e) {}
      return this.renderCard((0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          padding: _ThemeManager.ThemeManager.style.contentPaddingValue,
          paddingTop: 0,
          width: '100%'
        },
        children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          color: _ThemeManager.ThemeManager.style.brandDanger,
          centeredBox: true,
          centeredText: true,
          children: noCurrentWeatherDataText
        })
      }));
    }
  }, {
    key: "renderCard",
    value: function renderCard(content, subtitle) {
      var city = this.props.city;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          width: '100%'
        },
        onLayout: this.onLayoutCard,
        children: [(0, _jsxRuntime.jsx)(_CardHeader.CardHeader, {
          title: city,
          subtitle: subtitle
        }), content]
      });
    }
  }, {
    key: "getForecastSection",
    value: function getForecastSection() {
      var _this$state2 = this.state,
        weatherData = _this$state2.weatherData,
        cardWidth = _this$state2.cardWidth;
      if (cardWidth == null) return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {});
      if (weatherData == null) {
        return (0, _jsxRuntime.jsx)(_Spinner.Spinner, {});
      }
      var today = new Date();
      today = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0);
      var forecasts = [];
      if (weatherData.daily != null && weatherData.daily.data != null) {
        for (var i = 0; i < weatherData.daily.data.length; i += 1) {
          var dataOfTheDay = weatherData.daily.data[i];
          if (new Date(dataOfTheDay.time).getTime() >= today.getTime()) {
            forecasts.push(this.getForecast(dataOfTheDay));
          }
        }
      }
      if (forecasts.length === 0) return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {});
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          paddingTop: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(24)
        },
        children: (0, _jsxRuntime.jsx)(_ResponsiveColsList.ResponsiveColsList, {
          contents: forecasts,
          targetWidthPerSection: 80,
          width: cardWidth,
          height: 148
        })
      });
    }
  }, {
    key: "getWeatherForLocation",
    value: function () {
      var _getWeatherForLocation = (0, _asyncToGenerator2.default)(function* () {
        return this.props.mapper.getDataForLocation(this.props.lat, this.props.long);
      });
      function getWeatherForLocation() {
        return _getWeatherForLocation.apply(this, arguments);
      }
      return getWeatherForLocation;
    }()
  }, {
    key: "renderTodaysDetails",
    value: function renderTodaysDetails() {
      var cardWidth = this.state.cardWidth;
      var todaysData = this.getDataOfTheHour();
      var size = cardWidth > 390 ? 'M' : 'S';
      var iconSize = _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(24);
      if (todaysData == null) {
        return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {});
      }
      var icons = [(0, _jsxRuntime.jsx)(_IconWithText.IconWithText, {
        iconName: WeatherScreen.getPrecipIcon(todaysData.precipType),
        style: {
          padding: 4
        },
        iconSize: iconSize,
        children: todaysData.precipProbability ? `${Math.round(todaysData.precipProbability * 100)} mm/qm` : '0 mm/qm'
      }, "precipPropability"), (0, _jsxRuntime.jsx)(_IconWithText.IconWithText, {
        iconName: "water-percent",
        style: {
          padding: 4
        },
        iconSize: iconSize,
        children: todaysData.humidity ? `${Math.round(todaysData.humidity * 100)}%` : '?'
      }, "humidity"), (0, _jsxRuntime.jsx)(_IconWithText.IconWithText, {
        iconName: "weather-windy",
        style: {
          padding: 4
        },
        iconSize: iconSize,
        children: todaysData.windSpeed ? `${Math.round(todaysData.windSpeed).toString()} km/h` : '?'
      }, "wind"), (0, _jsxRuntime.jsx)(_IconWithText.IconWithText, {
        iconName: "speedometer",
        style: {
          padding: 4
        },
        iconSize: iconSize,
        children: todaysData.pressure ? `${Math.round(todaysData.pressure)} hPa` : '?'
      }, "speed")];
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          width: '100%'
        },
        children: [this.renderTodaysWeather(todaysData), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: '100%',
            justifyContent: 'space-between',
            flexDirection: 'row',
            flexWrap: 'wrap',
            paddingLeft: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(16),
            paddingRight: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(16),
            minHeight: 49,
            alignItems: 'center'
          },
          children: size === 'M' ? icons : [(0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
            children: [icons[0], icons[1]]
          }, "upperIcons"), (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
            children: [icons[2], icons[3]]
          }, "lowerIcons")]
        }), this.getForecastSection(), this.renderGraph()]
      });
    }
  }, {
    key: "renderGraph",
    value: function renderGraph() {
      var showGraph = this.props.showGraph;
      if (!showGraph) return null;
      var _this$state3 = this.state,
        selectedDay = _this$state3.selectedDay,
        weatherData = _this$state3.weatherData,
        cardWidth = _this$state3.cardWidth;
      if (weatherData == null) return null;
      var start = new Date(selectedDay.getFullYear(), selectedDay.getMonth(), selectedDay.getDate(), 0, 0, 0, 0);
      var end = new Date(selectedDay.getFullYear(), selectedDay.getMonth(), selectedDay.getDate() + 1, 0, 0, 0, 0);
      var hData = [];
      var labels = [];
      var rainData = [];
      var temperatureData = [];
      var windData = [];
      var s = cardWidth > 700 ? 1 : cardWidth > 500 ? 2 : 3;
      var icons = [];
      weatherData.hourly.data.forEach(function (e) {
        if (e.time >= start.getTime() && e.time <= end.getTime()) {
          var hour = new Date(e.time).getHours();
          if (hour % s === 0) {
            hData.push(e);
            icons.push(e.icon);
            labels.push(hour.toString());
            rainData.push(e.precipProbability == null ? 0 : e.precipProbability);
            temperatureData.push(e.temperature);
            windData.push(e.windSpeed != null ? e.windSpeed : 0);
          }
        }
      });
      var data = {
        labels: labels,
        datasets: [{
          data: temperatureData,
          color: function color() {
            var opacity = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
            return `rgba(255,255,0, ${opacity})`;
          },
          strokeWidth: 2
        }, {
          data: rainData,
          color: function color() {
            var opacity = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
            return `rgba(68, 138, 255, ${opacity})`;
          },
          strokeWidth: 2
        }, {
          data: windData,
          color: function color() {
            var opacity = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
            return `rgba(105,240,174, ${opacity})`;
          },
          strokeWidth: 2
        }],
        paddingRight: 0,
        height: 220,
        width: cardWidth,
        legend: [_I18n.I18n.m.getMessage('temperature'), _I18n.I18n.m.getMessage('precipitation'), _I18n.I18n.m.getMessage('wind')]
      };
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          width: '100%',
          backgroundColor: 'rgba(200, 200, 200, 0.10)'
        },
        children: [(0, _jsxRuntime.jsx)(_reactNativeChartKit.LineChart, {
          data: data,
          width: cardWidth,
          height: 220,
          chartConfig: this.chartConfig
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: '100%',
            justifyContent: 'space-between',
            flexDirection: 'row',
            flexWrap: 'wrap',
            margin: -8,
            paddingLeft: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(64),
            paddingRight: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(16),
            paddingBottom: 8,
            minHeight: 24,
            alignItems: 'center'
          },
          children: icons.map(function (e) {
            return (0, _jsxRuntime.jsx)(_WeatherIcons.WeatherIcons, {
              width: 24,
              height: 24,
              type: e
            });
          })
        })]
      });
    }
  }, {
    key: "renderTodaysWeather",
    value: function renderTodaysWeather(todaysData) {
      var cardWidth = this.state.cardWidth;
      var scale = 1;
      if (cardWidth < 327) {
        scale = cardWidth / 327;
      }
      var iconSize = _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(128);
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          width: cardWidth,
          padding: 0,
          overflow: 'visible'
        },
        children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            paddingLeft: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(16),
            paddingRight: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(16),
            overflow: 'visible',
            flexDirection: 'row',
            justifyContent: 'space-between',
            transform: [{
              scale: scale
            }]
          },
          children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              flexDirection: 'row'
            },
            children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              type: _MaterialText.MaterialTextTypes.H1,
              color: _ThemeManager.ThemeManager.style.black54,
              children: `${Math.round(todaysData.temperature)}°`
            })
          }), (0, _jsxRuntime.jsx)(_WeatherIcons.WeatherIcons, {
            width: iconSize,
            height: iconSize,
            type: todaysData.icon
          })]
        })
      });
    }
  }], [{
    key: "maxMinusMin",
    value: function maxMinusMin(a, b) {
      return Math.max(a, b) - Math.min(a, b);
    }
  }, {
    key: "defaultProps",
    get: function get() {
      return {
        backgroundColor: _ThemeManager.ThemeManager.style.cardDefaultBg,
        noWeatherDataText: 'Keine aktuellen Wetterdaten verfügbar',
        noCurrentWeatherDataText: 'Keine aktuellen Wetterdaten verfügbar'
      };
    }
  }, {
    key: "getPrecipIcon",
    value: function getPrecipIcon(precipType) {
      if (precipType == null || precipType === 'rain') {
        return 'weather-rainy';
      }
      if (precipType === 'snow') {
        return 'weather-snowy';
      }
      if (precipType === 'sleet') {
        return 'weather-snowy-rainy';
      }
      return 'weather-rainy';
    }
  }]);
}(_react.PureComponent);