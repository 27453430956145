import { DateInput } from 'materialTheme/src/theme/components/forminput/DateInput';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { SetTicketShouldCompleteDate, } from 'upmesh-core/src/client/commands/tickets/SetTicketShouldCompleteDate';
import { TicketEntity } from 'upmesh-core/src/client/query/entities/TicketEntity';
import { I18n } from '../../../i18n/I18n';
export class TicketCompletionDateChip extends PureComponent {
    constructor(props) {
        super(props);
        this.dateQuickSelection = [];
        this.checkDisabled = async () => {
            const { ticket } = this.props;
            const c = new SetTicketShouldCompleteDate({}, ticket.id);
            try {
                const canI = await c.canI();
                if (typeof canI === 'boolean' && canI) {
                    this.setState({ disabledChip: false });
                }
                else {
                    this.setState({ disabledChip: true });
                }
            }
            catch (e) {
                this.setState({ disabledChip: true });
            }
        };
        this.changeCompletionOn = async (newDate) => {
            const { ticket } = this.props;
            const completionOn = newDate;
            try {
                const c = new SetTicketShouldCompleteDate({ completionOn }, ticket.id);
                await c.execute();
            }
            catch (e) {
                this.props.errorResult(e);
            }
        };
        const completionStatus = TicketEntity.getStatusForCompletionOn(props.ticket.ticketStatus, props.ticket.completionOn);
        const completionColor = completionStatus === 'overdue' ? ThemeManager.style.brandDanger : ThemeManager.style.defaultIconColor;
        this.state = { completionColor, disabledChip: true };
        const today = new Date();
        this.dateQuickSelection = [
            { name: I18n.m.getMessage('today'), days: today.getTime() },
            {
                name: I18n.m.getMessage('tomorrow'),
                days: new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 0, 0, 0).getTime(),
            },
            {
                name: I18n.m.getMessage('nextWeek'),
                days: new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7, 0, 0, 0).getTime(),
            },
            {
                name: I18n.m.getMessage('weeks', { count: 2 }),
                days: new Date(today.getFullYear(), today.getMonth(), today.getDate() + 14, 0, 0, 0).getTime(),
            },
            {
                name: I18n.m.getMessage('weeks', { count: 4 }),
                days: new Date(today.getFullYear(), today.getMonth(), today.getDate() + 28, 0, 0, 0).getTime(),
            },
        ];
    }
    componentDidMount() {
        this.checkDisabled().catch((err) => console.debug(err));
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        const completionStatus = TicketEntity.getStatusForCompletionOn(nextProps.ticket.ticketStatus, nextProps.ticket.completionOn);
        const completionColor = completionStatus === 'overdue' ? ThemeManager.style.brandDanger : ThemeManager.style.defaultIconColor;
        if (completionColor !== prevState.completionColor) {
            return { completionColor, disabledChip: prevState.disabledChip };
        }
        return null;
    }
    render() {
        const { labelStyle, ticket, hideTime } = this.props;
        return [
            <View style={labelStyle} key="CompletionLabel">
        <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption}>
          {I18n.m.getMessage('ticketsDetailsCompletionOn')}
        </MaterialText>
      </View>,
            <DateInput key={`CompletionOn_${this.state.completionColor}`} labelText={I18n.m.getMessage('ticketsDetailsCompletionOn')} textColor={this.state.completionColor} onChange={this.changeCompletionOn} selectedDate={ticket.completionOn != null ? new Date(ticket.completionOn) : undefined} preSelectedDate={ticket.completionOn != null
                    ? undefined
                    : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())} quickSelections={this.dateQuickSelection} placeholder={I18n.m.getMessage('date')} selectTime={!hideTime} disabled={this.state.disabledChip} iconWidth={32} iconHeight={32}/>,
        ];
    }
}
