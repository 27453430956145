var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SyncView = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _WaitFor = require("cqrs-shared/build/src/WaitFor");
var _react = _interopRequireWildcard(require("../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-client/node_modules/react-native-web");
var _AuthClient = require("upmesh-auth-core/build/src/client/AuthClient");
var _CurrentUser = require("upmesh-auth-core/build/src/client/CurrentUser");
var _UserImageSyncButton = require("../../components/account/profile/UserImageSyncButton");
var _TempFile = require("../../file/upload/entities/TempFile");
var _Uploads = require("../../file/upload/Uploads");
var _Alert = require("../../theme/components/Alert");
var _ContainedButton = require("../../theme/components/button/ContainedButton");
var _Card = require("../../theme/components/Card");
var _Icon = require("../../theme/components/Icon");
var _ListItem = require("../../theme/components/ListItem");
var _UploadingList = require("../../theme/components/upload/UploadingList");
var _I18n = require("../../theme/i18n/I18n");
var _Routing = require("../../theme/routing/Routing");
var _ThemeManager = require("../../theme/ThemeManager");
var _BackButton = _interopRequireDefault(require("../components/BackButton"));
var _PageView = require("../root/PageView");
var _jsxRuntime = require("../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var SyncView = exports.SyncView = function (_PureComponent) {
  function SyncView(props) {
    var _this;
    (0, _classCallCheck2.default)(this, SyncView);
    _this = _callSuper(this, SyncView, [props]);
    _this.updateSyncStatus = function () {
      _this.init().catch(function (err) {
        return void 0;
      });
    };
    _this.onChangeCommands = function (key) {
      if (key === `offlineCommands_${_CurrentUser.CurrentUser.userId}`) {
        _this.init().catch(function (err) {
          return void 0;
        });
      }
    };
    _this.showCommandError = function (command) {
      return function (_e) {
        var result;
        var defaultErrorHandler = _this.props.defaultErrorHandler;
        try {
          if (command.result != null) {
            result = JSON.parse(command.result);
          }
        } catch (e) {}
        var error = defaultErrorHandler != null ? defaultErrorHandler(result) : result != null && result.messageCode != null ? _I18n.I18n.m.getMessage(result.messageCode) : command.result;
        _Routing.Routing.instance.alert.post({
          text: _I18n.I18n.m.getMessage('cantSyncCommand', {
            error: error
          }),
          buttons: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            onPress: _this.discardCommand(command),
            title: _I18n.I18n.m.getMessage('discard'),
            backgroundColor: "transparent",
            textColor: _ThemeManager.ThemeManager.style.brandPrimary
          }, "dis"), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            onPress: _this.syncCommandAgain(command),
            title: _I18n.I18n.m.getMessage('tryAgain'),
            backgroundColor: "transparent",
            textColor: _ThemeManager.ThemeManager.style.brandPrimary
          }, "again")]
        });
      };
    };
    _this.discardCommand = function (command) {
      return function () {
        var _ref = (0, _asyncToGenerator2.default)(function* (_e) {
          var _Alert$instance;
          (_Alert$instance = _Alert.Alert.instance) == null ? void 0 : _Alert$instance.close();
          if (_AuthClient.AuthClient.instance.offlineCommandRepo != null) {
            try {
              _AuthClient.AuthClient.instance.offlineCommandRepo.delete(command.id).catch(function (err) {
                return void 0;
              });
            } catch (e) {}
          }
        });
        return function (_x) {
          return _ref.apply(this, arguments);
        };
      }();
    };
    _this.syncCommandAgain = function (_command) {
      return function (_e) {
        var _Alert$instance2;
        (_Alert$instance2 = _Alert.Alert.instance) == null ? void 0 : _Alert$instance2.close();
        _this.startSync();
      };
    };
    _this.startSync = function () {
      if (_AuthClient.AuthClient.instance != null) {
        _AuthClient.AuthClient.instance.startSync().catch(function (err) {
          return void 0;
        });
      }
    };
    _this.onChanged = function () {
      var _ref2 = (0, _asyncToGenerator2.default)(function* (e) {
        if (e.event === 'onPatched' || e.event === 'onPosted' || e.event === 'onDeleted') {
          _this.init().catch(function (err) {
            return void 0;
          });
        }
      });
      return function (_x2) {
        return _ref2.apply(this, arguments);
      };
    }();
    _this.onChangeUser = function (e) {
      _this.setState({
        user: e.entity
      });
    };
    _this.state = {
      user: _CurrentUser.CurrentUser.entity,
      maxWidth: Math.min(512, _ThemeManager.ThemeManager.style.windowWidth),
      commands: [],
      uploadFiles: [],
      syncStatus: {
        color: _ThemeManager.ThemeManager.style.brandSecondary,
        status: 'Online',
        isSyncing: false,
        percent: 0
      }
    };
    return _this;
  }
  (0, _inherits2.default)(SyncView, _PureComponent);
  return (0, _createClass2.default)(SyncView, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.userAttachKey = _AuthClient.AuthClient.instance.eventDispatcher.attach({
        readModelName: 'User',
        callback: this.onChangeUser,
        entityId: _CurrentUser.CurrentUser.userId
      });
      _AuthClient.AuthClient.instance.syncDispatcher.syncStatus.attach(this.updateSyncStatus);
      _AuthClient.AuthClient.instance.serverConnectionsChanged.attach(this.updateSyncStatus);
      if (_AuthClient.AuthClient.instance.offlineCommandRepo) _AuthClient.AuthClient.instance.offlineCommandRepo.changeEvent.attach(this.onChanged);
      this.init().catch(function (err) {
        return void 0;
      });
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (_AuthClient.AuthClient.instance.offlineCommandRepo != null) {
        _AuthClient.AuthClient.instance.offlineCommandRepo.changeEvent.detach(this.onChanged);
      }
      _AuthClient.AuthClient.instance.syncDispatcher.syncStatus.detach(this.updateSyncStatus);
      _AuthClient.AuthClient.instance.serverConnectionsChanged.detach(this.updateSyncStatus);
      if (this.userAttachKey != null) {
        _AuthClient.AuthClient.instance.eventDispatcher.detach('User', this.userAttachKey);
      }
      if (this.tempfileRepo != null) {
        this.tempfileRepo.changeEvent.detach(this.onChanged);
      }
    }
  }, {
    key: "init",
    value: function () {
      var _init = (0, _asyncToGenerator2.default)(function* () {
        var _AuthClient$instance$;
        yield _WaitFor.WaitFor.instance.waitFor(function () {
          return _AuthClient.AuthClient.instance.ready;
        });
        var syncErrors = 0;
        var uploadFiles = [];
        var commands = [];
        var tempfilesErrors = 0;
        if (_Uploads.Uploads.localDb != null) {
          if (this.tempfileRepo == null) {
            this.tempfileRepo = _Uploads.Uploads.localDb.getRepos(new _TempFile.TempFile());
            this.tempfileRepo.changeEvent.attach(this.onChanged);
          }
          uploadFiles = yield this.tempfileRepo.get({
            filter: 'targetUrl ne null',
            orderby: 'createdAt ASC'
          });
          for (var t of uploadFiles) {
            if (t.error != null) {
              tempfilesErrors += 1;
            }
          }
        }
        var all = yield (_AuthClient$instance$ = _AuthClient.AuthClient.instance.offlineCommandRepo) == null ? void 0 : _AuthClient$instance$.get({
          filter: 'synced ne true',
          orderby: 'createdAt ASC'
        });
        if (all != null) {
          commands = (0, _toConsumableArray2.default)(all);
          for (var c of commands) {
            if (c.error) {
              syncErrors += 1;
            }
          }
        }
        syncErrors += tempfilesErrors;
        this.setState({
          commands: commands,
          uploadFiles: uploadFiles,
          syncStatus: _UserImageSyncButton.UserImageSyncButton.getSyncStatus(syncErrors, commands.length, uploadFiles)
        });
      });
      function init() {
        return _init.apply(this, arguments);
      }
      return init;
    }()
  }, {
    key: "renderDatabase",
    value: function renderDatabase() {
      var syncStatus = this.state.syncStatus;
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          width: '100%',
          paddingTop: _ThemeManager.ThemeManager.style.contentPaddingValue / 4,
          paddingBottom: _ThemeManager.ThemeManager.style.contentPaddingValue / 4,
          paddingLeft: _ThemeManager.ThemeManager.style.contentPaddingValue,
          borderWidth: 0,
          borderTopWidth: _ThemeManager.ThemeManager.style.borderWidth,
          borderColor: _ThemeManager.ThemeManager.style.borderColor,
          flexDirection: 'row',
          flexWrap: 'wrap'
        },
        children: (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
          backgroundColor: "transparent",
          textColor: _ThemeManager.ThemeManager.style.brandPrimary,
          disabled: syncStatus.status !== 'Online',
          onPress: this.startSync,
          title: _I18n.I18n.m.getMessage('syncAgain')
        })
      }, "databaseOptions");
    }
  }, {
    key: "renderContent",
    value: function renderContent() {
      var _this$state = this.state,
        commands = _this$state.commands,
        uploadFiles = _this$state.uploadFiles,
        syncStatus = _this$state.syncStatus;
      var fileInfo = this.props.fileInfo;
      var list = [];
      if (!syncStatus.isSyncing && commands.length === 0 && uploadFiles.length === 0) {
        list.push((0, _jsxRuntime.jsx)(_ListItem.ListItem, {
          style: {
            width: '100%'
          },
          title: syncStatus.status === 'Offline' ? _I18n.I18n.m.getMessage('syncStatusOffline', syncStatus.textValues) : _I18n.I18n.m.getMessage('syncStatusOk'),
          thumbnail: {
            thumbnail: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              style: {
                width: 56,
                height: 56
              },
              children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
                icon: syncStatus.status === 'Offline' ? 'cloud-off-outline' : 'check-circle-outline',
                color: syncStatus.status === 'Offline' ? _ThemeManager.ThemeManager.style.black38 : _ThemeManager.ThemeManager.style.brandSecondary,
                toolTip: "",
                iconSize: 36,
                outerSize: 56
              })
            }),
            width: 56
          }
        }, "syncOK"));
      }
      if (syncStatus.isSyncing) {
        list.push((0, _jsxRuntime.jsx)(_ListItem.ListItem, {
          style: {
            width: '100%'
          },
          title: `${_I18n.I18n.m.getMessage('syncStatusSyncing')} ${syncStatus.percent}%`,
          thumbnail: {
            thumbnail: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              style: {
                width: 56,
                height: 56
              },
              children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
                icon: "cached",
                toolTip: "",
                iconSize: 36,
                outerSize: 56
              })
            }),
            width: 56
          }
        }, "syncing"));
      }
      if (uploadFiles.length > 0) {
        list.push((0, _jsxRuntime.jsx)(_ListItem.ListItem, {
          selected: true,
          textStyle: {
            fontWeight: _ThemeManager.ThemeManager.style.fontWeightBold
          },
          style: {
            width: '100%'
          },
          title: _I18n.I18n.m.getMessage('filesSyncList')
        }, "sync_Files"), (0, _jsxRuntime.jsx)(_UploadingList.UploadingList, {
          fileInfo: fileInfo
        }, "uploadingList"));
      }
      if (commands.length > 0) {
        list.push((0, _jsxRuntime.jsx)(_ListItem.ListItem, {
          selected: true,
          textStyle: {
            fontWeight: _ThemeManager.ThemeManager.style.fontWeightBold
          },
          style: {
            width: '100%'
          },
          title: _I18n.I18n.m.getMessage('commandSyncList')
        }, "sync_commands"));
      }
      for (var c of commands) {
        var text = _I18n.I18n.m.getMessage(`command${c.commandName}`, Object.assign({}, c.data));
        list.push((0, _jsxRuntime.jsx)(_ListItem.ListItem, {
          style: {
            width: '100%'
          },
          secondTextLine: text,
          iconRight: c.error === true ? {
            icon: 'alert-circle'
          } : undefined,
          onPress: c.error === true ? this.showCommandError(c) : undefined
        }, `sync_${c.id}`));
      }
      list.push(this.renderDatabase());
      return list;
    }
  }, {
    key: "render",
    value: function render() {
      return (0, _jsxRuntime.jsx)(_PageView.PageView, {
        showAccountIcon: true,
        headerProps: {
          leftButtons: [(0, _jsxRuntime.jsx)(_BackButton.default, {}, "backButtonSyncView")],
          rightButtons: [],
          title: _I18n.I18n.m.getMessage('syncTitle')
        },
        scrollable: true,
        style: {
          backgroundColor: _ThemeManager.ThemeManager.style.appBgColor
        },
        children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: '100%',
            maxWidth: 640,
            paddingTop: _ThemeManager.ThemeManager.style.contentPaddingValue,
            paddingBottom: 64
          },
          children: (0, _jsxRuntime.jsx)(_Card.Card, {
            children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              style: {
                width: '100%'
              },
              children: this.renderContent()
            })
          })
        })
      });
    }
  }]);
}(_react.PureComponent);