"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FinalizeJournal = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var StdApplicationError_1 = require("odatarepos/build/src/entities/StdApplicationError");
var uuid = require("uuid");
var ClientStore_1 = require("../../../ClientStore");
var JournalEntity_1 = require("../../../query/entities/JournalEntity");
var ProjectEntity_1 = require("../../../query/entities/ProjectEntity");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var FinalizeJournal = function (_CommandRoot_1$Comman) {
  function FinalizeJournal(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v4();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, FinalizeJournal);
    _this = _callSuper(this, FinalizeJournal, [data, entityId, token]);
    _this.commandName = 'FinalizeJournal';
    _this.aggregate = 'project';
    return _this;
  }
  (0, _inherits2.default)(FinalizeJournal, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(FinalizeJournal, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        this.journalData = yield CommandReadModels_1.CommandReadModels.instance.journal.getById(this.data.journalId);
        var project = yield CommandReadModels_1.CommandReadModels.instance.project.getById(this.journalData.projectId);
        var companyMember = yield CommandReadModels_1.CommandReadModels.instance.companyMember.get({
          filter: `userId eq '${userId}' and deleted ne true and role ne 'inactive'`
        });
        if (companyMember != null && companyMember.length > 0) {
          if (project.creator !== userId) {
            if (companyMember[0].disableJournalEdit) {
              throw StdApplicationError_1.StdApplicationError.forbidden('keine Berechtigung');
            }
          }
          this.company = yield CommandReadModels_1.CommandReadModels.company.getById(companyMember[0].companyId);
        }
        if (this.journalData.projectId != null) {
          yield ProjectEntity_1.ProjectEntity.checkProjectStatusAndGeneralAccess(this.journalData.projectId, userId);
        }
        var userOrCompanyId = this.company != null ? this.company.id : userId;
        if (this.journalData.owner.id !== userOrCompanyId) {
          throw StdApplicationError_1.StdApplicationError.forbidden('keine Berechtigung');
        }
        return true;
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {
        if (this.journalData.weather != null && this.journalData.weather.length > 0) {
          this.journalData.weather.forEach(function (w, index) {
            if (w.temperatureLow != null && w.temperatureHigh != null && w.temperatureHigh < w.temperatureLow) {
              throw {
                key: 'weather',
                messageCode: 'journalWeatherHighLowerThanLow',
                message: `Bei dem Wettereintrag ${index + 1} ist die Höchsttemperatur kleiner als die Tiefsttemperatur (tief)`,
                messageData: {
                  index: index
                }
              };
            }
          });
        }
        if (this.journalData.employee != null && this.journalData.employee.length > 0) {
          this.journalData.employee.forEach(function (e, index) {
            if (JournalEntity_1.EmployeeJournal.isGroup(e)) {
              var g = e.employee;
              if (g.workerCount <= 0) {
                throw {
                  key: 'employee',
                  messageCode: 'journalEmployeeGroupCountToLow',
                  message: `Bei der Mitarbeitergruppe ${index} muss die Anzahl der Mitarbeiter mindestens 1 betragen`,
                  messageData: {
                    index: index
                  }
                };
              } else if (g.groupName == null || g.groupName.length === 0) {
                throw {
                  key: 'employee',
                  messageCode: 'journalEmployeeGroupName',
                  message: `Der Mitarbeiter-Gruppenname fehlt bei dem Eintrag ${index}`,
                  messageData: {
                    index: index
                  }
                };
              }
            } else {
              var m = e.employee;
              if (m.name == null || m.name.length === 0) {
                throw {
                  key: 'employee',
                  messageCode: 'journalEmployeeNameMissing',
                  message: `Der Mitarbeitername fehlt bei dem Eintrag ${index + 1}`,
                  messageData: {
                    index: index
                  }
                };
              }
            }
          });
        }
        if (this.journalData.attendance != null && this.journalData.attendance.length > 0) {
          this.journalData.attendance.forEach(function (a, index) {
            if (a.workerCount <= 0) {
              throw {
                key: 'attendance',
                messageCode: 'journalAttendanceEmployeeCountToLow',
                message: `Bei dem Nachunternehmer-Eintrag ${index} muss die Anzahl der Mitarbeiter mindestens 1 betragen`,
                messageData: {
                  index: index
                }
              };
            } else if (a.companyName == null || a.companyName.length === 0) {
              throw {
                key: 'attendance',
                messageCode: 'journalAttendanceCompanynameMissing',
                message: `Der Firmenname fehlt bei dem Nachunternehmer-Eintrag ${index + 1}`,
                messageData: {
                  index: index
                }
              };
            }
          });
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return [];
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "execute",
    value: function () {
      var _execute = (0, _asyncToGenerator2.default)(function* () {
        var onStore = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ClientStore_1.ClientStore.commandStore;
        return (0, _get2.default)((0, _getPrototypeOf2.default)(FinalizeJournal.prototype), "execute", this).call(this, onStore);
      });
      function execute() {
        return _execute.apply(this, arguments);
      }
      return execute;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.FinalizeJournal = FinalizeJournal;