import { PromisePool } from 'cqrs-shared/src/PromisePool';
import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { OpenableChip } from 'materialTheme/src/theme/components/chips/OpenableChip';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { PureComponent } from 'react';
import { ScrollView, UIManager, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { TicketStatus } from 'upmesh-core/src/client/query/entities/TicketEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { TicketCard } from '../../tickets/TicketCard';
import { TicketEntitySynced } from '../../tickets/TicketEntitySynced';
import { TicketNavigation } from '../../tickets/TicketNavigation';
import { CurrentProject } from '../CurrentProject';
export class FocusTicketItem extends PureComponent {
    constructor(props) {
        super(props);
        this.currentDate = new Date();
        this.dueDates = [
            {
                title: I18n.m.getMessage('dashboardFocusTicketsItemOverdue'),
                onPressChipData: {
                    index: 0,
                    from: new Date(0),
                    to: new Date(),
                },
            },
            {
                title: I18n.m.getMessage('dashboardFocusTicketsItemDueToday'),
                onPressChipData: {
                    index: 1,
                    from: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate()),
                    to: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate()).getTime() +
                        86399999,
                },
            },
            {
                title: I18n.m.getMessage('dashboardFocusTicketsItemDueTomorrow'),
                onPressChipData: {
                    index: 2,
                    from: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate() + 1, 0, 0, 0, 0),
                    to: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate() + 1, 23, 59, 59, 59),
                },
            },
            {
                title: I18n.m.getMessage('dashboardFocusTicketsItemDueInAWeek'),
                onPressChipData: {
                    index: 3,
                    from: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate()),
                    to: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate()).getTime() +
                        604800000,
                },
            },
            {
                title: I18n.m.getMessage('dashboardFocusTicketsItemDueInTwoWeeks'),
                onPressChipData: {
                    index: 4,
                    from: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate()),
                    to: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate()).getTime() +
                        1209600000,
                },
            },
            {
                title: I18n.m.getMessage('dashboardFocusTicketsItemDueInAMonth'),
                onPressChipData: {
                    index: 5,
                    from: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate()),
                    to: new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate()).getTime() +
                        2419200000,
                },
            },
        ];
        this.getAssignedTeamMemberList = async () => {
            const useGlobalTickets = this.props.projectId == null;
            const chips = [];
            chips.push({
                thumbnail: (<View style={{
                        borderStyle: 'dashed',
                        borderWidth: ThemeManager.style.borderWidth,
                        borderRadius: ThemeManager.style.getScreenRelativePixelSize(12),
                        width: ThemeManager.style.getScreenRelativePixelSize(24),
                        height: ThemeManager.style.getScreenRelativePixelSize(24),
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}>
          <Icon toolTip="" icon="account-group" iconSize={20} outerSize={ThemeManager.style.getScreenRelativePixelSize(24)} radius={0}/>
        </View>),
                title: I18n.m.getMessage('dashboardFocusItemAllAssignees'),
                onPressChipData: { index: 0, id: 'all' },
            });
            chips.push({
                thumbnail: (<View style={{
                        borderStyle: 'dashed',
                        borderWidth: ThemeManager.style.borderWidth,
                        borderRadius: ThemeManager.style.getScreenRelativePixelSize(12),
                        width: ThemeManager.style.getScreenRelativePixelSize(24),
                        height: ThemeManager.style.getScreenRelativePixelSize(24),
                        alignItems: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}>
          <Icon toolTip="" icon="help" iconSize={20} outerSize={ThemeManager.style.getScreenRelativePixelSize(24)} radius={0}/>
        </View>),
                title: I18n.m.getMessage('ticketsDetailsTitleNotAssignedToUser'),
                onPressChipData: { index: 1, id: '0' },
            });
            let team = [];
            let project = null;
            if (!useGlobalTickets && CurrentProject.instance.getCurrentProjectId() === this.props.projectId) {
                team = CurrentProject.instance.getCurrentProjectTeam();
                project = CurrentProject.instance.getCurrentProject();
            }
            else {
                const filter = this.props.projectId ? `id eq '${this.props.projectId}'` : 'deleted ne true and archived ne true';
                const projects = await UpmeshClient.instance.modals.project.get({
                    filter,
                });
                if (this.props.projectId && projects.length > 0)
                    project = projects[0];
                const projectIds = projects.map((p) => p.id);
                const userEntities = await UpmeshClient.instance.modals.projectUser.get({
                    filter: `status eq 'accepted' and (projectId in ${JSON.stringify(projectIds)})`,
                });
                const userids = [];
                projects.forEach((p) => {
                    if (!userids.includes(p.creator))
                        userids.push(p.creator);
                });
                userEntities.forEach((e) => {
                    if (!userids.includes(e.userId))
                        userids.push(e.userId);
                });
                try {
                    const allUser = await AuthClient.instance.modals.user.get({
                        filter: `userId in ${JSON.stringify(userids)}`,
                    });
                    allUser.forEach((tmp) => {
                        team.push({ user: tmp, role: '' });
                    });
                }
                catch (err) {
                    console.log('cant find user', err);
                }
            }
            team.sort((a, b) => {
                return `${a.user.firstname} ${a.user.lastname}`.localeCompare(`${b.user.firstname} ${b.user.lastname}`);
            });
            for (let i = 0; i < team.length; i += 1) {
                chips.push({
                    title: `${team[i].user.firstname} ${team[i].user.lastname}`,
                    subtitle: team[i].user.company,
                    onPressChipData: { index: i + 2, id: team[i].user.id },
                    thumbnail: <UserImage size={24} user={team[i].user}/>,
                });
            }
            this.setState({ assignedTeamMemberList: chips, project });
            return chips;
        };
        this.init = async () => {
            const assigneeStorage = await SimpleStorage.get('dashboardFocusTicketsAssignee');
            const assignees = (await this.getAssignedTeamMemberList()).filter((assignee) => assignee.onPressChipData.id === assigneeStorage);
            let selectedAssignee = { index: 0, id: 'all' };
            if (assignees.length !== 0) {
                selectedAssignee = assignees[0].onPressChipData;
            }
            const dueDateStorage = await SimpleStorage.get('dashboardFocusTicketsDueDate');
            let dueDateIndex = 0;
            if (dueDateStorage === '1') {
                dueDateIndex = 1;
            }
            else if (dueDateStorage === '2') {
                dueDateIndex = 2;
            }
            else if (dueDateStorage === '3') {
                dueDateIndex = 3;
            }
            else if (dueDateStorage === '4') {
                dueDateIndex = 4;
            }
            else if (dueDateStorage === '5') {
                dueDateIndex = 5;
            }
            else if (dueDateStorage === '6') {
                dueDateIndex = 6;
            }
            let sortBy = await SimpleStorage.get('dashboardFocusTicketsSortBy');
            if (sortBy == null) {
                sortBy = 'status';
            }
            const selectedDueDate = this.dueDates[dueDateIndex].onPressChipData;
            const tickets = await this.getSortedTickets(selectedAssignee, selectedDueDate, sortBy);
            this.setState({ selectedAssignee, selectedDueDate, sortBy, tickets });
        };
        this.getTicketData = async (t) => {
            const tu = new TicketEntitySynced(t);
            try {
                if (t.approverUserId != null && t.approverUserId.length > 1) {
                    const user = await AuthClient.instance.modals.user.getById(t.approverUserId);
                    tu.approverUser = user;
                    tu.approver = user.getFullName();
                }
                if (t.assignedToUserId != null && t.assignedToUserId.length > 1) {
                    const user = await AuthClient.instance.modals.user.getById(t.assignedToUserId);
                    tu.assigneeUser = user;
                    tu.assignee = user.getFullName();
                }
            }
            catch (e) {
                console.debug('cant get user');
            }
            return tu;
        };
        this.getSortedTickets = async (selectedAssignee, selectedDueDate, sortBy) => {
            const useGlobalTickets = this.props.projectId == null;
            let tickets = [];
            if (!useGlobalTickets && CurrentProject.instance.getCurrentProjectId() === this.props.projectId) {
                tickets = CurrentProject.instance.getCurrentTickets();
            }
            else {
                const filter = this.props.projectId ? `id eq '${this.props.projectId}'` : 'deleted ne true and archived ne true';
                const projects = await UpmeshClient.instance.modals.project.get({
                    filter,
                });
                const projectIds = [];
                projects.forEach((p) => projectIds.push(p.id));
                const t = await UpmeshClient.instance.modals.ticket.get({
                    filter: `(projectId in ${JSON.stringify(projectIds)})`,
                });
                tickets = await PromisePool.run({
                    collection: t,
                    task: this.getTicketData,
                    maxConcurrency: 2,
                });
            }
            tickets = tickets.filter((ticket) => ticket.archived !== true &&
                ticket.deleted !== true &&
                ticket.ticketStatus !== TicketStatus.checked &&
                ticket.completionOn != null &&
                ticket.completionOn >= selectedDueDate.from &&
                ticket.completionOn <= selectedDueDate.to);
            if (selectedAssignee.id !== 'all') {
                if (selectedAssignee.id === '0') {
                    tickets = tickets.filter((ticket) => ticket.assignedToUserId == null);
                }
                else {
                    tickets = tickets.filter((ticket) => ticket.assignedToUserId === selectedAssignee.id);
                }
            }
            if (sortBy === 'status') {
                tickets.sort((a, b) => a.ticketStatus - b.ticketStatus);
            }
            if (sortBy === 'assignee') {
                tickets.sort((a, b) => {
                    let sortValue;
                    this.sortFunctionAssignee(a, b)
                        .then((value) => {
                        sortValue = value;
                    })
                        .catch((err) => console.debug(err));
                    return sortValue;
                });
            }
            if (sortBy === 'title') {
                tickets.sort((a, b) => {
                    if (a > b) {
                        return -1;
                    }
                    if (b > a) {
                        return 1;
                    }
                    return 0;
                });
            }
            if (sortBy === 'date') {
                tickets.sort((a, b) => {
                    if (a.completionOn != null && b.completionOn != null) {
                        return new Date(a.completionOn).getTime() - new Date(b.completionOn).getTime();
                    }
                    if (a.completionOn != null) {
                        return 1;
                    }
                    if (b.completionOn != null) {
                        return -1;
                    }
                    return 0;
                });
            }
            if (sortBy === 'lastChanged') {
                tickets.sort((a, b) => a.lastModifiedAt.getTime() - b.lastModifiedAt.getTime());
            }
            TicketNavigation.setTickets(tickets);
            return tickets;
        };
        this.openDialog = () => {
            this.dialogIsOpen = true;
            Dialog.instance?.open({
                title: I18n.m.getMessage('dashboardFocusTicketsItem'),
                fullscreenResponsive: true,
                showCloseIcon: true,
                scrollable: true,
                isBlocking: false,
                contentPadding: true,
                onClose: () => {
                    this.dialogIsOpen = false;
                },
                content: (<View style={{
                        paddingBottom: 40,
                        position: 'relative',
                        height: ResizeEvent.current.windowWidth <= ThemeManager.style.breakpointM ? '100%' : undefined,
                    }}>
          {this.renderChips()}
          {this.renderTickets('dialog')}
        </View>),
            });
        };
        this.onOpenTicket = () => {
            if (this.dialogIsOpen) {
                Dialog.instance?.close();
            }
        };
        this.openSortByMenu = (e) => {
            const { sortBy } = this.state;
            UIManager.measureInWindow(e.nativeEvent.target, (x, y, _width, height) => {
                const client = {
                    x,
                    y,
                    height,
                    width: 256,
                };
                Menu.instance?.open({
                    client,
                    items: [
                        {
                            text: I18n.m.getMessage('dashboardItemsStatus'),
                            onPress: this.setSortBy('status'),
                            thumbnail: {
                                width: 24,
                                thumbnail: sortBy === 'status' ? <Icon toolTip="" icon="check"/> : <View />,
                            },
                        },
                        {
                            text: I18n.m.getMessage('dashboardItemsAssignee'),
                            onPress: this.setSortBy('assignee'),
                            thumbnail: {
                                width: 24,
                                thumbnail: sortBy === 'assignee' ? <Icon toolTip="" icon="check"/> : <View />,
                            },
                        },
                        {
                            text: I18n.m.getMessage('dashboardFocusTicketsItemTitle'),
                            onPress: this.setSortBy('title'),
                            thumbnail: {
                                width: 24,
                                thumbnail: sortBy === 'title' ? <Icon toolTip="" icon="check"/> : <View />,
                            },
                        },
                        {
                            text: I18n.m.getMessage('dashboardFocusTicketsItemDueDate'),
                            onPress: this.setSortBy('date'),
                            thumbnail: {
                                width: 24,
                                thumbnail: sortBy === 'date' ? <Icon toolTip="" icon="check"/> : <View />,
                            },
                        },
                        {
                            text: I18n.m.getMessage('dashboardFocusTicketsItemLastChanged'),
                            onPress: this.setSortBy('lastChanged'),
                            thumbnail: {
                                width: 24,
                                thumbnail: sortBy === 'lastChanged' ? <Icon toolTip="" icon="check"/> : <View />,
                            },
                        },
                    ],
                });
            });
        };
        this.setSelectedAssignee = async (selectedAssignee) => {
            const { selectedDueDate, sortBy } = this.state;
            const tickets = await this.getSortedTickets(selectedAssignee, selectedDueDate, sortBy);
            SimpleStorage.set('dashboardFocusTicketsAssignee', selectedAssignee.id);
            this.setState({ tickets, selectedAssignee }, () => {
                if (this.dialogIsOpen) {
                    this.openDialog();
                }
            });
        };
        this.setSelectedDueDate = async (selectedDueDate) => {
            const { selectedAssignee, sortBy } = this.state;
            const tickets = await this.getSortedTickets(selectedAssignee, selectedDueDate, sortBy);
            SimpleStorage.set('dashboardFocusTicketsDueDate', selectedDueDate.index.toString());
            this.setState({ tickets, selectedDueDate }, () => {
                if (this.dialogIsOpen) {
                    this.openDialog();
                }
            });
        };
        this.dialogIsOpen = false;
        this.setSortBy = (sortBy) => async () => {
            Menu.instance?.close();
            const { selectedAssignee, selectedDueDate } = this.state;
            const tickets = await this.getSortedTickets(selectedAssignee, selectedDueDate, sortBy);
            SimpleStorage.set('dashboardFocusTicketsSortBy', sortBy);
            this.setState({ tickets, sortBy });
        };
        this.getItemKey = (item, _index) => `ticket_card_${item.id}`;
        this.renderRow = ({ item }) => {
            const useGlobalTickets = this.props.projectId == null;
            return (<TicketCard onOpen={this.onOpenTicket} ticket={item} key={`tCard${item.id}`} showProjects={useGlobalTickets} bullseye={false} status/>);
        };
        this.state = {
            selectedAssignee: { index: 0, id: 'all' },
            selectedDueDate: this.dueDates[0].onPressChipData,
            tickets: [],
            sortBy: 'status',
            assignedTeamMemberList: [],
            limit: 5,
        };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
    }
    componentWillUnmount() {
        TicketNavigation.setTickets([]);
        Dialog.instance?.close();
    }
    render() {
        const { styles } = this.props;
        const { tickets, project } = this.state;
        return (<View style={[styles, { width: '100%', height: '100%', position: 'relative' }]}>
        <View style={{ padding: 16, paddingBottom: 4, width: '100%' }}>
          <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
            <MaterialText type={MaterialTextTypes.H5}>{`${I18n.m.getMessage('dashboardFocusTicketsItem')}${project && project.title && project.id !== CurrentProject.instance.getCurrentProjectId()
                ? ` (${project.title})`
                : ''}`}</MaterialText>
            <Icon icon="sort-ascending" toolTip={I18n.m.getMessage('dashboardFocusTicketsItemSortBy')} onPress={this.openSortByMenu}/>
          </View>
          {this.renderChips()}
        </View>
        <ScrollView style={{ flex: 1 }} nestedScrollEnabled onLayout={(l) => {
                if (l.nativeEvent.layout.height) {
                    const limit = Math.max(3, Math.floor(l.nativeEvent.layout.height / 58));
                    this.setState({ limit });
                }
            }}>
          {this.renderTickets('card')}
        </ScrollView>
        {tickets.length > this.state.limit ? (<View style={{
                    width: '100%',
                    borderTopColor: ThemeManager.style.borderColor,
                    borderTopWidth: ThemeManager.style.borderWidth,
                    position: 'absolute',
                    bottom: 0,
                    backgroundColor: '#FFFFFF',
                }}>
            <ContainedButton full title={I18n.m.getMessage('logBookShowMoreActivities')} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} onPress={this.openDialog}/>
          </View>) : null}
      </View>);
    }
    renderChips() {
        const { selectedAssignee, selectedDueDate, assignedTeamMemberList } = this.state;
        return (<View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <View>
          <MaterialText type={MaterialTextTypes.Caption}>
            {I18n.m.getMessage('dashboardFocusTicketsItemDueDate')}
          </MaterialText>
          <OpenableChip dialogTitle={I18n.m.getMessage('dashboardFocusTicketsItemDueDate')} chipsList={this.dueDates} selected={selectedDueDate.index} onPressChip={this.setSelectedDueDate}/>
        </View>
        <View>
          <MaterialText type={MaterialTextTypes.Caption}>
            {I18n.m.getMessage('dashboardFocusTicketsItemAssignedTo')}
          </MaterialText>
          <OpenableChip dialogTitle={I18n.m.getMessage('dashboardFocusTicketsItemAssignedTo')} chipsList={assignedTeamMemberList} selected={selectedAssignee.index} onPressChip={this.setSelectedAssignee} disableAutoSort/>
        </View>
      </View>);
    }
    renderTickets(place) {
        const { tickets } = this.state;
        if (tickets.length === 0) {
            return (<View style={{ justifyContent: 'center', paddingTop: ThemeManager.style.contentPaddingValue }}>
          <MaterialText color={ThemeManager.style.black42} type={MaterialTextTypes.Body2} centeredText centeredBox>
            {I18n.m.getMessage('dashboardFocusItemNoTickets')}
          </MaterialText>
        </View>);
        }
        const renderedItems = [];
        if (place === 'dialog') {
            if (tickets.length > 0) {
                for (let i = 0; i < tickets.length; i += 1) {
                    renderedItems.push(this.renderRow({ item: tickets[i] }));
                }
            }
            return renderedItems;
        }
        if (tickets.length > 0) {
            for (let i = 0; i < this.state.limit && i < tickets.length; i += 1) {
                renderedItems.push(this.renderRow({ item: tickets[i] }));
            }
        }
        return <View>{renderedItems}</View>;
    }
    async sortFunctionAssignee(a, b) {
        if (a.assignedToUserId == null && b.assignedToUserId == null) {
            return null;
        }
        if (a.assignedToUserId == null) {
            return 1;
        }
        if (b.assignedToUserId == null) {
            return -1;
        }
        const userA = await AuthClient.instance.modals.user.getById(a.id);
        const userB = await AuthClient.instance.modals.user.getById(b.id);
        if (userA.firstname > userB.firstname) {
            return -1;
        }
        if (userB.firstname > userA.firstname) {
            return 1;
        }
        if (userA.lastname > userB.lastname) {
            return -1;
        }
        if (userB.lastname > userA.lastname) {
            return 1;
        }
        return 0;
    }
}
