import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React from 'react';
import { Linking, View } from 'react-native';
import { LayoutFields } from 'upmesh-core/src/client/query/entities/simple/CustomField';
import { I18n } from '../../i18n/I18n';
const styles = {
    code: {
        width: '100%',
        backgroundColor: '#DEDEDE',
        padding: 8,
        marginVertical: 8,
    },
    section: {
        width: '100%',
        marginBottom: 8,
    },
};
export function CompanyReportTemplatesNotesDialog(props) {
    const downloadExamples = (_e) => {
        const link = 'https://upmesh.de/upmesh_template_examples.zip';
        Linking.openURL(link).catch((err) => console.debug(err));
    };
    const fieldsDaily = [
        'project.title',
        'project.client',
        'report.owner',
        'report.forDay',
        'report.projectCompanyName',
        'report.attendance[].companyName',
        'report.attendance[].workerCount',
        'report.attendance[].timeStart',
        'report.attendance[].timeEnd',
        'report.attendance[].specifics',
        'report.employee[].name',
        'report.employee[].workerCount',
        'report.employee[].task',
        'report.employee[].timeStart',
        'report.employee[].timeEnd',
        'report.employee[].totalTimeInMinutes',
        'report.specialOccurrences',
        'report.workDone',
        'report.weather[].time',
        'report.weather[].temperatureLow',
        'report.weather[].temperatureHigh',
        'report.weather[].weatherCondition',
        'report.weather[].specifics',
        'report.additionalFields[].title',
        'report.additionalFields[].content',
        'report.images[].fileName',
        'report.images[].userName',
        'report.images[].date',
        'report.images[].image',
        'company.name',
        'company.contactName',
        'company.street',
        'company.streetNr',
        'company.zip',
        'company.city',
        'company.country',
        'company.phone',
        'company.email',
    ];
    const fields = [
        'project.title',
        'project.client',
        'creator.fullname',
        'date.today',
        'tickets[].title',
        'tickets[].craft',
        'tickets[].type',
        'tickets[].statusText',
        'tickets[].creatorName',
        'tickets[].assignee',
        'tickets[].approver',
        'tickets[].completionOn',
        'tickets[].completionOnLocal',
        'tickets[].createdAtLocal',
        'tickets[].description',
        'tickets[].lastModifiedAtLocal',
        'tickets[].plan.title',
        'tickets[].plan.title.snippet',
        'tickets[].plan.title.large',
        'tickets[].watcher[]',
        'tickets[].archived',
        'tickets[].deleted',
        'tickets[].userComments[].date',
        'tickets[].userComments[].username',
        'tickets[].userComments[].comment',
        'tickets[].activites[].date',
        'tickets[].activites[].username',
        'tickets[].activites[].comment',
        'tickets[].files[].date',
        'tickets[].files[].username',
        'tickets[].files[].fileName',
        'tickets[].images[].date',
        'tickets[].images[].username',
        'tickets[].images[].fileName',
        'tickets[].images[].image',
    ];
    const renderFields = (array) => {
        const lines = [];
        array.forEach((k) => {
            lines.push(<MaterialText key={k} type={MaterialTextTypes.Body2}>{`${k}`}</MaterialText>);
        });
        const s = new Set();
        if (props.companySettings &&
            props.companySettings.ticketLayouts &&
            props.companySettings.ticketLayouts.length > 0) {
            props.companySettings.ticketLayouts.forEach((t) => {
                t.fields.forEach((f) => {
                    const k = `tickets[].fields.${LayoutFields.getIdentifier(f)}`;
                    s.add(k);
                });
            });
        }
        if (props.project && props.project.ticketLayouts && props.project.ticketLayouts.length > 0) {
            props.project.ticketLayouts.forEach((t) => {
                t.fields.forEach((f) => {
                    const k = `tickets[].fields.${LayoutFields.getIdentifier(f)}`;
                    s.add(k);
                });
            });
        }
        s.forEach((k) => {
            lines.push(<MaterialText key={k} type={MaterialTextTypes.Body2}>{`${k}`}</MaterialText>);
        });
        return lines;
    };
    return (<>
      <DialogTitle>{I18n.m.getMessage('reportTemplatesNotesDialogTitle')}</DialogTitle>
      <DialogContent>
        <View style={styles.section}>
          <MaterialText>{I18n.m.getMessage('reportTemplatesNotesDialogText')}</MaterialText>
        </View>
        <View style={styles.section}>
          <MaterialText type={MaterialTextTypes.Body1} strong>
            INS
          </MaterialText>
          <MaterialText type={MaterialTextTypes.Body1}>
            {I18n.m.getMessage('reportTemplatesNotesDialogTextIns')}
          </MaterialText>
          <View style={styles.code}>
            <MaterialText type={MaterialTextTypes.Body2}>{`{{INS project.title}}`}</MaterialText>
            <MaterialText type={MaterialTextTypes.Body2}>OR</MaterialText>
            <MaterialText type={MaterialTextTypes.Body2}>{`{{project.title}}`}</MaterialText>
          </View>
        </View>

        <View style={styles.section}>
          <MaterialText type={MaterialTextTypes.Body1} strong>
            IMAGE
          </MaterialText>
          <MaterialText type={MaterialTextTypes.Body1}>
            {I18n.m.getMessage('reportTemplatesNotesDialogTextImage')}
          </MaterialText>
          <View style={styles.code}>
            <MaterialText type={MaterialTextTypes.Body2}>{`{{IMAGE $ticket.images.image}}`}</MaterialText>
          </View>
        </View>

        <View style={styles.section}>
          <MaterialText type={MaterialTextTypes.Body1} strong>
            EXEC
          </MaterialText>
          <MaterialText type={MaterialTextTypes.Body1}>
            {I18n.m.getMessage('reportTemplatesNotesDialogTextExec')}
          </MaterialText>
          <View style={styles.code}>
            <MaterialText type={MaterialTextTypes.Body2}>{`{{EXEC`}</MaterialText>
            <MaterialText type={MaterialTextTypes.Body2}>MY_CONSTANT = 3;</MaterialText>
            <MaterialText type={MaterialTextTypes.Body2}>{`}}`}</MaterialText>
          </View>
        </View>

        <View style={styles.section}>
          <MaterialText type={MaterialTextTypes.Body1} strong>
            FOR & END-FOR
          </MaterialText>
          <MaterialText type={MaterialTextTypes.Body1}>
            {I18n.m.getMessage('reportTemplatesNotesDialogTextFor')}
          </MaterialText>
          <View style={styles.code}>
            <MaterialText type={MaterialTextTypes.Body2}>{`{{FOR person IN project.people}}`}</MaterialText>
            <MaterialText type={MaterialTextTypes.Body2}>{`{{INS $person.name}} (since {{INS $person.since}})`}</MaterialText>
            <MaterialText type={MaterialTextTypes.Body2}>{`{{END-FOR person}}`}</MaterialText>
          </View>
        </View>

        <View style={styles.section}>
          <MaterialText type={MaterialTextTypes.Body1} strong>
            IF & END-IF
          </MaterialText>
          <MaterialText type={MaterialTextTypes.Body1}>
            {I18n.m.getMessage('reportTemplatesNotesDialogTextIf')}
          </MaterialText>

          <View style={styles.code}>
            <MaterialText type={MaterialTextTypes.Body2}>{`{{IF person.name === 'upmesh'}}`}</MaterialText>
            <MaterialText type={MaterialTextTypes.Body2}>{`{{= person.fullName }}`}</MaterialText>
            <MaterialText type={MaterialTextTypes.Body2}>{`{{END-IF}}`}</MaterialText>
          </View>
        </View>

        <View style={styles.section}>
          <MaterialText type={MaterialTextTypes.Body1} strong>
            {I18n.m.getMessage('date')}
          </MaterialText>
          <MaterialText type={MaterialTextTypes.Body1}>{I18n.m.getMessage('reportTemplatesDateExamples')}</MaterialText>

          <MaterialText type={MaterialTextTypes.Body1}>
            {I18n.m.getMessage('reportTemplatesDateExamplesAll')}
          </MaterialText>
          <View style={styles.code}>
            <MaterialText type={MaterialTextTypes.Body2}>{`{{ new Date( DATE-FIELD ).toLocaleString('de-DE') }}`}</MaterialText>
          </View>
          <MaterialText type={MaterialTextTypes.Body1}>
            {I18n.m.getMessage('reportTemplatesDateExamplesTime')}
          </MaterialText>
          <View style={styles.code}>
            <MaterialText type={MaterialTextTypes.Body2}>{`{{ new Date( DATE-FIELD ).toLocaleTimeString('de-DE') }}`}</MaterialText>
          </View>
          <MaterialText type={MaterialTextTypes.Body1}>
            {I18n.m.getMessage('reportTemplatesDateExamplesDay')}
          </MaterialText>
          <View style={styles.code}>
            <MaterialText type={MaterialTextTypes.Body2}>{`{{ new Date( DATE-FIELD ).toLocaleDateString('de-DE') }}`}</MaterialText>
          </View>
        </View>

        <View style={styles.section}>
          <MaterialText type={MaterialTextTypes.Body1} strong>
            {I18n.m.getMessage('reportTemplatesNotesDialogTextVars')}
          </MaterialText>
          <View style={styles.code}>{renderFields(fields)}</View>
        </View>
        <View style={styles.section}>
          <MaterialText type={MaterialTextTypes.Body1} strong>
            {I18n.m.getMessage('reportTemplatesNotesDialogTextVarsDaily')}
          </MaterialText>
          <View style={styles.code}>{renderFields(fieldsDaily)}</View>
        </View>
      </DialogContent>
      <DialogActions>
        <ContainedButton key="no" title={I18n.m.getMessage('reportTemplatesDownloadExamples')} onPress={downloadExamples} textColor={ThemeManager.style.brandPrimary} backgroundColor="#FFFFFF"/>
        <ContainedButton key="yes" title={I18n.m.getMessage('close')} onPress={Dialog.instance?.close} textColor={ThemeManager.style.brandPrimary} backgroundColor="#FFFFFF"/>
      </DialogActions>
    </>);
}
export const openCompanyReportTemplatesNotesDialog = (props) => (e) => {
    const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
    Dialog.instance?.open({
        openPosition,
        fullscreenResponsive: true,
        scrollable: false,
        contentPadding: false,
        content: <CompanyReportTemplatesNotesDialog {...props}/>,
    });
};
