import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MenuRaw } from 'materialTheme/src/theme/components/MenuRaw';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { Toast } from 'materialTheme/src/theme/components/Toast';
import { Measurement } from 'materialTheme/src/theme/components/utils/Measurement';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { SaveReportSettings } from 'upmesh-core/src/client/commands/report/SaveReportSettings';
import { ReportSettingsEntity } from 'upmesh-core/src/client/query/entities/ReportSettingsEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CurrentProject } from '../project/CurrentProject';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { ReportDialog } from './ReportDialog';
import { openReportTemplateDialog } from './ReportTemplateDialog';
export class ReportIcon extends PureComponent {
    constructor(props, context) {
        super(props, context);
        this.errorResult = async (e) => {
            let reportSettingsError = '';
            if (e != null && e.messageCode != null) {
                if (e.messageCode === 'notForFree') {
                    reportSettingsError = I18n.m.getMessage('reportErrorNotForFree');
                }
                Routing.instance.alert.post({ text: I18n.m.getMessage('reportErrorNotForFree') });
            }
            this.setState({ reportSettingsError, isLoading: false });
        };
        this.openDialogButton = (e) => {
            this.openDialog(e).catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
        };
        this.createReportByTemplateFile = (t, asPdf) => {
            Dialog.instance?.close();
            Toast.instance?.open({ title: I18n.m.getMessage('reportWillBeCreated') });
            const { company } = CompanyUserInfo;
            if (company == null)
                return;
            const { selectedTickets } = this.props;
            const ticketIds = [];
            for (const t of selectedTickets) {
                ticketIds.push(t.id);
            }
            CurrentProject.instance
                .createTicketReportFromTemplate(t, ticketIds, true, company.id, asPdf)
                .then(() => {
                Toast.instance?.open({
                    title: I18n.m.getMessage('reportCreated'),
                    buttonTitle: I18n.m.getMessage('openFolder'),
                    onPressButton: () => {
                        Routing.instance.goTo(`/projects/${CurrentProject.instance.getCurrentProjectId()}/files/private`);
                        Toast.instance?.close();
                    },
                });
            })
                .catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
        };
        this.openDialog = async (e) => {
            const c = CompanyUserInfo.companySettings;
            const ticketTemplates = [];
            if (c && c.reportTemplates != null && c.reportTemplates.length > 0) {
                c.reportTemplates.forEach((a) => {
                    if (a.reportType === 'ticket')
                        ticketTemplates.push(a);
                });
            }
            if (ticketTemplates.length > 0) {
                let x = 0;
                let y = 0;
                try {
                    if (e.nativeEvent?.pageX != null && e.nativeEvent.pageY) {
                        x = e.nativeEvent.pageX;
                        y = e.nativeEvent.pageY;
                    }
                    else {
                        const s = await Measurement.measure(e.currentTarget);
                        x = s.pageX;
                        y = s.pageY;
                    }
                }
                catch (err) {
                    console.warn(err);
                }
                const client = {
                    height: 0,
                    width: 300,
                    x,
                    y,
                };
                const items = [
                    {
                        thumbnail: { thumbnail: <Icon icon="file-chart-outline" toolTip=""/>, width: 40 },
                        title: I18n.m.getMessage('ticketReportDefault'),
                        onPress: this.openDialogNow(),
                    },
                    {
                        thumbnail: { thumbnail: <Icon icon="file-export-outline" toolTip=""/>, width: 40 },
                        title: I18n.m.getMessage('ticketReportByTemplate'),
                        onPress: this.openDialogNow(ticketTemplates),
                    },
                ];
                MenuRaw.instance?.open({
                    client,
                    items,
                });
            }
            else {
                this.openDialogNow()().catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
            }
        };
        this.openDialogNow = (templates) => async (e) => {
            MenuRaw.instance?.close();
            if (templates != null && templates.length > 0) {
                openReportTemplateDialog({ templates, onSave: this.createReportByTemplateFile })(e);
            }
            else {
                this.openSettingsDialog().catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
            }
        };
        this.openSettingsDialog = async () => {
            const { selectedTickets, onPressFilter, filterActive } = this.props;
            let project;
            if (this.props.allTickets != null && this.props.allTickets) {
                project = null;
            }
            else {
                project = CurrentProject.instance.getCurrentProject();
            }
            const ticketIds = [];
            const { userId } = CurrentUser;
            for (const t of selectedTickets) {
                ticketIds.push(t.id);
            }
            let usedSettings = new ReportSettingsEntity({ userId });
            let editable = false;
            let settings;
            const saveReportSettings = new SaveReportSettings(usedSettings);
            try {
                editable = (await saveReportSettings.canI()) === true;
            }
            catch (e) {
                console.debug('default report settings');
            }
            if (this.props.allTickets != null && this.props.allTickets) {
                settings = await UpmeshClient.instance.modals.reportSettings.get({
                    filter: `projectId eq null and userId eq '${userId}'`,
                });
            }
            else {
                usedSettings.projectId = project.id;
                settings = await UpmeshClient.instance.modals.reportSettings.get({
                    filter: `projectId eq '${project.id}' and userId eq '${userId}'`,
                });
            }
            if (settings.length > 0) {
                usedSettings = settings[0];
            }
            else if (editable) {
                const save = new SaveReportSettings(usedSettings, usedSettings.id);
                await save.execute();
            }
            const titleChangedRegex = /^upmesh Statusbericht - \d{2}.\d{2}.\d{2}$/g;
            if (titleChangedRegex.test(usedSettings.frontPage.titleName) ||
                usedSettings.frontPage.titleName == null ||
                usedSettings.frontPage.titleName.length < 1) {
                usedSettings.frontPage.titleName = `${I18n.m.getMessage(`reportDefaultName`)} - ${moment(new Date()).format('DD.MM.YY')}`;
            }
            const cf = [];
            for (const t of selectedTickets) {
                if (t.fields != null && t.fields.length > 0) {
                    t.fields.forEach((f) => {
                        if (!cf.includes(f.name)) {
                            cf.push(f.name);
                        }
                    });
                }
            }
            this.reportDialogOpen = true;
            Dialog.instance?.open({
                closeOnTouchOutside: false,
                fullscreenResponsive: true,
                content: (<ReportDialog reportSettings={usedSettings} customFields={cf.length > 0 ? cf : undefined} ticketIds={ticketIds} errorResult={this.errorResult} editable={editable} onPressFilter={onPressFilter} filterActive={filterActive} allTickets={this.props.allTickets != null ? this.props.allTickets : false}/>),
                contentPadding: false,
                scrollable: false,
                onClose: () => {
                    this.reportDialogOpen = false;
                },
            }, true);
        };
        this.reportDialogOpen = false;
        this.state = {
            created: false,
            isLoading: false,
            reportSettingsError: '',
        };
    }
    componentDidMount() {
    }
    componentDidUpdate(prevProps) {
        if (this.reportDialogOpen &&
            JSON.stringify(prevProps.selectedTickets) !== JSON.stringify(this.props.selectedTickets)) {
            this.openDialogNow()().catch((err) => console.debug(err));
        }
    }
    render() {
        const { color, disabled } = this.props;
        const { isLoading } = this.state;
        if (isLoading) {
            return (<View style={{
                    width: ThemeManager.style.getScreenRelativePixelSize(36),
                    height: ThemeManager.style.getScreenRelativePixelSize(36),
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
          <Spinner small/>
        </View>);
        }
        return (<Icon key="reportButton" icon="file-chart-outline" disabled={this.state.isLoading || disabled === true} toolTip={I18n.m.getMessage('report')} color={color != null ? color : ThemeManager.style.defaultIconColor} onPress={this.openDialogButton}/>);
    }
}
