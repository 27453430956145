import { WaitFor } from 'cqrs-shared/src/WaitFor';
import ConnectionContext from 'materialTheme/src/connectionContext';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { CheckboxListItem } from 'materialTheme/src/theme/components/CheckboxListItem';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { ZoomTileHelper } from 'materialTheme/src/theme/components/zoom/ZoomTileHelper';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Keyboard, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ProjectEntity } from 'upmesh-core/src/client/query/entities/ProjectEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { OfflineDataDownloader } from '../../repo/file/OfflineDataDownloader';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
const bytes = require('bytes');
export class OfflineProjectSettingsPU extends PureComponent {
    constructor(props) {
        super(props);
        this.updateOfflineProjects = (offlineProject) => {
            if (offlineProject.settings.projectId === this.props.data.projectId) {
                this.setState({ offlineProject: { ...offlineProject } });
                LoadingEvents.instance.stopLoading();
            }
        };
        this.changeMaxZoom = (planId) => (e) => {
            const { planZoomLevel } = this.state;
            planZoomLevel[planId] = e.value.data;
            this.setState({ planZoomLevel: { ...planZoomLevel } });
        };
        this.changeSyncOnMobileConnection = (value) => {
            this.setState({ syncOnMobileConnection: value });
        };
        this.changeAllPlan = (value) => {
            const { plans } = this.state;
            const selectedPlanIds = [];
            if (value)
                plans.forEach((p) => selectedPlanIds.push(p.id));
            this.setState({ allPlans: value, selectedPlanIds: [...selectedPlanIds] });
        };
        this.changePlan = (id) => (value) => {
            const { selectedPlanIds, plans } = this.state;
            const index = selectedPlanIds.indexOf(id);
            if (index === -1 && value)
                selectedPlanIds.push(id);
            else if (index > -1 && !value)
                selectedPlanIds.splice(index, 1);
            const allPlans = selectedPlanIds.length === plans.length;
            this.setState({ allPlans, selectedPlanIds: [...selectedPlanIds] });
        };
        this.changeAllFiles = (value) => {
            this.setState({ allFiles: value });
        };
        this.saveSettings = async () => {
            LoadingEvents.instance.startLoading();
            const { data } = this.props;
            const { syncOnMobileConnection, allPlans, allFiles, selectedPlanIds, planZoomLevel } = this.state;
            const o = {
                syncOnMobileConnection,
                projectId: data.projectId,
                syncAllPlans: allPlans,
                syncFiles: allFiles,
                planIdsSync: selectedPlanIds,
                planZoomLevel,
            };
            await OfflineDataDownloader.changeProject(o);
            Dialog.instance?.close();
        };
        const o = OfflineDataDownloader.getProject(props.data.projectId);
        if (o != null) {
            this.state = {
                isLoading: false,
                init: false,
                project: null,
                plans: [],
                allPlans: o.settings.syncAllPlans,
                allFiles: o.settings.syncFiles,
                syncOnMobileConnection: o.settings.syncOnMobileConnection,
                selectedPlanIds: o.settings.planIdsSync,
                offlineProject: o,
                filesCount: 0,
                fileSize: 0,
                planZoomLevel: o.settings.planZoomLevel != null ? o.settings.planZoomLevel : {},
            };
        }
        else {
            this.state = {
                isLoading: false,
                init: false,
                project: null,
                plans: [],
                allPlans: false,
                allFiles: false,
                syncOnMobileConnection: false,
                selectedPlanIds: [],
                filesCount: 0,
                fileSize: 0,
                planZoomLevel: {},
            };
        }
    }
    componentDidMount() {
        OfflineDataDownloader.progress.attach(this.updateOfflineProjects);
        this.init().catch((err) => console.warn('cant init OfflinePrjectSettingsPU', err));
    }
    componentWillUnmount() {
        OfflineDataDownloader.progress.detach(this.updateOfflineProjects);
        if (Keyboard != null) {
            Keyboard.dismiss();
        }
    }
    renderPlans() {
        const { data } = this.props;
        const { plans, selectedPlanIds, offlineProject, tileInfos, planZoomLevel, allPlans } = this.state;
        const boxes = [];
        for (const plan of plans) {
            if (plan.activePlanId !== 'MAP') {
                let secondTextLine = '';
                const p = OfflineDataDownloader.isPlanVersionSynced(data.projectId, plan.activePlanId);
                if (offlineProject != null) {
                    if (p != null) {
                        secondTextLine = I18n.m.getMessage('projectDownloadDownloaded');
                    }
                    else if (offlineProject.settings.syncAllPlans ||
                        offlineProject.settings.planIdsSync.indexOf(plan.id) > -1) {
                        secondTextLine = I18n.m.getMessage('projectDownloadWaitForDownload');
                    }
                }
                const value = allPlans || selectedPlanIds.indexOf(plan.id) > -1;
                boxes.push(<CheckboxListItem key={`${plan.id}_${value}`} value={value} title={plan.title} listItemProps={{ secondTextLine, paddingLeft: 8 }} onChange={this.changePlan(plan.id)}/>);
                try {
                    if (value && tileInfos != null && tileInfos[plan.activePlanId] != null) {
                        const info = tileInfos[plan.activePlanId];
                        const list = [];
                        for (let i = 0; i <= info.maxLevel; i += 1) {
                            let size = 0;
                            if (info.levels != null) {
                                info.levels.forEach((p) => {
                                    if (p.level <= i)
                                        size += p.sizeInBytes;
                                });
                            }
                            if (size > 0) {
                                list.push({ title: `${i} (${bytes(size)})`, data: i });
                            }
                            else {
                                list.push({ title: `${i}`, data: i });
                            }
                        }
                        if (list.length > 0) {
                            const selectedIndex = planZoomLevel[plan.id] != null ? planZoomLevel[plan.id] : list.length - 1;
                            boxes.push(<View key={`zoomLevel_${plan.activePlanId}_${list.length}`} style={{ paddingLeft: 68 }}>
                  <FormInputPicker key={`zoomLevel_${plan.activePlanId}_${list.length}`} list={list} labelText={I18n.m.getMessage('offlineSettingsZoomLevel')} onChange={this.changeMaxZoom(plan.id)} selectedIndex={selectedIndex}/>
                </View>);
                        }
                    }
                }
                catch (e) {
                    console.debug('cant create plan boxes', e);
                }
            }
        }
        return boxes;
    }
    render() {
        const { init, allPlans, allFiles, syncOnMobileConnection, offlineProject, selectedPlanIds, planZoomLevel } = this.state;
        const saveEnabled = init &&
            (offlineProject == null ||
                offlineProject.settings.planZoomLevel !== planZoomLevel ||
                offlineProject.settings.syncAllPlans !== allPlans ||
                offlineProject.settings.planIdsSync !== selectedPlanIds ||
                offlineProject.settings.syncFiles !== allFiles ||
                offlineProject.settings.syncOnMobileConnection !== syncOnMobileConnection);
        return [
            <DialogTitle key="dialogTitle">{I18n.m.getMessage('projectDownload')}</DialogTitle>,
            <DialogContent key="dialogContent">{this.renderContent()}</DialogContent>,
            <DialogActions onBottom key="dialogActions">
        <ContainedButton title={I18n.m.getMessage('close')} onPress={Dialog.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>
        <ContainedButton title={I18n.m.getMessage('save')} disabled={!saveEnabled} onPress={this.saveSettings}/>
      </DialogActions>,
        ];
    }
    renderContent() {
        const { init, project, allPlans, allFiles, filesCount, fileSize, syncOnMobileConnection, offlineProject } = this.state;
        if (!init || project == null) {
            return <Spinner />;
        }
        let filesSecondTextLine = '';
        if (offlineProject != null && offlineProject.settings.syncFiles) {
            filesSecondTextLine = `${I18n.m.getMessage('projectDownloadDownloaded')} ${offlineProject.filesSynced.length}/${filesCount}`;
        }
        const mobileSecondTextLine = `${I18n.m.getMessage('projectDownloadCurrentConnection')}: ${I18n.m.getMessage(`network${this.context.type}`)}`;
        return [
            <MaterialText key="text">
        {I18n.m.getMessage('projectDownloadDialogText', { projectTitle: project != null ? project.title : '' })}
      </MaterialText>,
            <CheckboxListItem key={`mobileDate${syncOnMobileConnection}`} title={I18n.m.getMessage('projectDownloadUseMobileData')} value={syncOnMobileConnection} onChange={this.changeSyncOnMobileConnection} listItemProps={{ secondTextLine: mobileSecondTextLine }}/>,
            <CheckboxListItem key={`allFiles${allFiles}`} title={`${filesCount} ${I18n.m.getMessage('projectDownloadFiles')} (${bytes(fileSize)})`} value={allFiles} onChange={this.changeAllFiles} listItemProps={{ secondTextLine: filesSecondTextLine }}/>,
            <CheckboxListItem key={`allPlans_${allPlans}`} title={I18n.m.getMessage('projectDownloadAllPlans')} value={allPlans} onChange={this.changeAllPlan}/>,
            ...this.renderPlans(),
        ];
    }
    async init() {
        await WaitFor.instance.waitFor(() => UpmeshClient.instance.ready);
        const id = this.props.data['projectId'];
        if (id != null) {
            try {
                const project = await UpmeshClient.instance.modals.project.getById(id);
                const plans = await UpmeshClient.instance.modals.plan.get({
                    filter: `projectId eq '${project.id}' and deleted ne true`,
                });
                const files = await UpmeshClient.instance.modals.storedFile.get({
                    filter: `projectId eq '${project.id}' and deleted ne true`,
                });
                let fileSize = 0;
                files.forEach((f) => {
                    fileSize += f.fileSizeInBytes;
                });
                const tileInfos = {};
                for (const plan of plans) {
                    try {
                        const tileUrl = `${UpmeshClient.instance.url}/plan/tiles/${plan.projectId}/planVersion/${plan.activePlanId}`;
                        const tileInfo = await ZoomTileHelper.getTileInfo(tileUrl);
                        tileInfos[plan.activePlanId] = tileInfo;
                    }
                    catch (err) {
                        console.debug('cant get tile info', err);
                    }
                }
                this.setState({ project, plans, tileInfos, filesCount: files.length, fileSize, init: true });
            }
            catch (err) {
                console.debug('cant get project', err);
            }
        }
    }
}
OfflineProjectSettingsPU.defaultProps = {
    data: null,
};
OfflineProjectSettingsPU.contextType = ConnectionContext;
OfflineProjectSettingsPU.open = (projectId) => (e) => {
    let openPosition;
    if (e != null && e.nativeEvent != null && e.nativeEvent.pageX != null && e.nativeEvent.pageY != null) {
        openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
    }
    ProjectEntity.checkProjectStatusAndGeneralAccess(projectId, CurrentUser.userId)
        .then(() => {
        Dialog.instance?.open({
            content: <OfflineProjectSettingsPU data={{ projectId }}/>,
            fullscreenResponsive: true,
            openPosition,
        });
    })
        .catch((err) => {
        DefaultErrorHandler.showDefaultErrorAlert(err);
    });
};
