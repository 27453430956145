import { ChipDialogForm } from 'materialTheme/src/theme/components/chips/ChipDialogForm';
import { DialogUp } from 'materialTheme/src/theme/components/DialogUp';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Switch } from 'materialTheme/src/theme/components/Switch';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialTextWithOutToolTip';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { UserOrCompany } from 'upmesh-core/src/server/webserver/UserOrCompany';
import { I18n } from '../../i18n/I18n';
export class FolderAccessComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.closeDialog = () => {
            DialogUp.instance?.close();
        };
        this.openDialog = () => {
            const { add } = this.state;
            DialogUp.instance?.open({
                content: (<ChipDialogForm key="ChipDialogForm" title={I18n.m.getMessage('folderRoleOrUserChangeTitle')} items={add} onCancel={this.closeDialog} multiselect onSave={this.onAddedNewItem} canAddNewChips={false}/>),
                contentPadding: false,
                fullscreenResponsive: true,
                showCloseButton: false,
                showCloseIcon: true,
            });
        };
        this.onAddedNewItem = (e) => {
            this.closeDialog();
            const current = [];
            e.forEach((b) => {
                const a = b;
                if (a.selected) {
                    current.push({
                        type: a.type,
                        userOrRoleId: a.id,
                        delete: a.delete,
                        edit: a.edit,
                        write: a.write,
                        userOrRoleName: a.title,
                    });
                }
            });
            this.setState({ current }, () => {
                const { onChange } = this.props;
                if (onChange != null) {
                    onChange(current);
                }
            });
        };
        this.onChangeRight = (right, i) => (value) => {
            const { current } = this.state;
            current[i][right] = value;
            this.setState({ current: [...current] }, () => {
                const { onChange } = this.props;
                if (onChange != null) {
                    onChange(current);
                }
            });
        };
        this.removeLine = (i) => (_e) => {
            const { current } = this.state;
            current.splice(i, 1);
            this.setState({ current: [...current] }, () => {
                const { onChange } = this.props;
                if (onChange != null) {
                    onChange(current);
                }
            });
        };
        this.state = { current: [], add: [], defaults: [] };
    }
    blur() { }
    clear() { }
    focus() { }
    getId() {
        return '';
    }
    setValue(value) {
        this.init(value == null ? [] : value).catch((err) => console.debug(err));
    }
    getValue() {
        const v = [];
        const { current } = this.state;
        current.forEach((e) => {
            v.push({ write: e.write, edit: e.edit, delete: e.delete, type: e.type, userOrRoleId: e.userOrRoleId });
        });
        return v;
    }
    componentDidMount() {
        this.init(this.props.folder.access).catch((err) => console.debug(err));
    }
    async init(access) {
        const { projectId, subFolderFromId } = this.props.folder;
        let mainFolder = null;
        if (subFolderFromId != null && subFolderFromId.length > 0) {
            mainFolder = await UpmeshClient.instance.modals.folder.getById(subFolderFromId);
        }
        const defaults = [];
        const current = [];
        const project = await UpmeshClient.instance.modals.project.getById(projectId);
        const owner = await UserOrCompany.getById(project.creator);
        defaults.push({
            write: true,
            edit: true,
            delete: true,
            userOrRoleName: `${owner.getFullName()} (${I18n.m.getMessage('projectOwner')})`,
            type: 'user',
            userOrRoleId: project.creator,
        });
        defaults.push({
            write: true,
            edit: true,
            delete: true,
            userOrRoleName: I18n.m.getMessage('projectManager'),
            type: 'role',
            userOrRoleId: '1',
        });
        const defaultRoles = await UpmeshClient.instance.modals.projectRoles.get({
            filter: `projectId eq '${projectId}' and deleted ne true`,
        });
        defaultRoles.forEach((e) => {
            if (e.writeRights.commandEditFolders) {
                defaults.push({
                    write: true,
                    edit: true,
                    delete: true,
                    userOrRoleName: e.roleName,
                    type: 'role',
                    userOrRoleId: e.id,
                });
            }
        });
        for (let i = 0; i < access.length; i += 1) {
            const a = access[i];
            try {
                let userOrRoleName = '?';
                if (a.type === 'user') {
                    const user = await AuthClient.instance.modals.user.getById(a.userOrRoleId);
                    userOrRoleName = user.getFullName();
                }
                else {
                    const role = await UpmeshClient.instance.modals.projectRoles.getById(a.userOrRoleId);
                    userOrRoleName = I18n.m.getMessage(role.roleName);
                }
                current.push({ ...a, userOrRoleName });
            }
            catch (e) {
                console.debug('cant add', a, e);
            }
        }
        const add = [];
        const roles = await UpmeshClient.instance.modals.projectRoles.get({
            filter: `(projectId eq '${projectId}' or projectId eq '0') and deleted ne true`,
        });
        roles.forEach((role) => {
            if (role.id !== '1') {
                const sel = current.find((e) => e.userOrRoleId === role.id);
                if (mainFolder == null || mainFolder.access.find((f) => f.userOrRoleId === role.id)) {
                    add.push({
                        title: I18n.m.getMessage(role.roleName),
                        id: role.id,
                        type: 'role',
                        selected: sel != null,
                        write: sel != null && sel.write,
                        edit: sel != null && sel.edit,
                        delete: sel != null && sel.delete,
                    });
                }
            }
        });
        const users = await UpmeshClient.instance.modals.projectUser.get({
            filter: `projectId eq '${projectId}' and status eq 'accepted' and roleId ne '1'`,
        });
        for (let i = 0; i < users.length; i += 1) {
            const pu = users[i];
            const user = await AuthClient.instance.modals.user.getById(pu.userId);
            const sel = current.find((e) => e.userOrRoleId === user.id);
            if (mainFolder == null || mainFolder.access.find((f) => f.userOrRoleId === user.id)) {
                add.push({
                    title: user.getFullName(),
                    id: user.id,
                    type: 'user',
                    selected: sel != null,
                    write: sel != null && sel.write,
                    edit: sel != null && sel.edit,
                    delete: sel != null && sel.delete,
                });
            }
        }
        this.setState({ current, defaults, add });
    }
    renderPlusButton() {
        const { add } = this.state;
        if (add.length === 0)
            return null;
        return (<View style={{
                margin: ThemeManager.style.getScreenRelativePixelSize(4),
                height: ThemeManager.style.getScreenRelativePixelSize(32),
                width: ThemeManager.style.getScreenRelativePixelSize(32),
                borderRadius: ThemeManager.style.getScreenRelativePixelSize(16),
                borderWidth: 1,
                borderStyle: 'dashed',
                borderColor: ThemeManager.style.black42,
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
            }}>
        <Icon icon="plus" toolTip={I18n.m.getMessage('add')} onPress={this.openDialog} outerSize={ThemeManager.style.getScreenRelativePixelSize(32)} color={ThemeManager.style.brandPrimary}/>
      </View>);
    }
    renderLine(access, i, editable) {
        const backgroundColor = i % 2 === 0 ? ThemeManager.style.formInputFilledBackgroundColor : '#FFFFFF';
        return (<View style={{
                width: '100%',
                maxWidth: '100%',
                minHeight: 36,
                flexDirection: 'row',
                backgroundColor,
            }} key={`accessLine${i}${access.userOrRoleId}`}>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <MaterialText centeredBox fixedWidth="100%">
            {access.userOrRoleName}
          </MaterialText>
        </View>
        <View style={{ width: '5%', minWidth: 56, justifyContent: 'center' }}>
          <Switch disabled={!editable} value={access.write} onPress={this.onChangeRight('write', i)}/>
        </View>
        <View style={{ width: '5%', minWidth: 56, justifyContent: 'center' }}>
          <Switch disabled={!editable} value={access.edit} onPress={this.onChangeRight('edit', i)}/>
        </View>
        <View style={{ width: '5%', minWidth: 56, justifyContent: 'center' }}>
          <Switch disabled={!editable} value={access.delete} onPress={this.onChangeRight('delete', i)}/>
        </View>
        <View style={{ width: '5%', minWidth: 56, justifyContent: 'center' }}>
          {!editable ? null : <Icon icon="close" toolTip={I18n.m.getMessage('remove')} onPress={this.removeLine(i)}/>}
        </View>
      </View>);
    }
    render() {
        const { current, defaults } = this.state;
        const { labelText } = this.props;
        return (<View style={{
                paddingBottom: 8,
                width: '100%',
            }}>
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <MaterialText type={MaterialTextTypes.Caption}>{labelText}</MaterialText>
        </View>
        <View style={{
                width: '100%',
                maxWidth: '100%',
                flexDirection: 'row',
                borderStyle: 'solid',
                borderWidth: 0,
                borderBottomWidth: 2,
                borderColor: ThemeManager.style.borderColor,
            }}>
          <View style={{ flex: 1, justifyContent: 'center' }}>
            <MaterialText strong>{I18n.m.getMessage('folderRoleOrUser')}</MaterialText>
          </View>
          <View style={{ width: '5%', minWidth: 56 }}>
            <Icon icon="cloud-upload-outline" toolTip={I18n.m.getMessage('uploadFiles')} onPress={() => { }}/>
          </View>
          <View style={{ width: '5%', minWidth: 56 }}>
            <Icon icon="pencil-outline" toolTip={I18n.m.getMessage('edit')} onPress={() => { }}/>
          </View>
          <View style={{ width: '5%', minWidth: 56 }}>
            <Icon icon="delete-outline" toolTip={I18n.m.getMessage('deleteFiles')} onPress={() => { }}/>
          </View>
          <View style={{ width: '5%', minWidth: 56 }}>
            <MaterialText />
          </View>
        </View>
        {defaults.map((c, i) => this.renderLine(c, i, false))}
        {current.map((c, i) => this.renderLine(c, i, true))}
        {this.renderPlusButton()}
        
      </View>);
    }
}
