import React from 'react';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { View } from 'react-native';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { useDimensions } from '@react-native-community/hooks';
import { I18n } from '../../i18n/I18n';
const { breakpointM } = ThemeManager.style;
const styles = {
    container: {
        borderWidth: 1,
        borderRadius: ThemeManager.style.borderRadius,
        borderColor: 'rgba(0,115,255, 0.54)',
        minHeight: 56,
        margin: ThemeManager.style.getScreenRelativePixelSize(16),
        marginBottom: 0,
        paddingRight: ThemeManager.style.getScreenRelativePixelSize(16),
        paddingLeft: ThemeManager.style.getScreenRelativePixelSize(12),
        backgroundColor: 'rgba(0,115,255, 0.12)',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    smallScreenContainer: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: ThemeManager.style.getScreenRelativePixelSize(8),
    },
    containerItem: {
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
    },
    button: {
        paddingTop: ThemeManager.style.getScreenRelativePixelSize(8),
    },
};
const planReadyForReleaseNotice = ({ onPress, }) => {
    const screen = useDimensions().window;
    const smallScreenStyle = screen.width < breakpointM ? styles.smallScreenContainer : {};
    const buttonStyle = screen.width < breakpointM ? styles.button : {};
    return (<View style={[styles.container, smallScreenStyle]}>
      <View style={[styles.containerItem]}>
        <MaterialText centeredBox centeredText color={ThemeManager.style.black87} type={MaterialTextTypes.Subtitle2} adjustsFontSizeToFit allowFontScaling strong>
          {I18n.m.getMessage('plansUploadedWaitingForRelease')}
        </MaterialText>
      </View>
      <View style={[styles.containerItem, buttonStyle]}>
        <ContainedButton title={I18n.m.getMessage('release')} onPress={onPress}/>
      </View>
    </View>);
};
export default planReadyForReleaseNotice;
