var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SocialRegister = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _queryString = _interopRequireDefault(require("query-string"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _AuthClient = require("upmesh-auth-core/build/src/client/AuthClient");
var _LoginWithGoogle = require("upmesh-auth-core/build/src/client/commands/guest/LoginWithGoogle");
var _LoginWithMicrosoft = require("upmesh-auth-core/build/src/client/commands/guest/LoginWithMicrosoft");
var _SignUpUserWithGoogle = require("upmesh-auth-core/build/src/client/commands/guest/SignUpUserWithGoogle");
var _SignUpUserWithMicrosoft = require("upmesh-auth-core/build/src/client/commands/guest/SignUpUserWithMicrosoft");
var _Alert = require("../../../theme/components/Alert");
var _ContainedButton = require("../../../theme/components/button/ContainedButton");
var _Htextline = require("../../../theme/components/Htextline");
var _MaterialText = require("../../../theme/components/text/MaterialText");
var _I18n = require("../../../theme/i18n/I18n");
var _LoadingEvents = require("../../../theme/routing/LoadingEvents");
var _Routing = require("../../../theme/routing/Routing");
var _ThemeManager = require("../../../theme/ThemeManager");
var _GoogleButton = require("./GoogleButton");
var _MicrosoftButton = require("./MicrosoftButton");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var SocialRegister = exports.SocialRegister = function (_PureComponent) {
  function SocialRegister(props) {
    var _this;
    (0, _classCallCheck2.default)(this, SocialRegister);
    _this = _callSuper(this, SocialRegister, [props]);
    _this.useRedirect = false;
    _this.errorGoogle = function (response) {
      _LoadingEvents.LoadingEvents.instance.stopLoading();
      if (response && response.error === 'idpiframe_initialization_failed') {
        _this.useRedirect = true;
      } else {
        var errorMessage = response && response.details ? response.details : '';
        _this.setState({
          errorMessage: errorMessage,
          error: true
        }, function () {
          _LoadingEvents.LoadingEvents.instance.stopLoading();
        });
      }
    };
    _this.signUpWithMicrosoftToken = function () {
      var _ref = (0, _asyncToGenerator2.default)(function* (microsoftAccessToken, microsoftIdToken) {
        try {
          var connect = new _SignUpUserWithMicrosoft.SignUpUserWithMicrosoft({
            microsoftAccessToken: microsoftAccessToken,
            microsoftIdToken: microsoftIdToken,
            locale: 'de'
          }, '', '');
          yield connect.execute(_AuthClient.AuthClient.instance.commandStore);
          setTimeout(function () {
            _this.loginWithMicrosoftToken(microsoftAccessToken, microsoftIdToken, 0).catch(function (err) {
              return void 0;
            });
          }, 3000);
        } catch (e) {
          _LoadingEvents.LoadingEvents.instance.stopLoading();
          if (e.messageCode === 'SignUpErrorUserAlreadyExisting') {
            setTimeout(function () {
              _LoadingEvents.LoadingEvents.instance.stopLoading();
              _Routing.Routing.instance.alert.post({
                text: _I18n.I18n.m.getMessage('SignUpErrorMicrosoftUserAlreadyExisting'),
                buttons: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
                  backgroundColor: "transparent",
                  textColor: _ThemeManager.ThemeManager.style.brandPrimary,
                  onPress: _this.closeAlert,
                  title: _I18n.I18n.m.getMessage('cancel')
                }, "ok"), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
                  onPress: _this.loginWithMicrosoftButton(microsoftAccessToken, microsoftIdToken),
                  title: _I18n.I18n.m.getMessage('login')
                }, "login")]
              });
            }, 0);
            return;
          }
          var text = e.messageCode ? _I18n.I18n.m.getMessage(e.messageCode) : e.message ? e.message : e.toString();
          _Routing.Routing.instance.alert.post({
            text: text
          });
        }
        _LoadingEvents.LoadingEvents.instance.stopLoading();
      });
      return function (_x, _x2) {
        return _ref.apply(this, arguments);
      };
    }();
    _this.loginWithMicrosoftButton = function (microsoftAccessToken, microsoftIdToken) {
      return function () {
        var _Alert$instance;
        _LoadingEvents.LoadingEvents.instance.startLoading();
        (_Alert$instance = _Alert.Alert.instance) == null ? void 0 : _Alert$instance.close(function () {
          _this.loginWithMicrosoftToken(microsoftAccessToken, microsoftIdToken, 0).catch(function (err) {
            return void 0;
          });
        });
      };
    };
    _this.loginWithMicrosoftToken = function () {
      var _ref2 = (0, _asyncToGenerator2.default)(function* (microsoftAccessToken, microsoftIdToken, tries) {
        try {
          var connect = new _LoginWithMicrosoft.LoginWithMicrosoft({
            microsoftAccessToken: microsoftAccessToken,
            microsoftIdToken: microsoftIdToken
          });
          yield connect.execute(_AuthClient.AuthClient.instance.commandStore);
          yield _AuthClient.AuthClient.instance.logIn(connect.result);
        } catch (e) {
          _LoadingEvents.LoadingEvents.instance.stopLoading();
          if (tries <= 3) {
            setTimeout(function () {
              _this.loginWithMicrosoftToken(microsoftAccessToken, microsoftIdToken, tries + 1).catch(function (err) {
                return void 0;
              });
            }, 3000);
          } else {
            _Routing.Routing.instance.goTo('/login');
            var text = e.messageCode ? _I18n.I18n.m.getMessage(e.messageCode) : e.message ? e.message : e.toString();
            _Routing.Routing.instance.alert.post({
              text: text
            });
          }
        }
      });
      return function (_x3, _x4, _x5) {
        return _ref2.apply(this, arguments);
      };
    }();
    _this.googleSignIn = function (e) {
      if (e) {
        e.preventDefault();
      }
      if (window.gapi) {
        var auth2 = window.gapi.auth2.getAuthInstance();
        var options = {
          ux_mode: _this.useRedirect ? 'redirect' : 'popup',
          fetch_basic_profile: true
        };
        auth2.signIn(options).then(_this.signUpWithGoogle, _this.errorGoogle);
      }
    };
    _this.hashLoginOnConnection = function () {
      _AuthClient.AuthClient.instance.serverConnection.onConnect.detach(_this.hashLoginOnConnection);
      _this.hashLogin();
    };
    _this.loginNowWithGoogle = function () {
      var _ref3 = (0, _asyncToGenerator2.default)(function* (googleAccessToken, googleIdToken, tries) {
        try {
          var loginWithGoogle = new _LoginWithGoogle.LoginWithGoogle({
            googleAccessToken: googleAccessToken,
            googleIdToken: googleIdToken
          });
          yield loginWithGoogle.execute(_AuthClient.AuthClient.instance.commandStore);
          yield _AuthClient.AuthClient.instance.logIn(loginWithGoogle.result);
        } catch (e) {
          _LoadingEvents.LoadingEvents.instance.stopLoading();
          if (tries <= 3) {
            setTimeout(function () {
              _this.loginNowWithGoogle(googleAccessToken, googleIdToken, tries + 1).catch(function (err) {
                return void 0;
              });
            }, 3000);
          } else {
            _Routing.Routing.instance.goTo('/login');
            _Routing.Routing.instance.alert.post({
              text: _I18n.I18n.m.getMessage(e.messageCode)
            });
          }
        }
      });
      return function (_x6, _x7, _x8) {
        return _ref3.apply(this, arguments);
      };
    }();
    _this.signUpWithGoogle = function () {
      var _ref4 = (0, _asyncToGenerator2.default)(function* (user) {
        var userData = user.getAuthResponse();
        if (userData != null) {
          _LoadingEvents.LoadingEvents.instance.startLoading();
          var idToken = userData['id_token'];
          var accessToken = userData['access_token'];
          _this.signUpWithGoogleToken(accessToken, idToken).catch(function (err) {
            return void 0;
          });
        } else {}
      });
      return function (_x9) {
        return _ref4.apply(this, arguments);
      };
    }();
    _this.closeAlert = function () {
      var _Alert$instance2;
      (_Alert$instance2 = _Alert.Alert.instance) == null ? void 0 : _Alert$instance2.close();
    };
    _this.loginWithGoogle = function (googleAccessToken, googleIdToken) {
      return function () {
        var _Alert$instance3;
        _LoadingEvents.LoadingEvents.instance.startLoading();
        (_Alert$instance3 = _Alert.Alert.instance) == null ? void 0 : _Alert$instance3.close(function () {
          _this.loginNowWithGoogle(googleAccessToken, googleIdToken, 0).catch(function (err) {
            return void 0;
          });
        });
      };
    };
    _this.signUpWithGoogleToken = function () {
      var _ref5 = (0, _asyncToGenerator2.default)(function* (googleAccessToken, googleIdToken) {
        try {
          var signUpWithGoogle = new _SignUpUserWithGoogle.SignUpUserWithGoogle({
            googleAccessToken: googleAccessToken,
            googleIdToken: googleIdToken,
            locale: 'de'
          }, '', '');
          yield signUpWithGoogle.execute(_AuthClient.AuthClient.instance.commandStore);
          setTimeout(function () {
            _this.loginNowWithGoogle(googleAccessToken, googleIdToken, 0).catch(function (err) {
              return void 0;
            });
          }, 3000);
        } catch (e) {
          _LoadingEvents.LoadingEvents.instance.stopLoading();
          if (e.messageCode === 'SignUpErrorUserAlreadyExisting') {
            setTimeout(function () {
              _LoadingEvents.LoadingEvents.instance.stopLoading();
              _Routing.Routing.instance.alert.post({
                text: _I18n.I18n.m.getMessage('SignUpErrorGoogleUserAlreadyExisting'),
                buttons: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
                  backgroundColor: "transparent",
                  textColor: _ThemeManager.ThemeManager.style.brandPrimary,
                  onPress: _this.closeAlert,
                  title: _I18n.I18n.m.getMessage('cancel')
                }, "ok"), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
                  onPress: _this.loginWithGoogle(googleAccessToken, googleIdToken),
                  title: _I18n.I18n.m.getMessage('login')
                }, "login")]
              });
            }, 0);
            return;
          }
          _Routing.Routing.instance.alert.post({
            text: _I18n.I18n.m.getMessage(e.messageCode)
          });
        }
      });
      return function (_x10, _x11) {
        return _ref5.apply(this, arguments);
      };
    }();
    _this.state = {
      tokenId: '',
      user: null,
      error: false,
      isLoading: false,
      errorMessage: ''
    };
    return _this;
  }
  (0, _inherits2.default)(SocialRegister, _PureComponent);
  return (0, _createClass2.default)(SocialRegister, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this2 = this;
      if (!this.hashLogin()) {
        (function (d, s, id, cb) {
          var element = d.getElementsByTagName(s)[0];
          var fjs = element;
          if (fjs != null) {
            var js = element;
            js = d.createElement(s);
            js.id = id;
            js.src = '//apis.google.com/js/client:platform.js';
            if (fjs.parentNode != null) {
              fjs.parentNode.insertBefore(js, fjs);
            }
            js.onload = cb;
          }
        })(document, 'script', 'google-login', function () {
          _this2.initGoogle();
        });
      }
    }
  }, {
    key: "initGoogle",
    value: function initGoogle() {
      var _this3 = this;
      window.gapi.load('auth2', function () {
        var params = {
          client_id: _AuthClient.AuthClient.webClientId,
          cookie_policy: 'single_host_origin',
          login_hint: '',
          hosted_domain: '',
          fetch_basic_profile: true,
          ux_mode: _this3.useRedirect ? 'redirect' : 'popup',
          redirect_uri: null,
          scope: 'profile email'
        };
        window.gapi.auth2.init(params).then(function (_res) {}, _this3.errorGoogle);
      });
    }
  }, {
    key: "render",
    value: function render() {
      var _this4 = this;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          alignItems: 'center',
          alignSelf: 'stretch',
          padding: 0
        },
        children: [this.state.error ? (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            alignSelf: 'stretch'
          },
          children: [(0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            type: _MaterialText.MaterialTextTypes.Body1,
            children: _I18n.I18n.m.getMessage('loginErrorNoGoogle')
          }), this.state.errorMessage != null && this.state.errorMessage.length > 0 ? (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            type: _MaterialText.MaterialTextTypes.Body1,
            children: this.state.errorMessage
          }) : null]
        }) : null, (0, _jsxRuntime.jsx)(_GoogleButton.GoogleButton, {
          title: _I18n.I18n.m.getMessage('registerWithGoogle'),
          disabled: this.state.error || this.props.disabled,
          onPress: this.googleSignIn
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            height: 8
          }
        }), (0, _jsxRuntime.jsx)(_MicrosoftButton.MicrosoftButton, {
          disabled: this.state.error,
          title: _I18n.I18n.m.getMessage('registerWithMicrosoft'),
          onAccess: function onAccess(data) {
            return _this4.signUpWithMicrosoftToken(data.accessToken, data.idToken);
          }
        }), (0, _jsxRuntime.jsx)(_Htextline.Htextline, {
          sitePadding: 0,
          children: _I18n.I18n.m.getMessage('or')
        })]
      });
    }
  }, {
    key: "hashLogin",
    value: function hashLogin() {
      if (window.location.hash != null && window.location.hash.length > 0) {
        try {
          var qs = _queryString.default.parse(window.location.hash);
          if (qs != null && qs.id_token != null && typeof qs.id_token === 'string' && qs.id_token.length > 0) {
            if (_AuthClient.AuthClient.instance.serverConnection.isConnected) {
              _LoadingEvents.LoadingEvents.instance.startLoading();
              _Routing.Routing.instance.goTo('/register', true);
              this.signUpWithGoogleToken(qs.access_token == null || typeof qs.access_token !== 'string' ? '' : qs.access_token, qs.id_token).catch(function (err) {
                return void 0;
              });
            } else {
              _AuthClient.AuthClient.instance.serverConnection.onConnect.attach(this.hashLoginOnConnection);
            }
            return true;
          }
        } catch (e) {}
      }
      return false;
    }
  }]);
}(_react.PureComponent);