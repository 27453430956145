"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UnlockTimeTracking = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var TimeTrackingUnlocked_1 = require("../../../events/company/timetracking/TimeTrackingUnlocked");
var TimeTrackingEntity_1 = require("../../../query/entities/TimeTrackingEntity");
var UnlockTimeTracking = function (_CommandRoot_1$Comman) {
  function UnlockTimeTracking(data, entityId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, UnlockTimeTracking);
    _this = _callSuper(this, UnlockTimeTracking, [data, entityId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'UnlockTimeTracking';
    return _this;
  }
  (0, _inherits2.default)(UnlockTimeTracking, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(UnlockTimeTracking, [{
    key: "getTimeTrackingEntry",
    value: function () {
      var _getTimeTrackingEntry = (0, _asyncToGenerator2.default)(function* () {
        if (this._timeTrackingEntry == null) this._timeTrackingEntry = yield CommandReadModels_1.CommandReadModels.timeTracking.getById(this.entityId);
        return this._timeTrackingEntry;
      });
      function getTimeTrackingEntry() {
        return _getTimeTrackingEntry.apply(this, arguments);
      }
      return getTimeTrackingEntry;
    }()
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var timeTrackingEntry = yield this.getTimeTrackingEntry();
        if (userId === 'all') return true;
        var company = yield CommandReadModels_1.CommandReadModels.company.getById(timeTrackingEntry.companyId);
        var companyMember = yield CommandReadModels_1.CommandReadModels.companyMember.get({
          filter: `companyId eq '${company.id}' and userId eq '${userId}' and deleted ne true`,
          top: 1
        });
        if (companyMember.length === 1 && companyMember[0].role !== 'inactive' && companyMember[0].payroll) return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var timeTrackingEntry = yield this.getTimeTrackingEntry();
        if (timeTrackingEntry.status === 'Locked' || timeTrackingEntry.status === 'Exported') {
          var events = [];
          events.push(new TimeTrackingUnlocked_1.TimeTrackingUnlocked(this.entityId, {}, timeTrackingEntry.companyId));
          return events;
        }
        return null;
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        yield UnlockTimeTracking.validateTimeTrackingEntity(new TimeTrackingEntity_1.TimeTrackingEntity(Object.assign({
          id: this.entityId
        }, this.data)), key);
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }], [{
    key: "validateTimeTrackingEntity",
    value: function () {
      var _validateTimeTrackingEntity = (0, _asyncToGenerator2.default)(function* (_data, _key) {
        var errors = [];
        if (errors.length > 0) throw errors;
      });
      function validateTimeTrackingEntity(_x2, _x3) {
        return _validateTimeTrackingEntity.apply(this, arguments);
      }
      return validateTimeTrackingEntity;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.UnlockTimeTracking = UnlockTimeTracking;