"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConfigHandler = void 0;
var Config_1 = require("cqrs-core/build/src/Config");
var ConfigHandler = function (_Config_1$ConfigHandl) {
  function ConfigHandler() {
    (0, _classCallCheck2.default)(this, ConfigHandler);
    return _callSuper(this, ConfigHandler, arguments);
  }
  (0, _inherits2.default)(ConfigHandler, _Config_1$ConfigHandl);
  return (0, _createClass2.default)(ConfigHandler, null, [{
    key: "setConfig",
    value: function setConfig(config) {
      ConfigHandler._instance3 = config;
      Config_1.ConfigHandler.setConfig(config);
    }
  }, {
    key: "config",
    get: function get() {
      if (ConfigHandler._instance3 == null) {
        ConfigHandler._instance3 = ConfigHandler.createDefaultConfig();
        Config_1.ConfigHandler.setConfig(ConfigHandler._instance3);
      }
      return ConfigHandler._instance3;
    }
  }, {
    key: "createDefaultConfig",
    value: function createDefaultConfig() {
      var defaultConfig = {
        db: 'nedb',
        JWT_TOKEN_SECRET: 'cdKFAUfbwqeui239038ubnvcDSKbfg239ß1238',
        JWT_EXP_DAYS: 60,
        BCRYPT_SALT_ROUNDS: 12,
        cacheSize: '200MB',
        REDIS_PORT: 30634,
        REDIS_HOST: '127.0.0.1',
        REDIS_PASS: 'GoizrRSqRfdSQNirh9Oo',
        inMemoryDB: true,
        dbName: 'authcore',
        webServerUrl: 'http://127.0.0.1:3011/auth/api',
        startSwagger: false,
        eventServerUrl: 'http://127.0.0.1:3015/auth/api',
        startsWithTestData: false,
        cors: true,
        onlyWebsocketTransport: true,
        GOOGLE_CLIENT_ID: '576732586755-pl5uu1469orb2cnubsc2ic317ip5mtir.apps.googleusercontent.com',
        GOOGLE_SECRET: 'AIzaSyA5bXYSNpv6U2Lnm1GnCBFQboX8OCTdy9w',
        fileHandler: 'node',
        fileHandlerPath: '../files',
        version: '0.0.0',
        environment: 'development',
        serverName: 'auth',
        S3_ACCESS_KEY_ID: '',
        S3_SECRET_ACCESS_KEY: '',
        S3_ENDPOINT: '',
        maxUploadSizeInBytes: 104857600,
        cronClean: '10 1 * * *',
        tilesDir: './tiles',
        cronCacheClean: '',
        leaderElection: true
      };
      var env = ConfigHandler.getEnvirementConfig(defaultConfig);
      return ConfigHandler.getEnvirementConfig(defaultConfig);
    }
  }]);
}(Config_1.ConfigHandler);
exports.ConfigHandler = ConfigHandler;