var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DialogUpState = exports.DialogUp = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _scrollbarWidth = require("@xobotyi/scrollbar-width");
var _react = _interopRequireWildcard(require("../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-client/node_modules/react-native-web");
var _I18n = require("../i18n/I18n");
var _ResizeEvent = require("../ResizeEvent");
var _Routing = require("../routing/Routing");
var _ThemeManager = require("../ThemeManager");
var _DialogAnimation = require("./animations/DialogAnimation");
var _SlideAnimation = require("./animations/SlideAnimation");
var _ContainedButton = require("./button/ContainedButton");
var _DialogActions = require("./dialog/DialogActions");
var _Header = require("./header/Header");
var _Icon = require("./Icon");
var _Overlay = require("./Overlay");
var _ScrollContainer = require("./scroll/ScrollContainer");
var _ScrollEvents = require("./scroll/ScrollEvents");
var _MaterialText = require("./text/MaterialText");
var _Toast = require("./Toast");
var _KeyboardEvents = require("./utils/KeyboardEvents");
var _jsxRuntime = require("../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var DialogUpState;
(function (DialogUpState) {
  DialogUpState["opened"] = "opened";
  DialogUpState["opening"] = "opening";
  DialogUpState["closed"] = "closed";
  DialogUpState["closing"] = "closing";
})(DialogUpState || (exports.DialogUpState = DialogUpState = {}));
var DialogUp = exports.DialogUp = function (_PureComponent) {
  function DialogUp(props) {
    var _this;
    (0, _classCallCheck2.default)(this, DialogUp);
    _this = _callSuper(this, DialogUp, [props]);
    _this.scrollEvent = new _ScrollEvents.ScrollEvents();
    _this.scrollPosition = 0;
    _this.animationRunning = false;
    _this.zIndex = 24;
    _this._onLayoutView = function (_event) {
      _this.forceUpdate();
    };
    _this.close = function (_callBack) {
      if (!_this.animationRunning) {
        if (_this.animationRunningTO != null) {
          clearTimeout(_this.animationRunningTO);
        }
        _this.closeNow(_callBack);
      } else {
        _this.animationRunningTO = setTimeout(function () {
          _this.animationRunning = false;
          _this.close(_callBack);
        }, 200);
      }
    };
    _this.closeNow = function (_callBack) {
      _this.stopKeyboardEvents();
      if (_Toast.Toast.isOpened) {
        _Toast.Toast.restartTimeout();
      }
      var onClosed = _this.props.onClosed;
      var onClose = _this.state.onClose;
      var callback = function callback() {
        if (onClosed != null && typeof onClosed === 'function') {
          onClosed();
        }
        if (onClose != null && typeof onClose === 'function') {
          onClose();
        }
        if (_callBack != null && typeof _callBack === 'function') {
          _callBack();
        }
      };
      _this.setdialogState(0, callback);
    };
    _this.closeHandler = function () {
      if (!_this.state.isBlocking) {
        var _DialogUp$instance;
        (_DialogUp$instance = DialogUp.instance) == null ? void 0 : _DialogUp$instance.close();
      }
      return true;
    };
    _this.onOverlayPress = function () {
      var closeOnTouchOutside = _this.state.closeOnTouchOutside;
      if (closeOnTouchOutside === true) {
        if (_this.state.closeFunction != null) {
          _this.state.closeFunction();
        } else {
          _this.close();
        }
      }
    };
    _this.stopKeyboardEvents = function () {
      _KeyboardEvents.KeyboardEvents.remove('DialogUp');
      if (_this.backHandler) _this.backHandler.remove();
    };
    _this.handleScroll = function (e) {
      if (e && e.nativeEvent && e.nativeEvent.contentOffset && e.nativeEvent.contentOffset.y) {
        _this.scrollPosition = e.nativeEvent.contentOffset.y;
        _this.scrollEvent.scrollPostion = e.nativeEvent.contentOffset.y;
      }
    };
    _this.state = DialogUp.defaultState;
    _this.dialogAnimation = new _DialogAnimation.DialogAnimation(0, undefined, _this.props.animationDuration, 24);
    return _this;
  }
  (0, _inherits2.default)(DialogUp, _PureComponent);
  return (0, _createClass2.default)(DialogUp, [{
    key: "dialogSize",
    get: function get() {
      var _this$state = this.state,
        maxWidth = _this$state.maxWidth,
        maxHeight = _this$state.maxHeight,
        width = _this$state.width,
        height = _this$state.height;
      var _this$state2 = this.state,
        fullscreen = _this$state2.fullscreen,
        fullscreenResponsive = _this$state2.fullscreenResponsive,
        windowWidth = _this$state2.windowWidth;
      if (fullscreen === true || fullscreenResponsive === true && _ResizeEvent.ResizeEvent.current.windowWidth <= _ThemeManager.ThemeManager.style.breakpointM) {
        return {
          dialogStyle: {
            width: _ResizeEvent.ResizeEvent.current.contentWidth + _ResizeEvent.ResizeEvent.current.sidebarWidth,
            height: _ResizeEvent.ResizeEvent.current.contentHeight + _ResizeEvent.ResizeEvent.current.contentBottom,
            paddingBottom: 0,
            top: _ResizeEvent.ResizeEvent.current.contentTop,
            left: _ResizeEvent.ResizeEvent.current.contentLeft
          },
          containerStyle: {
            width: _ResizeEvent.ResizeEvent.current.windowWidth,
            height: _ResizeEvent.ResizeEvent.current.windowHeight,
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
          }
        };
      }
      if (width != null && width > 0.0 && width <= 1.0) {
        width *= _ResizeEvent.ResizeEvent.current.contentWidth + _ResizeEvent.ResizeEvent.current.sidebarWidth - _ResizeEvent.ResizeEvent.current.contentLeft;
      }
      if (height > 0.0 && height <= 1.0) {
        height *= _ResizeEvent.ResizeEvent.current.contentHeight + _ResizeEvent.ResizeEvent.current.contentBottom - _ResizeEvent.ResizeEvent.current.currentKeyboardHeight - _ResizeEvent.ResizeEvent.current.contentTop;
      }
      if (maxWidth != null && maxWidth > 0.0 && maxWidth <= 1.0) {
        maxWidth *= _ResizeEvent.ResizeEvent.current.contentWidth + _ResizeEvent.ResizeEvent.current.sidebarWidth - _ResizeEvent.ResizeEvent.current.contentLeft;
      }
      if (maxHeight != null && maxHeight > 0.0 && maxHeight <= 1.0) {
        maxHeight *= _ResizeEvent.ResizeEvent.current.contentHeight + _ResizeEvent.ResizeEvent.current.contentBottom - _ResizeEvent.ResizeEvent.current.currentKeyboardHeight - _ResizeEvent.ResizeEvent.current.contentTop;
      }
      if (Number.isNaN(maxWidth) || maxWidth > windowWidth) {
        maxWidth = windowWidth;
      }
      if (width > maxWidth) {
        width = maxWidth;
      }
      if (height > maxHeight) {
        height = maxHeight;
      }
      var size = {
        dialogStyle: {
          maxWidth: maxWidth,
          maxHeight: maxHeight,
          width: width === 0 ? 'auto' : width,
          height: height === 0 ? 'auto' : height
        },
        containerStyle: {
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: _ResizeEvent.ResizeEvent.current.contentTop,
          paddingLeft: _ResizeEvent.ResizeEvent.current.contentLeft,
          paddingRight: _ResizeEvent.ResizeEvent.current.contentRight,
          paddingBottom: _ResizeEvent.ResizeEvent.current.currentKeyboardHeight + _ResizeEvent.ResizeEvent.current.contentBottom
        }
      };
      return size;
    }
  }, {
    key: "dialogState",
    get: function get() {
      return this.state.dialogState;
    }
  }, {
    key: "pointerEvents",
    get: function get() {
      if (this.props.overlayPointerEvents != null) {
        return this.props.overlayPointerEvents;
      }
      return this.state.dialogState === DialogUpState.opened ? 'auto' : 'none';
    }
  }, {
    key: "componentDidMount",
    value: function componentDidMount() {
      DialogUp._instance = this;
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (_reactNativeWeb.Keyboard != null) _reactNativeWeb.Keyboard.dismiss();
    }
  }, {
    key: "open",
    value: function open(state) {
      var _this2 = this;
      var openImmediately = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      this.startKeyboardEvents();
      if (!this.animationRunning) {
        if (this.animationRunningTO != null) {
          clearTimeout(this.animationRunningTO);
        }
        var newState = Object.assign({}, DialogUp.defaultState, state);
        if (newState.openPosition == null) {
          newState.openPosition = {
            x: _ResizeEvent.ResizeEvent.current.windowWidth / 2,
            y: _ResizeEvent.ResizeEvent.current.windowHeight / 2,
            width: 1,
            height: 1
          };
        }
        if (newState.fullscreen === true) {
          this.dialogAnimation = new _SlideAnimation.SlideAnimation(this.dialogState === DialogUpState.opened ? 1 : 0, 'bottom', false, this.props.animationDuration);
        } else {
          this.dialogAnimation = new _DialogAnimation.DialogAnimation(this.dialogState === DialogUpState.opened ? 1 : 0, newState.openPosition, this.props.animationDuration, 24);
        }
        newState.openImmediately = openImmediately;
        this.setState(newState, function () {
          if (openImmediately) {
            _this2.openNow();
          }
        });
      } else {
        this.animationRunningTO = setTimeout(function () {
          _this2.animationRunning = false;
          _this2.open(state, openImmediately);
        }, 200);
      }
    }
  }, {
    key: "openNow",
    value: function openNow() {
      if (_Toast.Toast.isOpened) {
        _Toast.Toast.resetTimeout();
      }
      var onOpened = this.props.onOpened;
      this.setdialogState(1, onOpened);
    }
  }, {
    key: "getScrollBarWidth",
    value: function getScrollBarWidth() {
      var scrollable = this.state.scrollable;
      if (_reactNativeWeb.Platform.OS !== 'web' || !scrollable) return 0;
      try {
        var s = (0, _scrollbarWidth.scrollbarWidth)();
        return s != null ? s : 0;
      } catch (err) {
        return 0;
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _DialogUp$instance2;
      var _this$state3 = this.state,
        dialogState = _this$state3.dialogState,
        fullscreen = _this$state3.fullscreen,
        fullscreenButtonsBottom = _this$state3.fullscreenButtonsBottom,
        closeButtonDisabled = _this$state3.closeButtonDisabled,
        saveButtonDisabled = _this$state3.saveButtonDisabled,
        fullscreenResponsive = _this$state3.fullscreenResponsive,
        showCloseIcon = _this$state3.showCloseIcon;
      var isfullscreen = fullscreen === true || fullscreenResponsive === true && _ResizeEvent.ResizeEvent.current.windowWidth <= _ThemeManager.ThemeManager.style.breakpointM;
      if (dialogState === DialogUpState.closed && this.state.openImmediately !== true) {
        return null;
      }
      var overlayPointerEvents = this.pointerEvents;
      var dialogSize = this.dialogSize.dialogStyle;
      var hidden = dialogState === DialogUpState.closed ? _ThemeManager.ThemeManager.style.dialogHidden : {};
      var isShowOverlay = dialogState === DialogUpState.opened || dialogState === DialogUpState.opening;
      var dimensions = {
        width: this.state.windowWidth,
        height: this.state.windowHeight
      };
      var maxContentHeight = '100%';
      var header = [];
      var footer = [];
      if (isfullscreen === true) {
        if (fullscreenButtonsBottom === true) {
          var leftButtons = [];
          if (this.state.fullscreenLeftButtons != null) {
            leftButtons.push.apply(leftButtons, (0, _toConsumableArray2.default)(this.state.fullscreenLeftButtons));
          }
          var rightButtons = [];
          if (this.state.showCloseButton && this.state.closeFunction != null) {
            rightButtons.push((0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
              backgroundColor: "transparent",
              textColor: _ThemeManager.ThemeManager.style.brandPrimary,
              disabled: closeButtonDisabled,
              title: this.state.closeTitle,
              onPress: this.state.closeFunction
            }, "closeButton"));
          }
          if (this.state.saveFunction != null) {
            rightButtons.push((0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
              backgroundColor: _ThemeManager.ThemeManager.style.brandPrimary,
              textColor: "#FFFFFF",
              disabled: saveButtonDisabled,
              title: this.state.saveTitle,
              onPress: this.state.saveFunction
            }, "saveButton"));
          }
          if (this.state.fullscreenRightButtons != null) {
            rightButtons.push.apply(rightButtons, (0, _toConsumableArray2.default)(this.state.fullscreenRightButtons));
          }
          footer.push((0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
            style: {
              minHeight: 48,
              width: dialogSize.width,
              maxWidth: dialogSize.maxWidth,
              flexDirection: 'row',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              paddingRight: 8,
              paddingLeft: 8,
              position: 'absolute',
              bottom: _ResizeEvent.ResizeEvent.current.contentBottom
            },
            children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              style: {
                minHeight: 48,
                flexDirection: 'row',
                justifyContent: 'flex-start',
                flexWrap: 'wrap'
              },
              children: leftButtons
            }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
              style: {
                minHeight: 48,
                flexDirection: 'row',
                justifyContent: 'flex-end',
                flexWrap: 'wrap'
              },
              children: rightButtons
            })]
          }, "footer"));
        } else {
          var _leftButtons = this.state.closeFunction == null || this.state.showCloseButton === false ? [] : [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            backgroundColor: "transparent",
            textColor: this.state.fullscreenHeaderButtonColor,
            icon: {
              icon: 'close'
            },
            onPress: this.state.closeFunction
          }, "closeButton")];
          if (this.state.fullscreenLeftButtons != null) {
            _leftButtons.push.apply(_leftButtons, (0, _toConsumableArray2.default)(this.state.fullscreenLeftButtons));
          }
          var _rightButtons = this.state.saveFunction == null ? [] : [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            backgroundColor: "transparent",
            textColor: this.state.fullscreenHeaderButtonColor,
            icon: {
              icon: 'check'
            },
            onPress: this.state.saveFunction
          }, "saveButton")];
          if (this.state.fullscreenRightButtons != null) {
            _rightButtons.push.apply(_rightButtons, (0, _toConsumableArray2.default)(this.state.fullscreenRightButtons));
          }
          var headerVisible = _rightButtons.length > 0 || _leftButtons.length > 0 || !!this.state.title;
          maxContentHeight = _ResizeEvent.ResizeEvent.current.contentHeight + _ResizeEvent.ResizeEvent.current.contentBottom - (headerVisible ? _ThemeManager.ThemeManager.style.headerHeight : 0) - _ResizeEvent.ResizeEvent.current.currentKeyboardHeight;
          if (headerVisible) {
            header.push((0, _jsxRuntime.jsx)(_Header.Header, {
              textColor: this.state.fullscreenHeaderTextColor,
              titleCentered: this.state.fullscreenHeaderTitleCentered,
              leftButtons: _leftButtons,
              title: this.state.title != null ? this.state.title : '',
              rightButtons: _rightButtons,
              backgroundColor: this.state.fullscreenHeaderColor
            }, "fullscreenHeader"));
          }
        }
      }
      var closeIcon = showCloseIcon ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          position: 'absolute',
          right: Math.max(8, this.getScrollBarWidth()),
          top: 8,
          width: 36,
          height: 36
        },
        children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
          onPress: (_DialogUp$instance2 = DialogUp.instance) == null ? void 0 : _DialogUp$instance2.close,
          toolTip: _I18n.I18n.m.getMessage('close'),
          icon: "close"
        })
      }) : null;
      var animationStyle = this.dialogAnimation != null && this.dialogAnimation.animations != null ? this.dialogAnimation.animations : undefined;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: [_ThemeManager.ThemeManager.style.dialogContainer, hidden, dimensions, this.dialogSize.containerStyle, {
          zIndex: this.zIndex
        }],
        children: [(0, _jsxRuntime.jsx)(_Overlay.Overlay, {
          width: this.state.windowWidth,
          height: this.state.windowHeight,
          pointerEvents: overlayPointerEvents,
          showOverlay: isShowOverlay,
          onPress: this.state.closeOnTouchOutside === true ? this.onOverlayPress : undefined,
          backgroundColor: isfullscreen ? 'rgba(255,255,255,1)' : this.props.overlayBackgroundColor,
          opacity: isfullscreen ? 1 : this.props.overlayOpacity,
          animationDuration: this.props.animationDuration
        }), (0, _jsxRuntime.jsxs)(_reactNativeWeb.Animated.View, {
          onLayout: this._onLayoutView,
          style: [_ThemeManager.ThemeManager.style.dialogBackground, _ThemeManager.ThemeManager.style.elevation24, dialogSize, animationStyle],
          children: [header, header.length === 0 && this.state.fixedTitle === true && this.state.title != null && this.state.title.length > 0 ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              padding: _ThemeManager.ThemeManager.style.contentPaddingValue
            },
            children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              type: _MaterialText.MaterialTextTypes.H6,
              children: this.state.title
            })
          }) : null, this.renderContent(maxContentHeight, this.state.buttons != null && this.state.buttons.length > 0 && !fullscreenButtonsBottom ? (0, _jsxRuntime.jsx)(_DialogActions.DialogActions, {
            onBottom: false,
            marginRight: 8,
            children: this.state.buttons
          }) : null), footer, this.state.buttons != null && this.state.buttons.length > 0 && fullscreenButtonsBottom ? (0, _jsxRuntime.jsx)(_DialogActions.DialogActions, {
            onBottom: isfullscreen && fullscreenButtonsBottom,
            children: this.state.buttons
          }) : null, closeIcon]
        })]
      });
    }
  }, {
    key: "renderContent",
    value: function renderContent() {
      var maxHeight = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '100%';
      var buttons = arguments.length > 1 ? arguments[1] : undefined;
      var keyboardShouldPersistTaps = _reactNativeWeb.Platform.OS === 'android' ? {
        keyboardShouldPersistTaps: 'always'
      } : undefined;
      var _this$state4 = this.state,
        scrollable = _this$state4.scrollable,
        fullscreenResponsive = _this$state4.fullscreenResponsive,
        title = _this$state4.title,
        fixedTitle = _this$state4.fixedTitle,
        content = _this$state4.content,
        contentPadding = _this$state4.contentPadding,
        fullscreen = _this$state4.fullscreen,
        scrollContainerStyle = _this$state4.scrollContainerStyle;
      var isfullscreen = fullscreen === true || fullscreenResponsive === true && _ResizeEvent.ResizeEvent.current.windowWidth <= _ThemeManager.ThemeManager.style.breakpointM;
      var style = {
        maxHeight: maxHeight,
        borderBottomWidth: 0,
        borderColor: _ThemeManager.ThemeManager.style.borderColor,
        position: 'relative'
      };
      var titleView = !isfullscreen && fixedTitle !== true && title != null && title.length > 0 ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          marginBottom: _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(20)
        },
        children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
          type: _MaterialText.MaterialTextTypes.H6,
          children: title
        })
      }) : null;
      var cProps = typeof content !== 'string' && content.props != null ? content.props : {};
      var contentView = content != null ? typeof content === 'string' ? (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
        type: _MaterialText.MaterialTextTypes.Body1,
        color: _ThemeManager.ThemeManager.style.defaultTextColor,
        children: content
      }) : _react.default.cloneElement(content, Object.assign({}, this.props, cProps, {
        dialogState: this.state
      })) : null;
      if (!scrollable) {
        if (isfullscreen && typeof style.maxHeight === 'number') {
          style.height = style.maxHeight - _ResizeEvent.ResizeEvent.current.contentBottom;
          style.minHeight = style.maxHeight - _ResizeEvent.ResizeEvent.current.contentBottom;
        }
        return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: style,
          children: [(0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
            style: Object.assign({
              padding: contentPadding === true ? _ThemeManager.ThemeManager.style.contentPaddingValue : 0
            }, style),
            children: [titleView, contentView]
          }), buttons]
        });
      }
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: Object.assign({}, style, {
          paddingBottom: 0
        }),
        children: [(0, _jsxRuntime.jsxs)(_ScrollContainer.ScrollContainer, Object.assign({
          style: Object.assign({}, style, {
            height: fixedTitle && style.height && typeof style.height === 'number' ? style.height - 60 : style.height
          }),
          contentContainerStyle: Object.assign({}, scrollContainerStyle, {
            padding: contentPadding === true ? _ThemeManager.ThemeManager.style.contentPaddingValue : 0,
            paddingBottom: buttons != null ? 32 : 0
          }),
          scrollEnabled: scrollable,
          scrollEventThrottle: 16,
          onScroll: this.handleScroll
        }, keyboardShouldPersistTaps, {
          children: [titleView, contentView]
        })), buttons]
      });
    }
  }, {
    key: "startKeyboardEvents",
    value: function startKeyboardEvents() {
      var _this3 = this;
      _KeyboardEvents.KeyboardEvents.onKeyDown(function (e) {
        switch (e.keyCode) {
          case 27:
            e.preventDefault();
            if (_this3.state.closeOnTouchOutside === true) {
              var _DialogUp$instance3;
              (_DialogUp$instance3 = DialogUp.instance) == null ? void 0 : _DialogUp$instance3.close();
            }
            break;
        }
      }, 'DialogUp');
      if (_reactNativeWeb.Platform.OS === 'android') {
        this.backHandler = _reactNativeWeb.BackHandler.addEventListener('hardwareBackPress', this.closeHandler);
      }
    }
  }, {
    key: "setdialogState",
    value: function setdialogState(toValue) {
      var _this4 = this;
      var callback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};
      if (!this.animationRunning) {
        var dialogState = toValue > 0 ? DialogUpState.opening : DialogUpState.closing;
        if (dialogState === 'closing' && (this.state.dialogState === 'closed' || this.state.dialogState === 'closing')) {
          if (callback != null) callback();
          return;
        }
        if (dialogState === 'opening' && this.state.dialogState === 'opened' || this.state.dialogState === 'opening') {
          if (callback != null) callback();
          return;
        }
        this.animationRunning = true;
        this.setState({
          dialogState: dialogState
        }, function () {
          _this4.dialogAnimation.toValue(toValue, function () {
            if (dialogState === DialogUpState.closing) {
              _this4.setState(DialogUp.defaultState, function () {
                _this4.animationRunning = false;
                _Routing.Routing.instance.routeBlock.post(false);
                setTimeout(callback, 100);
              });
            } else {
              _this4.setState({
                dialogState: DialogUpState.opened,
                openImmediately: true
              }, function () {
                _this4.animationRunning = false;
                _Routing.Routing.instance.routeBlock.post(_this4.state.isBlocking);
                callback();
              });
            }
          });
        });
      } else {
        if (this.animationRunningTO) clearTimeout(this.animationRunningTO);
        this.animationRunningTO = setTimeout(function () {
          _this4.animationRunning = false;
          _this4.setdialogState(toValue, callback);
        }, this.props.animationDuration);
      }
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        animationDuration: 195,
        windowWidth: 1,
        windowHeight: 1,
        closeFunction: function closeFunction() {},
        onOpened: function onOpened() {},
        onClosed: function onClosed() {},
        open: false
      };
    }
  }, {
    key: "defaultState",
    get: function get() {
      return {
        title: '',
        isBlocking: true,
        openPosition: undefined,
        closeFunction: function closeFunction() {
          var _DialogUp$instance4;
          return (_DialogUp$instance4 = DialogUp.instance) == null ? void 0 : _DialogUp$instance4.closeNow();
        },
        onClose: undefined,
        saveFunction: undefined,
        saveTitle: 'save',
        closeTitle: 'close',
        dialogState: DialogUpState.closed,
        width: 512,
        height: 0,
        maxWidth: 0.9,
        maxHeight: 0.9,
        content: '',
        buttons: undefined,
        fullscreen: false,
        fullscreenResponsive: false,
        closeOnTouchOutside: true,
        scrollable: false,
        fixedTitle: false,
        contentPadding: true,
        openImmediately: false,
        fullscreenLeftButtons: undefined,
        fullscreenRightButtons: undefined,
        fullscreenHeaderButtonColor: _ThemeManager.ThemeManager.style.headerButtonColor,
        fullscreenHeaderColor: _ThemeManager.ThemeManager.style.headerBG,
        fullscreenHeaderTextColor: _ThemeManager.ThemeManager.style.headerTitleColor,
        fullscreenHeaderTitleCentered: false,
        fullscreenButtonsBottom: false,
        saveButtonDisabled: false,
        closeButtonDisabled: false,
        windowWidth: _ResizeEvent.ResizeEvent.current.windowWidth,
        windowHeight: _ResizeEvent.ResizeEvent.current.windowHeight,
        showCloseButton: false,
        showCloseIcon: false
      };
    }
  }, {
    key: "instance",
    get: function get() {
      if (DialogUp._instance != null) {
        return DialogUp._instance;
      }
      return null;
    }
  }, {
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps) {
      return {
        windowHeight: nextProps.size.windowHeight,
        windowWidth: nextProps.size.windowWidth
      };
    }
  }]);
}(_react.PureComponent);
DialogUp.changeScroll = function (disable) {
  if (DialogUp.instance != null) {
    var _DialogUp$instance5;
    (_DialogUp$instance5 = DialogUp.instance) == null ? void 0 : _DialogUp$instance5.setState({
      scrollable: !disable
    });
  }
};