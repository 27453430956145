import { CommandOfflineModel } from 'cqrs-core/src/client/commands/CommandOfflineFirstStore';
import { Url } from 'cqrs-shared/src/uri/Url';
import { WaitFor } from 'cqrs-shared/src/WaitFor';
import fetch from 'cross-fetch';
import { TempFile } from 'materialTheme/src/file/upload/entities/TempFile';
import { Uploads } from 'materialTheme/src/file/upload/Uploads';
import { FileHandler } from 'materialTheme/src/FileHandler';
import { ClientDBadapter } from 'materialTheme/src/odataDB/loki/ClientDBadapter';
import { DeviceManager } from 'materialTheme/src/securestore/DeviceManager';
import { SecureLocalStoreB } from 'materialTheme/src/securestore/SecureLocalStoreB';
import { LokiDBOptions } from 'odatarepos/src/db/loki/LokiDataBase';
import { LokiODataDB } from 'odatarepos/src/db/loki/LokiODataDB';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import { Platform } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { LoginWithAccessToken } from 'upmesh-core/src/client/commands/LoginWithAccessToken';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { Config } from './config/Config';
import { ConfigAll } from './config/ConfigAll';
import { Init } from './config/Init';
import { SharedWorkerWeb } from './config/SharedWorkerWeb';
import { UpdateHint } from './UpdateHint';
export class ClientDB {
    constructor() {
        this.initialized = false;
        this.isInit = false;
        this.initDB = async (userId, deleteDB = false) => {
            if (Platform.OS === 'web' && Init.hasWorker) {
                return undefined;
            }
            await WaitFor.instance.waitFor(() => !this.isInit);
            this.isInit = true;
            if (this.clientDB != null && this.lastUserId != null && this.lastUserId === userId) {
                this.isInit = false;
                return this;
            }
            if (this.clientDB != null && this.lastUserId !== userId) {
                try {
                    await Uploads.instance.stopUploads();
                }
                catch (e) {
                    console.debug('cant stop uploads', e);
                }
                await this.closeDB(deleteDB);
            }
            if (userId != null && userId.length > 0) {
                let initDB = false;
                const lastReset = await SimpleStorage.get(`dbVersion_${userId}`);
                initDB = await this.initialize(userId == null ? '' : userId);
                let tempfiles = [];
                let offlineCommands = [];
                if (lastReset == null || parseInt(lastReset, 10) < 34) {
                    let online = false;
                    try {
                        const apiOnline = await fetch(`${ConfigAll.b2cURL}/health`);
                        const state = await apiOnline.text();
                        online = state === 'started';
                    }
                    catch (e) {
                        console.debug('cant fetch online state', e);
                    }
                    if (online) {
                        const offlineCommandRepo = this.clientDB?.getRepos(new CommandOfflineModel());
                        if (offlineCommandRepo && (await offlineCommandRepo.count()) > 0) {
                            const g = await offlineCommandRepo.get();
                            offlineCommands = [...g];
                        }
                        const repo = this.clientDB?.getRepos(new TempFile());
                        if (repo && (await repo.count()) > 0) {
                            const g = await repo.get();
                            tempfiles = [...g];
                        }
                        await ClientDBadapter.deleteDB(userId);
                        await SimpleStorage.set(`dbVersion_${userId}`, '34');
                        initDB = await this.initialize(userId);
                        if (offlineCommands.length > 0) {
                            const offlineCommandRepo = this.clientDB?.getRepos(new CommandOfflineModel());
                            for (const c of offlineCommands) {
                                try {
                                    if (offlineCommandRepo)
                                        await offlineCommandRepo.post(c);
                                }
                                catch (e) {
                                    console.debug('cant save offlineCommand', e);
                                }
                            }
                        }
                        if (tempfiles.length > 0) {
                            const repo = this.clientDB?.getRepos(new TempFile());
                            for (const t of tempfiles) {
                                try {
                                    if (repo)
                                        await repo.post(t);
                                }
                                catch (e) {
                                    console.debug('cant save tempfile', e);
                                }
                            }
                        }
                    }
                }
                if (!initDB) {
                    ErrorReporter.sendReport({ subject: 'cant init db', data: new Error(), type: 'warn' });
                    return this;
                }
                this.lastUserId = userId;
                this.isInit = false;
                return this;
            }
            await this.closeDB(deleteDB);
            this.lastUserId = undefined;
            this.isInit = false;
            return undefined;
        };
        if (ClientDB._instance != null) {
            throw new Error('ClientDB singleton');
        }
    }
    static get instance() {
        if (ClientDB._instance == null) {
            ClientDB._instance = new ClientDB();
        }
        return ClientDB._instance;
    }
    get db() {
        return this.clientDB;
    }
    get localDb() {
        return this.clientDB;
    }
    async closeDB(deleteDB) {
        if (this.clientDB != null) {
            await this.clientDB.closeDB();
            if (deleteDB && this.lastUserId != null) {
                await ClientDBadapter.deleteDB(this.lastUserId);
            }
        }
        this.clientDB = undefined;
    }
    async init() {
        await DeviceManager.loadDeviceId();
        const resetToken = await SimpleStorage.get('resetToken');
        const { host } = Url.getURLfromString(Config.b2cURL);
        const tokenKey = `tt_${host}`;
        if (resetToken == null) {
            await SecureLocalStoreB.instance.removeItem(tokenKey);
            await SimpleStorage.set('resetToken', 'no');
        }
        const getToken = await SecureLocalStoreB.instance.getItem(tokenKey);
        try {
            await this.initAuth(getToken);
        }
        catch (e) {
            if (getToken != null) {
                await this.initAuth();
                await SecureLocalStoreB.instance.removeItem(tokenKey);
            }
            else {
                throw e;
            }
        }
        this.initialized = true;
    }
    async initAuth(token) {
        if (token != null && token.length > 0) {
            try {
                const userId = CurrentUser.getUnVerifiedDataFromToken(token)._id;
                await this.initDB(userId, false);
            }
            catch (e) {
                console.debug('invalid token');
                await this.initAuth();
                return;
            }
        }
        else {
            await this.initDB();
        }
        if (this.upmeshConfig == null) {
            await this.checkForMinVersion();
            const serverConnection = Init.getServerConnection(token);
            if (Platform.OS === 'web')
                Init.connectionStatusChanged.post(Init.connectionStatus);
            this.upmeshConfig = {
                remoteUrl: Config.b2cURL,
                clientDB: this,
                reInitDB: this.initDB,
                serverConnection,
                worker: Platform.OS === 'web' && Init.hasWorker ? SharedWorkerWeb.instance : undefined,
                discardOnErrors: -1,
            };
            await UpmeshClient.instance.init(this.upmeshConfig, token);
            UpmeshClient.eventDispatcher.timeOutForCollectEntities = 150;
        }
        else {
            this.upmeshConfig.clientDB = undefined;
        }
        if (Platform.OS === 'web' && location.href.indexOf('#at=') >= 0) {
            const accessToken = location.href.split('#at=')[1];
            if (accessToken != null && accessToken.length > 0) {
                try {
                    const c = new LoginWithAccessToken({ token: accessToken });
                    await c.execute();
                    await SecureLocalStoreB.instance.setItem(`tt_${Url.getURLfromString(Config.b2cURL).host}`, c.result.token);
                    location.replace(location.href.split('#at=')[0]);
                    return;
                }
                catch (e) {
                    console.debug('cant login with accesstoken', e);
                }
            }
        }
    }
    async checkForMinVersion() {
        try {
            const minVersionFetch = await fetch(`${Config.b2cURL}/backend/minVersion`);
            if (minVersionFetch.status === 200) {
                const minVersion = Number.parseInt(await minVersionFetch.text(), 10);
                const currentVersion = Number.parseInt(Config.getVersion().split('@')[1], 10);
                if (currentVersion < minVersion) {
                    setTimeout(UpdateHint.showUpdateHintForConnection, 5000);
                }
            }
        }
        catch (e) {
            console.debug('cant fetch min required version');
        }
    }
    async initialize(userId) {
        let initDB = false;
        this.clientDB = new LokiODataDB();
        const adapter = await ClientDBadapter.getAdapter(userId);
        this.dbOptions = new LokiDBOptions(new FileHandler(`${Config.b2cURL}/files`), `${userId}.json`, adapter);
        try {
            initDB = await this.clientDB.initDB(this.dbOptions);
            const getAll = await SimpleStorage.get(`offlineCommands_${userId}`);
            let all = [];
            try {
                if (getAll != null) {
                    all = JSON.parse(getAll);
                }
            }
            catch (err) {
                all = [];
                console.error('cant get offlineCommands for sync', err);
            }
            if (all != null && all.length > 0) {
                try {
                    const offlineCommandRepo = this.clientDB.getRepos(new CommandOfflineModel());
                    for (const c of all) {
                        try {
                            await offlineCommandRepo.post(c);
                        }
                        catch (e) {
                            console.error('cant post offlinecommand from simple storage synCommands', e);
                        }
                    }
                    await SimpleStorage.remove(`offlineCommands_${userId}`);
                }
                catch (e) {
                    console.error('cant save offline commands', e);
                }
            }
        }
        catch (e) {
            console.debug('cant load Loki, try to reset local db', e);
            await ClientDBadapter.deleteDB(userId);
            try {
                initDB = await this.clientDB.initDB(this.dbOptions);
            }
            catch (e) {
                console.warn('still cant load Loki, select other adapter?', e);
            }
        }
        return initDB;
    }
}
