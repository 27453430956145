var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Picker = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-client/node_modules/react-native-web");
var _ThemeManager = require("../ThemeManager");
var _CheckboxListItem = require("./CheckboxListItem");
var _ListItem = require("./ListItem");
var _Menu = require("./Menu");
var _Measurement = require("./utils/Measurement");
var _jsxRuntime = require("../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var Picker = exports.Picker = function (_PureComponent) {
  function Picker(props) {
    var _this;
    (0, _classCallCheck2.default)(this, Picker);
    _this = _callSuper(this, Picker, [props]);
    _this.onSubmitEditing = function () {
      var _Menu$instance;
      (_Menu$instance = _Menu.Menu.instance) == null ? void 0 : _Menu$instance.close();
      if (_this.props.onSubmitEditing != null) {
        _this.props.onSubmitEditing();
      }
    };
    _this.onCloseMenu = function () {
      var onCloseMenu = _this.props.onCloseMenu;
      _this.setState({
        menuIsOpen: false
      }, function () {
        if (onCloseMenu != null) {
          onCloseMenu();
        }
      });
    };
    _this.openMenu = function () {
      var _ref = (0, _asyncToGenerator2.default)(function* (_e) {
        var _Menu$instance2;
        var _this$props = _this.props,
          textColor = _this$props.textColor,
          selectedTextColor = _this$props.selectedTextColor,
          menuWidth = _this$props.menuWidth,
          elevation = _this$props.elevation,
          checkbox = _this$props.checkbox,
          checkboxSelectAll = _this$props.checkboxSelectAll,
          checkboxSelectAllTitle = _this$props.checkboxSelectAllTitle,
          formInput = _this$props.formInput,
          placeholder = _this$props.placeholder,
          maxLetters = _this$props.maxLetters,
          keyboardType = _this$props.keyboardType,
          onFocus = _this$props.onFocus,
          onChangeFormInput = _this$props.onChangeFormInput,
          onBlur = _this$props.onBlur,
          errorFormInput = _this$props.errorFormInput,
          disabledFormInput = _this$props.disabledFormInput,
          helperText = _this$props.helperText,
          formInputValue = _this$props.formInputValue,
          onOpenMenu = _this$props.onOpenMenu;
        if (_this.button == null) {
          return;
        }
        var s = yield _Measurement.Measurement.measure(_this.button);
        var items = _this.getList();
        var client = {
          height: s.height,
          width: menuWidth == null ? s.width : menuWidth,
          x: s.pageX,
          y: s.pageY
        };
        (_Menu$instance2 = _Menu.Menu.instance) == null ? void 0 : _Menu$instance2.open({
          elevation: elevation,
          client: client,
          checkbox: checkbox,
          checkboxSelectAllTitle: checkboxSelectAllTitle,
          checkboxSelectAll: checkboxSelectAll,
          formInput: formInput,
          placeholder: placeholder,
          maxLetters: maxLetters,
          keyboardType: keyboardType,
          onFocus: onFocus,
          formInputValue: formInputValue,
          onBlur: onBlur,
          errorFormInput: errorFormInput,
          disabledFormInput: disabledFormInput,
          helperText: helperText,
          items: items,
          onOpen: onOpenMenu,
          onClose: _this.onCloseMenu,
          onChange: onChangeFormInput,
          onPressCheckbox: _this.onChangeCheckedBox,
          onSubmitEditing: _this.onSubmitEditing,
          textColor: textColor != null && textColor.length > 0 ? textColor : _ThemeManager.ThemeManager.style.primaryTextColor,
          selectedTextColor: selectedTextColor != null && selectedTextColor.length > 0 ? selectedTextColor : _ThemeManager.ThemeManager.style.primaryTextColor
        });
        _this.setState({
          menuIsOpen: true
        });
      });
      return function (_x) {
        return _ref.apply(this, arguments);
      };
    }();
    _this.onChangeChecked = function (value) {
      var _this$props2 = _this.props,
        onChangeChecked = _this$props2.onChangeChecked,
        selectedIndex = _this$props2.selectedIndex;
      if (onChangeChecked != null && selectedIndex != null) {
        onChangeChecked(selectedIndex, value);
      }
    };
    _this.onChangeCheckedBox = function (index, value) {
      var onChangeChecked = _this.props.onChangeChecked;
      if (onChangeChecked != null && index != null) {
        onChangeChecked(index, value);
      }
    };
    _this.onPressListItem = function (_e, index) {
      var _Menu$instance3;
      var _this$props3 = _this.props,
        onChangePicker = _this$props3.onChangePicker,
        onChange = _this$props3.onChange;
      var list = _this.state.list;
      if (onChangePicker != null) {
        onChangePicker(index);
      }
      if (onChange != null) {
        onChange(index, list[index]);
      }
      _this.setState({
        currentIndex: index
      });
      (_Menu$instance3 = _Menu.Menu.instance) == null ? void 0 : _Menu$instance3.close();
    };
    _this.setButton = function (e) {
      _this.button = e;
    };
    _this.state = {
      currentIndex: 0,
      list: [],
      menuIsOpen: false
    };
    return _this;
  }
  (0, _inherits2.default)(Picker, _PureComponent);
  return (0, _createClass2.default)(Picker, [{
    key: "getList",
    value: function getList() {
      var selectedIndex = this.props.selectedIndex;
      var list = this.state.list;
      var items = [];
      for (var i = 0; i < list.length; i += 1) {
        var title = '';
        if (typeof list[i] === 'string') {
          title = list[i].toString();
        } else if (list[i]['title']) {
          title = list[i]['title'];
        }
        items.push({
          text: title,
          onPress: this.onPressListItem,
          selected: i === selectedIndex,
          checked: typeof list[i] !== 'string' && list[i]['checked'] != null ? list[i]['checked'] : false,
          subtitle: typeof list[i] !== 'string' && list[i]['subtitle'] ? list[i]['subtitle'] : null
        });
      }
      return items;
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      var _Menu$instance4;
      (_Menu$instance4 = _Menu.Menu.instance) == null ? void 0 : _Menu$instance4.close();
    }
  }, {
    key: "render",
    value: function render() {
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        collapsable: false,
        ref: this.setButton,
        children: this.renderItem()
      });
    }
  }, {
    key: "renderItem",
    value: function renderItem() {
      var _this$props4 = this.props,
        pickerColor = _this$props4.pickerColor,
        pickerTextColor = _this$props4.pickerTextColor,
        selectedIndex = _this$props4.selectedIndex,
        subtitle = _this$props4.subtitle,
        checkbox = _this$props4.checkbox,
        pickerButtonColorRadius = _this$props4.pickerButtonColorRadius,
        pickerHoverColor = _this$props4.pickerHoverColor;
      var _this$state = this.state,
        list = _this$state.list,
        currentIndex = _this$state.currentIndex,
        menuIsOpen = _this$state.menuIsOpen;
      if (selectedIndex == null) {
        return null;
      }
      var title = '';
      if (typeof list[currentIndex] === 'string') {
        title = list[currentIndex].toString();
      } else if (list[currentIndex]['title']) {
        title = list[currentIndex]['title'];
      }
      if (checkbox === true) {
        return (0, _jsxRuntime.jsx)(_CheckboxListItem.CheckboxListItem, {
          title: title,
          onPressText: this.openMenu,
          roundedCheckbox: true,
          value: list[currentIndex]['checked'],
          onChange: this.onChangeChecked,
          listItemProps: {
            iconRight: {
              icon: menuIsOpen ? 'menu-up' : 'menu-down',
              toolTip: '',
              onPress: this.openMenu
            },
            secondTextLine: subtitle != null ? subtitle : undefined,
            textColor: pickerTextColor,
            backgroundColor: pickerColor,
            width: 'auto',
            style: {
              borderRadius: pickerButtonColorRadius != null ? pickerButtonColorRadius : 0
            }
          }
        }, `${list[currentIndex]['title']}_${list[currentIndex]['checked']}`);
      }
      return (0, _jsxRuntime.jsx)(_ListItem.ListItem, {
        title: title,
        onPress: this.openMenu,
        iconRight: {
          icon: menuIsOpen === true ? 'menu-up' : 'menu-down',
          toolTip: '',
          onPress: this.openMenu,
          color: pickerTextColor
        },
        secondTextLine: subtitle != null ? subtitle : undefined,
        textColor: pickerTextColor,
        backgroundColor: pickerColor,
        width: "auto",
        hoverColor: pickerHoverColor,
        style: {
          borderRadius: pickerButtonColorRadius != null ? pickerButtonColorRadius : 0,
          minWidth: 100
        }
      });
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        list: [],
        selectedIndex: 0,
        onChangeChecked: undefined,
        elevation: 8,
        checkboxSelectAllTitle: 'Alles auswählen',
        checkboxSelectAll: false,
        checkbox: false
      };
    }
  }, {
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, _prevState) {
      return {
        currentIndex: nextProps.selectedIndex,
        list: nextProps.list
      };
    }
  }]);
}(_react.PureComponent);
Picker.defaultState = {
  currentIndex: 0,
  list: [],
  menuIsOpen: false
};