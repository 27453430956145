var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LoginView = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-client/node_modules/react-native-web");
var _AuthClient = require("upmesh-auth-core/build/src/client/AuthClient");
var _userContext = _interopRequireDefault(require("../../userContext"));
var _LeftImageOrTopLogoResponsive = require("../../components/login/components/LeftImageOrTopLogoResponsive");
var _LoginForm = require("../../components/login/components/LoginForm");
var _LoginOrRegisterTitle = require("../../components/login/components/LoginOrRegisterTitle");
var _ContainedButton = require("../../theme/components/button/ContainedButton");
var _MaterialText = require("../../theme/components/text/MaterialText");
var _I18n = require("../../theme/i18n/I18n");
var _RouterControl = require("../../theme/routing/RouterControl");
var _Routing = require("../../theme/routing/Routing");
var _ThemeManager = require("../../theme/ThemeManager");
var _jsxRuntime = require("../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var styles = {
  container: {
    width: '100%',
    maxWidth: 512,
    alignSelf: 'center'
  },
  contentView: {
    alignItems: 'center',
    marginTop: 32,
    marginBottom: 46,
    alignSelf: 'stretch'
  }
};
var LoginView = exports.LoginView = function (_PureComponent) {
  function LoginView() {
    var _this;
    (0, _classCallCheck2.default)(this, LoginView);
    _this = _callSuper(this, LoginView, arguments);
    _this.logout = function () {
      var currentLink = _RouterControl.RouterControl.instance.currentUrl;
      _AuthClient.AuthClient.instance.logOut(true, currentLink).catch(function (err) {
        return void 0;
      });
    };
    return _this;
  }
  (0, _inherits2.default)(LoginView, _PureComponent);
  return (0, _createClass2.default)(LoginView, [{
    key: "render",
    value: function render() {
      var user = this.context.user;
      var size = this.props.size;
      if (user != null) {
        return (0, _jsxRuntime.jsx)(_LeftImageOrTopLogoResponsive.LeftImageOrTopLogoResponsive, {
          size: size,
          children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
            style: {
              width: '100%',
              alignContent: 'center',
              padding: _ThemeManager.ThemeManager.style.contentPaddingValue
            },
            children: [(0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              children: _I18n.I18n.m.getMessage('logOutFirst')
            }), (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
              style: {
                paddingTop: _ThemeManager.ThemeManager.style.contentPaddingValue,
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'flex-end'
              },
              children: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
                title: _I18n.I18n.m.getMessage('goToHome'),
                onPress: _Routing.Routing.instance.goToButton('/'),
                backgroundColor: "transparent",
                textColor: _ThemeManager.ThemeManager.style.brandPrimary
              }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
                style: {
                  width: 12
                }
              }), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
                title: _I18n.I18n.m.getMessage('logOutFirstButton'),
                onPress: this.logout
              })]
            })]
          })
        });
      }
      var appVersionInfo = this.props.appVersion;
      return (0, _jsxRuntime.jsx)(_LeftImageOrTopLogoResponsive.LeftImageOrTopLogoResponsive, {
        size: size,
        children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: [_ThemeManager.ThemeManager.style.contentStyle, styles.container],
          children: [(0, _jsxRuntime.jsx)(_LoginOrRegisterTitle.LoginOrRegisterTitle, {
            active: "login"
          }), (0, _jsxRuntime.jsx)(_LoginForm.LoginForm, {}, "loginForm"), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: styles.contentView,
            children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              centeredBox: true,
              centeredText: true,
              children: _I18n.I18n.m.getMessage('loginInfo')
            })
          }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            type: _MaterialText.MaterialTextTypes.Overline,
            color: "#aaaaaa",
            children: appVersionInfo
          })]
        })
      });
    }
  }]);
}(_react.PureComponent);
LoginView.contextType = _userContext.default;