import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Checkbox } from 'materialTheme/src/theme/components/Checkbox';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { DateInputFormFilled } from 'materialTheme/src/theme/components/forminput/DateInputFormFilled';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { FormInputPicker } from 'materialTheme/src/theme/components/forminput/FormInputPicker';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { ScrollContainer } from 'materialTheme/src/theme/components/scroll/ScrollContainer';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { ChangeWorkingTimeModel } from 'upmesh-core/src/client/commands/companies/workingtimemodels/ChangeWorkingTimeModel';
import { CreateWorkingTimeModel } from 'upmesh-core/src/client/commands/companies/workingtimemodels/CreateWorkingTimeModel';
import { WorkingTimeModelEntity, } from 'upmesh-core/src/client/query/entities/WorkingTimeModelEntity';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
export class AddOrChangeWorkingTimeModelDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.onLayoutChange = (e) => {
            this.setState({ height: e.nativeEvent.layout.height, width: e.nativeEvent.layout.width });
        };
        this.validate = async () => {
            const { model } = this.state;
            const errors = {};
            try {
                await CreateWorkingTimeModel.validateWorkingTimeModel(new WorkingTimeModelEntity(model));
                this.setState({ errors });
                return true;
            }
            catch (e) {
                if (Array.isArray(e)) {
                    e.forEach((err) => {
                        if (err['key'] != null) {
                            if (err['messageCode'] != null) {
                                errors[err.key] = I18n.m.getMessage(err.messageCode, err['messageData']);
                            }
                            else {
                                errors[err.key] = err['messsage'];
                            }
                        }
                    });
                }
            }
            this.setState({ errors });
            return false;
        };
        this.onSave = () => {
            this.validate()
                .then((ok) => {
                if (ok) {
                    this.setState({ isLoading: true }, () => {
                        this.saveNow()
                            .then(() => {
                            this.setState({ isLoading: false }, () => Dialog.instance?.close());
                        })
                            .catch((err) => {
                            this.setState({ isLoading: false }, () => {
                                DefaultErrorHandler.showDefaultErrorAlert(err);
                            });
                        });
                    });
                }
            })
                .catch((err) => console.debug(err));
        };
        this.onChangeMark = (text) => {
            this.setState((prevState) => {
                const current = prevState.model;
                current.mark = text;
                return { model: new WorkingTimeModelEntity(current) };
            }, this.validate);
        };
        this.onChangePostRecordingInDays = (text) => {
            this.setState((prevState) => {
                const current = prevState.model;
                let n = text == null ? 0 : Number.parseInt(text, 10);
                n = Number.isNaN(n) ? 0 : n;
                if (n > 31)
                    n = 31;
                if (n < 0)
                    n = 0;
                const model = new WorkingTimeModelEntity(current);
                model.postRecordingInDays = n;
                return { model };
            }, this.validate);
        };
        this.onChangeDay = (index) => (value) => {
            this.setState((prevState) => {
                const current = prevState.model;
                current.sections[index].validFromDay = value.index + 1;
                return { model: new WorkingTimeModelEntity(current) };
            }, this.validate);
        };
        this.onChangeMonth = (index) => (value) => {
            this.setState((prevState) => {
                const current = prevState.model;
                current.sections[index].validFromMonth = value.index + 1;
                return { model: new WorkingTimeModelEntity(current) };
            }, this.validate);
        };
        this.onAddSection = () => {
            this.setState((prevState) => {
                const { model } = prevState;
                const sections = model.sections == null ? [] : [...model.sections];
                const validFromMonth = sections.length > 0 && sections[sections.length - 1].validFromMonth < 12
                    ? sections[sections.length - 1].validFromMonth + 1
                    : 1;
                sections.push({
                    validFromMonth,
                    validFromDay: 1,
                    workingTime: { ...WorkingTimeModelEntity.defaultTimeModelSection.workingTime },
                });
                return { model: new WorkingTimeModelEntity({ ...model, sections }) };
            }, this.validate);
        };
        this.renderSection = (section, sectionIndex) => {
            const { width } = this.state;
            const weekkeys = ['mon', 'tue', 'wed', 'thi', 'fri', 'sat', 'sun'];
            const weekdays = I18n.m.dateCurrent.weekdays();
            const views = [];
            weekkeys.forEach((key, index) => {
                const time = section.workingTime[key];
                const startTime = time != null ? new Date(1970, 0, 1, time.start.hours, time.start.minutes, 0, 0) : undefined;
                const stopTime = time != null ? new Date(1970, 0, 1, time.stop.hours, time.stop.minutes, 0, 0) : undefined;
                const pauseTime = time != null ? new Date(1970, 0, 1, time.pause.hours, time.pause.minutes, 0, 0) : undefined;
                const workFree = time == null;
                views.push(<View key={`times_${JSON.stringify({ index, key })}`} style={{
                        width: width > 1000 ? Math.floor(width / 7.5) : '100%',
                    }}>
          <View style={{ width: '100%', flexDirection: 'row' }}>
            <Checkbox value={!workFree} onChange={this.onChangeWorkfree(sectionIndex, key)}/>
            <MaterialText centeredBox>{weekdays[index >= 6 ? 0 : index + 1]}</MaterialText>
          </View>
          {workFree ? null : (<View style={{ width: '100%' }}>
              <DateInputFormFilled labelText={I18n.m.getMessage('workingTimeModelsStart')} selectDate={false} selectTime onChange={this.onChangeTime(sectionIndex, key, 'start')} value={startTime} clearButton={false}/>
              <DateInputFormFilled labelText={I18n.m.getMessage('workingTimeModelsStop')} selectDate={false} selectTime onChange={this.onChangeTime(sectionIndex, key, 'stop')} value={stopTime} clearButton={false}/>
              <DateInputFormFilled labelText={I18n.m.getMessage('workingTimeModelsPause')} selectDate={false} selectTime onChange={this.onChangeTime(sectionIndex, key, 'pause')} value={pauseTime} clearButton={false}/>
            </View>)}
        </View>);
            });
            return (<View style={{ width: '100%', flexDirection: width > 1000 ? 'row' : 'column', justifyContent: 'space-between' }}>
        {views}
      </View>);
        };
        this.onChangeTime = (sectionIndex, day, type) => (value) => {
            this.setState((prevState) => {
                const current = prevState.model;
                if (current.sections[sectionIndex] != null) {
                    if (current.sections[sectionIndex].workingTime == null)
                        current.sections[sectionIndex].workingTime = {};
                    if (current.sections[sectionIndex].workingTime[day] == null)
                        current.sections[sectionIndex].workingTime[day] = {
                            start: { hours: 0, minutes: 0 },
                            stop: { hours: 0, minutes: 0 },
                            pause: { hours: 0, minutes: 0 },
                        };
                    if (value != null) {
                        current.sections[sectionIndex].workingTime[day][type] = {
                            hours: value.getHours(),
                            minutes: value.getMinutes(),
                        };
                    }
                    return { model: new WorkingTimeModelEntity(current) };
                }
                return null;
            }, this.validate);
        };
        this.onChangeWorkfree = (sectionIndex, day) => (value) => {
            this.setState((prevState) => {
                const current = prevState.model;
                if (current.sections[sectionIndex] != null) {
                    if (current.sections[sectionIndex].workingTime == null)
                        current.sections[sectionIndex].workingTime = {};
                    if (value) {
                        if (current.sections[sectionIndex].workingTime[day] == null)
                            current.sections[sectionIndex].workingTime[day] = {
                                start: { hours: 7, minutes: 0 },
                                stop: { hours: 16, minutes: 0 },
                                pause: { hours: 1, minutes: 0 },
                            };
                    }
                    else {
                        current.sections[sectionIndex].workingTime[day] = null;
                    }
                    return { model: new WorkingTimeModelEntity(current) };
                }
                return null;
            }, this.validate);
        };
        this.removeSection = (sectionIndex) => (_e) => {
            this.setState((prevState) => {
                const current = prevState.model;
                if (current.sections[sectionIndex] != null) {
                    current.sections.splice(sectionIndex, 1);
                    return { model: new WorkingTimeModelEntity(current) };
                }
                return null;
            }, this.validate);
        };
        this.state = {
            model: props.model != null
                ? new WorkingTimeModelEntity(props.model)
                : new WorkingTimeModelEntity({
                    companyId: props.company.id,
                    sections: [{ ...WorkingTimeModelEntity.defaultTimeModelSection }],
                }),
            isLoading: false,
            height: 0,
            width: 0,
            errors: {},
        };
    }
    render() {
        const { createNewFromDraft } = this.props;
        const { model, errors, isLoading, width } = this.state;
        const equal = JSON.stringify(this.props.model) === JSON.stringify(model);
        if (width === 0) {
            return (<View onLayout={this.onLayoutChange} style={{
                    width: '100%',
                    height: '100%',
                }}>
          <Spinner />
        </View>);
        }
        return (<View onLayout={this.onLayoutChange} style={{
                width: '100%',
                height: '100%',
            }}>
        <DialogTitle iconRight={{
                icon: 'close',
                disabled: isLoading,
                toolTip: I18n.m.getMessage('close'),
                onPress: () => {
                    if (!equal) {
                        Routing.instance.alert.post({
                            text: I18n.m.getMessage('unsavedChangesInDialog'),
                            buttons: [
                                <ContainedButton key="discard" title={I18n.m.getMessage('discard')} backgroundColor={ThemeManager.style.brandDanger} onPress={() => {
                                        Alert.instance?.close(() => {
                                            Dialog.instance?.close();
                                        });
                                    }}/>,
                                <ContainedButton key="save" title={I18n.m.getMessage('save')} onPress={() => {
                                        Alert.instance?.close(() => {
                                            this.saveNow()
                                                .then(() => {
                                                Dialog.instance?.close();
                                            })
                                                .catch((e) => {
                                                DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m);
                                            });
                                        });
                                    }}/>,
                            ],
                        });
                    }
                    else {
                        Dialog.instance?.close();
                    }
                },
            }}>
          {this.props.model == null || createNewFromDraft === true
                ? I18n.m.getMessage('workingTimeModelAdd')
                : I18n.m.getMessage('workingTimeModelEdit')}
        </DialogTitle>
        <ScrollContainer style={{ width: '100%' }}>
          <View style={{
                width: '100%',
                height: 'auto',
                paddingTop: 0,
                paddingHorizontal: ThemeManager.style.contentPaddingValue,
            }}>
            <FormInputFilled onChange={this.onChangeMark} labelText={I18n.m.getMessage('workingTimeModelMark')} initValue={model.mark} error={errors['mark'] != null} helperText={errors['mark']}/>
            <FormInputFilled onChange={this.onChangePostRecordingInDays} keyboardType="numeric" labelText={I18n.m.getMessage('workingTimeModelPostRecordingInDays')} value={model.postRecordingInDays.toString()} error={errors['postRecordingInDays'] != null} helperText={errors['postRecordingInDays']}/>
            {this.renderSections()}
          </View>
        </ScrollContainer>
        <View style={{
                width: '100%',
                alignContent: 'flex-end',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                flexDirection: 'row',
                paddingBottom: 8,
                paddingRight: 8,
            }}>
          <DialogActions>
            <ContainedButton title={I18n.m.getMessage('save')} onPress={this.onSave} loading={isLoading} disabled={isLoading || equal || JSON.stringify(errors) !== JSON.stringify({})}/>
          </DialogActions>
        </View>
      </View>);
    }
    async saveNow() {
        const { model, createNewFromDraft } = this.props;
        const m = this.state.model;
        if (model == null || createNewFromDraft === true) {
            const s = new CreateWorkingTimeModel({ ...m });
            await s.execute(ClientStore.commandStore);
        }
        else {
            const s = new ChangeWorkingTimeModel({ ...m }, m.id);
            await s.execute(ClientStore.commandStore);
        }
        return m;
    }
    renderSections() {
        const { model, isLoading, width, errors } = this.state;
        const views = [];
        const days = Array.from(Array(31).keys()).map((e) => {
            return (e + 1).toString(10);
        });
        const month = Array.from(Array(12).keys()).map((e) => {
            return { data: (e + 1).toString(10), title: I18n.m.dateCurrent.monthsNames()[e] };
        });
        if (model.sections != null) {
            model.sections.forEach((s, i) => {
                views.push(<View key={`section_${JSON.stringify(i)}`} style={{ width: '100%', ...ThemeManager.style.borderStyle, borderWidth: 0, borderBottomWidth: 1 }}>
            <MaterialText strong>{I18n.m.getMessage('validFrom')}</MaterialText>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <View style={{ width: width / 2 - 60 }}>
                <FormInputPicker disabled={isLoading || model.sections.length <= 1} list={days} labelText={I18n.m.getMessage('day')} selectedIndex={s.validFromDay > 1 ? s.validFromDay - 1 : 0} onChange={this.onChangeDay(i)}/>
              </View>
              <View style={{ width: width / 2 - 60 }}>
                <FormInputPicker disabled={isLoading || model.sections.length <= 1} list={month} labelText={I18n.m.getMessage('month')} selectedIndex={s.validFromMonth > 1 ? s.validFromMonth - 1 : 0} onChange={this.onChangeMonth(i)}/>
              </View>
              <View style={{ width: 48, paddingTop: 18 }}>
                <Icon disabled={isLoading || model.sections.length <= 1} toolTip={I18n.m.getMessage('delete')} icon="delete" onPress={this.removeSection(i)}/>
              </View>
            </View>
            {this.renderSection(s, i)}
          </View>);
                views.push(<View key={`spacer_${JSON.stringify(i)}`} style={{ height: 16 }}/>);
            });
        }
        if (errors['sections'] != null && errors['sections'] === I18n.m.getMessage('workingTimeModelSectionRequired')) {
            views.push(<View key="errorMessageSectionReuqired">
          <MaterialText>{I18n.m.getMessage('workingTimeModelSectionRequired')}</MaterialText>
        </View>);
        }
        views.push(<View key="plusButton">
        <ContainedButton icon={{ icon: 'plus', toolTip: '' }} title={I18n.m.getMessage('workingTimeModelAddSection')} onPress={this.onAddSection} disabled={isLoading}/>
      </View>);
        return views;
    }
}
