import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { DialogBetween } from 'materialTheme/src/theme/components/DialogBetween';
import DraggableFlatList from 'materialTheme/src/theme/components/DraggableFlatList';
import { Draggable } from 'materialTheme/src/theme/components/dragndrop/Dragable';
import { DropArea } from 'materialTheme/src/theme/components/dragndrop/DropArea';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MenuRaw } from 'materialTheme/src/theme/components/MenuRaw';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { Measurement } from 'materialTheme/src/theme/components/utils/Measurement';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useState } from 'react';
import { Platform, ScrollView, TouchableOpacity, View } from 'react-native';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { AddCompanyTicketLayout } from 'upmesh-core/src/client/commands/companies/ticketlayouts/AddCompanyTicketLayout';
import { ChangeCompanyTicketLayout } from 'upmesh-core/src/client/commands/companies/ticketlayouts/ChangeCompanyTicketLayout';
import { AddProjectTicketLayout } from 'upmesh-core/src/client/commands/project/layouts/AddProjectTicketLayout';
import { ChangeProjectTicketLayout } from 'upmesh-core/src/client/commands/project/layouts/ChangeProjectTicketLayout';
import { LayoutFields, } from 'upmesh-core/src/client/query/entities/simple/CustomField';
import { TicketLayoutsEntity } from 'upmesh-core/src/client/query/entities/simple/TicketLayoutsEntity';
import * as uuid from 'uuid';
import { I18n } from '../../../../i18n/I18n';
import { DefaultErrorHandler } from '../../../DefaultErrorHandler';
import { TicketLayoutCheckboxListFieldOptions } from './TicketLayoutCheckboxListOptions';
import { TicketLayoutDateFieldOptions } from './TicketLayoutDateOptions';
import { TicketLayoutDateRangeFieldOptions } from './TicketLayoutDateRangeOptions';
import { TicketLayoutDueDateOptions } from './TicketLayoutDueDateOptions';
import { TicketLayoutListFieldOptions } from './TicketLayoutListOptions';
import { TicketLayoutMultiPersonFieldOptions } from './TicketLayoutMultiPersonFieldOptions';
import { TicketLayoutMultiselectListFieldOptions } from './TicketLayoutMultiselectListOptions';
import { TicketLayoutNumericFieldOptions } from './TicketLayoutNumericFieldOptions';
import { TicketLayoutPersonFieldOptions } from './TicketLayoutPersonFieldOptions';
import { TicketLayoutStringFieldOptions } from './TicketLayoutStringFieldOptions';
export function AddOrChangeProjectTicketLayoutDialog(props) {
    const [currentLayout, setCurrentLayout] = useState(props.layout
        ? new TicketLayoutsEntity(props.layout)
        : props.copiedLayout
            ? new TicketLayoutsEntity(props.copiedLayout)
            : new TicketLayoutsEntity());
    const [highlightDropArea, setHighlightDropArea] = useState(false);
    const [formError, setFormError] = useState(new Map());
    const sView = props.size != null && props.size.windowWidth <= ThemeManager.style.breakpointM;
    const onSaveField = (field) => {
        const fields = [];
        let updated = false;
        for (const f of currentLayout.fields) {
            if (f.id !== field.id)
                fields.push(f);
            else {
                updated = true;
                fields.push(field);
            }
        }
        if (!updated) {
            fields.push(field);
        }
        setCurrentLayout(new TicketLayoutsEntity({ ...currentLayout, fields }));
    };
    const openCompletionOnEdit = (field) => (e) => {
        MenuRaw.instance?.close();
        const openPosition = e == null ? undefined : { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
        DialogBetween.instance?.open({
            openPosition,
            content: <TicketLayoutDueDateOptions currentFields={currentLayout.fields} field={field} onSave={onSaveField}/>,
            contentPadding: false,
            fullscreenResponsive: true,
            showCloseIcon: false,
            closeOnTouchOutside: false,
        });
    };
    const openEdit = (field, newField = false) => (e) => {
        MenuRaw.instance?.close();
        const openPosition = e == null ? undefined : { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
        let content;
        if (field.customField) {
            const approverCanVisible = currentLayout.fields.find((a) => a.systemField === 'approver') != null;
            const assigneeCanEditVisible = currentLayout.fields.find((a) => a.systemField === 'assignee') != null;
            if (field.customField.type === 'number') {
                content = (<TicketLayoutNumericFieldOptions currentFields={currentLayout.fields} field={field} newField={newField} onSave={onSaveField} approverCanVisible={approverCanVisible} assigneeCanEditVisible={assigneeCanEditVisible}/>);
            }
            else if (field.customField.type === 'string') {
                content = (<TicketLayoutStringFieldOptions currentFields={currentLayout.fields} field={field} newField={newField} onSave={onSaveField} approverCanVisible={approverCanVisible} assigneeCanEditVisible={assigneeCanEditVisible}/>);
            }
            else if (field.customField.type === 'Date') {
                content = (<TicketLayoutDateFieldOptions currentFields={currentLayout.fields} field={field} newField={newField} onSave={onSaveField} approverCanVisible={approverCanVisible} assigneeCanEditVisible={assigneeCanEditVisible}/>);
            }
            else if (field.customField.type === 'DateRange') {
                content = (<TicketLayoutDateRangeFieldOptions currentFields={currentLayout.fields} field={field} newField={newField} onSave={onSaveField} approverCanVisible={approverCanVisible} assigneeCanEditVisible={assigneeCanEditVisible}/>);
            }
            else if (field.customField.type === 'List') {
                content = (<TicketLayoutListFieldOptions currentFields={currentLayout.fields} field={field} newField={newField} onSave={onSaveField} approverCanVisible={approverCanVisible} assigneeCanEditVisible={assigneeCanEditVisible}/>);
            }
            else if (field.customField.type === 'MultiselectList') {
                content = (<TicketLayoutMultiselectListFieldOptions currentFields={currentLayout.fields} field={field} newField={newField} onSave={onSaveField} approverCanVisible={approverCanVisible} assigneeCanEditVisible={assigneeCanEditVisible}/>);
            }
            else if (field.customField.type === 'CheckboxList') {
                content = (<TicketLayoutCheckboxListFieldOptions currentFields={currentLayout.fields} field={field} newField={newField} onSave={onSaveField} approverCanVisible={approverCanVisible} assigneeCanEditVisible={assigneeCanEditVisible}/>);
            }
            else if (field.customField.type === 'person') {
                content = (<TicketLayoutPersonFieldOptions currentFields={currentLayout.fields} field={field} newField={newField} onSave={onSaveField} approverCanVisible={approverCanVisible} assigneeCanEditVisible={assigneeCanEditVisible}/>);
            }
            else if (field.customField.type === 'multiperson') {
                content = (<TicketLayoutMultiPersonFieldOptions currentFields={currentLayout.fields} field={field} newField={newField} onSave={onSaveField} approverCanVisible={approverCanVisible} assigneeCanEditVisible={assigneeCanEditVisible}/>);
            }
            if (content != null) {
                DialogBetween.instance?.open({
                    openPosition,
                    content,
                    contentPadding: false,
                    fullscreenResponsive: true,
                    showCloseIcon: false,
                    closeOnTouchOutside: false,
                });
            }
        }
    };
    const addField = (p) => {
        if (TicketLayoutsEntity.canBeAdded(p, currentLayout)) {
            if (p.customField != null) {
                openEdit(p, true)(null);
            }
            else {
                const f = [...currentLayout.fields];
                f.push(p);
                setCurrentLayout(new TicketLayoutsEntity({ ...currentLayout, fields: f }));
            }
        }
    };
    const renderField = (title, type) => {
        const field = {
            id: uuid.v1(),
            label: type.systemField ? title : '',
            description: '',
            customField: type.customField,
            systemField: type.systemField,
        };
        const isPossible = TicketLayoutsEntity.canBeAdded(field, currentLayout);
        const icon = TicketLayoutsEntity.getIconName(field);
        const iconColor = field.customField != null ? ThemeManager.style.brandSuccess : ThemeManager.style.brandPrimary;
        const b = (<View style={{
                padding: 8,
                ...ThemeManager.style.borderStyle,
                borderRadius: ThemeManager.style.borderRadius,
                ...ThemeManager.noSelectionWebStyle(),
                flexDirection: 'row',
                alignItems: 'center',
            }}>
        <View style={{ width: 48 }}>
          <Icon icon={icon} toolTip="" color={iconColor} accessibilityLabel={title}/>
        </View>
        <View style={{ flex: 1 }}>
          <MaterialText>{title}</MaterialText>
        </View>
      </View>);
        if (sView) {
            return (<View style={{ paddingVertical: 4, width: '100%' }}>
          <Ripple disabled={!isPossible} backgroundColor="transparent" style={{ width: '100%', opacity: !isPossible ? 0.5 : 1 }} onPress={() => {
                    DialogBetween.instance?.close(() => {
                        addField(field);
                    });
                }}>
            {b}
          </Ripple>
        </View>);
        }
        if (!isPossible) {
            return <View style={{ paddingVertical: 4, opacity: 0.5 }}>{b}</View>;
        }
        return (<View style={{ paddingVertical: 4 }}>
        <Draggable onStartDrag={() => console.log('start Drag')} dropData={field}>
          {b}
        </Draggable>
      </View>);
    };
    const removeField = (field) => () => {
        Alert.instance?.close();
        const fields = [];
        currentLayout.fields.forEach((f) => {
            if (f.id !== field.id)
                fields.push(f);
        });
        setCurrentLayout(new TicketLayoutsEntity({ ...currentLayout, fields }));
    };
    const renderFieldOptions = ({ item, drag }) => {
        const iconColor = item.customField != null ? ThemeManager.style.brandSuccess : ThemeManager.style.brandPrimary;
        const icon = TicketLayoutsEntity.getIconName(item);
        const b = (<View style={{
                padding: 8,
                ...ThemeManager.style.borderStyle,
                borderRadius: ThemeManager.style.borderRadius,
                ...ThemeManager.noSelectionWebStyle(),
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
            }}>
        <View style={{ width: 48 }}>
          <Icon icon={icon} toolTip="" color={iconColor}/>
        </View>
        <View style={{ flex: 1 }}>
          <MaterialText>
            {item.label}
            {item.customField &&
                LayoutFields.getIdentifier(item) !== item.label &&
                ` (${LayoutFields.getIdentifier(item)})`}
          </MaterialText>
        </View>
        <View style={{ width: 40 }}>
          <Icon icon="dots-vertical" toolTip="" onPress={async (e) => {
                let x = 0;
                let y = 0;
                try {
                    if (e.nativeEvent?.pageX != null && e.nativeEvent.pageY) {
                        x = e.nativeEvent.pageX;
                        y = e.nativeEvent.pageY;
                    }
                    else {
                        const s = await Measurement.measure(e.currentTarget);
                        x = s.pageX;
                        y = s.pageY;
                    }
                }
                catch (err) {
                    console.warn(err);
                }
                const client = {
                    height: 0,
                    width: 300,
                    x,
                    y,
                };
                const items = [];
                if (item.systemField == null)
                    items.push({
                        disabled: item.systemField != null,
                        thumbnail: { thumbnail: <Icon icon="pencil-outline" toolTip=""/>, width: 40 },
                        title: I18n.m.getMessage('edit'),
                        onPress: item.systemField != null ? undefined : openEdit(item),
                    });
                else if (item.systemField === 'completionOn')
                    items.push({
                        disabled: item.systemField != null,
                        thumbnail: { thumbnail: <Icon icon="pencil-outline" toolTip=""/>, width: 40 },
                        title: I18n.m.getMessage('edit'),
                        onPress: openCompletionOnEdit(item),
                    });
                items.push({
                    thumbnail: { thumbnail: <Icon icon="delete-outline" toolTip=""/>, width: 40 },
                    title: I18n.m.getMessage('delete'),
                    onPress: () => {
                        Routing.instance.alert.post({
                            text: I18n.m.getMessage('ticketLayoutRemoveFieldQuestion', { title: item.label }),
                            buttons: [
                                <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                                <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={removeField(item)} backgroundColor={ThemeManager.style.brandDanger}/>,
                            ],
                        });
                        MenuRaw.instance?.close();
                    },
                });
                MenuRaw.instance?.open({
                    client,
                    items,
                });
            }}/>
        </View>
      </View>);
        return (<View style={{ paddingVertical: 4, width: '100%' }}>
        <TouchableOpacity onPressIn={drag} style={{ width: '100%', height: 56, justifyContent: 'center', alignItems: 'center' }}>
          {b}
        </TouchableOpacity>
      </View>);
    };
    const renderFields = () => {
        return (<ScrollView style={{ width: '100%', height: '100%' }}>
        <View collapsable={false} style={{
                width: '100%',
                padding: 16,
                ...ThemeManager.noSelectionWebStyle(),
            }}>
          <View style={{ paddingBottom: 4, width: '100%' }}>
            <MaterialText>{I18n.m.getMessage('systemFields')}</MaterialText>
          </View>
          {renderField(I18n.m.getMessage('ticketsDetailsState'), { systemField: 'status' })}
          {renderField(I18n.m.getMessage('ticketsDetailsDescription'), { systemField: 'description' })}
          {renderField(I18n.m.getMessage('ticketsDetailsPlan'), { systemField: 'plan' })}
          {renderField(I18n.m.getMessage('ticketDetailsDueDate'), { systemField: 'completionOn' })}
          {renderField(I18n.m.getMessage('ticketsDetailsTitleAssignedToUser'), {
                systemField: 'assignee',
            })}
          {renderField(I18n.m.getMessage('ticketsDetailsTitleApprover'), { systemField: 'approver' })}
          {renderField(I18n.m.getMessage('ticketsDetailsTags'), { systemField: 'tags' })}
          {renderField(I18n.m.getMessage('ticketsDetailsCraft'), { systemField: 'craft' })}
        </View>
        <View collapsable={false} style={{ width: '100%', padding: 16, ...ThemeManager.noSelectionWebStyle() }}>
          <View style={{ paddingBottom: 4, width: '100%' }}>
            <MaterialText>{I18n.m.getMessage('customFields')}</MaterialText>
          </View>
          {renderField(I18n.m.getMessage('customFieldNumber'), {
                customField: { type: 'number', options: { delimiter: 0, dezimals: 0 } },
            })}
          {renderField(I18n.m.getMessage('customFieldText'), {
                customField: { type: 'string', options: {} },
            })}
          {renderField(I18n.m.getMessage('customFieldDate'), {
                customField: { type: 'Date', options: {} },
            })}
          {renderField(I18n.m.getMessage('customFieldDateRange'), {
                customField: { type: 'DateRange', options: {} },
            })}
          {renderField(I18n.m.getMessage('customFieldList'), {
                customField: { type: 'List', options: {} },
            })}
          {renderField(I18n.m.getMessage('customFieldMultiselectList'), {
                customField: { type: 'MultiselectList', options: {} },
            })}
          {renderField(I18n.m.getMessage('customFieldCheckboxList'), {
                customField: { type: 'CheckboxList', options: {} },
            })}
          {renderField(I18n.m.getMessage('customFieldPerson'), {
                customField: { type: 'person', options: {} },
            })}
          {renderField(I18n.m.getMessage('customFieldMultiPerson'), {
                customField: { type: 'multiperson', options: {} },
            })}
        </View>
      </ScrollView>);
    };
    const renderDropAreaOrButton = () => {
        if (sView) {
            return (<ContainedButton full backgroundColor="transparent" title="Feld hinzufügen" onPress={(e) => {
                    const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
                    DialogBetween.instance?.open({
                        openPosition,
                        content: <View>{renderFields()}</View>,
                        closeOnTouchOutside: false,
                        contentPadding: false,
                        scrollable: true,
                        fullscreenResponsive: true,
                        showCloseIcon: true,
                    });
                }} icon={{ icon: 'plus' }} textColor={ThemeManager.style.brandPrimary} borderStyle="dashed" outlineColor={ThemeManager.style.borderColor}/>);
        }
        return (<DropArea dropFilter={() => true} onDrop={async (field) => {
                addField(field);
            }} toggleHighlight={(active) => setHighlightDropArea(active)}>
        <View accessibilityLabel="DropArea" style={{
                width: '100%',
                height: 48,
                ...ThemeManager.style.borderStyle,
                borderRadius: ThemeManager.style.borderRadius,
                borderStyle: 'dashed',
                justifyContent: 'center',
                padding: 4,
                backgroundColor: highlightDropArea ? ThemeManager.style.brandSuccess : 'transparent',
            }}>
          <View style={{ padding: 4 }}>
            <MaterialText color={ThemeManager.style.black54}>{I18n.m.getMessage('dropNewFieldHere')}</MaterialText>
          </View>
        </View>
      </DropArea>);
    };
    const setOrder = (data) => {
        setCurrentLayout(new TicketLayoutsEntity({ ...currentLayout, fields: data }));
    };
    const renderForm = () => {
        return (<>
        <DialogTitle>
          {props.layout
                ? I18n.m.getMessage('ticketLayoutTitleChange', { title: props.layout.title })
                : I18n.m.getMessage('ticketLayoutTitleCreate')}
        </DialogTitle>
        <DialogContent>
          <FormInputFilled helperText={formError.has('title') ? formError.get('title') : ''} error={formError.has('title')} labelText="Name" initValue={currentLayout.title} onChange={(v) => {
                setCurrentLayout(new TicketLayoutsEntity({ ...currentLayout, title: v }));
            }}/>
          <MaterialText>{I18n.m.getMessage('defaultFields')}</MaterialText>
          <View style={{ paddingVertical: 4, width: '100%', flexDirection: 'row', flexWrap: 'wrap' }}>
            <Chip title={I18n.m.getMessage('ticketsDetailsTitle')}/>
            <Chip title={I18n.m.getMessage('ticketsDetailsType')}/>
          </View>
          <View style={{
                marginVertical: 8,
                height: 1,
                backgroundColor: ThemeManager.style.borderColor,
                width: '100%',
            }}/>
          <DraggableFlatList style={{ width: '100%', marginRight: Platform.OS !== 'web' ? 32 : 0 }} data={currentLayout.fields} renderItem={renderFieldOptions} keyExtractor={(item) => item.id} onDragEnd={({ data }) => setOrder(data)}/>
          <View style={{ paddingVertical: 4, width: '100%' }}>{renderDropAreaOrButton()}</View>
        </DialogContent>
      </>);
    };
    const renderContent = () => {
        const height = props.size
            ? props.size.contentHeight * 0.9 - 112 - props.size.currentKeyboardHeight
            : ResizeEvent.current.contentHeight - ResizeEvent.current.currentKeyboardHeight;
        if (sView)
            return renderForm();
        return (<View style={{
                height,
                flexDirection: 'row',
                overflow: 'visible',
                ...ThemeManager.noSelectionWebStyle(),
            }}>
        <View style={{
                flex: 1,
                height,
                backgroundColor: ThemeManager.style.appBgColor,
                ...ThemeManager.noSelectionWebStyle(),
            }}>
          {renderFields()}
        </View>
        <View style={{ flex: 1.618, height, ...ThemeManager.noSelectionWebStyle() }}>{renderForm()}</View>
      </View>);
    };
    const saveForm = async () => {
        if (props.companySettings != null) {
            if (props.layout == null) {
                const c = new AddCompanyTicketLayout({
                    companyId: props.companySettings.id,
                    fields: currentLayout.fields,
                    title: currentLayout.title,
                }, currentLayout.id);
                await c.execute();
            }
            else {
                const c = new ChangeCompanyTicketLayout({ title: currentLayout.title, fields: currentLayout.fields, companyId: props.companySettings.id }, currentLayout.id);
                await c.execute(ClientStore.commandStore);
            }
        }
        else if (props.project != null) {
            if (props.layout == null) {
                const c = new AddProjectTicketLayout({
                    projectId: props.project.id,
                    fields: currentLayout.fields,
                    title: currentLayout.title,
                }, currentLayout.id);
                await c.execute();
            }
            else {
                const c = new ChangeProjectTicketLayout({ title: currentLayout.title, fields: currentLayout.fields, projectId: props.project.id }, currentLayout.id);
                const hasRemovedFields = await c.hasRemovedFields();
                if (hasRemovedFields.length === 0) {
                    await c.execute();
                }
                else {
                    const removedFields = [];
                    hasRemovedFields.forEach((field) => {
                        removedFields.push(field.label);
                    });
                    Routing.instance.alert.post({
                        title: I18n.m.getMessage('projectSettingsTicketLayoutFieldsRemovedTitle', {
                            fields: removedFields.join(', '),
                        }),
                        text: I18n.m.getMessage('projectSettingsTicketLayoutFieldsRemovedText'),
                        buttons: [
                            <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                            <ContainedButton key="yes" title={I18n.m.getMessage('delete')} onPress={() => {
                                    Alert.instance?.close(() => {
                                        c.execute().catch(DefaultErrorHandler.showDefaultErrorAlert);
                                    });
                                }} backgroundColor={ThemeManager.style.brandDanger}/>,
                        ],
                    });
                }
            }
        }
        Dialog.instance?.close();
    };
    const submitForm = (_e) => {
        if (currentLayout.title == null || currentLayout.title.length === 0) {
            const s = new Map();
            s.set('title', I18n.m.getMessage('required'));
            setFormError(s);
            return;
        }
        setFormError(new Map());
        saveForm().catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
    };
    return (<>
      {renderContent()}
      <DialogActions>
        <ContainedButton backgroundColor="#FFFFFF" textColor={ThemeManager.style.brandPrimary} onPress={() => {
            const hasChanges = props.layout == null || JSON.stringify(props.layout) !== JSON.stringify(currentLayout);
            if (hasChanges) {
                Routing.instance.alert.post({
                    text: I18n.m.getMessage('ticketLayoutDiscardChangesQuestion'),
                    buttons: [
                        <ContainedButton key="no" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close}/>,
                        <ContainedButton key="yes" title={I18n.m.getMessage('discard')} onPress={() => {
                                Alert.instance?.close();
                                Dialog.instance?.close();
                            }} backgroundColor={ThemeManager.style.brandDanger}/>,
                    ],
                });
            }
            else {
                Dialog.instance?.close();
            }
        }} title={I18n.m.getMessage('cancel')}/>
        <ContainedButton onPress={submitForm} title={props.layout ? I18n.m.getMessage('save') : I18n.m.getMessage('create')}/>
      </DialogActions>
    </>);
}
export const openAddOrChangeProjectTicketLayoutDialog = (props) => (e) => {
    const openPosition = { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY };
    Dialog.instance?.open({
        openPosition,
        width: 1024,
        closeOnTouchOutside: false,
        fullscreenResponsive: true,
        scrollable: false,
        contentPadding: false,
        content: <AddOrChangeProjectTicketLayoutDialog {...props}/>,
    });
};
