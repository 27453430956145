var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserMailsList = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _ErrorReporter = require("odatarepos/build/src/reporting/ErrorReporter");
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _AuthClient = require("upmesh-auth-core/build/src/client/AuthClient");
var _RemoveEmail = require("upmesh-auth-core/build/src/client/commands/user/RemoveEmail");
var _RequestValidationMail = require("upmesh-auth-core/build/src/client/commands/user/RequestValidationMail");
var _CurrentUser = require("upmesh-auth-core/build/src/client/CurrentUser");
var _Alert = require("../../../theme/components/Alert");
var _ContainedButton = require("../../../theme/components/button/ContainedButton");
var _ListItem = require("../../../theme/components/ListItem");
var _I18n = require("../../../theme/i18n/I18n");
var _Routing = require("../../../theme/routing/Routing");
var _ThemeManager = require("../../../theme/ThemeManager");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var UserMailsList = exports.UserMailsList = function (_PureComponent) {
  function UserMailsList(props, context) {
    var _this;
    (0, _classCallCheck2.default)(this, UserMailsList);
    _this = _callSuper(this, UserMailsList, [props, context]);
    _this.list = [];
    _this.deleteMail = function (mail) {
      return function (_e) {
        var _Alert$instance;
        _Routing.Routing.instance.alert.post({
          text: _I18n.I18n.m.getMessage('accountDeleteEmailQuestion'),
          buttons: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            title: _I18n.I18n.m.getMessage('cancel'),
            onPress: (_Alert$instance = _Alert.Alert.instance) == null ? void 0 : _Alert$instance.close
          }, "no"), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            title: _I18n.I18n.m.getMessage('delete'),
            onPress: _this.deleteMailNow(mail),
            backgroundColor: _ThemeManager.ThemeManager.style.brandDanger
          }, "yes")]
        });
      };
    };
    _this.deleteMailNow = function (mail) {
      return function (_e) {
        var _Alert$instance2;
        (_Alert$instance2 = _Alert.Alert.instance) == null ? void 0 : _Alert$instance2.close((0, _asyncToGenerator2.default)(function* () {
          try {
            var removeMail = new _RemoveEmail.RemoveEmail({
              userId: mail.userId
            }, mail.id);
            yield removeMail.execute(_AuthClient.AuthClient.instance.commandStore);
          } catch (e) {
            _ErrorReporter.ErrorReporter.sendReport({
              subject: 'deleteMailNow error',
              data: e,
              type: 'warn'
            });
            _Routing.Routing.instance.alert.post({
              text: _I18n.I18n.m.getMessage('emailRemovalError')
            });
          }
        }));
      };
    };
    _this.onChangeUserMails = function (_e) {
      _this.init().catch(function (err) {
        return void 0;
      });
    };
    _this.requestValidationMail = function (mailId) {
      return function () {
        var _ref2 = (0, _asyncToGenerator2.default)(function* (_e) {
          try {
            var r = new _RequestValidationMail.RequestValidationMail({}, mailId);
            yield r.execute(_AuthClient.AuthClient.instance.commandStore);
            _Routing.Routing.instance.alert.post({
              text: _I18n.I18n.m.getMessage('emailValidateSend')
            });
          } catch (e) {
            _Routing.Routing.instance.alert.post({
              text: _I18n.I18n.m.getMessage('emailRequestEmailValidationError')
            });
          }
        });
        return function (_x) {
          return _ref2.apply(this, arguments);
        };
      }();
    };
    _this.state = {
      init: false
    };
    return _this;
  }
  (0, _inherits2.default)(UserMailsList, _PureComponent);
  return (0, _createClass2.default)(UserMailsList, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      this.userMailsAttachKey = _AuthClient.AuthClient.instance.eventDispatcher.attach({
        readModelName: 'UserMails',
        callback: this.onChangeUserMails
      });
      this.init().catch(function (err) {
        return void 0;
      });
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (this.userMailsAttachKey != null) {
        _AuthClient.AuthClient.instance.eventDispatcher.detach('UserMails', this.userMailsAttachKey);
      }
    }
  }, {
    key: "render",
    value: function render() {
      var init = this.state.init;
      if (!init) {
        return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {});
      }
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          width: '100%',
          position: 'relative'
        },
        children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: Object.assign({}, _ThemeManager.ThemeManager.style.contentPadding),
          children: this.list
        }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: '100%',
            paddingTop: _ThemeManager.ThemeManager.style.contentPaddingValue / 4,
            paddingBottom: _ThemeManager.ThemeManager.style.contentPaddingValue / 4,
            paddingLeft: _ThemeManager.ThemeManager.style.contentPaddingValue,
            borderWidth: 0,
            borderColor: _ThemeManager.ThemeManager.style.borderColor,
            borderTopWidth: _ThemeManager.ThemeManager.style.borderWidth
          },
          children: (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            iconPosition: "left",
            icon: {
              icon: 'plus'
            },
            onPress: _Routing.Routing.instance.openDialog('addEmail'),
            full: false,
            title: _I18n.I18n.m.getMessage('accountEmailsAdd'),
            backgroundColor: "transparent",
            textColor: _ThemeManager.ThemeManager.style.brandPrimary
          })
        }, "add")]
      });
    }
  }, {
    key: "createMailList",
    value: function () {
      var _createMailList = (0, _asyncToGenerator2.default)(function* (userMails) {
        var list = [];
        var first = true;
        for (var mail of userMails) {
          var emailValidationButton = mail.validated !== true ? (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            backgroundColor: "transparent",
            textColor: _ThemeManager.ThemeManager.style.brandPrimary,
            title: _I18n.I18n.m.getMessage('accountEmailRequestValidateMail'),
            onPress: this.requestValidationMail(mail.id)
          }, "notValidated") : null;
          var removeMail = new _RemoveEmail.RemoveEmail({
            userId: mail.userId
          }, mail.id);
          var canI = yield removeMail.canI();
          var borderTop = first ? 0 : _ThemeManager.ThemeManager.style.borderWidth;
          list.push((0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
            style: {
              borderTopWidth: borderTop,
              borderWidth: 0,
              borderColor: _ThemeManager.ThemeManager.style.borderColor
            },
            children: [(0, _jsxRuntime.jsx)(_ListItem.ListItem, {
              title: mail.email,
              secondTextLine: mail.validated !== true ? _I18n.I18n.m.getMessage('accountEmailNotValidated') : undefined,
              iconRight: {
                icon: 'delete',
                disabled: canI !== true,
                toolTip: _I18n.I18n.m.getMessage('delete'),
                onPress: this.deleteMail(mail)
              }
            }), emailValidationButton]
          }, mail.id));
          first = false;
        }
        this.list = list;
      });
      function createMailList(_x2) {
        return _createMailList.apply(this, arguments);
      }
      return createMailList;
    }()
  }, {
    key: "init",
    value: function () {
      var _init = (0, _asyncToGenerator2.default)(function* () {
        var userMails = yield _AuthClient.AuthClient.instance.modals.userMails.get({
          filter: `deleted ne true and userId eq '${_CurrentUser.CurrentUser.userId}'`,
          orderby: 'validated DESC,email ASC'
        });
        yield this.createMailList(userMails);
        if (!this.state.init) {
          this.setState({
            init: true
          });
        } else {
          this.forceUpdate();
        }
      });
      function init() {
        return _init.apply(this, arguments);
      }
      return init;
    }()
  }]);
}(_react.PureComponent);