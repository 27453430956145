import _ from 'lodash';
import ImagePickerComp from 'materialTheme/src/file/ImagePicker';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Chip } from 'materialTheme/src/theme/components/chips/Chip';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { ScrollContainer } from 'materialTheme/src/theme/components/scroll/ScrollContainer';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { Keyboard, View } from 'react-native';
import { ArchiveTicket } from 'upmesh-core/src/client/commands/tickets/ArchiveTicket';
import { ChangeTicketStatus } from 'upmesh-core/src/client/commands/tickets/ChangeTicketStatus';
import { UnArchiveTicket } from 'upmesh-core/src/client/commands/tickets/UnArchiveTicket';
import { TicketStatus, TicketStatusColor } from 'upmesh-core/src/client/query/entities/TicketEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { DateLocale } from 'upmesh-i18n/src/i18n/DateLocale';
import { StoredStartPages } from 'materialTheme/src/utils/StoredStartPages';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CurrentProject } from '../project/CurrentProject';
import { CustomFields } from './ticketdetails/CustomFields';
import { SubTickets } from './ticketdetails/SubTickets';
import { TicketBuilder } from './ticketdetails/TicketBuilder';
import { TicketTitleInput } from './ticketdetails/TicketTextInputs';
import { TicketTypeChip } from './ticketdetails/TicketTypeChip';
import { UpTicket } from './ticketdetails/UpTicket';
import { ProjectImage } from '../project/ProjectImage';
export class TicketDetailsEditable extends PureComponent {
    componentDidMount() {
        this.checkData(this.props).catch((err) => console.debug(err));
        const promise = new Promise((resolve) => {
            resolve(ImagePickerComp.hasCamera());
        });
        promise
            .then((result) => {
            this.setState({ hasCam: result });
        })
            .catch(() => {
            this.setState({ hasCam: false });
        });
    }
    componentDidUpdate(_prevProps, _prevState, _snapshot) {
        if (!_.isEqual(this.props.ticket, _prevProps.ticket)) {
            this.checkData(this.props).catch((err) => console.debug(err));
        }
    }
    componentWillUnmount() {
        if (Keyboard != null) {
            Keyboard.dismiss();
        }
    }
    constructor(props) {
        super(props);
        this.gap = 16;
        this.changeToNextTicketState = () => {
            const { ticket } = this.state;
            if (ticket.archived != null && ticket.archived) {
                const c = new UnArchiveTicket({}, ticket.id);
                c.execute().catch((error) => {
                    DefaultErrorHandler.getDefaultErrorMessages(error, I18n.m, error);
                });
                return;
            }
            let nextState;
            switch (ticket.ticketStatus) {
                case TicketStatus.checked:
                    nextState = null;
                    break;
                case TicketStatus.closed:
                    nextState = TicketStatus.checked;
                    break;
                case TicketStatus.processing:
                    nextState = TicketStatus.closed;
                    break;
                default:
                    nextState = TicketStatus.processing;
            }
            if (nextState != null) {
                const c = new ChangeTicketStatus({ ticketStatus: nextState }, ticket.id);
                c.execute().catch((e) => {
                    if (e.messageCode != null && e.message != null && e.messageCode === 'ticketsDetailsErrorStatusChange') {
                        e.messageData.newState = I18n.m.getMessage(`ticketsDetailsState${TicketStatus[e.messageData.stateId]}`);
                    }
                    DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m);
                });
            }
            else {
                const c = new ArchiveTicket({}, ticket.id);
                c.execute().catch((error) => {
                    DefaultErrorHandler.showDefaultErrorAlert(error, I18n.m, error);
                });
            }
        };
        this.goToProject = () => {
            const { ticket } = this.state;
            Routing.instance.goTo(`/projects/${ticket.projectId}/${StoredStartPages.getStoredProjectStartPage()}`);
        };
        this.state = {
            isLoading: false,
            ticket: this.props.ticket,
            descriptionError: '',
            planVersion: undefined,
            init: props.ticket != null,
            hasCam: false,
        };
    }
    createSize() {
        const size = ResizeEvent.current;
        let fullscreen = true;
        let width = size.contentWidth;
        let height = size.contentHeight;
        if (width > ThemeManager.style.breakpointL) {
            fullscreen = false;
            width = 1024;
            height *= 0.9;
        }
        else if (width > ThemeManager.style.breakpointM) {
            fullscreen = false;
            width = Math.min(0.9 * width, 1024);
            height *= 0.9;
        }
        return {
            fullscreen,
            width: Math.round(width),
            height: Math.round(height),
            maxWidth: Math.round(width),
            maxHeight: Math.round(height),
            currentKeyboardHeight: size.currentKeyboardHeight,
        };
    }
    async checkData(props) {
        await DateLocale.waitFor(() => UpmeshClient.instance.ready);
        await DateLocale.waitFor(() => CurrentProject.instance.getCurrentProjectTeam().length > 0);
        const { ticket } = props;
        let planVersion;
        let plan;
        if (ticket.planId != null) {
            try {
                plan = await UpmeshClient.instance.modals.plan.getById(ticket.planId);
                if (plan.activePlanId !== 'MAP')
                    planVersion = await UpmeshClient.instance.modals.planVersion.getById(plan.activePlanId);
            }
            catch (e) {
                console.debug('cant load PlanVersion', e);
            }
        }
        this.setState({
            ticket,
            plan,
            planVersion,
            init: true,
        }, () => {
            this.forceUpdate();
        });
    }
    getNextTicketState() {
        const { ticket } = this.state;
        if (ticket.archived != null && ticket.archived) {
            return { nextState: I18n.m.getMessage('unarchiveTicketShort'), color: '#618081' };
        }
        switch (ticket.ticketStatus) {
            case TicketStatus.open:
                return { nextState: I18n.m.getMessage('ticketsDetailsStateprocessing'), color: TicketStatusColor.processing };
            case TicketStatus.processing:
                return { nextState: I18n.m.getMessage('ticketsDetailsStateclosed'), color: TicketStatusColor.closed };
            case TicketStatus.closed:
                return { nextState: I18n.m.getMessage('ticketsDetailsStatechecked'), color: TicketStatusColor.checked };
            default:
                return { nextState: I18n.m.getMessage('archiveTicketShort'), color: '#3A6073' };
        }
    }
    renderBottomBar() {
        const { contentWidth, addAttachment, openCam, splitView, size, fullscreen } = this.props;
        const { hasCam } = this.state;
        const { currentKeyboardHeight } = size;
        if (splitView)
            return null;
        const nextTicketState = this.getNextTicketState();
        return (<View style={{
                position: 'absolute',
                width: contentWidth,
                bottom: fullscreen
                    ? currentKeyboardHeight - 10
                    : Math.max(0, currentKeyboardHeight - 0.1 * ResizeEvent.current.contentHeight),
                borderColor: ThemeManager.style.borderColor,
                borderStyle: 'solid',
                borderWidth: 0,
                borderTopWidth: ThemeManager.style.borderWidth,
                backgroundColor: '#FFFFFF',
                height: ThemeManager.style.getScreenRelativePixelSize(56),
                alignItems: 'center',
                justifyContent: 'space-around',
                flexDirection: 'row',
            }}>
        <Icon toolTip={I18n.m.getMessage('ticketsDetailsAddAttachment')} icon="attachment" onPress={addAttachment}/>
        <View style={{ margin: 5, alignItems: 'stretch', paddingHorizontal: 0 }}>
          <ContainedButton title={nextTicketState.nextState} backgroundColor={nextTicketState.color} onPress={this.changeToNextTicketState}/>
        </View>
        <Icon toolTip={I18n.m.getMessage('tooltipOpenCamera')} icon="camera" onPress={openCam} disabled={!hasCam}/>
      </View>);
    }
    renderProjectTitle() {
        const { showProject, project } = this.props;
        if (!showProject)
            return null;
        return (<View style={{ marginBottom: this.gap, width: '100%' }}>
        <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('project')}</MaterialText>
        <Chip thumbnail={<ProjectImage size={24} project={project}/>} title={project.title} onPressChip={this.goToProject}/>
      </View>);
    }
    render() {
        const labelStyle = { marginTop: this.gap, width: '100%' };
        const { contentWidth, contentHeight, ticket, project, hasSubtickets } = this.props;
        if (ticket == null) {
            return (<View style={{ width: contentWidth, height: contentHeight, justifyContent: 'center' }}>
          <Spinner />
        </View>);
        }
        const { init } = this.state;
        if (!init) {
            return (<View style={{ width: contentWidth, height: contentHeight, justifyContent: 'center' }}>
          <Spinner />
        </View>);
        }
        const leftView = (<View>
        <ScrollContainer containerId="ticketDetails" key="ticketDetails" style={{ width: contentWidth, height: contentHeight, maxHeight: contentHeight }} contentContainerStyle={{ padding: ThemeManager.style.contentPaddingValue }}>
          {this.renderProjectTitle()}
          <View style={{ paddingBottom: 200 }}>
            <TicketTitleInput key={`${ticket.id}}`} ticket={ticket} errorResult={this.props.errorResult} gap={this.gap} titleError=""/>
            <TicketTypeChip labelStyle={labelStyle} ticket={ticket} errorResult={this.props.errorResult}/>
            <CustomFields errorResult={this.props.errorResult} ticket={ticket} project={project} labelStyle={labelStyle} plan={this.state.plan} planVersion={this.state.planVersion}/>
            {ticket != null && ticket.subTicketFrom != null && ticket.subTicketFrom.length > 0 && (<UpTicket ticket={ticket} labelStyle={labelStyle} canEditSubTickets={this.props.canEditSubTicktes}/>)}
            {hasSubtickets && ticket != null && (<SubTickets ticket={ticket} labelStyle={labelStyle} scrollContainer="ticketDetails" canEditSubTickets={this.props.canEditSubTicktes}/>)}
            <TicketBuilder labelStyle={labelStyle} ticket={ticket}/>
            <View style={{ height: this.gap }}/>
          </View>
        </ScrollContainer>
        {this.renderBottomBar()}
      </View>);
        return leftView;
    }
}
