import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { MaterialText } from 'materialTheme/src/theme/components/text/MaterialText';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { RouterControl } from 'materialTheme/src/theme/routing/RouterControl';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { UpgradeProject } from 'upmesh-core/src/client/commands/project/UpgradeProject';
import { ChangeUserRole } from 'upmesh-core/src/client/commands/user/ChangeUserRole';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
export class UpgradeButton extends PureComponent {
    constructor() {
        super(...arguments);
        this.openUpgradeProjectConfirmationDialog = () => {
            const { userSubscription } = this.props;
            let content = <View />;
            if (userSubscription === 'proAndEnterprise') {
                content = (<View>
          <MaterialText>{I18n.m.getMessage('upgradeProjectConfirmationMessageEnterpriseOrPro')}</MaterialText>
          <View style={{
                        flexDirection: 'row',
                        flexWrap: 'wrap-reverse',
                        width: '100%',
                        justifyContent: 'flex-end',
                        paddingTop: 8,
                    }}>
            <View style={{ paddingLeft: 16, paddingTop: 16 }}>
              <ContainedButton title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close} backgroundColor={ThemeManager.style.white87} textColor={ThemeManager.style.brandPrimary}/>
            </View>
            <View style={{ paddingLeft: 16, paddingTop: 16 }}>
              <ContainedButton title={I18n.m.getMessage('upgradeProjectConfirmationToProButton')} onPress={this.prepareUpgradeProject('pro')}/>
            </View>
            <View style={{ paddingLeft: 16, paddingTop: 16 }}>
              <ContainedButton title={I18n.m.getMessage('upgradeProjectConfirmationToEnterpriseButton')} onPress={this.prepareUpgradeProject('enterprise')}/>
            </View>
          </View>
        </View>);
            }
            else if (userSubscription === 'pro' || userSubscription === 'enterprise') {
                const text = userSubscription === 'enterprise'
                    ? 'upgradeProjectConfirmationMessageEnterprise'
                    : 'upgradeProjectConfirmationMessagePro';
                content = (<View>
          <MaterialText>{I18n.m.getMessage(text)}</MaterialText>
          <View style={{ flexDirection: 'row', justifyContent: 'flex-end', paddingTop: 8 }}>
            <ContainedButton title={I18n.m.getMessage('no')} onPress={Dialog.instance?.close} backgroundColor={ThemeManager.style.white87} textColor={ThemeManager.style.brandPrimary}/>
            <View style={{ paddingLeft: 16 }}/>
            <ContainedButton title={I18n.m.getMessage('yes')} onPress={this.prepareUpgradeProject(userSubscription === 'enterprise' ? 'enterprise' : 'pro')}/>
          </View>
        </View>);
            }
            RouterControl.instance.openUnRoutedDialog({
                content,
            });
        };
        this.prepareUpgradeProject = (version) => async (_e) => {
            Dialog.instance?.close();
            const { project } = this.props;
            if (project != null) {
                LoadingEvents.instance.startLoading();
                const members = await UpmeshClient.instance.modals.projectUser.get({
                    filter: `projectId eq '${project.id}' and  (status eq 'accepted' or status eq 'invited')`,
                });
                const downgradeList = [];
                for (const member of members) {
                    if (!(member.roleId === '2' || member.roleId === '3' || member.roleId === '4')) {
                        if (member.userId == null || member.userId.length === 0) {
                            downgradeList.push(member);
                        }
                        else {
                            const v = await RightsManager.hasVersion(member.userId);
                            if (v === 'basic') {
                                downgradeList.push(member);
                            }
                        }
                    }
                }
                LoadingEvents.instance.stopLoading();
                if (downgradeList.length > 0) {
                    this.showDowngradeList(downgradeList, version).catch((err) => console.debug(err));
                }
                else {
                    await this.upgradeProject(project, version);
                }
            }
        };
        this.downgradeUserRoles = (members, version) => async () => {
            Alert.instance?.close();
            LoadingEvents.instance.startLoading();
            for (const pu of members) {
                await new Promise((resolve) => {
                    UpmeshClient.eventDispatcher.attach({
                        readModelName: 'ProjectUser',
                        once: true,
                        entityId: pu.id,
                        callback: (_e) => {
                            resolve();
                        },
                    });
                    const e = new ChangeUserRole({ roleId: '4' }, pu.id);
                    e.execute().catch((err) => console.debug(err));
                });
            }
            this.prepareUpgradeProject(version)(null).catch((err) => console.debug(err));
        };
    }
    render() {
        const { project, userSubscription } = this.props;
        if (CurrentUser.userId === project.creator &&
            userSubscription !== 'basic' &&
            (project.projectSubscription == null || project.projectSubscription === 'basic')) {
            return (<ContainedButton title={I18n.m.getMessage('upgradeProject', {
                    context: userSubscription === 'enterprise' ? 'enterprise' : userSubscription === 'pro' ? 'pro' : '',
                })} onPress={this.openUpgradeProjectConfirmationDialog}/>);
        }
        return null;
    }
    async showDowngradeList(downgradeList, version) {
        let text = `${I18n.m.getMessage('projectUpgradeDowngradeUserHint')}\n\n`;
        for (const pu of downgradeList) {
            const getRole = await UpmeshClient.instance.modals.projectRoles.getById(pu.roleId);
            if (pu.userId != null && pu.userId.length > 0) {
                const getUser = await AuthClient.instance.modals.user.getById(pu.userId);
                text += `${getUser.firstname} ${getUser.lastname} (${I18n.m.getMessage(getRole.roleName)}) \n`;
            }
            else {
                text += `${pu.toEmail} (${I18n.m.getMessage(getRole.roleName)}) \n`;
            }
        }
        Routing.instance.alert.post({
            text,
            buttons: [
                <ContainedButton key="abortButton" onPress={Alert.instance?.close} title={I18n.m.getMessage('abort')} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
                <ContainedButton key="okButton" onPress={this.downgradeUserRoles(downgradeList, version)} title={I18n.m.getMessage('upgradeProject')}/>,
            ],
        });
    }
    async upgradeProject(project, version) {
        try {
            const up = new UpgradeProject({ project, version }, project.id);
            await up.execute();
        }
        catch (e) {
            console.debug('error', e);
            if (e['downgradeList'] != null) {
                this.showDowngradeList(e['downgradeList'], version).catch((err) => console.debug(err));
            }
        }
    }
}
