"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AuthClient = exports.ClientModals = void 0;
var CommandOfflineFirstStore_1 = require("cqrs-core/build/src/client/commands/CommandOfflineFirstStore");
var EventHandler_1 = require("cqrs-core/build/src/client/events/EventHandler");
var ReadModelChangedDefaultClientDispatcher_1 = require("cqrs-core/build/src/client/query/ReadModelChangedDefaultClientDispatcher");
var ReadModelClientOfflineFirst_1 = require("cqrs-core/build/src/client/query/ReadModelClientOfflineFirst");
var CommandRemoteStore_1 = require("cqrs-core/build/src/core/commands/CommandRemoteStore");
var ReadRemoteStore_1 = require("cqrs-core/build/src/core/query/ReadRemoteStore");
var SyncDispatcher_1 = require("cqrs-core/build/src/core/query/SyncDispatcher");
var ServerConnection_1 = require("cqrs-core/build/src/core/ServerConnection");
var WaitFor_1 = require("cqrs-shared/build/src/WaitFor");
var ts_events_1 = require("ts-events");
var LoginWithToken_1 = require("./commands/guest/LoginWithToken");
var Logout_1 = require("./commands/guest/Logout");
var OfflineCommands_1 = require("./commands/OfflineCommands");
var CurrentUser_1 = require("./CurrentUser");
var NetworkStatus_1 = require("./NetworkStatus");
var UserSettingsEntity_1 = require("./query/entities/UserSettingsEntity");
var User_1 = require("./query/User");
var UserAdress_1 = require("./query/UserAdress");
var UserMails_1 = require("./query/UserMails");
var UserSettings_1 = require("./query/UserSettings");
var ClientModals = (0, _createClass2.default)(function ClientModals() {
  (0, _classCallCheck2.default)(this, ClientModals);
  this.user = new User_1.User();
  this.userMails = new UserMails_1.UserMails();
  this.userAdress = new UserAdress_1.UserAdress();
  this.userSettings = new UserSettings_1.UserSettings();
});
exports.ClientModals = ClientModals;
var AuthClient = function () {
  function AuthClient() {
    var _this = this;
    (0, _classCallCheck2.default)(this, AuthClient);
    this.stayLoggedIn = true;
    this.deleteClientDB = false;
    this.initialized = false;
    this._eventDispatcher = ReadModelChangedDefaultClientDispatcher_1.ReadModelChangedDefaultClientDispatcher.instance;
    this._loggedIn = undefined;
    this._syncDispatcher = new SyncDispatcher_1.SyncDispatcher();
    this._userId = '';
    this.beforeLogoutFunctions = [];
    this.isInit = false;
    this.syncIntervalRoutine = function () {
      var _this$serverConnectio, _this$authConfig;
      if (_this.syncInterval != null) clearTimeout(_this.syncInterval);
      if (_this.loggedIn && (_this$serverConnectio = _this.serverConnection) != null && _this$serverConnectio.isConnected) {
        _this.startSync(true).catch(function (err) {
          return void 0;
        });
      }
      if (((_this$authConfig = _this.authConfig) == null ? void 0 : _this$authConfig.clientDB) != null) _this.syncInterval = setTimeout(_this.syncIntervalRoutine, 600000);
    };
    this.serverConnectionsChanged = new ts_events_1.AsyncEvent();
    this.onConnectionChanged = function () {
      _this.serverConnectionsChanged.post(_this.serverConnected());
    };
    this.validatedToken = '';
    this.validatingToken = '';
    this.validateToken = function () {
      var _ref = (0, _asyncToGenerator2.default)(function* (reason) {
        var _this$authConfig2;
        var token = _this.token;
        if (_this.validateTokenTimer != null) clearTimeout(_this.validateTokenTimer);
        if (((_this$authConfig2 = _this.authConfig) == null ? void 0 : _this$authConfig2.worker) == null) {
          if (token != null && token.length > 0 && token === _this.validatedToken) {
            _this._userId = _this.getUserIdFromToken(token);
            return;
          }
          if (token != null && token.length > 0 && _this.validatingToken === token) {
            return new Promise(function (resolve, reject) {
              setTimeout(function () {
                _this.validateToken(reason).then(resolve).catch(reject);
              }, 100);
            });
          }
          if (_this.commandStore != null && _this.serverConnection != null && _this.serverConnection.isConnected && token != null && token.length > 0) {
            _this.validatingToken = token;
            var loginWithToken = new LoginWithToken_1.LoginWithToken({
              token: token
            });
            try {
              yield loginWithToken.execute(_this.commandStore);
              _this.validatingToken = '';
              _this._userId = _this.getUserIdFromToken(token);
              _this.validatedToken = token;
            } catch (e) {
              _this.validatedToken = '';
              _this.validatingToken = '';
              if (e != null && e.messageCode != null && (e.messageCode === 'loginErrorNoAccount' || e.messageCode === 'loginError' || e.messageCode === 'accountMarkedForDeletion' || e.messageCode === 'accountLocked' || e.messageCode === 'loginErrorPasswordRequired' || e.messageCode === 'unauthorized' || e.messageCode === 'tokenError')) {
                _this.isInit = false;
                try {
                  yield _this.logOut(false);
                } catch (err) {}
                _this._userId = '';
                _this._loggedIn = false;
                yield _this.setToken({
                  token: '',
                  goTo: undefined
                });
                AuthClient.onLogoutEvent.post({
                  userId: ''
                });
                throw e;
              }
            }
          } else if (token != null && token.length > 0) {
            _this.validateTokenTimer = setTimeout(function () {
              return _this.validateToken(`(TO) ${reason}`);
            }, 300);
          }
        }
        _this._userId = _this.getUserIdFromToken(token);
      });
      return function (_x) {
        return _ref.apply(this, arguments);
      };
    }();
    this.registerOfflineCommands = false;
    this.onCommandError = function (e) {
      if (e.statusCode === 401) _this.validateToken('on command Error').catch(function (err) {
        return void 0;
      });
    };
    this.onServerConnection = function () {
      var _ref2 = (0, _asyncToGenerator2.default)(function* (_socket) {
        if (_this.token != null && _this.token.length > 0 && _this.commandStore != null && _this.initialized) {
          try {
            yield _this.startSync();
          } catch (e) {}
        }
      });
      return function (_x2) {
        return _ref2.apply(this, arguments);
      };
    }();
    this.onServerDisconnection = function () {
      _this.validatedToken = '';
      if (_this.authConfig == null) return;
      if (_this.authConfig.serverConnection != null) {
        _this.authConfig.serverConnection.onSyncShouldStop.post();
      }
    };
    this.getUserSettings = function () {
      var _ref3 = (0, _asyncToGenerator2.default)(function* (userId) {
        try {
          return yield AuthClient.instance.modals.userSettings.getById(_this.userId);
        } catch (e) {
          return new UserSettingsEntity_1.UserSettingsEntity({
            id: userId,
            userId: userId
          });
        }
      });
      return function (_x3) {
        return _ref3.apply(this, arguments);
      };
    }();
    this.setCurrentUser = function () {
      var _ref4 = (0, _asyncToGenerator2.default)(function* (e) {
        try {
          if (e != null && e.id != null) {
            if (_this.userId === e.id) {
              var settings = yield _this.getUserSettings(_this.userId);
              CurrentUser_1.CurrentUser.setUser(e, _this.token, settings);
            }
          } else if (_this.userId !== 'all' && _this.userId.length > 0) {
            try {
              yield WaitFor_1.WaitFor.instance.waitFor(function () {
                return _this.authConfig != null && (_this.authConfig.clientDB == null || _this.authConfig.clientDB != null && _this.authConfig.clientDB.initialized);
              });
              var u = yield _this.modals.user.getById(_this.userId);
              var _settings = yield _this.getUserSettings(_this.userId);
              CurrentUser_1.CurrentUser.setUser(u, _this.token, _settings);
            } catch (err) {
              yield _this.logOut(false);
              AuthClient.onLogoutEvent.post({
                userId: '',
                goTo: _this.goTo
              });
              throw err;
            }
          }
        } catch (err) {
          AuthClient.onLogoutEvent.post({
            userId: '',
            goTo: _this.goTo
          });
          throw e;
        }
      });
      return function (_x4) {
        return _ref4.apply(this, arguments);
      };
    }();
    this.setToken = function () {
      var _ref5 = (0, _asyncToGenerator2.default)(function* (data) {
        if (_this.authConfig == null) throw new Error('first set up authClient');
        if (_this.authConfig.loginEvents) {
          _this.goTo = data.goTo;
          if (_this.initialized) {
            if (_this.serverConnection.token !== data.token) {
              yield _this.serverConnection.changeAuthToken(data.token);
              yield _this.reInit();
            }
          } else {
            yield WaitFor_1.WaitFor.instance.waitFor(function () {
              return _this.initialized;
            });
            if (_this.initialized) yield _this.setToken(data);
          }
        }
      });
      return function (_x5) {
        return _ref5.apply(this, arguments);
      };
    }();
    this.startSync = (0, _asyncToGenerator2.default)(function* () {
      var start = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      try {
        var _this$authConfig$serv, _this$authConfig3;
        yield _this.validateToken('on start sync');
        if (_this.authConfig == null || !start || !_this.loggedIn || _this.authConfig.clientDB == null) return;
        (_this$authConfig$serv = _this.authConfig.serverConnection) == null ? void 0 : _this$authConfig$serv.dispatchSyncCouldStart();
        if (((_this$authConfig3 = _this.authConfig) == null ? void 0 : _this$authConfig3.worker) != null) {
          _this.syncDispatcher.postSyncStatus({
            running: true,
            percent: 1
          });
          _this.authConfig.worker.postToWorker('toLeaderµstartSync');
        } else if (typeof _this.commandStore['syncCommands'] === 'function') {
          try {
            yield _this.commandStore.syncCommands();
          } catch (err) {}
        }
      } catch (err) {}
    });
    if (AuthClient._instance != null) {
      throw new Error('use AuthClient.instance');
    }
  }
  return (0, _createClass2.default)(AuthClient, [{
    key: "eventDispatcher",
    get: function get() {
      return this._eventDispatcher;
    }
  }, {
    key: "localDb",
    get: function get() {
      if (this.authConfig != null && this.authConfig.clientDB != null) {
        return this.authConfig.clientDB.localDb;
      }
      return undefined;
    }
  }, {
    key: "loggedIn",
    get: function get() {
      return this._loggedIn === true;
    }
  }, {
    key: "modals",
    get: function get() {
      return this._modals;
    }
  }, {
    key: "serverConnection",
    get: function get() {
      if (this.authConfig != null) {
        return this.authConfig.serverConnection;
      }
      return null;
    }
  }, {
    key: "syncDispatcher",
    get: function get() {
      return this._syncDispatcher;
    }
  }, {
    key: "token",
    get: function get() {
      if (this.authConfig == null || this.authConfig.serverConnection == null || this.authConfig.serverConnection.token == null) {
        return '';
      }
      return this.authConfig.serverConnection.token;
    }
  }, {
    key: "ready",
    get: function get() {
      return this.initialized && !this.isInit;
    }
  }, {
    key: "registerBeforeLogout",
    value: function registerBeforeLogout(register) {
      this.beforeLogoutFunctions.push(register);
    }
  }, {
    key: "url",
    get: function get() {
      return this.authConfig != null ? this.authConfig.remoteUrl : '';
    }
  }, {
    key: "userId",
    get: function get() {
      return this._userId;
    }
  }, {
    key: "getUserIdFromToken",
    value: function getUserIdFromToken(token) {
      var _this$serverConnectio2,
        _this2 = this;
      var userId = '';
      var t = token != null && token.length > 0 ? token : (_this$serverConnectio2 = this.serverConnection) == null ? void 0 : _this$serverConnectio2.token;
      if (t != null && t.length > 1) {
        try {
          var uId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(t);
          if (uId == null || uId._id == null || uId._id.length === 0) {
            throw {
              key: '',
              messageCode: 'loginErrorWrongCredentials',
              message: 'Fehlerhafte Zugangsdaten'
            };
          }
          userId = uId._id;
        } catch (e) {
          this.logOut(false).then(function () {
            AuthClient.onLogoutEvent.post({
              userId: '',
              goTo: _this2.goTo
            });
          }).catch(function (err) {
            AuthClient.onLogoutEvent.post({
              userId: '',
              goTo: _this2.goTo
            });
          });
          throw {
            key: '',
            messageCode: 'loginErrorWrongCredentials',
            message: 'Fehlerhafte Zugangsdaten'
          };
        }
      }
      return userId;
    }
  }, {
    key: "close",
    value: function () {
      var _close = (0, _asyncToGenerator2.default)(function* () {
        if (this.serverConnection != null) yield this.serverConnection.close();
        if (this.authConfig != null && this.authConfig.clientDB != null) {
          var _this$authConfig$clie;
          yield (_this$authConfig$clie = this.authConfig.clientDB.localDb) == null ? void 0 : _this$authConfig$clie.closeDB();
        }
      });
      function close() {
        return _close.apply(this, arguments);
      }
      return close;
    }()
  }, {
    key: "hasWorker",
    value: function hasWorker() {
      return this.authConfig != null && this.authConfig.worker != null;
    }
  }, {
    key: "serverConnected",
    value: function serverConnected() {
      if (this.authConfig != null) {
        if (this.authConfig.worker != null) return this.authConfig.worker.serverConnected();
        if (this.authConfig.serverConnection != null) return this.authConfig.serverConnection.isConnected;
      }
      return false;
    }
  }, {
    key: "init",
    value: function () {
      var _init = (0, _asyncToGenerator2.default)(function* (authConfig, token) {
        var _this3 = this;
        yield WaitFor_1.WaitFor.instance.waitFor(function () {
          return !_this3.isInit;
        });
        this.isInit = true;
        this.authConfig = authConfig;
        if (this.authConfig.loginEvents == null) {
          this.authConfig.loginEvents = true;
        }
        if (this.authConfig.worker != null) {
          this.authConfig.serverConnection = this.authConfig.worker.getClientSC(token != null ? token : '', this.syncDispatcher);
          this.authConfig.clientDB = yield this.authConfig.worker.getClientDB(token != null ? this.getUserIdFromToken(token) : '');
          this.authConfig.loginEvents = true;
          this.authConfig.reInitDB = function () {
            var _ref7 = (0, _asyncToGenerator2.default)(function* (userId, deleteDB) {
              return _this3.authConfig.worker.getClientDB(userId, deleteDB);
            });
            return function (_x8, _x9) {
              return _ref7.apply(this, arguments);
            };
          }();
          yield this.authConfig.worker.initWorker(token != null ? token : '');
          this.authConfig.worker.serverConnectionsChanged.detach(this.onConnectionChanged);
          this.authConfig.worker.serverConnectionsChanged.attach(this.onConnectionChanged);
        } else if (this.authConfig.serverConnection == null) {
          this.authConfig.serverConnection = new ServerConnection_1.ServerConnection({
            remoteOptions: {
              apiUrl: authConfig.remoteUrl,
              authToken: token
            },
            autoConnect: false
          });
        } else {
          yield this.authConfig.serverConnection.changeAuthToken(token != null ? token : '');
        }
        this._userId = token != null ? this.getUserIdFromToken(token) : '';
        this.authConfig.serverConnection.onConnectionChanged.detach(this.onConnectionChanged);
        this.authConfig.serverConnection.onConnect.detach(this.onServerConnection);
        this.authConfig.serverConnection.onDisconnect.detach(this.onServerDisconnection);
        this.authConfig.serverConnection.onConnectionChanged.attach(this.onConnectionChanged);
        this.authConfig.serverConnection.onConnect.attach(this.onServerConnection);
        this.authConfig.serverConnection.onDisconnect.attach(this.onServerDisconnection);
        if (NetworkStatus_1.NetworkStatus.online && this.serverConnection != null && !this.serverConnection.isConnected) {
          yield this.connectToServer();
        }
        this.initialized = true;
        if (token != null && token.length > 0) {
          try {
            yield this.initWithToken(token);
          } catch (e) {
            yield this.initWithOutToken();
            this.isInit = false;
            return;
          }
        } else {
          yield this.initWithOutToken();
        }
        this.isInit = false;
      });
      function init(_x6, _x7) {
        return _init.apply(this, arguments);
      }
      return init;
    }()
  }, {
    key: "logIn",
    value: function () {
      var _logIn = (0, _asyncToGenerator2.default)(function* (result) {
        var _this$authConfig4;
        if ((_this$authConfig4 = this.authConfig) != null && _this$authConfig4.worker) {
          yield this.authConfig.worker.initWorker(result.token);
        }
        return this.setToken(result);
      });
      function logIn(_x10) {
        return _logIn.apply(this, arguments);
      }
      return logIn;
    }()
  }, {
    key: "logOut",
    value: function () {
      var _logOut = (0, _asyncToGenerator2.default)(function* (deleteClientDB, goToAfterLogout) {
        var _this4 = this;
        yield WaitFor_1.WaitFor.instance.waitFor(function () {
          return !_this4.isInit;
        });
        this.deleteClientDB = deleteClientDB;
        this.syncDispatcher.syncStop.post();
        yield WaitFor_1.WaitFor.instance.waitFor(function () {
          return !_this4.syncDispatcher.isSyncing;
        });
        if (this.authConfig != null && this.authConfig.serverConnection != null) {
          this.authConfig.serverConnection.onSyncShouldStop.post();
        }
        try {
          var promises = [];
          this.beforeLogoutFunctions.forEach(function (f) {
            return promises.push(f());
          });
          yield Promise.all(promises);
        } catch (e) {}
        this.beforeLogoutFunctions = [];
        try {
          if (this.serverConnection != null && this.serverConnection.isConnected) {
            var logout = new Logout_1.Logout({
              goTo: goToAfterLogout
            }, this.token);
            yield logout.execute(this.commandStore);
          }
        } catch (e) {}
        try {
          if (this.authConfig != null) this.authConfig.clientDB = yield this.authConfig.reInitDB(this.getUserIdFromToken(), deleteClientDB);
        } catch (e) {}
        yield this.setToken({
          token: '',
          goTo: goToAfterLogout
        });
      });
      function logOut(_x11, _x12) {
        return _logOut.apply(this, arguments);
      }
      return logOut;
    }()
  }, {
    key: "offlineCommandRepo",
    get: function get() {
      return this._offlineCommandRepo;
    }
  }, {
    key: "freshDatabase",
    value: function () {
      var _freshDatabase = (0, _asyncToGenerator2.default)(function* () {
        var _this$authConfig5;
        if (((_this$authConfig5 = this.authConfig) == null ? void 0 : _this$authConfig5.clientDB) == null) return false;
        try {
          var _this$authConfig$clie2;
          if (this.authConfig.worker != null) {
            var f = yield this.authConfig.worker.getFreshDatabase();
            return f;
          }
          if (((_this$authConfig$clie2 = this.authConfig.clientDB) == null ? void 0 : _this$authConfig$clie2.localDb) != null) {
            var offlineStaterepo = this.authConfig.clientDB.localDb.getRepos(new ReadModelClientOfflineFirst_1.ReadOfflineModel());
            if (offlineStaterepo) {
              var one = yield offlineStaterepo.get({
                top: 1,
                orderby: 'lastSync DESC'
              });
              if (one.length > 0) {
                var t = new Date();
                var thirtyDays = new Date(t.getFullYear(), t.getMonth(), t.getDate() - 30, 0, 0, 0);
                return one[0].lastSync.getTime() < thirtyDays.getTime();
              }
              return true;
            }
          }
        } catch (e) {
          return true;
        }
        return false;
      });
      function freshDatabase() {
        return _freshDatabase.apply(this, arguments);
      }
      return freshDatabase;
    }()
  }, {
    key: "resetModels",
    value: function () {
      var _resetModels = (0, _asyncToGenerator2.default)(function* () {
        var _this$authConfig6;
        if (this.modals != null && (_this$authConfig6 = this.authConfig) != null && _this$authConfig6.clientDB) {
          for (var key in this.modals) {
            var model = this.modals[key];
            yield model.reset();
          }
        }
      });
      function resetModels() {
        return _resetModels.apply(this, arguments);
      }
      return resetModels;
    }()
  }, {
    key: "initWithOutToken",
    value: function () {
      var _initWithOutToken = (0, _asyncToGenerator2.default)(function* () {
        this._userId = '';
        if (this.syncInterval != null) clearTimeout(this.syncInterval);
        CurrentUser_1.CurrentUser.setUser(undefined, '', new UserSettingsEntity_1.UserSettingsEntity({
          id: ''
        }));
        if (this.authConfig == null) return;
        this.authConfig.serverConnection.onSyncShouldStop.post();
        this.createCommandStore();
        if (this.remoteDB == null && this.commandStore != null) {
          this.remoteDB = new ReadRemoteStore_1.ReadRemoteStore(this.commandStore);
        }
        yield this.remoteDB.initDB();
        yield this.resetModels();
        if (this.authConfig != null && this.authConfig.clientDB != null) {
          this.authConfig.clientDB.initialized = true;
        }
        if (this.userListener != null) {
          this.eventDispatcher.detach('User', this.userListener);
          this.userListener = undefined;
        }
        if (this._loggedIn !== false) {
          this._loggedIn = false;
          AuthClient.onLogoutEvent.post({
            userId: '',
            goTo: this.goTo
          });
          if (this.goTo != null) {
            this.goTo = undefined;
          }
        }
      });
      function initWithOutToken() {
        return _initWithOutToken.apply(this, arguments);
      }
      return initWithOutToken;
    }()
  }, {
    key: "initWithToken",
    value: function () {
      var _initWithToken = (0, _asyncToGenerator2.default)(function* (token) {
        var _this$authConfig$clie3,
          _this$authConfig$clie4,
          _this$authConfig$clie5,
          _this$authConfig$clie6,
          _this5 = this;
        if (this.authConfig == null) return;
        this.createCommandStore();
        yield this.validateToken('on init');
        if (this._modals == null) {
          this._modals = new ClientModals();
        }
        if (this.remoteDB == null && this.commandStore != null) {
          this.remoteDB = new ReadRemoteStore_1.ReadRemoteStore(this.commandStore);
        }
        yield this.remoteDB.initDB();
        if (this.authConfig.clientDB == null) {}
        this._offlineCommandRepo = (_this$authConfig$clie3 = this.authConfig.clientDB) != null && _this$authConfig$clie3.localDb ? this.authConfig.clientDB.localDb.getRepos(new CommandOfflineFirstStore_1.CommandOfflineModel()) : this.remoteDB.getRepos(new CommandOfflineFirstStore_1.CommandOfflineModel());
        var repo = (_this$authConfig$clie4 = this.authConfig.clientDB) != null && _this$authConfig$clie4.localDb ? this.authConfig.clientDB.localDb.getRepos(this.modals.user.exampleEntity) : this.remoteDB.getRepos(this.modals.user.exampleEntity);
        var readModelConfig = {
          remoteDB: this.remoteDB,
          serverConnection: this.authConfig.serverConnection,
          syncDispatcher: this._syncDispatcher,
          repo: repo,
          events: EventHandler_1.EventHandler.defaultHandler,
          eventDispatcher: this._eventDispatcher,
          offlineStaterepo: (_this$authConfig$clie5 = this.authConfig.clientDB) == null ? void 0 : (_this$authConfig$clie6 = _this$authConfig$clie5.localDb) == null ? void 0 : _this$authConfig$clie6.getRepos(new ReadModelClientOfflineFirst_1.ReadOfflineModel())
        };
        for (var key in this.modals) {
          var _this$authConfig$clie7;
          var model = this.modals[key];
          var _repo = (_this$authConfig$clie7 = this.authConfig.clientDB) != null && _this$authConfig$clie7.localDb ? this.authConfig.clientDB.localDb.getRepos(model.exampleEntity) : this.remoteDB.getRepos(model.exampleEntity);
          yield model.init(Object.assign({}, readModelConfig, {
            repo: _repo
          }));
        }
        if (this.authConfig.clientDB) this.authConfig.clientDB.initialized = true;
        yield this.startSync(this.authConfig.startSync);
        if (this._loggedIn !== true) {
          this._userId = this.getUserIdFromToken(token);
          if (this.syncInterval != null) clearTimeout(this.syncInterval);
          this.syncInterval = setTimeout(this.syncIntervalRoutine, 300000);
          this._loggedIn = true;
          if (this.userId !== 'all') {
            yield this.setCurrentUser();
            if (this.userListener == null) {
              this.userListener = this.eventDispatcher.attach({
                readModelName: 'User',
                callback: function callback(r) {
                  r.entities.forEach(function (f) {
                    if (f.entity.id === _this5.userId) _this5.setCurrentUser(f.entity).catch(function (err) {
                      return void 0;
                    });
                  });
                }
              });
            }
          }
          AuthClient.onLoginEvent.post({
            token: token,
            userId: this.userId,
            goTo: this.goTo
          });
          if (this.goTo != null) {
            this.goTo = undefined;
          }
        }
      });
      function initWithToken(_x13) {
        return _initWithToken.apply(this, arguments);
      }
      return initWithToken;
    }()
  }, {
    key: "reInit",
    value: function () {
      var _reInit = (0, _asyncToGenerator2.default)(function* () {
        if (this.authConfig == null) return;
        if (this.authConfig.clientDB != null) {
          this.authConfig.clientDB.initialized = false;
        }
        try {
          this.authConfig.clientDB = yield this.authConfig.reInitDB(this.getUserIdFromToken(), this.deleteClientDB);
        } catch (e) {
          this.isInit = false;
          yield this.logOut(false);
          this._userId = '';
          throw e;
        }
        this.deleteClientDB = false;
        yield this.init(this.authConfig, this.token);
      });
      function reInit() {
        return _reInit.apply(this, arguments);
      }
      return reInit;
    }()
  }, {
    key: "connectToServer",
    value: function () {
      var _connectToServer = (0, _asyncToGenerator2.default)(function* () {
        var _this$authConfig$serv2;
        if (this.authConfig == null) return;
        yield (_this$authConfig$serv2 = this.authConfig.serverConnection) == null ? void 0 : _this$authConfig$serv2.connect();
      });
      function connectToServer() {
        return _connectToServer.apply(this, arguments);
      }
      return connectToServer;
    }()
  }, {
    key: "createCommandStore",
    value: function createCommandStore() {
      if (this.authConfig == null) return;
      var remoteStoreConfig = new CommandRemoteStore_1.CommandRemoteStoreConfig(this.authConfig.serverConnection, this._eventDispatcher);
      if (this.authConfig == null) return;
      if (this.authConfig.clientDB != null && this.authConfig.clientDB.localDb != null) {
        this.commandStore = new CommandOfflineFirstStore_1.CommandOfflineFirstStore({
          commandDb: this.authConfig.clientDB.localDb,
          startSyncOnConnection: false,
          remoteConfig: remoteStoreConfig,
          eventHandler: EventHandler_1.EventHandler.defaultHandler,
          serverConnection: this.authConfig.serverConnection,
          discardOnErrors: this.authConfig.discardOnErrors
        });
        this.syncDispatcher.setCommandStore(this.commandStore);
        this.commandStore.onError.attach(this.onCommandError);
        this.commandLocalStore = new CommandOfflineFirstStore_1.CommandOfflineFirstStore({
          commandDb: this.authConfig.clientDB.localDb,
          discardOnErrors: 10,
          startSyncOnConnection: false,
          eventHandler: EventHandler_1.EventHandler.defaultHandler,
          serverConnection: this.authConfig.serverConnection
        });
        if (!this.registerOfflineCommands) {
          OfflineCommands_1.OfflineCommands.registerOfflineCommands();
          this.registerOfflineCommands = true;
        }
      } else {
        this.commandStore = new CommandRemoteStore_1.CommandRemoteStore(remoteStoreConfig);
        this.syncDispatcher.setCommandStore(undefined);
        this.commandStore.onError.attach(this.onCommandError);
      }
    }
  }], [{
    key: "instance",
    get: function get() {
      if (AuthClient._instance == null) {
        AuthClient._instance = new AuthClient();
      }
      return AuthClient._instance;
    }
  }, {
    key: "onLogin",
    value: function onLogin(callback) {
      AuthClient.onLoginEvent.attach(callback);
    }
  }, {
    key: "onLoginDetach",
    value: function onLoginDetach(callback) {
      AuthClient.onLoginEvent.detach(callback);
    }
  }, {
    key: "onLogout",
    value: function onLogout(callback) {
      AuthClient.onLogoutEvent.attach(callback);
    }
  }, {
    key: "onLogoutDetach",
    value: function onLogoutDetach(callback) {
      AuthClient.onLogoutEvent.detach(callback);
    }
  }]);
}();
exports.AuthClient = AuthClient;
AuthClient.webClientId = '576732586755-pl5uu1469orb2cnubsc2ic317ip5mtir.apps.googleusercontent.com';
AuthClient.onLoginEvent = new ts_events_1.AsyncEvent();
AuthClient.onLogoutEvent = new ts_events_1.AsyncEvent();