import * as EmailValidator from 'email-validator';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { DialogActions } from 'materialTheme/src/theme/components/dialog/DialogActions';
import { DialogContent } from 'materialTheme/src/theme/components/dialog/DialogContent';
import { DialogTitle } from 'materialTheme/src/theme/components/dialog/DialogTitle';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { useEffect, useState } from 'react';
import { SendJournal } from 'upmesh-core/src/client/commands/project/journal/SendJournal';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
export function JournalSendDialog(props) {
    const [receivers, setReceivers] = useState([]);
    const onChangeReceivers = (chipReceivers) => {
        const updatedRecievers = [];
        for (const item of chipReceivers) {
            if (item.secondTextLine != null && item.secondTextLine.length > 0) {
                const tmp = item.secondTextLine.split(', ');
                if (tmp[0].length > 0) {
                    updatedRecievers.push(tmp[0]);
                }
            }
            else {
                updatedRecievers.push(item.title);
            }
        }
        setReceivers(updatedRecievers);
    };
    useEffect(() => {
        onChangeReceivers(props.receiverChips);
    }, []);
    const sendNow = async (_e) => {
        try {
            if (receivers != null && receivers.length > 0) {
                const send = new SendJournal({ journalId: props.journalDataId, receivers }, props.protocolId);
                await send.execute();
                await SimpleStorage.set(`lastJournalReceiver_${props.projectId}`, JSON.stringify(receivers));
            }
            Dialog.instance?.close();
        }
        catch (e) {
            DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m);
        }
    };
    return (<>
      <DialogTitle>{I18n.m.getMessage('journalSendQuestion')}</DialogTitle>
      <DialogContent>
        <ChipGroup label={I18n.m.getMessage('journalRecipient')} searchBarPlaceholder={I18n.m.getMessage('journalReceiverSearchOrAddNew')} availableChips={props.allReceivers} initChips={props.receiverChips} editable onChanged={onChangeReceivers} canAddNewChips addNewText={(email) => I18n.m.getMessage('journalAddNewReceiverMail', { email })} checkBeforeEnterNewText={(text) => !EmailValidator.validate(text)}/>
      </DialogContent>
      <DialogActions>
        <ContainedButton title={I18n.m.getMessage('journalSendCancel')} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} onPress={Dialog.instance?.close} key="cancel"/>
        <ContainedButton key="send" disabled={receivers.length === 0} onPress={sendNow} title={I18n.m.getMessage('send')}/>
      </DialogActions>
    </>);
}
export const openJournalSendDialog = (props) => (e) => {
    const openPosition = e != null ? { x: e.nativeEvent.pageX, y: e.nativeEvent.pageY } : undefined;
    Dialog.instance?.open({
        content: <JournalSendDialog {...props}/>,
        fullscreenResponsive: true,
        contentPadding: false,
        openPosition,
        openImmediately: false,
        closeOnTouchOutside: false,
    });
};
