"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LokiODataDB = void 0;
var Loki = require("../../../../../upmesh-client/node_modules/lokijs");
var ODataDB_1 = require("../../odata/root/ODataDB");
var ODataLokiDBRepo_1 = require("./ODataLokiDBRepo");
var LokiODataDB = function (_ODataDB_1$ODataDB) {
  function LokiODataDB() {
    var _this;
    (0, _classCallCheck2.default)(this, LokiODataDB);
    _this = _callSuper(this, LokiODataDB);
    _this.odataRepoClass = ODataLokiDBRepo_1.ODataLokiDBRepo;
    _this.init = false;
    if (LokiODataDB._mainInstance == null) {
      LokiODataDB._mainInstance = _this;
    }
    return _this;
  }
  (0, _inherits2.default)(LokiODataDB, _ODataDB_1$ODataDB);
  return (0, _createClass2.default)(LokiODataDB, [{
    key: "connected",
    get: function get() {
      return true;
    }
  }, {
    key: "addCollection",
    value: function addCollection(name, options) {
      return this.db.addCollection(name, options);
    }
  }, {
    key: "closeDB",
    value: function () {
      var _closeDB = (0, _asyncToGenerator2.default)(function* () {
        var _this2 = this;
        try {
          yield new Promise(function (resolve, reject) {
            _this2.db.close(function (err) {
              if (err != null) {
                reject(err);
              }
              resolve();
            });
          });
        } catch (e) {}
        return true;
      });
      function closeDB() {
        return _closeDB.apply(this, arguments);
      }
      return closeDB;
    }()
  }, {
    key: "getCollection",
    value: function getCollection(name) {
      return this.db.getCollection(name);
    }
  }, {
    key: "initDB",
    value: function () {
      var _initDB = (0, _asyncToGenerator2.default)(function* (options) {
        var _this3 = this;
        if (this.init) {
          return true;
        }
        if (options != null) {
          this.options = options;
          try {
            this.db = options.db != null ? options.db : new Loki(options.filename, options);
            var loaded = yield new Promise(function (resolve, reject) {
              _this3.db.loadDatabase(undefined, function (err) {
                if (err != null) {
                  reject(err);
                }
                resolve(true);
              });
            });
            this.fileHandler = this.options.fileHandler;
            this.init = loaded;
            return loaded;
          } catch (e) {
            throw e;
          }
        }
        return false;
      });
      function initDB(_x) {
        return _initDB.apply(this, arguments);
      }
      return initDB;
    }()
  }], [{
    key: "mainInstance",
    get: function get() {
      if (LokiODataDB._mainInstance == null) {
        LokiODataDB._mainInstance = new LokiODataDB();
      }
      return LokiODataDB._mainInstance;
    }
  }]);
}(ODataDB_1.ODataDB);
exports.LokiODataDB = LokiODataDB;