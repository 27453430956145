"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SyncDispatcher = void 0;
var WaitFor_1 = require("cqrs-shared/build/src/WaitFor");
var ts_events_1 = require("ts-events");
var SyncDispatcher = function () {
  function SyncDispatcher() {
    var _this = this;
    var maxParallelSyncs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 4;
    (0, _classCallCheck2.default)(this, SyncDispatcher);
    this.syncStart = new ts_events_1.AsyncEvent();
    this.syncStop = new ts_events_1.AsyncEvent();
    this.eventServerSyncStart = new ts_events_1.AsyncEvent();
    this.eventServerSyncStop = new ts_events_1.AsyncEvent();
    this.syncStatus = new ts_events_1.AsyncEvent();
    this.currentSyncStatus = {
      percent: 0,
      running: false
    };
    this.maxParallelSyncs = 4;
    this.syncs = [];
    this.currentSyncs = [];
    this.syncsRunning = [];
    this.postSyncStatus = function (status) {
      _this.currentSyncStatus = status;
      if (_this.commandStore != null) _this.commandStore.currentSyncStatus = status;
      _this.syncStatus.post(_this.currentSyncStatus);
    };
    this.sortSync = function (a, b) {
      var aDate = a.lastRun != null ? a.lastRun.getTime() : 0;
      var bDate = b.lastRun != null ? b.lastRun.getTime() : 0;
      if (aDate < bDate) {
        return -1;
      }
      if (aDate > bDate) {
        return 1;
      }
      return 0;
    };
    this.startTime = new Date();
    this.maxParallelSyncs = maxParallelSyncs;
  }
  return (0, _createClass2.default)(SyncDispatcher, [{
    key: "setCommandStore",
    value: function setCommandStore(commandStore) {
      this.commandStore = commandStore;
    }
  }, {
    key: "syncRunning",
    get: function get() {
      var _this$currentSyncStat;
      return (_this$currentSyncStat = this.currentSyncStatus) == null ? void 0 : _this$currentSyncStat.running;
    }
  }, {
    key: "isSyncing",
    get: function get() {
      return this.syncsRunning.length > 0;
    }
  }, {
    key: "registerSync",
    value: function registerSync(readModelName, startScript) {
      var _this2 = this;
      if (this.startSyncTO) clearTimeout(this.startSyncTO);
      var s = {
        readModelName: readModelName,
        startScript: startScript
      };
      var ind = this.syncs.findIndex(function (v) {
        return v.readModelName === readModelName;
      });
      if (ind === -1) {
        this.syncs.unshift(s);
        this.syncs.sort(this.sortSync);
      }
      this.startSyncTO = setTimeout(function () {
        _this2.startSync().catch(function (err) {
          return void 0;
        });
      }, 100);
    }
  }, {
    key: "startSync",
    value: function () {
      var _startSync = (0, _asyncToGenerator2.default)(function* () {
        var _this3 = this;
        this.startTime = new Date();
        if (this.syncStopTimeout != null) {
          clearTimeout(this.syncStopTimeout);
        }
        this.syncStart.post();
        yield WaitFor_1.WaitFor.instance.waitFor(function () {
          return _this3.areCommandsSynced();
        });
        if (!this.areCommandsSynced()) {
          setTimeout(function () {
            _this3.startSync().catch(function (err) {
              return void 0;
            });
          }, 100);
        }
        this.postSyncStatus({
          percent: 10,
          running: true
        });
        this.syncs.sort(this.sortSync);
        this.currentSyncs = (0, _toConsumableArray2.default)(this.syncs);
        this.startFreeSyncSlot().catch(function (err) {
          return void 0;
        });
      });
      function startSync() {
        return _startSync.apply(this, arguments);
      }
      return startSync;
    }()
  }, {
    key: "areCommandsSynced",
    value: function areCommandsSynced() {
      return this.commandStore == null || !this.commandStore.isSyncing;
    }
  }, {
    key: "startFreeSyncSlot",
    value: function () {
      var _startFreeSyncSlot = (0, _asyncToGenerator2.default)(function* () {
        if (this.syncStopTimeout != null) {
          clearTimeout(this.syncStopTimeout);
        }
        if (this.syncsRunning.length < this.maxParallelSyncs) {
          var s = this.currentSyncs.shift();
          if (s != null && this.syncsRunning.indexOf(s.readModelName) === -1) {
            this.syncsRunning.push(s.readModelName);
            try {
              yield s.startScript();
              this.syncFinished(s.readModelName);
            } catch (e) {
              this.syncFinished(s.readModelName);
            }
          }
          if (this.syncsRunning.length < this.maxParallelSyncs && this.currentSyncs.length > 0) {
            this.startFreeSyncSlot().catch(function (err) {
              return void 0;
            });
          }
          return true;
        }
        return false;
      });
      function startFreeSyncSlot() {
        return _startFreeSyncSlot.apply(this, arguments);
      }
      return startFreeSyncSlot;
    }()
  }, {
    key: "syncFinished",
    value: function syncFinished(readModelName) {
      var _this4 = this;
      var indexExecuting = this.syncsRunning.indexOf(readModelName);
      if (indexExecuting >= 0) {
        this.syncsRunning.splice(indexExecuting, 1);
      }
      var index = this.syncs.findIndex(function (v) {
        return v.readModelName === readModelName;
      });
      if (index >= 0) {
        this.syncs[index].lastRun = new Date();
      }
      var index2 = this.currentSyncs.findIndex(function (v) {
        return v.readModelName === readModelName;
      });
      if (index2 >= 0) {
        this.currentSyncs.splice(index, 1);
      }
      if (this.syncStopTimeout != null) {
        clearTimeout(this.syncStopTimeout);
      }
      if (this.currentSyncs.length === 0 && this.syncsRunning.length === 0) {
        this.syncStopTimeout = setTimeout(function () {
          if (!_this4.isSyncing && _this4.areCommandsSynced()) {
            _this4.syncStop.post();
            _this4.postSyncStatus({
              percent: 100,
              running: false
            });
          }
        }, 100);
      } else {
        var synced = this.syncs.length - this.currentSyncs.length;
        var percent = Math.ceil((synced + 10) / (this.syncs.length + 10) * 100);
        this.postSyncStatus({
          percent: percent,
          running: true
        });
        var currentS = [];
        this.currentSyncs.forEach(function (s) {
          return currentS.push(s.readModelName);
        });
        this.startFreeSyncSlot().catch(function (err) {
          return void 0;
        });
      }
    }
  }]);
}();
exports.SyncDispatcher = SyncDispatcher;