var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ClientDBadapter = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _lokijs = require("../../../../../upmesh-client/node_modules/lokijs");
var _incrementalIndexeddbAdapter = _interopRequireDefault(require("../../../../../upmesh-client/node_modules/lokijs/src/incremental-indexeddb-adapter"));
var ClientDBadapter = exports.ClientDBadapter = function () {
  function ClientDBadapter() {
    (0, _classCallCheck2.default)(this, ClientDBadapter);
  }
  return (0, _createClass2.default)(ClientDBadapter, null, [{
    key: "iDB",
    get: function get() {
      if (ClientDBadapter._i == null) {
        try {
          ClientDBadapter._i = indexedDB || msIndexedDB || mozIndexedDB || webkitIndexedDB || OIndexedDB;
        } catch (e) {}
      }
      return ClientDBadapter._i;
    }
  }, {
    key: "getAdapter",
    value: function () {
      var _getAdapter = (0, _asyncToGenerator2.default)(function* (_userId) {
        if (!ClientDBadapter.iDB) {
          ClientDBadapter.indexDB = false;
          return new _lokijs.LokiMemoryAdapter();
        }
        return new Promise(function (resolve) {
          var init = function init() {
            return new _incrementalIndexeddbAdapter.default('upmesh');
          };
          if (ClientDBadapter.tested) {
            resolve(init());
          } else {
            var dbTest = ClientDBadapter.iDB.open('test');
            dbTest.onerror = function (e) {
              ClientDBadapter.indexDB = false;
              resolve(new _lokijs.LokiMemoryAdapter());
            };
            dbTest.onsuccess = function () {
              ClientDBadapter.tested = true;
              try {
                ClientDBadapter.iDB.deleteDatabase('test');
                resolve(init());
              } catch (err) {
                ClientDBadapter.indexDB = false;
                resolve(new _lokijs.LokiMemoryAdapter());
              }
            };
          }
        });
      });
      function getAdapter(_x) {
        return _getAdapter.apply(this, arguments);
      }
      return getAdapter;
    }()
  }, {
    key: "deleteDatabase",
    value: function () {
      var _deleteDatabase = (0, _asyncToGenerator2.default)(function* (name) {
        return new Promise(function (resolve) {
          var request = ClientDBadapter.iDB.deleteDatabase(name);
          request.onsuccess = function (e) {
            resolve();
          };
          request.onblocked = function (e) {
            resolve();
          };
        });
      });
      function deleteDatabase(_x2) {
        return _deleteDatabase.apply(this, arguments);
      }
      return deleteDatabase;
    }()
  }, {
    key: "deleteDB",
    value: function () {
      var _deleteDB = (0, _asyncToGenerator2.default)(function* (userId) {
        if (userId.length > 0) {
          try {
            if (ClientDBadapter.indexDB) {
              yield ClientDBadapter.deleteDatabase(`${userId}.json`);
              yield ClientDBadapter.deleteDatabase('LokiCatalog');
              yield ClientDBadapter.deleteDatabase('test');
            }
          } catch (e) {}
        }
      });
      function deleteDB(_x3) {
        return _deleteDB.apply(this, arguments);
      }
      return deleteDB;
    }()
  }]);
}();
ClientDBadapter.indexDB = true;
ClientDBadapter.tested = false;