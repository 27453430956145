var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RegisterForm = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _AuthClient = require("upmesh-auth-core/build/src/client/AuthClient");
var _SignUpUser = require("upmesh-auth-core/build/src/client/commands/guest/SignUpUser");
var _ContainedButton = require("../../../theme/components/button/ContainedButton");
var _Checkbox = require("../../../theme/components/Checkbox");
var _Dialog = require("../../../theme/components/Dialog");
var _Form = require("../../../theme/components/forminput/Form");
var _Htextline = require("../../../theme/components/Htextline");
var _MaterialText = require("../../../theme/components/text/MaterialText");
var _Ripple = require("../../../theme/components/utils/Ripple");
var _I18n = require("../../../theme/i18n/I18n");
var _Routing = require("../../../theme/routing/Routing");
var _ThemeManager = require("../../../theme/ThemeManager");
var _AGB = require("../../AGB");
var _Privacy = require("../../Privacy");
var _SocialRegister = require("./SocialRegister");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var RegisterForm = exports.RegisterForm = function (_PureComponent) {
  function RegisterForm(props) {
    var _this;
    (0, _classCallCheck2.default)(this, RegisterForm);
    _this = _callSuper(this, RegisterForm, [props]);
    _this.onCheckChange = function (checkedTerms) {
      _this.setState({
        checkedTerms: checkedTerms
      });
    };
    _this.alertCheckTermsFirst = function () {
      _Routing.Routing.instance.alert.post({
        text: _I18n.I18n.m.getMessage('registerPrivacyTextAlert')
      });
    };
    _this.openPrivacy = function () {
      var _Dialog$instance;
      (_Dialog$instance = _Dialog.Dialog.instance) == null ? void 0 : _Dialog$instance.open({
        content: (0, _jsxRuntime.jsx)(_Privacy.Privacy, {}),
        scrollable: true,
        showCloseIcon: true
      });
    };
    _this.openAGB = function () {
      var _Dialog$instance2;
      (_Dialog$instance2 = _Dialog.Dialog.instance) == null ? void 0 : _Dialog$instance2.open({
        content: (0, _jsxRuntime.jsx)(_AGB.AGB, {}),
        scrollable: true,
        showCloseIcon: true
      });
    };
    _this.onSaved = function (e) {
      _this.props.onRegistered(Object.assign({}, e));
    };
    var email = '';
    var emailRegex = /mail=([^&;]*)/;
    if (_this.props.hash != null && _this.props.hash.match(emailRegex) != null) {
      var matchArray = _this.props.hash.match(emailRegex);
      if (matchArray != null && matchArray[1] != null) {
        email = matchArray[1];
      }
    } else if (props.email != null) {
      email = props.email;
    }
    _this.state = {
      email: email,
      checkedTerms: false
    };
    return _this;
  }
  (0, _inherits2.default)(RegisterForm, _PureComponent);
  return (0, _createClass2.default)(RegisterForm, [{
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (_reactNativeWeb.Keyboard != null) {
        _reactNativeWeb.Keyboard.dismiss();
      }
    }
  }, {
    key: "renderSocialRegister",
    value: function renderSocialRegister() {
      var checkedTerms = this.state.checkedTerms;
      return _reactNativeWeb.Platform.OS !== 'ios' ? (0, _jsxRuntime.jsx)(_SocialRegister.SocialRegister, {
        disabled: !checkedTerms
      }) : null;
    }
  }, {
    key: "render",
    value: function render() {
      var email = this.state.email != null ? this.state.email : '';
      var dataOptions = new Map();
      dataOptions.set('firstname', {
        formType: 'string',
        cols: 1,
        props: {
          labelText: _I18n.I18n.m.getMessage('firstName')
        }
      });
      dataOptions.set('lastname', {
        formType: 'string',
        cols: 1,
        props: {
          labelText: _I18n.I18n.m.getMessage('lastName')
        }
      });
      dataOptions.set('email', {
        formType: 'email',
        cols: 2,
        props: {
          labelText: _I18n.I18n.m.getMessage('email')
        }
      });
      var checkedTerms = this.state.checkedTerms;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          alignSelf: 'stretch',
          alignContent: 'center'
        },
        children: [(0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            alignSelf: 'stretch',
            paddingLeft: 0,
            overflow: 'visible',
            flexDirection: 'row'
          },
          children: [(0, _jsxRuntime.jsx)(_Checkbox.Checkbox, {
            onChange: this.onCheckChange,
            value: checkedTerms
          }), (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
            centeredBox: true,
            children: _I18n.I18n.m.getMessage('registerPrivacyText')
          })]
        }), (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            alignSelf: 'stretch',
            paddingLeft: 0,
            paddingBottom: 0,
            flexDirection: 'row',
            flexWrap: 'wrap',
            maxWidth: '100%'
          },
          children: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            title: _I18n.I18n.m.getMessage('termsOfUse'),
            onPress: this.openAGB,
            backgroundColor: "transparent",
            textColor: _ThemeManager.ThemeManager.style.brandPrimary
          }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              width: 8
            }
          }), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            title: _I18n.I18n.m.getMessage('privacyPolicy'),
            onPress: this.openPrivacy,
            backgroundColor: "transparent",
            textColor: _ThemeManager.ThemeManager.style.brandPrimary
          })]
        }), (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            alignSelf: 'stretch',
            padding: 0,
            position: 'relative'
          },
          children: [(0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
            style: {
              alignSelf: 'stretch',
              paddingLeft: 0
            },
            children: [(0, _jsxRuntime.jsx)(_Htextline.Htextline, {
              sitePadding: 0,
              children: _I18n.I18n.m.getMessage('and')
            }), this.renderSocialRegister()]
          }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              alignSelf: 'stretch',
              paddingLeft: 0
            },
            children: (0, _jsxRuntime.jsx)(_Form.Form, {
              baseCols: 2,
              dataOptions: dataOptions,
              store: _AuthClient.AuthClient.instance.commandStore,
              command: new _SignUpUser.SignUpUser({
                email: email,
                locale: _I18n.I18n.m.currentLanguage
              }),
              saveButtonLabel: _I18n.I18n.m.getMessage('loginRegister'),
              onSaved: this.onSaved
            })
          }), checkedTerms ? null : (0, _jsxRuntime.jsx)(_Ripple.Ripple, {
            accessibilityLabel: "Check Terms",
            backgroundColor: "rgba(255,255,255,0.75)",
            onPress: this.alertCheckTermsFirst,
            style: Object.assign({}, _ThemeManager.ThemeManager.style.absoluteStyle, {
              backgroundColor: 'rgba(255,255,255,0.5)',
              borderRadius: _ThemeManager.ThemeManager.style.borderRadius,
              marginRight: -8,
              overflow: 'visible'
            })
          })]
        })]
      });
    }
  }]);
}(_react.PureComponent);