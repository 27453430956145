import { WaitFor } from 'cqrs-shared/src/WaitFor';
import { UserImageSyncButton } from 'materialTheme/src/components/account/profile/UserImageSyncButton';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { ChipDialogForm } from 'materialTheme/src/theme/components/chips/ChipDialogForm';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { ListItem } from 'materialTheme/src/theme/components/ListItem';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { StoredStartPages } from 'materialTheme/src/utils/StoredStartPages';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { PureComponent } from 'react';
import { Platform, View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { CompanyUserInfo } from './CompanyUserInfo';
import { openSearch } from './globalSearch/GlobalSearch';
import { Logo } from './Logo';
import { NotificationBell } from './NotificationBell';
import { TimeTrackingChip } from './TimeTrackingChip';
export class GlobalBar extends PureComponent {
    constructor(props) {
        super(props);
        this.searchBar = null;
        this.onLayout = (_e) => {
            this.forceUpdate();
        };
        this.onChangeProject = (title, projectId) => () => {
            Dialog.instance?.close(() => {
                const { projectList } = this.state;
                const selectedItem = projectList.findIndex((project) => project.title === title);
                this.setState({ selectedItem, projectListIsOpen: false }, () => {
                    Routing.instance.goTo(`/projects/${projectId}/${StoredStartPages.getStoredProjectStartPage()}`);
                });
            });
        };
        this.init = async () => {
            const { currentProjectId } = this.props;
            await WaitFor.instance.waitFor(() => UpmeshClient.instance.modals.project != null);
            const [projects, version] = await Promise.all([
                UpmeshClient.instance.modals.project.get({
                    filter: 'deleted ne true and archived ne true and locked ne true',
                    orderby: 'title ASC',
                }),
                RightsManager.hasVersion(CurrentUser.userId),
            ]);
            const projectList = [];
            if (currentProjectId == null) {
                projectList.push({
                    title: I18n.m.getMessage('selectProject'),
                    onPress: this.closeDialog,
                });
            }
            let selectedItem = 0;
            if (projects != null && projects.length > 0) {
                for (let i = 0; i < projects.length; i += 1) {
                    projectList.push({
                        title: projects[i].title,
                        onPress: this.onChangeProject(projects[i].title, projects[i].id),
                    });
                    if (currentProjectId != null && projects[i].id === this.props.currentProjectId) {
                        selectedItem = i;
                    }
                }
            }
            const invitations = await UpmeshClient.instance.modals.projectUser.count(`userId eq '${CurrentUser.userId}' and status eq 'invited'`);
            this.setState({
                projectList,
                selectedItem,
                basicUser: version === 'basic' && CompanyUserInfo.company == null,
                invitations,
            });
        };
        this.onPressUpgradeButton = () => {
            Routing.instance.goTo('/subscriptionPlanView');
        };
        this.renderUpgradeButton = () => {
            const buttonVisible = Platform.OS === 'web' && this.state.basicUser === true;
            if (!buttonVisible) {
                return null;
            }
            return (<View style={{ alignSelf: 'center', marginRight: 16 }}>
        <ContainedButton radius={18} backgroundColor={ThemeManager.style.brandWarning} title={I18n.m.getMessage('upgradeProject')} icon={{ icon: 'rocket', iconSize: 32, outerSize: 32 }} onPress={this.onPressUpgradeButton}/>
      </View>);
        };
        this.openProjectList = () => {
            const { projectList, selectedItem } = this.state;
            Dialog.instance?.open({
                content: (<ChipDialogForm items={projectList} title={I18n.m.getMessage('projects')} onCancel={this.closeDialog} onSave={this.closeDialog} singleSelectSelected={selectedItem} canAddNewChips={false} showSelectedIcons={false}/>),
                onClose: () => this.setState({ projectListIsOpen: false }),
                contentPadding: false,
                fullscreenResponsive: true,
            });
            this.setState({ projectListIsOpen: true });
        };
        this.closeDialog = () => {
            Dialog.instance?.close();
        };
        this.openSearch = () => {
            if (this.searchBar != null)
                this.searchBar.blur();
            openSearch();
        };
        this.openLink = (link) => (_e) => {
            Routing.instance.goTo(`${link}`);
        };
        this.renderTab = (tab) => {
            const { currentProjectId, size, site } = this.props;
            const breakPoint = tab.timeTrackingModule ? 1100 : 800;
            const active = site != null ? tab.link.includes(site) : false;
            const textColor = active
                ? ThemeManager.style.brandPrimary
                : currentProjectId != null
                    ? ThemeManager.style.defaultIconColor
                    : '#FFFFFF';
            const backgroundColor = active ? 'rgba(255,255,255,0.8)' : 'transparent';
            if (size.windowWidth < breakPoint) {
                return (<View style={{
                        paddingHorizontal: 8,
                        justifyContent: 'center',
                        height: 36,
                        backgroundColor,
                        borderRadius: 18,
                    }}>
          <Icon toolTip={tab.title} color={textColor} icon={tab.icon} onPress={this.openLink(tab.link)} badge={tab.badge} accessibilityLabel={tab.accessibilityLabel}/>
        </View>);
            }
            return (<View style={{ height: '100%', justifyContent: 'center' }}>
        <Ripple hoverColor={currentProjectId != null ? ThemeManager.style.lisItemHoverColor : undefined} style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingRight: 8,
                    height: 36,
                    backgroundColor,
                    borderRadius: 24,
                }} accessibilityLabel={tab.accessibilityLabel} onPress={this.openLink(tab.link)}>
          <Icon toolTip={tab.title} color={textColor} icon={tab.icon} badge={tab.badge}/>
          <View>
            <MaterialText color={textColor}>{tab.title}</MaterialText>
          </View>
        </Ripple>
      </View>);
        };
        GlobalBar._instance = this;
        this.state = {
            projectList: [],
            selectedItem: 0,
            projectListIsOpen: false,
            invitations: 0,
        };
    }
    static update(p) {
        if (GlobalBar._instance != null) {
            GlobalBar._instance.update(p);
        }
    }
    update(p) {
        const projectList = [];
        projectList.push(...this.state.projectList);
        if (projectList.length >= this.state.selectedItem) {
            projectList[this.state.selectedItem] = { title: p.title, onPress: this.onChangeProject(p.title, p.id) };
            this.setState({ projectList }, () => {
                this.forceUpdate();
            });
        }
    }
    componentDidMount() {
        UpmeshClient.eventDispatcher.attach({
            readModelName: 'Project',
            attachKey: 'globalBar',
            callback: this.init,
        });
        UpmeshClient.eventDispatcher.attach({
            readModelName: 'ProjectUser',
            attachKey: 'globalBarUser',
            callback: this.init,
        });
        this.init().catch((err) => console.debug(err));
    }
    componentWillUnmount() {
        UpmeshClient.eventDispatcher.detach('Project', 'globalBar');
        UpmeshClient.eventDispatcher.detach('ProjectUser', 'globalBarUser');
        UpmeshClient.eventDispatcher.detach('TimeTracking', 'globalBar2');
    }
    static getMainLinksWithSavedFilter(mainLink) {
        const link = mainLink;
        let savedFilter = '?init=1';
        if (link === '/timeTracking') {
            const f = SimpleStorage.get(`${CurrentUser.userId}_saved_timetrackingfilter2`);
            if (f != null)
                savedFilter += `&ft=${f}`;
        }
        else if (link === '/absences') {
            const f = SimpleStorage.get(`${CurrentUser.userId}_saved_absencesfilter`);
            if (f != null)
                savedFilter += `&fab=${f}`;
        }
        else if (link === '/projects') {
            const f = SimpleStorage.get(`${CurrentUser.userId}_saved_projectfilter`);
            if (f != null)
                savedFilter += `&f=${f}`;
        }
        else if (link === '/allTickets') {
            const f = SimpleStorage.get(`${CurrentUser.userId}_saved_allticketsfilter`);
            if (f != null)
                savedFilter += `&fat=${f}`;
        }
        return `${mainLink}${savedFilter}`;
    }
    render() {
        const { currentProjectId, size, hideLogo } = this.props;
        const { projectListIsOpen, selectedItem, projectList, invitations } = this.state;
        const timeTrackingModule = CompanyUserInfo.companySettings != null && CompanyUserInfo.companySettings.hasModule('timeTracking');
        return (<View onLayout={this.onLayout} style={{
                height: ThemeManager.style.getScreenRelativePixelSize(48),
                width: '100%',
                justifyContent: 'space-between',
                alignContent: 'center',
                flexDirection: 'row',
                paddingLeft: 0,
                paddingRight: 0,
                backgroundColor: currentProjectId != null ? '#FFFFFF' : 'transparent',
                borderStyle: 'solid',
                borderWidth: 0,
                borderBottomWidth: 1,
                overflow: 'hidden',
                borderColor: currentProjectId != null ? ThemeManager.style.borderColor : 'transparent',
            }}>
        <View style={{
                width: 'auto',
                paddingLeft: hideLogo ? 0 : ThemeManager.style.contentPaddingValue,
                height: '100%',
                overflow: 'hidden',
                justifyContent: 'center',
            }}>
          <View style={{ flexDirection: 'row', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
            {!hideLogo && (<Ripple onPress={() => Routing.instance.goTo('/dashboard')} style={{ width: 40, height: 32, paddingRight: 8 }}>
                <Logo width={32} height={32}/>
              </Ripple>)}
            {this.renderTab({
                title: I18n.m.getMessage('dashboard'),
                icon: 'view-dashboard-outline',
                link: '/dashboard?init=1',
                accessibilityLabel: 'dashboard',
                timeTrackingModule,
            })}
            {this.renderTab({
                title: I18n.m.getMessage('projects'),
                icon: 'office-building',
                link: GlobalBar.getMainLinksWithSavedFilter('/projects'),
                accessibilityLabel: 'projects',
                timeTrackingModule,
                badge: invitations > 0 ? invitations.toString(10) : undefined,
            })}
            {this.renderTab({
                title: I18n.m.getMessage('allTickets'),
                link: GlobalBar.getMainLinksWithSavedFilter('/allTickets'),
                icon: 'check-circle-outline',
                accessibilityLabel: 'allTickets',
                timeTrackingModule,
            })}
            {timeTrackingModule &&
                this.renderTab({
                    title: I18n.m.getMessage('timeTracking'),
                    icon: 'timer-outline',
                    link: GlobalBar.getMainLinksWithSavedFilter('/timeTracking'),
                    accessibilityLabel: 'timeTracking',
                    timeTrackingModule,
                })}
            {timeTrackingModule &&
                this.renderTab({
                    title: I18n.m.getMessage('absences'),
                    icon: 'calendar-arrow-right',
                    link: GlobalBar.getMainLinksWithSavedFilter('/absences'),
                    accessibilityLabel: 'absences',
                    timeTrackingModule,
                })}
          </View>
        </View>

        <View style={{
                flex: 1,
                height: ThemeManager.style.getScreenRelativePixelSize(48),
                marginRight: ThemeManager.style.contentPaddingValue - ThemeManager.style.getScreenRelativePixelSize(6),
                justifyContent: 'flex-end',
                alignContent: 'center',
                flexDirection: 'row',
                alignItems: 'center',
            }}>
          {size.windowWidth > 1200 &&
                this.state.projectList != null &&
                this.state.projectList.length > 0 &&
                currentProjectId != null ? (<View style={{
                    flex: 1,
                    maxWidth: 350,
                    paddingLeft: ThemeManager.style.contentPaddingValue,
                    height: '100%',
                    overflow: 'hidden',
                }}>
              <ListItem iconRight={{
                    icon: projectListIsOpen ? 'menu-up' : 'menu-down',
                    toolTip: '',
                    accessibilityLabel: 'openMenu',
                    color: currentProjectId != null ? ThemeManager.style.defaultIconColor : '#FFFFFF',
                }} onPress={this.openProjectList} title={projectList[selectedItem].title} backgroundColor={currentProjectId != null ? '#FFFFFF' : 'transparent'} textColor={currentProjectId != null ? ThemeManager.style.black87 : '#FFFFFF'}/>
            </View>) : (<View style={{ alignSelf: 'center' }}>
              <MaterialText color={ThemeManager.style.headerButtonColor} type={MaterialTextTypes.H6}/>
            </View>)}
          <TimeTrackingChip />
          <Icon icon="magnify" toolTip={I18n.m.getMessage('globalSearch')} onPress={this.openSearch} color={currentProjectId != null ? ThemeManager.style.defaultIconColor : ThemeManager.style.white87}/>
          <NotificationBell iconColor={currentProjectId != null ? undefined : '#FFFFFF'}/>
          {this.renderUpgradeButton()}
          <UserImageSyncButton key="userImage" size={24} additionalMenuElements={TimeTrackingChip.getTimeTrackingMenuElement}/>
        </View>
      </View>);
    }
}
