"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TicketLayoutsEntity = void 0;
var ReadModelEntity_1 = require("cqrs-core/build/src/core/query/ReadModelEntity");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var TicketLayoutsEntity = function (_ReadModelEntity_1$Re) {
  function TicketLayoutsEntity(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, TicketLayoutsEntity);
    _this = _callSuper(this, TicketLayoutsEntity);
    _this.entityName = 'TicketLayouts';
    _this.fields = [];
    if (obj != null) _this.fill(obj);
    return _this;
  }
  (0, _inherits2.default)(TicketLayoutsEntity, _ReadModelEntity_1$Re);
  return (0, _createClass2.default)(TicketLayoutsEntity, [{
    key: "createOne",
    value: function createOne(obj) {
      return new TicketLayoutsEntity(obj);
    }
  }, {
    key: "fill",
    value: function fill(object) {
      return (0, _get2.default)((0, _getPrototypeOf2.default)(TicketLayoutsEntity.prototype), "fill", this).call(this, object);
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('title', new OdataEntity_1.OdataIndex());
      baseMap.set('onTypes', new OdataEntity_1.OdataIndex());
      baseMap.set('fields', new OdataEntity_1.OdataIndex());
      return baseMap;
    }
  }], [{
    key: "getIconName",
    value: function getIconName(field) {
      var _field$customField;
      var iconType = field.systemField ? field.systemField.toString() : (_field$customField = field.customField) == null ? void 0 : _field$customField.type;
      return iconType && TicketLayoutsEntity.iconByType[iconType] ? TicketLayoutsEntity.iconByType[iconType] : 'numeric';
    }
  }, {
    key: "defaultTicketLayout",
    value: function defaultTicketLayout() {
      return new TicketLayoutsEntity({
        id: '0',
        title: 'default',
        fields: [{
          id: '12',
          label: 'ticketsDetailsDescription',
          systemField: 'description'
        }, {
          id: '10',
          label: 'ticketsDetailsState',
          systemField: 'status'
        }, {
          id: '5',
          label: 'craft',
          systemField: 'craft'
        }, {
          id: '6',
          label: 'ticketsDetailsCompletionOn',
          systemField: 'completionOn'
        }, {
          id: '7',
          label: 'ticketsDetailsTitleAssignedToUser',
          systemField: 'assignee'
        }, {
          id: '8',
          label: 'ticketsDetailsTitleApprover',
          systemField: 'approver'
        }, {
          id: '9',
          label: 'ticketsDetailsTags',
          systemField: 'tags'
        }, {
          id: '11',
          label: 'ticketsDetailsPlan',
          systemField: 'plan'
        }]
      });
    }
  }, {
    key: "canBeAdded",
    value: function canBeAdded(field, currentLayout) {
      return field.systemField == null || !currentLayout.fields.find(function (a) {
        return a.systemField === field.systemField;
      });
    }
  }]);
}(ReadModelEntity_1.ReadModelEntity);
exports.TicketLayoutsEntity = TicketLayoutsEntity;
TicketLayoutsEntity.iconByType = {
  completionOn: 'calendar',
  assignee: 'account-outline',
  approver: 'check',
  tags: 'tag-outline',
  craft: 'wrench-outline',
  string: 'form-textbox',
  number: 'numeric',
  Date: 'calendar-outline',
  DateRange: 'calendar-range-outline',
  List: 'view-list-outline',
  MultiselectList: 'format-list-checks',
  CheckboxList: 'format-list-checkbox',
  person: 'account-outline',
  multiperson: 'account-multiple-outline',
  status: 'progress-check',
  plan: 'map-marker-outline',
  description: 'text-long'
};