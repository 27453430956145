var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ClientDBadapterAsyncStorage = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _lokijs = require("../../../../../upmesh-client/node_modules/lokijs");
var _SimpleStorage = require("odatarepos/build/src/db/SimpleStorage");
var _LokiReactNativeAsyncStorageAdapter = require("./LokiReactNativeAsyncStorageAdapter");
var ClientDBadapterAsyncStorage = exports.ClientDBadapterAsyncStorage = function () {
  function ClientDBadapterAsyncStorage() {
    (0, _classCallCheck2.default)(this, ClientDBadapterAsyncStorage);
  }
  return (0, _createClass2.default)(ClientDBadapterAsyncStorage, null, [{
    key: "getAdapter",
    value: function () {
      var _getAdapter = (0, _asyncToGenerator2.default)(function* (_userId) {
        return new _lokijs.LokiPartitioningAdapter(new _LokiReactNativeAsyncStorageAdapter.LokiReactNativeAsyncStorageAdapter(), {
          paging: true
        });
      });
      function getAdapter(_x) {
        return _getAdapter.apply(this, arguments);
      }
      return getAdapter;
    }()
  }, {
    key: "deleteDB",
    value: function () {
      var _deleteDB = (0, _asyncToGenerator2.default)(function* (userId) {
        try {
          var keys = yield _SimpleStorage.SimpleStorage.getAllKeys();
          for (var key of keys) {
            if (key.length > userId.length && key.substr(0, userId.length) === userId) {
              yield _SimpleStorage.SimpleStorage.remove(key);
            }
          }
        } catch (e) {}
        return new _lokijs.LokiPartitioningAdapter(new _LokiReactNativeAsyncStorageAdapter.LokiReactNativeAsyncStorageAdapter(), {
          paging: true
        });
      });
      function deleteDB(_x2) {
        return _deleteDB.apply(this, arguments);
      }
      return deleteDB;
    }()
  }]);
}();