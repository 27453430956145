"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeCompanyTicketLayout = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var StdApplicationError_1 = require("odatarepos/build/src/entities/StdApplicationError");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../../access/rights/RightsManager");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var CompanyTicketLayoutChanged_1 = require("../../../events/company/ticketlayout/CompanyTicketLayoutChanged");
var AddCompanyTicketLayout_1 = require("./AddCompanyTicketLayout");
var ChangeCompanyTicketLayout = function (_CommandRoot_1$Comman) {
  function ChangeCompanyTicketLayout(data, companyId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ChangeCompanyTicketLayout);
    _this = _callSuper(this, ChangeCompanyTicketLayout, [data, companyId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'ChangeCompanyTicketLayout';
    _this.waitForResult = false;
    return _this;
  }
  (0, _inherits2.default)(ChangeCompanyTicketLayout, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(ChangeCompanyTicketLayout, [{
    key: "getCompanySettings",
    value: function () {
      var _getCompanySettings = (0, _asyncToGenerator2.default)(function* () {
        if (this._cs == null) this._cs = yield CommandReadModels_1.CommandReadModels.companySettings.getById(this.data.companyId);
        return this._cs;
      });
      function getCompanySettings() {
        return _getCompanySettings.apply(this, arguments);
      }
      return getCompanySettings;
    }()
  }, {
    key: "getLayout",
    value: function () {
      var _getLayout = (0, _asyncToGenerator2.default)(function* () {
        var _this2 = this;
        if (this._layout == null) {
          var _p$ticketLayouts;
          var p = yield this.getCompanySettings();
          var l = (_p$ticketLayouts = p.ticketLayouts) == null ? void 0 : _p$ticketLayouts.find(function (a) {
            return a.id === _this2.entityId;
          });
          if (l == null) throw StdApplicationError_1.StdApplicationError.notFound();
          this._layout = l;
        }
        return this._layout;
      });
      function getLayout() {
        return _getLayout.apply(this, arguments);
      }
      return getLayout;
    }()
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        var company = yield this.getCompanySettings();
        if (yield RightsManager_1.RightsManager.isCompanyAdmin(userId, company.id)) return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        var d = {};
        var old = yield this.getLayout();
        var hasChanged = false;
        for (var k in this.data) {
          if (Array.isArray(this.data[k]) && JSON.stringify(this.data[k] !== JSON.stringify(old[k]))) {
            hasChanged = true;
            d[k] = this.data[k];
          } else if (this.data[k] !== old[k]) {
            hasChanged = true;
            d[k] = this.data[k];
          }
        }
        if (!hasChanged) return [];
        var events = [new CompanyTicketLayoutChanged_1.CompanyTicketLayoutChanged(this.entityId, d, this.data.companyId)];
        return events;
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {
        yield AddCompanyTicketLayout_1.AddCompanyTicketLayout.validateCustoms(this.data.fields);
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.ChangeCompanyTicketLayout = ChangeCompanyTicketLayout;