import { UserImage } from 'materialTheme/src/components/account/profile/UserImage';
import ImagePickerComp from 'materialTheme/src/file/ImagePicker';
import { ImagePickerOptions } from 'materialTheme/src/file/ImagePickerOptions';
import { Uploads } from 'materialTheme/src/file/upload/Uploads';
import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { FormInputFilled } from 'materialTheme/src/theme/components/forminput/FormInputFilled';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Menu } from 'materialTheme/src/theme/components/Menu';
import { Table } from 'materialTheme/src/theme/components/Table';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import * as mime from 'mime';
import { ErrorReporter } from 'odatarepos/src/reporting/ErrorReporter';
import React, { PureComponent } from 'react';
import { Linking, Platform, ScrollView, UIManager, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { CreateApiToken } from 'upmesh-core/src/client/commands/companies/apitoken/CreateApiToken';
import { RevokeApiToken } from 'upmesh-core/src/client/commands/companies/apitoken/RevokeApiToken';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import * as uuid from 'uuid';
import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { ConfigAll } from '../../config/ConfigAll';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
import { CompanyLogo } from './CompanyLogo';
const fetch = require('cross-fetch');
export class CompanyProfileView extends PureComponent {
    constructor(props) {
        super(props);
        this.onUpdateTokens = () => {
            this.loadTokens();
        };
        this.openUrl = (url) => (_e) => {
            Linking.openURL(url).catch((err) => console.debug(err));
        };
        this.tokenRenderer = (item, column) => {
            const additionalTextStyle = item.expired != null ? { textDecorationLine: 'line-through' } : undefined;
            return (<View key={`times_${item.id}_${uuid.v1()}`} style={[
                    {
                        justifyContent: 'center',
                        paddingHorizontal: 16,
                        alignItems: 'center',
                    },
                    column.style,
                ]}>
        <MaterialText centeredBox additionalTextStyle={additionalTextStyle} type={MaterialTextTypes.Body2} numberOfLines={1}>
          {item.token}
        </MaterialText>
      </View>);
        };
        this.createTokenQuestion = () => {
            this.apiNote = '';
            Dialog.instance?.open({
                title: I18n.m.getMessage('apiTokenAddQuestionCompany'),
                content: (<FormInputFilled labelText={I18n.m.getMessage('apiTokenAddQuestionNote')} onChangeText={this.onChangeApiNote}/>),
                buttons: [
                    <ContainedButton backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} key="cnacel" title={I18n.m.getMessage('cancel')} onPress={() => Dialog.instance?.close()}/>,
                    <ContainedButton key="save" title={I18n.m.getMessage('create')} onPress={this.createToken(true)}/>,
                ],
                contentPadding: true,
            });
        };
        this.onChangeApiNote = (t) => {
            this.apiNote = t;
        };
        this.createToken = (companyToken) => () => {
            Dialog.instance?.close(() => {
                const { company } = this.props;
                const t = new CreateApiToken({ companyToken, companyId: company.id, note: this.apiNote });
                t.execute(ClientStore.commandStore)
                    .then(() => setTimeout(this.loadTokens, 300))
                    .catch((e) => DefaultErrorHandler.showDefaultErrorAlert(e));
            });
        };
        this.loadTokens = () => {
            UpmeshClient.instance.modals.apiToken
                .get({ filter: `createdBy eq '${CurrentUser.userId}' and companyToken eq true` })
                .then((tokens) => {
                if (tokens.length > 0) {
                    const promises = [];
                    tokens.forEach((e) => {
                        promises.push(new Promise((resolve, reject) => {
                            AuthClient.instance.modals.user
                                .getById(e.createdBy)
                                .then((user) => {
                                resolve({ ...e, userName: user.getFullName() });
                            })
                                .catch(reject);
                        }));
                    });
                    return Promise.all(promises);
                }
                return [];
            })
                .then((apiTokens) => {
                this.setState({ apiTokens });
            })
                .catch((err) => console.debug('cant load tokens', err));
        };
        this.revokeTokenQuestion = (token) => () => {
            Routing.instance.alert.post({
                text: I18n.m.getMessage('apiTokenRevokeQuestion'),
                buttons: [
                    <ContainedButton key="abort" title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary}/>,
                    <ContainedButton backgroundColor={ThemeManager.style.brandDanger} key="save" title={I18n.m.getMessage('apiTokenRevoke')} onPress={this.revokeToken(token)}/>,
                ],
            });
        };
        this.revokeToken = (token) => () => {
            Alert.instance?.close(() => {
                const t = new RevokeApiToken({ companyId: token.companyId }, token.id);
                t.execute(ClientStore.commandStore)
                    .then(() => this.loadTokens)
                    .catch((e) => DefaultErrorHandler.showDefaultErrorAlert(e));
            });
        };
        this.copyTokenToClipboard = (token) => () => {
            try {
                navigator.clipboard
                    .writeText(token.token)
                    .then(() => {
                    alert(`Copied text: ${token.token}`);
                })
                    .catch((err) => {
                    alert(`cant copy to clipboard: ${err}`);
                });
            }
            catch (e) {
                alert(`cant copy to clipboard: ${e}`);
            }
        };
        this.setImagePicker = (r) => {
            this.imagePicker = r;
        };
        this.openEditMenu = (e) => {
            UIManager.measureInWindow(e.nativeEvent.target, (x, y, _width, height) => {
                const client = {
                    x,
                    y,
                    height,
                    width: 256,
                };
                const items = [
                    {
                        text: I18n.m.getMessage('changeCompanyImage'),
                        onPress: this.changeCompanyImage,
                    },
                ];
                const { company } = this.props;
                if (company != null && company.logo != null && company.logo.length > 0) {
                    items.unshift({
                        text: I18n.m.getMessage('deleteCompanyImage'),
                        onPress: this.onMenuPressDeleteCompanyImage,
                    });
                }
                Menu.instance?.open({
                    client,
                    items,
                });
            });
        };
        this.onMenuPressDeleteCompanyImage = (_e) => {
            Menu.instance?.close();
            Routing.instance.alert.post({
                text: I18n.m.getMessage('companyImageDeleteQuestion'),
                buttons: [
                    <ContainedButton key="no" onPress={Alert.instance?.close} title={I18n.m.getMessage('cancel')}/>,
                    <ContainedButton key="yes" onPress={this.deleteCompanyImageNow} title={I18n.m.getMessage('delete')} backgroundColor={ThemeManager.style.brandDanger}/>,
                ],
            });
        };
        this.deleteCompanyImageNow = (_e) => {
            Alert.instance?.close();
            const { company } = this.props;
            if (company != null) {
                const fetchUrl = `${ConfigAll.b2cURL}/company/${company.id}/logo`;
                const headers = {};
                headers['authorization'] = `Bearer ${CurrentUser.token}`;
                fetch(fetchUrl, { method: 'DELETE', headers })
                    .then((result) => {
                    if (result.status !== 200) {
                        DefaultErrorHandler.showDefaultErrorAlert(result.statusText);
                    }
                })
                    .catch((err) => {
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                });
            }
        };
        this.changeCompanyImage = (_e) => {
            Menu.instance?.close();
            if (this.imagePicker != null) {
                this.imagePicker.execute();
            }
        };
        this.onGotCompanyFile = async (response) => {
            const f = Array.isArray(response) ? response[0] : response;
            const { company } = this.props;
            if (company != null) {
                const ext = mime.getExtension(f.mime);
                if (ext == null) {
                    Routing.instance.alert.post({
                        text: I18n.m.getMessage('fileError'),
                    });
                    return;
                }
                const fileId = `${uuid.v1()}.${ext}`;
                try {
                    await Uploads.addUpload(`${ConfigAll.b2cURL}/company/${company.id}/logo/${fileId}`, f.webFile != null ? f.webFile : f, 'image', {}, 5, 1920);
                }
                catch (e) {
                    ErrorReporter.sendReport({ subject: 'cant add User Image', data: e, type: 'warn' });
                    Routing.instance.alert.post({
                        text: `${I18n.m.getMessage('imageUploadError')} ${e.toString()}`,
                    });
                }
            }
            const { fileIdCounter } = this.state;
            this.setState({ fileIdCounter: fileIdCounter + 1 });
        };
        this.onLayout = (_e) => {
            this.setState({
                imageSize: ResizeEvent.current.windowWidth > 400 ? 128 : 64,
                scrollHeight: ResizeEvent.current.contentHeight - 96,
            });
        };
        const isAdmin = CompanyUserInfo.me?.role === 'admin';
        this.state = {
            fileIdCounter: 0,
            imageSize: ResizeEvent.current.windowWidth > 400 ? 128 : 64,
            isAdmin,
            apiTokens: [],
            apiTokenNote: '',
            scrollHeight: ResizeEvent.current.contentHeight - 96,
        };
    }
    componentDidMount() {
        this.init()
            .then(() => {
            this.loadTokens();
        })
            .catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
        if (this.state.isAdmin)
            UpmeshClient.eventDispatcher.attach({
                readModelName: 'ApiToken',
                callback: this.onUpdateTokens,
                attachKey: 'companyProfileTokens',
            });
    }
    componentWillUnmount() {
        if (this.state.isAdmin)
            UpmeshClient.eventDispatcher.detach('ApiToken', 'companyProfileTokens');
    }
    async init() {
        const { company, companySettings } = this.props;
        let partner;
        let accountManager;
        if (company.partnerId != null) {
            try {
                partner = await UpmeshClient.instance.modals.upmeshPartner.getById(company.partnerId);
            }
            catch (err) {
                console.warn('cant get Upmesh Partner', err);
            }
        }
        if (companySettings.accountManager != null && companySettings.accountManager.length > 0) {
            try {
                accountManager = await AuthClient.instance.modals.user.getById(companySettings.accountManager);
            }
            catch (err) {
                console.warn('cant get accountManager', err);
            }
        }
        this.setState({ partner, accountManager });
    }
    renderContact() {
        const { partner, accountManager } = this.state;
        let supportHeader = 'upmesh';
        let phone = '+49 5451 59 33 6';
        let mail = 'moin@upmesh.de';
        if (partner) {
            supportHeader = partner.company;
            if (partner.supportEmail)
                mail = partner.supportEmail;
            else
                mail = partner.email;
            if (partner.supportPhone)
                phone = partner.supportPhone;
            else
                phone = partner.phone;
        }
        if (accountManager) {
            supportHeader = accountManager.getFullName();
            if (accountManager && accountManager.emails != null && accountManager.emails.length > 0) {
                mail = accountManager.emails[0];
            }
            if (accountManager && accountManager.phone && accountManager.phone.length > 0) {
                phone = accountManager.phone;
            }
        }
        return (<View style={{ width: '100%' }}>
        <View style={{ marginLeft: 8 + ThemeManager.style.contentPaddingValue, marginTop: 16 }}>
          <MaterialText type={MaterialTextTypes.H6}>{I18n.m.getMessage('contactPersons')}</MaterialText>
        </View>

        <Card style={{ width: '100%', padding: 16 }}>
          <View style={{ flexDirection: 'row', width: '100%', flexWrap: 'wrap' }}>
            {accountManager != null ? (<View style={{ marginRight: 16 }}>
                <UserImage size={128} user={accountManager}/>
              </View>) : (<View />)}
            <View style={{ flex: 1, minWidth: 150 }}>
              <MaterialText type={MaterialTextTypes.H6}>{supportHeader}</MaterialText>
              {supportHeader !== 'upmesh' ? (<MaterialText>{I18n.m.getMessage('accountManagerSubtitle')}</MaterialText>) : (<View />)}
              <View style={{ height: 16 }}/>
              <ChipGroup editable={false} canAddNewChips={false} borderProps={{
                borderWidth: ThemeManager.style.borderWidth,
                borderColor: ThemeManager.style.borderColor,
            }} backgroundColor="#ffffff" outlined chips={[
                { title: I18n.m.getMessage('accountManagerServicesOnboarding') },
                { title: I18n.m.getMessage('accountManagerServicesContractTopics') },
                { title: I18n.m.getMessage('accountManagerServicesLicenses') },
                { title: I18n.m.getMessage('accountManagerServicesTrainings') },
            ]}/>
              <ChipGroup editable={false} canAddNewChips={false} borderProps={{
                borderWidth: ThemeManager.style.borderWidth,
                borderColor: ThemeManager.style.borderColor,
            }} outlined backgroundColor="#ffffff" chips={[
                {
                    title: `📞 ${I18n.m.getMessage('accountManagerPhone').toUpperCase()}`,
                    onPressChip: this.openUrl(`tel:${phone}`),
                    backgroundColor: '#ffffff',
                },
                {
                    title: `📧 ${I18n.m.getMessage('accountManagerWriteMail').toUpperCase()}`,
                    onPressChip: this.openUrl(`mailto:${mail}`),
                    backgroundColor: '#ffffff',
                },
            ]}/>
            </View>
          </View>
        </Card>
      </View>);
    }
    render() {
        const { company } = this.props;
        const { fileIdCounter, imageSize, scrollHeight, isAdmin } = this.state;
        return (<ScrollView style={{ width: '100%', height: scrollHeight }}>
        <View style={{
                width: '100%',
                maxWidth: '100%',
                paddingTop: ThemeManager.style.contentPaddingValue,
                alignSelf: 'center',
                paddingHorizontal: 8,
                paddingBottom: 196,
            }}>
          <View style={{ marginLeft: 8 + ThemeManager.style.contentPaddingValue }} onLayout={this.onLayout}>
            <MaterialText type={MaterialTextTypes.H6}>{I18n.m.getMessage('companyProfile')}</MaterialText>
          </View>
          <Card style={{ width: '100%', padding: 16, minHeight: imageSize + 64 }}>
            <View style={{ position: 'relative', minHeight: imageSize + 64, width: '100%' }}>
              <View style={{ width: '100%', paddingRight: imageSize + 36 }}>
                <MaterialText type={MaterialTextTypes.Caption} color={ThemeManager.style.black54} numberOfLines={1}>
                  {I18n.m.getMessage('company')}
                </MaterialText>
                <MaterialText>{company.company}</MaterialText>
                <View style={{ height: 8 }}/>
                <MaterialText type={MaterialTextTypes.Caption} color={ThemeManager.style.black54} numberOfLines={1}>
                  {I18n.m.getMessage('companyAddress')}
                </MaterialText>
                <MaterialText>{`${company.street} ${company.streetNr}`}</MaterialText>
                <MaterialText>{`${company.zip} ${company.city}`}</MaterialText>
                <MaterialText>{company.country}</MaterialText>
              </View>
              <View style={{
                position: 'absolute',
                top: 0,
                right: 0,
            }}>
                <Icon toolTip={I18n.m.getMessage('changeCompanyImage')} icon="pencil-outline" onPress={company.logo != null && company.logo.length > 0 ? this.openEditMenu : this.changeCompanyImage} disabled={!isAdmin}/>
              </View>
              <View style={{
                position: 'absolute',
                top: 36,
                right: 36,
                width: imageSize,
                height: imageSize,
                overflow: 'hidden',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <CompanyLogo company={company} round={false} size={imageSize}/>
              </View>
            </View>
          </Card>
          {this.renderContact()}
          {this.renderApiTokens()}
          <ImagePickerComp ref={this.setImagePicker} options={new ImagePickerOptions({
                maxWidth: 1920,
                maxHeight: 1920,
                includeBase64: false,
                cropping: true,
                cropperCircleOverlay: false,
                dropMultiHandlerDialogText: I18n.m.getMessage('accountImageDropOnMultiSelect'),
                dropText: I18n.m.getMessage('accountImageDropHint'),
                selectMethod: 'any',
                includeExif: true,
                useFrontCamera: true,
                multiple: false,
                mediaType: 'photo',
                filePicketCompId: `companyImage_${fileIdCounter}`,
                compressImageQuality: 0.85,
            })} callBack={this.onGotCompanyFile}/>
        </View>
      </ScrollView>);
    }
    renderApiTokens() {
        const { isAdmin, apiTokens } = this.state;
        if (!isAdmin)
            return null;
        return (<View style={{ width: '100%' }}>
        <View style={{ marginLeft: 8 + ThemeManager.style.contentPaddingValue, marginTop: 16, flexDirection: 'row' }}>
          <MaterialText type={MaterialTextTypes.H6}>{I18n.m.getMessage('apiTokenTitle')}</MaterialText>
          <Icon icon="information-outline" toolTip="" outerSize={28} onPress={() => Alert.instance?.open({
                title: I18n.m.getMessage('apiTokenTitle'),
                content: I18n.m.getMessage('apiTokenAlert'),
                closeOnTouchOutside: true,
                closeFunction: () => Alert.instance?.close(),
                buttons: [
                    <ContainedButton backgroundColor="transparent" onPress={Alert.instance?.close} textColor={ThemeManager.style.brandPrimary} title={I18n.m.getMessage('ok')}/>,
                ],
            })}/>
        </View>

        <Card style={{ width: '100%' }}>
          <View style={{ width: '100%' }}>
            {apiTokens.length === 0 ? (<View />) : (<Table actionItemsLength={2} tableName="CompanyApiTokens" actions={(item) => {
                    const e = [
                        {
                            icon: 'delete',
                            onAction: this.revokeTokenQuestion,
                            disabled: item.expired != null,
                            toolTip: I18n.m.getMessage('apiTokenRevoke'),
                        },
                    ];
                    if (Platform.OS === 'web' && item.expired == null)
                        e.unshift({
                            icon: 'clipboard-text-outline',
                            onAction: this.copyTokenToClipboard,
                            toolTip: I18n.m.getMessage('apiTokenCopyToClipoard'),
                            disabled: false,
                        });
                    return e;
                }} maxHeight={0} data={apiTokens} sortBy="expired" sortDirection="desc" columns={[
                    {
                        title: I18n.m.getMessage('apiToken'),
                        keyInData: 'token',
                        cellRenderer: this.tokenRenderer,
                        style: { width: 200 },
                        sortable: true,
                        dataType: 'string',
                    },
                    {
                        title: I18n.m.getMessage('apiTokenAddQuestionNote'),
                        keyInData: 'note',
                        style: { width: 200 },
                        sortable: true,
                        dataType: 'string',
                    },
                    {
                        title: I18n.m.getMessage('creator'),
                        keyInData: 'userName',
                        style: { width: 200 },
                        sortable: true,
                        dataType: 'string',
                    },
                    {
                        title: I18n.m.getMessage('reportTicketCreated'),
                        keyInData: 'createdAt',
                        style: { width: 250 },
                        sortable: true,
                        dataType: 'DateWithTime',
                    },
                    {
                        title: I18n.m.getMessage('apiTokenRevoked'),
                        keyInData: 'expired',
                        style: { width: 200 },
                        sortable: true,
                        dataType: 'DateWithTime',
                    },
                ]}/>)}
            <View style={{ margin: 8, marginLeft: 16 }}>
              <ContainedButton title={I18n.m.getMessage('apiTokenAddText')} onPress={this.createTokenQuestion}/>
            </View>
          </View>
        </Card>
      </View>);
    }
}
