var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ImagePickerResponse = exports.ImagePickerOptions = exports.ImagePickerI = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _react = require("../../../../upmesh-client/node_modules/react");
var uuid = _interopRequireWildcard(require("uuid"));
var _I18n = require("../theme/i18n/I18n");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var ImagePickerOptions = exports.ImagePickerOptions = (0, _createClass2.default)(function ImagePickerOptions(obj) {
  (0, _classCallCheck2.default)(this, ImagePickerOptions);
  this.editModeEnabled = false;
  this.selectMethod = 'any';
  this.mediaType = 'photo';
  this.includeExif = true;
  this.compressImageQuality = 0.85;
  this.cropping = true;
  this.multiple = false;
  this.writeTempFile = true;
  this.forceJpg = true;
  for (var k in obj) {
    this[k] = obj[k];
  }
});
var ImagePickerResponse = exports.ImagePickerResponse = (0, _createClass2.default)(function ImagePickerResponse() {
  (0, _classCallCheck2.default)(this, ImagePickerResponse);
});
var ImagePickerI = exports.ImagePickerI = function (_PureComponent) {
  function ImagePickerI() {
    var _this;
    (0, _classCallCheck2.default)(this, ImagePickerI);
    _this = _callSuper(this, ImagePickerI, arguments);
    _this.defaultOptions = {
      mediaType: 'photo',
      includeExif: true,
      compressImageQuality: 0.85,
      cropperCancelText: _I18n.I18n.m.getMessage('cancel')
    };
    return _this;
  }
  (0, _inherits2.default)(ImagePickerI, _PureComponent);
  return (0, _createClass2.default)(ImagePickerI, [{
    key: "hasCamera",
    value: function () {
      var _hasCamera = (0, _asyncToGenerator2.default)(function* () {
        return false;
      });
      function hasCamera() {
        return _hasCamera.apply(this, arguments);
      }
      return hasCamera;
    }()
  }, {
    key: "render",
    value: function render() {
      if (this.props.children != null) {
        return this.props.children;
      }
      return null;
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        dragNdrop: true,
        options: new ImagePickerOptions({
          filePicketCompId: uuid.v1(),
          selectMethod: 'any',
          mediaType: 'photo',
          includeExif: true,
          includeBase64: false,
          compressImageQuality: 0.85,
          cropperCircleOverlay: false,
          multiple: false,
          cropping: false,
          dropText: 'Drop Files here',
          dropMultiHandlerDialogText: 'Drop Files here',
          forceJpg: true
        })
      };
    }
  }]);
}(_react.PureComponent);