"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Ticket = void 0;
var ReadModelClientOfflineFirst_1 = require("cqrs-core/build/src/client/query/ReadModelClientOfflineFirst");
var EventRootOdata_1 = require("cqrs-core/build/src/core/events/EventRootOdata");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var TicketEntity_1 = require("./entities/TicketEntity");
var Ticket = function (_ReadModelClientOffli) {
  function Ticket() {
    var _this;
    (0, _classCallCheck2.default)(this, Ticket);
    _this = _callSuper(this, Ticket, arguments);
    _this.exampleEntity = new TicketEntity_1.TicketEntity();
    _this.onEvents = [{
      eventName: 'TicketAssignedChanged',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'TicketCreated',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'TicketDescriptionChanged',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'TicketNumberChanged',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'TicketStatusChanged',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'TicketApproverChanged',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'TicketAssignerRemoved',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'TicketApproverRemoved',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'TicketTagsChanged',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'TicketTagAdded',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'TicketTagRemoved',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'TicketTitleChanged',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'TicketSubTicketFromChanged',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'TicketSubTicketFromRemoved',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'TicketForeignIdChanged',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'TicketCompletionOnChanged',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'TicketCompletionOnRemoved',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'TicketPlanAffiliationChanged',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'TicketPlanPositionChanged',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'TicketTypeChanged',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'TicketWatcherAdded',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'TicketWatcherRemoved',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'TicketArchiveStatusChanged',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'TicketDeleteStatusChanged',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'TicketPlanAffiliationRemoved',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'TicketCraftChanged',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'TicketCommentAdded',
      affect: 'one',
      identifier: 'data/ticketId'
    }, {
      eventName: 'TicketCommentChanged',
      affect: 'one',
      identifier: 'entityId'
    }, {
      eventName: 'TicketCustomFieldChanged',
      affect: 'one',
      identifier: 'entityId'
    }];
    return _this;
  }
  (0, _inherits2.default)(Ticket, _ReadModelClientOffli);
  return (0, _createClass2.default)(Ticket, [{
    key: "onTicketCompletionOnRemoved",
    value: function () {
      var _onTicketCompletionOnRemoved = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        entity.completionOn = null;
        return entity;
      });
      function onTicketCompletionOnRemoved(_x, _x2) {
        return _onTicketCompletionOnRemoved.apply(this, arguments);
      }
      return onTicketCompletionOnRemoved;
    }()
  }, {
    key: "onTicketApproverChanged",
    value: function () {
      var _onTicketApproverChanged = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        if (e.data.approverUserId === '0' || e.data.approverUserId === '') entity.approverUserId = null;
        return entity;
      });
      function onTicketApproverChanged(_x3, _x4) {
        return _onTicketApproverChanged.apply(this, arguments);
      }
      return onTicketApproverChanged;
    }()
  }, {
    key: "onTicketAssignedChanged",
    value: function () {
      var _onTicketAssignedChanged = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        if (e.data.assignedToUserId === '0' || e.data.assignedToUserId === '') entity.assignedToUserId = null;
        entity.assignedOn = e.createdAt;
        return entity;
      });
      function onTicketAssignedChanged(_x5, _x6) {
        return _onTicketAssignedChanged.apply(this, arguments);
      }
      return onTicketAssignedChanged;
    }()
  }, {
    key: "onTicketAssignerRemoved",
    value: function () {
      var _onTicketAssignerRemoved = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        entity.assignedToUserId = null;
        entity.assignedOn = e.createdAt;
        return entity;
      });
      function onTicketAssignerRemoved(_x7, _x8) {
        return _onTicketAssignerRemoved.apply(this, arguments);
      }
      return onTicketAssignerRemoved;
    }()
  }, {
    key: "onTicketApproverRemoved",
    value: function () {
      var _onTicketApproverRemoved = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        entity.approverUserId = null;
        return entity;
      });
      function onTicketApproverRemoved(_x9, _x10) {
        return _onTicketApproverRemoved.apply(this, arguments);
      }
      return onTicketApproverRemoved;
    }()
  }, {
    key: "onTicketPlanAffiliationRemoved",
    value: function () {
      var _onTicketPlanAffiliationRemoved = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        entity.planId = null;
        entity.planPositionX = null;
        entity.planPositionY = null;
        entity.planPositionZ = null;
        return entity;
      });
      function onTicketPlanAffiliationRemoved(_x11, _x12) {
        return _onTicketPlanAffiliationRemoved.apply(this, arguments);
      }
      return onTicketPlanAffiliationRemoved;
    }()
  }, {
    key: "onTicketWatcherAdded",
    value: function () {
      var _onTicketWatcherAdded = (0, _asyncToGenerator2.default)(function* (e, entity) {
        var watcherIds = entity.watchers == null ? [] : entity.watchers;
        if (watcherIds.findIndex(function (i) {
          return i.userId === e.data.userId;
        }) > -1) {
          return entity;
        }
        watcherIds.push({
          userId: e.data.userId
        });
        entity.watchers = watcherIds;
        return entity;
      });
      function onTicketWatcherAdded(_x13, _x14) {
        return _onTicketWatcherAdded.apply(this, arguments);
      }
      return onTicketWatcherAdded;
    }()
  }, {
    key: "onTicketWatcherRemoved",
    value: function () {
      var _onTicketWatcherRemoved = (0, _asyncToGenerator2.default)(function* (e, entity) {
        var watcherIds = [];
        if (entity.watchers != null) {
          for (var watcher of entity.watchers) {
            if (watcher.userId !== e.data.userId) {
              watcherIds.push(watcher);
            }
          }
        }
        entity.watchers = [].concat(watcherIds);
        return entity;
      });
      function onTicketWatcherRemoved(_x15, _x16) {
        return _onTicketWatcherRemoved.apply(this, arguments);
      }
      return onTicketWatcherRemoved;
    }()
  }, {
    key: "onTicketTypeChanged",
    value: function () {
      var _onTicketTypeChanged = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        entity.type = e.data.ticketType;
        return entity;
      });
      function onTicketTypeChanged(_x17, _x18) {
        return _onTicketTypeChanged.apply(this, arguments);
      }
      return onTicketTypeChanged;
    }()
  }, {
    key: "onTicketTagAdded",
    value: function () {
      var _onTicketTagAdded = (0, _asyncToGenerator2.default)(function* (e, entity) {
        var tags = entity.tags == null ? [] : entity.tags;
        if (tags.findIndex(function (i) {
          return i.tagName === e.data.tag.tagName;
        }) > -1) {
          return entity;
        }
        tags.push(e.data.tag);
        entity.tags = tags;
        return entity;
      });
      function onTicketTagAdded(_x19, _x20) {
        return _onTicketTagAdded.apply(this, arguments);
      }
      return onTicketTagAdded;
    }()
  }, {
    key: "onTicketTagRemoved",
    value: function () {
      var _onTicketTagRemoved = (0, _asyncToGenerator2.default)(function* (e, entity) {
        var tags = [];
        if (entity.tags != null) {
          for (var tag of entity.tags) {
            if (tag.tagName !== e.data.tag.tagName) {
              tags.push(tag);
            }
          }
        }
        entity.tags = [].concat(tags);
        return entity;
      });
      function onTicketTagRemoved(_x21, _x22) {
        return _onTicketTagRemoved.apply(this, arguments);
      }
      return onTicketTagRemoved;
    }()
  }, {
    key: "onTicketCommentAdded",
    value: function () {
      var _onTicketCommentAdded = (0, _asyncToGenerator2.default)(function* (e, _entity) {
        try {
          var correspondingTicket = yield this.getById(e.data.ticketId);
          if (e.data.comment != null && e.data.comment.length > 0) {
            if (correspondingTicket.commentCounter == null) {
              correspondingTicket.commentCounter = 1;
            } else {
              correspondingTicket.commentCounter += 1;
            }
          } else if (correspondingTicket.fileCounter == null) {
            correspondingTicket.fileCounter = 1;
          } else {
            correspondingTicket.fileCounter += 1;
          }
          correspondingTicket.lastModifiedAt = OdataEntity_1.OdataEntity.dateReviver('', e.createdAt);
          if (correspondingTicket.commentCounter < 0 || correspondingTicket.fileCounter < 0) {}
          return correspondingTicket;
        } catch (error) {}
        return undefined;
      });
      function onTicketCommentAdded(_x23, _x24) {
        return _onTicketCommentAdded.apply(this, arguments);
      }
      return onTicketCommentAdded;
    }()
  }, {
    key: "onTicketCommentChanged",
    value: function () {
      var _onTicketCommentChanged = (0, _asyncToGenerator2.default)(function* (e, _entity) {
        try {
          var ticketComments = ReadModels_1.ReadModels.get('TicketComments');
          var correspondingTicketComment = yield ticketComments.getById(e.entityId);
          var correspondingTicket = yield this.getById(correspondingTicketComment.ticketId);
          if (e.data.comment != null && e.data.attachedFileId != null && e.data.attachedFileType != null) {
            correspondingTicket.fileCounter -= 1;
          } else if (e.data.comment.length < 1) {
            correspondingTicket.commentCounter -= 1;
          } else if (correspondingTicketComment.comment != null && correspondingTicketComment.comment.length > 0) {
            return undefined;
          } else {
            correspondingTicket.commentCounter += 1;
          }
          correspondingTicket.lastModifiedAt = OdataEntity_1.OdataEntity.dateReviver('', e.createdAt);
          if (correspondingTicket.commentCounter < 0 || correspondingTicket.fileCounter < 0) {}
          return correspondingTicket;
        } catch (error) {}
        return undefined;
      });
      function onTicketCommentChanged(_x25, _x26) {
        return _onTicketCommentChanged.apply(this, arguments);
      }
      return onTicketCommentChanged;
    }()
  }, {
    key: "onTicketCustomFieldChanged",
    value: function () {
      var _onTicketCustomFieldChanged = (0, _asyncToGenerator2.default)(function* (e, ent) {
        if (ent == null) return undefined;
        var entity = yield this.defaultEventHandler(new EventRootOdata_1.EventRootOdata(Object.assign({}, e, {
          data: {}
        })), ent);
        if (entity.fields == null) entity.fields = [];
        var n = {
          id: e.data.fieldId,
          value: e.data.value,
          formattedValue: e.data.formattedValue,
          name: e.data.name,
          type: e.data.type
        };
        var found = false;
        entity.fields.forEach(function (a, index) {
          if (a.id === e.data.fieldId) {
            entity.fields[index] = n;
            found = true;
          }
        });
        if (!found) entity.fields.push(n);
        return entity;
      });
      function onTicketCustomFieldChanged(_x27, _x28) {
        return _onTicketCustomFieldChanged.apply(this, arguments);
      }
      return onTicketCustomFieldChanged;
    }()
  }, {
    key: "onTicketSubTicketFromChanged",
    value: function () {
      var _onTicketSubTicketFromChanged = (0, _asyncToGenerator2.default)(function* (e, ent) {
        if (ent == null) return undefined;
        if (ent.id === e.entityId) {
          var entity = yield this.defaultEventHandler(e, ent);
          try {
            var _this$config;
            var e2 = yield (_this$config = this.config) == null ? void 0 : _this$config.repo.getById(e.data.subTicketFrom);
            if (e2 != null) {
              var _this$config2;
              var count = yield (_this$config2 = this.config) == null ? void 0 : _this$config2.repo.count(`projectId eq '${e2.projectId}' and subTicketFrom eq '${e2.id}'`);
              if (count != null) {
                e2.subTicketsCounter = Math.max(0, count + 1);
                return [entity, e2];
              }
            }
          } catch (error) {}
          return entity;
        }
        return undefined;
      });
      function onTicketSubTicketFromChanged(_x29, _x30) {
        return _onTicketSubTicketFromChanged.apply(this, arguments);
      }
      return onTicketSubTicketFromChanged;
    }()
  }, {
    key: "onTicketSubTicketFromRemoved",
    value: function () {
      var _onTicketSubTicketFromRemoved = (0, _asyncToGenerator2.default)(function* (e, ent) {
        if (ent == null) return undefined;
        if (ent.id === e.entityId) {
          var entity = yield this.defaultEventHandler(e, ent);
          entity.subTicketFrom = '';
          try {
            var _this$config3;
            var e2 = yield (_this$config3 = this.config) == null ? void 0 : _this$config3.repo.getById(e.data.ticketId);
            if (e2 != null) {
              var _this$config4;
              var count = yield (_this$config4 = this.config) == null ? void 0 : _this$config4.repo.count(`projectId eq '${e2.projectId}' and subTicketFrom eq '${e2.id}'`);
              if (count != null) {
                e2.subTicketsCounter = Math.max(0, count - 1);
                return [entity, e2];
              }
            }
          } catch (error) {}
          return entity;
        }
        return undefined;
      });
      function onTicketSubTicketFromRemoved(_x31, _x32) {
        return _onTicketSubTicketFromRemoved.apply(this, arguments);
      }
      return onTicketSubTicketFromRemoved;
    }()
  }, {
    key: "onTicketCreated",
    value: function () {
      var _onTicketCreated = (0, _asyncToGenerator2.default)(function* (e, ent) {
        var entity = yield this.defaultEventHandler(e, ent);
        if (entity.subTicketFrom != null && this.config != null) {
          try {
            var e2 = yield this.config.repo.getById(entity.subTicketFrom);
            var count = yield this.config.repo.count(`projectId eq '${e2.projectId}' and subTicketFrom eq '${e2.id}'`);
            e2.subTicketsCounter = Math.max(0, count + 1);
            return [entity, e2];
          } catch (error) {}
        }
        return entity;
      });
      function onTicketCreated(_x33, _x34) {
        return _onTicketCreated.apply(this, arguments);
      }
      return onTicketCreated;
    }()
  }]);
}(ReadModelClientOfflineFirst_1.ReadModelClientOfflineFirst);
exports.Ticket = Ticket;