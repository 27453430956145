var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DefaultHeaderContent = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-client/node_modules/react-native-web");
var _ContainedButton = require("../../theme/components/button/ContainedButton");
var _Imprint = require("../../components/Imprint");
var _ButtonGroup = require("../../theme/components/button/ButtonGroup");
var _Dialog = require("../../theme/components/Dialog");
var _Icon = require("../../theme/components/Icon");
var _Menu = require("../../theme/components/Menu");
var _Ripple = require("../../theme/components/utils/Ripple");
var _I18n = require("../../theme/i18n/I18n");
var _LanguagePicker = require("../../theme/i18n/LanguagePicker");
var _ThemeManager = require("../../theme/ThemeManager");
var _jsxRuntime = require("../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var DefaultHeaderContent = exports.DefaultHeaderContent = function (_PureComponent) {
  function DefaultHeaderContent(props) {
    var _this;
    (0, _classCallCheck2.default)(this, DefaultHeaderContent);
    _this = _callSuper(this, DefaultHeaderContent, [props]);
    _this.openMenu = function (e) {
      _reactNativeWeb.UIManager.measureInWindow(e.nativeEvent.target, function (x, y, _width, height) {
        var _Menu$instance;
        var client = {
          x: x,
          y: y,
          height: height,
          width: 256
        };
        (_Menu$instance = _Menu.Menu.instance) == null ? void 0 : _Menu$instance.open({
          client: client,
          items: [{
            text: _I18n.I18n.m.getMessage('helpArea'),
            onPress: function onPress() {
              return _this.openUrl('https://upmesh.freshdesk.com/support/solutions');
            },
            thumbnail: {
              thumbnail: (0, _jsxRuntime.jsx)(_Icon.Icon, {
                toolTip: _I18n.I18n.m.getMessage('helpArea'),
                icon: "help-circle-outline"
              }),
              width: 24
            }
          }, {
            text: _I18n.I18n.m.getMessage('statusPage'),
            onPress: function onPress() {
              return _this.openUrl('https://status.upmesh.de');
            },
            thumbnail: {
              thumbnail: (0, _jsxRuntime.jsx)(_Icon.Icon, {
                toolTip: _I18n.I18n.m.getMessage('statusPage'),
                icon: "web-check"
              }),
              width: 24
            }
          }, {
            text: _I18n.I18n.m.getMessage('impressum'),
            onPress: _this.openImprint
          }, {
            text: _I18n.I18n.m.getMessage('language'),
            onPress: _this.onLanguagePress
          }]
        });
      });
    };
    _this.openImprint = function () {
      var _Menu$instance2, _Dialog$instance;
      (_Menu$instance2 = _Menu.Menu.instance) == null ? void 0 : _Menu$instance2.close();
      (_Dialog$instance = _Dialog.Dialog.instance) == null ? void 0 : _Dialog$instance.open({
        content: (0, _jsxRuntime.jsx)(_Imprint.Imprint, {}),
        scrollable: true,
        showCloseIcon: true
      });
    };
    _this.onLangChanged = function () {
      var _Dialog$instance2;
      (_Dialog$instance2 = _Dialog.Dialog.instance) == null ? void 0 : _Dialog$instance2.close();
    };
    _this.onLanguagePress = function () {
      var _Menu$instance3, _Dialog$instance3;
      (_Menu$instance3 = _Menu.Menu.instance) == null ? void 0 : _Menu$instance3.close();
      (_Dialog$instance3 = _Dialog.Dialog.instance) == null ? void 0 : _Dialog$instance3.open({
        title: 'Language',
        content: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: '100%',
            position: 'relative'
          },
          children: (0, _jsxRuntime.jsx)(_LanguagePicker.LanguagePicker, {
            onChanged: _this.onLangChanged
          })
        })
      });
    };
    _this.onResize = function (e) {
      _this.setState({
        width: e.nativeEvent.layout.width
      });
    };
    _this.openUrl = function (url) {
      return function (_e) {
        var _Menu$instance4;
        _reactNativeWeb.Linking.openURL(url).catch(function (err) {
          return void 0;
        });
        (_Menu$instance4 = _Menu.Menu.instance) == null ? void 0 : _Menu$instance4.close();
      };
    };
    _this.state = {
      width: _reactNativeWeb.Dimensions.get('window').width
    };
    return _this;
  }
  (0, _inherits2.default)(DefaultHeaderContent, _PureComponent);
  return (0, _createClass2.default)(DefaultHeaderContent, [{
    key: "render",
    value: function render() {
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          width: '100%',
          alignItems: 'flex-end'
        },
        onLayout: this.onResize,
        children: this.renderMenu()
      });
    }
  }, {
    key: "renderMenu",
    value: function renderMenu() {
      if (this.state.width > 300) {
        return (0, _jsxRuntime.jsxs)(_ButtonGroup.ButtonGroup, {
          align: "flex-end",
          marginRight: 24,
          direction: "horizontal",
          children: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            onPress: this.onLanguagePress,
            icon: {
              icon: 'translate',
              toolTip: 'language'
            },
            title: "",
            backgroundColor: "transparent",
            textColor: _ThemeManager.ThemeManager.style.brandPrimary
          }), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            onPress: this.openImprint,
            title: _I18n.I18n.m.getMessage('impressum'),
            backgroundColor: "transparent",
            textColor: _ThemeManager.ThemeManager.style.brandPrimary
          })]
        });
      }
      return (0, _jsxRuntime.jsx)(_Ripple.Ripple, {
        onPress: this.openMenu,
        backgroundColor: "rgba(255,255,255,0)",
        hoverColor: _ThemeManager.ThemeManager.style.getDefaultHoverColor(),
        style: {
          width: 30,
          height: 30,
          borderRadius: 15,
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          paddingTop: 5
        },
        children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: 15,
            height: 15,
            borderRadius: 8,
            justifyContent: 'center',
            alignItems: 'center'
          },
          children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
            toolTip: "",
            icon: "dots-vertical",
            color: "black"
          })
        }, "dropDownIcon")
      });
    }
  }]);
}(_react.PureComponent);