var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WidgetItem = WidgetItem;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _I18n = require("../../i18n/I18n");
var _Card = require("../Card");
var _Icon = require("../Icon");
var _Menu = require("../Menu");
var _Measurement = require("../utils/Measurement");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function WidgetItem(props) {
  var _useState = (0, _react.useState)(),
    _useState2 = (0, _slicedToArray2.default)(_useState, 2),
    moveStart = _useState2[0],
    setMoveStart = _useState2[1];
  var _useState3 = (0, _react.useState)(),
    _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
    lastMove = _useState4[0],
    setLastMove = _useState4[1];
  var _useState5 = (0, _react.useState)(),
    _useState6 = (0, _slicedToArray2.default)(_useState5, 2),
    resizeStart = _useState6[0],
    setResizeStart = _useState6[1];
  var _useState7 = (0, _react.useState)(),
    _useState8 = (0, _slicedToArray2.default)(_useState7, 2),
    lastSize = _useState8[0],
    setLastSize = _useState8[1];
  var moveS = _reactNativeWeb.Platform.OS === 'web' && !props.notMovable ? {
    cursor: 'move'
  } : {};
  var resizeS = _reactNativeWeb.Platform.OS === 'web' ? {
    cursor: 'nwse-resize'
  } : {};
  if (props.editMode) {
    var panResponderMove = _reactNativeWeb.PanResponder.create({
      onStartShouldSetPanResponder: function onStartShouldSetPanResponder() {
        return true;
      },
      onPanResponderMove: function onPanResponderMove(_e, gestureState) {
        if (moveStart != null && !props.notMovable) {
          var lm = {
            x: gestureState.moveX - moveStart.x,
            y: gestureState.moveY - moveStart.y
          };
          setLastMove(lm);
          props.onMove(lm);
        } else {
          setMoveStart({
            x: gestureState.moveX,
            y: gestureState.moveY
          });
        }
      },
      onPanResponderRelease: function onPanResponderRelease(_e, _gestureState) {
        if (moveStart != null && lastMove != null && !props.notMovable) {
          props.onMoved(lastMove);
          setLastMove(undefined);
          setMoveStart(undefined);
        }
      }
    });
    var panResponderResize = _reactNativeWeb.PanResponder.create({
      onStartShouldSetPanResponder: function onStartShouldSetPanResponder() {
        return true;
      },
      onPanResponderMove: function onPanResponderMove(_e, gestureState) {
        if (resizeStart != null) {
          var lm = {
            width: gestureState.moveX - resizeStart.x,
            height: gestureState.moveY - resizeStart.y
          };
          setLastSize(lm);
          props.onResize(lm);
        } else {
          setResizeStart({
            x: gestureState.moveX,
            y: gestureState.moveY
          });
        }
      },
      onPanResponderRelease: function onPanResponderRelease(_e, _gestureState) {
        if (resizeStart != null && lastSize != null) {
          props.onResized(lastSize);
          setLastSize(undefined);
          setResizeStart(undefined);
        }
      }
    });
    var openOptions = function openOptions(e) {
      _Measurement.Measurement.measure(e.currentTarget).then(function (p) {
        var _Menu$instance3;
        var client = {
          height: p.height,
          width: p.width,
          x: p.pageX,
          y: p.pageY
        };
        var onEdit = props.onEdit,
          onDeleted = props.onDeleted,
          notDeletable = props.notDeletable,
          notEditable = props.notEditable;
        var items = [{
          onPress: function onPress() {
            var _Menu$instance;
            (_Menu$instance = _Menu.Menu.instance) == null ? void 0 : _Menu$instance.close();
            onDeleted();
          },
          thumbnail: {
            thumbnail: (0, _jsxRuntime.jsx)(_Icon.Icon, {
              icon: "delete-outline",
              toolTip: ""
            }),
            width: 40
          },
          text: _I18n.I18n.m.getMessage('delete'),
          disabled: notDeletable
        }];
        if (onEdit != null) {
          items.unshift({
            onPress: function onPress(e) {
              var _Menu$instance2;
              (_Menu$instance2 = _Menu.Menu.instance) == null ? void 0 : _Menu$instance2.close();
              if (onEdit) onEdit(e);
            },
            thumbnail: {
              thumbnail: (0, _jsxRuntime.jsx)(_Icon.Icon, {
                icon: "pencil-outline",
                toolTip: ""
              }),
              width: 40
            },
            text: _I18n.I18n.m.getMessage('edit'),
            disabled: notEditable || !onEdit
          });
        }
        (_Menu$instance3 = _Menu.Menu.instance) == null ? void 0 : _Menu$instance3.open({
          client: client,
          items: items,
          formInput: false
        });
      }).catch(function (err) {
        return void 0;
      });
    };
    return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      onLayout: props.onLayout,
      style: Object.assign({}, props.style),
      children: (0, _jsxRuntime.jsxs)(_Card.Card, {
        outerPadding: 4,
        contentHeight: "100%",
        style: {
          height: '100%',
          width: '100%'
        },
        children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            position: 'relative',
            height: '100%',
            width: '100%'
          },
          children: props.children
        }, `childrenContainer${props.widgetKey}`), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, Object.assign({
          style: Object.assign({
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.65)'
          }, moveS)
        }, panResponderMove.panHandlers)), !props.notResizeable ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, Object.assign({
          style: Object.assign({
            position: 'absolute',
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
            bottom: 0,
            right: 0,
            width: 48,
            height: 48
          }, resizeS)
        }, panResponderResize.panHandlers, {
          children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
            iconSize: 36,
            outerSize: 48,
            icon: "resize-bottom-right",
            toolTip: ""
          })
        })) : (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {}), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            position: 'absolute',
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
            top: 0,
            right: 0,
            width: 48,
            height: 48
          },
          children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
            iconSize: 36,
            outerSize: 48,
            icon: "dots-vertical",
            toolTip: "",
            onPress: openOptions
          })
        })]
      })
    });
  }
  return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
    onLayout: props.onLayout,
    style: Object.assign({}, props.style),
    children: (0, _jsxRuntime.jsx)(_Card.Card, {
      outerPadding: 4,
      contentHeight: "100%",
      style: {
        height: '100%'
      },
      children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          position: 'relative',
          height: '100%',
          width: '100%'
        },
        children: props.children
      }, `childrenContainer${props.widgetKey}`)
    })
  });
}