import { Url } from 'cqrs-shared/src/uri/Url';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import { Platform } from 'react-native';
import DeviceInfo from 'react-native-device-info';
let localUrl = 'http://127.0.0.1/api';
let localHostname = 'dev';
if (Platform.OS !== 'web' && __DEV__) {
    try {
        localUrl = require('./localUrl').default;
    }
    catch (e) {
        console.debug('could not import localUrl file');
    }
}
try {
    localHostname = require('./localHostname').default;
}
catch (e) {
    console.debug('could not import localHostname file');
}
export class ConfigAll {
    constructor() {
    }
    static get SENTRY_CONFIG_KEY() {
        return 'https://b1b7dd1e52d848728968e55b476ec864@s.upmesh.de/2';
    }
    static get SUPPORTED_IMAGE_MIME_TYPES() {
        const imageMimeTypes = Platform.OS === 'ios' ? ['public.jpeg', 'public.png'] : ['image/jpeg', 'image/png'];
        return imageMimeTypes;
    }
    static get b2cURL() {
        return ConfigAll._backendUrl;
    }
    static get MOBILE_VERSION_INFO() {
        return `${DeviceInfo.getVersion()}@${DeviceInfo.getBuildNumber()}`;
    }
    static get WEB_VERSION_INFO() {
        return '1.46.1@2024081202';
    }
    static getVersion() {
        return Platform.OS === 'web' ? ConfigAll.WEB_VERSION_INFO : ConfigAll.MOBILE_VERSION_INFO;
    }
    static getVersionCode() {
        const version = Platform.OS === 'web' ? ConfigAll.WEB_VERSION_INFO : ConfigAll.MOBILE_VERSION_INFO;
        return version.split('@')[1];
    }
    static setBasename(url) {
        const uri = Url.getURLfromString(url);
        ThemeManager.style.basename = uri.hostname === 'dev.upmesh.de' ? '/app/' : '/';
    }
    static getEnvironment() {
        if (ConfigAll._backendUrl === 'https://api.upmesh.de')
            return 'production';
        if (ConfigAll._backendUrl === 'https://dev.upmesh.de/api/upmesh')
            return 'testing';
        if (ConfigAll._backendUrl === 'https://devapi.upmesh.de')
            return 'testing';
        return 'development';
    }
    static getHost() {
        if (localHostname != null)
            return `${localHostname}`;
        return '';
    }
    static async init() {
        if (Platform.OS === 'web') {
            const url = location.href;
            if (url.indexOf('devapp.upmesh.de') >= 0) {
                ConfigAll._backendUrl = 'https://devapi.upmesh.de';
                return;
            }
            if (url.indexOf('devcontrol.upmesh.de') >= 0) {
                ConfigAll._backendUrl = 'https://devapi.upmesh.de';
                return;
            }
            if (url.indexOf('app.upmesh.de') >= 0) {
                ConfigAll._backendUrl = 'https://api.upmesh.de';
                return;
            }
            if (url.indexOf('dev.upmesh.de') >= 0) {
                ConfigAll._backendUrl = 'https://dev.upmesh.de/api/upmesh';
                return;
            }
            if (url.indexOf('127.0.0.1:30666') >= 0) {
                ConfigAll._backendUrl = 'http://127.0.0.1:30012/api';
                return;
            }
            if (url.indexOf('127.0.0.1') >= 0 || url.indexOf('127.0.0.1') >= 0) {
                return;
            }
            if (url.indexOf('localhost') >= 0 || url.indexOf('localhost') >= 0) {
                return;
            }
            if (url.indexOf('control.upmesh.de') >= 0) {
                ConfigAll._backendUrl = 'https://api.upmesh.de';
                return;
            }
            if (url.indexOf('demo.upmesh.de') >= 0) {
                ConfigAll._backendUrl = 'https://demo.upmesh.de/api';
                return;
            }
            if (url.indexOf('pilot.upmesh.de') >= 0) {
                ConfigAll._backendUrl = 'https://pilotapi.upmesh.de';
                return;
            }
        }
        if (Platform.OS !== 'web' && __DEV__) {
            ConfigAll._backendUrl = localUrl;
            return;
        }
        try {
            let d;
            const data = await fetch(`https://api.upmesh.de/backend/(${ConfigAll.getVersionCode()})`);
            if (data != null) {
                if (data.status === 404) {
                    return;
                }
                d = await data.json();
            }
            if (d != null && d['link'] != null) {
                ConfigAll._backendUrl = d['link'];
                ConfigAll.setBasename(d['link']);
                await SimpleStorage.set('lastBackendUrl', d['link']);
            }
            else {
                const getLink = await SimpleStorage.get('lastBackendUrl');
                if (getLink != null) {
                    ConfigAll._backendUrl = getLink;
                    ConfigAll.setBasename(getLink);
                }
            }
        }
        catch (e) {
            console.debug('cant get backend Url', e);
            const getLink = await SimpleStorage.get('lastBackendUrl');
            if (getLink != null) {
                ConfigAll._backendUrl = getLink;
                ConfigAll.setBasename(getLink);
            }
        }
    }
}
ConfigAll._backendUrl = 'http://127.0.0.1:30012/api';
