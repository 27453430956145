var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UploadButton = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _crossFetch = _interopRequireDefault(require("cross-fetch"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _CurrentUser = require("upmesh-auth-core/build/src/client/CurrentUser");
var _FilePicker = require("../../../file/FilePicker");
var _FilePicketComp = require("../../../file/FilePicketComp");
var _ImagePicker = _interopRequireDefault(require("../../../file/ImagePicker"));
var _ImagePickerOptions = require("../../../file/ImagePickerOptions");
var _UploadFile = require("../../../file/upload/impl/UploadFile");
var _Uploads = require("../../../file/upload/Uploads");
var _I18n = require("../../i18n/I18n");
var _ResizeEvent = require("../../ResizeEvent");
var _LoadingEvents = require("../../routing/LoadingEvents");
var _Routing = require("../../routing/Routing");
var _ThemeManager = require("../../ThemeManager");
var _FileDropHandler = require("../file/FileDropHandler");
var _Icon = require("../Icon");
var _ListItem = require("../ListItem");
var _ModalBottomSheet = require("../ModalBottomSheet");
var _MaterialText = require("../text/MaterialText");
var _Ripple = require("../utils/Ripple");
var _UploadingList = require("./UploadingList");
var _UploadTypes = require("./UploadTypes");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var UploadButton = exports.UploadButton = function (_PureComponent) {
  function UploadButton(props) {
    var _this;
    (0, _classCallCheck2.default)(this, UploadButton);
    _this = _callSuper(this, UploadButton, [props]);
    _this.openCam = function () {
      var _ModalBottomSheet$ins;
      (_ModalBottomSheet$ins = _ModalBottomSheet.ModalBottomSheet.instance) == null ? void 0 : _ModalBottomSheet$ins.close();
      if (_this.imagePicker != null) {
        _this.imagePicker.execute({
          selectMethod: 'camera'
        });
      }
    };
    _this.openLibrary = function () {
      var _ModalBottomSheet$ins2;
      (_ModalBottomSheet$ins2 = _ModalBottomSheet.ModalBottomSheet.instance) == null ? void 0 : _ModalBottomSheet$ins2.close();
      if (_this.imagePicker != null) {
        _this.imagePicker.execute({
          selectMethod: 'library'
        });
      }
    };
    _this.addFile = function () {
      var _ref = (0, _asyncToGenerator2.default)(function* (e) {
        var _this$props = _this.props,
          filter = _this$props.filter,
          additionalOptions = _this$props.additionalOptions;
        var listItems = [];
        if (additionalOptions != null && additionalOptions.length > 0) {
          additionalOptions.forEach(function (addOption) {
            listItems.push(addOption);
          });
          listItems.push({
            title: _I18n.I18n.m.getMessage('uploadFiles'),
            thumbnail: {
              thumbnail: (0, _jsxRuntime.jsx)(_Icon.Icon, {
                toolTip: "",
                icon: "file-multiple-outline"
              }),
              width: 24
            },
            onPress: _this.openFilePicker
          });
        }
        if (_reactNativeWeb.Platform.OS !== 'web' && _this.imagePicker != null && (filter == null || filter.includes('allFiles') || filter.includes('images'))) {
          var hasCam = yield _ImagePicker.default.hasCamera();
          if (!(additionalOptions != null && additionalOptions.length > 0)) {
            listItems.push({
              title: _I18n.I18n.m.getMessage('uploadFiles'),
              thumbnail: {
                thumbnail: (0, _jsxRuntime.jsx)(_Icon.Icon, {
                  toolTip: "",
                  icon: "file-multiple-outline"
                }),
                width: 24
              },
              onPress: _this.openFilePicker
            });
          }
          if (_reactNativeWeb.Platform.OS === 'ios') {
            listItems.push({
              title: _I18n.I18n.m.getMessage('fromImageLibrary'),
              thumbnail: {
                thumbnail: (0, _jsxRuntime.jsx)(_Icon.Icon, {
                  toolTip: "",
                  icon: "folder-image"
                }),
                width: 24
              },
              onPress: _this.openLibrary
            });
          }
          if (hasCam) {
            listItems.push({
              title: _I18n.I18n.m.getMessage('fromCamera'),
              thumbnail: {
                thumbnail: (0, _jsxRuntime.jsx)(_Icon.Icon, {
                  toolTip: "",
                  icon: "camera"
                }),
                width: 24
              },
              onPress: _this.openCam
            });
          }
        }
        if (listItems.length > 0) {
          if (listItems.length === 1 && listItems[0].onPress != null) {
            listItems[0].onPress(e);
          } else {
            _reactNativeWeb.UIManager.measure(e.nativeEvent.target, function () {
              var _ref2 = (0, _asyncToGenerator2.default)(function* (_originX, _originY, _width, height, pageX, pageY) {
                var _ModalBottomSheet$ins3;
                (_ModalBottomSheet$ins3 = _ModalBottomSheet.ModalBottomSheet.instance) == null ? void 0 : _ModalBottomSheet$ins3.open({
                  client: {
                    width: 500,
                    height: height,
                    x: pageX,
                    y: pageY
                  },
                  listItems: listItems,
                  maxHeight: 500,
                  maxWidth: 500
                });
              });
              return function (_x2, _x3, _x4, _x5, _x6, _x7) {
                return _ref2.apply(this, arguments);
              };
            }());
          }
        } else {
          _this.openFilePicker();
        }
      });
      return function (_x) {
        return _ref.apply(this, arguments);
      };
    }();
    _this.openFilePicker = function () {
      var _ModalBottomSheet$ins4;
      (_ModalBottomSheet$ins4 = _ModalBottomSheet.ModalBottomSheet.instance) == null ? void 0 : _ModalBottomSheet$ins4.close();
      var _this$props2 = _this.props,
        id = _this$props2.id,
        filter = _this$props2.filter,
        multiple = _this$props2.multiple;
      _FilePicker.FilePicker.show(id, multiple, filter == null ? ['allFiles'] : filter, function (response) {
        return _this.onGotFiles(response);
      });
    };
    _this.defaultDeleteFunction = function () {
      var _ref3 = (0, _asyncToGenerator2.default)(function* (file) {
        try {
          var headers = {};
          headers['authorization'] = `Bearer ${_CurrentUser.CurrentUser.token}`;
          var result = yield (0, _crossFetch.default)(file.targetUrl, {
            method: 'DELETE',
            headers: headers
          });
          if (result.status === 200) return true;
          _Routing.Routing.instance.alert.post({
            text: `${_I18n.I18n.m.getMessage('couldNotDelete')}`
          });
        } catch (e) {
          var message = e['messageCode'] != null ? _I18n.I18n.m.getMessage(e['messageCode']) : '?';
          _Routing.Routing.instance.alert.post({
            text: `${_I18n.I18n.m.getMessage('couldNotDelete')}: ${message !== '?' ? message : e}`
          });
        }
        return false;
      });
      return function (_x8) {
        return _ref3.apply(this, arguments);
      };
    }();
    _this.onPressDelete = function (file, index) {
      return function () {
        var _ref4 = (0, _asyncToGenerator2.default)(function* (_e) {
          var deleteFunction = _this.props.deleteFunction;
          if (deleteFunction != null) {
            _LoadingEvents.LoadingEvents.instance.startLoading();
            try {
              var deleted = deleteFunction === 'default' ? yield _this.defaultDeleteFunction(file) : yield deleteFunction(file);
              if (deleted) {
                _this.setState(function (prevState) {
                  prevState.uploaded.splice(index, 1);
                  return {
                    uploaded: (0, _toConsumableArray2.default)(prevState.uploaded),
                    uploading: 0
                  };
                });
              }
            } catch (e) {
              var text = e['messageCode'] != null ? _I18n.I18n.m.getMessage(e['messageCode']) : e.toString();
              _Routing.Routing.instance.alert.post({
                text: text
              });
            }
            _LoadingEvents.LoadingEvents.instance.stopLoading();
          }
        });
        return function (_x9) {
          return _ref4.apply(this, arguments);
        };
      }();
    };
    _this.lastRemovedOrError = false;
    _this.onPressRemoveToUpload = function (index) {
      return function (_e) {
        var filesToUpload = _this.state.filesToUpload;
        filesToUpload.splice(index, 1);
        _this.lastRemovedOrError = true;
        _this.setState({
          filesToUpload: (0, _toConsumableArray2.default)(filesToUpload)
        }, _this.onStatus);
      };
    };
    _this.onGotFiles = function () {
      var _ref5 = (0, _asyncToGenerator2.default)(function* (f) {
        var multiple = _this.props.multiple;
        var uploads = Object.values(f);
        if (multiple === true) {
          for (var i = 0; i < uploads.length; i += 1) {
            yield _this.uploadFile(uploads[i]);
          }
        } else {
          var uploaded = _this.state.uploaded;
          if (uploaded.length > 0) yield _this.onPressDelete(uploaded[0], 0)();
          yield _this.uploadFile(uploads[0]);
        }
      });
      return function (_x10) {
        return _ref5.apply(this, arguments);
      };
    }();
    _this.onUploaded = function (uploaded) {
      _this.setState(function (prevState) {
        return {
          uploaded: [].concat((0, _toConsumableArray2.default)(prevState.uploaded), [uploaded])
        };
      });
    };
    _this.onUploading = function (files, withErrorOrRemoved) {
      _this.lastRemovedOrError = withErrorOrRemoved || false;
      _this.setState({
        uploading: files.length
      }, _this.onStatus);
    };
    _this.lastUploadingState = 0;
    _this.onStatus = function () {
      var _this$state = _this.state,
        filesToUpload = _this$state.filesToUpload,
        uploading = _this$state.uploading;
      var onUploading = _this.props.onUploading;
      var s = uploading + filesToUpload.length;
      if (onUploading != null && _this.lastUploadingState !== s) onUploading(uploading + filesToUpload.length, _this.lastRemovedOrError);
      _this.lastUploadingState = s;
      _this.lastRemovedOrError = false;
    };
    _this.onImageSelectionSuccess = function (response) {
      var multiple = _this.props.multiple;
      var f = response;
      if (!multiple && Array.isArray(response)) f = [response[0]];else if (multiple && !Array.isArray(response)) f = [response];
      _this.onGotFiles(Array.isArray(f) ? f : [f]).catch(function (err) {
        return void 0;
      });
    };
    _this.imagePicker = null;
    _this.state = {
      uploading: 0,
      uploaded: props.uploaded != null ? props.uploaded : [],
      startUploadImmediately: props.startUploadImmediately == null || props.startUploadImmediately === true,
      filesToUpload: []
    };
    return _this;
  }
  (0, _inherits2.default)(UploadButton, _PureComponent);
  return (0, _createClass2.default)(UploadButton, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      var _this$props3 = this.props,
        multiple = _this$props3.multiple,
        filter = _this$props3.filter,
        uploadText = _this$props3.uploadText,
        uploadDropDialogText = _this$props3.uploadDropDialogText;
      this.dropKey = _FileDropHandler.FileDropHandler.addTarget({
        filter: filter,
        onGotFile: this.onGotFiles,
        text: uploadDropDialogText != null ? uploadDropDialogText : uploadText,
        multiHandlerDialogText: uploadDropDialogText != null ? uploadDropDialogText : uploadText,
        multiFiles: multiple
      });
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (this.dropKey != null) {
        _FileDropHandler.FileDropHandler.removeTarget(this.dropKey);
        this.dropKey = undefined;
      }
    }
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(prevProps, _prevState) {
      var _prevProps$uploaded, _this$props$uploaded;
      if (prevProps.startNow !== true && this.props.startNow) {
        this.startUploadsNow().catch(function (err) {
          return void 0;
        });
      }
      if (((_prevProps$uploaded = prevProps.uploaded) == null ? void 0 : _prevProps$uploaded.length) !== ((_this$props$uploaded = this.props.uploaded) == null ? void 0 : _this$props$uploaded.length)) {
        var _this$props$uploaded2;
        this.setState({
          uploaded: (_this$props$uploaded2 = this.props.uploaded) != null ? _this$props$uploaded2 : []
        });
      }
    }
  }, {
    key: "startUploadsNow",
    value: function () {
      var _startUploadsNow = (0, _asyncToGenerator2.default)(function* () {
        var filesToUpload = this.state.filesToUpload;
        if (filesToUpload.length > 0) {
          for (var f of filesToUpload) {
            yield this.addFileToUploads(f);
          }
          this.setState({
            filesToUpload: []
          });
        }
      });
      function startUploadsNow() {
        return _startUploadsNow.apply(this, arguments);
      }
      return startUploadsNow;
    }()
  }, {
    key: "uploadFile",
    value: function () {
      var _uploadFile = (0, _asyncToGenerator2.default)(function* (f) {
        var _this2 = this;
        var startUploadImmediately = this.state.startUploadImmediately;
        var _this$props4 = this.props,
          startNow = _this$props4.startNow,
          multiple = _this$props4.multiple;
        if (startUploadImmediately || startNow) {
          _LoadingEvents.LoadingEvents.instance.startLoading();
          yield this.addFileToUploads(f);
          _LoadingEvents.LoadingEvents.instance.stopLoading();
        } else {
          f['preview'] = yield _UploadFile.UploadFile.getFilePreview(f);
          var filesToUpload = this.state.filesToUpload;
          if (!multiple && filesToUpload.length > 0) filesToUpload.pop();
          filesToUpload.push(f);
          yield new Promise(function (resolve) {
            _this2.setState({
              filesToUpload: (0, _toConsumableArray2.default)(filesToUpload)
            }, function () {
              _this2.onStatus();
              resolve();
            });
          });
        }
      });
      function uploadFile(_x11) {
        return _uploadFile.apply(this, arguments);
      }
      return uploadFile;
    }()
  }, {
    key: "addFileToUploads",
    value: function () {
      var _addFileToUploads = (0, _asyncToGenerator2.default)(function* (f) {
        var _this$props5 = this.props,
          id = _this$props5.id,
          data = _this$props5.data;
        try {
          yield _Uploads.Uploads.addUpload(this.props.endpointUrl.href, f, id, data);
        } catch (e) {
          var filename = f['name'] != null ? f['name'] : f['filename'] != null ? f['filename'] : f['fileName'];
          var message = e['messageCode'] != null ? _I18n.I18n.m.getMessage(e['messageCode']) : e['message'] != null ? e['message'] : '?';
          _Routing.Routing.instance.alert.post({
            text: _I18n.I18n.m.getMessage('cantUpload', {
              error: message !== '?' ? message : e.toString(),
              filename: `"${filename}"`
            })
          });
        }
      });
      function addFileToUploads(_x12) {
        return _addFileToUploads.apply(this, arguments);
      }
      return addFileToUploads;
    }()
  }, {
    key: "renderFileDisplays",
    value: function renderFileDisplays() {
      var _this$state2 = this.state,
        uploaded = _this$state2.uploaded,
        filesToUpload = _this$state2.filesToUpload;
      var _this$props6 = this.props,
        id = _this$props6.id,
        iconPosition = _this$props6.iconPosition;
      var height = iconPosition != null && iconPosition === 'top' ? _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(96) : _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(58);
      var listItems = [(0, _jsxRuntime.jsx)(_UploadingList.UploadingList, {
        onUploading: this.onUploading,
        itemHeight: height,
        type: id,
        onUploaded: this.onUploaded,
        alertErrors: true
      }, "uploading")];
      if (filesToUpload.length > 0) {
        for (var i = 0; i < filesToUpload.length; i += 1) {
          var f = filesToUpload[i];
          var iconRight = {
            icon: 'delete-outline',
            onPress: this.onPressRemoveToUpload(i)
          };
          var filename = f['name'] != null ? f['name'] : f['filename'] != null ? f['filename'] : f['fileName'];
          listItems.push((0, _jsxRuntime.jsx)(_ListItem.ListItem, {
            height: height,
            title: filename,
            thumbnail: _UploadTypes.UploadTypes.getThumbnailForFileName(f),
            iconRight: iconRight
          }, `uploadFileLI_${i}`));
        }
      }
      if (uploaded.length > 0) {
        var deleteFunction = this.props.deleteFunction;
        for (var _i = 0; _i < uploaded.length; _i += 1) {
          var _f = uploaded[_i];
          var _iconRight = deleteFunction != null ? {
            icon: 'delete-outline',
            onPress: this.onPressDelete(_f, _i)
          } : {
            icon: 'check-circle-outline',
            color: _ThemeManager.ThemeManager.style.brandSuccess
          };
          listItems.push((0, _jsxRuntime.jsx)(_ListItem.ListItem, {
            height: height,
            title: _f.name,
            thumbnail: _UploadTypes.UploadTypes.getThumbnailForFileName(_f),
            iconRight: _iconRight
          }, `uploadedFileLI${_i}`));
        }
      }
      return listItems;
    }
  }, {
    key: "renderHasUploadedView",
    value: function renderHasUploadedView() {
      var _this$props7 = this.props,
        multiple = _this$props7.multiple,
        iconPosition = _this$props7.iconPosition;
      var _this$state3 = this.state,
        uploaded = _this$state3.uploaded,
        uploading = _this$state3.uploading,
        filesToUpload = _this$state3.filesToUpload;
      var visible = filesToUpload.length > 0 || uploading > 0 || uploaded != null && uploaded.length > 0;
      var maxHeight = _ResizeEvent.ResizeEvent.current.contentHeight * (_ResizeEvent.ResizeEvent.current.contentWidth > _ThemeManager.ThemeManager.style.breakpointM ? 0.8 : 1) - 190;
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          borderRadius: _ThemeManager.ThemeManager.style.borderRadius,
          borderWidth: _ThemeManager.ThemeManager.style.borderWidth,
          borderColor: visible ? _ThemeManager.ThemeManager.style.borderColor : 'transparent',
          marginTop: visible && multiple ? 16 : undefined,
          opacity: visible ? 1 : 0
        },
        children: (0, _jsxRuntime.jsx)(_reactNativeWeb.ScrollView, {
          scrollEnabled: true,
          style: {
            minHeight: !visible ? 0 : iconPosition != null && iconPosition === 'top' ? _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(96) : _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(58),
            maxHeight: maxHeight
          },
          children: this.renderFileDisplays()
        })
      });
    }
  }, {
    key: "renderUploadButton",
    value: function renderUploadButton() {
      var _this$props8 = this.props,
        uploadText = _this$props8.uploadText,
        disableUpload = _this$props8.disableUpload,
        id = _this$props8.id,
        multiple = _this$props8.multiple,
        uploadIcon = _this$props8.uploadIcon,
        filter = _this$props8.filter,
        iconPosition = _this$props8.iconPosition;
      var _this$state4 = this.state,
        uploaded = _this$state4.uploaded,
        uploading = _this$state4.uploading,
        filesToUpload = _this$state4.filesToUpload;
      if (!multiple && (uploaded != null && uploaded.length > 0 || uploading > 0 || filesToUpload.length > 0)) return null;
      var icon = uploadIcon == null ? null : (0, _jsxRuntime.jsx)(_Icon.Icon, {
        toolTip: "",
        icon: uploadIcon,
        color: disableUpload ? _ThemeManager.ThemeManager.style.disabledColor : _ThemeManager.ThemeManager.style.brandPrimary
      });
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        children: [(0, _jsxRuntime.jsxs)(_Ripple.Ripple, {
          disabled: disableUpload,
          onPress: this.addFile,
          hoverColor: _ThemeManager.ThemeManager.style.getDefaultHoverColor('transparent'),
          style: {
            width: 'auto',
            height: 'auto',
            backgroundColor: 'transparent'
          },
          accessibilityLabel: this.props.accessibilityLabel,
          children: [(0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
            style: {
              borderColor: _ThemeManager.ThemeManager.style.borderColor,
              borderRadius: _ThemeManager.ThemeManager.style.borderRadius,
              borderWidth: 2,
              borderStyle: 'dashed',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: iconPosition != null && iconPosition === 'top' ? undefined : 'row',
              height: iconPosition != null && iconPosition === 'top' ? _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(96) : _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(58),
              paddingBottom: iconPosition != null && iconPosition === 'top' ? _ThemeManager.ThemeManager.style.contentPaddingValue : 0
            },
            children: [icon, (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              type: _MaterialText.MaterialTextTypes.Button,
              color: disableUpload ? _ThemeManager.ThemeManager.style.disabledColor : _ThemeManager.ThemeManager.style.brandPrimary,
              centeredBox: true,
              centeredText: iconPosition != null && iconPosition === 'top',
              children: uploadText
            })]
          }), this.renderOptionalImagePicker()]
        }), (0, _jsxRuntime.jsx)(_FilePicketComp.FilePicketComp, {
          id: id,
          multiple: multiple,
          gotFile: this.onGotFiles,
          filter: filter
        })]
      });
    }
  }, {
    key: "renderOptionalImagePicker",
    value: function renderOptionalImagePicker() {
      var _this3 = this;
      var _this$props9 = this.props,
        filter = _this$props9.filter,
        multiple = _this$props9.multiple,
        id = _this$props9.id;
      if (_reactNativeWeb.Platform.OS !== 'web' && (filter == null || filter.includes('allFiles') || filter.includes('images'))) {
        return (0, _jsxRuntime.jsx)(_ImagePicker.default, {
          ref: function ref(_ref6) {
            _this3.imagePicker = _ref6;
          },
          callBack: this.onImageSelectionSuccess,
          options: new _ImagePickerOptions.ImagePickerOptions({
            multiple: multiple,
            filePicketCompId: `imagepicker_${id}`,
            selectMethod: 'any',
            mediaType: 'photo',
            includeExif: true,
            compressImageQuality: 0.85,
            cropperCircleOverlay: false,
            cropping: false,
            includeBase64: false,
            editModeEnabled: false
          })
        });
      }
      return null;
    }
  }, {
    key: "render",
    value: function render() {
      var showUploadOptionIfDisabled = this.props.showUploadOptionIfDisabled;
      return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          position: 'relative'
        },
        children: [showUploadOptionIfDisabled !== false ? this.renderUploadButton() : undefined, this.renderHasUploadedView()]
      });
    }
  }]);
}(_react.PureComponent);