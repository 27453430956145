"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CreateRegion = void 0;
var CommandRoot_1 = require("cqrs-core/build/src/core/commands/CommandRoot");
var ReadModels_1 = require("cqrs-core/build/src/core/query/ReadModels");
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var uuid = require("uuid");
var RightsManager_1 = require("../../../../access/rights/RightsManager");
var CommandReadModels_1 = require("../../../../server/webserver/commands/CommandReadModels");
var RegionCreated_1 = require("../../../events/company/region/RegionCreated");
var CreateRegion = function (_CommandRoot_1$Comman) {
  function CreateRegion(data) {
    var _this;
    var entityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : uuid.v1();
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, CreateRegion);
    _this = _callSuper(this, CreateRegion, [data, entityId, token]);
    _this.aggregate = 'company';
    _this.commandName = 'CreateRegion';
    return _this;
  }
  (0, _inherits2.default)(CreateRegion, _CommandRoot_1$Comman);
  return (0, _createClass2.default)(CreateRegion, [{
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        var companies = ReadModels_1.ReadModels.get('Company');
        var company = yield companies.getById(this.data.companyId);
        if (yield RightsManager_1.RightsManager.isCompanyAdmin(userId, company.id)) return true;
        throw {
          message: 'Keine Berechtigung',
          messageCode: 'forbiddenCommand'
        };
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return new RegionCreated_1.RegionCreated(this.entityId, Object.assign({}, this.data));
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (key) {
        var _this2 = this;
        if (key == null || key === 'region') {
          if (this.data.region == null || this.data.region.length === 0) {
            throw {
              message: 'Region wird benötigt',
              messageCode: 'required'
            };
          }
        }
        if (key == null || key === 'country') {
          if (this.data.region == null || this.data.region.length === 0) {
            throw {
              message: 'Land wird benötigt',
              messageCode: 'required'
            };
          }
        }
        if (key == null || key === 'companyId') {
          if (this.data.region == null || this.data.region.length === 0) {
            throw {
              message: 'Firma wird benötigt',
              messageCode: 'required'
            };
          }
        }
        var regions = yield CommandReadModels_1.CommandReadModels.region.get({
          filter: `deleted ne true and companyId eq '${this.data.companyId}'`
        });
        if (regions.find(function (element) {
          return _this2.data.region === element.region && _this2.data.country === element.country;
        })) {
          throw {
            message: 'Region existiert bereits',
            messageCode: 'companyRegionAlreadyExists'
          };
        }
      });
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }]);
}(CommandRoot_1.CommandRoot);
exports.CreateRegion = CreateRegion;