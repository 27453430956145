import { ChipGroup } from 'materialTheme/src/theme/components/chips/ChipGroup';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { I18n } from '../../i18n/I18n';
import { PlanThumb169 } from './PlanThumb169';
export class PlanDetailsComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.loading = false;
        this.pressEditPlan = async (e) => {
            const { plan } = this.state;
            if (plan != null) {
                Routing.instance.openDialog('editPlan', { type: 'edit', planId: plan.id })(e);
            }
        };
        this.gotoActivePlanVersion = () => {
            const { plan } = this.props;
            Routing.instance.goTo(`/projects/${plan.projectId}/plans/${plan.id}/versions/${plan.activePlanId}`);
        };
        this.onLayout = (_e) => { };
        this.state = {
            init: false,
            plan: undefined,
            planTags: [],
        };
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (JSON.stringify(nextProps.plan) === JSON.stringify(prevState.plan)) {
            return null;
        }
        return {
            init: false,
            plan: undefined,
            planTags: [],
        };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
    }
    componentDidUpdate() {
        if (!this.state.init && !this.loading && this.props.plan != null) {
            this.loading = true;
            this.init()
                .then(() => {
                this.loading = false;
            })
                .catch((err) => console.debug(err));
        }
    }
    render() {
        if (!this.state.init) {
            return <Spinner />;
        }
        const { plan } = this.state;
        const { showThumb } = this.props;
        if (plan == null) {
            return <View />;
        }
        return (<View onLayout={this.onLayout} style={{
                width: '100%',
                position: 'relative',
                flexDirection: 'column',
                height: 'auto',
                padding: 16,
                paddingTop: 32,
            }}>
        <View style={{ position: 'absolute', right: 16, top: 16 }}>
          <Icon toolTip={I18n.m.getMessage('tooltipEditPlanDetails')} key="edit" color={ThemeManager.style.headerButtonColor} onPress={this.pressEditPlan} icon="pencil-outline"/>
        </View>

        <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('planDetailsPlanTitle')}</MaterialText>
        <MaterialText type={MaterialTextTypes.Body1}>{plan.title}</MaterialText>

        {!showThumb ? null : (<View style={{ paddingTop: 16, paddingBottom: 0 }}>
            <MaterialText type={MaterialTextTypes.Caption}>
              {I18n.m.getMessage('planDetailsPlanActivePlan')}
            </MaterialText>
            <PlanThumb169 plan={plan} projectId={plan.projectId} fileSource={plan.activePlanId === 'MAP' ? 'map' : 'planVersion'} width={200} fileId={plan.activePlanId} onPress={this.gotoActivePlanVersion}/>
          </View>)}

        <View style={{ paddingTop: 16, paddingBottom: 0 }}>
          <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('planDetailsPlanTags')}</MaterialText>
        </View>
        <ChipGroup buttonDisabled textColor="#FFFFFF" chips={this.state.planTags} dialogTitle={I18n.m.getMessage('planDetailsPlanTags')}/>
        <View style={{ paddingTop: 16, paddingBottom: 0 }}>
          <MaterialText type={MaterialTextTypes.Caption}>{I18n.m.getMessage('planDetailsPlanNotes')}</MaterialText>
        </View>
        <MaterialText type={MaterialTextTypes.Body1}>{plan.notes}</MaterialText>
      </View>);
    }
    async init() {
        const { plan } = this.props;
        const planTags = [];
        if (plan != null && plan.tags != null && plan.tags.length > 0) {
            plan.tags.forEach((t) => {
                planTags.push({
                    title: t.tagName,
                    backgroundColor: t.color,
                });
            });
        }
        await new Promise((resolve) => {
            this.setState({ planTags, plan, init: true }, () => {
                resolve();
            });
        });
    }
}
