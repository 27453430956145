import { Alert } from 'materialTheme/src/theme/components/Alert';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { OpenableChip } from 'materialTheme/src/theme/components/chips/OpenableChip';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { ChangeTicketType } from 'upmesh-core/src/client/commands/tickets/ChangeTicketType';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
export class TicketTypeChip extends PureComponent {
    constructor(props) {
        super(props);
        this.init = async () => {
            const { ticket } = this.props;
            const project = await UpmeshClient.instance.modals.project.getById(ticket.projectId);
            const { ticketTypes } = project;
            const c = new ChangeTicketType({ ticketType: ticket.type == null ? null : ticket.type }, ticket.id);
            try {
                const canI = await c.canI();
                if (typeof canI === 'boolean' && canI) {
                    this.setState({ ticketTypes, disabledChip: false });
                }
                else {
                    this.setState({ ticketTypes, disabledChip: true });
                }
            }
            catch (e) {
                this.setState({ ticketTypes, disabledChip: true });
            }
        };
        this.onChangeType = async (e) => {
            const { errorResult } = this.props;
            const { ticket } = this.props;
            try {
                const c = new ChangeTicketType({ ticketType: e == null || e === 0 ? null : e }, ticket.id);
                const { removedFields } = await c.getRemovedFieldsWithContent();
                if (removedFields.length > 0) {
                    const fields = [];
                    removedFields.forEach((f) => {
                        if (f.customField) {
                            fields.push(f.label);
                        }
                        else if (f.systemField) {
                            const k = f.systemField === 'assignee'
                                ? 'reportTicketDetailsAssignee'
                                : f.systemField === 'approver'
                                    ? 'reportTicketDetailsApprover'
                                    : f.systemField === 'tags'
                                        ? 'reportTicketDetailsTags'
                                        : f.systemField === 'craft'
                                            ? 'reportTicketDetailsCraft'
                                            : f.systemField === 'description'
                                                ? 'reportTicketDetailsDescription'
                                                : f.systemField === 'status'
                                                    ? 'reportTicketDetailsStatus'
                                                    : f.systemField === 'plan'
                                                        ? 'reportTicketDetailsPlan'
                                                        : f.systemField === 'completionOn'
                                                            ? 'reportTicketDetailsCompletionOn'
                                                            : 'reportTicketDetailsTags';
                            fields.push(I18n.m.getMessage(k));
                        }
                    });
                    Routing.instance.alert.post({
                        title: I18n.m.getMessage('ticketChangeTypeWarningRemovedFieldValuesTitle'),
                        text: I18n.m.getMessage('ticketChangeTypeWarningRemovedFieldValuesText', { fields: fields.join(', ') }),
                        buttons: [
                            <ContainedButton key="no" textColor={ThemeManager.style.brandPrimary} title={I18n.m.getMessage('cancel')} onPress={Alert.instance?.close} backgroundColor="transparent"/>,
                            <ContainedButton key="yes" title={I18n.m.getMessage('change')} onPress={() => {
                                    Alert.instance?.close();
                                    c.execute().catch((err) => errorResult(err));
                                }}/>,
                        ],
                    });
                }
                else {
                    await c.execute();
                }
            }
            catch (err) {
                errorResult(err);
            }
        };
        this.state = { disabledChip: true, ticketTypes: [] };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
    }
    static typeList(ticketTypes) {
        const list = [];
        ticketTypes?.forEach((s) => {
            list.push({
                title: s,
                onPressChipData: s,
                textColor: ThemeManager.style.defaultTextColor,
            });
        });
        list.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
        return list;
    }
    render() {
        const { labelStyle, ticket } = this.props;
        const { ticketTypes } = this.state;
        if (ticketTypes == null || ticketTypes.length === 0)
            return null;
        let currentTypeIndex = -1;
        const typeList = TicketTypeChip.typeList(ticketTypes);
        for (let i = 0; i < typeList.length; i += 1) {
            if (typeList[i].onPressChipData === ticket.type) {
                currentTypeIndex = i;
                break;
            }
        }
        return [
            <View key="typeLabel" style={labelStyle}>
        <MaterialText fixedWidth="100%" type={MaterialTextTypes.Caption}>
          {I18n.m.getMessage('ticketsDetailsType')}
        </MaterialText>
      </View>,
            <OpenableChip showDeleteIcon emptyStateChip={{
                    title: I18n.m.getMessage('ticketTypeNotSelected'),
                    onPressChipData: 0,
                    textColor: ThemeManager.style.defaultTextColor,
                }} key="typeChip" dialogTitle={I18n.m.getMessage('ticketsDetailsType')} accessibilityLabel="type" elevation={40} selected={currentTypeIndex} onPressChip={this.onChangeType} widthOpen={256} chipsList={typeList} chipDisabled={this.state.disabledChip}/>,
        ];
    }
}
