import { Icon } from 'materialTheme/src/theme/components/Icon';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { openNotificationBellDialog } from './NotificationBellDialog';
export function NotificationBell(props) {
    const [notifications, setNotifications] = useState([]);
    const t = new Date();
    const updateView = (limit) => {
        if (limit == null) {
            if (CurrentUser.settings != null &&
                CurrentUser.settings.appSettings != null &&
                CurrentUser.settings.appSettings['lastOpenNotifications']) {
                limit = new Date(CurrentUser.settings.appSettings['lastOpenNotifications']);
            }
        }
        if (limit != null) {
            UpmeshClient.instance.modals.logBook
                .get({
                filter: `userIds/userId eq '${CurrentUser.userId}' and creator ne '${CurrentUser.userId}' and createdBy ne '${CurrentUser.userId}' and createdAt gt ${limit.toISOString()}`,
                orderby: 'createdAt DESC',
                top: 99,
            })
                .then((e) => {
                setNotifications(e);
            })
                .catch((err) => console.error(err));
        }
    };
    useEffect(() => {
        if (props.onNotificationsUpdate)
            props.onNotificationsUpdate(notifications.length);
    }, [notifications]);
    useEffect(() => {
        let limit = new Date(t.getFullYear(), t.getMonth(), t.getDate() - 30, 0, 0, 0, 0);
        if (CurrentUser.settings != null &&
            CurrentUser.settings.appSettings != null &&
            CurrentUser.settings.appSettings['lastOpenNotifications']) {
            limit = new Date(CurrentUser.settings.appSettings['lastOpenNotifications']);
        }
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'NotificationBellUserSettings',
            readModelName: 'UserSettings',
            callback: (_e) => {
                if (CurrentUser.settings != null &&
                    CurrentUser.settings.appSettings != null &&
                    CurrentUser.settings.appSettings['lastOpenNotifications']) {
                    const limit2 = new Date(CurrentUser.settings.appSettings['lastOpenNotifications']);
                    console.log('UPDATE USER SETTINGS', limit.getTime() !== limit2.getTime(), limit2, limit);
                    updateView(limit2);
                }
            },
        });
        UpmeshClient.eventDispatcher.attach({
            attachKey: 'NotificationBell',
            readModelName: 'LogBook',
            callback: (e) => {
                let forMe = false;
                e.entities.forEach((l) => {
                    const lb = l.entity;
                    if (lb &&
                        lb.creator !== CurrentUser.userId &&
                        lb.createdBy !== CurrentUser.userId &&
                        lb.userIds.findIndex((a) => a.userId === CurrentUser.userId) >= 0)
                        forMe = true;
                });
                if (forMe) {
                    updateView();
                }
            },
        });
        updateView(limit);
        return () => {
            UpmeshClient.eventDispatcher.detach('LogBook', 'NotificationBell');
            UpmeshClient.eventDispatcher.detach('UserSettings', 'NotificationBellUserSettings');
        };
    }, []);
    return (<View style={{ position: 'relative', width: 48, height: 48 }}>
      <View style={{
            width: 48,
            height: 48,
            justifyContent: 'center',
            alignItems: 'center',
        }}>
        <Icon icon="bell-outline" onPress={(e) => {
            setNotifications([]);
            openNotificationBellDialog()(e);
        }} toolTip={I18n.m.getMessage('accountNotifications')} color={props.iconColor ? props.iconColor : ThemeManager.style.defaultIconColor}/>
      </View>
      {notifications.length > 0 ? (<View pointerEvents="none" style={{
                position: 'absolute',
                top: 4,
                right: 4,
                backgroundColor: ThemeManager.style.brandDanger,
                borderRadius: 10,
                width: 20,
                height: 20,
                justifyContent: 'center',
            }}>
          <MaterialText centeredBox centeredText fixedWidth={ThemeManager.style.getScreenRelativePixelSize(24)} type={MaterialTextTypes.Caption} color="#FFFFFF">
            {notifications.length > 99 ? '99' : notifications.length}
          </MaterialText>
        </View>) : null}
    </View>);
}
