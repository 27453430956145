var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WidgetItemContainer = WidgetItemContainer;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _I18n = require("../../i18n/I18n");
var _Routing = require("../../routing/Routing");
var _ThemeManager = require("../../ThemeManager");
var _Alert = require("../Alert");
var _ContainedButton = require("../button/ContainedButton");
var _Dialog = require("../Dialog");
var _Icon = require("../Icon");
var _AddWidgetDialog = require("./AddWidgetDialog");
var _WidgetItem = require("./WidgetItem");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function WidgetItemContainer(props) {
  var _useState = (0, _react.useState)(0),
    _useState2 = (0, _slicedToArray2.default)(_useState, 2),
    currentWidth = _useState2[0],
    setCurrentWith = _useState2[1];
  var _useState3 = (0, _react.useState)(false),
    _useState4 = (0, _slicedToArray2.default)(_useState3, 2),
    editMode = _useState4[0],
    setEditMode = _useState4[1];
  var _useState5 = (0, _react.useState)([]),
    _useState6 = (0, _slicedToArray2.default)(_useState5, 2),
    items = _useState6[0],
    setItems = _useState6[1];
  var _useState7 = (0, _react.useState)([]),
    _useState8 = (0, _slicedToArray2.default)(_useState7, 2),
    tempItems = _useState8[0],
    setTempItems = _useState8[1];
  var colsHeight = 64;
  var _useState9 = (0, _react.useState)(0),
    _useState10 = (0, _slicedToArray2.default)(_useState9, 2),
    colsWidth = _useState10[0],
    setColsWidth = _useState10[1];
  var widgetPostions = (0, _react.useRef)(new Map());
  var lastHeight = (0, _react.useRef)(0);
  var isCollidating = function isCollidating(w) {
    var colliding = false;
    widgetPostions.current.forEach(function (a, key) {
      if (key !== w.id) {
        if (a.left < w.left + w.width && a.left + a.width > w.left && a.top < w.top + w.height && a.top + a.height > w.top) {
          colliding = true;
        }
      }
    });
    return colliding;
  };
  var getNextFreePostion = function getNextFreePostion(widget, start) {
    var left = start.left;
    var top = start.top;
    while (isCollidating({
      id: widget.id,
      left: left,
      top: top,
      width: widget.width,
      height: widget.height
    })) {
      left += colsWidth;
      if (left + widget.width > currentWidth) {
        left = 0;
        top += colsHeight;
      }
    }
    return {
      left: left,
      top: top,
      width: widget.width,
      height: widget.height
    };
  };
  var sortItems = function sortItems(items) {
    return items.sort(function (a, b) {
      var aTop = a.top != null ? a.top : 9999;
      var bTop = b.top != null ? b.top : 9999;
      if (aTop !== bTop) return aTop > bTop ? 1 : -1;
      if (a.top === b.top) {
        var aLeft = a.left ? a.left : 0;
        var bLeft = b.left ? b.left : 0;
        if (aLeft !== bLeft) return aLeft > bLeft ? 1 : -1;
      }
      return 0;
    });
  };
  (0, _react.useEffect)(function () {
    var t = setTimeout(function () {
      var cols = 12;
      var cw = Math.floor(currentWidth / cols);
      widgetPostions.current = new Map();
      setColsWidth(cw);
    }, 200);
    return function () {
      return clearTimeout(t);
    };
  }, [currentWidth]);
  var itemsRunning = (0, _react.useRef)(false);
  var itemsTo2 = function itemsTo2(i) {
    var sort = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    while (itemsRunning.current) {}
    itemsRunning.current = true;
    if (sort) i = sortItems(i);
    var it = [];
    i.forEach(function (a) {
      var width = a.width * colsWidth;
      var height = a.height * colsHeight;
      var widget = {
        id: a.id,
        width: width,
        height: height
      };
      var start = {
        left: a.left ? a.left * colsWidth : 0,
        top: a.top ? a.top * colsHeight : 0
      };
      var position = getNextFreePostion(widget, start);
      widgetPostions.current.set(a.id, Object.assign({}, position));
      a.left = position.left ? Math.floor(position.left / colsWidth) : 0;
      a.top = position.top ? position.top / colsHeight : 0;
      it.push(a);
    });
    itemsRunning.current = false;
    return it;
  };
  (0, _react.useEffect)(function () {
    if (colsWidth > 0) {
      var savedItems = props.savedItems,
        defaultItems = props.defaultItems;
      var i = savedItems != null ? (0, _toConsumableArray2.default)(savedItems) : (0, _toConsumableArray2.default)(defaultItems);
      var it = itemsTo2(i);
      setItems(it);
      widgetPostions.current.forEach(function (_a, key) {
        var isIn = it.find(function (b) {
          return b.id === key;
        });
        if (!isIn) widgetPostions.current.delete(key);
      });
    }
  }, [props.defaultItems, props.savedItems, colsWidth]);
  var getMinHeight = function getMinHeight() {
    var h = 0;
    widgetPostions.current.forEach(function (a) {
      var t = a.height + a.top;
      if (h < t) h = t;
    });
    h += 64;
    if (editMode && h < lastHeight.current) return lastHeight.current;
    lastHeight.current = h;
    return h;
  };
  var onMove = function onMove(id) {
    return function (move) {
      var i = items.find(function (a) {
        return a.id === id;
      });
      if (i == null) return;
      var dTop = Math.round(move.y / colsHeight);
      var dLeft = Math.round(move.x / colsWidth);
      var top = Math.max(0, i.top ? i.top + dTop : dTop);
      var left = Math.min(Math.max(0, i.left ? i.left + dLeft : dLeft), 12 - i.width);
      var tempItems = [Object.assign({}, i, {
        top: top,
        left: left
      })];
      items.forEach(function (e) {
        if (e.id !== tempItems[0].id) {
          tempItems.push(Object.assign({}, e));
        }
      });
      widgetPostions.current = new Map();
      var it = itemsTo2(tempItems, false);
      setTempItems(it);
    };
  };
  var onMoved = function onMoved(_id) {
    return function (_move) {
      if (tempItems != null && tempItems.length > 0) {
        setItems((0, _toConsumableArray2.default)(tempItems));
        setTempItems([]);
      }
    };
  };
  var onResize = function onResize(id) {
    return function (resize) {
      var i = items.find(function (a) {
        return a.id === id;
      });
      if (i == null) return;
      var dWidth = Math.round(resize.width / colsWidth);
      var dHeight = Math.round(resize.height / colsHeight);
      var template = props.templates.find(function (c) {
        return c.id === i.widgetId;
      });
      if (template == null) return;
      var minWidth = template.minWidth ? template.minWidth : 1;
      var minHeight = template.minHeight ? template.minHeight : 1;
      var maxHeight = template.maxHeight ? template.maxHeight : 20;
      var newWidth = Math.min(12, Math.max(minWidth, i.width + dWidth));
      var newHeight = Math.min(maxHeight, Math.max(minHeight, i.height + dHeight));
      var tempItems = [Object.assign({}, i, {
        width: newWidth,
        height: newHeight
      })];
      items.forEach(function (e) {
        if (e.id !== tempItems[0].id) {
          tempItems.push(Object.assign({}, e));
        }
      });
      widgetPostions.current = new Map();
      var it = itemsTo2(tempItems, false);
      setTempItems(it);
    };
  };
  var onResized = function onResized(_id) {
    return function (_resize) {
      if (tempItems != null && tempItems.length > 0) {
        setItems((0, _toConsumableArray2.default)(tempItems));
        setTempItems([]);
      }
    };
  };
  var onDeleted = function onDeleted(id) {
    return function () {
      var item = items.find(function (a) {
        return a.id === id;
      });
      if (item == null) return;
      if (item.notDeletable) return;
      _Routing.Routing.instance.alert.post({
        text: _I18n.I18n.m.getMessage('deleteWidgetsQuestion'),
        buttons: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
          onPress: function onPress() {
            var _Alert$instance;
            return (_Alert$instance = _Alert.Alert.instance) == null ? void 0 : _Alert$instance.close();
          },
          title: _I18n.I18n.m.getMessage('cancel'),
          backgroundColor: "transparent",
          textColor: _ThemeManager.ThemeManager.style.brandPrimary
        }, "cancel"), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
          onPress: function onPress() {
            var _Alert$instance2;
            (_Alert$instance2 = _Alert.Alert.instance) == null ? void 0 : _Alert$instance2.close();
            var i = [];
            widgetPostions.current = new Map();
            items.forEach(function (a) {
              if (a.id !== id) i.push(Object.assign({}, a, {
                left: 0,
                top: 0
              }));
            });
            var it = itemsTo2(i);
            setItems(it);
            if (item.onDeleted) item.onDeleted();
            props.onChanged(it);
          },
          title: _I18n.I18n.m.getMessage('remove')
        }, "close")]
      });
    };
  };
  var onOptionsSaved = function onOptionsSaved(w) {
    return function (settings) {
      var _Dialog$instance;
      var newItmes = [];
      items.forEach(function (a) {
        if (a.id === w.id) {
          newItmes.push(Object.assign({}, w, {
            settings: settings
          }));
        } else newItmes.push(a);
      });
      var it = itemsTo2(newItmes);
      setItems(it);
      props.onChanged(it);
      (_Dialog$instance = _Dialog.Dialog.instance) == null ? void 0 : _Dialog$instance.close();
    };
  };
  var onOptions = function onOptions(template, w) {
    return function (e) {
      var _Dialog$instance2;
      var optionWindow = template.optionWindow;
      if (optionWindow == null) return;
      var o = optionWindow(w.settings ? {
        initValues: Object.assign({}, w.settings),
        onSaved: onOptionsSaved(w),
        type: 'edit'
      } : {
        onSaved: onOptionsSaved(w),
        type: 'edit'
      });
      var openPosition = {
        x: e.nativeEvent.pageX,
        y: e.nativeEvent.pageY
      };
      (_Dialog$instance2 = _Dialog.Dialog.instance) == null ? void 0 : _Dialog$instance2.open({
        content: o,
        fullscreenResponsive: true,
        contentPadding: false,
        openPosition: openPosition,
        scrollable: false,
        openImmediately: false,
        closeOnTouchOutside: true,
        showCloseIcon: true
      });
    };
  };
  var renderContainer = function renderContainer() {
    if (currentWidth && currentWidth > 768) {
      var it = tempItems != null && tempItems.length > 0 ? (0, _toConsumableArray2.default)(tempItems) : (0, _toConsumableArray2.default)(items);
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.ScrollView, {
        style: {
          width: '100%',
          height: props.height,
          backgroundColor: 'transparent'
        },
        children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: currentWidth,
            position: 'relative',
            overflow: 'visible',
            left: 12,
            top: 12,
            minHeight: getMinHeight()
          },
          children: it.map(function (a) {
            var template = props.templates.find(function (c) {
              return c.id === a.widgetId;
            });
            if (template == null) return null;
            var width = a.width * colsWidth;
            var height = a.height * colsHeight;
            var position = {
              left: a.left * colsWidth,
              top: a.top * colsHeight
            };
            return (0, _jsxRuntime.jsx)(_WidgetItem.WidgetItem, {
              onDeleted: onDeleted(a.id),
              onMoved: onMoved(a.id),
              onMove: onMove(a.id),
              onResize: onResize(a.id),
              onResized: onResized(a.id),
              onEdit: template.optionWindow != null ? onOptions(template, a) : undefined,
              editMode: editMode,
              style: Object.assign({
                position: 'absolute'
              }, position, {
                width: width,
                height: height
              }),
              notDeletable: a.notDeletable,
              notEditable: a.notEditable,
              widgetKey: `${a.id}_${a.settings ? JSON.stringify(a.settings) : ''}`,
              children: template.component(a.settings)
            }, `widgetItem_${a.id}`);
          })
        })
      });
    }
    return (0, _jsxRuntime.jsx)(_reactNativeWeb.ScrollView, {
      style: {
        width: '100%',
        height: props.height,
        backgroundColor: 'transparent'
      },
      contentContainerStyle: {
        padding: 0,
        width: '100%',
        alignItems: 'center'
      },
      children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          width: '100%',
          padding: 16,
          paddingLeft: 12
        },
        children: items.map(function (a) {
          var template = props.templates.find(function (c) {
            return c.id === a.widgetId;
          });
          if (template == null) return null;
          var width = currentWidth;
          var height = a.height * colsHeight;
          return (0, _jsxRuntime.jsx)(_WidgetItem.WidgetItem, {
            widgetKey: `${a.id}_${a.settings ? JSON.stringify(a.settings) : ''}`,
            notMovable: true,
            notResizeable: true,
            onDeleted: onDeleted(a.id),
            onMove: onMove(a.id),
            onMoved: onMoved(a.id),
            onResized: onResized(a.id),
            onResize: onResize(a.id),
            onEdit: template.optionWindow != null ? onOptions(template, a) : undefined,
            editMode: editMode,
            style: {
              width: width,
              height: height
            },
            notDeletable: a.notDeletable,
            notEditable: a.notEditable,
            children: template.component(a.settings)
          }, `widgetItem_S_${a.id}`);
        })
      })
    });
  };
  var toggleEdit = function toggleEdit() {
    if (editMode) props.onChanged(items);
    setEditMode(!editMode);
  };
  var addItem = function addItem(e) {
    var i = (0, _toConsumableArray2.default)(items);
    i.push(e);
    var it = itemsTo2(i);
    setItems(it);
    props.onChanged(it);
  };
  var renderIcons = function renderIcons() {
    return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      style: {
        width: '100%',
        position: 'absolute',
        bottom: 16,
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'center'
      },
      pointerEvents: "box-none",
      children: editMode ? (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
        style: {
          width: 128,
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'center'
        },
        children: [(0, _jsxRuntime.jsx)(_Icon.Icon, {
          icon: "refresh",
          toolTip: _I18n.I18n.m.getMessage('reset'),
          backgroundColor: _ThemeManager.ThemeManager.style.brandPrimary,
          color: "#FFFFFF",
          onPress: function onPress() {
            _Routing.Routing.instance.alert.post({
              text: _I18n.I18n.m.getMessage('resetWidgetsQuestion'),
              buttons: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
                onPress: function onPress() {
                  var _Alert$instance3;
                  return (_Alert$instance3 = _Alert.Alert.instance) == null ? void 0 : _Alert$instance3.close();
                },
                title: _I18n.I18n.m.getMessage('cancel'),
                backgroundColor: "transparent",
                textColor: _ThemeManager.ThemeManager.style.brandPrimary
              }, "cancel"), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
                onPress: function onPress() {
                  var _Alert$instance4;
                  (_Alert$instance4 = _Alert.Alert.instance) == null ? void 0 : _Alert$instance4.close();
                  widgetPostions.current = new Map();
                  var it = itemsTo2(props.defaultItems);
                  setItems(it);
                  props.onChanged(it);
                  setEditMode(false);
                },
                title: _I18n.I18n.m.getMessage('reset')
              }, "close")]
            });
          }
        }, "refresh"), (0, _jsxRuntime.jsx)(_Icon.Icon, {
          icon: "close",
          toolTip: _I18n.I18n.m.getMessage('close'),
          backgroundColor: _ThemeManager.ThemeManager.style.brandPrimary,
          color: "#FFFFFF",
          onPress: toggleEdit
        }, "close"), (0, _jsxRuntime.jsx)(_Icon.Icon, {
          icon: "plus",
          toolTip: _I18n.I18n.m.getMessage('addWidget'),
          backgroundColor: _ThemeManager.ThemeManager.style.brandPrimary,
          color: "#FFFFFF",
          onPress: (0, _AddWidgetDialog.openAddWidgetDialog)({
            templates: props.templates,
            currentItems: (0, _toConsumableArray2.default)(items),
            addItem: addItem
          })
        }, "plus")]
      }) : (0, _jsxRuntime.jsx)(_Icon.Icon, {
        icon: "widgets",
        toolTip: _I18n.I18n.m.getMessage('edit'),
        backgroundColor: _ThemeManager.ThemeManager.style.brandPrimary,
        color: "#FFFFFF",
        onPress: toggleEdit
      }, "widget")
    });
  };
  var webStyle = editMode ? Object.assign({}, _ThemeManager.ThemeManager.noSelectionWebStyle()) : {};
  return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
    style: Object.assign({
      width: '100%',
      height: props.height
    }, webStyle),
    onLayout: function onLayout(l) {
      if (l.nativeEvent.layout.width) {
        setCurrentWith(l.nativeEvent.layout.width - 24);
      }
    },
    children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
      style: {
        width: '100%',
        height: props.height,
        position: 'relative'
      },
      children: [renderContainer(), renderIcons()]
    })
  });
}