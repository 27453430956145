import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React from 'react';
import { View } from 'react-native';
import QRCode from 'react-native-qrcode-svg';
import { I18n } from '../i18n/I18n';
import VCardGenerator from '../utils/VCardGenerator';
import { Download } from '../repo/file/Download';
import { DefaultErrorHandler } from './DefaultErrorHandler';
export function QRCodeView(props) {
    const generateQrCodeValue = () => {
        const { user, company } = props;
        let generator = null;
        if (user != null) {
            generator = VCardGenerator.fromUserEntity(user);
        }
        else if (company != null) {
            generator = VCardGenerator.fromCompanyEntity(company);
        }
        if (generator != null) {
            return generator.getAsVcard();
        }
        return '';
    };
    const downloadQrCode = async (_e) => {
        const { user, company } = props;
        let generator = null;
        try {
            if (user != null) {
                generator = await VCardGenerator.fromUserEntityWithLogo(user);
            }
            else if (company != null) {
                generator = await VCardGenerator.fromCompanyEntityWithLogo(company);
            }
            if (generator != null) {
                Download.downloadDataAsString('vcard.vcf', 'text/vcard', generator.getAsVcard()).catch((err) => {
                    DefaultErrorHandler.showDefaultErrorAlert(err);
                });
            }
        }
        catch (err) {
            DefaultErrorHandler.showDefaultErrorAlert(err);
        }
    };
    return (<View style={{ paddingTop: 28 }}>
      <QRCode value={generateQrCodeValue()} backgroundColor="transparent" size={256}/>
      <View style={{
            margin: ThemeManager.style.contentPaddingValue,
            justifyContent: 'center',
            flexDirection: 'row',
        }}>
        <ContainedButton backgroundColor="transparent" title={I18n.m.getMessage('userDetailsQrDownload')} onPress={downloadQrCode} textColor={ThemeManager.style.brandPrimary}/>
      </View>
    </View>);
}
