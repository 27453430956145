import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import { WidgetItemContainer } from 'materialTheme/src/theme/components/widgets/WidgetItemContainer';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { ChangeUserSettings } from 'upmesh-auth-core/src/client/commands/user/ChangeUserSettings';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { UserSettingsEntity } from 'upmesh-auth-core/src/client/query/entities/UserSettingsEntity';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { DeleteProjectAddress } from 'upmesh-core/src/client/commands/projectaddress/DeleteProjectAddress';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import * as uuid from 'uuid';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { I18n } from '../../i18n/I18n';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { FabWithCamera } from '../FabWithCamera';
import { IframeWidget } from '../root/board/IframeWidget';
import { IframeWidgetOptions } from '../root/board/IframeWidgetOptions';
import { DashboardWidgetPreview } from '../root/DashboardWidgetPreview';
import { PageView } from '../root/PageView';
import { ChartItemWidget } from './board/ChartItemWidget';
import { DashboardItemWidget } from './board/DashboardItemWidget';
import { DashboardItemWidgetOptions } from './board/DashboardItemWidgetOptions';
import { FocusTicketItem } from './board/FocusTicketItem';
import { ImageItem } from './board/ImageItem';
import { ImageItemOptions } from './board/ImageItemOptions';
import { LogsItem } from './board/LogsItem';
import { NotesWidget } from './board/NotesWidget';
import { ProjectAddressItem } from './board/ProjectAddressItem';
import { ProjectAddressItemOptions } from './board/ProjectAddressItemOptions';
import { ProjectItem } from './board/ProjectItem';
import { WeatherItem } from './board/WeatherItem';
import { CurrentProject } from './CurrentProject';
import { SpeechCommandDialog } from './SpeechCommandDialog';
import { CompanyUserInfo } from '../root/CompanyUserInfo';
export function ProjectView(props) {
    const [items, setItems] = useState();
    const [settingsLoaded, setSettingsLoaded] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [canEditAddress, setCanEditAddress] = useState();
    const [addresses, setAddresses] = useState(undefined);
    const [settings, setSettings] = useState(undefined);
    const { project } = props;
    if (project == null) {
        console.warn('no Project on ProjectView', props);
        return null;
    }
    const defaultItems = useRef([
        {
            widgetId: '10',
            settings: {},
            id: '1',
            width: 4,
            height: 6,
            top: 0,
            left: 0,
        },
        {
            widgetId: '4',
            settings: {},
            id: '2',
            width: 8,
            height: 6,
            top: 0,
            left: 4,
        },
        {
            widgetId: '8',
            settings: {},
            id: '3',
            width: 6,
            height: 7,
            top: 6,
            left: 0,
        },
        {
            widgetId: '3',
            settings: {},
            id: '4',
            width: 6,
            height: 7,
            top: 6,
            left: 6,
        },
        {
            widgetId: '12',
            settings: {},
            id: '5',
            width: 6,
            height: 6,
            top: 13,
            left: 0,
        },
        {
            widgetId: '9',
            settings: {},
            id: '6',
            width: 6,
            height: 6,
            top: 13,
            left: 6,
        },
        {
            widgetId: '13',
            settings: {},
            id: '7',
            width: 8,
            height: 10,
            top: 19,
            left: 4,
        },
        {
            widgetId: '11',
            settings: { type: 'tickets' },
            id: '8',
            width: 4,
            height: 2,
            top: 19,
            left: 0,
        },
        {
            widgetId: '11',
            settings: { type: 'plans' },
            id: '9',
            width: 4,
            height: 2,
            top: 21,
            left: 0,
        },
        {
            widgetId: '11',
            settings: { type: 'files' },
            id: '10',
            width: 4,
            height: 2,
            top: 23,
            left: 0,
        },
        {
            widgetId: '11',
            settings: { type: 'journals' },
            id: '11',
            width: 4,
            height: 2,
            top: 25,
            left: 0,
        },
        {
            widgetId: '11',
            settings: { type: 'team' },
            id: '12',
            width: 4,
            height: 2,
            top: 27,
            left: 0,
        },
    ]);
    const onDeleteProjectAddress = async (id) => {
        const c = new DeleteProjectAddress({}, id);
        await c.execute();
    };
    const loadAddresses = async () => {
        const adr = await UpmeshClient.instance.modals.projectAddress.get({
            filter: `projectId eq '${project.id}' and deleted ne true and type eq 'contact'`,
        });
        setAddresses(adr);
        return adr;
    };
    const loadCanEditAddress = async () => {
        if (canEditAddress == null) {
            const canE = await RightsManager.hasWriteRight(project.id, CurrentUser.userId, 'commandChangeProject');
            setCanEditAddress(canE);
        }
    };
    useEffect(() => {
        if (addresses == null || !settingsLoaded || canEditAddress == null)
            return;
        let i = items ? [...items] : [];
        if (settings != null &&
            settings.appSettings != null &&
            settings.appSettings[`projectDashboard_${CurrentProject.instance.getCurrentProjectId()}`]) {
            try {
                const it = JSON.parse(settings.appSettings[`projectDashboard_${CurrentProject.instance.getCurrentProjectId()}`]);
                setItems(it);
                i = [...it];
            }
            catch (err) {
                console.warn('cant load Global Dashboard Items', err);
            }
        }
        else {
            UpmeshClient.instance.modals.logBook
                .get({
                filter: `eventName eq ProjectCopied and entityId eq '${CurrentProject.instance.getCurrentProjectId()}'`,
                top: 1,
            })
                .then((data) => {
                if (data.length > 0 && data[0].data.oldProjectId != null) {
                    const { oldProjectId } = data[0].data;
                    if (settings != null &&
                        settings.appSettings != null &&
                        settings.appSettings[`projectDashboard_${oldProjectId}`]) {
                        try {
                            const it = settings.appSettings[`projectDashboard_${oldProjectId}`];
                            const appSettings = { ...settings.appSettings };
                            appSettings[`projectDashboard_${CurrentProject.instance.getCurrentProjectId()}`] = it;
                            const newSettings = new UserSettingsEntity({ ...CurrentUser.settings, appSettings });
                            const cCommand = new ChangeUserSettings(newSettings);
                            cCommand.execute(AuthClient.instance.commandStore).catch((err) => {
                                console.warn('cant save app settings project dashboard for copied project', err);
                            });
                            const obj = JSON.parse(it);
                            setItems(obj);
                            i = [...obj];
                        }
                        catch (err) {
                            console.warn('cant load Global Dashboard Items', err);
                        }
                    }
                }
            })
                .catch((err) => {
                console.error('cant load Global Dashboard Items', err);
            });
        }
        const adr = [...addresses];
        i.forEach((a, index) => {
            if (a.widgetId === '15') {
                const { addressId } = a.settings;
                if (addressId) {
                    const isIn = adr.find((b) => b.id === addressId && !b.deleted);
                    if (!isIn) {
                        i.splice(index, 1);
                    }
                }
            }
        });
        adr.forEach((a) => {
            const item = {
                widgetId: '15',
                settings: { addressId: a.id },
                id: a.id,
                height: 6,
                width: 6,
                notDeletable: !canEditAddress,
                notEditable: !canEditAddress,
                onDeleted: () => {
                    onDeleteProjectAddress(a.id).catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
                },
            };
            const isInDefault = defaultItems.current?.find((b) => b.widgetId === '15' && b.settings['addressId'] === a.id);
            if (!isInDefault) {
                defaultItems.current.push(item);
            }
            const isIn = i?.find((b) => b.widgetId === '15' && b.settings['addressId'] === a.id);
            if (!isIn) {
                i.push(item);
            }
            setItems(i);
        });
        setLoaded(true);
    }, [addresses, settings, canEditAddress, settingsLoaded]);
    useEffect(() => {
        if (items != null) {
            items?.forEach((a) => {
                if (a.widgetId === '15') {
                    a.notDeletable = !canEditAddress;
                    a.notEditable = !canEditAddress;
                    a.onDeleted = () => {
                        onDeleteProjectAddress(a.settings['addressId']).catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
                    };
                }
            });
        }
    }, [items]);
    const updateDashboard = (e) => {
        if (e && e.entities && e.entities.size > 0) {
            let settings = null;
            try {
                e.entities.forEach((a) => {
                    if (a.entity) {
                        settings = a.entity;
                    }
                });
                if (settings && settings['appSettings']) {
                    setSettings(settings);
                }
            }
            catch (err) {
                console.warn('cant load Global Dashboard Items', err);
            }
        }
    };
    const updateAddress = (e) => {
        if (e && e.entities && e.entities.size > 0) {
            let thisProject = false;
            try {
                e.entities.forEach((a) => {
                    if (a.entity && a.entity.projectId === project.id) {
                        thisProject = true;
                    }
                });
                if (thisProject) {
                    loadAddresses().catch((err) => console.warn(err));
                }
            }
            catch (err) {
                console.warn('cant load Global Dashboard Items', err);
            }
        }
    };
    useEffect(() => {
        const key = uuid.v4();
        const key2 = uuid.v4();
        UpmeshClient.eventDispatcher.attach({
            attachKey: key,
            readModelName: 'UserSettings',
            callback: updateDashboard,
        });
        UpmeshClient.eventDispatcher.attach({
            attachKey: key2,
            readModelName: 'ProjectAddress',
            callback: updateAddress,
        });
        loadCanEditAddress().catch((err) => console.warn(err));
        loadAddresses().catch((err) => console.warn(err));
        setSettings(CurrentUser.settings);
        setSettingsLoaded(true);
        return () => {
            UpmeshClient.eventDispatcher.detach('UserSettings', key);
            UpmeshClient.eventDispatcher.detach('ProjectAddress', key2);
        };
    }, []);
    const templates = [
        {
            id: '3',
            multiple: false,
            previewImage: DashboardWidgetPreview.fokusTickets,
            widgetName: I18n.m.getMessage('dashboardFocusTicketsItem'),
            component: (props) => (<FocusTicketItem {...props} projectId={CurrentProject.instance.getCurrentProjectId()}/>),
            defaultSize: { width: 6, height: 7 },
            minWidth: 4,
            minHeight: 7,
        },
        {
            id: '4',
            multiple: false,
            widgetName: I18n.m.getMessage('activities'),
            previewImage: DashboardWidgetPreview.activites,
            component: (props) => <LogsItem {...props} projectLogBook projectId={project?.id}/>,
            defaultSize: { width: 6, height: 6 },
            minWidth: 4,
            minHeight: 6,
            maxHeight: 8,
        },
        {
            id: '8',
            multiple: false,
            previewImage: DashboardWidgetPreview.overview,
            widgetName: I18n.m.getMessage('dashboardChartItemTicketOverview'),
            component: (props) => (<ChartItemWidget {...props} projectId={CurrentProject.instance.getCurrentProjectId()}/>),
            defaultSize: { width: 6, height: 6 },
            minWidth: 3,
            minHeight: 6,
        },
        {
            id: '9',
            multiple: false,
            previewImage: DashboardWidgetPreview.notes,
            widgetName: I18n.m.getMessage('dashboardNotes'),
            component: (props) => <NotesWidget {...props} projectId={CurrentProject.instance.getCurrentProjectId()}/>,
            defaultSize: { width: 6, height: 6 },
            minWidth: 3,
            minHeight: 6,
        },
        {
            id: '10',
            multiple: false,
            previewImage: DashboardWidgetPreview.projectInfo,
            widgetName: I18n.m.getMessage('projectInfo'),
            component: (props) => <ProjectItem {...props}/>,
            defaultSize: { width: 6, height: 5 },
            minWidth: 3,
            minHeight: 4,
        },
        {
            id: '11',
            multiple: true,
            previewImage: DashboardWidgetPreview.stats,
            widgetName: I18n.m.getMessage('statistics'),
            component: (props) => <DashboardItemWidget {...props}/>,
            defaultSettings: { type: 'tickets' },
            optionWindow: (props) => <DashboardItemWidgetOptions {...props}/>,
            defaultSize: { width: 3, height: 2 },
            minWidth: 3,
            minHeight: 2,
            maxHeight: 2,
        },
        {
            id: '12',
            previewImage: DashboardWidgetPreview.projectContact,
            multiple: false,
            widgetName: I18n.m.getMessage('projectContactLabel'),
            component: (props) => <ProjectAddressItem {...props} type="constructionSite" addAcs/>,
            defaultSize: { width: 6, height: 5 },
            minWidth: 3,
            minHeight: 4,
        },
        {
            id: '13',
            previewImage: DashboardWidgetPreview.weather,
            multiple: false,
            widgetName: I18n.m.getMessage('weather'),
            component: (props) => <WeatherItem {...props} project={project}/>,
            defaultSize: { width: 6, height: 5 },
            minWidth: 3,
            minHeight: 4,
        },
        {
            id: '14',
            previewImage: DashboardWidgetPreview.iFrame,
            multiple: true,
            widgetName: I18n.m.getMessage('website'),
            component: (props) => <IframeWidget {...props}/>,
            optionWindow: (props) => <IframeWidgetOptions {...props}/>,
            defaultSize: { width: 6, height: 6 },
            minWidth: 2,
            minHeight: 2,
        },
        {
            id: '15',
            previewImage: DashboardWidgetPreview.contact,
            multiple: true,
            widgetName: I18n.m.getMessage('contactPersons'),
            component: (props) => <ProjectAddressItem {...props} type="contact" hideEdit/>,
            optionWindow: (props) => (<ProjectAddressItemOptions {...props} projectId={project?.id} subTitle={I18n.m.getMessage('projectContactWidgetSubTitle')}/>),
            defaultSize: { width: 6, height: 6 },
            minWidth: 2,
            minHeight: 2,
        },
        {
            id: '16',
            previewImage: DashboardWidgetPreview.images,
            multiple: true,
            widgetName: I18n.m.getMessage('images'),
            component: (props) => <ImageItem {...props} projectId={project.id}/>,
            optionWindow: (props) => <ImageItemOptions {...props} projectId={project.id}/>,
            defaultSize: { width: 6, height: 6 },
            minWidth: 2,
            minHeight: 2,
        },
    ];
    const saveChanges = (e) => {
        try {
            if (CurrentUser.settings != null) {
                const appSettings = CurrentUser.settings.appSettings != null ? { ...CurrentUser.settings.appSettings } : {};
                appSettings[`projectDashboard_${CurrentProject.instance.getCurrentProjectId()}`] = JSON.stringify(e);
                const newSettings = new UserSettingsEntity({
                    ...CurrentUser.settings,
                    appSettings,
                });
                const c = new ChangeUserSettings(newSettings);
                c.execute(AuthClient.instance.commandStore).catch((err) => {
                    console.warn('cant save app settings global dashboard', err);
                });
            }
        }
        catch (e) {
            console.warn('cant save app settings global dashboard', e);
        }
        setItems(e);
    };
    const renderContent = () => {
        if (!loaded)
            return <Spinner />;
        const sViewHeight = props.size.windowWidth <= ThemeManager.style.breakpointM ? 48 : 0;
        return (<View style={{ width: props.size.contentWidth, height: props.size.contentHeight - ThemeManager.style.headerHeight }}>
        <WidgetItemContainer templates={templates} savedItems={items} defaultItems={defaultItems.current} height={props.size.contentHeight - ThemeManager.style.headerHeight - sViewHeight} onChanged={saveChanges}/>
      </View>);
    };
    const additionalActions = [];
    if (CompanyUserInfo.companySettings != null && CompanyUserInfo.companySettings.hasModule('moduleUpmeshKi')) {
        additionalActions.push({
            icon: 'robot',
            onPress: (_e) => {
                Dialog.instance?.open({
                    closeOnTouchOutside: false,
                    content: <SpeechCommandDialog projectId={project.id}/>,
                    contentPadding: false,
                    fullscreenResponsive: true,
                    scrollable: false,
                    showCloseIcon: true,
                });
            },
            text: I18n.m.getMessage('kiFabTitle'),
        });
    }
    return (<PageView headerProps={{ title: project != null && project.title ? project.title : '' }} style={{
            width: props.size.contentWidth,
            overflow: 'hidden',
            position: 'absolute',
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
            alignItems: 'center',
        }}>
      <FabWithCamera projectId={project.id} additionalActionButtons={additionalActions} size={props.size}/>
      {renderContent()}
    </PageView>);
}
