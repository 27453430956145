"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WorkingTimeModelEntity = exports.Daytime = void 0;
var ReadModelEntity_1 = require("cqrs-core/build/src/core/query/ReadModelEntity");
var OdataEntity_1 = require("odatarepos/build/src/entities/root/OdataEntity");
var Daytime = function () {
  function Daytime() {
    (0, _classCallCheck2.default)(this, Daytime);
  }
  return (0, _createClass2.default)(Daytime, null, [{
    key: "toString",
    value: function toString(d) {
      var inMinute = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var format = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '24';
      if (d != null) {
        if (inMinute) return `${d.hours * 60 + d.minutes}`;
        if (format === '24') {
          return `${d.hours}:${d.minutes < 10 ? `0${d.minutes}` : d.minutes}`;
        }
        var unit = 'am';
        var h = d.hours;
        if (h > 12) {
          h -= 12;
          unit = 'pm';
        }
        if (h === 0) h = 12;
        return `${h}:${d.minutes < 10 ? `0${d.minutes}` : d.minutes} ${unit}`;
      }
      return '';
    }
  }, {
    key: "toDate",
    value: function toDate(d) {
      return new Date(new Date().getFullYear(), 0, 0, d.hours, d.minutes);
    }
  }, {
    key: "toMs",
    value: function toMs(d) {
      return (d.hours * 60 + d.minutes) * 60000;
    }
  }, {
    key: "fromString",
    value: function fromString(input) {
      if (input == null || input.length < 1) {
        return {
          hours: 0,
          minutes: 0
        };
      }
      var splitted = input.split(/[.,:]/);
      if (splitted.length > 1) {
        return {
          hours: Number(splitted[0]),
          minutes: Number(splitted[1])
        };
      }
      return {
        hours: Number(splitted[0]),
        minutes: 0
      };
    }
  }, {
    key: "fromDate",
    value: function fromDate(input) {
      var working = new Date(input);
      return {
        hours: working.getHours(),
        minutes: working.getMinutes()
      };
    }
  }]);
}();
exports.Daytime = Daytime;
var WorkingTimeModelEntity = function (_ReadModelEntity_1$Re) {
  function WorkingTimeModelEntity(obj) {
    var _this;
    (0, _classCallCheck2.default)(this, WorkingTimeModelEntity);
    _this = _callSuper(this, WorkingTimeModelEntity, [obj]);
    _this.entityName = 'WorkingTimeModel';
    _this.postRecordingInDays = 7;
    _this.deleted = false;
    _this.fill(obj);
    return _this;
  }
  (0, _inherits2.default)(WorkingTimeModelEntity, _ReadModelEntity_1$Re);
  return (0, _createClass2.default)(WorkingTimeModelEntity, [{
    key: "sortSections",
    value: function sortSections() {
      this.sections.sort(function (a, b) {
        if (a.validFromMonth > b.validFromMonth) return 1;
        if (a.validFromMonth < b.validFromMonth) return -1;
        if (a.validFromDay > b.validFromDay) return 1;
        if (a.validFromDay < b.validFromDay) return -1;
        return 0;
      });
    }
  }, {
    key: "getCorrectDaytime",
    value: function getCorrectDaytime(date) {
      var bestDay = 0;
      var bestMonth = 0;
      this.sortSections();
      var index = this.sections.length > 0 && this.sections[0].validFromMonth === 1 ? 0 : this.sections.length - 1;
      for (var i = 0; i < this.sections.length; i += 1) {
        var section = this.sections[i];
        if (section.validFromMonth <= date.getMonth() + 1 && section.validFromDay <= date.getDate()) {
          if (section.validFromMonth > bestMonth) {
            bestDay = section.validFromDay;
            bestMonth = section.validFromMonth;
            index = i;
          } else if (section.validFromMonth === bestMonth && section.validFromDay > bestDay) {
            bestDay = section.validFromDay;
            index = i;
          }
        }
      }
      var bestFittingSection = this.sections[index];
      switch (date.getDay()) {
        case 1:
          return bestFittingSection.workingTime.mon != null ? bestFittingSection.workingTime.mon : null;
        case 2:
          return bestFittingSection.workingTime.tue != null ? bestFittingSection.workingTime.tue : null;
        case 3:
          return bestFittingSection.workingTime.wed != null ? bestFittingSection.workingTime.wed : null;
        case 4:
          return bestFittingSection.workingTime.thi != null ? bestFittingSection.workingTime.thi : null;
        case 5:
          return bestFittingSection.workingTime.fri != null ? bestFittingSection.workingTime.fri : null;
        case 6:
          return bestFittingSection.workingTime.sat != null ? bestFittingSection.workingTime.sat : null;
        case 0:
          return bestFittingSection.workingTime.sun != null ? bestFittingSection.workingTime.sun : null;
        default:
          return null;
      }
    }
  }, {
    key: "getTargetSumInMs",
    value: function getTargetSumInMs(date) {
      var d = this.getCorrectDaytime(date);
      if (d == null) return d;
      var start = Daytime.toMs(d.start);
      var ends = Daytime.toMs(d.stop);
      var pause = Daytime.toMs(d.pause);
      var total = ends - start - pause;
      return total;
    }
  }, {
    key: "createOne",
    value: function createOne(obj) {
      return new WorkingTimeModelEntity(obj);
    }
  }, {
    key: "createMap",
    value: function createMap() {
      var baseMap = ReadModelEntity_1.ReadModelEntity.createBaseMap();
      baseMap.set('companyId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('mark', new OdataEntity_1.OdataIndex(true));
      baseMap.set('sections', new OdataEntity_1.OdataIndex());
      baseMap.set('postRecordingInDays', new OdataEntity_1.OdataIndex());
      baseMap.set('foreignId', new OdataEntity_1.OdataIndex(true));
      baseMap.set('deleted', new OdataEntity_1.OdataIndex(true));
      return baseMap;
    }
  }]);
}(ReadModelEntity_1.ReadModelEntity);
exports.WorkingTimeModelEntity = WorkingTimeModelEntity;
WorkingTimeModelEntity.defaultTimeModelSection = {
  workingTime: {
    mon: {
      pause: {
        hours: 1,
        minutes: 0
      },
      start: {
        hours: 7,
        minutes: 0
      },
      stop: {
        hours: 16,
        minutes: 0
      }
    },
    tue: {
      pause: {
        hours: 1,
        minutes: 0
      },
      start: {
        hours: 7,
        minutes: 0
      },
      stop: {
        hours: 16,
        minutes: 0
      }
    },
    wed: {
      pause: {
        hours: 1,
        minutes: 0
      },
      start: {
        hours: 7,
        minutes: 0
      },
      stop: {
        hours: 16,
        minutes: 0
      }
    },
    thi: {
      pause: {
        hours: 1,
        minutes: 0
      },
      start: {
        hours: 7,
        minutes: 0
      },
      stop: {
        hours: 16,
        minutes: 0
      }
    },
    fri: {
      pause: {
        hours: 1,
        minutes: 0
      },
      start: {
        hours: 7,
        minutes: 0
      },
      stop: {
        hours: 16,
        minutes: 0
      }
    }
  },
  validFromDay: 1,
  validFromMonth: 1
};