"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _get2 = _interopRequireDefault(require("@babel/runtime/helpers/get"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeProjectTemplateStatus = void 0;
var CurrentUser_1 = require("upmesh-auth-core/build/src/client/CurrentUser");
var RightsManager_1 = require("../../../access/rights/RightsManager");
var CommandReadModels_1 = require("../../../server/webserver/commands/CommandReadModels");
var ProjectTemplateStatusChanged_1 = require("../../events/project/ProjectTemplateStatusChanged");
var ClientProjectCommands_1 = require("../_root/ClientProjectCommands");
var ChangeProjectTemplateStatus = function (_ClientProjectCommand) {
  function ChangeProjectTemplateStatus(data, projectId) {
    var _this;
    var token = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : CurrentUser_1.CurrentUser.token;
    (0, _classCallCheck2.default)(this, ChangeProjectTemplateStatus);
    _this = _callSuper(this, ChangeProjectTemplateStatus, [data, projectId, token]);
    _this.aggregate = 'project';
    _this.commandName = 'ChangeProjectTemplateStatus';
    return _this;
  }
  (0, _inherits2.default)(ChangeProjectTemplateStatus, _ClientProjectCommand);
  return (0, _createClass2.default)(ChangeProjectTemplateStatus, [{
    key: "getProject",
    value: function () {
      var _getProject = (0, _asyncToGenerator2.default)(function* () {
        if (this._project == null) this._project = yield CommandReadModels_1.CommandReadModels.project.getById(this.entityId);
        return this._project;
      });
      function getProject() {
        return _getProject.apply(this, arguments);
      }
      return getProject;
    }()
  }, {
    key: "validate",
    value: function () {
      var _validate = (0, _asyncToGenerator2.default)(function* (_key) {});
      function validate(_x) {
        return _validate.apply(this, arguments);
      }
      return validate;
    }()
  }, {
    key: "canI",
    value: function () {
      var _canI = (0, _asyncToGenerator2.default)(function* () {
        var userId = CurrentUser_1.CurrentUser.getUnVerifiedDataFromToken(this.token)._id;
        if (userId === 'all') return true;
        yield (0, _get2.default)((0, _getPrototypeOf2.default)(ChangeProjectTemplateStatus.prototype), "checkProjectStatusAndGeneralAccess", this).call(this, this.entityId, userId);
        var project = yield this.getProject();
        if (!(project.creator === userId || (yield RightsManager_1.RightsManager.hasWriteRight(this.entityId, userId, 'commandChangeProjectTemplateStatus')))) {
          throw {
            message: 'Keine Berechtigung',
            messageCode: 'forbiddenCommand'
          };
        }
        return true;
      });
      function canI() {
        return _canI.apply(this, arguments);
      }
      return canI;
    }()
  }, {
    key: "createEvents",
    value: function () {
      var _createEvents = (0, _asyncToGenerator2.default)(function* () {
        return new ProjectTemplateStatusChanged_1.ProjectTemplateStatusChanged(this.entityId, {
          template: this.data.template
        });
      });
      function createEvents() {
        return _createEvents.apply(this, arguments);
      }
      return createEvents;
    }()
  }]);
}(ClientProjectCommands_1.ClientProjectCommands);
exports.ChangeProjectTemplateStatus = ChangeProjectTemplateStatus;