import { Spinner } from 'materialTheme/src/theme/components/Spinner';
import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import { convertProject, ProjectFilter, } from 'upmesh-core/src/client/query/filter/ProjectFilter';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { DefaultErrorHandler } from '../../DefaultErrorHandler';
import { AppBar } from '../AppBar';
import { CompanyUserInfo } from '../CompanyUserInfo';
import { ProjectListItem } from '../ProjectListItem';
import { ProjectsOnMap } from '../ProjectsOnMap';
import { ProjectTable } from '../ProjectTable';
export function ProjectWidget(props) {
    const [searchWords, setSearchWords] = useState('');
    const [viewWidth, setViewWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [mapHeight, setMapHeight] = useState(0);
    const [projects, setProjects] = useState();
    const [visibleProjects, setVisibleProjects] = useState();
    const loadProjects = async () => {
        let projects = [];
        if (props.projects != null && props.projects.length > 0) {
            projects = await UpmeshClient.instance.modals.project.get({
                filter: `deleted ne true and inCreation ne true and (id in ${JSON.stringify(props.projects)})`,
                orderby: 'title',
            });
        }
        else {
            projects = await UpmeshClient.instance.modals.project.get({
                filter: 'deleted ne true and inCreation ne true',
                orderby: 'title',
            });
        }
        const projectsE = [];
        for (const project of projects) {
            const pe = await convertProject(project, CompanyUserInfo.company?.id);
            projectsE.push(pe);
        }
        setProjects(projectsE);
        if (props.filter != null) {
            try {
                const f = JSON.parse(props.filter);
                const filteredProjects = ProjectFilter.filterProjects(projectsE, f);
                setVisibleProjects(filteredProjects);
            }
            catch (err) {
                setVisibleProjects(projectsE);
                console.warn('cant filter Project in widgets', err);
            }
        }
        else {
            setVisibleProjects(projectsE);
        }
    };
    useEffect(() => {
        const key = UpmeshClient.eventDispatcher.attach({
            callback: () => {
                loadProjects().catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
            },
            readModelName: 'Project',
        });
        loadProjects().catch((err) => DefaultErrorHandler.showDefaultErrorAlert(err));
        return () => {
            UpmeshClient.eventDispatcher.detach('Project', key);
        };
    }, []);
    useEffect(() => {
        if (projects != null) {
            if (searchWords != null && searchWords.length > 0 && projects.length > 0) {
                if (props.filter != null) {
                    try {
                        const f = JSON.parse(props.filter);
                        const filteredProjects = ProjectFilter.filterProjects(projects, f);
                        const f2 = ProjectFilter.filterProjectsByText(searchWords, filteredProjects);
                        setVisibleProjects(f2);
                    }
                    catch (err) {
                        setVisibleProjects(projects);
                        console.warn('cant filter Project in widgets', err);
                    }
                }
                else {
                    const f = ProjectFilter.filterProjectsByText(searchWords, projects);
                    setVisibleProjects(f);
                }
            }
            else if (props.filter != null) {
                try {
                    const f = JSON.parse(props.filter);
                    const filteredProjects = ProjectFilter.filterProjects(projects, f);
                    setVisibleProjects(filteredProjects);
                }
                catch (err) {
                    setVisibleProjects(projects);
                    console.warn('cant filter Project in widgets', err);
                }
            }
            else {
                setVisibleProjects(projects);
            }
        }
    }, [searchWords, props.filter]);
    if (projects == null || visibleProjects == null)
        return <Spinner />;
    const renderListRow = ({ item }) => {
        return <ProjectListItem key={`project_${item.id}`} project={item}/>;
    };
    const renderList = () => {
        if (projects == null)
            return null;
        return (<ScrollView style={{ width: '100%', height: '100%' }}>
        {visibleProjects.map((p) => renderListRow({ item: p }))}
      </ScrollView>);
    };
    const renderTable = () => {
        return <ProjectTable projects={visibleProjects} tableName="widgets" height={height - 16}/>;
    };
    const renderAppBar = () => {
        return (<AppBar textColor="#FFFFFF" backgroundColor="transparent" searchBarProps={{
                backgroundColor: 'transparent',
                searchBarPlaceholder: props.title && props.title.length > 0 ? props.title : I18n.m.getMessage('myProjects'),
                searchOnChange: (a) => setSearchWords(a),
                tooltip: I18n.m.getMessage('searchProjects'),
                onlyAppBar: true,
                searchBarValue: searchWords,
                maxWidth: 10000,
            }} leftButtons={[]} withElevation={false} withBorder={false} hideMenu showAccountIcon={false}/>);
    };
    if (props.showMap && viewWidth > 500) {
        return (<View style={{ width: '100%', height: '100%' }}>
        <View style={{ width: '100%', height: '100%', flexDirection: 'row' }}>
          <View style={{ height: '100%', flex: 1 }} onLayout={(e) => setMapHeight(e.nativeEvent.layout.height)}>
            <View style={{ padding: 0, width: '100%' }}>{renderAppBar()}</View>
            <View style={{ flex: 1, width: '100%' }} onLayout={(e) => {
                setHeight(e.nativeEvent.layout.height);
                setViewWidth(e.nativeEvent.layout.width * 2);
            }}>
              {props.view === 'list' ? renderList() : renderTable()}
            </View>
          </View>
          {mapHeight > 0 && <ProjectsOnMap mapHeight={mapHeight} projects={visibleProjects}/>}
        </View>
      </View>);
    }
    return (<View style={{ width: '100%', height: '100%' }}>
      {props.title != null && props.title.length > 0 && (<View style={{ padding: 0, width: '100%' }}>{renderAppBar()}</View>)}
      <View style={{ flex: 1, width: '100%' }} onLayout={(e) => {
            setHeight(e.nativeEvent.layout.height);
            setViewWidth(e.nativeEvent.layout.width);
        }}>
        {props.view === 'list' ? renderList() : renderTable()}
      </View>
    </View>);
}
