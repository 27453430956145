var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LoginForm = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-client/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-client/node_modules/react-native-web");
var _AuthClient = require("upmesh-auth-core/build/src/client/AuthClient");
var _Login = require("upmesh-auth-core/build/src/client/commands/guest/Login");
var _Alert = require("../../../theme/components/Alert");
var _ContainedButton = require("../../../theme/components/button/ContainedButton");
var _CheckboxListItem = require("../../../theme/components/CheckboxListItem");
var _Form = require("../../../theme/components/forminput/Form");
var _Toast = require("../../../theme/components/Toast");
var _I18n = require("../../../theme/i18n/I18n");
var _LoadingEvents = require("../../../theme/routing/LoadingEvents");
var _Routing = require("../../../theme/routing/Routing");
var _ThemeManager = require("../../../theme/ThemeManager");
var _SocialLogin = require("./SocialLogin");
var _jsxRuntime = require("../../../../../../upmesh-client/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var LoginForm = exports.LoginForm = function (_PureComponent) {
  function LoginForm(props) {
    var _this;
    (0, _classCallCheck2.default)(this, LoginForm);
    _this = _callSuper(this, LoginForm, [props]);
    _this.onSaved = function (result) {
      _LoadingEvents.LoadingEvents.instance.startLoading();
      _AuthClient.AuthClient.instance.logIn(result).catch(function (e) {
        return void 0;
      });
    };
    _this.onChangeStayLoggenIn = function (value) {
      _AuthClient.AuthClient.instance.stayLoggedIn = value;
    };
    _this.abort = function () {
      var _Alert$instance;
      (_Alert$instance = _Alert.Alert.instance) == null ? void 0 : _Alert$instance.close();
    };
    _this.openContact = function () {
      var _Alert$instance2;
      (_Alert$instance2 = _Alert.Alert.instance) == null ? void 0 : _Alert$instance2.close();
      _reactNativeWeb.Linking.openURL('https://upmesh.de/kontakt/').catch(function (e) {
        return void 0;
      });
    };
    _this.catchError = function (e, data) {
      if (e['messageCode'] != null && e['messageCode'] === 'loginErrorNoAccount') {
        _Routing.Routing.instance.alert.post({
          text: _I18n.I18n.m.getMessage('loginErrorNoAccount'),
          buttons: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            title: _I18n.I18n.m.getMessage('close'),
            onPress: _this.abort,
            backgroundColor: "transparent",
            textColor: _ThemeManager.ThemeManager.style.brandPrimary
          }, "okButton"), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            title: _I18n.I18n.m.getMessage('loginErrorNoAccountRegister'),
            onPress: _this.registerNow(data.email),
            backgroundColor: "transparent",
            textColor: _ThemeManager.ThemeManager.style.brandPrimary
          }, "registerButton")]
        });
        return true;
      }
      if (e['messageCode'] != null && e['messageCode'] === 'accountMarkedForDeletion') {
        _Routing.Routing.instance.alert.post({
          text: _I18n.I18n.m.getMessage('accountMarkedForDeletionDialogText'),
          buttons: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            title: _I18n.I18n.m.getMessage('close'),
            onPress: _this.abort,
            backgroundColor: "transparent",
            textColor: _ThemeManager.ThemeManager.style.brandPrimary
          }, "okButton"), (0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
            title: _I18n.I18n.m.getMessage('accountMarkedForDeletionDialogReactivateButton'),
            onPress: _this.openContact,
            backgroundColor: "transparent",
            textColor: _ThemeManager.ThemeManager.style.brandPrimary
          }, "contactButton")]
        });
        return true;
      }
      return false;
    };
    _this.registerNow = function (email) {
      return function (_e) {
        var _Toast$instance, _Alert$instance3;
        (_Toast$instance = _Toast.Toast.instance) == null ? void 0 : _Toast$instance.close();
        (_Alert$instance3 = _Alert.Alert.instance) == null ? void 0 : _Alert$instance3.close(function () {
          _Routing.Routing.instance.goTo(`/register?email=${email}`);
        });
      };
    };
    _AuthClient.AuthClient.instance.stayLoggedIn = _reactNativeWeb.Platform.OS !== 'web';
    var defaultemail = '';
    if (_this.props.email != null && _this.props.email.length > 0) {
      defaultemail = _this.props.email;
    }
    _this.state = {
      defaultemail: defaultemail,
      offlineUsers: [],
      isLoading: false,
      layoutKnown: false,
      emailTextFieldActive: false,
      emailError: '',
      pwTextFieldActive: false,
      pwError: '',
      allError: ''
    };
    return _this;
  }
  (0, _inherits2.default)(LoginForm, _PureComponent);
  return (0, _createClass2.default)(LoginForm, [{
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      if (_reactNativeWeb.Keyboard != null) {
        _reactNativeWeb.Keyboard.dismiss();
      }
    }
  }, {
    key: "render",
    value: function render() {
      var dataOptions = new Map();
      dataOptions.set('email', {
        formType: 'email',
        cols: 1,
        props: {
          labelText: _I18n.I18n.m.getMessage('email'),
          autoFocus: _reactNativeWeb.Platform.OS === 'web'
        }
      });
      dataOptions.set('password', {
        formType: 'password',
        cols: 1,
        props: {
          labelText: _I18n.I18n.m.getMessage('password')
        }
      });
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          width: '100%',
          alignContent: 'center'
        },
        children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          style: {
            width: '100%',
            alignItems: 'stretch'
          },
          children: [this.renderSocialLogin(), (0, _jsxRuntime.jsx)(_Form.Form, {
            baseCols: 1,
            dataOptions: dataOptions,
            catchError: this.catchError,
            store: _AuthClient.AuthClient.instance.commandStore,
            command: new _Login.Login({}),
            saveButtonLabel: _I18n.I18n.m.getMessage('login'),
            onSaved: this.onSaved,
            additionalButtons: [(0, _jsxRuntime.jsx)(_ContainedButton.ContainedButton, {
              onPress: _Routing.Routing.instance.openDialog('forgotpw'),
              title: _I18n.I18n.m.getMessage('loginForgotPw'),
              backgroundColor: "transparent",
              textColor: _ThemeManager.ThemeManager.style.brandPrimary
            }, "forgotButton")],
            children: _reactNativeWeb.Platform.OS === 'web' ? (0, _jsxRuntime.jsx)(_CheckboxListItem.CheckboxListItem, {
              initValue: _AuthClient.AuthClient.instance.stayLoggedIn,
              title: _I18n.I18n.m.getMessage('stayLoggegIn'),
              onChange: this.onChangeStayLoggenIn
            }) : null
          })]
        })
      });
    }
  }, {
    key: "renderSocialLogin",
    value: function renderSocialLogin() {
      return _reactNativeWeb.Platform.OS !== 'ios' ? (0, _jsxRuntime.jsx)(_SocialLogin.SocialLogin, {}) : null;
    }
  }]);
}(_react.PureComponent);