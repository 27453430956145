import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { Form } from 'materialTheme/src/theme/components/forminput/Form';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { PureComponent } from 'react';
import { ClientStore } from 'upmesh-core/src/client/ClientStore';
import { ChangeFolder } from 'upmesh-core/src/client/commands/folder/ChangeFolder';
import { FolderEntity } from 'upmesh-core/src/client/query/entities/FolderEntity';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { FolderAccessComponent } from './FolderAccessComponent';
import { FolderTagsComponent } from './FolderTagsComponent';
export class ChangeFolderDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.onSaved = () => {
            Dialog.instance?.close();
        };
        this.accessComponent = (props) => {
            const { folder } = this.props;
            return <FolderAccessComponent folder={folder} {...props}/>;
        };
        this.tagsComponent = (props) => {
            const { folder } = this.props;
            return <FolderTagsComponent folder={folder} {...props}/>;
        };
        this.state = { hasSubFolders: false, init: false, maxLength: FolderEntity.maxPathLength };
    }
    componentDidMount() {
        this.init().catch((err) => console.debug(err));
    }
    async init() {
        const { folder } = this.props;
        const hasSubFolders = await UpmeshClient.instance.modals.folder.count(`subFolderFromId eq '${folder.id}' and deleted ne true`);
        let maxLength = FolderEntity.maxPathLength;
        if (folder.subFolderFromId != null && folder.subFolderFromId.length > 0) {
            const parentLength = await FolderEntity.getPathLength(folder.subFolderFromId);
            if (parentLength != null && parentLength.pathLength > 0)
                maxLength -= parentLength.pathLength;
        }
        this.setState({ init: true, hasSubFolders: hasSubFolders > 0, maxLength });
    }
    static openDialog(props) {
        Dialog.instance?.open({
            content: <ChangeFolderDialog {...props}/>,
            fullscreenResponsive: true,
            contentPadding: false,
            scrollable: false,
            showCloseIcon: true,
        });
    }
    render() {
        const { folder } = this.props;
        const { hasSubFolders, maxLength } = this.state;
        const command = new ChangeFolder({
            title: folder.title,
            access: folder.access,
            tags: folder.tags,
            description: folder.description,
        }, folder.id);
        const dataOptions = new Map();
        dataOptions.set('title', {
            formType: 'string',
            cols: 1,
            props: { labelText: I18n.m.getMessage('folderName'), maxLength },
        });
        dataOptions.set('description', {
            formType: 'string',
            optional: true,
            cols: 1,
            props: { labelText: I18n.m.getMessage('folderDescription'), numberOfLines: 4 },
        });
        dataOptions.set('tags', {
            formType: 'individiual',
            optional: true,
            cols: 1,
            props: { labelText: I18n.m.getMessage('tags'), numberOfLines: 4 },
            component: this.tagsComponent,
        });
        dataOptions.set('access', {
            formType: 'individiual',
            optional: true,
            cols: 1,
            props: { labelText: I18n.m.getMessage('folderAccess'), numberOfLines: 4 },
            component: this.accessComponent,
        });
        if (hasSubFolders) {
            const pickerList = [
                { title: I18n.m.getMessage('folderAccessOnlyFolder'), data: '' },
                { title: I18n.m.getMessage('folderAccessAddToSubFolder'), data: 'add' },
                { title: I18n.m.getMessage('folderAccessOverwriteSubFolder'), data: 'overwrite' },
            ];
            dataOptions.set('changeSubFolderRights', {
                formType: 'Picker',
                optional: true,
                cols: 1,
                props: { labelText: I18n.m.getMessage('folderTakeOverRights'), list: pickerList },
                mapValue: (value, fromFormInput) => {
                    if (fromFormInput)
                        return value.value.data;
                    let index = 0;
                    for (let i = 0; i < pickerList.length; i += 1) {
                        if (pickerList[i].data === value) {
                            index = i;
                            break;
                        }
                    }
                    const value2 = pickerList[index];
                    return { index, value: value2 };
                },
            });
        }
        return (<Form baseCols={1} stickyHeader stickyActions showButtonsIfOffline command={command} dataOptions={dataOptions} saveButtonLabel={I18n.m.getMessage('save')} onSaved={this.onSaved} store={ClientStore.commandStore} formHeaderProps={{ formTitle: I18n.m.getMessage('changeFolder') }} additionalButtons={[
                <ContainedButton key="abort" title={I18n.m.getMessage('cancel')} onPress={Dialog.instance?.close} backgroundColor="#FFFFFF" textColor={ThemeManager.style.brandPrimary}/>,
            ]}/>);
    }
}
