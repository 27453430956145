import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Dialog } from 'materialTheme/src/theme/components/Dialog';
import { ListItem } from 'materialTheme/src/theme/components/ListItem';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { LoadingEvents } from 'materialTheme/src/theme/routing/LoadingEvents';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { UnArchiveProject } from 'upmesh-core/src/client/commands/project/UnArchiveProject';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../i18n/I18n';
import { OfflineDataDownloader } from '../../repo/file/OfflineDataDownloader';
import { DefaultErrorHandler } from '../DefaultErrorHandler';
import { StoredFileDownloader } from '../file/StoredFileDownloader';
import { StoredFileDownloaderOptions } from '../file/StoredFileDownloaderOptions';
import { CurrentProject } from '../project/CurrentProject';
import { ProjectImage } from '../project/ProjectImage';
import { DeleteProjectDialog } from '../project/settings/DeleteProjectDialog';
import { OfflineProjectSettingsPU } from './OfflineProjectSettingsPU';
export function ProjectListItem(props) {
    const [offlineProjects, setOfflineProjects] = useState([...OfflineDataDownloader.getProjects()]);
    const item = props.project;
    const updateOfflineProject = (_e) => {
        const ind = offlineProjects.findIndex((p) => p.settings.projectId === _e.settings.projectId);
        if (ind > -1) {
            offlineProjects[ind] = _e;
        }
        setOfflineProjects([...offlineProjects]);
    };
    const updateOfflineProjects = (e) => {
        setOfflineProjects([...e]);
    };
    useEffect(() => {
        OfflineDataDownloader.event.attach(updateOfflineProjects);
        OfflineDataDownloader.progress.attach(updateOfflineProject);
        return () => {
            OfflineDataDownloader.event.detach(updateOfflineProjects);
            OfflineDataDownloader.progress.detach(updateOfflineProject);
        };
    }, []);
    const waitForSync = async () => {
        if (AuthClient.instance != null && AuthClient.instance.serverConnection != null) {
            await new Promise((resolve) => {
                AuthClient.instance.syncDispatcher.syncStop.once(() => {
                    resolve();
                });
                if (AuthClient.instance != null) {
                    AuthClient.instance.startSync().catch((err) => console.debug(err));
                }
                else {
                    resolve();
                }
            });
        }
    };
    const onPressDownloadProject = async (_e) => {
        Dialog.instance?.close();
        if (!UpmeshClient.instance.serverConnected()) {
            Routing.instance.alert.post({
                text: I18n.m.getMessage('commandOfflineNotPossible'),
            });
        }
        else {
            LoadingEvents.instance.startLoading();
            try {
                const link = await CurrentProject.instance.createArchive(item.id);
                if (link != null) {
                    const orgFilename = `${CurrentProject.instance.getCurrentProject()?.title}.zip`;
                    StoredFileDownloader.downloadFile(new StoredFileDownloaderOptions({
                        link,
                        orgFilename,
                    })).catch((e) => {
                        console.error('cant download', link, e);
                        DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m, e);
                        throw e;
                    });
                }
            }
            catch (e) {
                console.debug('Archive Error', e);
                DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m);
            }
            LoadingEvents.instance.stopLoading();
        }
    };
    const onPressReactivateProject = async (_e) => {
        Dialog.instance?.close();
        if (item == null)
            return;
        LoadingEvents.instance.startLoading();
        try {
            const c = new UnArchiveProject({}, item.id);
            await c.execute();
            await waitForSync();
            Routing.instance.goTo(`/projects/${item.id}/project`);
        }
        catch (e) {
            console.debug('UnArchive Error', e);
            DefaultErrorHandler.showDefaultErrorAlert(e, I18n.m);
        }
        LoadingEvents.instance.stopLoading();
    };
    const onPressDeleteProject = async (_e) => {
        if (item == null)
            return;
        Dialog.instance?.open({
            scrollable: false,
            showCloseIcon: true,
            contentPadding: false,
            content: <DeleteProjectDialog projectId={item.id} downloadAction={onPressDownloadProject}/>,
        });
    };
    const openArchiveDialog = (_e) => {
        Dialog.instance?.open({
            content: I18n.m.getMessage('projectUnarchiveProjectDialogText'),
            title: I18n.m.getMessage('projectUnarchiveProjectDialogTitle'),
            showCloseIcon: true,
            closeOnTouchOutside: true,
            buttons: [
                <ContainedButton key="download" title={I18n.m.getMessage('projectUnarchiveProjectDialogButtonDownload')} onPress={onPressDownloadProject} textColor="#FFFFFF" backgroundColor={ThemeManager.style.brandPrimary}/>,
                <ContainedButton key="archive" textColor="#FFFFFF" backgroundColor={ThemeManager.style.brandPrimary} title={I18n.m.getMessage('projectUnarchiveProjectDialogButtonReactivate')} onPress={onPressReactivateProject}/>,
                <ContainedButton key="archive" backgroundColor={ThemeManager.style.brandDanger} title={I18n.m.getMessage('projectUnarchiveProjectDialogButtonDelete')} onPress={onPressDeleteProject}/>,
            ],
        });
    };
    let icon;
    let textRight = '';
    if (Platform.OS !== 'web') {
        const offlineIndex = offlineProjects.findIndex((o) => o.settings.projectId === item.id);
        icon = {
            icon: 'cloud-outline',
            color: props.textColor ? props.textColor : undefined,
            toolTip: I18n.m.getMessage('projectDownloadOfflineUnavailable'),
            onPress: OfflineProjectSettingsPU.open(item.id),
        };
        if (offlineIndex > -1) {
            const offline = offlineProjects[offlineIndex];
            if (offline.status !== 'removed') {
                if (offline.status === 'isSyncing' && offline.percentSynced != null) {
                    textRight = `${offline.percentSynced}%`;
                    icon = {
                        icon: 'cloud-sync-outline',
                        color: props.textColor ? props.textColor : undefined,
                        toolTip: I18n.m.getMessage('projectDownloadOfflineAvailableSyncing'),
                        onPress: OfflineProjectSettingsPU.open(item.id),
                    };
                }
                else {
                    icon = {
                        icon: 'cloud-check-outline',
                        color: props.textColor ? props.textColor : undefined,
                        toolTip: I18n.m.getMessage('projectDownloadOfflineAvailable'),
                        onPress: OfflineProjectSettingsPU.open(item.id),
                    };
                }
            }
        }
    }
    if (item.archived) {
        icon = {
            icon: 'archive-outline',
            color: props.textColor ? props.textColor : undefined,
            toolTip: I18n.m.getMessage('projectUnarchiveProjectDialogTitle'),
            onPress: openArchiveDialog,
        };
    }
    return (<ListItem key={`project_${item.id}`} thumbnail={{
            thumbnail: (<Ripple style={{ width: 40, height: 40, padding: 0 }} onPress={Routing.instance.goToButton(`projects/${item.id}/project`)}>
            <ProjectImage size={40} project={item}/>
          </Ripple>),
            width: 40,
        }} hoverColor={props.hoverColor ? props.hoverColor : undefined} title={item.title} onPress={item.archived ? openArchiveDialog : Routing.instance.goToButton(`projects/${item.id}/project`)} backgroundColor="transparent" textColor={props.textColor ? props.textColor : undefined} iconRight={icon} textRight={textRight}/>);
}
