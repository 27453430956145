"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OfflineCommands = void 0;
var CommandOfflineRegister_1 = require("cqrs-core/build/src/client/commands/CommandOfflineRegister");
var ChangePhone_1 = require("./user/ChangePhone");
var ChangeUserAdress_1 = require("./user/ChangeUserAdress");
var ChangeUserCompany_1 = require("./user/ChangeUserCompany");
var ChangeUserData_1 = require("./user/ChangeUserData");
var ChangeUserImageId_1 = require("./user/ChangeUserImageId");
var ChangeUserSettings_1 = require("./user/ChangeUserSettings");
var RemoveUserImage_1 = require("./user/RemoveUserImage");
var ChangeUserNames_1 = require("./user/ChangeUserNames");
var SetLocale_1 = require("./user/SetLocale");
var OfflineCommands = function () {
  function OfflineCommands() {
    (0, _classCallCheck2.default)(this, OfflineCommands);
  }
  return (0, _createClass2.default)(OfflineCommands, null, [{
    key: "registerOfflineCommands",
    value: function registerOfflineCommands() {
      var offlineCommands = [new ChangeUserAdress_1.ChangeUserAdress({}), new ChangeUserCompany_1.ChangeUserCompany({}), new ChangeUserImageId_1.ChangeUserImageId({}), new RemoveUserImage_1.RemoveUserImage({}), new ChangeUserNames_1.ChangeUserNames({}), new ChangePhone_1.ChangePhone({}), new ChangeUserData_1.ChangeUserData({}, '', ''), new SetLocale_1.SetLocale({}), new ChangeUserSettings_1.ChangeUserSettings({})];
      for (var c of offlineCommands) {
        CommandOfflineRegister_1.CommandOfflineRegister.registerCommands(c);
      }
    }
  }]);
}();
exports.OfflineCommands = OfflineCommands;