import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { DateLocale } from 'upmesh-i18n/src/i18n/DateLocale';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { CurrentUser } from 'upmesh-auth-core/src/client/CurrentUser';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { RightsManager } from 'upmesh-core/src/access/rights/RightsManager';
import { I18n } from '../../../i18n/I18n';
import { CurrentProject } from '../CurrentProject';
import { DashBoardItem } from './DashBoardItem';
export class TeamItem extends PureComponent {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.addTeamMember = async (e) => {
            Routing.instance.openDialog('addTeam')(e);
        };
        this.loadDashData = async () => {
            await DateLocale.waitFor(() => UpmeshClient.instance.ready);
            const projectId = CurrentProject.instance.getCurrentProjectId();
            const userId = CurrentUser.entity.id;
            if (projectId != null && this.mounted) {
                const canRead = await RightsManager.hasReadRight(projectId, userId, 'teams');
                if (!canRead) {
                    return;
                }
                const counts = (await UpmeshClient.instance.modals.projectUser.count(`projectId eq '${projectId}' and status eq 'accepted'`)) +
                    1;
                this.setState({ counts, projectId, canRead }, () => { });
            }
        };
        this.state = {
            counts: CurrentProject.instance.getCurrentProjectTeam().length,
            projectId: props.projectId,
            canRead: CurrentProject.instance.getCurrentRights().read.teams,
        };
    }
    componentDidMount() {
        this.mounted = true;
        CurrentProject.teamChanged.attach(this.loadDashData);
        this.loadDashData().catch((err) => console.debug(err));
    }
    componentWillUnmount() {
        this.mounted = false;
        CurrentProject.teamChanged.detach(this.loadDashData);
    }
    render() {
        const { counts, projectId, canRead } = this.state;
        if (!canRead) {
            return null;
        }
        return (<Ripple style={{ width: '100%', paddingBottom: 16 }} onPress={Routing.instance.goToButton(`/projects/${projectId}/team`)}>
        <View style={{ width: '100%' }}>
          <DashBoardItem title={I18n.m.getMessage('menuProjectInvolved')} icon="account-multiple-outline" iconColor="#80CBC4" counts={counts}/>
        </View>
      </Ripple>);
    }
}
