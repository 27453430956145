import Color from 'color';
import { PromisePool } from 'cqrs-shared/src/PromisePool';
import { ContainedButton } from 'materialTheme/src/theme/components/button/ContainedButton';
import { Card } from 'materialTheme/src/theme/components/Card';
import { FilterChips } from 'materialTheme/src/theme/components/chips/FilterChips';
import { DialogBetween } from 'materialTheme/src/theme/components/DialogBetween';
import { Icon } from 'materialTheme/src/theme/components/Icon';
import { SearchBar } from 'materialTheme/src/theme/components/SearchBar';
import { MaterialText, MaterialTextTypes } from 'materialTheme/src/theme/components/text/MaterialText';
import { Ripple } from 'materialTheme/src/theme/components/utils/Ripple';
import { ResizeEvent } from 'materialTheme/src/theme/ResizeEvent';
import { Routing } from 'materialTheme/src/theme/routing/Routing';
import { ThemeManager } from 'materialTheme/src/theme/ThemeManager';
import { SimpleStorage } from 'odatarepos/src/db/SimpleStorage';
import React, { useEffect, useRef, useState } from 'react';
import { FlatList, View } from 'react-native';
import { AuthClient } from 'upmesh-auth-core/src/client/AuthClient';
import { UpmeshClient } from 'upmesh-core/src/client/UpmeshClient';
import { I18n } from '../../../i18n/I18n';
import { TicketCard } from '../../tickets/TicketCard';
import { TicketEntitySynced } from '../../tickets/TicketEntitySynced';
import { CommentCard } from './CommentCard';
import { FileCard } from './FileCard';
import { PlanCard } from './PlanCard';
import { ProjectCard } from './ProjectCard';
const numberOfShownElements = 3;
export function GlobalSearch(props) {
    const [searchText, setSearchText] = useState(props.initialText ? props.initialText : '');
    const [foundTickets, setFoundTickets] = useState([]);
    const [showAllTickets, setShowAllTickets] = useState(false);
    const [foundPlans, setFoundPlans] = useState([]);
    const [showAllPlans, setShowAllPlans] = useState(false);
    const [foundProjects, setFoundProjects] = useState([]);
    const [showAllProjects, setShowAllProjects] = useState(false);
    const [foundFiles, setFoundFiles] = useState([]);
    const [showAllFiles, setShowAllFiles] = useState(false);
    const [foundComments, setFoundComments] = useState([]);
    const [showAllComments, setShowAllComments] = useState(false);
    const [selectedAnyFilter, setSelectedAnyFilter] = useState(false);
    const [filterChips, setFilterChips] = useState([
        { title: I18n.m.getMessage('projects') },
        { title: I18n.m.getMessage('plans') },
        { title: I18n.m.getMessage('tickets') },
        { title: I18n.m.getMessage('files') },
        { title: I18n.m.getMessage('ticketComments') },
    ]);
    const [lastSearches, setLastSearches] = useState([]);
    const searchBar = useRef(null);
    const mounted = useRef(false);
    const searchTimer = React.useRef(null);
    const init = async () => {
        const searches = await SimpleStorage.get('globalSearch');
        if (searches != null) {
            const searchArray = searches.split(';');
            setLastSearches(searchArray);
        }
    };
    useEffect(() => {
        if (mounted.current === false && searchBar !== null) {
            mounted.current = true;
            searchBar.current.focus();
            init().catch((err) => console.debug(err));
        }
    }, []);
    const getTicketData = async (t) => {
        const tu = new TicketEntitySynced(t);
        try {
            if (t.approverUserId != null && t.approverUserId.length > 1) {
                const user = await AuthClient.instance.modals.user.getById(t.approverUserId);
                tu.approverUser = user;
                tu.approver = user.getFullName();
            }
            if (t.assignedToUserId != null && t.assignedToUserId.length > 1) {
                const user = await AuthClient.instance.modals.user.getById(t.assignedToUserId);
                tu.assigneeUser = user;
                tu.assignee = user.getFullName();
            }
        }
        catch (e) {
            console.debug('cant get user');
        }
        return tu;
    };
    const renderHeadline = (name, amount) => {
        let text = I18n.m.getMessage(name).toUpperCase();
        if (amount != null)
            text = `${text} (${amount})`;
        return (<View style={{ paddingLeft: 8 }}>
        <MaterialText type={MaterialTextTypes.Caption} strong color={ThemeManager.style.black54}>
          {text}
        </MaterialText>
        {amount === 0 ? <MaterialText>-</MaterialText> : null}
      </View>);
    };
    const maxFlatListHeight = ResizeEvent.current.contentHeight / 2;
    const renderProject = ({ item }) => {
        return <ProjectCard project={item} key={`projects${item.id}`}/>;
    };
    const renderProjects = () => {
        if (foundProjects.length === 0)
            return <View />;
        const iMaxLength = showAllProjects || foundProjects.length < numberOfShownElements ? foundProjects.length : numberOfShownElements;
        const projects = foundProjects.slice(0, iMaxLength);
        return (<View style={{ paddingTop: 8 }}>
        {renderHeadline('projects', foundProjects.length)}
        <FlatList style={{ maxHeight: maxFlatListHeight }} renderItem={renderProject} data={projects}/>
        {foundProjects.length > numberOfShownElements ? (<ContainedButton key={`ticketsShowMore${foundProjects}`} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} title={showAllProjects ? I18n.m.getMessage('showLess') : I18n.m.getMessage('showMore')} onPress={() => setShowAllProjects(!showAllProjects)}/>) : null}
      </View>);
    };
    const renderFile = ({ item }) => {
        return <FileCard file={item} key={`files${item.id}`}/>;
    };
    const renderFiles = () => {
        if (foundFiles.length === 0)
            return <View />;
        const iMaxLength = showAllFiles || foundFiles.length < numberOfShownElements ? foundFiles.length : numberOfShownElements;
        const files = foundFiles.slice(0, iMaxLength);
        return (<View style={{ paddingTop: 8 }}>
        {renderHeadline('files', foundFiles.length)}
        <FlatList style={{ maxHeight: maxFlatListHeight }} renderItem={renderFile} data={files}/>
        {foundFiles.length > numberOfShownElements ? (<ContainedButton key={`ticketsShowMore${foundFiles}`} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} title={showAllFiles ? I18n.m.getMessage('showLess') : I18n.m.getMessage('showMore')} onPress={() => setShowAllFiles(!showAllFiles)}/>) : null}
      </View>);
    };
    const renderComment = ({ item }) => {
        return <CommentCard key={`comment_${item.id}`} comment={item} showProjects/>;
    };
    const renderComments = () => {
        if (foundComments.length === 0)
            return <View />;
        const iMaxLength = showAllComments || foundComments.length < numberOfShownElements ? foundComments.length : numberOfShownElements;
        const ticketComments = foundComments.slice(0, iMaxLength);
        return (<View style={{ paddingTop: 8 }}>
        {renderHeadline('ticketComments', foundComments.length)}
        <FlatList style={{ maxHeight: maxFlatListHeight }} renderItem={renderComment} data={ticketComments}/>
        {foundComments.length > numberOfShownElements ? (<ContainedButton key={`ticketsShowMore${foundComments}`} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} title={showAllComments ? I18n.m.getMessage('showLess') : I18n.m.getMessage('showMore')} onPress={() => setShowAllComments(!showAllComments)}/>) : null}
      </View>);
    };
    const renderTicket = ({ item }) => {
        return (<TicketCard key={`searchAllTicket${item.id}`} ticket={item} bullseye={false} status={false} onOpen={(_t, e) => {
                DialogBetween.instance?.close(() => {
                    Routing.instance.openDialog('ticket', { id: item.id })(e);
                });
            }} showProjects/>);
    };
    const renderTickets = () => {
        if (foundTickets.length === 0)
            return <View />;
        const iMaxLength = showAllTickets || foundTickets.length < numberOfShownElements ? foundTickets.length : numberOfShownElements;
        const tickets = foundTickets.slice(0, iMaxLength);
        return (<View style={{ paddingTop: 8 }}>
        {renderHeadline('tickets', foundTickets.length)}
        <FlatList style={{ maxHeight: maxFlatListHeight }} renderItem={renderTicket} data={tickets}/>
        {foundTickets.length > numberOfShownElements ? (<ContainedButton key={`ticketsShowMore${showAllTickets}`} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} title={showAllTickets ? I18n.m.getMessage('showLess') : I18n.m.getMessage('showMore')} onPress={() => setShowAllTickets(!showAllTickets)}/>) : null}
      </View>);
    };
    const renderPlan = ({ item }) => {
        return <PlanCard key={`searchAllPlans${item.id}`} plan={item} onOpen={() => props.closeFunction}/>;
    };
    const renderPlans = () => {
        if (foundPlans.length === 0)
            return <View />;
        const iMaxLength = showAllPlans || foundPlans.length < numberOfShownElements ? foundPlans.length : numberOfShownElements;
        const plans = foundPlans.slice(0, iMaxLength);
        return (<View style={{ paddingTop: 8 }}>
        {renderHeadline('plans', foundPlans.length)}
        <FlatList style={{ maxHeight: maxFlatListHeight }} renderItem={renderPlan} data={plans}/>
        {foundPlans.length > numberOfShownElements ? (<ContainedButton key={`plansShowMore${showAllPlans}`} backgroundColor="transparent" textColor={ThemeManager.style.brandPrimary} title={showAllPlans ? I18n.m.getMessage('showLess') : I18n.m.getMessage('showMore')} onPress={() => setShowAllPlans(!showAllPlans)}/>) : null}
      </View>);
    };
    const onSearch = async (text) => {
        let projects = [];
        let tickets = [];
        let plans = [];
        let files = [];
        let comments = [];
        if (text.length > 0) {
            try {
                projects = await UpmeshClient.instance.modals.project.get({
                    filter: `deleted ne true and locked ne true and (contains(title, '${text}') or contains(description, '${text}') or contains(projectType, '${text}'))`,
                });
            }
            catch (err) {
                console.warn('cant find projects in global search', err);
            }
            try {
                let te = [];
                te = await UpmeshClient.instance.modals.ticket.get({
                    filter: `contains(title, '${text}') or contains(description, '${text}') or contains(address, '${text}') or contains(craft, '${text}') or contains(ticketNumber, '${text}') or contains(builder/name, '${text}') or contains(builder/phone, '${text}') or contains(builder/email, '${text}') or contains(builder/address/address, '${text}') or contains(address, '${text}') or contains(tags/tagName, '${text}') or contains(fields/value, '${text}')`,
                });
                tickets = await PromisePool.run({
                    collection: te,
                    task: getTicketData,
                    maxConcurrency: 2,
                });
            }
            catch (err) {
                console.warn('cant find tickets in global search', err);
            }
            try {
                plans = await UpmeshClient.instance.modals.plan.get({
                    filter: `deleted ne true and (contains(title, '${text}') or contains(notes, '${text}'))`,
                });
            }
            catch (err) {
                console.warn('cant find plans in global search', err);
            }
            try {
                files = await UpmeshClient.instance.modals.storedFile.get({
                    filter: `deleted ne true and (contains(orgFilename, '${text}') or contains(type, '${text}') or contains(comment, '${text}'))`,
                });
            }
            catch (err) {
                console.warn('cant find files in global search', err);
            }
            try {
                comments = await UpmeshClient.instance.modals.ticketComments.get({
                    filter: `deleted ne true and commentType eq 'usercomment' and contains(comment, '${text}')`,
                });
            }
            catch (err) {
                console.warn('cant find comments in global search', err);
            }
        }
        setFoundProjects(projects);
        setFoundPlans(plans);
        setFoundTickets(tickets);
        setFoundFiles(files);
        setFoundComments(comments);
    };
    const search = (text) => {
        setSearchText(text);
        if (searchTimer.current)
            clearTimeout(searchTimer.current);
        searchTimer.current = setTimeout(() => {
            onSearch(text).catch((err) => console.error(err));
        }, 250);
    };
    const renderLastSearches = () => {
        if (searchText.length > 0 || lastSearches.length === 0)
            return <View />;
        const renderedSearches = [];
        for (let i = 0; i < lastSearches.length; i += 1) {
            if (lastSearches[i].length > 0)
                renderedSearches.push(<Card outerPadding={4}>
            <Ripple style={{
                        width: '100%',
                        padding: ThemeManager.style.getScreenRelativePixelSize(4),
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        height: 44,
                    }} onPress={() => search(lastSearches[i])}>
              <View style={{ height: 'auto', maxWidth: '100%', alignSelf: 'center', flexGrow: 1 }}>
                <MaterialText numberOfLines={1} centeredText>
                  {lastSearches[i]}
                </MaterialText>
              </View>
            </Ripple>
          </Card>);
        }
        return (<View>
        {renderHeadline('globalSearchLastRequests')}
        {renderedSearches}
      </View>);
    };
    return (<View style={{
            paddingBottom: 8,
            minHeight: ResizeEvent.current.contentWidth < ThemeManager.style.breakpointM
                ? '100%'
                : ResizeEvent.current.contentHeight * 0.9,
        }}>
      <View style={{
            height: 36,
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
        }}>
        <View style={{ flex: 1 }}>
          <SearchBar hideClearButton searchOnChange={search} searchBarValue={searchText} backgroundColor="#FFFFFF" searchBarPlaceholder={I18n.m.getMessage('globalSearch')} ref={(r) => {
            searchBar.current = r;
        }} onBlur={async () => {
            const newText = searchText.replace(';', '').trim();
            if (searchText.length > 0 && lastSearches.indexOf(newText) === -1) {
                const searches = [newText].concat(lastSearches);
                if (searches.length > 5)
                    searches.splice(5, searches.length - 5);
                setLastSearches(searches);
                let searchesString = '';
                searches.forEach((s) => {
                    searchesString = `${searchesString + s};`;
                });
                await SimpleStorage.set('globalSearch', searchesString);
            }
        }}/>
        </View>
        <View style={{ width: 48, justifyContent: 'flex-end', paddingLeft: 12 }}>
          <Icon icon="close" toolTip={I18n.m.getMessage('close')} onPress={() => DialogBetween.instance?.close()}/>
        </View>
      </View>
      <FilterChips chips={filterChips} outlined backgroundColor="#ffffff" backgroundColorChecked={Color(ThemeManager.style.brandPrimary).alpha(0.3).toString()} onPressChip={(index, chip) => {
            const chips = [...filterChips];
            chips[index] = chip;
            let checked = false;
            chips.forEach((c) => {
                if (c.checked)
                    checked = true;
            });
            setSelectedAnyFilter(checked);
            setFilterChips(chips);
        }}/>
      {renderLastSearches()}
      {!selectedAnyFilter || filterChips[0].checked ? renderProjects() : null}
      {!selectedAnyFilter || filterChips[1].checked ? renderPlans() : null}
      {!selectedAnyFilter || filterChips[2].checked ? renderTickets() : null}
      {!selectedAnyFilter || filterChips[3].checked ? renderFiles() : null}
      {!selectedAnyFilter || filterChips[4].checked ? renderComments() : null}
    </View>);
}
export const openSearch = () => {
    DialogBetween.instance?.open({
        fullscreenResponsive: true,
        content: <GlobalSearch closeFunction={DialogBetween.instance?.close}/>,
        closeOnTouchOutside: true,
        scrollable: true,
        closeTitle: I18n.m.getMessage('close'),
        showCloseIcon: false,
    });
};
